/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { FaFire, FaTag } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { Button } from "reactstrap";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import MobileNavMenu from "../../../components/Navbar/MobileNavMenu";
import ProductCard from "../../../components/ProductCard";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import useIsInViewport from "../../../hooks/useInViewPort";
import useScreenSize from "../../../hooks/useScreenSize";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import getRestaurantPathFromUrl from "../../../utils/getRestaurantPathFromUrl";
import LoginModal from "../components/LoginModal";
import PopularDishesModal from "../components/PopularDishesModal";
import UsualDishesModal from "../components/UsualDishesModal";
import EmptyPlaceholder from "./components/EmptyPlaceholder";
import GridCardProduct from "./components/GridCardProduct";
import ViewDishModalCatering from "../components/ViewDishModalCatering";
import logger from "../../../utils/logger";
import { toast } from "react-toastify";
import ViewDishModal from "../components/ViewDishModal";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import Select from "react-select";
import { customStyles } from "./components/MenuSidebar";
import Footer from "../components/Footer";
import DetailsPanel from "../catering/components/DetailsPanel";
import MenuBannerDetails from "./components/MenuBannerDetails";
import HorizontalList from "../../../components/List/HorizontalList";
import HorizontalListMobile from "../../../components/List/HorizontalListMobile";

const MenuMobile = (props) => {
  const [searchString, setSearchString] = useState("");
  const [activeCat, setActiveCat] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { restaurant } = useOrderingRestaurant();
  const headerRef = useRef(null);
  const authContext = useContext(AuthContext);
  const [showUsual, setShowUsual] = useState(false);
  const [showPopular, setShowPopular] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const { width } = useScreenSize();
  const [dish, setDish] = useState({});
  const [openDish, setOpenDish] = useState(false);
  const [selectedGroupCategories, setSelectedGroupCategories] = useState(null);

  const {
    find,
    entities,
    loading: isLoading,
  } = useEntity("eat/ordering/ordering-menu");

  const {
    timing,
    laterDate,
    laterTime,
    method,
    location,
    cateringData,
    setGateway,
  } = useOrdering();
  const { theme } = useTheme();
  const contentRef = useRef(null);
  const categoryButtonRefs = useRef([]);
  const sectionRef = useRef(null);
  const isInViewPort = useIsInViewport(sectionRef);
  const [searchResults, setSearchResults] = useState([]);
  const { addToCart } = useCart();
  const [isMobileSearch, setIsMobileSearch] = useState(false);

  const showOrderUsualAndPopular = !(
    restaurant?.ordering?.hideOrderUsualAndPopular || method === "walkup"
  );

  useEffect(() => {
    let dateString;
    if (timing === "later") {
      dateString = new Date(laterDate);
      const splittedTime = laterTime.split(":");
      dateString.setHours(splittedTime[0], splittedTime[1]);
    }

    find({
      restaurant: restaurant?.userId?._id,
      location: location?._id,
      method: method,
      schedule: timing === "later" ? dateString : undefined,
      filterDSP: location?.connectedToStream ? "true" : "false",
      catering: cateringData?.catering ? "true" : "false",
      groupCategory: selectedGroupCategories,
      isMobile: "false",
    });
  }, [selectedGroupCategories]);

  const scrollToCategory = (category) => {
    const targetItemIndex = entities?.data?.categories?.findIndex(
      (c) => c.categoryName === category
    );

    if (targetItemIndex !== -1) {
      if (contentRef.current && contentRef.current.children[targetItemIndex]) {
        const targetElement = contentRef.current.children[targetItemIndex];
        const elementPosition =
          targetElement.getBoundingClientRect().top + window.scrollY;
        let offsetPosition = elementPosition - (!isInViewPort ? 120 : 200);
        if (targetItemIndex === 0 && isInViewPort) {
          offsetPosition += 140;
        }
        if (targetItemIndex === 0 && !isInViewPort) {
          offsetPosition += 60;
        }
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const searchItems = (searchTerm) => {
    try {
      if (searchTerm.toLocaleLowerCase() === "") {
        setSearchResults([]);
        return;
      }

      const searchLower = searchTerm.toLowerCase().trim();
      const items = entities?.data?.categories?.flatMap((c) => c.items) || [];
      if (items.length > 0) {
        const results = items
          ?.filter((item) => {
            const nameMatch = item?.name.toLowerCase().includes(searchLower);
            const descriptionMatch =
              item?.description &&
              item?.description.toLowerCase().includes(searchLower);

            return nameMatch || descriptionMatch;
          })
          .filter(
            (function () {
              const seen = new Set();
              return function (item) {
                if (!seen.has(item._id)) {
                  seen.add(item._id);
                  return true;
                }
                return false;
              };
            })()
          );

        setSearchResults(results);
      }
    } catch (error) {}
  };

  const { findOne, entity } = useEntity("eat/ordering/gateway");

  useEffect(() => {
    setGateway(entity?.data);
  }, [entity]);

  useEffect(() => {
    findOne(location?._id);
  }, [location]);

  useEffect(() => {
    searchItems(searchString);
  }, [searchString]);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setIsMobileSearch(event.detail.mobileSearch);
    };

    document.addEventListener("mobileSeachEnabled", handleCustomEvent);

    return () => {
      document.removeEventListener("mobileSeachEnabled", handleCustomEvent);
    };
  }, []);

  return (
    <RestaurantWrapper>
      <div className={`${"mobile_top_nav_menu mb-5"}`}>
        <MobileNavMenu
          searchString={(val) => {
            setSearchString(val);
          }}
          history={props.history}
          setState={(val) => setSidebarOpen(val)}
          searchValues={searchString}
          hideBack={false}
          menu={true}
        />
      </div>
      <div
        className={` menu_sidebar_image_container mt-5`}
        style={{
          padding: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          backgroundColor: theme.bannerColor,
        }}
      >
        {restaurant?.ordering?.headerImage !== "" &&
          restaurant?.ordering?.headerImage && (
            <img
              className="header_image_sidebar"
              src={restaurant?.ordering?.headerImage}
              style={{
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                width: "100%",
              }}
              alt="headerImage"
            />
          )}
        <a
          className="mobile_menu_sidebar_image"
          style={{
            zIndex: 1,
            backgroundColor: restaurant?.ordering?.logoWhiteBackground
              ? "white"
              : "transparent",
          }}
          href={restaurant?.homeLink || restaurant?.url}
        >
          <div
            style={{
              zIndex: 1,
              backgroundColor: restaurant?.ordering?.logoWhiteBackground
                ? "white"
                : "transparent",
            }}
          >
            <img
              src={`${restaurant?.logo}`}
              alt="logo"
              style={{
                cursor: "pointer",
                zIndex: 1,
              }}
              className="header_image_ordering_menu  "
            />
          </div>
        </a>
      </div>
      {showOrderUsualAndPopular && (
        <div
          className="padding-horizontal no_border padding-vertical"
          style={{ borderBottom: "1px solid #ededed" }}
        >
          <div className={"order_usual_wrapper"}>
            {!cateringData?.method && (
              <div style={{ flex: 1 }}>
                <Button
                  block
                  className="py-3 text-xs order_usual_btn order_usual_btn_margin"
                  style={{
                    backgroundColor: "white",
                    color: theme.primary,
                  }}
                  onClick={() =>
                    authContext?.user ? setShowUsual(true) : setLoginModal(true)
                  }
                >
                  {width > 600 && (
                    <FaTag
                      className="mx-1"
                      style={{ color: theme.secondary }}
                    />
                  )}
                  My Usual
                </Button>
              </div>
            )}
            {!cateringData?.method && <div className="space_usual"></div>}
            <div style={{ flex: 1 }}>
              <Button
                block
                className="py-3 order_usual_btn"
                style={{
                  backgroundColor: "white",
                  color: theme.primary,
                }}
                onClick={() => setShowPopular(true)}
              >
                {width > 600 && (
                  <FaFire className="mx-1" style={{ color: theme.secondary }} />
                )}{" "}
                Popular Picks
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="show_mobile_group_cats">
        {entities?.data?.groupCategories?.length > 0 && (
          <HorizontalListMobile
            selected={selectedGroupCategories}
            onChangeItem={(id) => {
              setSelectedGroupCategories(id);
            }}
            items={entities?.data?.groupCategories || []}
            cats={entities?.data?.categories || []}
            hasExtraCats={entities?.data?.hasExtraCats}
          />
        )}
      </div>
      <div ref={sectionRef}>
        {restaurant?.ordering?.categoryLayout === "horizontal" &&
          restaurant?.ordering?.categoryLayout !== "select" && (
            <div
              style={{
                borderTop: `1px solid silver`,
                borderBottom: `1px solid silver`,
                marginTop:
                  !isInViewPort && !isMobileSearch
                    ? 60
                    : !isInViewPort && isMobileSearch
                    ? 140
                    : 0,
              }}
              ref={headerRef}
              className={
                isInViewPort
                  ? "hide_scroll hide_mobile_category"
                  : "hide_mobile_category mobile_horizontal_layout_fixed hide_scroll"
              }
            >
              {entities?.data?.categories?.map((cat) => {
                return (
                  <div
                    key={cat?.categoryName}
                    ref={(el) => {
                      categoryButtonRefs.current[cat.categoryName] = el;
                    }}
                    onClick={() => {
                      scrollToCategory(cat?.categoryName);
                      setActiveCat(cat?.categoryName);
                    }}
                    className="px-4 py-3"
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      cursor: "pointer",
                      borderRadius: "0px",
                      background: " #FFFFFF",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      flex: 1,

                      borderBottom:
                        activeCat === cat?.categoryName
                          ? `1px solid black`
                          : "",
                    }}
                  >
                    {cat.categoryName}
                  </div>
                );
              })}
            </div>
          )}
      </div>
      {(restaurant?.ordering?.categoryLayout === "select" ||
        !restaurant?.ordering?.categoryLayout ||
        restaurant?.ordering?.categoryLayout === "") &&
        restaurant?.ordering?.categoryLayout !== "horizontal" && (
          <div
            className={
              isInViewPort
                ? "category_dropdown_menu mx-3 my-2 "
                : "category_dropdown_menu_fixed mx-3"
            }
            style={{
              backgroundColor: "#ededed",
              borderRadius: 0,
              zIndex: isInViewPort ? 999 : 9,
              marginTop:
                !isInViewPort && !isMobileSearch
                  ? 60
                  : !isInViewPort && isMobileSearch
                  ? 140
                  : 0,
            }}
          >
            <div style={{ width: "98%" }}>
              <Select
                isSearchable={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "null",
                  },
                })}
                onChange={(val) => {
                  scrollToCategory(val?.label);
                  setActiveCat(val?.label);
                  setSelectCategory({ value: val?.value, label: val?.label });
                }}
                name="category"
                styles={customStyles}
                value={selectCategory}
                options={entities?.data?.categories?.map((cat) => {
                  return {
                    label: cat?.categoryName,
                    value: cat?._id,
                  };
                })}
              />
            </div>
          </div>
        )}
      <div className="mt-4">
        {cateringData?.catering ? (
          <DetailsPanel
            whiteBg={true}
            showNav={true}
            history={props.history}
            theme={theme}
            origin="menu"
          />
        ) : (
          <MenuBannerDetails popover={false} />
        )}
      </div>
      <div className="mx-3 mobile-minus text-left mb-8">
        {isLoading ? (
          <div className="my-3 mt-5">
            <Skeleton height={30} width={100} />
            <Skeleton height={25} />
            <Skeleton height={25} width={"100%"} />
            {[...new Array(4).fill(null).map((_) => _)].map((_, i) => {
              return (
                <div key={i} className="">
                  <Skeleton height={200} />
                  <Skeleton height={15} width={100} />
                  <Skeleton height={15} />
                  <Skeleton height={15} />
                  <Skeleton height={40} width={100} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mt-5">
            {searchResults.length <= 0 && searchString === "" ? (
              <>
                <div className="prod_menu_card">
                  <div ref={contentRef}>
                    {entities?.data?.categories?.map((menu) => {
                      return (
                        <div key={menu.categoryName}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: 20,
                            }}
                          >
                            <div>
                              <div className="d-flex w-full justify-content-between align-items-center">
                                <h1 style={{ color: theme.primary }}>
                                  {menu?.categoryName}
                                </h1>
                              </div>
                              <h1
                                className="mb-3"
                                style={{
                                  color: "#666666",
                                  fontSize: 14,
                                  fontWeight: "500",
                                  marginBottom: 10,
                                }}
                              >
                                {menu?.description ? menu?.description : ""}
                              </h1>
                            </div>
                          </div>
                          <div>
                            {restaurant?.ordering?.menuLayout === "grid" ? (
                              <div className="grid-layout-menu-new">
                                {menu?.items?.map((d, i) => {
                                  return (
                                    <GridCardProduct
                                      length={menu?.items?.length}
                                      ind={i}
                                      key={i}
                                      theme={theme}
                                      sidebarOpen={sidebarOpen}
                                      data={d}
                                      onClick={() => {
                                        setDish(d);
                                        setOpenDish(true);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            ) : (
                              <div
                                className="main-sec-cati row"
                                style={{ margin: 0 }}
                              >
                                {menu?.items?.map((d, i) => {
                                  return (
                                    <ProductCard
                                      length={menu?.items?.length}
                                      ind={i}
                                      key={i}
                                      theme={theme}
                                      sidebarOpen={sidebarOpen}
                                      data={d}
                                      onClick={() => {
                                        setDish(d);
                                        setOpenDish(true);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-4">
                {searchResults?.length === 0 ? (
                  <div>
                    <EmptyPlaceholder text="Nothing found" />
                  </div>
                ) : (
                  <div>
                    <h1>Search Results</h1>
                    {restaurant?.ordering?.menuLayout === "grid" ? (
                      <div className="grid-layout-menu-new">
                        {searchResults?.map((d, i) => {
                          return (
                            <GridCardProduct
                              length={searchResults?.length}
                              ind={i}
                              key={i}
                              theme={theme}
                              sidebarOpen={sidebarOpen}
                              data={d}
                              onClick={() => {
                                setDish(d);
                                setOpenDish(true);
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <div
                          className="main-sec-cati row"
                          style={{ margin: 0 }}
                        >
                          {searchResults?.map((d, i) => {
                            return (
                              <ProductCard
                                length={searchResults?.length}
                                ind={i}
                                key={i}
                                theme={theme}
                                sidebarOpen={sidebarOpen}
                                data={d}
                                onClick={() => {
                                  setDish(d);
                                  setOpenDish(true);
                                }}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mb-8">
        <Footer />
      </div>
      <UsualDishesModal
        theme={theme}
        isOpen={showUsual}
        usualdata={[]}
        toggle={() => setShowUsual(!showUsual)}
      />
      {showPopular && (
        <PopularDishesModal
          origin={getRestaurantPathFromUrl(window.location.pathname)}
          theme={theme}
          isOpen={showPopular}
          toggle={() => setShowPopular(!showPopular)}
        />
      )}
      <LoginModal
        open={loginModal}
        toggle={() => setLoginModal(!loginModal)}
        theme={theme}
      />
      {Object.keys(dish).length > 0 && !cateringData?.catering ? (
        <ViewDishModal
          theme={theme}
          onAddToCart={(op, notes, qty = 1) => {
            try {
              addToCart(
                {
                  ...dish,
                  _id: dish?._id,
                  qty,
                  modifiers: op,
                  note: notes,
                },
                true
              );
            } catch (error) {
              logger.push({ error, info: "MainMenuSectionViewDish" });
              toast.error(error.message);
            }
          }}
          isOpen={openDish}
          dish={dish}
          toggle={() => setOpenDish(!openDish)}
        />
      ) : (
        <ViewDishModalCatering
          theme={theme}
          onAddToCart={(op, notes, qty = 1) => {
            try {
              addToCart(
                {
                  ...dish,
                  _id: dish?._id,
                  qty,
                  modifiers: op,
                  note: notes,
                },
                true
              );
            } catch (error) {
              logger.push({ error, info: "MainMenuSectionViewDish" });
              toast.error(error.message);
            }
          }}
          isOpen={openDish}
          dish={dish}
          toggle={() => setOpenDish(!openDish)}
        />
      )}
    </RestaurantWrapper>
  );
};

export default MenuMobile;
