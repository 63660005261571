import { useState } from "react";
import { useCookies } from "react-cookie";
import { FaArrowLeft, FaLock } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import AuthHeader from "../../components/Header/AuthHeader";
import { useEntity } from "../../hooks/useEntity";

const UpdatePassword = ({ history }) => {
  const location = useLocation();
  const [, setCookie] = useCookies(["secondsLeft"]);

  const { create, loading } = useEntity("auth/update-password");
  const [password, setPassword] = useState("");

  const schema = Yup.object().shape({
    password: Yup.string().required("Password can't be empty"),
  });

  return (
    <div className="bg-default" style={{ height: "100vh" }}>
      <>
        <AuthHeader title="Update Password" lead="" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <div
                  onClick={() => history.push("/login")}
                  className="text-left mb-3 px-3 mt-3"
                  style={{ color: "gray", cursor: "pointer" }}
                >
                  <FaArrowLeft className="mr-2" style={{ color: "gray" }} />{" "}
                </div>

                <CardBody className="">
                  <div>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FaLock />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="New Password"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </InputGroup>
                      <Button
                        onClick={async () => {
                          try {
                            await schema.validate({ password });
                            await create({
                              password,
                              email: location?.state?.email,
                              otp: location?.state?.otp,
                            });
                            toast.success("Password updated successfully");
                            setCookie("secondsLeft", 30, { path: "/" });
                            history.push("/login");
                          } catch (error) {
                            toast.error(error.message);
                          }
                        }}
                        block
                        className="my-4"
                        color="info"
                        type="button"
                      >
                        {loading ? (
                          <Spinner size="sm" color="white" />
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </div>
  );
};

export default UpdatePassword;
