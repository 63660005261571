import { HOST } from "../config";
import DB from "../utils/DB";
import { DBKeys } from "../utils/Keys";
import logger from "../utils/logger";
import endpoints from "./endpoints";
import { ResponseError } from "./error";

let token;

export default async function serviceCaller(service, api, body, query, params) {
  let authorizationHeader = {};
  const pathname = window.location.pathname;

  if (pathname.split("/")[1] === "ordering") {
    token = await DB.retrieveData(DBKeys.CUSTOMER_TOKEN);
    authorizationHeader["Authorization"] = `Bearer ${token}`;
  } else {
    token = await DB.retrieveData(DBKeys.TOKEN);
    authorizationHeader["token"] = token;
  }

  const request = endpoints[service][api];
  const options = {
    method: request.method,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
  };
  if (request.method !== "get" && body) {
    options.body = JSON.stringify(body);
  }

  let url = `${HOST}${request.path}`;
  if (params) {
    params.forEach((element) => {
      url = url.replace(`:${element.key}`, element.value);
    });
  }
  url = new URL(url);
  if (query) {
    const searchParams = new URLSearchParams(query).toString();
    url = `${url.toString()}?${searchParams}`;
  }
  const apiIdentity = `${options.method} ${url}`;

  try {
    const response = await fetch(url, options);
    console.log("SERVICE_CALLER", response);
    const jsonResponse = await response.json();
    if (jsonResponse.type === "error") throw new ResponseError(jsonResponse);
    return jsonResponse;
  } catch (error) {
    logger.push({ error, info: "SERVICECALLER" });
    throw error instanceof ResponseError ? error : new ResponseError(error);
  }
}
