import { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Spinner,
} from "reactstrap";

const RefundModal = ({
  toggle,
  open,
  refundAmount,
  refundProcess,
  loading,
}) => {
  const [refund, setRefund] = useState();

  useEffect(() => {
    if (refundAmount) {
      setRefund(refundAmount);
    }
  }, [refundAmount, open]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={open}
      toggle={() => {
        toggle();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Refund
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-dollar" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => {
                setRefund(e.target.value);
              }}
              placeholder="Refund Amount"
              type="number"
              value={refund}
            />
          </InputGroup>
        </FormGroup>
      </div>
      <div className="modal-footer">
        <div>
          <Button
            onClick={() => refundProcess(refund)}
            color="primary"
            type="button"
          >
            {loading ? <Spinner size={"sm"} /> : "Save"}
          </Button>

          <Button
            onClick={() => {
              toggle();
            }}
            color="neutral"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RefundModal;
