import { isMobile } from "react-device-detect";
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

export default function Email({
  errors,
  touched,
  handleChange,
  values,
  setFieldValue,
}) {
  return (
    <Row>
      <Col md={12}>
        <Row>
          <Col md="6">
            <Row>
              <Col
                md="3"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                SendGrid Email
              </Col>
              <Col md="9" className={isMobile && "my-2"}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    value={values?.sendGridEmail}
                    onChange={handleChange("sendGridEmail")}
                    className={`form-control ${
                      errors.sendGridEmail && touched.sendGridEmail
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col
                md="3"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                Sendgrid Key
              </Col>
              <Col md="9" className={isMobile && "my-2"}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-lock" aria-hidden="true"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    value={values?.sendGridKey}
                    onChange={handleChange("sendGridKey")}
                    className={`form-control ${
                      errors.sendGridKey && touched.sendGridKey
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md={12} style={{ marginTop: 20 }}>
        <Row>
          <Col md="6">
            <Row>
              <Col
                md="3"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                Order webhook
              </Col>
              <Col md="9" className={isMobile && "my-2"}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-share" aria-hidden="true"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    value={values?.orderWebhook}
                    onChange={(e) => {
                      setFieldValue("orderWebhook", e.target.value);
                      console.log(e.target.value);
                    }}
                    className={`form-control ${
                      errors.orderWebhook && touched.orderWebhook
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col
                md="3"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                Customer webhook
              </Col>
              <Col md="9" className={isMobile && "my-2"}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-share" aria-hidden="true"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    value={values?.customerWebhook}
                    onChange={(e) =>
                      setFieldValue("customerWebhook", e.target.value)
                    }
                    className={`form-control ${
                      errors.customerWebhook && touched.customerWebhook
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
