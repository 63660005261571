import { useState } from "react";
import { useQuery } from "react-query";
import apiCaller from "../../api/apiCaller";

export function useRestaurant() {
  const [findEnabled, setFindEnabled] = useState(false);
  const [findQuery, setFindQuery] = useState(false);

  const {
    isLoading: findLoading,
    data: entity = {},
    isFetching,
    error: findError,
    refetch: refetchFind,
  } = useQuery(
    [`find-restaurant-by-hostname`, findQuery],
    () => {
      return apiCaller(`/eat/restaurant/restaurant-by-host`, {
        query: { ...findQuery },
      });
    },
    {
      enabled: findEnabled,
      retry: false,
    }
  );

  function find(payload) {
    setFindQuery(payload);
    setFindEnabled(true);
  }

  return {
    find,
    entity,
    isFetching,
    error: findError,
    refetch: refetchFind,
    loading: findLoading,
  };
}
