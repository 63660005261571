import { Button, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import AddCustomPrepTimeModal from "./AddCustomPrepTimeModal";
import { useState } from "react";
import moment from "moment";

const toAMPM = (time) => {
  return moment(time, "HH:mm").format("hh:mm A");
};

export default function CustomPrepTimes({ formik }) {
  const [openModal, setOpenModal] = useState(false);

  //some validations
  const handleValidate = (val) => {
    let values = formik.values?.customPrepTimes ?? [];
    // only one method one time per day
    let isExist = values.some((customPrepTime) => {
      return (
        customPrepTime.day === val.day &&
        val.methods.some((m) => customPrepTime.methods.includes(m))
      );
    });
    return !isExist;
  };

  //For saving the data into table data state
  const handleSave = (val) => {
    let values = formik.values?.customPrepTimes ?? [];
    formik.setFieldValue("customPrepTimes", [...values, val]);
  };

  //handleing deletion of prep time
  const handleDelete = (index) => {
    /** @type {string[]} */
    let values = [...(formik.values?.customPrepTimes || [])];
    console.log("deleteing", index, formik.values.customPrepTimes[index]);
    values.splice(index, 1);
    formik.setFieldValue("customPrepTimes", [...values]);
  };

  //handling open close of prepTime modal
  const handlePrepTimeModal = () => setOpenModal(!openModal);

  return (
    <>
      <Col className="mt-5" md="12">
        <h2 style={{ fontWeight: "bolder" }}>Time based prep time</h2>
      </Col>
      <Col md="12" className="mt-3">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Enable Time Based Prep Times
            </Label>
            <Col
              md="1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label className="custom-toggle">
                <input
                  checked={formik.values?.customPrepTimesEnabled}
                  value={formik.values?.customPrepTimesEnabled}
                  onChange={formik.handleChange("customPrepTimesEnabled")}
                  type="checkbox"
                  disabled={formik.values.deliveryZones?.length > 1}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </FormGroup>
        </Form>
      </Col>
      {formik.values?.customPrepTimesEnabled && (
        <>
          <Col md="12">
            <Row>
              <Col md="4"></Col>
              <Col md="8">
                <Button
                  onClick={() => setOpenModal(true)}
                  size="sm"
                  type="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4"></Col>
              <Col md="8">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Preptime</th>
                      <th>Methods</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values?.customPrepTimes?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.day}</td>
                        <td>{toAMPM(item.from)}</td>
                        <td>{toAMPM(item.to)}</td>
                        <td>{item.time}</td>
                        <td>{item.methods.join(", ")}</td>
                        <td>
                          <Button
                            onClick={() => handleDelete(index)}
                            size="sm"
                            type="button"
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
          <AddCustomPrepTimeModal
            open={openModal}
            toggle={handlePrepTimeModal}
            setData={handleSave}
            validate={handleValidate}
          />
        </>
      )}
    </>
  );
}
