import React from "react";
import { Button } from "reactstrap";

const PageNotFound = ({ history }) => {
  return (
    <div className="container-fluid" style={{ height: "100vh" }} id="wrapper">
      <img height={400} src="https://i.imgur.com/qIufhof.png" />
      <div id="info">
        <h3>This page could not be found</h3>
        <Button
          onClick={() => {
            history.goBack();
          }}
          size="sm"
          color="secondary"
          className="mt-4"
        >
          Go back
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
