import moment from "moment";
import React from "react";
import { Button, Table } from "reactstrap";

export default function IPAddressTable() {
  return (
    <>
      <Table className="align-items-center" responsive bordered>
        <thead className="thead-light">
          <tr>
            <th scope="col">IP</th>
            <th scope="col">Total Account</th>
            <th scope="col">Date Added</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4].map((d, i) => {
            return (
              <tr key={i}>
                <td>17.245.246.{i}</td>
                <td>{i * 5}</td>
                <td>{moment().format("LLL")}</td>
                <td>
                  <Button size="sm" color="danger">
                    Add Ban IP
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
