import { toFixed } from "../../../utils/toFixed";

export const TableRow = ({ index, data }) => {
  return (
    <tr className="text-left">
      <td>{data?.restaurantName}</td>
      <td>{toFixed(data?.totalOrderFee || 0)}</td>
      <td>{toFixed(data?.newCustomers || 0)}</td>
      <td>{toFixed(data?.totalOrders || 0)}</td>
    </tr>
  );
};
