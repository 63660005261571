import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import "../../styles/Header/Header.css";
const MenuHeader = () => {
  const { restaurant } = useOrderingRestaurant();
  return (
    <div
      style={{
        backgroundColor: restaurant?.ordering?.backgroundColor,
      }}
    >
      <div className="p-3"></div>
    </div>
  );
};

export default MenuHeader;
