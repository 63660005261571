import { isMobileOnly } from "react-device-detect";
import { Card, Container, Row } from "reactstrap";

const TableWrapper = ({ children, customStyles }) => {
  return (
    <Container className="mt--6" fluid>
      <Row>
        <div className="col">
          <Card
            className={`${customStyles} shadow`}
            style={{ padding: "2px 2px" }}
          >
            {children}
          </Card>
        </div>
      </Row>
      {isMobileOnly && <div style={{ height: 200 }}></div>}
    </Container>
  );
};

export default TableWrapper;
