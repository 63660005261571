import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import getCirclePath from "../../../../utils/getCirclePath";
import LocationSearchInput from "../../../ordering/components/LocationSearchInput";

let color = [
  "#FF0000",
  "#4286f4",
  "#ffff00",
  "#ff00b2",
  "#bb00ff",
  "#00ffff",
  "#26ff00",
  "#00ff87",
];

const MapCustom = ({ geofencing, setGeofencing, radius, center }) => {
  const mapRef = React.useRef(null);
  const [polygon, setPolygon] = useState(null);
  const [map, setMap] = useState(null);
  const [isInit, setIsInit] = useState(false);

  const initMap = () => {
    let google = window.google;
    let map = mapRef.current;

    const mapOptions = {
      zoom: 12,
      center: center,
      scrollwheel: true,
      zoomControl: true,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);
    setMap(map);
  };
  React.useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (map) {
      setIsInit(true);
    }
  }, [map]);

  React.useEffect(() => {
    if (isInit) {
      renderToMaps();
    }
  }, [map, radius, geofencing, isInit]);

  const renderToMaps = () => {
    if (!center.lat && !geofencing) {
      return;
    }
    polygon?.setMap(null);

    const path = geofencing
      ? geofencing
      : getCirclePath(center, radius * 1609.344, 10);

    const polygonObj = new window.google.maps.Polygon({
      map: map,
      path: path,
      strokeColor: color[1],
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: color[1],
      fillOpacity: 0.35,
      editable: true,
    });

    new window.google.maps.event.addListener(
      polygonObj.getPath(),
      "set_at",
      () => {
        processCoordinates(polygonObj.getPath());
      }
    );

    new window.google.maps.event.addListener(
      polygonObj.getPath(),
      "insert_at",
      () => {
        processCoordinates(polygonObj.getPath());
      }
    );

    setGeofencing(path);
    setPolygon(polygonObj);
  };

  function processCoordinates(polygonBounds) {
    var bounds = [];

    for (var i = 0; i < polygonBounds.length; i++) {
      var point = {
        lat: polygonBounds.getAt(i).lat() || 0,
        lng: polygonBounds.getAt(i).lng() || 0,
      };
      bounds.push(point);
    }

    setGeofencing(bounds);
  }

  return (
    <div>
      <div
        style={{ height: `600px` }}
        className="map-canvas"
        id="map-custom"
        ref={mapRef}
      />
    </div>
  );
};

function Map({ setGeofencing, geofencing, radius, center }) {
  return (
    <>
      <Card className="border-0">
        <MapCustom
          center={center}
          radius={radius}
          geofencing={geofencing}
          setGeofencing={setGeofencing}
        />
      </Card>
    </>
  );
}

export default Map;
