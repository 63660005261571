import React from "react";

const Testimonials = () => {
  return (
    <section className="section section-lg pt-lg-0 mt--7">
      <div className="container">
        <div className="justify-content-left row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4">
                <div className="card-lift--hover shadow border-0 card">
                  <div className="py-5 card-body">
                    <div
                      className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4"
                      style={{ justifyContent: "flex-start", display: "flex" }}
                    >
                      <i className="ni ni-check-bold" />
                    </div>
                    <h4 className="h4 text-info text-left text-uppercase">
                      Lorem Ipsum
                    </h4>
                    <p className="description text-left mt-2">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </p>
                    <div
                      style={{ justifyContent: "flex-start", display: "flex" }}
                    >
                      <span className="badge badge-info badge-pill">
                        lorem ipsum
                      </span>
                      <span className="badge badge-info badge-pill">
                        lorem ipsum
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-lift--hover shadow border-0 card">
                  <div className="py-5 card-body">
                    <div
                      className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4"
                      style={{ justifyContent: "flex-start", display: "flex" }}
                    >
                      <i className="ni ni-istanbul" />
                    </div>
                    <h4 className="h4 text-info text-left text-uppercase">
                      Lorem Ipsum
                    </h4>
                    <p className="description text-left mt-2">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </p>
                    <div
                      style={{ justifyContent: "flex-start", display: "flex" }}
                    >
                      <span className="badge badge-success badge-pill">
                        lorem ipsum
                      </span>
                      <span className="badge badge-success badge-pill">
                        lorem ipsum
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-lift--hover shadow border-0 card">
                  <div className="py-5 card-body">
                    <div
                      className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4"
                      style={{ justifyContent: "flex-start", display: "flex" }}
                    >
                      <i className="ni ni-planet" />
                    </div>
                    <h4 className="h4 text-info text-left text-uppercase">
                      Lorem Ipsum
                    </h4>
                    <p className="description text-left mt-2">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </p>
                    <div
                      style={{ justifyContent: "flex-start", display: "flex" }}
                    >
                      <span className="badge badge-warning badge-pill">
                        lorem ipsum
                      </span>
                      <span className="badge badge-warning badge-pill">
                        lorem ipsum
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
