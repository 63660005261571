import { useEffect, useState } from "react";
import groupByNameParent from "../../../../utils/groupByParent";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";

const GroupedPizzaToppings = ({ data, it, viewOrder = false }) => {
  const [groupedData, setGroupedData] = useState({});
  const { restaurant } = useOrderingRestaurant();

  useEffect(() => {
    const grouped = data.reduce((acc, item) => {
      if (!acc[item?.size]) {
        acc[item?.size] = [];
      }
      acc[item?.size].push(item);
      return acc;
    }, {});
    setGroupedData(grouped);
  }, [data]);

  const shouldShowPrice = (modif) => {
    if (!modif.defaultSelected) return true;
    return modif.type === "extra";
  };

  const calculatePrice = (item, qty) => {
    const priceMap = {
      regular: item.size === "all" ? item.price : item.halfPrice,
      extra: item.size === "all" ? item.extraPrice : item.extraPrice / 2,
    };

    const price = Number(priceMap[item.type] * qty).toFixed(2);

    return price !== "0.00" && price !== 0 ? `$${price}` : "";
  };

  return (
    <>
      {!viewOrder ? (
        <div className="text-left my-3">
          {Object.keys(groupedData).map((size) => {
            if (size !== "undefined") {
              return (
                <div
                  className="mt-2"
                  style={{ textTransform: "capitalize" }}
                  key={`${size}`}
                >
                  <div className="bold">{size}</div>
                  <div>
                    {groupByNameParent(groupedData[size]).map((modif) => {
                      return (
                        <>
                          {restaurant?.showModifierName && (
                            <div className={"bold text-left"}>{modif.name}</div>
                          )}
                          {modif.data.map((item) => {
                            if (item.advancedPizzaOptions) {
                              return (
                                <div
                                  className="d-flex justify-content-between"
                                  key={item.product_id + "-" + item.type}
                                >
                                  <div>{`${item.product_name} ${
                                    item.type === "extra" ? "x2" : ""
                                  }`}</div>
                                  {shouldShowPrice(item) &&
                                    calculatePrice(item, it.qty) + " "}
                                </div>
                              );
                            }
                          })}
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            }
          })}
        </div>
      ) : (
        <div className="text-left d-flex">
          {Object.keys(groupedData).map((size) => {
            if (size !== "undefined") {
              return (
                <div
                  className="mt-2"
                  style={{ textTransform: "capitalize" }}
                  key={size}
                >
                  {groupByNameParent(groupedData[size]).map((modif, idx) => {
                    return (
                      <>
                        {restaurant?.showModifierName && (
                          <div className={"bold text-left"}>{modif.name}</div>
                        )}
                        {modif.data.map((item) => {
                          if (item.advancedPizzaOptions) {
                            return (
                              <div className="ml-2" key={item.product_id}>
                                {`${size} - ${item.product_name} ${
                                  item.type === "extra" ? "x2" : ""
                                } `}
                                {shouldShowPrice(item) &&
                                  "(" + calculatePrice(item, it.qty) + ")"}
                                {idx !==
                                  groupByNameParent(groupedData[size]).length &&
                                  ","}
                              </div>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default GroupedPizzaToppings;
