/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useTheme } from "../../store/theme";
import getSortedArray from "../../utils/getSortedArray";

export const scrollContainerStyle = {
  display: "flex",
  flexDirection: "row",
  overflowX: "scroll",
  flex: 2,
  maxWidth: 780,
};

const HorizontalListMobile = ({
  items,
  onChangeItem,
  selected,
  cats,
  hasExtraCats,
}) => {
  const [selectedItemId, setSelectedItemId] = useState(selected);

  const [groupCategoriesOptions, setgroupCategoriesOptions] = useState([]);

  const itemStyle = {
    flex: "0 0 auto",
    marginRight: "10px",
    marginBottom: "10px",
  };
  const { theme } = useTheme();

  const handleItemClick = (itemId) => {
    setSelectedItemId(itemId);
  };

  useEffect(() => {
    onChangeItem(selectedItemId);
  }, [selectedItemId]);

  useEffect(() => {
    if (!selected) {
      setSelectedItemId(items[0]?._id);
      onChangeItem(items[0]?._id);
    }
  }, []);

  useMemo(() => {
    const arrayOfGroupCategories = [...getSortedArray(items, "sort")];
    if (hasExtraCats) {
      const newFirstElement = { name: "Menu", _id: null };

      const newArray = [newFirstElement].concat(arrayOfGroupCategories);

      setgroupCategoriesOptions([...newArray]);
    } else {
      setgroupCategoriesOptions([...arrayOfGroupCategories]);
    }
  }, [cats, items]);

  return (
    <ListGroup
      style={scrollContainerStyle}
      horizontal
      className="mx-3 hide_scroll"
    >
      {groupCategoriesOptions.map((item) => {
        return (
          <ListGroup.Item
            className="mr-2 px-4 py-2 rounded text-center"
            onClick={() => handleItemClick(item?._id)}
            key={item._id}
            style={{
              cursor: "pointer",
              backgroundColor:
                item._id === selectedItemId ? `${theme.primary}` : `white`,
              border:
                item._id === selectedItemId
                  ? `2px solid ${theme.primary}`
                  : `2px solid ${theme.primary}`,

              color: item._id === selectedItemId ? `white` : theme.primary,
              fontSize: 15,
              fontWeight: 600,
              ...itemStyle,
            }}
          >
            {item?.name}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default HorizontalListMobile;
