import { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Input, Modal, Spinner } from "reactstrap";

const AddFormModal = ({
  toggle,
  open,
  inputType,
  handleSubInfo,
  activeItem,
  subInfo,
}) => {
  const [optionLabel, setoptionLabel] = useState("");
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldOptions, setFieldOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [required, setRequired] = useState(false);

  const handleClearValues = () => {
    setFieldLabel("");
    setRequired(false);
    setFieldOptions([]);
  };

  const handleSubmit = () => {
    if (fieldLabel === "") {
      toast.error("Field title cannot be empty");
      return;
    }

    if (inputType !== "text" && fieldOptions.length <= 0) {
      toast.error("must add atleast one option");
      return;
    }

    const payload = {
      infoId: Date.now().toString(),
      label: fieldLabel,
      options: inputType !== "text" ? fieldOptions : [],
      type: inputType,
      required,
    };
    setFieldLabel("");
    setRequired(false);
    setFieldOptions([]);
    handleSubInfo(payload);
    toggle();
  };

  const handleAddOptions = () => {
    if (optionLabel === "") {
      toast.error("Options name cannot be empty");
      return;
    }
    const arr = fieldOptions;
    arr.push(optionLabel);
    setFieldOptions(arr);
    setoptionLabel("");
  };

  const handleRemoveOptions = (index) => {
    const arr = fieldOptions;
    arr.splice(index, 1);
    setFieldOptions([...arr]);
  };

  const handleUpdate = () => {
    if (activeItem) {
      const item = subInfo.findIndex((it) => it.infoId === activeItem.infoId);
      subInfo[item].label = fieldLabel;
      subInfo[item].options = fieldOptions;
      subInfo[item].required = required;

      toggle();
    }
  };

  useEffect(() => {
    if (activeItem) {
      setFieldLabel(activeItem?.label);
      setFieldOptions(activeItem?.options);
      setRequired(activeItem?.required);
    } else {
      handleClearValues();
    }
  }, [activeItem]);

  return (
    <div>
      {" "}
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => {
          toggle();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add {inputType} form
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {inputType === "text" && (
            <>
              <Input
                onChange={(e) => setFieldLabel(e.target.value)}
                value={fieldLabel}
                placeholder={`Add ${inputType} name`}
                type="text"
              />
            </>
          )}
          {inputType !== "text" && (
            <>
              <div>
                <Input
                  onChange={(e) => setFieldLabel(e.target.value)}
                  value={fieldLabel}
                  placeholder={`Add ${inputType} name`}
                  type="text"
                />
                <div className="d-flex mt-3">
                  <Input
                    onChange={(e) => setoptionLabel(e.target.value)}
                    value={optionLabel}
                    placeholder={`Add option`}
                    type="text"
                  />
                  <span className="mx-2" />
                  <Button onClick={handleAddOptions}>
                    <FaPlus />
                  </Button>
                </div>
              </div>
              {fieldOptions?.map((opt, index) => {
                return (
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div
                      style={{
                        flex: 1,
                        padding: "12px 20px",
                        border: "2px solid #ededed",
                      }}
                      className="w-full rounded"
                    >
                      {opt}
                    </div>
                    <span className="mx-2" />
                    <Button onClick={() => handleRemoveOptions(index)}>
                      <FaMinus />
                    </Button>
                  </div>
                );
              })}
            </>
          )}
        </div>

        <div className="modal-footer justify-content-between">
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={(e) => setRequired(e.target.checked)}
              checked={required}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Required
            </label>
          </div>
          <div>
            {!activeItem ? (
              <Button
                color="primary"
                data-dismiss="modal"
                type="button"
                onClick={handleSubmit}
              >
                {isSubmitting ? <Spinner /> : " Save"}
              </Button>
            ) : (
              <Button
                color="primary"
                data-dismiss="modal"
                type="button"
                onClick={handleUpdate}
              >
                {" "}
                {isSubmitting ? <Spinner /> : " Update"}
              </Button>
            )}
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggle()}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddFormModal;
