import * as Yup from "yup";

export const validationSchemaReEngage = Yup.object().shape({
  campaignName: Yup.string().trim().required("Required"),
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
  dayOfTheWeekToRun: Yup.string().required("Required"),
  usesPerCoupon: Yup.number().required("Required"),
  deleteUnusedCoupons: Yup.boolean(),
  locations: Yup.array()
    .of(Yup.mixed().required("Required"))
    .min(1, "Please select atleast one location")
    .required("Required"),
  orderCondition: Yup.string().required("Required"),
  conditionWithinValue: Yup.number().required("Required"),
  conditionWithinInterval: Yup.string().required("Required"),
  couponDiscountType: Yup.string().required("Required"),
  couponValidityValue: Yup.number().required("Required"),
  couponValidityInterval: Yup.string().required("Required"),
  runImmediately: Yup.boolean(),
});
