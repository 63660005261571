/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";
import { toast } from "react-toastify";
// reactstrap components
import Compressor from "compressorjs";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../components/Header/SimpleHeader";
import SecureInputRestaurant from "../../components/Inputs/SecureInputRestaurant";
import TieredInput from "../../components/Restaurant/tiered-inputt";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useUploadImage } from "../../hooks/useUploadImage";
import { useAdminStore } from "../../store/admin";
import ErrorLable from "../../utils/ErrorLable";
import logger from "../../utils/logger";
import {
  editRestaurantSchema,
  validationSchema,
} from "../../utils/schemas/AddRestaurant";
import states from "../../utils/states.json";
import Privacy from "./tabs/Privacy";

const AddRestaurant = (props) => {
  const location = useLocation();
  const { partner } = useAdminStore();
  const { findOne, entity } = useEntity("restaurant");
  const { updateEntity } = useEntity("restaurant");
  const { upload } = useUploadImage();
  const authContext = useContext(AuthContext);
  const [options, setOptions] = useState([]);
  const [logo, setLogo] = useState("");
  const [logoMd, setLogoMd] = useState("");
  const [logoLg, setLogoLg] = useState("");
  const [logoXl, setLogoXl] = useState("");
  const [favImg, setFavImg] = useState("");
  const { create } = useEntity("restaurant");
  const { findOne: findPartner, entity: partnerDoc } = useEntity("partner");
  const today = new Date();
  const dayAfterTomorrow = new Date(today);

  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      folderName: "",
      email: "",
      state: "",
      urlFormat: "",
      accountColor: "",
      print: "",
      username: "",
      password: "",
      orderFee: "",
      cateringOrderFee: "",
      payment: "",
      logo: "",
      logoMd: "",
      logoLg: "",
      logoXl: "",
      favImg: "",
      shopTitle: "",
      shopEmail: "",
      shopPhone: "",
      firstTierUpto: "",
      secondTierUpto: "",
      thirdTierUpto: "",
      fourthTierUpto: "",
      firstTierOnlineOrder: "",
      secondTierOnlineOrder: "",
      thirdTierOnlineOrder: "",
      fourthTierOnlineOrder: "",
      cateringFirstTierUpto: "",
      cateringSecondTierUpto: "",
      cateringThirdTierUpto: "",
      cateringFourthTierUpto: "",
      cateringFirstTierOnlineOrder: "",
      cateringSecondTierOnlineOrder: "",
      cateringThirdTierOnlineOrder: "",
      cateringFourthTierOnlineOrder: "",
      fixedValue: "",
      cateringFixedValue: "",
      enable_deliverect: false,
      enableTaxOnOrderFee: false,
      enableTaxOnDeliveryFee: false,
      enableGuestPaythere: false,
      deliverect_client_secret: "",
      deliverect_client_id: "",
      useRestaurantUrl: false,
      restaurantUrl: false,
      url: "",
      privacy: "",
      terms: "",
      privacyUrl: "",
      termsUrl: "",
      orderingApp: true,
      ordering: {},
      homeLink: "",
      ihdFees: "partner",
      ihdFeesValue: "",
      enable2fa: false,
      disableGuest: false,
      enableMenuocity: false,
    },
    validationSchema: entity ? editRestaurantSchema : validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      let logoPath = "";
      let logoMdPath = "";
      let logoLgPath = "";
      let logoXlPath = "";
      let favImgPath = "";

      if (logo) {
        logoPath = await upload({
          file: logo,
          owner: "restaurant",
          resize: false,
        });
      }

      if (logoMd) {
        logoMdPath = await upload({
          file: logoMd,
          owner: "restaurantlogoMd",
        });
      }

      if (logoLg) {
        logoLgPath = await upload({
          file: logoLg,
          owner: "restaurantLogoThree",
        });
      }

      if (logoXl) {
        logoXlPath = await upload({
          file: logoXl,
          owner: "restaurantLogoFour",
        });
      }

      if (favImg) {
        favImgPath = await upload({
          file: favImg,
          owner: "restaurantLogoFour",
        });
      }

      const doc = {
        partner: partner || authContext?.user?._id,
        name: values.name,
        email: values.email,
        phone: values.phone,
        username: values.username,
        address: values.address,
        password: values.password,
        print: values.print,
        state: values.state,
        enableTaxOnOrderFee: values.enableTaxOnOrderFee,
        enableTaxOnDeliveryFee: values.enableTaxOnDeliveryFee,
        useRestaurantUrl: values.useRestaurantUrl,
        enableGuestPaythere: values.enableGuestPaythere,
        ordering: {
          ...values?.ordering,
          backgroundColor: values?.accountColor,
          orderNow: true,
          orderLater: true,
          orderForLaterDays: 7,
          allowTip: true,
        },
        status: "active",
        role: "restaurant",
        subRole: "owner",
        orderFee: values?.orderFee,
        cateringOrderFee: values?.cateringOrderFee,
        payment: values.payment,
        homeLink: values.homeLink,
        url:
          "https://" +
          (values.useRestaurantUrl
            ? values.restaurantUrl
            : partnerDoc?.data?.partner?.url) +
          "/ordering/" +
          values?.url.toLowerCase(),
        tiers: {
          firstTier: {
            upto: values.firstTierUpto,
            onlineOrder: values.firstTierOnlineOrder,
          },
          secondTier: {
            upto: values.secondTierUpto,
            onlineOrder: values.secondTierOnlineOrder,
          },
          thirdTier: {
            upto: values.thirdTierUpto,
            onlineOrder: values.thirdTierOnlineOrder,
          },
          forthTier: {
            upto: values.fourthTierUpto,
            onlineOrder: values.fourthTierOnlineOrder,
          },
        },
        cateringTiers: {
          firstTier: {
            upto: values.cateringFirstTierUpto,
            onlineOrder: values.cateringFirstTierOnlineOrder,
          },
          secondTier: {
            upto: values.cateringSecondTierUpto,
            onlineOrder: values.cateringSecondTierOnlineOrder,
          },
          thirdTier: {
            upto: values.cateringThirdTierUpto,
            onlineOrder: values.cateringThirdTierOnlineOrder,
          },
          forthTier: {
            upto: values.cateringFourthTierUpto,
            onlineOrder: values.cateringFourthTierOnlineOrder,
          },
        },
        enable_deliverect: values?.enable_deliverect,
        deliverect_client_secret: values.deliverect_client_secret,
        deliverect_client_id: values.deliverect_client_id,
        logo: logo ? logoPath : values?.logo,
        logoMd: logoMd ? logoMdPath : values?.logoMd,
        logoLg: logoLg ? logoLgPath : values?.logoLg,
        logoXl: logoXl ? logoXlPath : values?.logoXl,
        favImg: favImg ? favImgPath : values?.favImg,
        fixedValue: values?.fixedValue,
        cateringFixedValue: values?.cateringFixedValue,
        privacy: values?.privacy,
        terms: values?.terms,
        privacyUrl: values?.privacyUrl,
        termsUrl: values?.termsUrl,
        orderingApp: values?.orderingApp,
        loyalty: {
          minSubtotal: 100,
          minRedeemableAmount: 100,
          pointsPerDollar: 100,
          visitThreshold: 10,
          rewardPoints: 1000,
        },
        ihdFees: values.ihdFees,
        ihdFeesValue: values.ihdFeesValue,
        viewablePassword: values?.viewablePassword,
        restaurantUrl: values.restaurantUrl,
        enable2fa: values?.enable2fa,
        disableGuest: values?.disableGuest,
        enableMenuocity: values?.enableMenuocity,
      };
      if (doc.password === "") {
        delete doc.password;
      }

      try {
        if (location?.state?.restaurantId) {
          await updateEntity(location.state.restaurantId, { ...doc });
        } else {
          await create({
            ...doc,
          });
        }
        toast.success(
          location.state.restaurantId
            ? "Restaurant updated successfully"
            : "Restaurant created successfully"
        );
        props.history.goBack();
      } catch (error) {
        logger.push({ error, info: "AddRestaurant" });
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (location.state.restaurantId) {
      findOne(location.state.restaurantId);
    }
  }, [location.state.restaurantId]);

  useEffect(() => {
    if (partner || authContext?.user?._id) {
      findPartner(partner || authContext?.user?._id);
    }
  }, [partner, authContext?.user?._id]);

  console.log(errors);

  useEffect(() => {
    if (entity) {
      setValues({
        name: entity?.data?.restaurant?.name,
        restaurantUrl: entity?.data?.restaurant?.restaurantUrl,
        homeLink: entity?.data?.restaurant?.homeLink,
        useRestaurantUrl: entity?.data?.restaurant?.useRestaurantUrl,
        email: entity?.data?.restaurant?.email,
        phone: entity?.data?.restaurant?.phone,
        address: entity?.data?.restaurant?.address,
        shopEmail: entity?.data?.restaurant?.shopEmail,
        shopPhone: entity?.data?.restaurant?.shopPhone,
        shopTitle: entity?.data?.restaurant?.shopTitle,
        folderName: entity?.data?.restaurant?.folderName,
        state: entity?.data?.restaurant?.state,
        print: entity?.data?.restaurant?.print,
        accountColor: entity?.data?.restaurant?.ordering?.backgroundColor,
        username: entity?.data?.restaurant?.username,
        payment: entity?.data?.restaurant?.payment,
        ihdFees: entity?.data?.restaurant?.ihdFees,
        ihdFeesValue: entity?.data?.restaurant?.ihdFeesValue,
        logo: entity?.data?.restaurant?.logo,
        logoMd: entity?.data?.restaurant?.logoMd,
        logoLg: entity?.data?.restaurant?.logoLg,
        logoXl: entity?.data?.restaurant?.logoXl,
        favImg: entity?.data?.restaurant?.favImg,
        privacy: entity?.data?.restaurant?.privacy || "",
        terms: entity?.data?.restaurant?.terms || "",
        termsUrl: entity?.data?.restaurant?.termsUrl,
        privacyUrl: entity?.data?.restaurant?.privacyUrl,
        orderingApp: values?.orderingApp,
        enableMenuocity: entity?.data?.restaurant?.enableMenuocity,
        enableTaxOnOrderFee:
          entity?.data?.restaurant?.enableTaxOnOrderFee || false,
        enableTaxOnDeliveryFee:
          entity?.data?.restaurant?.enableTaxOnDeliveryFee || false,
        enableGuestPaythere:
          entity?.data?.restaurant?.enableGuestPaythere || false,

        ordering: {
          ...entity?.data?.restaurant?.ordering,
        },
        orderFee: entity?.data?.restaurant?.orderFee,
        firstTierUpto: entity?.data?.restaurant?.tiers?.firstTier?.upto,
        secondTierUpto: entity?.data?.restaurant?.tiers?.secondTier?.upto,
        thirdTierUpto: entity?.data?.restaurant?.tiers?.thirdTier?.upto,
        fourthTierUpto: entity?.data?.restaurant?.tiers?.forthTier?.upto,
        firstTierOnlineOrder:
          entity?.data?.restaurant?.tiers?.firstTier?.onlineOrder,
        secondTierOnlineOrder:
          entity?.data?.restaurant?.tiers?.secondTier?.onlineOrder,
        thirdTierOnlineOrder:
          entity?.data?.restaurant?.tiers?.thirdTier?.onlineOrder,
        fourthTierOnlineOrder:
          entity?.data?.restaurant?.tiers?.forthTier?.onlineOrder,
        fixedValue: entity?.data?.restaurant?.fixedValue || 0,
        cateringOrderFee: entity?.data?.restaurant?.cateringOrderFee,
        cateringFirstTierUpto:
          entity?.data?.restaurant?.cateringTiers?.firstTier?.upto,
        cateringSecondTierUpto:
          entity?.data?.restaurant?.cateringTiers?.secondTier?.upto,
        cateringThirdTierUpto:
          entity?.data?.restaurant?.cateringTiers?.thirdTier?.upto,
        cateringFourthTierUpto:
          entity?.data?.restaurant?.cateringTiers?.forthTier?.upto,
        cateringFirstTierOnlineOrder:
          entity?.data?.restaurant?.cateringTiers?.firstTier?.onlineOrder,
        cateringSecondTierOnlineOrder:
          entity?.data?.restaurant?.cateringTiers?.secondTier?.onlineOrder,
        cateringThirdTierOnlineOrder:
          entity?.data?.restaurant?.cateringTiers?.thirdTier?.onlineOrder,
        cateringFourthTierOnlineOrder:
          entity?.data?.restaurant?.cateringTiers?.forthTier?.onlineOrder,
        cateringFixedValue: entity?.data?.restaurant?.cateringFixedValue || 0,
        enable_deliverect: entity?.data?.restaurant?.enable_deliverect,
        deliverect_client_secret:
          entity?.data.restaurant?.deliverect_client_secret,
        deliverect_client_id: entity?.data.restaurant?.deliverect_client_id,
        url: entity?.data?.restaurant?.url.split("/")[
          entity?.data?.restaurant?.url.split("/").length - 1
        ],
        password: entity?.data?.password,
        viewablePassword: entity?.data?.viewablePassword,
        enable2fa: entity?.data?.restaurant?.enable2fa,
        disableGuest: entity?.data?.restaurant?.disableGuest,
      });
    }
  }, [entity]);

  useEffect(() => {
    let arr = [];
    states?.map((s) => {
      Object.keys(s).map((k) => {
        arr.push({
          label: s[k],
          value: k,
        });
      });
    });
    setOptions(arr);
  }, [states]);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
    }),
  };

  return (
    <div>
      <TimelineHeader
        name={
          location.state.restaurantId ? "Edit Restaurant" : "Add Restaurant"
        }
        parentName="Partners"
        showBtn={true}
        path="/admin/partners/resturants"
      >
        <Button
          className="btn-neutral bg-primary text-white"
          color="default"
          size="md"
          onClick={handleSubmit}
        >
          {isSubmitting ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button
          className="btn-neutral text-black"
          color="default"
          size="sm"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Cancel
        </Button>
      </TimelineHeader>
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Restaurant Information</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-shopping-cart" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            onChange={handleChange("name")}
                            value={values?.name}
                            placeholder="Restaurant Name"
                            className={`form-control ${
                              errors.name && touched.name ? "is-invalid" : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.name && <ErrorLable message={errors?.name} />}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-envelope" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            onChange={handleChange("email")}
                            value={values?.email}
                            placeholder="Email"
                            className={`form-control ${
                              errors.email && touched.email ? "is-invalid" : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.email && <ErrorLable message={errors?.email} />}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-phone" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="number"
                            onChange={handleChange("phone")}
                            value={values?.phone}
                            placeholder="Phone"
                            className={`form-control ${
                              errors.phone && touched.phone ? "is-invalid" : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.phone && <ErrorLable message={errors?.phone} />}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-map-marker"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            onChange={handleChange("address")}
                            value={values?.address}
                            placeholder="Address"
                            className={`form-control ${
                              errors.address && touched.address
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.address && (
                          <ErrorLable message={errors?.address} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-user" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            onChange={handleChange("username")}
                            value={values?.username}
                            placeholder="Username"
                            className={`form-control ${
                              errors.username && touched.username
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.username && (
                          <ErrorLable message={errors?.username} />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" className="my-2">
                      <Row>
                        <Col className={isMobile && "my-2"}>
                          <SecureInputRestaurant
                            icon={"fa fa-lock"}
                            value={values?.password}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value);
                              setFieldValue("viewablePassword", e.target.value);
                            }}
                            viewablePassword={values?.viewablePassword}
                            className={`form-control ${
                              errors.password && touched.password
                                ? "is-invalid"
                                : ""
                            }`}
                            oldViewable={entity?.data?.viewablePassword}
                            setFieldValue={setFieldValue}
                            add={location.state.restaurantId}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row style={{ textAlign: "left" }}>
                        <FormGroup className="d-flex mt-3 mx-3">
                          <Label
                            md={8}
                            className="form-control-label text-left p-0 mb-3 mx-1"
                            htmlFor="example-text-input"
                          >
                            Enable Restaurant URL
                          </Label>
                          <InputGroup className="mx-4">
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                checked={values?.useRestaurantUrl}
                                value={values?.useRestaurantUrl}
                                onChange={(e) => {
                                  setFieldValue("url", "");
                                  handleChange("useRestaurantUrl")(e);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row style={{ textAlign: "left" }}>
                        <FormGroup className="d-flex mt-3 mx-3">
                          <Label
                            md={8}
                            className="form-control-label text-left p-0 mb-3 mx-1"
                            htmlFor="example-text-input"
                          >
                            Enable 2FA
                          </Label>
                          <InputGroup className="mx-4">
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                checked={values?.enable2fa}
                                value={values?.enable2fa}
                                onChange={(e) => {
                                  handleChange("enable2fa")(e);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row style={{ textAlign: "left" }}>
                        <FormGroup className="d-flex mt-3 mx-3">
                          <Label
                            md={8}
                            className="form-control-label text-left p-0 mb-3 mx-1"
                            htmlFor="example-text-input"
                          >
                            Disable Guest Checkout
                          </Label>
                          <InputGroup className="mx-4">
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                checked={values?.disableGuest}
                                value={values?.disableGuest}
                                onChange={(e) => {
                                  handleChange("disableGuest")(e);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </Row>
                    </Col>
                    {/* <Col md="6">
                      <Row style={{ textAlign: "left" }}>
                        <FormGroup className="d-flex mt-3 mx-3">
                          <Label
                            md={8}
                            className="form-control-label text-left p-0 mb-3 mx-1"
                            htmlFor="example-text-input"
                          >
                            Enable Menuocity
                          </Label>
                          <InputGroup className="mx-4">
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                checked={values?.enableMenuocity}
                                value={values?.enableMenuocity}
                                onChange={(e) => {
                                  handleChange("enableMenuocity")(e);
                                }}
                              />
                              <span className="custom-toggle-slider rounded-circle"></span>
                            </label>
                          </InputGroup>
                        </FormGroup>
                      </Row>
                    </Col> */}
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-globe" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Home link"
                            type="text"
                            onChange={handleChange("homeLink")}
                            value={values?.homeLink}
                            className={`form-control ${
                              errors.homeLink && touched.homeLink
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.homeLink && (
                          <ErrorLable message={errors?.homeLink} />
                        )}
                      </FormGroup>
                    </Col>
                    {values.useRestaurantUrl ? (
                      <Col md="6">
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon
                              style={{ width: "70%" }}
                              addonType="prepend"
                            >
                              <Input
                                placeholder="example.com"
                                type="text"
                                onChange={handleChange("restaurantUrl")}
                                value={values?.restaurantUrl}
                                className={`form-control ${
                                  errors.restaurantUrl && touched.restaurantUrl
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <InputGroupText>/ordering/</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="exampleRestaurant"
                              type="text"
                              onChange={handleChange("url")}
                              value={values?.url}
                              className={`form-control ${
                                errors.url && touched.url ? "is-invalid" : ""
                              }`}
                            />
                          </InputGroup>
                          {errors.url && <ErrorLable message={errors?.url} />}
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col md="6">
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                {"http://" +
                                  partnerDoc?.data?.partner?.url +
                                  "/ordering"}
                                /
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="exampleRestaurant"
                              type="text"
                              onChange={handleChange("url")}
                              value={values?.url}
                              className={`form-control ${
                                errors.url && touched.url ? "is-invalid" : ""
                              }`}
                            />
                          </InputGroup>
                          {errors.url && <ErrorLable message={errors?.url} />}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Account Settings</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <Select
                      isSearchable={true}
                      onChange={(e) => setFieldValue("state", e.value)}
                      options={options}
                      placeholder="Select State"
                      value={options.find(
                        (item) => item.value === values.state
                      )}
                      styles={errors?.state && customStyles}
                    />
                    {errors.state && <ErrorLable message={errors?.state} />}
                  </Col>
                  <Col md="6">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Account Color</InputGroupText>
                        <div
                          style={{
                            background: values?.accountColor,
                            height: "100%",
                            width: 20,
                            borderRight: 1,
                          }}
                        ></div>
                      </InputGroupAddon>

                      <Input
                        type="text"
                        onChange={handleChange("accountColor")}
                        value={values?.accountColor}
                        className={`form-control ${
                          errors.accountColor && touched.accountColor
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </InputGroup>
                    {errors.accountColor && (
                      <ErrorLable message={errors?.accountColor} />
                    )}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="6">
                    <Row>
                      <span style={{ textAlign: "left" }} className="mx-4">
                        Logo image
                      </span>
                      <Col
                        md={!logo && location.state.restaurantId ? "6" : "9"}
                        className={isMobile && "my-2"}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ImageUploader
                            withIcon={true}
                            buttonText="Choose images"
                            fileContainerStyle={{
                              elevation: 0,
                              shadow: 0,
                            }}
                            // singleImage
                            onChange={(f) => {
                              setLogo(f[0]);
                              const image = f[0];
                              if (image) {
                                new Compressor(image, {
                                  quality: 0.6,
                                  retainExif: false,
                                  // height: 1000,
                                  success: (compressedResult) => {
                                    setLogo(compressedResult);
                                  },
                                });
                              }
                            }}
                            imgExtension={[
                              ".jpg",
                              ".gif",
                              ".png",
                              ".gif",
                              "webp",
                              "jpeg",
                            ]}
                            maxFileSize={10002880}
                            withPreview
                          />
                        </div>
                      </Col>
                      {!logo && location.state.restaurantId && (
                        <Col
                          md="3"
                          className="row"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="logo"
                            style={{ height: 100, width: 100 }}
                            src={`${entity?.data?.restaurant?.logo}`}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {entity?.data?.restaurant?.showInPartnerApp && (
                    <Col md="6">
                      <Row>
                        <span style={{ textAlign: "left" }} className="mx-4">
                          Logo (111*112)
                        </span>
                        <Col
                          md={
                            !logoMd &&
                            values?.logoMd &&
                            location?.state?.restaurantId
                              ? "6"
                              : "9"
                          }
                          className={isMobile && "my-2"}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ImageUploader
                              withIcon={true}
                              buttonText="Choose images"
                              fileContainerStyle={{
                                elevation: 0,
                                shadow: 0,
                              }}
                              singleImage
                              onChange={(f) => {
                                setLogoMd(f[0]);
                                const image = f[0];
                                if (image) {
                                  new Compressor(image, {
                                    quality: 0.6,
                                    retainExif: false,
                                    // height: 1000,
                                    success: (compressedResult) => {
                                      setLogoMd(compressedResult);
                                    },
                                  });
                                }
                              }}
                              imgExtension={[
                                ".jpg",
                                ".gif",
                                ".png",
                                ".gif",
                                "webp",
                                "jpeg",
                              ]}
                              maxFileSize={10002880}
                              withPreview
                            />
                          </div>
                        </Col>
                        {!logoMd &&
                          values?.logoMd &&
                          location?.state?.restaurantId && (
                            <Col
                              md="3"
                              className="row"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                alt="logo"
                                style={{
                                  height: 100,
                                  width: 100,
                                  objectFit: "contain",
                                }}
                                src={`${entity?.data?.restaurant?.logoMd}`}
                              />
                            </Col>
                          )}
                      </Row>
                    </Col>
                  )}
                </Row>
                {entity?.data?.restaurant?.showInPartnerApp && (
                  <>
                    <Row className="mt-4">
                      <Col md="6">
                        <Row>
                          <span style={{ textAlign: "left" }} className="mx-4">
                            Logo (111*160)
                          </span>
                          <Col
                            md={
                              !logoLg &&
                              values?.logoLg &&
                              location.state.restaurantId
                                ? "6"
                                : "9"
                            }
                            className={isMobile && "my-2"}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                fileContainerStyle={{
                                  elevation: 0,
                                  shadow: 0,
                                }}
                                singleImage
                                onChange={(f) => {
                                  setLogoLg(f[0]);
                                  const image = f[0];
                                  if (image) {
                                    new Compressor(image, {
                                      quality: 0.6,
                                      retainExif: false,
                                      // height: 1000,
                                      success: (compressedResult) => {
                                        setLogoLg(compressedResult);
                                      },
                                    });
                                  }
                                }}
                                imgExtension={[
                                  ".jpg",
                                  ".gif",
                                  ".png",
                                  ".gif",
                                  "webp",
                                  "jpeg",
                                ]}
                                maxFileSize={10002880}
                                withPreview
                              />
                            </div>
                          </Col>
                          {!logoLg &&
                            values?.logoLg &&
                            location.state.restaurantId && (
                              <Col
                                md="3"
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="logo"
                                  style={{
                                    height: 100,
                                    width: 100,
                                    objectFit: "contain",
                                  }}
                                  src={`${values?.logoLg}`}
                                />
                              </Col>
                            )}
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <span style={{ textAlign: "left" }} className="mx-4">
                            Logo (111*222)
                          </span>
                          <Col
                            md={
                              !logoXl &&
                              values?.logoXl &&
                              location.state.restaurantId
                                ? "6"
                                : "9"
                            }
                            className={isMobile && "my-2"}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                fileContainerStyle={{
                                  elevation: 0,
                                  shadow: 0,
                                }}
                                singleImage
                                onChange={(f) => {
                                  setLogoXl(f[0]);
                                  const image = f[0];
                                  if (image) {
                                    new Compressor(image, {
                                      quality: 0.6,
                                      retainExif: false,
                                      // height: 1000,
                                      success: (compressedResult) => {
                                        setLogoXl(compressedResult);
                                      },
                                    });
                                  }
                                }}
                                imgExtension={[
                                  ".jpg",
                                  ".gif",
                                  ".png",
                                  ".gif",
                                  "webp",
                                  "jpeg",
                                ]}
                                maxFileSize={10002880}
                                withPreview
                              />
                            </div>
                          </Col>
                          {!logoXl &&
                            values?.logoXl &&
                            location.state.restaurantId && (
                              <Col
                                md="3"
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="logo"
                                  style={{
                                    height: 100,
                                    width: 100,
                                    objectFit: "contain",
                                  }}
                                  src={`${values?.logoXl}`}
                                />
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Row>
                          <span style={{ textAlign: "left" }} className="mx-4">
                            Favorite Graphic
                          </span>
                          <Col
                            md={
                              !favImg &&
                              values?.favImg &&
                              location.state.restaurantId
                                ? "6"
                                : "9"
                            }
                            className={isMobile && "my-2"}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                fileContainerStyle={{
                                  elevation: 0,
                                  shadow: 0,
                                }}
                                singleImage
                                onChange={(f) => {
                                  setFavImg(f[0]);
                                  const image = f[0];
                                  if (image) {
                                    new Compressor(image, {
                                      quality: 0.6,
                                      retainExif: false,
                                      // height: 1000,
                                      success: (compressedResult) => {
                                        setFavImg(compressedResult);
                                      },
                                    });
                                  }
                                }}
                                imgExtension={[
                                  ".jpg",
                                  ".gif",
                                  ".png",
                                  ".gif",
                                  "webp",
                                  "jpeg",
                                ]}
                                maxFileSize={10002880}
                                withPreview
                              />
                            </div>
                          </Col>
                          {!favImg &&
                            values?.favImg &&
                            location.state.restaurantId && (
                              <Col
                                md="3"
                                className="row"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  alt="logo"
                                  style={{
                                    height: 100,
                                    width: 100,
                                    objectFit: "contain",
                                  }}
                                  src={`${values?.favImg}`}
                                />
                              </Col>
                            )}
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col md="6" className="mt-3">
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-credit-card-alt" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          onChange={handleChange("payment")}
                          value={values?.payment}
                          placeholder="Payment"
                          className={`form-control ${
                            errors.payment && touched.payment
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Payment</option>
                          <option value="both">Both</option>
                          <option value="online">Online</option>
                          <option value="pay-there">Pay There</option>
                        </Input>
                      </InputGroup>
                      {errors.payment && (
                        <ErrorLable message={errors?.payment} />
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6" className="mt-3">
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>IHD Fees</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          onChange={handleChange("ihdFees")}
                          value={values?.ihdFees}
                          className={`form-control ${
                            errors.ihdFees && touched.ihdFees
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="restaurant">Restaurant</option>
                          <option value="partner">Partner</option>
                        </Input>
                      </InputGroup>
                      {errors.ihdFees && (
                        <ErrorLable message={errors?.ihdFees} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" className="mt-3 mx-3">
                    <Row style={{ textAlign: "left" }}>
                      <FormGroup className="d-flex">
                        <Label
                          md={8}
                          className="form-control-label text-left p-0  text-sm"
                          htmlFor="example-text-input"
                        >
                          Allow Pay There for Guest
                        </Label>
                        <InputGroup className="mx-4">
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              checked={values?.enableGuestPaythere}
                              value={values?.enableGuestPaythere}
                              onChange={handleChange("enableGuestPaythere")}
                            />
                            <span className="custom-toggle-slider rounded-circle"></span>
                          </label>
                        </InputGroup>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-3">
                    <FormGroup>
                      <Label
                        md={12}
                        className="form-control-label text-left mb-2 p-0  text-sm"
                        htmlFor="example-text-input"
                      >
                        Order Fee
                      </Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-credit-card-alt" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          onChange={handleChange("orderFee")}
                          value={values?.orderFee}
                          className={`form-control ${
                            errors.orderFee && touched.orderFee
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Order Fee</option>
                          <option value="Fixed">Fixed</option>
                          <option value="Tiered">Tiered</option>
                        </Input>
                      </InputGroup>
                      {errors.orderFee && (
                        <ErrorLable message={errors?.orderFee} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" className="mt-3">
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-money" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Dynamic Fee - (Default - $1.5)"
                          type="number"
                          onChange={handleChange("ihdFeesValue")}
                          value={values?.ihdFeesValue}
                          className={`form-control ${
                            errors.ihdFeesValue && touched.ihdFeesValue
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.ihdFeesValue && (
                        <ErrorLable message={errors?.ihdFeesValue} />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                {values.orderFee === "Tiered" && (
                  <TieredInput
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    values={values}
                  />
                )}
                <Row>
                  <Col md="6">
                    {values.orderFee === "Fixed" && (
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="number"
                            onChange={handleChange("fixedValue")}
                            value={values?.fixedValue}
                            placeholder="Order Fee"
                            className={`form-control ${
                              errors.fixedValue && touched.fixedValue
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.fixedValue && (
                          <ErrorLable message={errors?.fixedValue} />
                        )}
                      </FormGroup>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md="6" className="mt-3">
                    <FormGroup>
                      <Label
                        md={12}
                        className="form-control-label text-left mb-2 p-0  text-sm"
                        htmlFor="example-text-input"
                      >
                        Catering Order Fee
                      </Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-credit-card-alt" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          onChange={handleChange("cateringOrderFee")}
                          value={values?.cateringOrderFee}
                          className={`form-control ${
                            errors.cateringOrderFee && touched.cateringOrderFee
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Catering Order Fee</option>
                          <option value="Fixed">Fixed</option>
                          <option value="Tiered">Tiered</option>
                        </Input>
                      </InputGroup>
                      {errors.cateringOrderFee && (
                        <ErrorLable message={errors?.cateringOrderFee} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <Row style={{ textAlign: "left" }}>
                      <FormGroup className="d-flex mt-3 mx-3">
                        <Label
                          md={8}
                          className="form-control-label text-left p-0 mb-3 mx-1"
                          htmlFor="example-text-input"
                        >
                          Enable Tax On Order Fee
                        </Label>
                        <InputGroup className="mx-4">
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              checked={values?.enableTaxOnOrderFee}
                              value={values?.enableTaxOnOrderFee}
                              onChange={handleChange("enableTaxOnOrderFee")}
                            />
                            <span className="custom-toggle-slider rounded-circle"></span>
                          </label>
                        </InputGroup>
                      </FormGroup>
                    </Row>
                    <Row style={{ textAlign: "left" }}>
                      <FormGroup className="d-flex mt-3 mx-3">
                        <Label
                          md={8}
                          className="form-control-label text-left p-0 mb-3 mx-1"
                          htmlFor="example-text-input"
                        >
                          Enable Tax On Delivery Fee
                        </Label>
                        <InputGroup className="mx-4">
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              checked={values?.enableTaxOnDeliveryFee}
                              value={values?.enableTaxOnDeliveryFee}
                              onChange={handleChange("enableTaxOnDeliveryFee")}
                            />
                            <span className="custom-toggle-slider rounded-circle"></span>
                          </label>
                        </InputGroup>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                {values.cateringOrderFee === "Tiered" && (
                  <TieredInput
                    catering={true}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    values={values}
                  />
                )}
                <Row>
                  <Col md="6">
                    {values.cateringOrderFee === "Fixed" && (
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="number"
                            onChange={handleChange("cateringFixedValue")}
                            value={values?.cateringFixedValue}
                            placeholder="Catering Order Fee"
                            className={`form-control ${
                              errors.cateringFixedValue &&
                              touched.cateringFixedValue
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                        {errors.cateringFixedValue && (
                          <ErrorLable message={errors?.cateringFixedValue} />
                        )}
                      </FormGroup>
                    )}
                  </Col>
                </Row>

                <Row style={{ textAlign: "left" }}>
                  <FormGroup className="d-flex mt-3 mx-3">
                    <Label
                      className="form-control-label text-left p-0 mb-3 mx-1"
                      htmlFor="example-text-input"
                    >
                      Deliverect
                    </Label>
                    <InputGroup className="mx-4">
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          checked={values?.enable_deliverect}
                          value={values?.enable_deliverect}
                          onChange={handleChange("enable_deliverect")}
                        />
                        <span className="custom-toggle-slider rounded-circle"></span>
                      </label>
                    </InputGroup>
                  </FormGroup>
                </Row>

                {values?.enable_deliverect && (
                  <Row style={{ textAlign: "left" }}>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>Cleint ID</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            disabled={!values?.enable_deliverect}
                            onChange={handleChange}
                            value={values.deliverect_client_id}
                            name="deliverect_client_id"
                            className={`form-control ${
                              errors.deliverect_client_id &&
                              touched.deliverect_client_id
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>Client Secret</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            disabled={!values?.enable_deliverect}
                            name="deliverect_client_secret"
                            onChange={handleChange("deliverect_client_secret")}
                            value={values.deliverect_client_secret}
                            className={`form-control ${
                              errors.deliverect_client_secret &&
                              touched.deliverect_client_secret
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <div className="card-wrapper ">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Privacy & Terms</h3>
                </CardHeader>
                <CardBody>
                  <Privacy
                    errors
                    touched
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddRestaurant;
