/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import useScreenSize from "../../../../hooks/useScreenSize";
import useOrdering from "../../../../store/ordering";
import LocationSearchInputCatering from "../../components/LocationSearchInputCatering";

export default function DetailsPanel({
  theme,
  history,
  showNav = false,
  whiteBg = false,
  origin = "catering",
}) {
  const [error, setError] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [maxHeadCountError, setMaxHeadCountError] = useState(false);
  const [maxHeadCount, setMaxHeadCount] = useState();
  const { width } = useScreenSize();

  const {
    setCateringData: setZustandCateringData,
    location,
    setMethod,
    setDeliveryZone,
    setDeliveryMethod,
    setTiming,
    setLaterDate,
    setLaterTime,
    cateringData: zustandCateringData,
  } = useOrdering();

  const [cateringData, setCateringData] = useState({
    method: "pickup",
    address: "",
    count: "",
    date: "",
    time: "",
    occasion: "",
    enquiry: false,
    apartment: "",
  });

  const styleActive = {
    padding: 10,
    backgroundColor: theme.primary,
    color: "#fff",
    borderRadius: 5,
    cursor: "pointer",
    flex: 1,
    textAlign: "center",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  };

  const styleInactive = {
    padding: 10,
    backgroundColor: "#ececec",
    color: "#000",
    borderRadius: 5,
    cursor: "pointer",
    flex: 1,
    textAlign: "center",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  };

  function isPointInPolygon(latLng, geofencing) {
    const coordinate = new window.google.maps.LatLng(latLng[0], latLng[1]);
    const polygon = new window.google.maps.Polygon({ paths: geofencing });
    const result = window.google.maps.geometry.poly.containsLocation(
      coordinate,
      polygon
    );

    return result;
  }

  const { find, entities } = useEntity("eat/ordering/catering-slots");

  const {
    find: findTimeSlots,
    entities: timeSlots,
    error: prepTimeError,
  } = useEntity("eat/ordering/catering-date-slots");

  useEffect(() => {
    if (cateringData?.method && cateringData?.count > 0) {
      find({
        locationId: location?._id,
        method: cateringData?.method,
        count: cateringData?.count,
      });
    }
  }, [cateringData]);

  useEffect(() => {
    try {
      if (cateringData?.count > 0) {
        findTimeSlots({
          date: new Date(cateringData?.date),
          locationId: location?._id,
          method: cateringData?.method,
          count: cateringData?.count,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [cateringData]);

  useEffect(() => {
    setCateringData({
      method:
        (zustandCateringData?.method === "" || !zustandCateringData?.method) &&
        location?.cateringMethod === "both"
          ? "pickup"
          : location?.cateringMethod === "pickup"
          ? "pickup"
          : location?.cateringMethod === "delivery"
          ? "delivery"
          : zustandCateringData?.method,
      address:
        (location?.cateringMethod === "both" ||
          location?.cateringMethod === "pickup") &&
        (zustandCateringData?.method === "pickup" ||
          zustandCateringData?.method === "" ||
          !zustandCateringData?.method)
          ? location?.displayAddress ||
            cateringData.address ||
            zustandCateringData?.address ||
            ""
          : origin !== "catering"
          ? zustandCateringData?.address || ""
          : cateringData?.address || "",
      count:
        zustandCateringData?.count === "" ? "" : zustandCateringData?.count,
      date: zustandCateringData?.date,
      time: zustandCateringData?.time,
      occasion: zustandCateringData?.occasion,
      enquiry: zustandCateringData?.enquiry,
      apartment: zustandCateringData?.apartment,
    });
  }, [zustandCateringData, location]);

  useEffect(() => {
    if (location?.cateringPrepTimes) {
      const maxHeadCount = Math.max(
        ...location?.cateringPrepTimes?.map((obj) => parseInt(obj.maxHeadCount))
      );

      setMaxHeadCount(maxHeadCount);

      if (cateringData?.count > maxHeadCount) {
        setMaxHeadCountError(true);
      } else setMaxHeadCountError(false);
    }
  }, [location, cateringData?.count]);

  if (!showNav) {
    return (
      <Card
        style={{
          backgroundColor: whiteBg ? "#fff" : "#F5F6F8",
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: width < 500 && origin !== "menu" ? 70 : 0,
          paddingTop: !showNav ? 0 : 0,
        }}
      >
        <Row className="p-1 mt-1">
          <Col md={4}>
            <div>
              <div className="ml-2">
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left ml-1"
                >
                  Method
                </h6>
              </div>
              <div
                className="ml-2 mb-3"
                style={{
                  borderRadius: 10,
                  display: "flex",
                }}
              >
                {(location?.cateringMethod === "delivery" ||
                  location?.cateringMethod === "both") && (
                  <div
                    className="px-3"
                    style={
                      cateringData.method === "delivery"
                        ? styleActive
                        : styleInactive
                    }
                    onClick={() => {
                      setCateringData({
                        ...cateringData,
                        method: "delivery",
                        address: "",
                      });
                      setZustandCateringData({
                        ...zustandCateringData,
                        ...cateringData,
                        method: "delivery",
                        address: "",
                      });
                      setError(null);
                    }}
                  >
                    Delivery
                  </div>
                )}
                {(location?.cateringMethod === "pickup" ||
                  location?.cateringMethod === "both") && (
                  <div
                    className="px-3"
                    style={
                      cateringData.method === "pickup"
                        ? styleActive
                        : styleInactive
                    }
                    onClick={() => {
                      setCateringData({
                        ...cateringData,
                        method: "pickup",
                        address: location?.displayAddress,
                      });
                      setZustandCateringData({
                        ...zustandCateringData,
                        ...cateringData,
                        method: "pickup",
                        address: location?.displayAddress,
                      });
                      setError(null);
                    }}
                  >
                    Pickup
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <h6
                style={{
                  fontSize: "16px",
                  color: theme.primary,
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
                className="text-uppercase  text-left"
              >
                Address
              </h6>
            </div>
            <div className="mt-1 mb-3">
              <LocationSearchInputCatering
                value={cateringData?.address}
                onChangeAddress={(addr) => {
                  const coordinates = {
                    lat: addr[0]?.geometry?.location?.lat(),
                    lng: addr[0]?.geometry?.location?.lng(),
                  };

                  const finalCords = Object.values(coordinates);

                  const zones = location?.cateringDeliveryZones;

                  const zone = zones?.find((zone) => {
                    return isPointInPolygon(finalCords, zone.geofencing);
                  });

                  if (!zone) {
                    setError(true);
                    swal("Error", "Out of delivery zone", "error");
                    setCateringData({ ...cateringData, address: "" });
                    return;
                  }

                  setError(false);
                  setCateringData({
                    ...cateringData,
                    address: addr[0]?.formatted_address,
                  });

                  setZustandCateringData({
                    ...cateringData,
                    ...zustandCateringData,
                    address: addr[0]?.formatted_address,
                  });

                  setDeliveryZone(zone);
                  setDeliveryMethod("native");
                }}
                disabled={cateringData.method === "pickup" ? true : false}
              />
            </div>
          </Col>
          <Col md={2}>
            <div>
              <h6
                style={{
                  fontSize: "16px",
                  color: theme.primary,
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
                className="text-uppercase  text-left ml-1"
              >
                Ste / Ap
              </h6>
            </div>
            <div className="mt-1 mb-3">
              <Input
                type="text"
                value={cateringData?.apartment}
                disabled={cateringData.method === "pickup" ? true : false}
                onChange={(e) => {
                  setCateringData({
                    ...cateringData,
                    apartment: e.target.value,
                  });

                  setZustandCateringData({
                    ...zustandCateringData,
                    ...cateringData,

                    apartment: e.target.value,
                  });
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="p-2" style={{ alignItems: "center" }}>
          <Col md={2}>
            <div className="ml-1">
              <h6
                style={{
                  fontSize: "16px",
                  color: theme.primary,
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
                className="text-uppercase  text-left"
              >
                Count
              </h6>
            </div>
            <div className="mt-1 mb-3 ml-1">
              <Input
                type="text"
                value={cateringData.count}
                onKeyDown={(event) => {
                  if (isNaN(event.key) && event.key !== "Backspace") {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setCateringData({
                    ...cateringData,
                    count: e.target.value,
                  });

                  setZustandCateringData({
                    ...cateringData,
                    ...zustandCateringData,
                    count: e.target.value,
                  });
                }}
              />
              {Number(cateringData.count) < location?.cateringMinHeadCount && (
                <span className="text-sm text-red">
                  Minimum: {location?.cateringMinHeadCount}
                </span>
              )}
              {maxHeadCountError && (
                <span className="text-sm text-red">
                  Maximum: {maxHeadCount}
                </span>
              )}
              {!maxHeadCountError &&
                !(
                  Number(cateringData.count) < location?.cateringMinHeadCount
                ) && <div style={{ height: 24 }}></div>}
            </div>
          </Col>
          <Col md={2} xs={6}>
            <div>
              <h6
                style={{
                  fontSize: "16px",
                  color: theme.primary,
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
                className="text-uppercase  text-left ml-1"
              >
                DATE
              </h6>
            </div>
            <div className="mt-1 mb-3">
              <Input
                type="select"
                disabled={!cateringData.count}
                onChange={(e) => {
                  setCateringData({
                    ...cateringData,
                    date: e.target.value,
                  });
                }}
                value={cateringData.date}
              >
                <option value={""}>Select Date</option>
                {entities?.data?.map((date) => {
                  return (
                    <option value={date.toString()}>
                      {moment(date).format("ddd DD")}
                    </option>
                  );
                })}
              </Input>
              {<div style={{ height: 24 }}></div>}
            </div>
          </Col>
          <Col md={2} xs={6}>
            <div>
              <h6
                style={{
                  fontSize: "16px",
                  color: theme.primary,
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
                className="text-uppercase  text-left ml-1"
              >
                TIME
              </h6>
            </div>
            <div className="mt-1 mb-3">
              <Input
                type="select"
                disabled={!cateringData.count}
                value={cateringData?.time}
                onChange={(e) => {
                  setCateringData({
                    ...cateringData,
                    time: e.target.value,
                  });
                }}
              >
                <option value={""}>Select Time</option>
                {timeSlots?.data?.map((date) => {
                  const momentTime = moment(date, "HH:mm").format("h:mm A");

                  return <option value={date}>{momentTime}</option>;
                })}
              </Input>
              {prepTimeError && (
                <small className="text-red text-sm ">
                  Invalid count or date
                </small>
              )}
              {!prepTimeError && <div style={{ height: 24 }}></div>}
            </div>
          </Col>
          <Col md={5}>
            <div>
              <h6
                style={{
                  fontSize: "16px",
                  color: theme.primary,
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
                className="text-uppercase  text-left"
              >
                Occasion
              </h6>
            </div>
            <div
              className="mt-1 mb-3 "
              style={{ width: width < 500 ? "100%" : "80%" }}
            >
              <Input
                type="select"
                value={cateringData.occasion}
                onChange={(e) =>
                  setCateringData({
                    ...cateringData,
                    occasion: e.target.value,
                  })
                }
              >
                <option value={""}>Select Occasion</option>
                <option value={"anniversary"}>Anniversary</option>
                <option value={"birthdayParty"}>Birthday Party</option>
                <option value={"christmasParty"}>Christmas Party</option>
                <option value={"corporateEvent"}>Corporate Event</option>
                <option value={"donation"}>Donation</option>
                <option value={"engagementParty"}>Engagement Party</option>
                <option value={"fundRaiser"}>Fund Raiser</option>
                <option value={"funeral"}>Funeral</option>
                <option value={"graduation"}>Graduation</option>
                <option value={"grandOpening"}>Grand Opening</option>
                <option value={"meeting"}>Meeting</option>
                <option value={"houseParty"}>House Party</option>
                <option value={"reception"}>Reception</option>
                <option value={"rehearsalDinner"}>Rehearsal Dinner</option>
                <option value={"repLunch"}>Rep Lunch</option>
                <option value={"retirement"}>Retirement</option>
                <option value={"wedding"}>Wedding</option>
                <option value={"other"}>Other</option>
              </Input>
              {<div style={{ height: 24 }}></div>}
            </div>
          </Col>

          <Col
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              block={width <= 500}
              className="mt--2"
              onClick={() => {
                if (
                  cateringData?.method === "delivery" &&
                  cateringData?.address === ""
                ) {
                  toast.error("Address can't be empty");
                  return;
                }
                if (cateringData?.count < location?.cateringMinHeadCount) {
                  toast.error(
                    "Minimum count must be " + location?.cateringMinHeadCount
                  );
                  return;
                }

                if (maxHeadCountError) {
                  toast.error("Maximum count must be" + " " + maxHeadCount);
                  return;
                }
                if (!cateringData?.date) {
                  toast.error("Date can't be empty");
                  return;
                }

                if (!cateringData?.time) {
                  toast.error("Time can't be empty");
                  return;
                }

                setZustandCateringData({
                  ...zustandCateringData,
                  ...cateringData,
                  catering: true,
                });

                setTiming("later");

                setLaterDate(cateringData?.date);

                setLaterTime(cateringData?.time.split(" ")[0]);

                setMethod(cateringData?.method);

                history.push("menu", { fromFlow: true });
              }}
              disabled={error}
              style={{
                backgroundColor: theme.primary,
                color: "white",
                width: width < 500 && "100%",
              }}
            >
              SUBMIT
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <ListGroup className={width < 500 ? "tal mt--4" : "tal mt-4"} flush>
      <ListGroupItem
        className="list-group-item-action"
        id="linkToggler"
        tag="a"
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      >
        <Row className="align-items-center">
          <div className="col ml--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-0 text-md">Catering Options</h4>
              </div>
              <div className="text-right text-muted">
                {isOpened ? (
                  <i className="fa fa-chevron-up"></i>
                ) : (
                  <i className="fa fa-chevron-down"></i>
                )}
              </div>
            </div>
          </div>
        </Row>
      </ListGroupItem>
      <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
        <Card
          style={{
            backgroundColor: whiteBg ? "#fff" : "#F5F6F8",
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: width < 500 && origin === "catering" ? 70 : 0,
            paddingTop: !showNav ? 0 : 0,
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <Row className="p-1 mt-1">
            <Col md={6}>
              <div>
                <div className="ml-2">
                  <h6
                    style={{
                      fontSize: "16px",
                      color: theme.primary,
                      fontWeight: "600",
                      letterSpacing: "0.1em",
                    }}
                    className="text-uppercase  text-left ml-1"
                  >
                    Method
                  </h6>
                </div>
                <div
                  className="ml-2 mb-3"
                  style={{
                    borderRadius: 10,
                    display: "flex",
                  }}
                >
                  {(location?.cateringMethod === "delivery" ||
                    location?.cateringMethod === "both") && (
                    <div
                      className="px-3"
                      style={
                        cateringData.method === "delivery"
                          ? styleActive
                          : styleInactive
                      }
                      onClick={() => {
                        setCateringData({
                          ...cateringData,
                          method: "delivery",
                          address: "",
                        });
                        setZustandCateringData({
                          ...zustandCateringData,
                          ...cateringData,
                          method: "delivery",
                          address: "",
                        });
                        setError(null);
                      }}
                    >
                      Delivery
                    </div>
                  )}
                  {(location?.cateringMethod === "pickup" ||
                    location?.cateringMethod === "both") && (
                    <div
                      className="px-3"
                      style={
                        cateringData.method === "pickup"
                          ? styleActive
                          : styleInactive
                      }
                      onClick={() => {
                        setCateringData({
                          ...cateringData,
                          method: "pickup",
                          address: location?.displayAddress,
                        });
                        setZustandCateringData({
                          ...zustandCateringData,
                          ...cateringData,
                          method: "pickup",
                          address: location?.displayAddress,
                        });
                        setError(null);
                      }}
                    >
                      Pickup
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left ml-1"
                >
                  Address
                </h6>
              </div>
              <div className="mt-1 mb-3">
                <LocationSearchInputCatering
                  value={cateringData?.address || ""}
                  onChangeAddress={(addr) => {
                    const coordinates = {
                      lat: addr[0]?.geometry?.location?.lat(),
                      lng: addr[0]?.geometry?.location?.lng(),
                    };

                    const finalCords = Object.values(coordinates);

                    const zones = location?.cateringDeliveryZones;

                    const zone = zones?.find((zone) => {
                      return isPointInPolygon(finalCords, zone.geofencing);
                    });

                    if (!zone) {
                      setError(true);
                      swal("Error", "Out of delivery zone", "error");
                      setCateringData({ ...cateringData, address: "" });
                      return;
                    }

                    setError(false);
                    setCateringData({
                      ...cateringData,
                      address: addr[0]?.formatted_address,
                    });

                    setZustandCateringData({
                      ...cateringData,
                      ...zustandCateringData,
                      address: addr[0]?.formatted_address,
                    });
                    setDeliveryZone(zone);
                    setDeliveryMethod("native");
                  }}
                  disabled={cateringData.method === "pickup" ? true : false}
                />
              </div>
            </Col>
            <Col md={12}>
              <div>
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left ml-1"
                >
                  Ste / Ap
                </h6>
              </div>
              <div className="mt-1 mb-3">
                <Input
                  type="text"
                  value={cateringData?.apartment}
                  disabled={cateringData.method === "pickup" ? true : false}
                  onChange={(e) => {
                    setCateringData({
                      ...cateringData,
                      apartment: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="ml-1">
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left"
                >
                  Count
                </h6>
              </div>
              <div className="mt-1 mb-3 ml-1">
                <Input
                  type="number"
                  value={cateringData.count}
                  onChange={(e) => {
                    setCateringData({
                      ...cateringData,
                      count: e.target.value,
                    });

                    setZustandCateringData({
                      ...cateringData,
                      ...zustandCateringData,
                      count: e.target.value,
                    });
                  }}
                />
                {Number(cateringData.count) <
                  location?.cateringMinHeadCount && (
                  <span className="text-sm text-red">
                    Minimum: {location?.cateringMinHeadCount}
                  </span>
                )}
                {maxHeadCountError && (
                  <span className="text-sm text-red">
                    Maximum: {maxHeadCount}
                  </span>
                )}
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left ml-1"
                >
                  DATE
                </h6>
              </div>
              <div className="mt-1 mb-3">
                <Input
                  type="select"
                  onChange={(e) => {
                    setCateringData({
                      ...cateringData,
                      date: e.target.value,
                      time: "",
                    });
                    setZustandCateringData({
                      ...cateringData,
                      ...zustandCateringData,
                      date: e.target.value,
                      time: "",
                    });
                  }}
                  value={entities?.data?.find(
                    (d) =>
                      moment(d).format("DD/MM/YYYY") ===
                      moment(zustandCateringData?.date).format("DD/MM/YYYY")
                  )}
                >
                  <option value={""}>Select Date</option>
                  {entities?.data?.map((date) => {
                    return (
                      <option value={date.toString()}>
                        {moment(date).format("ddd DD")}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div>
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left ml-1"
                >
                  TIME
                </h6>
              </div>
              <div className="mt-1 mb-3">
                <Input
                  type="select"
                  value={cateringData.time}
                  onChange={(e) =>
                    setCateringData({
                      ...cateringData,

                      time: e.target.value.split(" ")[0],
                    })
                  }
                >
                  <option value={""}>Select Time</option>
                  {timeSlots?.data?.map((date) => {
                    const momentTime = moment(date, "HH:mm").format("h:mm A");
                    return <option value={date}>{momentTime}</option>;
                  })}
                </Input>
                {prepTimeError && (
                  <small color="red">Invalid count or date</small>
                )}
              </div>
            </Col>
            <Col md={8}>
              <div>
                <h6
                  style={{
                    fontSize: "16px",
                    color: theme.primary,
                    fontWeight: "600",
                    letterSpacing: "0.1em",
                  }}
                  className="text-uppercase  text-left ml-1"
                >
                  Occasion
                </h6>
              </div>
              <div
                className="mt-1 mb-3 "
                style={{ width: width < 500 ? "100%" : "80%" }}
              >
                <Input
                  type="select"
                  value={cateringData.occasion}
                  onChange={(e) =>
                    setCateringData({
                      ...cateringData,
                      occasion: e.target.value,
                    })
                  }
                >
                  <option value={""}>Select Occasion</option>
                  <option value={"anniversary"}>Anniversary</option>
                  <option value={"birthdayParty"}>Birthday Party</option>
                  <option value={"christmasParty"}>Christmas Party</option>
                  <option value={"corporateEvent"}>Corporate Event</option>
                  <option value={"donation"}>Donation</option>
                  <option value={"engagementParty"}>Engagement Party</option>
                  <option value={"fundRaiser"}>Fund Raiser</option>
                  <option value={"funeral"}>Funeral</option>
                  <option value={"graduation"}>Graduation</option>
                  <option value={"grandOpening"}>Grand Opening</option>
                  <option value={"meeting"}>Meeting</option>
                  <option value={"houseParty"}>House Party</option>
                  <option value={"reception"}>Reception</option>
                  <option value={"rehearsalDinner"}>Rehearsal Dinner</option>
                  <option value={"repLunch"}>Rep Lunch</option>
                  <option value={"retirement"}>Retirement</option>
                  <option value={"wedding"}>Wedding</option>
                  <option value={"other"}>Other</option>
                </Input>
              </div>
            </Col>

            <Col
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                className="mt-3"
                onClick={() => {
                  if (
                    cateringData?.method === "delivery" &&
                    cateringData?.address === ""
                  ) {
                    toast.error("Address can't be empty");
                    return;
                  }
                  if (cateringData?.count < location?.cateringMinHeadCount) {
                    toast.error(
                      "Minimum count must be " + location?.cateringMinHeadCount
                    );
                    return;
                  }

                  if (maxHeadCountError) {
                    toast.error("Maximum count must be" + " " + maxHeadCount);
                    return;
                  }
                  if (!cateringData?.date) {
                    toast.error("Date can't be empty");
                    return;
                  }

                  if (!cateringData?.time) {
                    toast.error("Time can't be empty");
                    return;
                  }

                  setZustandCateringData({
                    ...zustandCateringData,
                    ...cateringData,
                    catering: true,
                  });

                  setTiming("later");

                  setLaterDate(cateringData?.date);

                  setLaterTime(cateringData?.time.split(" ")[0]);

                  setMethod(cateringData?.method);

                  history.push("menu", { fromFlow: true });
                }}
                disabled={error}
                style={{
                  backgroundColor: theme.primary,
                  color: "white",
                  width: width < 500 && "100%",
                }}
              >
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Card>
        {/* {isMobileOnly && <Footer />} */}
        {/* {isMobileOnly && <div style={{ height: 200 }}></div>} */}
      </UncontrolledCollapse>
    </ListGroup>
  );
}
