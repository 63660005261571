/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";

// reactstrap components
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import ConfirmModal from "../../../components/ConfirmModal";
// core components
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import SuperTable from "../../../widgets/SuperTable";
import TableWrapper from "../../../widgets/TableWrapper";
import FilterModal from "./components/FilterModal";
import TableRowOrders from "./components/TableRowOrders";

function Orders(props) {
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);

  const { restaurant } = useAdminStore();
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { entities, find, loading } = useEntity("order");

  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterData, setFilterData] = useState({});
  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      limit: rowsPerPage,
      page: page,
      ...filterData,
      orderType: tabs === 1 ? "all" : "ExternalOrder",
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
  }, [filterData, page, rowsPerPage, tabs]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = [
    "ID",
    "Customer",
    "Status",
    "Total",
    "Method",
    "Location Name",
    "Date Added",
    "Action",
  ];

  return (
    <div>
      <ConfirmModal open={open} toggle={() => setOpen(!open)} />
      <SimpleHeader
        path="/resturants"
        name="Orders"
        parentName="Dashboard"
        showBtn={true}
      >
        <FilterModal
          onChangeData={(data) => {
            setFilterData(data);
          }}
        />
      </SimpleHeader>

      <TableWrapper customStyles="px-2">
        <Nav
          className="nav-fill flex-column flex-md-row mb-3"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Native
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              External
            </NavLink>
          </NavItem>
        </Nav>
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRowOrders}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no Orders"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </div>
  );
}

export default Orders;
