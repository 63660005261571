/* eslint-disable no-unused-expressions */
// reactstrap components
import { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import TableWrapper from "../../../widgets/TableWrapper";
import { useFormik } from "formik";
import AvailablityMenuModal from "./tabs/AvailablityMenuModal";
import EmptyPlaceholder from "../../ordering/menu/components/EmptyPlaceholder";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import usePartnerId from "../../../hooks/usePartnerId";
import CopyMenuModal from "./components/CopyMenuModal";
import ErrorLable from "../../../utils/ErrorLable";
import ItemEditModal from "./components/ItemEditModal";
import AddNewItemModal from "./components/AddNewItemModal";
import AddNewCategoryModal from "./components/AddNewCategoryModal";
import GroupCatModal from "./components/GroupCatModal";

export default function MenuManagement(props) {
  const { restaurant } = useAdminStore();
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const [openModal, setOpenModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openItemEdit, setOpenItemEdit] = useState(false);
  const [openItemAdd, setOpenItemAdd] = useState(false);
  const [openCatAdd, setOpenCatAdd] = useState(false);
  const [openGroupCat, setOpenGroupCat] = useState(false);
  const [categoryQuery, setCategoryQuery] = useState("");
  const [productQuery, setProductQuery] = useState("");
  const [selectedGroupCategory, setSelectedGroupCategory] = useState("other");
  const [catIdsFiltered, setCatIdsFiltered] = useState([]);
  const [groupCatIds, setGroupCatIds] = useState([]);
  const [activeCats, setActiveCats] = useState([]);
  const [otherCats, setOtherCats] = useState(false);
  const [selected, setSelected] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [index, setIndex] = useState(-1);
  const [itemIndex, setItemIndex] = useState(-1);
  const { find: findLocation, entities: location } = useEntity("location");
  const { find: findItemOpt, entities: items } = useEntity("menu/item-options");
  const { find: findCategoryOpt, entities: cats } = useEntity(
    "menu/category-options"
  );

  const { find: findMenuLocation, entities: menuLocation } =
    useEntity("menu/menu-location");
  const { find: findTag, entities: tags } = useEntity("tag");
  const { find, entities, loading: isLoading } = useEntity("menu/default-menu");
  const { findOne, entity } = useEntity("menu");
  const { create, updateEntity } = useEntity("menu");
  const partner = usePartnerId();

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      restaurant: authRest || restaurant,
      partner: partner,
      name: "",
      location: "",
      status: true,
      source: "local",
      categories: [],
      availability: [],
      method: [{ label: "All", value: "all" }],
      isAvailableDaily: false,
      groupCategories: [],
    },
    onSubmit: async (values) => {
      try {
        if (values.categories.length === 0) {
          toast.error("Menu must have at least one category");
          return;
        }
        if (!location) {
          toast.error("Please select at least one location");
          return;
        }

        if (values.availability.length === 0 && !values.isAvailableDaily) {
          toast.error("Menu must have availability defined");
          return;
        }
        // Create a map of active categories for quick lookup
        const activeCatsMap = new Map(
          activeCats.map((cat, index) => [
            cat._id,
            { ...cat, activeIndex: index },
          ])
        );

        // Update the categories in the form values
        const updatedCategories = values.categories.map((category) => {
          const activeCategory = activeCatsMap.get(category._id);
          if (activeCategory) {
            // If the category is in activeCats, update its properties
            return {
              ...category,
              ...activeCategory,
              items: activeCategory.items || category.items, // Preserve items if not in activeCategory
            };
          }
          return category; // If not in activeCats, keep the original category
        });

        // Sort the updatedCategories based on the order in activeCats
        updatedCategories.sort((a, b) => {
          const aIndex = activeCatsMap.get(a._id)?.activeIndex ?? Infinity;
          const bIndex = activeCatsMap.get(b._id)?.activeIndex ?? Infinity;
          return aIndex - bIndex;
        });

        if (props.location.state) {
          await updateEntity(entity?.data?._id, {
            ...values,
            categories: updatedCategories,
            method: values?.method.map((t) => t.value),
            location: values.location.map((t) => t.value),
            groupCategories: values.groupCategories.map((c) => {
              return {
                name: c.label,
                _id: c.value,
                categories: c.categories,
                status: c.status,
                sort: c.sort,
              };
            }),
          });
        } else {
          await create({
            ...values,
            categories: updatedCategories,
            location: values.location.map((t) => t.value),
            method: values?.method.map((t) => t.value),
            groupCategories: values.groupCategories.map((c) => {
              return {
                name: c.label,
                _id: c.value,
                categories: c.categories,
                status: c.status,
                sort: c.sort,
              };
            }),
          });
        }

        toast.success(props.location.state ? "Menu Updated" : "Menu Created");
        props.history.goBack();
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    },
  });

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = Array.from(values.categories[index].items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    values.categories[index].items = [...items];
    const val = values.categories;
    setFieldValue("categories", [...val]);
  };

  const handleDragEndCategory = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items = Array.from(activeCats);
    const [reorderedItem] = items.splice(result.source.index, 1);
    setSelected(reorderedItem?._id);
    setIndex(result.destination.index);
    items.splice(result.destination.index, 0, reorderedItem);
    setActiveCats([...items]);
  };

  useEffect(() => {
    if (values.location) {
      find({
        restaurant: authRest || restaurant,
        location: values.location.map((l) => l.value),
        method: values?.method?.map?.((t) => t.value) || ["all"],
        filterDsp:
          restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
          restaurantDoc?.restaurant?.stream?.enableStreamPOS
            ? "true"
            : "false",
      });
    }
  }, [values.location, values.method]);

  useEffect(() => {
    if (values.location?.length > 0 && index !== -1) {
      findItemOpt({
        restaurant: authRest || restaurant,
        locationId: values.location?.map((t) => t.value),
        categoryId: activeCats[index]?._id,
        method: values.method.map((t) => t.value),
        source:
          restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
          !restaurantDoc?.restaurant?.stream?.enableStreamPOS
            ? "stream"
            : "local",
      });
    }
  }, [values.location, index, values.method, restaurant, authRest]);

  console.log("ITEM", items);

  useEffect(() => {
    if (values.location.length > 0) {
      findCategoryOpt({
        restaurant: authRest || restaurant,
        locationId: values.location?.map((t) => t.value),
        method: values.method.map((t) => t.value),
        source:
          restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
          !restaurantDoc?.restaurant?.stream?.enableStreamPOS
            ? "stream"
            : "local",
      });
    }
  }, [values.location, values.method, restaurant, authRest]);

  useEffect(() => {
    findLocation({
      resturant: authRest || restaurant,
    });
    findTag({ restaurant: authRest || restaurant });
  }, []);

  useEffect(() => {
    if (props.location.state) {
      findOne(props.location?.state);
    }
    findMenuLocation({ resturant: authRest || restaurant });
  }, [props.location.state, selectedGroupCategory]);

  useEffect(() => {
    if (props.location.state && entity) {
      const methodMap = {
        pickup: "Pickup",
        delivery: "Delivery",
        all: "All",
        walkup: "Walk-up",
        "dine-in": "Dine-in",
        catering: "Catering",
      };

      const convertedMethod = entity.data.method.map((method) => ({
        label: methodMap[method] || method,
        value: method,
      }));

      setFieldValue("name", entity.data.name);
      setFieldValue(
        "location",
        entity?.data?.location?.map((t) => {
          return { label: t.name, value: t._id };
        })
      );
      setFieldValue("status", entity.data.status);
      setFieldValue("source", entity.data.source);
      setFieldValue(
        "categories",
        entity.data.categories.sort((a, b) => {
          if (a.status === b.status) {
            return 0;
          } else if (a.status === false) {
            return 1;
          } else {
            return -1;
          }
        })
      );
      setFieldValue("availability", entity.data.availability);
      setFieldValue("method", convertedMethod);
      setFieldValue("isAvailableDaily", entity.data.isAvailableDaily);
      setFieldValue(
        "groupCategories",
        entity.data.groupCategories.map((category) => {
          return {
            label: category.name,
            value: category._id,
            sort: category.sort,
            status: category.status,
            categories: category.categories,
          };
        })
      );
    }
  }, [entity, props.location.state]);

  useEffect(() => {
    if (values.source === "stream") {
      setFieldValue("method", ["all"]);
    }
  }, [values.source]);

  // useEffect(() => {
  //   const cats = values.categories
  //     .filter(
  //       (t) =>
  //         categoryQuery === "" ||
  //         t?.categoryName?.toLowerCase()?.includes(categoryQuery?.toLowerCase())
  //     )
  //     .sort((a, b) => {
  //       if (a.status === b.status) {
  //         return 0;
  //       } else if (a.status === false) {
  //         return 1;
  //       } else {
  //         return -1;
  //       }
  //     });

  //   // const valIndex = values.categories.findIndex((c) => c._id === cats[0]._id);
  //   // setIndex(valIndex);
  // }, [values.categories, categoryQuery]);

  useEffect(() => {
    if (values.groupCategories.length > 0) {
      setSelectedGroupCategory(values?.groupCategories?.[0]?.value);
    }
  }, [values.groupCategories]);

  useEffect(() => {
    if (entities?.data && !props.location.state) {
      setFieldValue("categories", entities?.data?.data);
      setFieldValue(
        "groupCategories",
        entities?.data?.groupCategories.map((e) => {
          return { label: e.name, value: e._id, ...e };
        })
      );
    }
  }, [entities?.data]);

  useEffect(() => {
    if (values.groupCategories.length > 0) {
      const selected = values.groupCategories.find(
        (t) => t.value === selectedGroupCategory
      );

      setCatIdsFiltered(selected?.categories?.map((c) => c.category?._id));

      const allCatIds = values.groupCategories?.flatMap((c) =>
        c.categories?.map((c) => c.category?._id)
      );
      setGroupCatIds(allCatIds);
      const menuCats = values.categories.map((c) => c._id);

      // Check if menuCats contains any IDs that don't exist in allCatIds
      const hasOtherCats = menuCats.some((id) => !allCatIds.includes(id));
      setOtherCats(hasOtherCats);
    }
  }, [selectedGroupCategory, values.categories]);

  useEffect(() => {
    const c = values.categories
      .filter((t) => {
        const categoryNameMatches =
          categoryQuery === "" ||
          t?.categoryName
            ?.toLowerCase()
            ?.includes(categoryQuery?.toLowerCase());

        const categoryIdMatches =
          selectedGroupCategory !== "other" &&
          catIdsFiltered?.length > 0 &&
          groupCatIds?.includes(t?._id?.toString()) &&
          catIdsFiltered.includes(t?._id);

        // Additional condition for "other" to exclude categories in groupCatIds
        const isNotInGroupCategory =
          selectedGroupCategory === "other" &&
          !groupCatIds?.includes(t?._id?.toString());

        return (
          categoryNameMatches && (isNotInGroupCategory || categoryIdMatches)
        );
      })
      .sort((a, b) => {
        if (a.status === b.status) {
          return 0;
        } else if (a.status === false) {
          return 1;
        } else {
          return -1;
        }
      });

    setActiveCats(c);
  }, [
    groupCatIds,
    categoryQuery,
    selectedGroupCategory,
    catIdsFiltered?.length,
    values.groupCategories,
  ]);

  return (
    <>
      <TimelineHeader
        parentPath="/resturants"
        name="Add Menu"
        parentName="Dashboard"
        showBtn={true}
        showSearch={false}
        placeholder="Search"
        getSearchQuery={(val) => {}}
        path="/resturants"
        tagsFilter={false}
        // tags={tags?.data}
        getTag={(val) => {}}
        streamFilter={false}
        source={[{ name: "Stream", _id: "stream" }]}
        getSource={(val) => {}}
        isCategory={false}
      >
        <>
          {/* {!props.location.state && (
            <div
              onClick={() => {
                if (values.location === "") {
                  toast.error("Please select a location");
                  return;
                } else {
                  setOpenCopyModal(true);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <span style={{ color: "#fff" }} className="fa fa-copy" />
              <span style={{ color: "#fff" }} className="ml-2">
                Copy Menu
              </span>
            </div>
          )} */}

          <Button
            color="primary"
            type="button"
            size="md"
            onClick={handleSubmit}
            disabled={restaurantDoc?.restaurant?.enableDeliverect}
          >
            {isSubmitting ? <Spinner size={"sm"} /> : "Save"}
          </Button>
          <Button
            color="secondary"
            type="button"
            size="md"
            onClick={() => {
              props.history.push({
                pathname: "/resturants/menu",
                search: props.location.search,
              });
            }}
            disabled={restaurantDoc?.restaurant?.enableDeliverect}
          >
            Back
          </Button>
        </>
      </TimelineHeader>

      <TableWrapper customStyles="px-4 pt-3">
        <Row>
          <Col md={2}>
            <FormGroup className="text-left">
              <label className="text-left">Menu Name</label>
              <Input
                onChange={handleChange}
                name="name"
                type="text"
                placeholder=""
                value={values.name}
              ></Input>
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup className="text-left">
              <label className="text-left">Location</label>
              {/* <Input
                onChange={handleChange}
                type="select"
                name="location"
                value={values.location}
                disabled={values.source === "stream" || entity}
              >
                <option value={null}>Select</option>
                {(location?.data?.data || [])?.map((item) => {
                  return (
                    <option key={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input> */}
              <Select
                onChange={(val) => setFieldValue("location", val)}
                isDisabled={values.source === "stream" || entity}
                isMulti
                name="location"
                isSearchable={true}
                options={location?.data?.data?.map((item) => {
                  return { label: item.name, value: item._id };
                })}
                value={values?.location}
              />
            </FormGroup>
          </Col>

          <Col md="2">
            <label style={{ fontSize: 15, fontWeight: "500" }}>
              Destination
            </label>
            <Input
              multiple=""
              type="select"
              onChange={handleChange("source")}
              disabled={values.source === "stream" || entity}
              value={values?.source}
              className={`form-control ${
                errors.source && touched.source ? "is-invalid" : ""
              }`}
            >
              <option value="">Select Destination</option>
              <option value="local">Native</option>
              <option value="stream">Stream</option>
            </Input>
            {errors.source && touched.source && (
              <ErrorLable message={errors.source} />
            )}
          </Col>
          <Col md="3">
            <label style={{ fontSize: 15, fontWeight: "500" }}>
              Method Availability
            </label>

            <Select
              onChange={(val) => setFieldValue("method", val)}
              isDisabled={values.source === "stream" || entity}
              isMulti
              name="method"
              isSearchable={true}
              options={[
                {
                  label: "All",
                  value: "all",
                },
                {
                  label: "Pickup",
                  value: "pickup",
                },
                {
                  label: "Delivery",
                  value: "delivery",
                },
                {
                  label: "Dine In",
                  value: "dine-in",
                },
                {
                  label: "Catering",
                  value: "catering",
                },
              ]}
              value={values?.method}
            />
            {errors.method && touched.method && (
              <ErrorLable message={errors.method} />
            )}
          </Col>

          <Col
            md={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={values.location === ""}
              onClick={() => setOpenModal(true)}
              color={"primary"}
            >
              {values?.availability?.length === 0 && !values?.isAvailableDaily
                ? "Set Availability"
                : "Edit Availability"}
            </Button>
          </Col>
          <Col
            md={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={values.location === ""}
              onClick={() => setOpenGroupCat(true)}
              color={"primary"}
            >
              Group
            </Button>
          </Col>
        </Row>
      </TableWrapper>

      <Col md={12}>
        <Row className="mx-3">
          {values.groupCategories.length > 0 && otherCats && (
            <Col
              onClick={() => {
                setSelectedGroupCategory("other");
              }}
              className="my-2"
              md={2}
              style={{
                borderBottom:
                  "other" === selectedGroupCategory
                    ? "2px solid #5e72e4"
                    : "1px solid lightgray",
                padding: 5,
                color:
                  "other" === selectedGroupCategory ? "#5e72e4" : "lightgray",
                cursor: "pointer",
              }}
            >
              {"Menu"}
            </Col>
          )}
          {values.groupCategories.map((t) => {
            return (
              <Col
                onClick={() => {
                  setSelectedGroupCategory(t.value);
                }}
                className="my-2"
                md={2}
                style={{
                  borderBottom:
                    t.value === selectedGroupCategory
                      ? "2px solid #5e72e4"
                      : "1px solid lightgray",
                  padding: 5,
                  color:
                    t.value === selectedGroupCategory ? "#5e72e4" : "lightgray",
                  cursor: "pointer",
                }}
              >
                {t.label}
              </Col>
            );
          })}
        </Row>
      </Col>

      {values.location && !isLoading ? (
        <Row className="px-4">
          <Col md={4}>
            <Card>
              <DragDropContext onDragEnd={handleDragEndCategory}>
                <Table responsive>
                  <thead className="thead-light text-left">
                    <tr>
                      <th>
                        <Row>
                          <Col md={10}>Categories</Col>
                          {entity?.data && (
                            <Col md={2}>
                              <span
                                onClick={() => {
                                  setOpenCatAdd(true);
                                }}
                                className="fa fa-plus-circle text-lg"
                                style={{ cursor: "pointer" }}
                              ></span>
                            </Col>
                          )}
                        </Row>
                      </th>
                    </tr>
                  </thead>
                  <Input
                    size={"sm"}
                    style={{ margin: 10, width: "95%" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setCategoryQuery(e.target.value);
                    }}
                  ></Input>
                  <Droppable droppableId="table">
                    {(provided, snapshot) => (
                      <tbody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {activeCats.map((cat, idx) => (
                          <Draggable
                            key={cat.categoryName}
                            draggableId={cat.categoryName}
                            index={idx}
                          >
                            {(provided) => (
                              <tr
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                className={
                                  snapshot.isDraggingOver ? "dragging" : ""
                                }
                              >
                                <th
                                  onClick={() => {
                                    setSelected(cat?._id);
                                    setIndex(idx);
                                  }}
                                  style={{
                                    backgroundColor:
                                      selected === cat?._id
                                        ? "lightgrey"
                                        : null,
                                  }}
                                >
                                  <Row>
                                    <Col
                                      md={8}
                                      style={{
                                        display: "flex",
                                        textAlign: "left",
                                        alignItems: "center",
                                        fontWeight: "normal",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span className="fa fa-bars"></span>
                                      <span className="ml-2">
                                        {cat.categoryName}
                                      </span>
                                    </Col>
                                    <Col
                                      md={2}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <div className="ml-4">
                                        <label className="custom-toggle">
                                          <input
                                            checked={cat.status}
                                            type="checkbox"
                                            onChange={async () => {
                                              const updatedCategories = [
                                                ...values.categories,
                                              ];
                                              const categoryIndex =
                                                updatedCategories.findIndex(
                                                  (c) => c._id === cat._id
                                                );
                                              if (categoryIndex !== -1) {
                                                updatedCategories[
                                                  categoryIndex
                                                ].status = !cat.status;
                                                setFieldValue(
                                                  "categories",
                                                  updatedCategories
                                                );
                                              }
                                            }}
                                          />
                                          <span
                                            className="custom-toggle-slider rounded-circle"
                                            data-label-off="Off"
                                            data-label-on="On"
                                          />
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </th>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </DragDropContext>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              {index >= 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Table className="align-items-center " responsive>
                    <thead className="thead-light text-left">
                      <tr>
                        <th>Items</th>
                        <th>Tags</th>
                        <th>Price</th>
                        <th>Plu</th>
                        <th
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Status
                          {entity?.data && (
                            <span
                              onClick={() => setOpenItemAdd(true)}
                              style={{ cursor: "pointer" }}
                              className="fa fa-plus-circle text-lg ml-4"
                            ></span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tr>
                      <td colSpan={5}>
                        <Input
                          onChange={(e) => {
                            setProductQuery(e.target.value);
                          }}
                          size={"sm"}
                          placeholder="Search"
                        ></Input>
                      </td>
                    </tr>
                    <Droppable droppableId="table">
                      {(provided, snapshot) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {activeCats[index]?.items
                            ?.sort((a, b) => {
                              if (a.status === b.status) {
                                return 0;
                              } else if (a.status === false) {
                                return 1;
                              } else {
                                return -1;
                              }
                            })
                            .map((it, idx) => {
                              if (
                                !it?.name
                                  ?.toLowerCase()
                                  ?.includes(productQuery?.toLowerCase()) &&
                                productQuery !== ""
                              ) {
                                return <></>;
                              }
                              return (
                                <Draggable
                                  key={it._id}
                                  draggableId={it._id}
                                  index={idx}
                                >
                                  {(provided) => (
                                    <tr
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className={
                                        snapshot.isDraggingOver
                                          ? "dragging"
                                          : ""
                                      }
                                    >
                                      <th>
                                        <Row>
                                          <Col
                                            md={10}
                                            style={{
                                              fontWeight: "bold",
                                              cursor: "pointer",
                                              padding: 5,
                                              textAlign: "left",
                                            }}
                                            onClick={() => {
                                              setItemIndex(idx);
                                              setOpenItemEdit(true);
                                            }}
                                          >
                                            <span className="fa fa-bars"></span>
                                            <span
                                              className="ml-2"
                                              style={{
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                                padding: 5,
                                              }}
                                              onClick={() => {
                                                const updatedCategories = [
                                                  ...values.categories,
                                                ];
                                                const categoryIndex =
                                                  updatedCategories.findIndex(
                                                    (c) =>
                                                      c._id ===
                                                      activeCats[index]._id
                                                  );
                                                setEditIndex(categoryIndex);
                                                setItemIndex(idx);
                                                setOpenItemEdit(true);
                                              }}
                                            >
                                              {it.name}
                                            </span>
                                          </Col>
                                        </Row>
                                      </th>
                                      <td className="text-left">
                                        {it?.tag?.map((t) => t?.name).join(",")}
                                      </td>
                                      <td className="text-left">
                                        <Input
                                          style={{ width: 80 }}
                                          size={"sm"}
                                          type="text"
                                          onChange={(e) => {
                                            const updatedCategories = [
                                              ...values.categories,
                                            ];
                                            const categoryIndex =
                                              updatedCategories.findIndex(
                                                (c) =>
                                                  c._id ===
                                                  activeCats[index]._id
                                              );
                                            if (categoryIndex !== -1) {
                                              const itemIndex =
                                                updatedCategories[
                                                  categoryIndex
                                                ].items.findIndex(
                                                  (item) => item._id === it._id
                                                );
                                              if (itemIndex !== -1) {
                                                updatedCategories[
                                                  categoryIndex
                                                ].items[itemIndex].price =
                                                  e.target.value;
                                                setFieldValue(
                                                  "categories",
                                                  updatedCategories
                                                );
                                              }
                                            }
                                          }}
                                          value={it.price}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          style={{ width: 80 }}
                                          size={"sm"}
                                          type="text"
                                          onChange={(e) => {
                                            const updatedCategories = [
                                              ...values.categories,
                                            ];
                                            const categoryIndex =
                                              updatedCategories.findIndex(
                                                (c) =>
                                                  c._id ===
                                                  activeCats[index]._id
                                              );
                                            if (categoryIndex !== -1) {
                                              const itemIndex =
                                                updatedCategories[
                                                  categoryIndex
                                                ].items.findIndex(
                                                  (item) => item._id === it._id
                                                );
                                              if (itemIndex !== -1) {
                                                updatedCategories[
                                                  categoryIndex
                                                ].items[itemIndex].plu =
                                                  e.target.value;
                                                setFieldValue(
                                                  "categories",
                                                  updatedCategories
                                                );
                                              }
                                            }
                                          }}
                                          value={it.plu}
                                        />
                                      </td>
                                      <td className="text-left">
                                        <div>
                                          <label className="custom-toggle">
                                            <input
                                              checked={it.status}
                                              type="checkbox"
                                              value={it.status}
                                              onChange={async (e) => {
                                                e.stopPropagation();
                                                const updatedCategories = [
                                                  ...values.categories,
                                                ];
                                                const categoryIndex =
                                                  updatedCategories.findIndex(
                                                    (c) =>
                                                      c._id ===
                                                      activeCats[index]._id
                                                  );
                                                if (categoryIndex !== -1) {
                                                  const itemIndex =
                                                    updatedCategories[
                                                      categoryIndex
                                                    ].items.findIndex(
                                                      (item) =>
                                                        item._id === it._id
                                                    );
                                                  if (itemIndex !== -1) {
                                                    updatedCategories[
                                                      categoryIndex
                                                    ].items[itemIndex].status =
                                                      !it.status;
                                                    setFieldValue(
                                                      "categories",
                                                      updatedCategories
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                            <span
                                              className="custom-toggle-slider rounded-circle"
                                              data-label-off="Off"
                                              data-label-on="On"
                                            />
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </Table>
                  {activeCats?.[index]?.items?.length === 0 && (
                    <div
                      style={{
                        padding: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <EmptyPlaceholder text={"No Items"} />
                    </div>
                  )}
                </DragDropContext>
              ) : (
                <div
                  style={{
                    padding: 20,
                  }}
                >
                  <span>
                    <EmptyPlaceholder text={"No Categories Selected"} />
                  </span>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      ) : isLoading ? (
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      ) : (
        <div
          style={{
            padding: 20,
          }}
        >
          <span>
            <EmptyPlaceholder text={"No Location Selected"} />
          </span>
        </div>
      )}
      <AvailablityMenuModal
        isAvailableDaily={values.isAvailableDaily}
        onIsAvailableDailyChange={(val) =>
          setFieldValue("isAvailableDaily", val)
        }
        onChangeSlots={(slots) => {
          setFieldValue("availability", slots);
        }}
        slots={values.availability}
        open={openModal}
        toggle={() => setOpenModal(!openModal)}
      />

      <CopyMenuModal
        isVisible={openCopyModal}
        onClose={() => setOpenCopyModal(false)}
        toggle={() => setOpenCopyModal(!openCopyModal)}
        locations={menuLocation?.data || []}
        setMenu={(data) => {
          setFieldValue("name", data.name);
          setFieldValue("status", data.status);
          setFieldValue("source", data.source);
          setFieldValue("categories", data.categories);
          setFieldValue("availability", data.availability);
        }}
      />

      <AddNewItemModal
        isVisible={openItemAdd}
        onClose={() => setOpenItemAdd(false)}
        toggle={() => setOpenItemAdd(!openItemAdd)}
        method={values?.method}
        data={
          items?.data?.filter((itm) => {
            const ids = values.categories.flatMap((c) =>
              c.items.map((c) => c._id)
            );
            return !ids.includes(itm._id);
          }) || []
        }
        saveItem={(data) => {
          const updatedCategories = [...values.categories];
          const categoryIndex = updatedCategories.findIndex(
            (c) => c._id === activeCats[index]._id
          );
          values.categories[categoryIndex].items.push(data);
          setFieldValue("categories", values.categories);
        }}
      />

      <AddNewCategoryModal
        isVisible={openCatAdd}
        onClose={() => setOpenCatAdd(false)}
        toggle={() => setOpenCatAdd(!openCatAdd)}
        data={
          cats?.data?.filter((f) => {
            const ids = values?.categories?.map((c) => c?._id);
            return !ids.includes(f._id);
          }) || []
        }
        restaurant={restaurant}
        restaurantDoc={restaurantDoc}
        location={values.location}
        method={values?.method}
        saveItem={(data) => {
          console.log("Category---", data);
          values.categories.push(data);
          setActiveCats([...activeCats, data]);
          setFieldValue("categories", [...values.categories]);
        }}
      />
      <GroupCatModal
        isVisible={openGroupCat}
        onClose={() => setOpenGroupCat(false)}
        toggle={() => setOpenGroupCat(!openGroupCat)}
        groupCategories={values?.groupCategories}
        restaurant={restaurant}
        restaurantDoc={restaurantDoc}
        location={values.location}
        method={values?.method}
        saveItem={(data) => {
          const idxg = values.groupCategories.findIndex(
            (c) => c.value === data._id
          );
          const d = values.groupCategories;

          d[idxg] = {
            label: data.name,
            value: data._id,
            sort: data.sort,
            status: data.status,
            categories: data.categories.map((d) => {
              return {
                category: d.category._id,
                sort: d.sortOrder,
              };
            }),
          };

          // setFieldValue("groupCategories", [...d]);
        }}
      />

      {openItemEdit && itemIndex !== -1 && (
        <ItemEditModal
          isVisible={openItemEdit}
          onClose={() => setOpenItemEdit(false)}
          toggle={() => setOpenItemEdit(!openItemEdit)}
          data={values.categories[editIndex].items[itemIndex] || []}
          categories={values.categories}
          selectedCategory={values.categories[editIndex]._id}
          tag={tags?.data || []}
          handleChangeCategory={(nextCategory, value) => {
            const item = values.categories[editIndex].items[itemIndex];
            item.name = value.name;
            item.price = value.price;
            item.plu = value.plu;
            item.tag = value.tag.map((t) => {
              return { _id: t.value, name: t.label };
            });
            values.categories[editIndex].items[itemIndex] = item;
            const items = values.categories[editIndex].items;
            const changedItem = items.splice(itemIndex, 1);
            values.categories[editIndex].items = items;
            const nextCategoryIndex = values.categories.findIndex(
              (c) => c._id === nextCategory
            );

            values.categories[nextCategoryIndex].items = [
              ...values.categories[nextCategoryIndex].items,
              ...changedItem,
            ];

            setFieldValue("categories", [...values.categories]);
          }}
          updateItem={(value) => {
            const item = values.categories[editIndex].items[itemIndex];
            item.name = value.name;
            item.price = value.price;
            item.plu = value.plu;
            item.tag = value.tag.map((t) => {
              return { _id: t.value, name: t.label };
            });
            values.categories[editIndex].items[itemIndex] = item;

            setFieldValue("categories", [...values.categories]);
          }}
        />
      )}
    </>
  );
}
