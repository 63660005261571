import moment from "moment";
import { Button, Spinner } from "reactstrap";
import { useEntity } from "../../../hooks/useEntity";

export const TableRow = ({ history, data, handleOpen, view }) => {
  const { deleteEntity, loading } = useEntity("microsale");

  return (
    <tr className="text-left">
      <th scope="row">{data?.mac || "N/A"}</th>
      {view !== "partner" && <td>{data?.partner?.partner?.name || "N/A"}</td>}
      <td>{data?.restDoc?.name || "N/A"}</td>
      <td> {data?.location?.name || "N/A"}</td>
      <td style={{ textTransform: "capitalize" }}>
        {" "}
        {data?.provider || "N/A"}
      </td>
      <td>
        <div style={{ width: 120, display: "inline-block" }}>First Contact</div>
        {data?.firstContactAt
          ? moment(data.firstContactAt).format("YYYY-MM-DD hh:mm a")
          : "N/A"}{" "}
        <br />
        <div style={{ width: 120, display: "inline-block" }}>
          Last Menu Push
        </div>
        {data?.lastMenuPush
          ? moment(data.lastMenuPush).format("YYYY-MM-DD hh:mm a")
          : "N/A"}{" "}
        <br />
        <div style={{ width: 120, display: "inline-block" }}>Last Ping At</div>
        {data?.lastPingAt
          ? moment(data.lastPingAt).format("YYYY-MM-DD hh:mm a")
          : "N/A"}{" "}
      </td>
      <td>
        <Button onClick={() => handleOpen(data)}>Edit</Button>
        <Button
          className="my-4"
          color="danger"
          type="button"
          onClick={() => {
            console.log("deleting");
            deleteEntity(data._id);
          }}
        >
          {loading ? <Spinner size="sm" color="light" /> : "Delete"}
        </Button>
      </td>
    </tr>
  );
};
