import "@djthoms/pretty-checkbox";
import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { useTheme } from "../../../store/theme";
import { toast } from "react-toastify";
import CustomCheckbox from "../../../components/Inputs/CustomCheckbox";
import leftPizza from "../../../assets/left.svg";
import rightPizza from "../../../assets/right.svg";
import wholePizza from "../../../assets/whole.svg";
import useScreenSize from "../../../hooks/useScreenSize";
import Select from "react-select";

const CustomOption = ({ innerProps, isDisabled, children }) => {
  const child = children.split("$");

  const str = children;

  const lastDollarIndex = str.lastIndexOf("$");

  const price = lastDollarIndex !== -1 ? str.substring(lastDollarIndex + 1) : 0;

  return (
    <>
      {!isDisabled ? (
        <div
          className="p-2 modifier_select_component"
          style={{ display: "flex", justifyContent: "space-between" }}
          {...innerProps}
        >
          <div>{child[0] === "" ? `$${child[1]}` : child[0]}</div>
          <div>
            {price !== 0 && price !== "0.00" && price !== undefined
              ? `$${Number(price)?.toFixed(2)}`
              : ""}{" "}
          </div>
        </div>
      ) : null}
    </>
  );
};

const AdvancedPizzaModifiers = ({
  allData,
  subProd,
  handleAddOptions,
  handleRemoveOption,
  isSelected,
  index,
  advancedPizzaOptions,
  size: parentSize,
  type: parentType,
  countByParentId,
  parentChanged,
  selectedOptions,
  name,
  max,
  sortOrder = 0,
  modifier,
  maxFreeToppings,
  originalMaxFreeToppings,
  isFree,
}) => {
  const { product_name, product_id } = subProd;

  const { theme } = useTheme();
  const [size, setSize] = useState(parentSize || "all");
  const [type, setType] = useState(parentType || "regular");
  const [exhcnaged, setExhcnaged] = useState();
  const [exhcnagedSize, setExhcnagedSize] = useState("all");
  const [exhcnagedType, setExhcnagedType] = useState("regular");

  const selectedRef = useRef();

  const [finalPrice, setFinalPrice] = useState(0);
  const { width, height } = useScreenSize();
  const [isItemFree, setIsItemFree] = useState(isFree);

  const shouldShowPrice =
    ((subProd.defaultSelected && type === "extra") ||
      !subProd.defaultSelected) &&
    maxFreeToppings <= 0 &&
    !isFree;

  const isToppingSelected = (toppingType) => {
    return toppingType === type;
  };

  const isSizeSelected = (toppingSize) => {
    return toppingSize === size;
  };

  const isExchangeToppingSelected = (toppingType) => {
    return toppingType === exhcnagedType;
  };

  const isExchangeSizeSelected = (toppingSize) => {
    return toppingSize === exhcnagedSize;
  };
  // Set initial free status when component mounts or isFree prop changes
  useEffect(() => {
    setIsItemFree(isFree);
  }, [isFree]);

  useEffect(() => {
    if (isSelected(subProd) && type !== "" && size !== "") {
      const baseOptions = {
        ...subProd,
        type,
        size,
        advancedPizzaOptions,
        name: subProd.name,
        parent: subProd.name,
        sort: Number(sortOrder || 0),
        isFree: isItemFree,
        maxFreeToppings: originalMaxFreeToppings,
        ...(isItemFree || (subProd?.defaultSelected && type !== "extra")
          ? { price: 0, extraPrice: 0, halfPrice: 0 }
          : {}),
      };

      handleAddOptions(baseOptions);
    }
  }, [size, type, subProd]);

  // Initial price calculation based on maxFreeToppings and isFree
  useEffect(() => {
    if (exhcnaged) {
      const baseOptions = {
        ...exhcnaged,
        type: exhcnagedType,
        size: exhcnagedSize,
        advancedPizzaOptions,
        name: exhcnaged.name,
        parent: exhcnaged.name,
        sort: Number(sortOrder || 0),
        allowExchange: true,
      };

      if (exhcnaged?.defaultSelected && type !== "extra") {
        handleAddOptions({
          ...baseOptions,
          exchangedWith: subProd?._id,
          // Set all prices to 0 for included modifiers
          price: 0,
          extraPrice: 0,
          halfPrice: 0,
          advancedPizzaOptions: true,
        });
      } else {
        const exVal = exhcnaged;
        let priceDiff;
        let halfPriceDiff;
        let extraPriceDiff;

        if (exhcnagedType === "extra") {
          priceDiff = subProd?.price
            ? Math.round(Math.max(0, exVal.price) * 100) / 100
            : Math.round(exVal.price * 100) / 100;

          halfPriceDiff = subProd?.halfPrice
            ? Math.round(Math.max(0, exVal.halfPrice) * 100) / 100
            : Math.round(exVal.halfPrice * 100) / 100;

          extraPriceDiff = subProd?.extraPrice
            ? Math.round(Math.max(0, exVal.extraPrice) * 100) / 100
            : Math.round(exVal.extraPrice * 100) / 100;
        } else {
          priceDiff = subProd?.price
            ? Math.round(Math.max(0, exVal.price - subProd.price) * 100) / 100
            : Math.round(exVal.price * 100) / 100;

          halfPriceDiff = subProd?.halfPrice
            ? Math.round(
                Math.max(0, exVal.halfPrice - subProd.halfPrice) * 100
              ) / 100
            : Math.round(exVal.halfPrice * 100) / 100;

          extraPriceDiff = subProd?.extraPrice
            ? Math.round(
                Math.max(0, exVal.extraPrice - subProd.extraPrice) * 100
              ) / 100
            : Math.round(exVal.extraPrice * 100) / 100;
        }

        handleAddOptions({
          ...baseOptions,
          exchangedWith: subProd?.product_id,
          price: priceDiff,
          halfPrice: halfPriceDiff,
          extraPrice: extraPriceDiff,
          advancedPizzaOptions: true,
        });
      }
    }
    if (maxFreeToppings > 0 || isFree) {
      setFinalPrice(0);
    } else {
      calculatePrice();
    }
  }, [subProd, maxFreeToppings, isFree, size, type, exhcnaged]);

  useEffect(() => {
    setFinalPrice(subProd.price);
  }, [subProd]);

  const calculatePrice = () => {
    const { halfPrice, extraPrice, price } = subProd;
    if (size !== "" && type !== "") {
      if (type === "regular" && (size === "left" || size === "right")) {
        setFinalPrice(halfPrice);
      } else if (type === "regular" && size === "all") {
        setFinalPrice(price);
      } else if (type === "extra" && size === "all") {
        setFinalPrice(extraPrice);
      } else if ((type === "extra" && size === "left") || size === "right") {
        setFinalPrice(extraPrice / 2);
      }
    }
  };

  return (
    <div key={index}>
      <>
        <div className="">
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                zIndex: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginTop: 3,
              }}
            >
              <CustomCheckbox
                className="cb1"
                key={product_name}
                type={"checkbox"}
                id={product_name}
                name={product_name}
                value={JSON.stringify(subProd)}
                onChange={(e) => {
                  if (e.target.checked) {
                    if (subProd?.allowExchange) {
                      const exchangedModifs = selectedOptions.find(
                        (t) => t.exchangedWith === product_id
                      );
                      setExhcnaged(null);
                      handleRemoveOption(exchangedModifs);
                    }
                    const count = selectedOptions.reduce(
                      (count, item) =>
                        item.name === subProd?.name ? count + 1 : count,
                      0
                    );

                    if (Number(max) > 0 && count >= Number(max) && max) {
                      toast.error(`You can select ${max} maximum.`);
                      return;
                    }

                    setType("regular");
                    setSize("all");

                    const newIsItemFree = maxFreeToppings > 0;
                    setIsItemFree(newIsItemFree);

                    const baseOptions = {
                      ...subProd,
                      type: "regular",
                      size: "all",
                      name: name,
                      parent: name,
                      isFree: newIsItemFree,
                      maxFreeToppings: originalMaxFreeToppings,
                    };

                    if (newIsItemFree || subProd?.defaultSelected) {
                      handleAddOptions({
                        ...baseOptions,
                        price: 0,
                        extraPrice: 0,
                        halfPrice: 0,
                      });
                    } else {
                      handleAddOptions(baseOptions);
                    }
                  } else {
                    subProd["unCheck"] = true;
                    handleRemoveOption(subProd);
                  }
                }}
                checked={isSelected(subProd)}
                style={{
                  marginBottom: 10,
                  fontSize: 15,
                  fontWeight: "400",
                }}
              />
              <label
                className="ml-2 my-0"
                style={{ cursor: "pointer" }}
                htmlFor={product_name}
              >
                {product_name}
              </label>
            </div>

            <div style={{ fontSize: 16 }}>
              {finalPrice !== 0 && shouldShowPrice
                ? `$${Number(finalPrice).toFixed(2)}`
                : ""}
            </div>
          </div>
        </div>

        {isSelected(subProd) && (
          <div
            className="my-2 mx-2 p-1"
            style={{
              width: "max-content",
              display: "flex",
              alignItems: "center",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
          >
            <Button
              size="sm"
              onClick={() => {
                setSize("left");
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: isSizeSelected("left")
                  ? theme.primary
                  : "white",
                color: isSizeSelected("left") ? "white" : "black",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={leftPizza} alt="Left Pizza Slice" />
              {width < 550 ? "L" : "Left"}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setSize("all");
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: isSizeSelected("all")
                  ? theme.primary
                  : "white",
                color: isSizeSelected("all") ? "white" : "black",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={wholePizza} alt="Whole Pizza" />{" "}
              {width < 550 ? "W" : "Whole"}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                setSize("right");
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: isSizeSelected("right")
                  ? theme.primary
                  : "white",
                color: isSizeSelected("right") ? "white" : "black",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={rightPizza} alt="Right Pizza Slice" />{" "}
              {width < 550 ? "R" : "Right"}
            </Button>
            <Button
              size="sm"
              onClick={() => {
                if (isToppingSelected("extra")) {
                  setType("regular");
                } else {
                  setType("extra");
                }
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: isToppingSelected("extra")
                  ? theme.primary
                  : "white",
                color: isToppingSelected("extra") ? "white" : "black",
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ height: "28px" }}></div>{" "}
                <div>{width < 550 ? "Extra" : "Extra"}</div>
              </div>
            </Button>
          </div>
        )}

        {subProd.defaultSelected &&
          !isSelected(subProd) &&
          subProd?.allowExchange && (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Select
                components={{ Option: CustomOption }}
                isSearchable={false}
                className="basic-single"
                classNamePrefix="select"
                placeholder={`Exchange ${subProd.product_name}`}
                ref={selectedRef}
                options={allData
                  .filter((t) => {
                    const allOptId = selectedOptions.map((t) => t.product_id);
                    return !allOptId.includes(t.product_id);
                  })
                  .filter((t) => {
                    return (
                      t?.product_name !== subProd?.product_name &&
                      (subProd?.selectedParentValue?.label || "") ===
                        (t?.selectedParentValue?.label || "") &&
                      !t?.defaultSelected
                    );
                  })
                  // .filter((r) => {
                  //   const exchangedWith = selectedOptions
                  //     .map((t) => (t.exchangedWith ? r?.product_id : null))
                  //     .filter(Boolean);

                  //   return !exchangedWith.includes(r.product_id);
                  // })
                  .map((d) => {
                    return {
                      label: `${d?.product_name} ${
                        d?.price - subProd?.price > 0
                          ? "$" + `${d?.price - subProd?.price}`
                          : ""
                      }`,
                      value: d.product_id,
                    };
                  })}
                onChange={(data) => {
                  if (exhcnaged) {
                    const exchangedModifs = selectedOptions.find(
                      (t) => t.exchangedWith === product_id
                    );
                    handleRemoveOption(exchangedModifs);
                  }
                  const sel = allData.find((d) => d.product_id === data.value);
                  setExhcnaged(sel);
                }}
                value={
                  exhcnaged
                    ? {
                        label: `${exhcnaged?.product_name} ${
                          exhcnaged?.price - subProd?.price > 0
                            ? ` - $${Number(
                                (exhcnaged?.price - subProd?.price).toFixed(2)
                              )}`
                            : ""
                        }`,
                        value: exhcnaged?.product_id,
                      }
                    : ""
                }
              />
              {exhcnaged && (
                <div
                  className="my-2 p-1"
                  style={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  }}
                >
                  <Button
                    size="sm"
                    onClick={() => {
                      setExhcnagedSize("left");
                    }}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: isExchangeSizeSelected("left")
                        ? theme.primary
                        : "white",
                      color: isExchangeSizeSelected("left") ? "white" : "black",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <img src={leftPizza} alt="Left Pizza Slice" />
                    {width < 550 ? "L" : "Left"}
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => {
                      setExhcnagedSize("all");
                    }}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: isExchangeSizeSelected("all")
                        ? theme.primary
                        : "white",
                      color: isExchangeSizeSelected("all") ? "white" : "black",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <img src={wholePizza} alt="Whole Pizza" />{" "}
                    {width < 550 ? "W" : "Whole"}
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => {
                      setExhcnagedSize("right");
                    }}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: isExchangeSizeSelected("right")
                        ? theme.primary
                        : "white",
                      color: isExchangeSizeSelected("right")
                        ? "white"
                        : "black",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <img src={rightPizza} alt="Right Pizza Slice" />{" "}
                    {width < 550 ? "R" : "Right"}
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => {
                      if (isExchangeToppingSelected("extra")) {
                        setExhcnagedType("regular");
                      } else {
                        setExhcnagedType("extra");
                      }
                    }}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: isExchangeToppingSelected("extra")
                        ? theme.primary
                        : "white",
                      color: isExchangeToppingSelected("extra")
                        ? "white"
                        : "black",
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ height: "28px" }}></div>{" "}
                      <div>{width < 550 ? "Extra" : "Extra"}</div>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          )}
      </>
    </div>
  );
};

export default AdvancedPizzaModifiers;
