import { useFormik } from "formik";
import { useContext, useState } from "react";
import { FaEnvelope, FaUserCircle } from "react-icons/fa";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardFooter, Spinner } from "reactstrap";
import SecureInput from "../../../../components/Inputs/SecureInput";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import DB from "../../../../utils/DB";
import getDaysInMonth from "../../../../utils/getDaysInMonth";
import { DBKeys } from "../../../../utils/Keys";
import logger from "../../../../utils/logger";
import { validationSchema } from "../../../../utils/schemas/Register";
import SocialComponent from "../../auth/components/SocialComponent";
import GenericModal from "../../components/modals/GenericModal";

const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const Register = ({ toggle }) => {
  const { restaurant } = useOrderingRestaurant();
  const { create: registerCustomer } = useEntity("eat/auth");
  const { create: login } = useEntity("eat/auth/local");
  const { create } = useEntity("checkout-session");
  const authContext = useContext(AuthContext);
  const { theme } = useTheme();
  const [openTerms, setOpenTerms] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      birthday: "",
      password: "",

      anniversary: "",
      anniversaryMonth: "",
      smsForCouponAndInfo: false,
      emailForCouponAndInfo: true,
      birthMonth: "",
      agreeTermsAndConditions: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        email: values?.email.toString().toLowerCase(),
        password: values?.password,
        phone: values?.phone,
        name: values?.name,
        role: "customer",
        restaurant: restaurant?.userId,
        birthday: `${
          values?.birthMonth.toString().length === 1
            ? `0${values?.birthMonth.toString()}`
            : values?.birthMonth.toString()
        }/${
          values?.birthday.toString().length === 1
            ? `0${values?.birthday.toString()}`
            : values?.birthday.toString()
        }`,
        anniversary: `${
          values?.anniversary.toString().length === 1
            ? `0${values?.anniversaryMonth.toString()}`
            : values?.anniversaryMonth.toString()
        }/${
          values?.anniversary.toString().length === 1
            ? `0${values?.anniversary.toString()}`
            : values?.anniversary.toString()
        }`,
        status: "active",
        emailForCouponAndInfo: values?.emailForCouponAndInfo,
        smsForCouponAndInfo: values?.smsForCouponAndInfo,
        agreeTermsAndConditions: values?.agreeTermsAndConditions,
        source: "web",
        loyalty: {
          restaurant: restaurant?.userId,
          points: 0,
        },
      };

      try {
        await registerCustomer({
          ...data,
        });
        const r = await login({
          restaurant: restaurant?.userId,
          email: values?.email.toString().toLowerCase(),
          password: values?.password,
        });
        if (
          (await DB.storeData(DBKeys.CUSTOMER_USER, r?.data?.user)) &&
          (await DB.storeData(DBKeys.CUSTOMER_TOKEN, r?.data?.accessToken))
        ) {
          if (restaurant?.enable2fa) {
            window.localStorage.removeItem("otpDoc");

            const checkoutSessionData = await create(r?.data);

            window.localStorage.setItem(
              "checkoutSessionData",
              JSON.stringify(checkoutSessionData)
            );
          }
          authContext.login(r?.data);
        }
        toast("You have been registered successfully!");
        toggle();
      } catch (error) {
        logger.push({ error, info: "RegisterCustomer" });
        toast.error(error?.message);
      }
    },
  });

  return (
    <>
      <div>
        <Card style={{ boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)" }}>
          <CardBody>
            <h1>Register</h1>{" "}
            {restaurant?.ordering?.socialLogin && (
              <SocialComponent
                text="- Or sign up with -"
                className="mt-2 mb-3"
              />
            )}
            <div>
              <div className="input_group_checkout">
                <div style={{ flex: 1 }}>
                  <div
                    className="px-3 mt-2"
                    style={{
                      backgroundColor: "#ededed",
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: errors?.name && "1px solid red",
                    }}
                  >
                    <input
                      className=" py-3"
                      style={{
                        border: "none",
                        flex: 1,
                        fontSize: "18px",
                        background: "transparent",
                      }}
                      type="text"
                      autoComplete="off"
                      placeholder="Name"
                      onChange={handleChange("name")}
                      value={values?.name}
                    />

                    <FaUserCircle size={25} />
                  </div>
                  {errors.name && touched.name ? (
                    <small className="text-red mb-2">{errors.name}</small>
                  ) : (
                    <small></small>
                  )}
                </div>

                <span className="mx-5"></span>

                <div style={{ flex: 1 }}>
                  <div
                    className="px-3 mt-2"
                    style={{
                      backgroundColor: "#ededed",
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: errors?.phone && "1px solid red",
                    }}
                  >
                    <input
                      className=" py-3"
                      style={{
                        border: "none",

                        flex: 1,
                        fontSize: "18px",
                        background: "transparent",
                      }}
                      type="number"
                      placeholder="Telephone"
                      autoComplete="off"
                      onChange={handleChange("phone")}
                      value={values?.phone}
                    />
                    <i class="fa fa-phone text-xl" aria-hidden="true"></i>
                  </div>
                  {errors?.phone && touched?.phone ? (
                    <small className="text-red mb-2">{errors?.phone}</small>
                  ) : (
                    <small></small>
                  )}
                </div>
              </div>

              <div className="input_group_checkout mt-3">
                <div style={{ flex: 1 }}>
                  <div
                    className="px-3 mt-2"
                    style={{
                      backgroundColor: "#ededed",
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: errors?.email && "1px solid red",
                    }}
                  >
                    <input
                      className=" py-3"
                      style={{
                        border: "none",

                        flex: 1,
                        fontSize: "18px",
                        background: "transparent",
                      }}
                      type="email"
                      placeholder="Email"
                      onChange={handleChange("email")}
                      value={values?.email}
                    />
                    <FaEnvelope size={25} />
                  </div>
                  {errors?.email && touched?.email ? (
                    <small className="text-red mb-2">{errors?.email}</small>
                  ) : (
                    <small></small>
                  )}
                </div>
                <span className="mx-5"></span>
                <div style={{ flex: 1 }}>
                  <div
                    className="mt-2"
                    style={{
                      backgroundColor: "#ededed",
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: errors?.password && "1px solid red",
                    }}
                  >
                    <SecureInput
                      ordering
                      onChange={(val) => handleChange("password")(val)}
                      onBlur={(e) => console.log(e)}
                      isValid={touched.password && !errors.password}
                      label="Password"
                      name="password"
                      placeholder="Password"
                      className={"signin_input_ordering"}
                    />
                  </div>
                  {errors?.password && touched?.password ? (
                    <small className="text-red mb-2">{errors?.password}</small>
                  ) : (
                    <small></small>
                  )}
                </div>
              </div>
              <div className="input_group_checkout mt-3">
                <div style={{ flex: 1 }}>
                  {" "}
                  <div
                    className="px-3 py-3 mt-2"
                    style={{
                      backgroundColor: "#ededed",
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border:
                        (errors?.birthday || errors.birthMonth) &&
                        "1px solid red",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 10,
                      }}
                    >
                      {" "}
                      <span className="mb-2 bold">Birthday </span>{" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          <Select
                            isSearchable={false}
                            name="month"
                            placeholder="Month"
                            options={months}
                            onChange={(val) =>
                              setFieldValue("birthMonth", val?.value)
                            }
                          />
                        </div>
                        <span className="mx-2"></span>
                        <div style={{ flex: 1 }}>
                          <Select
                            isSearchable={false}
                            name="day"
                            placeholder="Day"
                            options={[
                              ...Array(
                                getDaysInMonth(values.birthMonth)
                              ).keys(),
                            ].map((it) => {
                              return {
                                label: it + 1,
                                value: it + 1,
                              };
                            })}
                            onChange={(val) =>
                              setFieldValue("birthday", val?.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors?.birthday ||
                  (errors.birthMonth && touched?.birthday) ||
                  touched.birthMonth ? (
                    <small className="text-red mb-2">
                      {errors?.birthday || errors.birthMonth}
                    </small>
                  ) : (
                    <small></small>
                  )}
                </div>

                <span className="mx-5"></span>

                <div style={{ flex: 1 }}>
                  <div
                    className="px-3 py-3 mt-2"
                    style={{
                      backgroundColor: "#ededed",
                      flex: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border:
                        (errors?.anniversary || errors.anniversaryMonth) &&
                        "1px solid red",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        zIndex: 9,
                      }}
                    >
                      <span className="mb-2 bold">Anniversary </span>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                          <Select
                            isSearchable={false}
                            name="month"
                            placeholder="Month"
                            options={months}
                            onChange={(val) =>
                              setFieldValue("anniversaryMonth", val?.value)
                            }
                          />
                        </div>
                        <span className="mx-2"></span>
                        <div style={{ flex: 1 }}>
                          <Select
                            isSearchable={false}
                            name="day"
                            placeholder="Day"
                            options={[
                              ...Array(
                                getDaysInMonth(values.anniversaryMonth)
                              ).keys(),
                            ].map((it) => {
                              return {
                                label: it + 1,
                                value: it + 1,
                              };
                            })}
                            onChange={(val) =>
                              setFieldValue("anniversary", val?.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors?.anniversary ||
                  (errors.anniversaryMonth && touched?.anniversary) ||
                  touched.anniversaryMonth ? (
                    <small className="text-red mb-2">
                      {errors?.anniversary || errors.anniversaryMonth}
                    </small>
                  ) : (
                    <small></small>
                  )}
                </div>
              </div>

              <div className="input_group_checkout mt-3">
                <div
                  className="px-3 input_checkout"
                  style={{
                    flex: 1,
                  }}
                >
                  {" "}
                </div>
              </div>

              <div
                className="custom-control custom-checkbox mt-3"
                style={{ zIndex: 0 }}
              >
                <input
                  className="custom-control-input"
                  id="customCheck4"
                  type="checkbox"
                  onChange={handleChange("smsForCouponAndInfo")}
                  value={values.smsForCouponAndInfo}
                />
                <label
                  className="custom-control-label"
                  style={{ marginTop: 3 }}
                  htmlFor="customCheck4"
                >
                  Sign up for SMS coupons and info
                </label>
              </div>

              <div className="custom-control custom-checkbox mt-3">
                <input
                  className="custom-control-input"
                  id="customeCheck3"
                  type="checkbox"
                  onChange={handleChange("agreeTermsAndConditions")}
                  value={values?.agreeTermsAndConditions}
                />
                <label
                  className="custom-control-label mt-1"
                  htmlFor="customeCheck3"
                >
                  I agree to{" "}
                </label>
                <label className="text-sm pointer mx-1 mt-1">
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={() => setOpenTerms(true)}
                  >
                    terms
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={() => setOpen(true)}
                  >
                    policies
                  </span>
                </label>
              </div>
            </div>
          </CardBody>
          <CardFooter className="d-flex justify-content-end">
            <Button
              disabled={!values.agreeTermsAndConditions}
              onClick={() => handleSubmit()}
              style={{ background: theme.primary, color: "white" }}
            >
              {isSubmitting ? <Spinner size={"sm"} /> : "Register"}
            </Button>
          </CardFooter>
        </Card>
      </div>
      <GenericModal
        isOpen={open}
        title="Privacy"
        toggle={() => setOpen(!open)}
        data={
          restaurant?.privacy !== ""
            ? restaurant?.privacy
            : restaurant?.partner?.partner?.privacy
        }
      />
      <GenericModal
        isOpen={openTerms}
        title="Terms"
        toggle={() => setOpenTerms(!openTerms)}
        data={
          restaurant?.terms !== ""
            ? restaurant?.terms
            : restaurant?.partner?.partner?.terms
        }
      />
    </>
  );
};

export default Register;
