export default function getGroupedRows(groupedArray) {
  console.log("GOT THIS->", groupedArray);
  let array = groupedArray.filter((ga) => ga !== undefined || ga);

  const rows = [];

  for (let i = 0; i < array.length; i++) {
    const {
      product_id,
      product_name,
      price,
      halfPrice,
      extraPrice,
      defaultSelected,
      selectedParentValue,
      sort,
      advancePizzaOptions, // Add this line to get advancePizzaOptions from the groupedArray
    } = groupedArray[i];

    for (let j = 0; j < selectedParentValue.length; j++) {
      const { i: rowNumber, ...rest } = selectedParentValue[j];
      const row = {
        product_id,
        product_name,
        price,
        halfPrice,
        extraPrice,
        defaultSelected,
        selectedParentValue: selectedParentValue[j],
        sort,
        advancePizzaOptions, // Add this line to include advancePizzaOptions in the row object
        ...rest,
      };
      rows.push(row);
    }
  }

  return rows;
}
