/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import { Button, Card, Spinner } from "reactstrap";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import swal from "sweetalert";
import CheckoutGraphic from "../../../../assets/icons/checkout_graphic.svg";
import TopNav from "../../../../components/Navbar/TopNav";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import logger from "../../../../utils/logger";
import {
  GuestValidationSchema,
  GuestValidationWalkup,
} from "../../../../utils/schemas/Guest";
import MenuHeader from "../../auth/components/MenuHeader";
import Footer from "../../components/Footer";
import CartSection from "./CartSection";
import CustomerDetails from "./CustomerDetails";
import Guest from "./Guest";
import LoginCustomer from "./LoginCustomer";
import OrderDetails from "./OrderDetails";
import Register from "./Register";
import UserButtons from "./UserButtons";

const components = {
  "sign-in": LoginCustomer,
  guest: Guest,
  register: Register,
};

const CateringEnquiryCheckout = ({ history, menuId }) => {
  const myRef = useRef(null);
  const [activeTab, setActiveTab] = useState("sign-in");
  const authContext = useContext(AuthContext);
  const { create: getBilling, loading: billingLoading } = useEntity(
    "eat/ordering/billing"
  );
  const executeScroll = () => myRef.current.scrollIntoView();
  const { restaurant: orderingRestaurant } = useOrderingRestaurant();
  const { cartItems, coupon } = useCartStore();
  const {
    location: orderingLocation,
    method,
    laterDate,
    laterTime,
    timing,
    deliveryConfirmAddress,
    deliveryZone,
    deliveryMethod,
    setActiveTab: setZustandActiveTab,
    cateringData,
  } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  const { theme } = useTheme();
  const { create: createOrder } = useEntity("eat/ordering/order");

  const { handleChange, values, errors, handleSubmit, touched } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      note: "",
    },
    onSubmit: (values) => {
      orderFormik.setFieldValue("guest_details", { ...values });
      orderFormik.handleSubmit();
    },
    validationSchema:
      method === "walkup" ? GuestValidationWalkup : GuestValidationSchema,
    validateOnMount: true,
  });

  const orderFormik = useFormik({
    initialValues: {
      coupon: "",
      tip: "",
      loyaltyPoints: "",
      orderNotes: "",
      billing: null,
      payment_method: "",
      selected_card: "",
      guest_details: {},
      moreInfoData: {},
    },
    onSubmit: async (values) => {
      const splittedTime = laterTime.split(":");
      const scheduleDate = new Date(laterDate);
      scheduleDate.setHours(splittedTime[0], splittedTime[1], 0, 0);

      console.log(scheduleDate.toISOString(), "DATE");

      let data = {
        customer: authContext?.user?.customer?._id,
        guest: { ...values?.guest_details },
        restaurant: orderingRestaurant?._id,
        method: method,
        orderTiming: timing,
        note: values?.orderNotes || values?.guest_details?.note,
        items: cartItems,
        // coupon: values?._id,
        paymentMethod: values?.payment_method,
        scheduledOn: timing === "later" ? scheduleDate.toISOString() : "",
        location: orderingLocation?._id,
        deliveryAddress: method === "delivery" ? deliveryConfirmAddress : null,
        // tableNo: method === "dine" ? tableNo : "",
        // headCount: method === "catering" ? count : 0,
        loyalty: values.loyaltyPoints || 0,
        tip: values?.billing?.tip || 0,
        deliveryFee:
          (method === "delivery" && values.billing?.deliveryFee) || 0,
        deliveryZoneId: deliveryZone._id,
        deliveryMethod,
        loyaltyDiscount: values?.billing?.loyaltyDiscount,
        orderFee: values?.billing?.orderFee || 0,
        taxDetails: {
          appliedCoupon: values.billing.appliedCoupon,
          serviceCharge: values.billing.serviceCharge,
          taxDetails: values.billing.taxDetails,
        },
        moreInfoData: values.moreInfoData,
        cateringDetails: {
          ...cateringData,
        },
      };

      try {
        // await OrderValidationSchema.validate(data);

        const order = await createOrder({ ...data });

        if (cateringData?.enquiry) {
          history.push(`success?orderId=${order?.data?._id}&orderType=enquiry`);
        } else history.push(`success?orderId=${order?.data?._id}`);
      } catch (error) {
        logger.push({ error, info: "CateringEnquiryCheckout" });

        if (error.message === "payment_failed") {
          toast.error("Payment failed. Please contact administrator");
        } else if (error.message?.includes?.("ms_order_failed")) {
          swal({
            title: "Failed to place order",
            text: "We are unable to send your order to the restaurant. They are offline. Please call the restaurant to place your order",
          });
        } else if (error.message === "location_closed") {
          toast.error(
            "Sorry, The Restaurant is closed right now. Please try again later."
          );
        } else {
          toast.error(error.message);
        }
      }
    },
  });

  const getBillingDetails = async () => {
    try {
      const billingResponse = await getBilling({
        data: [...cartItems],
        tip: orderFormik.values?.tip,
        coupon: coupon || orderFormik.values?.coupon,
        points: orderFormik.values?.loyaltyPoints,
        location: orderingLocation?._id,
        restaurant: restaurant?._id,
        method: method,
        customer: authContext?.user?.customer?._id,
        address: deliveryConfirmAddress,
        deliveryZoneId: deliveryZone?._id,
        deliveryMethod,
        catering: cateringData?.catering,
        menuId: menuId,
      });

      orderFormik.setFieldValue("billing", billingResponse?.data);
    } catch (error) {
      logger.push({ error, info: "CateringEnquiryCheckoutBillingApi" });
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (cartItems?.length > 0 && orderingLocation._id && restaurant?._id) {
      getBillingDetails().then((res) => console.log(res));
    }
  }, [
    orderFormik.values.coupon,
    cartItems,
    orderFormik.values.tip,
    orderFormik.values.loyaltyPoints,
    orderingLocation,
    restaurant,
    coupon,
  ]);

  const Component = components[activeTab];

  useEffect(() => {
    if (authContext?.user) {
      setActiveTab("logged_in");
      setZustandActiveTab("logged_in");
    } else {
      setActiveTab(method === "walkup" ? "guest" : "sign-in");
      setZustandActiveTab(method === "walkup" ? "guest" : "sign-in");
    }
  }, [authContext?.user]);

  return (
    <div>
      <TopNav
        tip={orderFormik.values.tip}
        cart={true}
        startOver={true}
        history={history}
      />
      <div className="only_tab">
        <MenuHeader />
      </div>
      <div className="text-left my-3 mx-3" style={{ minHeight: "100vh" }}>
        <h1 style={{ fontWeight: "400", fontSize: 28 }}>Checkout</h1>
        {!authContext?.user && (
          <UserButtons activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        {authContext?.user && (
          <CustomerDetails
            getOrderNotes={(val) =>
              orderFormik.setFieldValue("orderNotes", val)
            }
          />
        )}
        {!authContext?.user && (
          <div className="mt-4" ref={myRef}>
            <Component
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              toggle={() => setActiveTab("sign-in")}
            />
          </div>
        )}

        <Card
          className="px-3 py-3"
          style={{
            boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className="d-flex justify-content-between"
            style={{ overflow: "none", height: "max-content" }}
          >
            <div className="right_border" style={{ flex: 1.5 }}>
              <div className="py-4">
                <h1
                  style={{
                    fontWeight: "600",
                    color: theme.primary,
                  }}
                >
                  Order Details
                </h1>
                <hr className="my-4" style={{ padding: 0, margin: 0 }} />
                <OrderDetails />
                <hr
                  className="my-4 hide_mobile"
                  style={{ padding: 0, margin: 0 }}
                />
              </div>
              <CartSection
                billingDetails={orderFormik.values.billing}
                method={method}
                loading={billingLoading}
                couponDoc={null}
              />
            </div>
            <div className="hide mx-5"></div>
            <div
              className="hide "
              style={{
                overflow: "none",
                width: "100%",
                flex: 1,
                backgroundImage: `url(${CheckoutGraphic})`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        </Card>

        <div className="mt-4">
          <Button
            className="py-3"
            block
            style={{
              ...styles.btnStyle,
              backgroundColor: theme.primary,
              color: "white",
            }}
            disabled={
              orderFormik?.values?.billing?.disableOrdering ||
              cartItems?.length <= 0 ||
              billingLoading ||
              activeTab === "sign-in" ||
              activeTab === "register" ||
              orderFormik.isSubmitting
            }
            onClick={() => {
              orderFormik.setSubmitting(true);
              if (
                Object.keys(errors).length === 0 ||
                authContext?.user?.customer
              ) {
                if (!authContext?.user) {
                  handleSubmit();
                  return;
                }

                orderFormik.handleSubmit();
              } else {
                executeScroll();
                orderFormik.setSubmitting(false);
                handleSubmit();
                return;
              }
            }}
          >
            {orderFormik.isSubmitting ? (
              <Spinner size={"sm"} />
            ) : (
              "Submit Order Inquiry"
            )}
          </Button>
          {orderFormik?.values?.billing?.disableOrdering && (
            <small className="text-lg mt-4">
              NOTE: Minimum Subtotal should be $
              {deliveryZone?.minimumDeliveryAmount}
            </small>
          )}
        </div>

        <hr />
        <div className="mb-8">
          <Footer showCardLogo={true} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  btnStyle: {
    color: "white",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
  },
  sliderStyle: {
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  suggestedProductStyle: {
    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    borderRadius: "6px",
  },
};

export default CateringEnquiryCheckout;
