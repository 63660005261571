/* eslint-disable react-hooks/exhaustive-deps */
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useContext, useEffect, useState } from "react";
// reactstrap components
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
import { useRestaurantStore } from "../../../../store/restaurant";
import SuperTableDnd from "../../../../widgets/SuperTableDnd";
import { OptionsRow } from "../components/OptionsRow";

function OptionsTab(props) {
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const query = useQueryClient();
  const { create: updateSortOrder } = useEntity("modifier-group/sort-order");

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const items = Array.from(data);

    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    setData([...items]);

    setIsSortOrderChanged(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = !restaurantDoc?.restaurant?.enable_deliverect
    ? ["Modiefier Name", "Sort Order", "ID", "Type", "Action"]
    : ["Modiefier Name", "Sort Order", "ID", "Type"];

  const { find, entities, loading } = useEntity("modifier-group");

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
  }, []);

  useEffect(() => {
    if (entities?.data) {
      setData([...entities?.data]);
    }
  }, [entities?.data]);

  const handleSaveSortOrder = async () => {
    const IdsAndPos = data.map((d, idx) => {
      const obj = { _id: d._id, sortOrder: idx };
      return obj;
    });

    try {
      await updateSortOrder({ sortOrders: IdsAndPos });
      await query.invalidateQueries("find-modifier-group");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }

    setIsSortOrderChanged(false);
  };

  useEffect(() => {
    if (isSortOrderChanged) {
      handleSaveSortOrder();
    }
  }, [isSortOrderChanged]);

  return (
    <>
      {!loading ? (
        <SuperTableDnd
          sort={handleSort}
          showCheckboxes={false}
          data={data}
          onPageChange={handlePageChange}
          OnRowPerPageChange={handleRowsPerPageChange}
          total={entities?.length}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          loading={loading}
          history={props.history}
          row={OptionsRow}
          columns={Headers}
          handleDragEnd={handleDragEnd}
          isLoading={loading}
          emptyMessage="Currently no modifiers"
          prevPage={data?.prevPage}
          nextPage={data?.nextPage}
          currentPage={data?.currentPage}
        />
      ) : (
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      )}
    </>
  );
}

export default OptionsTab;
