import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import { useEntity } from "../../../../hooks/useEntity";
import serviceCaller from "../../../../api/serviceCaller";
import apiCaller from "../../../../api/apiCaller";

export default function CopyMenuModal({
  isVisible,
  toggle,
  onClose,
  locations,
  setMenu,
}) {
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      location: "",
    },
    onSubmit: async () => {
      try {
        const entities = await apiCaller("/menu", {
          query: {
            location: values.location,
          },
        });
        setMenu(entities?.data?.data?.[0]);
        toggle();
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isVisible}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <h1 className="display-4">Select Location</h1>
          </CardHeader>
          <CardBody className="">
            <Row>
              <Col md="12" className="my-2">
                <FormGroup className="text-left">
                  <label className="text-left">Location</label>
                  <Input
                    onChange={handleChange}
                    type="select"
                    name="location"
                    value={values.location}
                  >
                    <option value={null}>Select</option>
                    {[...locations]?.map((item) => {
                      return (
                        <option key={item?._id} value={item?._id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-4"
                color="primary"
                type="button"
                onClick={handleSubmit}
                style={{ width: 150 }}
              >
                Copy
              </Button>

              <Button
                onClick={onClose}
                style={{ width: 150 }}
                className="mt-4"
                color="warning"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
