import { FaTrash } from "react-icons/fa"
import { RiMastercardLine, RiVisaLine } from "react-icons/ri"
import { useTheme } from "../../../../store/theme"
import EmptyPlaceholder from "../../menu/components/EmptyPlaceholder"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { Button, Spinner } from "reactstrap"
import { useState } from "react"
import useOrdering from "../../../../store/ordering"
import { toast } from "react-toastify"
import { useCartStore } from "../../../../store"

const Table = ({
  cards,
  showRadio,
  setSelectedIndex,
  selectedIndex,
  clientSecret,
  onSuccess,
  stripeCustomerId,
  formik,
  gateway,
}) => {
  const { theme } = useTheme()
  const stripe = useStripe()
  const elements = useElements()
  const { activeTab } = useOrdering()
  const { cartItems } = useCartStore()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    if (!stripe || !elements) {
      setLoading(false)
      return
    }

    const token = cards?.data[selectedIndex]

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: token?.token?.token,
        save_payment_method: true,
      })

    if (stripeError) {
      toast.error(stripeError.message)
      setLoading(false)
      return
    }

    if (paymentIntent.status === "succeeded" && activeTab !== "guest") {
      onSuccess({ ...paymentIntent, stripeCustomerId })
    }
  }

  return (
    <div>
      <div>
        <table style={{ width: "100%" }}>
          <tr
            style={{ color: theme?.primary, lineHeight: 2 }}
            className="payment_table_header"
          >
            <th className="text-left">Select</th>
            <th>Number</th>

            <th>Type</th>
          </tr>
          {(cards?.data?.length === 0 || !cards?.data) && (
            <tr>
              <td colSpan={4} className="text-center p-4">
                <EmptyPlaceholder />
              </td>
            </tr>
          )}
          {cards?.data?.map((card, i) => {
            return (
              <>
                <tr
                  style={{
                    textAlign: "left",
                    lineHeight: 3,
                    borderBottom:
                      !showRadio &&
                      i !== cards?.data?.length - 1 &&
                      "2px solid #ededed",
                  }}
                >
                  {showRadio && (
                    <td>
                      <input
                        onClick={() => {
                          setSelectedIndex(i)
                        }}
                        checked={i === selectedIndex}
                        type="radio"
                      />
                    </td>
                  )}
                  <td>{card.truncatedCardNumber}</td>
                  <td>
                    {card?.cardLogo === "Visa" ? (
                      <RiVisaLine size={35} />
                    ) : card?.cardLogo === "Mastercard" ? (
                      <RiMastercardLine size={35} />
                    ) : (
                      card.cardLogo
                    )}
                  </td>
                  {!showRadio && (
                    <td className="mx-6 text-center">
                      <FaTrash color={theme?.primary} />
                    </td>
                  )}
                </tr>
              </>
            )
          })}
        </table>
      </div>
      <Button
        onClick={handleSubmit}
        block
        disabled={
          loading ||
          formik?.values?.billing?.disableOrdering ||
          cartItems?.length <= 0 ||
          activeTab === "sign-in" ||
          activeTab === "register" ||
          formik.isSubmitting
        }
        className="mt-4"
        style={{
          backgroundColor: theme.primary,
          color: "white",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        }}
      >
        {loading ? <Spinner size={"sm"} /> : "Place Order"}
      </Button>
    </div>
  )
}

const CardsTableStripe = ({
  cards,
  setSelectedIndex,
  selectedIndex,
  showRadio,
  clientSecret,
  stripeCustomerId,
  gateway,
  onSuccess,
  formik,
}) => {
  const stripePromise = loadStripe(gateway?.stripePublishableKey)

  const appearance = {
    theme: "stripe",
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <Table
            cards={cards}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            showRadio={showRadio}
            clientSecret={clientSecret}
            stripeCustomerId={stripeCustomerId}
            onSuccess={onSuccess}
            formik={formik}
          />
        </Elements>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"lg"} />
        </div>
      )}
    </>
  )
}

export default CardsTableStripe
