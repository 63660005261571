/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import { useFormik } from "formik";
import { generateUsername } from "friendly-username-generator";
import { useContext, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Select2 from "react-select2-wrapper";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import CreateIpadDevice from "../../../components/modal/CreateIpadDevice";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import {
  deliveryZoneArrayValidation,
  validationSchemaAddLocation,
  validationSchemaCateringSettings,
  validationSchemaEditLocation,
} from "../../../utils/schemas/AddLocation";
import timezones from "../../../utils/timezones.js";
import TableWrapper from "../../../widgets/TableWrapper";
import LocationSearchInput from "../../ordering/components/LocationSearchInput";
import LocationIntegrations from "./location/Integration";
import { PickupDelivery } from "./tabs/location/PickupDelivery";
import WalkupOrdering from "./tabs/location/WalkupOrdering";
import Catering from "./tabs/location/Catering";
import EmptyPlaceholder from "../../ordering/menu/components/EmptyPlaceholder";
import MenuocityPrinter from "./components/MenuocityPrinter";

export const ErrorLable = ({ message }) => {
  return (
    <span style={{ color: "red" }}>
      <span>
        <AiOutlineInfoCircle className="mr-1" size={12} />
      </span>
      <span className="text-xs">{message}</span>
    </span>
  );
};

export default function AddLocation(props) {
  const locationID = useLocation();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [addDevice, setAddDevice] = useState(false);
  const [categoryMac, setCategoryMac] = useState("");
  const [removedMac, setRemovedMac] = useState([]);

  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const { findOne, entity } = useEntity("location");
  const { create: removeMac } = useEntity("location/remove-mac");
  const { find, entities, deleteEntity } = useEntity("ipad-device");
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const { create: createLocation, updateEntity } = useEntity("location");
  const { create: addTestPrintToQueue, loading: isSubmitting } =
    useEntity("print/test");

  const [finalMac] = useState();

  const [tabs, setTabs] = useState(1);

  const defaultAvailabilitySchedule = {
    pickup: {
      startTime: "10:00",
      endTime: "22:00",
    },
    delivery: {
      startTime: "",
      endTime: "",
    },
    availableOn: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ Your logic here
        console.log("PRESSED");
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const defaultAvailability = [
    {
      day: "Monday",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
    {
      day: "Tuesday",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
    {
      day: "Wednesday",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
    {
      day: "Thursday",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
    {
      day: "Friday",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
    {
      day: "Saturday",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
    {
      day: "Sunday ",
      deliveryTo: "",
      addPickupTime: false,
      addDeliveryTime: false,
      deliveryFrom: "",
      pickupFrom: "10:00",
      pickupTo: "22:00",
    },
  ];

  useEffect(() => {
    if (props?.location?.state) {
      findOne(props.location.state);
      find({ locationId: props.location.state });
    }
  }, [props]);

  const formik = useFormik({
    initialValues: {
      status: true,
      general_sortOrder: 0,
      cateringShop: false,
      general_locationName: "",
      dineInMethod: "",
      general_timezone: "Pacific Standard Time",
      general_displayAddress: "",
      general_phone: "",
      general_email: "",
      printEmail: "",
      fax: "",
      serviceCharge: false,
      pickup: true,
      pickupPrepTime: 15,
      enableDelivery: false,
      enableTaxOnOrderFee: false,
      enableTaxOnDeliveryFee: false,
      deliveryFee: 0,
      minimumDeliveryAmount: 0,
      deliveryReadyTime: 45,
      deliveryFeeSetting: "always",
      deliveryOption: "geofence",
      forwardPrint: false,
      deliveryZone: "",
      print: false,
      smsAlertPhone: "",
      automatedCall: false,
      numberOfPrints: 1,
      locationBasedTax: false,
      location_tax_title: "",
      general_address: "",
      enableDeliverect: restaurantDoc?.restaurant?.enable_deliverect,
      deliverect_client_secret:
        restaurantDoc?.restaurant?.deliverect_client_secret,
      deliverect_client_id: restaurantDoc?.restaurant?.deliverect_client_id,
      deliverect_channel_name: "",
      deliverect_channel_link_id: "",
      location_tax: 0,
      macAddress: "",
      enableDineIn: false,
      enableWalkupOrdering: false,
      disableNormalOrdering: false,
      service_charge_title: "",
      service_charge_percentage: 0,
      txnReport: false,
      txnReportFrequency: [],
      txnReportEmail: "",
      closeOfBusiness: false,
      closeOfBusinessEmail: "",
      orderEmail: "",
      geofencing: [],
      JKSoftLocationId: "",
      latLng: [0, 0],
      deliveryRadius: 1,
      walkupOrderingPaymentMethods: [],
      streetAddress: "",
      state: "",
      postal: "",
      city: "",
      country: "",
      ipadUsername: "",
      ipadPassword: "",
      ihdLocationId: "",
      enableIhd: false,
      ihdClientSecret: "",
      ihdClientId: "",
      orderAlertPhoneNumber: "",
      customPrepTimes: [],
      customPrepTimesEnabled: false,
      enableKitchenHub: false,
      kitchenHubLocationId: "",
      externalLinkId: "",
      deliveryZones: [],
      cateringDeliveryZones: [],
      cateringMethod: "",
      cateringOrderFee: "",
      cateringPrepTimes: [],
      cateringMinHeadCount: 0,
      cateringHours: [],
      categoryMacAddress: [],
      menuocityPrinters: [],
      sendCateringOrderToJksoft: false,
    },
    validationSchema: entity?.data?.ordering?.enableWalkupOrdering
      ? validationSchemaEditLocation
      : validationSchemaAddLocation,
    validateOnChange: false,

    onSubmit: async (values) => {
      setLoadingBtn(true);

      const data = {
        resturant: authRest || restaurant,
        status: formik.values.status,
        sortOrder: formik.values.general_sortOrder,
        name: formik.values.general_locationName,
        timezone: formik.values?.general_timezone,
        displayAddress: formik.values.general_displayAddress,
        address: formik.values?.general_address,
        streetAddress: formik.values?.streetAddress,
        state: formik.values?.state,
        dineInMethod: formik.values?.dineInMethod,
        postal: formik.values?.postal,
        city: formik.values?.city,
        country: formik.values?.country,
        forwardPrint: formik.values.forwardPrint,
        phone: formik.values.general_phone,
        email: formik.values.general_email,
        orderEmail: formik.values.orderEmail,
        smsAlertPhone: formik.values.smsAlertPhone,
        print: formik.values?.print,
        cateringShop: formik.values?.cateringShop || false,
        fax: formik.values.fax,
        serviceCharge: formik.values.serviceCharge,
        serviceChargeTitle: formik.values.service_charge_title,
        serviceChargePercentage: formik.values.service_charge_percentage || 0,
        enableTaxOnOrderFee: formik.values.enableTaxOnOrderFee,
        enableTaxOnDeliveryFee: formik.values.enableTaxOnDeliveryFee,
        latLng: formik.values.latLng,
        enableIhd: formik.values.enableIhd,
        ihdClientId: formik.values.ihdClientId?.trim(),
        ihdClientSecret: formik.values.ihdClientSecret?.trim(),
        ihdLocationId: formik.values.ihdLocationId?.trim(),
        ihdReadyTime: formik.values.ihdReadyTime,
        numberOfPrints: formik.values.numberOfPrints,
        ordering: {
          printRequired: formik.values.print,
          macAddresses: finalMac,
          smsAlertPhone: formik.values.smsAlertPhone,
          automatedCall: formik.values.automatedCall,
          everyOrder: false,
          printFail: false,
          locationBasedTax: formik.values.locationBasedTax,
          locationTaxTitle: formik.values?.location_tax_title,
          printGenieStatus: formik.values.printGenieStatus,
          ipadRefreshTimeInMin: formik.values?.ipadRefreshTimeInMin,
          hideCatPrice: false,
          hidePrepTime: false,
          locationTax: formik.values?.location_tax || 0,
          enableDineIn: formik.values.enableDineIn,
          enableWalkupOrdering: formik.values.enableWalkupOrdering,
          disableNormalOrdering: formik.values.disableNormalOrdering,
        },
        pickupDelivery: {
          enablePickup: formik.values.pickup,
          pickupPrepTime: formik.values.pickupPrepTime,
          enableDelivery: formik.values.enableDelivery,
          deliveryZones: formik.values.deliveryZones,
        },

        itemPrint: formik.values?.itemPrint,
        enableDeliverect: formik.values?.enableDeliverect,
        deliverect_client_secret: formik.values.deliverect_client_secret,
        deliverect_client_id: formik.values.deliverect_client_id,
        deliverect_channel_name: formik.values.deliverect_channel_name,
        deliverect_channel_link_id: formik.values.deliverect_channel_link_id,
        macAddress: formik.values?.macAddress?.toLowerCase(),
        walkupOrderingPaymentMethods: Object.values(
          formik.values.walkupOrderingPaymentMethods
        ),
        ipadUsername: formik.values.ipadUsername,
        ipadPassword: formik.values.ipadPassword,
        orderAlertPhoneNumber: formik.values.orderAlertPhoneNumber,
        customPrepTimes: formik.values.customPrepTimes,
        customPrepTimesEnabled: formik.values.customPrepTimesEnabled,
        kitchenHubLocationId: formik.values.kitchenHubLocationId,
        enableKitchenHub: formik.values.enableKitchenHub,
        txnReport: formik.values.txnReport,
        txnReportFrequency: formik?.values?.txnReportFrequency?.map(
          (freq) => freq.value
        ),
        txnReportEmail: formik.values.txnReportEmail,
        closeOfBusiness: formik.values.closeOfBusiness,
        closeOfBusinessEmail: formik.values.closeOfBusinessEmail,
        enableCatering: formik.values.enableCatering,
        cateringMethod: formik.values.cateringMethod,
        cateringOrderFee: formik.values.cateringOrderFee,
        cateringPrepTimes: formik.values?.cateringPrepTimes,
        cateringMinHeadCount: formik.values.cateringMinHeadCount,
        cateringDeliveryZones: formik.values.cateringDeliveryZones,
        cateringHours: formik.values.cateringHours,
        categoryMacAddress: formik.values.categoryMacAddress,
        menuocityPrinters: formik?.values?.menuocityPrinters,
        sendCateringOrderToJksoft: formik?.values?.sendCateringOrderToJksoft,
      };

      try {
        if (formik.values.cateringShop) {
          if (!formik.values.cateringDeliveryZones) {
            throw new Error("No delivery zone defined for catering");
          }

          await validationSchemaCateringSettings.validate({
            cateringMinHeadCount: formik.values.cateringMinHeadCount || 0,
            cateringPrepTimes: formik.values.cateringPrepTimes,
            cateringOrderFee: formik.values.cateringOrderFee,
          });
          if (
            (formik.values.cateringMethod === "delivery" ||
              formik.values.cateringMethod === "both") &&
            formik.values.cateringDeliveryZones.length <= 0
          ) {
            throw new Error("No delivery zone defined for catering");
          }
        }
      } catch (error) {
        toast.error(error.message);
        setLoadingBtn(false);
        return;
      }

      try {
        const prms = removedMac.map((r) =>
          removeMac({
            restaurant: authRest || restaurant,
            mac: r,
          })
        );
        await Promise.all(prms);
        if (formik.values.enableDelivery) {
          await deliveryZoneArrayValidation.validate(
            formik.values.deliveryZones
          );
        }
        if (!props.location.state) {
          await createLocation({
            ...data,
            hoursOfOperation: defaultAvailability,
            schedule: { ...defaultAvailabilitySchedule },
          });
          props.history.push({
            pathname: "/resturants/settings",
            state: { activeTab: 2 },
            search: locationID.search,
          });
          toast.success("Location created successfully");
        } else {
          await updateEntity(props.location.state, {
            ...data,
            // schedule: { ...defaultAvailabilitySchedule },
          });
          // props.history.push({
          //   pathname: "/resturants/settings",
          //   state: { activeTab: 2 },
          //   search: locationID.search,
          // });
          toast.success("Location Updated Successfully");
        }
      } catch (error) {
        console.log(error);

        swal("Error occured", error.message, "error");
      }

      setLoadingBtn(false);
    },
  });

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {
    if (entity) {
      formik.setValues({
        status: entity?.data?.status,
        general_sortOrder: entity?.data?.sortOrder,
        cateringShop: entity?.data?.cateringShop || false,
        general_locationName: entity?.data?.name,
        general_timezone: entity?.data?.timezone,
        numberOfPrints: entity?.data?.numberOfPrints,
        general_address: entity?.data?.address,
        general_displayAddress: entity?.data?.displayAddress,
        forwardPrint: entity?.data?.forwardPrint,
        dineInMethod: entity?.data?.dineInMethod,
        general_phone: entity?.data?.phone,
        general_email: entity?.data?.email,
        latLng: entity?.data?.latLng,
        JKSoftLocationId: entity?.data.JKSoftLocationId,
        serviceCharge: entity?.data?.serviceCharge,
        service_charge_title: entity?.data?.serviceChargeTitle,
        service_charge_percentage: entity?.data?.serviceChargePercentage || 0,
        pickup: entity?.data?.pickupDelivery?.enablePickup,
        ihdReadyTime: entity?.data?.ihdReadyTime || 0,
        pickupPrepTime: entity?.data?.pickupDelivery?.pickupPrepTime,
        location_tax_title: entity?.data?.ordering?.locationTaxTitle,
        enableDelivery: entity?.data?.pickupDelivery?.enableDelivery,
        deliveryFee: entity?.data?.pickupDelivery?.deliveryFee,
        geofencing: entity?.data?.pickupDelivery.geofencing,
        minimumDeliveryAmount:
          entity?.data?.pickupDelivery?.minimumDeliveryAmount,
        // deliveryFeeSetting: entity?.data?.pickupDelivery?.deliveryFeeSetting,
        // deliveryReadyTime: entity?.data?.pickupDelivery?.deliveryReadyTime,
        // deliveryRadius: entity?.data?.pickupDelivery?.deliveryRadius,
        // deliveryOption: entity?.data?.pickupDelivery?.deliveryOption,
        deliveryZone: entity?.data?.pickupDelivery?.deliveryZone,
        print: entity?.data?.print,
        locationBasedTax: entity?.data?.ordering?.locationBasedTax,
        enableDeliverect: entity?.data?.enableDeliverect,
        deliverect_channel_name: entity?.data?.deliverect_channel_name,
        deliverect_channel_link_id: entity?.data?.deliverect_channel_link_id,
        location_tax: entity?.data?.ordering?.locationTax || 0,
        macAddress: entity?.data?.macAddress,
        enableDineIn: entity?.data?.ordering?.enableDineIn,
        orderEmail: entity?.data?.orderEmail,
        smsAlertPhone: entity?.data?.smsAlertPhone,
        enableWalkupOrdering: entity?.data?.ordering?.enableWalkupOrdering,
        disableNormalOrdering: entity?.data?.ordering?.disableNormalOrdering,
        enableTaxOnOrderFee: entity?.data?.enableTaxOnOrderFee,
        enableTaxOnDeliveryFee: entity?.data?.enableTaxOnDeliveryFee,
        walkupOrderingPaymentMethods:
          entity?.data?.walkupOrderingPaymentMethods || [],
        streetAddress: entity?.data?.streetAddress,
        state: entity?.data?.state,
        postal: entity?.data?.postal,
        city: entity?.data?.city,
        country: entity?.data?.country,
        ipadUsername: entity?.data?.ipadDevice?.username,
        ipadPassword: entity?.data?.ipadDevice?.passwordText,
        ihdLocationId: entity?.data?.ihdLocationId,
        enableIhd: entity?.data?.enableIhd,
        ihdClientId: entity?.data?.ihdClientId,
        ihdClientSecret: entity?.data?.ihdClientSecret,
        orderAlertPhoneNumber: entity?.data?.orderAlertPhoneNumber,
        customPrepTimes: entity?.data?.customPrepTimes,
        customPrepTimesEnabled: entity?.data?.customPrepTimesEnabled,
        enableKitchenHub: entity?.data?.enableKitchenHub,
        kitchenHubLocationId: entity?.data?.kitchenHubLocationId,
        externalLinkId: entity?.data?.externalLinkId,
        deliveryZones: entity?.data?.pickupDelivery?.deliveryZones || [],
        txnReport: entity?.data?.txnReport,
        txnReportFrequency: entity?.data?.txnReportFrequency?.length
          ? entity?.data?.txnReportFrequency?.map((freq) => {
              return {
                label: freq?.charAt(0)?.toUpperCase() + freq?.slice(1),
                value: freq,
              };
            })
          : [],
        txnReportEmail: entity?.data?.txnReportEmail,
        closeOfBusiness: entity?.data?.closeOfBusiness,
        closeOfBusinessEmail: entity?.data?.closeOfBusinessEmail,
        enableCatering: entity?.data?.enableCatering,
        cateringMethod: entity?.data?.cateringMethod,
        cateringOrderFee: entity?.data?.cateringOrderFee,
        cateringPrepTimes: entity.data?.cateringPrepTimes,
        cateringMinHeadCount: entity?.data?.cateringMinHeadCount,
        cateringDeliveryZones: entity.data.cateringDeliveryZones,
        cateringHours: entity?.data?.cateringHours,
        categoryMacAddress: entity?.data?.categoryMacAddress || [],
        menuocityPrinters: entity?.data?.menuocityPrinters || [],
        sendCateringOrderToJksoft: entity?.data?.sendCateringOrderToJksoft,
      });
    }
  }, [entity]);

  const handleWalkUpOrderingPaymentMethods = (e) => {
    const method = e.target.dataset.value;
    const checked = e.target.checked;
    let existingValues = formik.values.walkupOrderingPaymentMethods;
    if (checked) {
      existingValues.values.push(method);
    } else {
      existingValues = existingValues.values.filter((v) => v !== method);
    }

    const _values = {
      ...formik.values,
      walkupOrderingPaymentMethods: existingValues,
    };

    formik.setValues(_values);
  };

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0 && formik.touched) {
      Object.keys(formik.errors)?.map((er) => {
        if (formik.errors && er.includes("general") && formik.touched) {
          setTabs(1);
        } else if (
          formik.errors &&
          (er.includes("location") || er.includes("service")) &&
          formik.touched
        ) {
          setTabs(3);
        }
      });
    }
  }, [formik.errors, formik.touched]);

  const handleGenerate = (type) => {
    const generated = generateUsername({ useHyphen: false });
    formik.setFieldValue(type, generated);
  };

  const copyToClipboard = (val) => {
    navigator?.clipboard?.writeText?.(val);
    toast.success("Copied");
  };

  const handleAddCategoryMac = () => {
    console.log("ADDING", categoryMac);
    formik.setFieldValue("categoryMacAddress", [
      ...formik.values.categoryMacAddress,
      categoryMac.toLowerCase(),
    ]);
    setCategoryMac("");
  };

  const handleRemoveCategoryMac = (idx) => {
    const values = formik.values.categoryMacAddress;
    //API CALL
    setRemovedMac([...removedMac, formik.values.categoryMacAddress[idx]]);
    values.splice(idx, 1);
    formik.setFieldValue("categoryMacAddress", [...values]);
    setCategoryMac("");
  };

  return (
    <>
      <CreateIpadDevice
        location={props.location.state}
        isOpen={addDevice}
        toggle={() => {
          setAddDevice(false);
        }}
        handleGenerate={handleGenerate}
      />
      <TimelineHeader
        name={"Add Location"}
        parentName="Locations"
        showBtn={true}
        path="/resturants/settings"
      >
        <Button color="primary" size="md" onClick={formik.handleSubmit}>
          {loadingBtn ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button
          className="btn-neutral text-black"
          color="default"
          size="md"
          onClick={() => {
            props.history.push({
              pathname: "/resturants/settings",
              state: { activeTab: 2 },
              search: locationID.search,
            });
          }}
        >
          Cancel
        </Button>
      </TimelineHeader>

      <TableWrapper customStyles="py-3 px-1">
        <Nav
          className="nav-fill flex-column flex-sm-row px-3"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-user mr-2" />
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-map-marker mr-2" />
              Notification
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 3}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 3,
              })}
              onClick={(e) => toggleNavs(e, 3)}
              href="#pablo"
              role="tab"
            >
              <i
                className={
                  "fa fa-cutlery mr-2" +
                  ` ${tabs === 3 ? "text-white" : "text-primary"}`
                }
                style={{ fontSize: 16 }}
                aria-hidden="true"
              ></i>
              Taxes/Fees
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              aria-selected={tabs === 5}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 5,
              })}
              onClick={(e) => toggleNavs(e, 5)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-money mr-2" />
              Pickup / Delivery
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 4}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 4,
              })}
              onClick={(e) => toggleNavs(e, 4)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Reports
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 6}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 6,
              })}
              onClick={(e) => toggleNavs(e, 6)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Integrations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 7}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 7,
              })}
              onClick={(e) => toggleNavs(e, 7)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Dine in
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 10}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 10,
              })}
              onClick={(e) => toggleNavs(e, 10)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Catering
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 12}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 12,
              })}
              onClick={(e) => toggleNavs(e, 12)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Menuocity Printer
            </NavLink>
          </NavItem>

          {restaurantDoc?.restaurant?.enable_deliverect && (
            <NavItem>
              <NavLink
                aria-selected={tabs === 8}
                className={classnames("mb-sm-3 mb-md-0 mt-2", {
                  active: tabs === 8,
                })}
                onClick={(e) => toggleNavs(e, 8)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-clock-o mr-2" />
                Deliverect
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem>
            <NavLink
              aria-selected={tabs === 9}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 9,
              })}
              onClick={(e) => toggleNavs(e, 9)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Walk Up
            </NavLink>
          </NavItem> */}
        </Nav>

        <TabContent
          className="mt-5 mx-4"
          style={{ minHeight: "50vh" }}
          activeTab={"tabs" + tabs}
        >
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <Row>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Location ID
                    </Label>
                    <Col md="7">
                      <Input
                        type="text"
                        value={formik.values.externalLinkId}
                        className={`form-control ${
                          formik.errors.externalLinkId &&
                          formik.touched.externalLinkId
                            ? "is-invalid"
                            : ""
                        }`}
                        disabled
                      />
                      {formik.errors.externalLinkId &&
                        formik.touched.externalLinkId && (
                          <ErrorLable message={formik.errors.externalLinkId} />
                        )}
                    </Col>
                    <Col md="1">
                      <Button
                        onClick={() =>
                          copyToClipboard(formik.values.externalLinkId)
                        }
                      >
                        <BiCopy />
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Location Name
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        value={formik.values.general_locationName}
                        onChange={formik.handleChange("general_locationName")}
                        placeholder="Title"
                        className={`form-control ${
                          formik.errors.general_locationName &&
                          formik.touched.general_locationName
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.general_locationName &&
                        formik.touched.general_locationName && (
                          <ErrorLable
                            message={formik.errors.general_locationName}
                          />
                        )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Address
                    </Label>
                    <Col md="8">
                      <LocationSearchInput
                        value={formik.values.general_address}
                        disabled={false}
                        setFieldValue={formik.setFieldValue}
                        onChangeAddress={(addr) => {
                          formik.setFieldValue(
                            "general_address",
                            addr.formatted_address
                          );
                        }}
                        getCoordinates={(latlng) => {
                          formik.setFieldValue("latLng", [
                            latlng?.lat,
                            latlng.lng,
                          ]);
                        }}
                      />
                      {formik.errors.general_address &&
                      formik.touched.general_address ? (
                        <ErrorLable message={formik.errors.general_address} />
                      ) : (
                        <small>*Please use autocomplete</small>
                      )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Display Address
                    </Label>
                    <Col md="8">
                      <Input
                        type="textarea"
                        placeholder="Display Address"
                        rows="3"
                        value={formik.values.general_displayAddress}
                        onChange={formik.handleChange("general_displayAddress")}
                        className={`form-control ${
                          formik.errors.general_displayAddress &&
                          formik.touched.general_displayAddress
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.general_displayAddress &&
                        formik.touched.general_displayAddress && (
                          <ErrorLable
                            message={formik.errors.general_displayAddress}
                          />
                        )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>

              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Phone
                    </Label>
                    <Col md="8">
                      <Input
                        type="number"
                        value={formik.values.general_phone}
                        onChange={formik.handleChange("general_phone")}
                        placeholder="Enter Mobile Phone"
                        className={`form-control ${
                          formik.errors.general_phone &&
                          formik.touched.general_phone
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.general_phone &&
                        formik.touched.general_phone && (
                          <ErrorLable message={formik.errors.general_phone} />
                        )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Email
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        value={formik.values.general_email}
                        onChange={formik.handleChange("general_email")}
                        placeholder="Enter email"
                        className={`form-control ${
                          formik.errors.general_email &&
                          formik.touched.general_email
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.general_email &&
                        formik.touched.general_email && (
                          <ErrorLable message={formik.errors.general_email} />
                        )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Sort Order
                    </Label>
                    <Col md="8">
                      <Input
                        type="number"
                        placeholder="Enter sort order"
                        value={formik.values.general_sortOrder}
                        onChange={formik.handleChange("general_sortOrder")}
                        className={`form-control ${
                          formik.errors.general_sortOrder &&
                          formik.touched.general_sortOrder
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.general_sortOrder &&
                      formik.touched.general_sortOrder ? (
                        <ErrorLable message={formik.errors.general_sortOrder} />
                      ) : (
                        <small>
                          * Location will be sorted based on this value. please
                          enter any numeric value.
                        </small>
                      )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Timezone
                    </Label>
                    <Col md="8">
                      <Select2
                        className="form-control"
                        isSearchable={false}
                        options={{
                          placeholder:
                            formik.values?.general_timezone ||
                            "Select timezone",
                        }}
                        value={formik.values.general_timezone || ""}
                        data={timezones}
                        onSelect={(e) => {
                          formik.setFieldValue(
                            "general_timezone",
                            e.target.value
                          );
                        }}
                      />
                      {formik.errors.general_timezone &&
                        formik.touched.general_timezone && (
                          <ErrorLable
                            message={formik.errors.general_timezone}
                          />
                        )}
                    </Col>
                  </FormGroup>
                </Form>
              </Col>

              <Col md="12" className="mt-2">
                <Row>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: 10,
                    }}
                  >
                    <h4>IPAD Devices</h4>
                  </Col>
                  <Col
                    md="8"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: 10,
                    }}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        setAddDevice(true);
                      }}
                      size="sm"
                      type="submit"
                    >
                      Add New
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Table responsive className="align-items-center mt-2">
                <thead className="thead-light">
                  <tr>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {entities?.data?.length > 0 ? (
                  <tbody>
                    {console.log(entities)}
                    {entities?.data?.map((device) => {
                      return (
                        <tr>
                          <td>
                            <Form>
                              <FormGroup className="row">
                                <Col md="6">{device?.username}</Col>
                              </FormGroup>
                            </Form>
                          </td>
                          <td>
                            <Form>
                              <FormGroup className="row">
                                <Col md="6">{device?.passwordText}</Col>
                              </FormGroup>
                            </Form>
                          </td>
                          <td>
                            <FormGroup
                              className="row"
                              style={{
                                justifyContent: "left",
                                paddingLeft: 17,
                              }}
                            >
                              <Col md="6">{device?.status}</Col>
                            </FormGroup>
                          </td>

                          <td>
                            <FormGroup
                              className="row"
                              style={{
                                justifyContent: "left",
                                paddingLeft: 30,
                              }}
                            >
                              <i
                                onClick={async () => {
                                  if (entities.data.length > 1) {
                                    await deleteEntity(device._id);
                                    swal(
                                      "Success",
                                      "Device Deleted",
                                      "success"
                                    );
                                    return;
                                  }
                                  swal(
                                    "Failed",
                                    "Default device can't be deleted",
                                    "error"
                                  );
                                }}
                                style={{
                                  fontSize: 20,
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                className="fa fa-trash"
                              />
                            </FormGroup>
                          </td>
                        </tr>
                      );
                    })}
                    {/* {[1, 2].map((t) => {
                    return (
                     
                    );
                  })} */}
                  </tbody>
                ) : (
                  <tbody className="w-full">
                    <tr>
                      <td></td>{" "}
                      <td>
                        <EmptyPlaceholder text={"No IPAD Device found"} />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>

              {/* <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Tablet Username
                    </Label>
                    <Col md="7">
                      <Input
                        type="text"
                        placeholder="Username"
                        value={formik.values.ipadUsername}
                        onChange={formik.handleChange("ipadUsername")}
                        className={`form-control ${
                          formik.errors.ipadUsername &&
                          formik.touched.ipadUsername
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.ipadUsername &&
                      formik.touched.ipadUsername ? (
                        <ErrorLable message={formik.errors.ipadUsername} />
                      ) : (
                        <small>Enter a username for tablet</small>
                      )}
                    </Col>
                    <Col md="1">
                      <Button onClick={() => handleGenerate("ipadUsername")}>
                        <BiRefresh />
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Tablet Password
                    </Label>
                    <Col md="7">
                      <Input
                        type="text"
                        placeholder="Password"
                        value={formik.values.ipadPassword}
                        onChange={formik.handleChange("ipadPassword")}
                        className={`form-control ${
                          formik.errors.ipadPassword &&
                          formik.touched.ipadPassword
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {formik.errors.ipadPassword &&
                      formik.touched.ipadPassword ? (
                        <ErrorLable message={formik.errors.ipadPassword} />
                      ) : (
                        <small>Enter a password for tablet</small>
                      )}
                    </Col>
                    <Col md="1">
                      <Button onClick={() => handleGenerate("ipadPassword")}>
                        <BiRefresh />
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col> */}
            </Row>
          </TabPane>
          <TabPane tabId="tabs2" style={{ textAlign: "left" }}>
            <Form>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Order Email
                </Label>
                <Col md="8">
                  <Input
                    type="text"
                    placeholder="Enter Email"
                    value={formik.values.orderEmail}
                    onChange={formik.handleChange("orderEmail")}
                    className={`form-control ${
                      formik.errors?.orderEmail && formik.touched?.orderEmail
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors?.orderEmail && formik.touched?.orderEmail ? (
                    <ErrorLable message={formik.errors.orderEmail} />
                  ) : (
                    <small>
                      * Add 1 or multiple emails, separated by a comma, to
                      receive the order confirmation emails for only this
                      location . (Ex: kyle@mail.com, ally@mail.com)
                    </small>
                  )}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  SMS Alert
                </Label>
                <Col md="8">
                  <Input
                    type="text"
                    placeholder="Enter Mobile Phone"
                    value={formik.values.smsAlertPhone}
                    onChange={formik.handleChange("smsAlertPhone")}
                    className={`form-control ${
                      formik.errors?.smsAlertPhone &&
                      formik.touched?.smsAlertPhone
                        ? "is-invalid"
                        : ""
                    }`}
                  ></Input>
                  {formik.errors?.smsAlertPhone &&
                  formik.touched?.smsAlertPhone ? (
                    <ErrorLable message={formik.errors.smsAlertPhone} />
                  ) : (
                    <small>
                      * Add 1 or multiple phone numbers, separated by a comma,
                      to receive the order confirmation sms for only this
                      location . (Ex: 5109274238, 7798006712)
                    </small>
                  )}
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Order Call Alert Phone Number
                </Label>
                <Col md="8">
                  <Input
                    type="text"
                    placeholder="A phone number"
                    value={formik.values.orderAlertPhoneNumber}
                    onChange={formik.handleChange("orderAlertPhoneNumber")}
                    className={`form-control ${
                      formik.errors.orderAlertPhoneNumber &&
                      formik.touched.orderAlertPhoneNumber
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.errors.orderAlertPhoneNumber &&
                  formik.touched.orderAlertPhoneNumber ? (
                    <ErrorLable message={formik.errors.orderAlertPhoneNumber} />
                  ) : (
                    <small>
                      Enter a phone number to recieve calls for every order
                    </small>
                  )}
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Print
                </Label>
                <Col md="1">
                  <label className="custom-toggle">
                    <input
                      checked={formik.values?.print}
                      value={formik.values?.print}
                      onChange={formik.handleChange("print")}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Mac Address
                </Label>
                <Col md="8">
                  <Input
                    value={formik.values?.macAddress}
                    onChange={formik.handleChange("macAddress")}
                    type="text"
                  />
                  <small>* Enter the MAC Address of printer.</small>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Category Mac Addresses
                </Label>
                <Col md="6">
                  <Input
                    value={categoryMac}
                    onChange={(e) => {
                      setCategoryMac(e.target.value);
                    }}
                    type="text"
                    placeholder="100:100:100:100"
                  />
                  <small>
                    * Enter the MAC Address of printer which you want to be
                    assigned to category.
                  </small>

                  {formik?.values?.categoryMacAddress?.map((mac, idx) => {
                    return (
                      <Row className="my-2">
                        <Col md={8}>
                          <Input value={mac} disabled type="text" />
                        </Col>
                        <Col
                          md={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{
                              fontSize: 20,
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveCategoryMac(idx)}
                            className="fa fa-trash"
                          ></span>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col md={2}>
                  <Button onClick={handleAddCategoryMac}>Add</Button>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Forward Print
                </Label>
                <Col md="1">
                  <label className="custom-toggle">
                    <input
                      checked={formik.values?.forwardPrint}
                      value={formik.values?.forwardPrint}
                      onChange={formik.handleChange("forwardPrint")}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </FormGroup>
              {formik.values.print && formik.values.macAddress?.length > 0 && (
                <>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Test Print
                    </Label>
                    <Col md="4">
                      <Button
                        size="sm"
                        onClick={async () => {
                          try {
                            await addTestPrintToQueue({
                              locationID: props.location.state,
                              macAddress: formik.values.macAddress,
                            });
                            swal("Success", "Test print sent", "success");
                          } catch (error) {
                            swal("Error", "Unable to send test print", "error");
                          }
                        }}
                      >
                        {isSubmitting ? (
                          <Spinner size={"sm"} />
                        ) : (
                          "Send Test Print"
                        )}
                      </Button>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Number Of Prints
                    </Label>
                    <Col md="8">
                      <Input
                        value={formik.values?.numberOfPrints}
                        onChange={formik.handleChange("numberOfPrints")}
                        type="text"
                        placeholder="Number of Prints"
                        className={`form-control ${
                          formik.errors?.numberOfPrints &&
                          formik.touched?.numberOfPrints
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </Col>
                    {formik.errors?.numberOfPrints &&
                      formik.touched?.numberOfPrints && (
                        <ErrorLable message={formik.errors.numberOfPrints} />
                      )}
                  </FormGroup>
                </>
              )}
            </Form>
          </TabPane>
          <TabPane tabId="tabs3" style={{ textAlign: "left" }}>
            <Col md="12" className="mt--3">
              <Form>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Tax Order Fee
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={formik.values?.enableTaxOnOrderFee}
                        value={formik.values?.enableTaxOnOrderFee}
                        onChange={formik.handleChange("enableTaxOnOrderFee")}
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <Col md="12">
                      <small className="ml--2">
                        * Enable this to apply tax on order fee.
                      </small>
                    </Col>
                  </Col>
                </FormGroup>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Tax Delivery Fee
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={formik.values?.enableTaxOnDeliveryFee}
                        value={formik.values?.enableTaxOnDeliveryFee}
                        onChange={formik.handleChange("enableTaxOnDeliveryFee")}
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <Col md="12">
                      <small className="ml--2">
                        * Enable this to apply tax on delivery fee.
                      </small>
                    </Col>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col md="12" className="mt--3">
              <Form>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Location Based Tax
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={formik.values?.locationBasedTax}
                        value={formik.values?.locationBasedTax}
                        onChange={formik.handleChange("locationBasedTax")}
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                    <Col md="12">
                      <small className="ml--2">
                        * Enable this to set default tax value for this location
                      </small>
                    </Col>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            {formik.values?.locationBasedTax && (
              <>
                <Col className="mt--3" md="12">
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Location Tax Title
                      </Label>
                      <Col md="8">
                        <Input
                          value={formik.values?.location_tax_title}
                          onChange={formik.handleChange("location_tax_title")}
                          type="text"
                          className={`form-control ${
                            formik.errors?.location_tax_title &&
                            formik.touched?.location_tax_title
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors?.location_tax_title &&
                          formik.touched?.location_tax_title && (
                            <ErrorLable
                              message={formik.errors.location_tax_title}
                            />
                          )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
                <Col md="12">
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Percentage
                      </Label>
                      <Col md="8">
                        <Input
                          value={formik.values?.location_tax}
                          onChange={formik.handleChange("location_tax")}
                          type="number"
                          className={`form-control ${
                            formik.errors?.location_tax &&
                            formik.touched?.location_tax
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors?.location_tax &&
                          formik.touched.location_tax && (
                            <ErrorLable message={formik.errors.location_tax} />
                          )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </>
            )}
            <Col md="12">
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Service Charge
                </Label>
                <Col md="8">
                  <label className="custom-toggle">
                    <input
                      checked={formik.values?.serviceCharge}
                      value={formik.values?.serviceCharge}
                      onChange={formik.handleChange("serviceCharge")}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <Col md="12">
                    <small className="ml--3">
                      * Enable this to set service Charge
                    </small>
                  </Col>
                </Col>
              </FormGroup>
            </Col>
            {formik.values?.serviceCharge && (
              <>
                <Col md="12">
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Service Charge Title
                      </Label>
                      <Col md="8">
                        <Input
                          value={formik.values?.service_charge_title}
                          onChange={formik.handleChange("service_charge_title")}
                          type="text"
                          className={`form-control ${
                            formik.errors?.service_charge_title &&
                            formik.touched?.service_charge_title
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors?.service_charge_title &&
                          formik.touched.service_charge_title && (
                            <ErrorLable
                              message={formik.errors.service_charge_title}
                            />
                          )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
                <Col md="12">
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Percentage
                      </Label>
                      <Col md="8">
                        <Input
                          value={formik.values?.service_charge_percentage}
                          onChange={formik.handleChange(
                            "service_charge_percentage"
                          )}
                          type="number"
                          className={`form-control ${
                            formik.errors?.service_charge_percentage &&
                            formik.touched?.service_charge_percentage
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {formik.errors?.service_charge_percentage &&
                          formik.touched.service_charge_percentage && (
                            <ErrorLable
                              message={formik.errors.service_charge_percentage}
                            />
                          )}
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </>
            )}
          </TabPane>

          <TabPane tabId="tabs5" style={{ textAlign: "left" }}>
            <PickupDelivery
              location={entity}
              formik={formik}
              restaurantDoc={restaurantDoc}
            />
          </TabPane>
          <TabPane tabId="tabs4" style={{ textAlign: "left" }}>
            <Col md="12">
              <Form>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Transaction Report
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        checked={formik.values?.txnReport}
                        value={formik.values?.txnReport}
                        onChange={formik.handleChange("txnReport")}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
                {formik.values.txnReport && (
                  <>
                    <FormGroup className="row" style={{ alignItems: "center" }}>
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Frequency
                      </Label>
                      <Col md="8">
                        <Select
                          isSearchable={false}
                          isMulti
                          closeMenuOnSelect={false}
                          placeholder="Select frequency"
                          value={formik.values.txnReportFrequency}
                          options={[
                            { label: "Daily", value: "daily" },
                            { label: "Weekly", value: "weekly" },
                            { label: "Monthly", value: "monthly" },
                          ]}
                          onChange={(e) => {
                            formik.setFieldValue("txnReportFrequency", e);
                          }}
                        />
                        {formik.errors.txnReportFrequency &&
                          formik.touched.txnReportFrequency && (
                            <ErrorLable
                              message={formik.errors.txnReportFrequency}
                            />
                          )}
                      </Col>
                    </FormGroup>
                    <FormGroup className="row" style={{ alignItems: "center" }}>
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Email
                      </Label>
                      <Col md="8">
                        <Input
                          onChange={formik.handleChange("txnReportEmail")}
                          type="text"
                          value={formik.values?.txnReportEmail}
                          placeholder="Enter email to recieve transaction reports"
                        />
                        {formik.errors.txnReportEmail &&
                          formik.touched.txnReportEmail && (
                            <ErrorLable
                              message={formik.errors.txnReportEmail}
                            />
                          )}
                      </Col>
                    </FormGroup>
                  </>
                )}
              </Form>
              <Form>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Close Of Business
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        checked={formik.values?.closeOfBusiness}
                        value={formik.values?.closeOfBusiness}
                        onChange={formik.handleChange("closeOfBusiness")}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
                {formik.values.closeOfBusiness && (
                  <>
                    <FormGroup className="row" style={{ alignItems: "center" }}>
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Email
                      </Label>
                      <Col md="8">
                        <Input
                          onChange={formik.handleChange("closeOfBusinessEmail")}
                          type="text"
                          value={formik.values.closeOfBusinessEmail}
                          placeholder="Enter email to recieve close of business reports"
                        />
                        {formik.errors.closeOfBusinessEmail &&
                          formik.touched.closeOfBusinessEmail && (
                            <ErrorLable
                              message={formik.errors.closeOfBusinessEmail}
                            />
                          )}
                      </Col>
                    </FormGroup>
                  </>
                )}
              </Form>
            </Col>
          </TabPane>
          <TabPane tabId="tabs6" style={{ textAlign: "left" }}>
            <LocationIntegrations data={formik.values} formik={formik} />
          </TabPane>
          <TabPane tabId="tabs7" style={{ textAlign: "left" }}>
            <FormGroup className="row my-2" style={{ alignItems: "center" }}>
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                Enable Dine In
              </Label>
              <Col md="8">
                <label className="custom-toggle">
                  <input
                    checked={formik.values?.enableDineIn}
                    value={formik.values?.enableDineIn}
                    onChange={formik.handleChange("enableDineIn")}
                    type="checkbox"
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </FormGroup>
            {formik.values.enableDineIn && (
              <>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Method
                  </Label>
                  <Col md="8">
                    <Select
                      isSearchable={false}
                      closeMenuOnSelect={true}
                      placeholder="Select Method"
                      value={[
                        {
                          value: "web",
                          label: "Web",
                        },
                        {
                          value: "qr",
                          label: "QR",
                        },
                        {
                          value: "web-qr",
                          label: "Web And QR",
                        },
                      ].find((t) => t.value === formik.values.dineInMethod)}
                      options={[
                        {
                          value: "web",
                          label: "Web",
                        },
                        {
                          value: "qr",
                          label: "QR",
                        },
                        {
                          value: "web-qr",
                          label: "Web And QR",
                        },
                      ]}
                      onChange={(e) => {
                        formik.setFieldValue("dineInMethod", e.value);
                      }}
                    />
                  </Col>
                </FormGroup>
                {entity?.data?.ordering?.enableDineIn &&
                  (formik.values.dineInMethod === "qr" ||
                    formik.values.dineInMethod === "web-qr") && (
                    <>
                      <FormGroup
                        className="row"
                        style={{ alignItems: "center" }}
                      >
                        <Label
                          className="form-control-label text-left"
                          htmlFor="example-text-input"
                          md="4"
                        >
                          QR Code
                        </Label>
                        <Col md="8">
                          <QRCode
                            value={`${restaurantDoc?.restaurant?.url}/dine-in?location=${entity?.data?._id}`}
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                {(formik.values.dineInMethod === "web" ||
                  formik.values.dineInMethod === "web-qr") && (
                  <FormGroup
                    className="row mt--3"
                    style={{ alignItems: "center" }}
                  >
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    ></Label>

                    {entity?.data?._id && (
                      <Col md="8">
                        <a
                          href={`${restaurantDoc?.restaurant?.url}/dine-in?location=${entity.data._id}`}
                        >
                          {`${restaurantDoc?.restaurant?.url}/dine-in?location=${entity?.data?._id}`}
                        </a>
                      </Col>
                    )}
                  </FormGroup>
                )}
              </>
            )}
          </TabPane>

          <TabPane tabId="tabs8" style={{ textAlign: "left" }}>
            {restaurantDoc?.restaurant?.enable_deliverect && (
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Channel Name
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        placeholder="Enter deliverect channel name"
                        name="deliverect_channel_name"
                        onChange={formik.handleChange}
                        value={formik.values.deliverect_channel_name}
                        className={`form-control ${
                          formik.errors.deliverect_channel_name &&
                          formik.touched.deliverect_channel_name
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </Col>
                  </FormGroup>
                </Form>

                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Channel Link ID
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        name="deliverect_channel_link_id"
                        placeholder="Enter deliverect channel id"
                        onChange={formik.handleChange}
                        value={formik.values.deliverect_channel_link_id}
                        className={`form-control ${
                          formik.errors.deliverect_channel_link_id &&
                          formik.touched.deliverect_channel_link_id
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            )}
          </TabPane>
          {/* walkup ordering qr */}
          <TabPane tabId="tabs9" style={{ textAlign: "left" }}>
            <WalkupOrdering
              handleChange={formik.handleChange}
              data={entity?.data}
              values={formik.values}
              handleWalkUpOrderingPaymentMethods={
                handleWalkUpOrderingPaymentMethods
              }
              locationId={props.location.state}
              errors={formik.errors}
            />
          </TabPane>
          <TabPane tabId="tabs10" style={{ textAlign: "left" }}>
            <Catering
              handleChange={formik.handleChange}
              data={entity?.data}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              errors={formik.errors}
              location={entity}
              restaurantDoc={restaurantDoc}
              touched={formik.touched}
            />
          </TabPane>
          <TabPane tabId="tabs12" style={{ textAlign: "left" }}>
            <MenuocityPrinter formik={formik} />
          </TabPane>
        </TabContent>
      </TableWrapper>
    </>
  );
}
