import { toast } from "react-toastify";
import { useEntity } from "../../../hooks/useEntity";
import logger from "../../../utils/logger";

const TableRowMobileAppRestaurant = ({ data }) => {
  const { updateEntity } = useEntity("restaurant");

  return (
    <tr className="text-left">
      <td>{data?.name}</td>
      <td>{data?.email}</td>
      <td>
        <label className="custom-toggle">
          <input
            checked={data?.showInPartnerApp}
            type="checkbox"
            onClick={async () => {
              try {
                await updateEntity(data?.userId?._id, {
                  showInPartnerApp:
                    data?.showInPartnerApp === true ? false : true,
                });
              } catch (error) {
                logger.push({ error, info: "TableRowRestaurants" });
                toast(error.message);
              }
            }}
          />
          <span
            className="custom-toggle-slider rounded-circle"
            data-label-off="Off"
            data-label-on="On"
          />
        </label>
      </td>
    </tr>
  );
};

export default TableRowMobileAppRestaurant;
