import CryptoJS from "crypto-js";
import { useState } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { CRYPTOJS_SECRET } from "../../config";

const SecureInputRestaurant = ({
  icon,
  value,
  onChange,
  className,
  ordering = false,
  placeholder = "",
  viewablePassword = "",
  add = "",
  setFieldValue,
  oldViewable,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <InputGroup>
      {icon && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon} aria-hidden="true"></i>
          </InputGroupText>
        </InputGroupAddon>
      )}
      {add ? (
        <>
          {visible ? (
            <Input
              type={"text"}
              value={value}
              onChange={onChange}
              className={className}
              placeholder={placeholder}
            />
          ) : (
            <Input
              type={"password"}
              value={
                oldViewable === viewablePassword ? viewablePassword : value
              }
              onChange={onChange}
              className={className}
              placeholder={placeholder}
            />
          )}
        </>
      ) : (
        <Input
          type={visible ? "text" : "password"}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
        />
      )}
      <InputGroupAddon addonType="append">
        <InputGroupText
          onClick={() => {
            if (oldViewable === viewablePassword) {
              const bytes = CryptoJS.AES.decrypt(
                viewablePassword,
                CRYPTOJS_SECRET
              );
              var decryptedPassword = bytes.toString(CryptoJS.enc.Utf8);

              setFieldValue("password", decryptedPassword);
              setVisible(!visible);
            } else setVisible(!visible);
          }}
        >
          <i
            style={{
              padding: 2,
              cursor: "pointer",
              color: ordering && "#525f7f",
            }}
            className={visible ? "fa fa-eye-slash" : "fa fa-eye"}
            aria-hidden="true"
          ></i>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default SecureInputRestaurant;
