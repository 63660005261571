import { Axiom } from "@axiomhq/js";

const axiom = new Axiom({
  token: "xaat-b3384e57-1c74-4e6c-afd6-ba126e1abe9c",
});

/**
 * Log a customer session event to Axiom with structured data
 * @param {string} dataset - The dataset to log to (e.g., "customer-sessions")
 * @param {string} eventType - The type of session event (e.g., "login", "logout", "action")
 * @param {string} [userId="guest"] - The user's ID or "guest" if not available
 * @param {Object} [customData={}] - Any additional custom data for the session
 * @throws {Error} If logging fails
 */
async function logCustomerSession(data) {
 
  try {
    await axiom.ingest("production", [data]);
  } catch (error) {
    console.error(`Failed to log session event to Axiom: ${error.message}`);
    throw error; // Re-throw the error for the calling code to handle
  }
}

export default logCustomerSession;