import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";

const MenuocityPrinter = ({ formik }) => {
  const [printers, setPrinters] = useState([]);

  const [printerConfig, setPrinterConfig] = useState({
    serialNumber: "",
    noOfPrints: 1,
    isKitchen: false,
  });

  const handleAdd = () => {
    if (!printerConfig.serialNumber || !printerConfig.noOfPrints) {
      toast.error("Please fill all the details");
      return;
    }

    if (printerConfig.noOfPrints <= 0) {
      toast.error("No of prints can't be 0");
      return;
    }

    const printersArray = [...printers];

    printersArray.push(printerConfig);

    setPrinters(printersArray);

    formik.setFieldValue("menuocityPrinters", printersArray);

    setPrinterConfig({ serialNumber: "", noOfPrints: 0 });
  };

  const handleDelete = (index) => {
    const printersArray = [...printers];

    printersArray.splice(index, 1);

    formik.setFieldValue("menuocityPrinters", printersArray);

    setPrinters(printersArray);
  };

  useEffect(() => {
    setPrinters([...(formik?.values?.menuocityPrinters || [])]);
  }, [formik?.values]);

  return (
    <>
      <>
        <Col className="mt--3" md="12">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                Printer Serial Number
              </Label>
              <Col md="8">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>PRN</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={printerConfig.serialNumber}
                    onChange={(e) => {
                      setPrinterConfig({
                        ...printerConfig,
                        serialNumber: e.target?.value,
                      });
                    }}
                    type="text"
                    className={`form-control `}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col className="mt--3" md="12">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                No of prints
              </Label>
              <Col md="8">
                <Input
                  value={printerConfig.noOfPrints}
                  min={1}
                  onChange={(e) => {
                    setPrinterConfig({
                      ...printerConfig,
                      noOfPrints: e.target?.value,
                    });
                  }}
                  type="number"
                  className={`form-control `}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                Kitchen Printer
              </Label>
              <Col md="8">
                <label className="custom-toggle">
                  <input
                    onChange={(e) => {
                      setPrinterConfig({
                        ...printerConfig,
                        isKitchen: e.target?.checked,
                      });
                    }}
                    type="checkbox"
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col
          md="12"
          style={{
            justifyContent: "flex-end",

            display: "flex",
          }}
        >
          <Button onClick={handleAdd} size="sm" color="primary">
            {" "}
            Add
          </Button>
        </Col>
      </>
      {printers?.map((printer, index) => {
        console.log(printer, "asdaskl");
        return (
          <>
            <Col className="mt-3" md="12">
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Printer Serial Number
                  </Label>
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>PRN</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={printer.serialNumber}
                        disabled
                        type="text"
                        className={`form-control `}
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col className="mt--3" md="12">
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    No of prints
                  </Label>
                  <Col md="8">
                    <Input
                      value={printer.noOfPrints}
                      disabled
                      type="text"
                      className={`form-control `}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col className="mt-3" md="12">
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Kitchen Printer
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        disabled
                        checked={printer.isKitchen}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col
              style={{
                justifyContent: "flex-end",

                display: "flex",
              }}
              md="12"
            >
              <Button
                onClick={() => handleDelete(index)}
                size="sm"
                color="danger"
              >
                <i className="fa fa-trash" />
              </Button>
            </Col>
          </>
        );
      })}
    </>
  );
};

export default MenuocityPrinter;
