import moment from "moment";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { useLocation } from "react-router-dom";

const TableRowReEngage = ({ data, history }) => {
  const { updateEntity, deleteEntity } = useEntity("reengage");
  const location = useLocation();
  return (
    <tr className="text-left">
      <td
        style={{ cursor: "pointer", fontWeight: "bold" }}
        onClick={() =>
          history.push({
            pathname: "/resturants/marketing/add-re-engage",
            state: data?._id,
            search: location.search,
          })
        }
      >
        {data?.campaignName}
      </td>
      <td>{data?.dayOfTheWeekToRun}</td>
      <td style={{ textTransform: "capitalize" }}>
        {data?.action.couponDiscountType === "free_delivery"
          ? "Free Delivery"
          : `${data?.action.couponDiscountType}%`}
      </td>
      <td>{moment(data?.startDate).format("MM/DD/YYYY")}</td>
      <td>{moment(data?.endDate).format("MM/DD/YYYY")}</td>
      <td>
        {" "}
        <>
          <label className="custom-toggle">
            <input
              checked={data?.status === true}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: !data?.status,
                  });
                  toast.success("Campaign updated successfully");
                } catch (error) {
                  toast(error.message);
                }
              }}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </>
      </td>
      <td>
        <span
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(data?._id);
                  toast.error("Campaign deleted succesfully");
                }
              });
            } catch (error) {
              toast(error.message);
            }
          }}
        >
          <i className="fa fa-trash text-lg text-danger" />
        </span>
      </td>
    </tr>
  );
};

export default TableRowReEngage;
