/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { RiVisaLine } from "react-icons/ri";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import swal from "sweetalert";
import OrderingLayout from "../../../../components/Layout/OrderingLayout";
import { AuthContext } from "../../../../context";
import { useCart } from "../../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../../hooks/useEntity";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import DB from "../../../../utils/DB";
import ErrorLable from "../../../../utils/ErrorLable";
import { DBKeys } from "../../../../utils/Keys";
import logger from "../../../../utils/logger";
import { validationSchemaCustomerUpdateProfile } from "../../../../utils/schemas/CustomerUpdateProfile";
import HistoryCard from "../../history/components/HistoryCard";
import LoyaltyModalComponent from "./LoyaltyModalComponent";

const months = [
  { month: "January", value: 1, label: "January" },
  { month: "February", value: 2, label: "February" },
  { month: "March", value: 3, label: "March" },
  { month: "April", value: 4, label: "April" },
  { month: "May", value: 5, label: "May" },
  { month: "June", value: 6, label: "June" },
  { month: "July", value: 7, label: "July" },
  { month: "August", value: 8, label: "August" },
  { month: "September", value: 9, label: "September" },
  { month: "October", value: 10, label: "October" },
  { month: "November", value: 11, label: "November" },
  { month: "December", value: 12, label: "December" },
];
export const AccountTabs = ({ styles, activeTab, setActiveTab, theme }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflowX: "scroll",
        whiteSpace: "nowrap",
        width: "100%",
        maxWidth: "100%",
        overflowY: "hidden",
        WebkitOverflowScrolling: "touch",
      }}
      className="mb-3 cutomer_profile_nav_buttons"
    >
      <Button
        onClick={() => setActiveTab(0)}
        style={{
          backgroundColor: activeTab === 0 ? theme.primary : "#fff",
          color: activeTab === 0 ? "#fff" : theme.primary,
          ...styles,
        }}
      >
        Profile
      </Button>
      <Button
        onClick={() => setActiveTab(1)}
        style={{
          backgroundColor: activeTab === 1 ? theme.primary : "#fff",
          color: activeTab === 1 ? "#fff" : theme.primary,
          border:
            activeTab === 1 ? "1px solid #fff" : `1px solid ${theme.primary}`,
          ...styles,
        }}
      >
        Cards
      </Button>

      <Button
        onClick={() => setActiveTab(2)}
        style={{
          backgroundColor: activeTab === 2 ? theme.primary : "#fff",
          color: activeTab === 2 ? "#fff" : theme.primary,
          border:
            activeTab === 2 ? "1px solid #fff" : `1px solid ${theme.primary}`,
          ...styles,
        }}
      >
        Orders
      </Button>
      <Button
        onClick={() => setActiveTab(3)}
        style={{
          backgroundColor: activeTab === 3 ? theme.primary : "#fff",
          color: activeTab === 3 ? "#fff" : theme.primary,
          border:
            activeTab === 3 ? "1px solid #fff" : `1px solid ${theme.primary}`,
          ...styles,
        }}
      >
        Loyalty
      </Button>
    </div>
  );
};

const CustomerProfile = (props) => {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { findOne, entity, error } = useEntity("eat/auth");
  const { updateEntity } = useEntity("eat/auth/profile");
  const {
    find,
    entities,
    loading: loadingOrders,
  } = useEntity("eat/ordering/history");
  const {
    find: findItems,
    entities: allItems,
    loading,
  } = useEntity("eat/ordering/items");
  const { location: locationDoc, method } = useOrdering();

  const { find: findCards, entities: cards } = useEntity("eat/card");
  const { deleteEntity } = useEntity("eat/card");
  const { find: findVisits, entities: visits } = useEntity(
    "eat/ordering/visits"
  );
  const { restaurant } = useOrderingRestaurant();
  const { addToCart } = useCart();
  const [page] = useState(1);
  const [rowsPerPage] = useState(10);

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabOrders, setActiveTabOrders] = useState(0);
  const [monthNameAn, setMonthNameAn] = useState("");
  const [monthNameBi, setMonthNameBi] = useState("");
  const { gateway } = useOrdering();

  useEffect(() => {
    findItems({
      restaurant: restaurant?.userId?._id,
      location: locationDoc?._id,
      method,
    });
  }, [restaurant, locationDoc, method]);

  useEffect(() => {
    find({
      customerId: authContext?.user?.customer?._id,
      restaurant: restaurant?.userId?._id,
      limit: rowsPerPage,
      locationId: locationDoc?._id,
      page: page,
      orders: activeTabOrders === 0 ? "all" : "inquiry",
    });
  }, [
    authContext,
    restaurant,
    rowsPerPage,
    page,
    locationDoc,
    activeTabOrders,
  ]);

  useEffect(() => {
    if (authContext?.user) {
      findOne(authContext?.user?._id);
      findVisits({
        id: authContext?.user?.customer?._id,
        restaurant: restaurant?.userId?._id,
      });
    }
  }, [authContext]);

  useEffect(() => {
    if (authContext?.user?._id) {
      findCards();
    }
  }, [authContext]);

  useEffect(() => {
    if (error) {
      localStorage.removeItem(DBKeys.CUSTOMER_TOKEN);
      localStorage.removeItem(DBKeys.CUSTOMER_USER);
      authContext.logout();
    }
  }, [error]);

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isSubmitting,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      birthday: "",
      birthMonth: "",
      password: "",
      smsForCouponAndInfo: false,
      emailForCouponAndInfo: false,
      anniversary: "",
      anniversaryMonth: "",
    },
    validationSchema: validationSchemaCustomerUpdateProfile,
    onSubmit: async (values) => {
      const data = {
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        emailForCouponAndInfo: values?.emailForCouponAndInfo,
        smsForCouponAndInfo: values?.smsForCouponAndInfo,
        birthday: `${
          values?.birthMonth.toString().length === 1
            ? `0${values?.birthMonth.toString()}`
            : values?.birthMonth.toString()
        }/${
          values?.birthday.toString().length === 1
            ? `0${values?.birthday.toString()}`
            : values?.birthday.toString()
        }`,
        anniversary: `${
          values?.anniversary.toString().length === 1
            ? `0${values?.anniversaryMonth.toString()}`
            : values?.anniversaryMonth.toString()
        }/${
          values?.anniversary.toString().length === 1
            ? `0${values?.anniversary.toString()}`
            : values?.anniversary.toString()
        }`,
      };

      try {
        await updateEntity(authContext?.user?.customer?._id, {
          userId: authContext.user._id,
          ...data,
        });
        toast.success("Customer updated successfully");
      } catch (error) {
        logger.push({ error, info: "CustomerProfile" });
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        name: entity?.data?.customer?.name,
        phone: entity?.data?.customer?.phone,
        email: entity?.data?.customer?.email,
        emailForCouponAndInfo: entity?.data?.customer?.emailForCouponAndInfo,
        smsForCouponAndInfo: entity?.data?.customer?.smsForCouponAndInfo,
        birthMonth: Number(entity?.data?.customer?.birthday?.split("/")[0]),
        birthday: Number(entity?.data?.customer?.birthday?.split("/")[1]),
        anniversary: Number(entity?.data?.customer?.anniversary?.split("/")[1]),
        anniversaryMonth: Number(
          entity?.data?.customer?.anniversary?.split("/")[0]
        ),
      });
    }
  }, [entity]);

  const styles = {
    fontSize: 13,
    padding: 10,
    transform: "none",
    boxShadow: "none",
    border: `1px solid ${theme.primary}`,
    textTransform: "uppercase",
  };

  useEffect(() => {
    months.findIndex((item) => {
      if (item.value === values.anniversaryMonth) {
        setMonthNameAn(item.month);
      }
      if (item.value === values.birthMonth) {
        setMonthNameBi(item.month);
      }
    });
  }, [months, values]);

  if (loading) {
    return <Spinner />;
  }

  console.log(cards, "CADS DATA");

  return (
    <OrderingLayout
      isCenter={true}
      loading={loading}
      title="My Account"
      history={props.history}
      classname="main_ordering_card_wide"
      layout={true}
      hideLogo={true}
    >
      <div>
        <AccountTabs
          theme={theme}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          styles={styles}
        />

        {activeTab === 0 && (
          <div>
            <div className="my-3">
              <input
                className="px-3 py-3 rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#ededed",
                  border: "none",
                  fontSize: "18px",
                }}
                type="text"
                placeholder="name"
                value={values?.name}
                onChange={handleChange("name")}
              />
              {touched.name && errors.name && (
                <ErrorLable message={errors.name} />
              )}
            </div>

            <div className="my-3">
              <input
                className="px-3 py-3 rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#ededed",
                  border: "none",
                  fontSize: "18px",
                }}
                type="text"
                placeholder="Email"
                value={values?.email}
                onChange={handleChange("email")}
              />
              {touched.email && errors.email && (
                <ErrorLable message={errors.email} />
              )}
            </div>
            <div className="my-3">
              <input
                className="px-3 py-3 rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#ededed",
                  border: "none",
                  fontSize: "18px",
                }}
                type="number"
                placeholder="Number"
                min={"0"}
                onKeyDown={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
                value={values?.phone}
                onChange={handleChange("phone")}
              />
              {touched.phone && errors.phone && (
                <ErrorLable message={errors.phone} />
              )}
            </div>
            <div className="my-3">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 99,
                }}
              >
                {" "}
                <span className="mb-2 bold">Birthday </span>{" "}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>
                    <Select
                      name="month"
                      placeholder="Month"
                      isSearchable={false}
                      options={months}
                      onChange={(val) =>
                        setFieldValue("birthMonth", val?.value)
                      }
                      value={
                        values.birthMonth
                          ? {
                              label: monthNameBi,
                              value: values?.birthMonth,
                            }
                          : null
                      }
                    />
                    {touched.birthMonth && errors.birthMonth && (
                      <ErrorLable message={errors.birthMonth} />
                    )}
                  </div>
                  <span className="mx-2"></span>
                  <div style={{ flex: 1 }}>
                    <Select
                      name="day"
                      placeholder="Day"
                      isSearchable={false}
                      options={[...Array(32).keys()]?.slice(1, 32).map((it) => {
                        return {
                          label: it,
                          value: it,
                        };
                      })}
                      onChange={(val) => setFieldValue("birthday", val?.value)}
                      value={
                        values.birthday
                          ? {
                              label: values?.birthday,
                              value: values?.birthday,
                            }
                          : null
                      }
                    />
                    {touched.birthday && errors.birthday && (
                      <ErrorLable message={errors.birthday} />
                    )}
                  </div>
                </div>
              </div>{" "}
              <div
                className="mt-3"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 9999999,
                }}
              >
                <span className="mb-2 bold">Anniversary </span>{" "}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1 }}>
                    <Select
                      name="month"
                      placeholder="Month"
                      isSearchable={false}
                      options={months}
                      onChange={(val) =>
                        setFieldValue("anniversaryMonth", val?.value)
                      }
                      value={
                        values.anniversaryMonth
                          ? {
                              label: monthNameAn,
                              value: values?.anniversaryMonth,
                            }
                          : null
                      }
                    />
                    {touched.anniversaryMonth && errors.anniversaryMonth && (
                      <ErrorLable message={errors.anniversaryMonth} />
                    )}
                  </div>
                  <span className="mx-2"></span>
                  <div style={{ flex: 1 }}>
                    <Select
                      name="day"
                      placeholder="Day"
                      isSearchable={false}
                      options={[...Array(32).keys()]?.slice(1, 32).map((it) => {
                        return {
                          label: it,
                          value: it,
                        };
                      })}
                      onChange={(val) =>
                        setFieldValue("anniversary", val?.value)
                      }
                      value={
                        values.anniversary
                          ? {
                              label: values?.anniversary,
                              value: values?.anniversary,
                            }
                          : null
                      }
                    />
                    {touched.anniversary && errors.anniversary && (
                      <ErrorLable message={errors.anniversary} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="custom-control custom-checkbox my-3"
              style={{ zIndex: 0 }}
            >
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
                onChange={handleChange("smsForCouponAndInfo")}
                value={values?.smsForCouponAndInfo}
                checked={values?.smsForCouponAndInfo}
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Sign up for SMS coupons and info{" "}
              </label>
            </div>
            <div
              style={{ zIndex: 0 }}
              className="custom-control custom-checkbox my-3"
            >
              <input
                className="custom-control-input"
                id="customCheck2"
                type="checkbox"
                onChange={handleChange("emailForCouponAndInfo")}
                value={values?.emailForCouponAndInfo}
                checked={values?.emailForCouponAndInfo}
              />
              <label className="custom-control-label" htmlFor="customCheck2">
                Sign up for Email coupons and info
              </label>
            </div>
            <div className="d-flex justify-content-between align-items-center ">
              <div
                onClick={() => {
                  let url = new URL(restaurant?.url);
                  props.history.push(
                    `${url.pathname}/request-account-deletion`
                  );
                }}
                className="text-danger text-underline pointer"
              >
                Request account deletion
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="dark"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    swal({
                      title: "Are you sure you want to logout?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willDelete) => {
                      if (willDelete) {
                        DB?.removeData(DBKeys?.CUSTOMER_USER);
                        DB.removeData(DBKeys?.CUSTOMER_TOKEN);
                        authContext.logout();
                      }
                    });
                  }}
                >
                  LOGOUT
                </Button>
                <Button
                  style={{
                    backgroundColor: theme?.primary,
                    color: "white",
                    textTransform: "uppercase",
                    letterSpacing: "0.1em",
                  }}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? <Spinner size="sm" /> : "Update"}
                </Button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div className="">
            <table style={{ width: "100%" }}>
              <tr
                style={{
                  color: theme.primary,
                  textTransform: "uppercase",
                }}
              >
                <th>Number</th>
                <th>Type</th>
                <th className="text-center">Action</th>
              </tr>
              {cards?.data?.map((card, i) => {
                return (
                  <>
                    <tr key={i}>
                      <td>{card?.truncatedCardNumber}</td>
                      <td>
                        {card?.cardLogo === "Visa" ? (
                          <RiVisaLine size={40} />
                        ) : (
                          card?.cardLogo
                        )}
                      </td>
                      <td className="text-center">
                        <FaTrash
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            try {
                              await deleteEntity(card?._id);
                              toast.success("Card deleted succesfully");
                            } catch (error) {
                              toast.error(error.message);
                            }
                          }}
                          color={theme?.primary}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
              {(cards?.data?.length === 0 || !cards?.data) && (
                <tr>
                  <td colSpan={4} className="text-center p-4">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <lottie-player
                        src="https://assets4.lottiefiles.com/packages/lf20_dmw3t0vg.json"
                        background="transparent"
                        speed="1"
                        style={{ maxWidth: 300, maxHeight: 200 }}
                        autoplay
                      ></lottie-player>
                      <div>
                        {" "}
                        <h1 className="mt-3" style={{ textAlign: "center" }}>
                          {"Currently no data here"}
                        </h1>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </table>
          </div>
        )}
        {activeTab === 2 && (
          <>
            {loadingOrders ? (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    width: "100%",
                    maxWidth: "100%",
                    overflowY: "hidden",
                    WebkitOverflowScrolling: "touch",
                  }}
                  className="mb-3 cutomer_profile_nav_buttons"
                >
                  <Button
                    onClick={() => setActiveTabOrders(0)}
                    style={{
                      backgroundColor:
                        activeTabOrders === 0 ? theme.primary : "#fff",
                      color: activeTabOrders === 0 ? "#fff" : theme.primary,
                      ...styles,
                    }}
                  >
                    Orders
                  </Button>
                  <Button
                    onClick={() => setActiveTabOrders(1)}
                    style={{
                      backgroundColor:
                        activeTabOrders === 1 ? theme.primary : "#fff",
                      color: activeTabOrders === 1 ? "#fff" : theme.primary,
                      border:
                        activeTabOrders === 1
                          ? "1px solid #fff"
                          : `1px solid ${theme.primary}`,
                      ...styles,
                    }}
                  >
                    {"Catering (Inquiry)"}
                  </Button>
                </div>
                {entities?.data?.data?.map((o, index) => {
                  return (
                    <HistoryCard
                      disable={false}
                      history={props.history}
                      length={entities?.data?.length}
                      index={index}
                      data={o}
                      key={index}
                      allItems={allItems?.data?.data}
                      onClick={() => {
                        let url = new URL(restaurant?.url);
                        entities?.data?.itemsAvailable[index]?.map((op) => {
                          // delete op.price;
                          // delete op.name;
                          delete op.isAvailable;
                          addToCart({
                            ...op,
                            modifiers: op?.modifiers || [],
                            note: op?.note || "",
                            availableQty: undefined,
                          });
                        });
                        props.history.push(`${url.pathname}/menu`);
                      }}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
        {entities?.data?.data?.length <= 0 && activeTab === 2 && (
          <div>No orders placed, Place an order now</div>
        )}
        {activeTab === 3 && (
          <div className=" pb-3">
            <h5>Keep earning points toward FREE faves</h5>
            <LoyaltyModalComponent />
            <div
              className="p-3 mt-3"
              style={{
                boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div>
                  <h5>Unlock boosted status</h5>
                </div>
                <div>
                  <h5>
                    {restaurant?.loyalty?.visitThreshold} visits in 3 calendar
                    month earns {restaurant?.loyalty?.rewardPoints} points.
                  </h5>
                </div>
              </div>
              <div className="text-uppercase d-flex flex-column align-items-center">
                <div>
                  <h5 className="m-0">VISITS</h5>
                </div>
                <div>
                  <h5
                    className="text-red m-0 text-xl"
                    style={{ fontWeight: "bolder" }}
                  >
                    {visits.data}
                  </h5>
                </div>
                <div>
                  <h5 className="m-0">
                    of {restaurant?.loyalty?.visitThreshold}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </OrderingLayout>
  );
};

export default CustomerProfile;
