/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

// reactstrap components
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import {
  Collapse,
  Container,
  Form,
  FormGroup,
  Nav,
  Navbar,
  NavItem,
  NavLink,
} from "reactstrap";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useAdminStore } from "../../store/admin";
import logger from "../../utils/logger";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav, user, history }) {
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  const authContext = useContext(AuthContext);
  const location = useLocation();
  const pathname = location.pathname.substr(1);
  const { restaurant } = useAdminStore();
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  // const [, setSearchData] = useState();
  const { findOne, entity, updateEntity } = useEntity("restaurant");
  // const { create: findInput } = useEntity("search");
  // const [, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (authRest || restaurant) {
      findOne(restaurant || authRest);
    }
  }, [restaurant, authContext, authRest]);

  // const searchBox = (event) => {
  //   let input = event.target;
  //   let timeout = null;
  //   if (input) {
  //     input.addEventListener("keyup", (e) => {
  //       clearTimeout(timeout);
  //       let value = e.target.value;
  //       if (value) {
  //         timeout = setTimeout(async () => {
  //           const res = await findInput({
  //             restaurant: restaurant || authRest,
  //             _q: e.target.value,
  //           });

  //           setSearchData(res);
  //           setDropdownOpen(true);
  //         }, 2000);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (
      window.location.pathname === "/resturants" &&
      restaurant === "" &&
      authContext?.user?.role !== "restaurant" &&
      authContext?.user?.role !== "partner"
    ) {
      window.location.href = `${window.location.origin}/admin/partners`;
    }
    if (
      window.location.pathname === "/resturants" &&
      restaurant === "" &&
      authContext?.user?.role !== "restaurant" &&
      authContext?.user?.role === "partner" &&
      authContext?.user?.role !== "super-admin"
    ) {
      window.location.href = `${window.location.origin}/partners/resturants`;
    }
  }, [restaurant, window]);

  let orderUrl = entity?.data?.restaurant?.url;
  if (process.env.REACT_APP_ENV !== "production") {
    orderUrl = entity?.data?.restaurant?.url
      ? `${window.location.origin}${
          new URL(entity?.data?.restaurant?.url || "").pathname
        }`
      : "";
    if (entity?.data?.restaurant?.useRestaurantUrl) {
      orderUrl = entity?.data?.restaurant?.url;
    }
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >
              <FormGroup className="mb-0">
                {(pathname?.split("/")[0] === "resturants/" ||
                  pathname?.split("/")[0] === "resturants") && (
                  <>
                    <span className="text-white bold text-lg">
                      {entity?.data?.restaurant?.name}
                    </span>
                  </>
                )}
                {/* <InputGroup className="input-group-alternative input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onClick={(e) => {
                      searchBox(e);
                    }}
                    placeholder="Order id or customer name..."
                    type="text"
                     onChange={handleChange}
                  />
                </InputGroup> */}
                {/* <Dropdown
                  className="bg-white"
                  isOpen={dropdownOpen}
                  toggle={toggle}
                >
                  {searchData?.data?.data?.map((op) => {
                    return (
                      <>
                        {" "}
                        {dropdownOpen && (
                          <DropdownItem
                            className="mt-1 rounded bg-white"
                            toggle={false}
                            onClick={() => {
                              if (op?.name) {
                                history.push({
                                  pathname: "/resturants/customers",
                                  search: `?id=${op?._id}`,
                                });
                                toggle();
                              } else {
                                history.push({
                                  pathname: "/resturants/order/view",
                                  state: { ...op },
                                });
                                toggle();
                              }
                            }}
                          >
                            {op?.name ? op?.name : "Order ID - " + op?.orderNum}
                          </DropdownItem>
                        )}
                      </>
                    );
                  })}
                </Dropdown> */}
              </FormGroup>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
            </Nav>
            {(pathname?.split("/")[0] === "resturants/" ||
              pathname?.split("/")[0] === "resturants") && (
              <div
                className="mx-4"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  className="mb-0 mx-2 text-white text-sm font-weight-bold"
                >
                  Ordering{" "}
                </span>
                <label className="custom-toggle">
                  <input
                    onClick={async () => {
                      try {
                        const upd = await updateEntity(restaurant || authRest, {
                          orderingApp:
                            entity?.data?.restaurant?.orderingApp === true
                              ? false
                              : true,
                        });
                        if (upd.code === "SUCCESS") {
                          toast("Ordering settings updated successfully");
                        }
                      } catch (error) {
                        logger.push({
                          error,
                          info: "AdminNavbar",
                        });
                        toast(error.message);
                      }
                    }}
                    type="checkbox"
                    checked={
                      entity?.data?.restaurant?.orderingApp === true
                        ? true
                        : false
                    }
                  />
                  <span
                    style={{ backgroundColor: "white" }}
                    className="custom-toggle-slider rounded-circle"
                  />
                </label>
              </div>
            )}
            {(pathname?.split("/")[0] === "resturants/" ||
              pathname?.split("/")[0] === "resturants") && (
              <>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={orderUrl}
                  style={{ cursor: "pointer" }}
                  className="mb-0 mx-2 text-white text-sm font-weight-bold"
                >
                  Place Order <i className="fa fa-arrow-right" />
                </a>
              </>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
