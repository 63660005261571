/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Select from "react-select";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { useRestaurantStore } from "../../store/restaurant";

function TimelineHeader({
  name,
  parentName,
  showBtn,
  children,
  showSearch = false,
  getSearchQuery,
  placeholder,
  filterByCategories = false,
  categories = [],
  getCategory,
  path,
  tagsFilter = false,
  tags,
  getTag,
  streamFilter = false,
  locationFilter = false,
  source = [],
  getSource,
  isCategory = false,
  getLocation,
  locations,
  selectedLocation,
  selectedSource,
  selectedTag,
  selectedCategory,
}) {
  const history = useHistory();
  const location = useLocation();
  const { restaurant } = useRestaurantStore();
  const restaurantDoc = restaurant.restaurant;
  const pathname = location.pathname.substr(1);

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar text-left">
        <Container fluid>
          <Row className="align-items-left py-4">
            <Col className="d-flex align-items-center" lg="6" xs="7">
              <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 ml-1">
                {name}
              </h6>
              <Breadcrumb
                className="d-none d-md-inline-block ml-lg-4"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fa fa-home" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a
                    href="javascript:void(0)"
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      history.push({
                        pathname: path,
                        search: location.search,
                      })
                    }
                  >
                    {parentName}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  {name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            {showBtn && (
              <Col
                className="mt-3 mt-md-0 text-md-right"
                lg="6"
                xs="5"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {children}
              </Col>
            )}
            {(pathname?.split("/")[0] === "resturants/" ||
              pathname?.split("/")[0] === "resturants") &&
              !showBtn && (
                <Col lg="6" xs="7">
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "right" }}
                  >
                    {restaurantDoc?.stream?.enableStreamPOS && (
                      <>
                        {" "}
                        <span
                          className="text-white"
                          style={{ fontWeight: "bold" }}
                        >
                          Stream:POS
                        </span>
                        {
                          <span
                            className={"fa fa-circle text-green ml-2"}
                          ></span>
                        }
                      </>
                    )}

                    {restaurantDoc?.stream?.enableStreamDSP && (
                      <>
                        <span
                          className="text-white ml-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Stream:DSP
                        </span>

                        {
                          <span
                            className={
                              restaurantDoc?.stream?.enableStreamDSP
                                ? "fa fa-circle text-green ml-2"
                                : "fa fa-circle text-red ml-2"
                            }
                          ></span>
                        }
                      </>
                    )}
                  </div>
                </Col>
              )}
          </Row>
          <div className=" simple_header_search_row">
            {showSearch && (
              <div className="simple_header_search_component">
                <InputGroup className="input-group-merge">
                  <Input
                    style={{
                      borderRadius: 100,
                      backgroundColor: "#ECFAFC",
                    }}
                    className="simple_header_search_component_placeholder"
                    placeholder={placeholder}
                    type="text"
                    onChange={(e) => {
                      getSearchQuery(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            )}
            {filterByCategories && (
              <div className="simple_header_search_component_middle">
                <Select
                  placeholder="Search with categories"
                  isClearable={true}
                  selectedCategory={selectedCategory()}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "none",
                      border: "none",
                      borderRadius: "30px",
                      padding: 4.5,
                      backgroundColor: "#ECFAFD",
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "0.875rem",
                      color: "#5F6465",
                    }),
                  }}
                  options={[
                    { categoryName: "All Categories", value: null },
                    ...categories,
                  ].map((cat) => {
                    return { label: cat.categoryName, value: cat._id };
                  })}
                  onChange={(val) => {
                    if (!val) {
                      getCategory("");
                    } else {
                      getCategory(val.value);
                    }
                  }}
                  value={selectedCategory()}
                />
              </div>
            )}
            {tagsFilter && (
              <div
                className={`simple_header_search_component ${
                  !filterByCategories ? "ml-2" : ""
                }`}
              >
                <Select
                  placeholder="Filter with tags"
                  isClearable={true}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "none",
                      border: "none",
                      borderRadius: "30px",
                      padding: 4.5,
                      backgroundColor: "#ECFAFD",
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "0.875rem",
                      color: "#5F6465",
                    }),
                  }}
                  options={
                    tags &&
                    [{ name: "All Tags", value: [] }, ...tags]?.map((cat) => {
                      return { label: cat.name, value: cat._id };
                    })
                  }
                  value={selectedTag()}
                  onChange={(val) => {
                    if (!val) {
                      getTag(val);
                    } else {
                      getTag(val.value);
                    }
                  }}
                />
              </div>
            )}
            {streamFilter && (
              <div
                className={`simple_header_search_component ${
                  streamFilter ? "ml-2" : ""
                }`}
              >
                <Select
                  placeholder={
                    isCategory
                      ? "Filter stream category"
                      : "Filter stream items"
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "none",
                      border: "none",
                      borderRadius: "30px",
                      padding: 4.5,
                      backgroundColor: "#ECFAFD",
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "0.875rem",
                      color: "#5F6465",
                    }),
                  }}
                  options={
                    streamFilter &&
                    [...source]?.map((cat) => {
                      return { label: cat.name, value: cat._id };
                    })
                  }
                  onChange={(val) => {
                    if (!val) {
                      getSource("local");
                    } else {
                      getSource(val.value);
                    }
                  }}
                  value={selectedSource()}
                  isClearable={true}
                />
              </div>
            )}

            {locationFilter && (
              <div className={`simple_header_search_component ${"ml-2"}`}>
                <Select
                  placeholder={"Filter by location"}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "none",
                      border: "none",
                      borderRadius: "30px",
                      padding: 4.5,
                      backgroundColor: "#ECFAFD",
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: "0.875rem",
                      color: "#5F6465",
                    }),
                  }}
                  value={selectedLocation()}
                  isClearable={true}
                  options={
                    locationFilter &&
                    locations &&
                    [{ name: "All Location", _id: "all" }, ...locations]?.map(
                      (cat) => {
                        return { label: cat.name, value: cat._id };
                      }
                    )
                  }
                  onChange={(val) => {
                    if (!val) {
                      getLocation("all");
                    } else {
                      getLocation(val.value);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
