import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";

export default function HistoryForm({ id }) {
  const { find } = useEntity("order");
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  useEffect(() => {
    find({
      customerId: id,
      restaurant: authRest || restaurant,
    });
  }, []);

  return (
    <>
      <Table className="align-items-center" responsive bordered>
        <thead className="thead-light">
          <tr>
            <th scope="col">Date Added</th>
            <th scope="col">Comment Added</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4].map((d, i) => {
            return (
              <tr key={i}>
                <td>Comment .....</td>
                <td>{moment().format("LLL")}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Row className="text-left mt-2">
        <Col md={10}>
          <Input type="textarea" />
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button color="primary">Add History</Button>
        </Col>
      </Row>
    </>
  );
}
