/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from "react"
import Select from "react-select"
import { toast } from "react-toastify"
import {
  Button,
  Col,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import { AuthContext } from "../../../../context"
import { useEntity } from "../../../../hooks/useEntity"
import { useAdminStore } from "../../../../store/admin"
import CopyOptionsRow from "./CopyOptionsRow"

const CopyModifiers = () => {
  const authContext = useContext(AuthContext)
  const [selectedItemForModifiers, setSelectedItemForModifiers] = useState()
  const [selectedItemsForCopy, setSelectedItemsForCopy] = useState()
  const [selectedGroup, setSelectedGroup] = useState("")
  const { restaurant } = useAdminStore()
  const { find: findAllItems, entities: allItems } = useEntity("item")
  const [allModifiers, setAllModifiers] = useState({})
  const [allSelectedItems, setAllSelectedItems] = useState([])
  const { create, loading } = useEntity("item/copy")
  const [selectedItemId, setSelectedItemId] = useState("")

  console.log(allModifiers, "ALL MODS")
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id

  useEffect(() => {
    findAllItems({
      restaurant: restaurant || authRest,
    })
  }, [restaurant])

  const handleCopyModifiers = (selectedModifiers) => {
    setSelectedItemId(selectedModifiers?.value)

    if (Object.keys(selectedModifiers?.modifiers).length <= 0) {
      setAllModifiers({})
    }

    setAllModifiers({ ...selectedModifiers?.modifiers })
  }

  const handleSelectItems = (e) => {
    e.map((mod) => {
      setAllSelectedItems((prev) => [...prev, mod?.value])
    })
  }

  const handleSubmit = async () => {
    try {
      const array = [...new Set([...allSelectedItems])]

      setAllSelectedItems()

      setSelectedItemForModifiers()

      setSelectedItemsForCopy()

      await create({
        items: array,
        modifiers: allModifiers,
      })

      toast.success("Modifiers copied successfully")
    } catch (error) {
      toast.error(error)
    }
  }

  const handleDelete = (activeItem) => {
    delete allModifiers[activeItem]
  }

  return (
    <div>
      <Row>
        <Col md="12">
          <FormGroup style={{ textAlign: "left" }}>
            <Row className="align-items-center">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600", textAlign: "left" }}
                >
                  Copy Modifiers From
                </label>
              </Col>
              <Col md="8">
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isSearchable={true}
                  value={selectedItemForModifiers}
                  menuPortalTarget={document.body}
                  options={allItems?.data?.data
                    ?.filter(
                      (it) => Object.keys(it?.modifiers || {})?.length > 0
                    )
                    ?.map((it) => {
                      return {
                        label: it?.name,
                        value: it?._id,
                        modifiers: it?.modifiers,
                      }
                    })}
                  onChange={(e) => {
                    setSelectedItemForModifiers(e)

                    handleCopyModifiers(e, true)
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600", textAlign: "left" }}
                >
                  Copy Modifiers To
                </label>
              </Col>
              <Col md="8">
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isSearchable={true}
                  isMulti
                  value={selectedItemsForCopy}
                  menuPortalTarget={document.body}
                  options={allItems?.data?.data
                    ?.filter?.((res) => res?._id !== selectedItemId)
                    ?.map((it) => {
                      return {
                        label: it?.name,
                        value: it?._id,
                        modifiers: it?.modifiers,
                      }
                    })}
                  onChange={(e) => {
                    setSelectedItemsForCopy(e)
                    handleSelectItems(e)
                  }}
                />
              </Col>
            </Row>
            {Object.keys(allModifiers).length > 0 && (
              <ListGroup className="mt-4">
                {Object?.keys(allModifiers)?.map((key, i) => {
                  return (
                    <ListGroupItem
                      key={i}
                      className={selectedGroup === key && "active"}
                      onClick={() => {
                        setSelectedGroup(key)
                      }}
                      style={{ padding: 5, cursor: "pointer" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <span
                          onClick={() => {
                            handleDelete(key)
                          }}
                        >
                          <i className="fa fa-minus-circle ml-2" />
                        </span>
                        <span className="ml-2">
                          {allModifiers[key]?.name?.length > 20
                            ? allModifiers[key]?.name?.substring(0, 18) + "..."
                            : allModifiers[key]?.name}
                        </span>
                      </div>
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            )}
          </FormGroup>
        </Col>
      </Row>
      {selectedGroup !== "" && (
        <Row>
          <Table>
            <thead className="thead-light text-left">
              <tr>
                <th>Modifier Value</th>
                <th>Price</th>
                <th>Sort</th>
              </tr>
            </thead>
            <tbody>
              {allModifiers[selectedGroup]?.subProducts?.map((prod, i) => {
                return (
                  <CopyOptionsRow
                    i={i}
                    modifiers={allModifiers}
                    prod={prod}
                    selectedGroup={selectedGroup}
                    key={i}
                  />
                )
              })}
            </tbody>
          </Table>
        </Row>
      )}
      <Row className="mt-5 d-flex justify-content-end">
        <Button
          disabled={allSelectedItems?.length <= 0}
          color="primary"
          onClick={() => {
            handleSubmit()
            setSelectedGroup("")
            setAllModifiers([])
            setAllSelectedItems([])
            setSelectedItemForModifiers("")
            setSelectedItemsForCopy("")
          }}
        >
          {loading ? <Spinner /> : "Submit"}
        </Button>
      </Row>
    </div>
  )
}

export default CopyModifiers
