import * as Yup from "yup";
import { phoneRegExp } from "./AddLocation";

export const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("Restaurant name is required"),
  address: Yup.string().trim().required("Address is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .max(10, "Phone number can't be more than 10 digits")
    .required("Phone number is required"),
  url: Yup.string()
    .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric and without spaces")
    .required("Restaurant URL is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email")
    .required("Email is required"),
  state: Yup.string().trim().required("State is required"),
  accountColor: Yup.string()
    .trim()
    .required("Provide account color in HEXCODE & RGB Code"),
  homeLink: Yup.string().url(),
  username: Yup.string().trim().required("Username is required"),
  password: Yup.string().trim().required("Password is required"),
  payment: Yup.string().trim().required("Payment setting is required"),
  orderFee: Yup.string().trim().required("Order Fee type is required"),
  fixedValue: Yup.number().when("orderFee", {
    is: "Fixed",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Order Fee is required"),
  }),
  firstTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  secondTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  thirdTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  fourthTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  firstTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  secondTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  thirdTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  fourthTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  ihdFeesValue: Yup.number()
    .optional()
    .min(0, "cannot be less than 0")
    .max(999, "cannot be more tha 999")
    .typeError("Please type a number between 0-999"),
});

export const editRestaurantSchema = Yup.object().shape({
  name: Yup.string().trim().required("Restaurant name is required"),
  address: Yup.string().trim().required("Address is required"),
  phone: Yup.string().trim().required("Phone is required"),
  url: Yup.string()
    .matches(/^[0-9a-zA-Z]+$/, "Must be alphanumeric and without spaces")
    .required("URL is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email")
    .required("Email is required"),
  password: Yup.string().trim().min(6, "Must be atleast 6 characters"),

  state: Yup.string().trim().required("State is required"),

  accountColor: Yup.string()
    .trim()
    .required("Provide account color in HEXCODE or RGB Code"),
  username: Yup.string().trim().required("Username is required"),
  homeLink: Yup.string().url(),

  payment: Yup.string().trim().required("Payment Setting is required"),
  orderFee: Yup.string().trim().required("Order Fee Type is required"),
  fixedValue: Yup.number().when("orderFee", {
    is: "Fixed",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Order Fee is required"),
  }),
  firstTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  secondTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  thirdTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  fourthTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  firstTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  secondTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  thirdTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  fourthTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  ihdFeesValue: Yup.number()
    .nullable()
    .optional()
    .min(0, "cannot be less than 0")
    .max(999, "cannot be more tha 999")
    .typeError("Please type a number between 0-999"),
});
