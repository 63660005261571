import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { FaPlus } from "react-icons/fa";
import { Button, Card, Col, Input, Row, Spinner } from "reactstrap";
import swal from "sweetalert";
import { LocationHoursRow } from "../../../../components/LocationHoursRow";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
import { days } from "../../menu/tabs/AvailablityTabItem";
import HoursModal from "../components/HoursModal";

function groupSchedulesByDay(schedules) {
  const reduced = schedules
    .filter((cv) => {
      const key = `${cv.pickupFrom}|${cv.pickupTo}|${cv.deliveryFrom}|${cv.deliveryTo}`;
      if (key !== "|||") {
        return cv;
      }
    })
    .reduce((pv, cv) => {
      const key = `${cv.pickupFrom}|${cv.pickupTo}|${cv.deliveryFrom}|${cv.deliveryTo}`;
      if (!pv[key]) pv[key] = [];
      pv[key].push(cv);
      return pv;
    }, {});

  const groupedSchedules = Object.values(reduced).map((row) => {
    return {
      days: row.map((r) => r.day),
      schedules: row[0],
    };
  });

  return groupedSchedules;
}

function ungroupDaysBySchedule(schedule) {
  const days = [];

  // Loop through each item in the schedule array
  schedule.forEach((item) => {
    // Split the day string into an array
    const dayArray = item.days;

    // Loop through each day in the array, and create a new object for each day
    dayArray.forEach((day) => {
      const newDay = {
        day: day,
        pickupTo: item.schedules.pickupTo,
        pickupFrom: item.schedules.pickupFrom,
        deliveryTo: item.schedules.deliveryTo,
        deliveryFrom: item.schedules.deliveryFrom,
      };
      days.push(newDay);
    });
  });

  return days;
}

const Hours = ({ onSelect }) => {
  const authContext = useContext(AuthContext);
  const { find, entities, loading } = useEntity("location");
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const formik = useFormik({
    initialValues: {
      selectedLocation: "",
      hoursOfOperation: [],
      selectedDays: [],
      pickupFrom: "",
      pickupTo: "",
      deliveryFrom: "",
      deliveryTo: "",
      openModal: false,
      method: "",
      groupedHours: [],
      activeIndex: -1,
    },
    onSubmit: (values, { setValues }) => {
      if (
        values.selectedDays?.length <= 0 ||
        (values.pickupTo === "" &&
          values.pickupFrom === "" &&
          values.deliveryTo === "" &&
          values.deliveryTo === "")
      ) {
        swal({
          title: "Please select all the required fields",
          icon: "warning",
          dangerMode: true,
        });
        return;
      }
      const ops = values.selectedDays.map((day) => {
        return {
          day,
          pickupTo: values.pickupTo,
          pickupFrom: values.pickupFrom,
          deliveryTo: values.deliveryTo,
          deliveryFrom: values.deliveryFrom,
        };
      });

      setValues({
        ...values,
        hoursOfOperation: [...values.hoursOfOperation, ...ops],
        selectedDays: [],
        pickupTo: "",
        pickupFrom: "",
        deliveryTo: "",
        deliveryFrom: "",
      });
    },
  });

  const { findOne, entity } = useEntity("location");

  useEffect(() => {
    find({
      resturant: authRest || restaurant,
    });
    if (formik.values.selectedLocation) {
      findOne(
        formik.values.selectedLocation !== "" && formik.values.selectedLocation
      );
    }
  }, [authRest, formik.values.selectedLocation]);

  useEffect(() => {
    if (entity) {
      formik.setFieldValue("hoursOfOperation", entity?.data?.hoursOfOperation);
    }
  }, [entity, formik.values.selectedLocation]);

  const handleScheduleChange = (schedule) => {
    const { from, to } = schedule;
    if (formik.values.method === "pickup") {
      formik.setValues({
        ...formik.values,
        pickupFrom: from?.value,
        pickupTo: to?.value,
        deliveryFrom: formik.values.deliveryFrom,
        deliveryTo: formik.values.deliveryTo,
      });
    } else {
      formik.setValues({
        ...formik.values,
        pickupFrom: formik.values.pickupFrom,
        pickupTo: formik.values.pickupTo,
        deliveryFrom: from?.value,
        deliveryTo: to?.value,
      });
    }
  };

  const handleEditSchedule = (schedule) => {
    const { from, to } = schedule;
    const { schedules, days } =
      formik.values.groupedHours[formik.values.activeIndex];

    if (formik.values.method === "pickup") {
      schedules.pickupFrom = from?.value;
      schedules.pickupTo = to?.value;
    } else {
      schedules.deliveryFrom = from?.value;
      schedules.deliveryTo = to?.value;
    }
    formik.values.groupedHours[formik.values.activeIndex] = { days, schedules };
    // formik.setFieldValue("groupedHours", ...formik.values.groupedHours);
    formik.setFieldValue(
      "hoursOfOperation",
      ungroupDaysBySchedule(formik.values.groupedHours)
    );
    formik.setFieldValue("activeIndex", -1);
  };

  useEffect(() => {
    formik.setFieldValue("groupedHours", [
      ...groupSchedulesByDay(formik.values.hoursOfOperation),
    ]);

    onSelect({
      location: formik.values.selectedLocation,
      timings: formik.values.hoursOfOperation,
    });
  }, [formik.values.hoursOfOperation]);

  useEffect(() => {
    if (entities?.data?.data?.length > 0) {
      formik.setFieldValue("selectedLocation", entities?.data?.data?.[0]?._id);
    }
  }, [entities]);

  return (
    <>
      {!loading ? (
        <Card className="shadow-none">
          <Row>
            <Col
              md="4"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "right",
                fontWeight: "bolder",
              }}
            >
              Locations
            </Col>
            <Col
              md="8"
              className={isMobile && "my-2"}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Input
                onChange={(e) => {
                  formik.setFieldValue("selectedLocation", e.target.value);
                }}
                type="select"
                value={formik.values.selectedLocation}
              >
                <option value={null}>Select</option>
                {entities?.data?.data?.map((item) => {
                  return (
                    <option key={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
          <div className="py-2 px-4 mt-4 availability_row">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {days.map((day, index) => (
                <div className={`${index === 0 ? "" : "ml-3"}`}>
                  <Button
                    style={{ fontSize: 12 }}
                    disabled={
                      formik.values.hoursOfOperation?.filter(
                        (slt) => slt.day === day
                      ).length >= 2
                    }
                    color={
                      formik.values.selectedDays.includes(day)
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => {
                      if (formik.values.selectedDays.includes(day)) {
                        const indx = formik.values.selectedDays.findIndex(
                          (d) => d === day
                        );
                        formik.values.selectedDays.splice(indx, 1);
                        formik.setValues(formik.values);
                      } else {
                        formik.setFieldValue("selectedDays", [
                          ...formik.values.selectedDays,
                          day,
                        ]);
                      }
                    }}
                  >
                    {day.substring(0, 3)}
                  </Button>
                </div>
              ))}
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ width: 100, fontWeight: "600", fontSize: 12 }}>
                Pickup Time
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {formik.values.pickupTo !== "" ? (
                  <span className="text-sm mt-2">
                    {moment(
                      new Date(
                        0,
                        0,
                        0,
                        formik?.values?.pickupFrom?.split(":")[0],
                        formik?.values?.pickupFrom.split(":")[1]
                      )
                    ).format("LT")}{" "}
                    -{" "}
                    {moment(
                      new Date(
                        0,
                        0,
                        0,
                        formik?.values?.pickupTo?.split(":")[0],
                        formik?.values?.pickupTo.split(":")[1]
                      )
                    ).format("LT")}
                  </span>
                ) : (
                  <Button
                    className="mt-2"
                    size="sm"
                    style={{ width: 100, fontSize: 10 }}
                    onClick={() => {
                      formik.setFieldValue("openModal", true);
                      formik.setFieldValue("method", "pickup");
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ width: 100, fontWeight: "600", fontSize: 12 }}>
                Delivery Time
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {
                  <>
                    {formik.values.deliveryFrom !== "" ? (
                      <span className="text-sm mt-2">
                        {moment(
                          new Date(
                            0,
                            0,
                            0,
                            formik?.values?.deliveryFrom?.split(":")[0],
                            formik?.values?.deliveryFrom.split(":")[1]
                          )
                        ).format("LT")}{" "}
                        -{" "}
                        {moment(
                          new Date(
                            0,
                            0,
                            0,
                            formik?.values?.deliveryTo?.split(":")[0],
                            formik?.values?.deliveryTo.split(":")[1]
                          )
                        ).format("LT")}
                      </span>
                    ) : (
                      <Button
                        size="sm"
                        className="mt-2"
                        style={{ width: 100, fontSize: 10 }}
                        onClick={() => {
                          formik.setFieldValue("openModal", true);
                          formik.setFieldValue("method", "delivery");
                        }}
                      >
                        Add
                      </Button>
                    )}
                  </>
                }
              </div>
            </div>

            <div className="mt-2">
              <Button
                className="bg-red px-3 py-2 rounded"
                style={{ cursor: "pointer" }}
                onClick={formik.handleSubmit}
              >
                <FaPlus color="white" />
              </Button>
            </div>
          </div>

          {formik.values.selectedLocation !== "" &&
            formik.values.groupedHours.map((row, index) => {
              return (
                <LocationHoursRow
                  data={row}
                  index={index}
                  handleEditSchedule={(index, method) => {
                    formik.setFieldValue("activeIndex", index);
                    formik.setFieldValue("method", method);
                    formik.setFieldValue("openModal", true);
                  }}
                  handleDeleteSchedule={(index) => {
                    formik.values.groupedHours.splice(index, 1);
                    formik.setFieldValue(
                      "hoursOfOperation",
                      ungroupDaysBySchedule(formik.values.groupedHours)
                    );
                  }}
                />
              );
            })}
        </Card>
      ) : (
        <div>
          <Spinner />
        </div>
      )}

      <HoursModal
        open={formik.values.openModal}
        toggle={() => formik.setFieldValue("openModal", false)}
        data={
          formik.values.groupedHours?.[formik.values.activeIndex]?.schedules
        }
        setData={(val) => {
          if (formik.values.activeIndex !== -1) {
            console.log("TESTIMONA", formik.values.activeIndex);
            handleEditSchedule(val);
          } else {
            console.log("TESTIMONA2");
            handleScheduleChange(val);
          }
        }}
        edit={formik.values.activeIndex >= 0}
        method={formik.values.method}
        selectedDays={formik.values.selectedDays}
        setMethod={(val) => formik.setFieldValue("openModal", val)}
      />
    </>
  );
};

export default Hours;
