import { useFormik } from "formik";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import ErrorLable from "../../../../utils/ErrorLable";
import logger from "../../../../utils/logger";
import ModalHeader from "./ModalHeader";

export default function ForgotPassword({ open, toggle, theme }) {
  let history = useHistory();

  const { restaurant } = useOrderingRestaurant();
  const { create, loading } = useEntity("eat/auth/reset");
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        const customer = await create({
          email: values.email,
          restaurant: restaurant?.userId?._id,
        });

        if (customer?.data) {
          history.push({
            pathname: "verify-otp",
            state: { customer },
          });
        }
      } catch (error) {
        logger.push({ error, info: "Customer_Forgot_Password" });
        swal(error.message, "", "error");
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
    }),
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={open}
      toggle={() => {
        resetForm();
        toggle();
      }}
    >
      <ModalHeader
        title="Forgot Password?"
        toggle={() => {
          resetForm();
          toggle();
        }}
      />

      <div className="modal-body">
        <p style={{ textAlign: "center" }}>
          Enter the e-mail address associated with your account. If an account
          exists, we will send a reset password email.
        </p>
        <Form role="form">
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={values.email}
                onChange={handleChange("email")}
                onBlur={handleBlur}
                placeholder="Email"
                type="email"
              />
            </InputGroup>
            {touched.email && errors.email && (
              <ErrorLable message={errors.email} />
            )}
          </FormGroup>

          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Button
                onClick={handleSubmit}
                style={{
                  width: "100%",
                  backgroundColor: theme.primary,
                  color: "#fff",
                }}
              >
                {loading ? <Spinner size="sm" /> : "CONTINUE"}
              </Button>
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
    </Modal>
  );
}
