/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
// reactstrap components
import { Spinner } from "reactstrap";
import ConfirmModal from "../../../components/ConfirmModal";
// core components
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTable from "../../../widgets/SuperTable";
import TableWrapper from "../../../widgets/TableWrapper";
import { CustomersRow } from "./components/CustomersRow";
import { useCallback } from "react";
// reactstrap components
import { Button } from "reactstrap";

// core components

import { exportAsCSV } from "../../../utils/expoAsCSV";

function Customers(props) {
  const [open, setOpen] = useState(false);
  const { entities, find, loading: isLoading } = useEntity("customer");
  const { restaurant: restaurantId } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    find({
      restaurant: authRest || restaurantId,
      limit: rowsPerPage,
      page,
      sorting,
    });
  }, [page, rowsPerPage, authRest, sorting]);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = [
    "Customer Name",
    "Email",
    "Customer Group",
    "Status",
    "Date Added",
    "Add Loyalty Points",
  ];

  const handleExport = useCallback(() => {
    const data = entities?.data?.exportData?.map((data, idx) => ({
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
    }));
    exportAsCSV({ arrayOfObject: data, fileName: "customers" });
  }, [entities?.data?.exportData]);

  return (
    <>
      <ConfirmModal open={open} toggle={() => setOpen(!open)} />
      <SimpleHeader
        path="/resturants"
        name="Customers"
        parentName="Dashboard"
        showBtn
      >
        <Button onClick={handleExport} color="primary">
          Export
        </Button>
      </SimpleHeader>

      <TableWrapper>
        {!isLoading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoading}
            history={props.history}
            row={CustomersRow}
            columns={Headers}
            isLoading={isLoading}
            emptyMessage="Currently no Customers"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}

export default Customers;
