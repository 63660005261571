import React from "react";

// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import MobileSidebarContent from "./MobileSidebarContent";
import { FaBars } from "react-icons/fa";
import { useTheme } from "../../store/theme";

const MobileSidebar = ({ history }) => {
  const { theme } = useTheme();

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div style={{ zIndex: 999 }}>
      <FaBars onClick={toggleDrawer} size="24px" color={theme.secondary} />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className="bla bla bla "
      >
        <MobileSidebarContent
          history={history}
          toggle={() => setIsOpen(!isOpen)}
        />
      </Drawer>
    </div>
  );
};

export default MobileSidebar;
