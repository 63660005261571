/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { Button, Card, CardBody, Container, Row, Spinner } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { exportAsCSV } from "../../../utils/expoAsCSV";
import SuperTable from "../../../widgets/SuperTable";
import ReportsHeader from "./components/ReportsHeader";
import TableRowRestaurantOrdersReport from "./components/TableRowRestaurantOrdersReport";

const RestaurantIndividualOrder = (props) => {
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const [locationFilter, setLocationFilter] = useState();

  const {
    find,
    entities,
    loading: isLoading,
  } = useEntity("restaurant-report/orders");

  const { create } = useEntity("restaurant-report/orders/export");
  const {
    find: locations,
    entities: locationsData,
    loading: locationLoading,
  } = useEntity("location");

  useEffect(() => {
    find({
      restaurantId: authRest || restaurant,
      startDate: startDate,
      endDate: endDate,
      location: locationFilter,
      page,
      limit: rowsPerPage,
      sorting,
    });
    locations({ resturant: authRest || restaurant });
  }, [
    startDate,
    endDate,
    restaurant,
    authRest,
    locationFilter,
    rowsPerPage,
    page,
  ]);

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };
    // needed for a bug in react-date-range which does not
    // set correct end date time

    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const Headers = [
    "Order Number",
    "Items",
    "Total",
    "Sub Total",
    "Tax",
    "Tip",
    "Delivery Fee",
    "Order fee",
  ];

  return (
    <div>
      <SimpleHeader
        name="Normal Orders Report"
        path="/resturants"
        parentName="Restaurant"
        showBtn={true}
      >
        <Button
          color="primary"
          size="md"
          onClick={async () => {
            const exportData = await create({
              restaurantId: authRest || restaurant,
              startDate: startDate,
              endDate: endDate,
              location: locationFilter,
              page,
              limit: rowsPerPage,
              sorting,
            });

            exportAsCSV({
              arrayOfObject: exportData.data,
              fileName: `Restaurant Orders Report (${new Date().toDateString()})`,
            });
          }}
        >
          CSV
        </Button>
      </SimpleHeader>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ReportsHeader
                endDate={endDate}
                startDate={startDate}
                handleSelectDates={handleSelectDates}
                locationFilter={locationFilter}
                locationsData={locationsData?.data}
                setLocationFilter={setLocationFilter}
                locationField={true}
              />
              <CardBody className="mt--5">
                {!isLoading || locationLoading ? (
                  <SuperTable
                    sort={handleSort}
                    showCheckboxes={false}
                    data={entities?.data?.data}
                    onPageChange={handlePageChange}
                    OnRowPerPageChange={handleRowsPerPageChange}
                    total={entities?.data?.total}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    loading={isLoading}
                    history={props.history}
                    row={TableRowRestaurantOrdersReport}
                    columns={Headers}
                    isLoading={isLoading}
                    prevPage={entities?.data?.prevPage}
                    currentPage={entities?.data?.currentPage}
                    nextPage={entities?.data?.nextPage}
                    emptyMessage="No records"
                  />
                ) : (
                  <div
                    style={{
                      padding: 20,
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <center>
                      <Spinner size="lg" color="primary" type="border" />
                    </center>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default RestaurantIndividualOrder;
