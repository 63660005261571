/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Button, CardText, Col, Row, Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import RefundModal from "../components/RefundModal";

export default function EnquiryOrderDetails({ data, history }) {
  const { findOne, entity } = useEntity("eat/ordering/order-alias/enquiry");
  const { findOne: findOneCateringAlias, entity: cateringAlias } = useEntity(
    "eat/ordering/order-alias"
  );

  const { create: isRefund, loading: refundStateLoading } =
    useEntity("order/isRefund");

  const { create: processRefund, loading } = useEntity("payment/refund");

  const [isRefundResult, setIsRefundResultt] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const { create: processVoid, loading: voidLoading } =
    useEntity("payment/void");

  useEffect(() => {
    findOne(data?._id);
  }, []);

  useEffect(() => {
    findOneCateringAlias(data?._id);
  }, []);

  useEffect(() => {
    isRefund({ orderId: data?._id })
      .then((res) => {
        console.log("res", res);
        setIsRefundResultt(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleProcessRefund = async (amount) => {
    try {
      await processRefund({ orderId: data?._id, amount });
      swal("Success", "Refund Approved", "success");
      setModalOpen(false);
      history.goBack();
    } catch (error) {
      swal({ text: "Error while processing refund.", icon: "error" });
    }
  };

  return (
    <>
      <CardText className="mb-4">
        {[
          {
            key: "Order ID",
            value: "#" + data?.orderNum,
          },
          {
            key: "Store name",
            value: data?.restaurant?.restaurant?.name,
          },
          {
            key: "Store URL",
            value: data?.restaurant?.restaurant?.url,
          },
          {
            key: "Customer",
            value: data?.customer?.name,
          },

          {
            key: "Email",
            value: data?.customer?.email,
          },
          {
            key: "Telephone",
            value: data?.customer?.phone,
          },

          {
            key: "Total",
            value: "$" + data?.payment?.total?.toFixed(2),
          },

          {
            key: "Order Placed",
            value: moment(data?.createdAt).format("MM/DD/YYYY hh:mm A"),
          },

          {
            key: "Order Method",
            value:
              String(data?.method).toUpperCase() +
              " - " +
              String(data?.cateringDetails?.method).toUpperCase(),
          },
          {
            key: "Payment Status",
            value: (
              <Badge
                color={
                  data?.payment?.status === "approved" ? "success" : "warning"
                }
              >
                {data?.payment?.status
                  ? data?.payment?.status
                  : "Needs Payment"}
              </Badge>
            ),
          },
          {
            key: "Head Count",
            value: data?.cateringDetails?.count,
          },
          {
            key: "Location",
            value: data?.location?.name,
          },

          {
            key: "Address",
            value:
              data?.method === "catering" &&
              data?.cateringDetails?.method === "delivery"
                ? (data?.cateringDetails?.apartment?.length > 0
                    ? data?.cateringDetails?.apartment
                    : "") + data?.cateringDetails?.address
                : data?.method === "delivery"
                ? (data?.deliveryAddress?.apartmentNo?.length > 0
                    ? data?.deliveryAddress?.apartmentNo
                    : "") + data?.deliveryAddress?.formatted_address
                : data?.location?.address,
          },
          {
            key: "Ready At",
            value:
              data.orderTiming === "now"
                ? moment(
                    data?.location?.enableIhd
                      ? data?.ihdDeliveryTime
                      : data?.orderNowPickupDate
                  ).format("MM/DD/YYYY hh:mm A")
                : moment(data?.scheduledOn).format("MM/DD/YYYY hh:mm A"),
          },
          (data?.payment?.status === "approved" ||
            data?.payment?.status === "succeeded" ||
            data?.payment?.status === "paid") &&
          !refundStateLoading
            ? {
                key: isRefundResult ? "Refund" : "Void",
                value: (
                  <Button
                    disabled={voidLoading}
                    onClick={async () => {
                      if (isRefundResult) {
                        setModalOpen(true);
                      } else {
                        try {
                          await processVoid({ orderId: data?._id });
                          swal({ text: "Void successful", icon: "success" });
                          history.goBack();
                        } catch (error) {
                          swal({
                            text: "Error while processing void.",
                            icon: "error",
                          });
                        }
                      }
                    }}
                    size="sm"
                    color="primary"
                  >
                    {isRefundResult ? (
                      "Refund"
                    ) : voidLoading ? (
                      <Spinner size={"sm"} />
                    ) : (
                      "Void"
                    )}
                  </Button>
                ),
              }
            : false,
          {
            key: "Order Details",
            value: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  disabled={
                    entity?.data?.message && cateringAlias?.data?.message
                  }
                  size="sm"
                >
                  <a
                    href={`https://7ord.co/o/${
                      entity?.data?.alias || cateringAlias?.data?.alias
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click to open
                  </a>
                </Button>
                <small>
                  {entity?.data?.message && cateringAlias?.data?.message
                    ? entity?.data?.message
                    : ""}
                </small>
              </div>
            ),
          },

          data?.note?.length > 0
            ? {
                key: "Special Instructions",
                value: data?.note,
              }
            : false,
        ]
          .filter((r) => r)
          .map((d) => {
            return (
              <Row className="my-1">
                <Col md={2}>
                  <span style={{ fontWeight: 500 }}>{d.key}</span>
                </Col>
                <Col md={8}>
                  <span>{d.value}</span>
                </Col>
              </Row>
            );
          })}
      </CardText>
      <RefundModal
        open={modalOpen}
        refundAmount={data?.payment?.total?.toFixed(2)}
        toggle={() => setModalOpen(false)}
        orderId={data?._id}
        refundProcess={handleProcessRefund}
        loading={loading}
      />
    </>
  );
}
