import { useEffect } from "react";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import EmptyPlaceholder from "../menu/components/EmptyPlaceholder";

const getDeliveryTime = (location, deliveryZone) => {
  let deliveryReadyTime = 0;
  console.log(location);
  if (location?.enableIhd) {
    deliveryReadyTime = location?.ihdReadyTime || 0;
  } else {
    deliveryReadyTime = deliveryZone?.deliveryReadyTime || 0;
  }

  return deliveryReadyTime;
};

const ScheduleComponent = ({
  theme,
  label,
  onSelect,
  desc,
  descStyles,
  disabled,
  waitingTimes,
}) => {
  return (
    <>
      {!disabled && (
        <div
          onClick={onSelect}
          className={`schedule_card_ordering`}
          style={{ border: `2px solid ${theme?.primary}`, maxWidth: "350px" }}
        >
          <div
            style={
              descStyles
                ? descStyles
                : { fontSize: 18, fontWeight: "600", color: theme?.primary }
            }
          >
            {label}
          </div>

          {label === "Order for now" && waitingTimes > 0 ? (
            <div style={{ color: "grey" }}>Wait: {waitingTimes} mins</div>
          ) : (
            <div style={{ color: "grey" }}>{desc}</div>
          )}
        </div>
      )}
    </>
  );
};

const Schedule = (props) => {
  const { restaurant } = useOrderingRestaurant();
  let history = props.history;
  const { theme } = useTheme();
  const { setTiming, location, method, deliveryZone } = useOrdering();
  const { find: findSchedule, entities: schedule } = useEntity(
    "eat/ordering/schedule"
  );
  let pathname = window.location.pathname.replace(/\/+$/, "");
  pathname = pathname.split("/");

  useEffect(() => {
    if (Object.keys(restaurant).length === 0) {
      const url = window.location.href?.replace(/\/timing$/, "");
      let hostUrl = new URL(url);
      props.history.push(hostUrl.pathname);
      return;
    }
    if ((!location || !method) && Object.keys(restaurant).length > 0) {
      let url = new URL(restaurant?.url);
      props.history.push(url.pathname);
    }
  }, []);

  useEffect(() => {
    findSchedule({ locationId: location?._id, method });
  }, []);

  return (
    <OrderingLayout
      isCenter={true}
      title="When do you want it?"
      history={props.history}
      theme={theme}
      classname="main_ordering_card_wide"
      layout={true}
    >
      <div className="schedule_ordering_card mt-3">
        {restaurant?.ordering?.orderNow && (
          <ScheduleComponent
            label="Order for now"
            desc={
              restaurant?.ordering?.orderNow
                ? "Get your food ASAP"
                : "Order now currently not available"
            }
            theme={theme}
            waitingTimes={
              method === "pickup"
                ? location?.pickupDelivery?.pickupPrepTime
                : getDeliveryTime(location, deliveryZone)
            }
            onSelect={() => {
              if (restaurant?.ordering?.orderNow) {
                history.push("/" + pathname[1] + "/" + pathname[2] + "/menu", {
                  fromFlow: true,
                });
                setTiming("now");
              } else {
              }
            }}
            descStyles={
              !restaurant?.ordering?.orderNow && {
                fontSize: 18,
                fontWeight: "600",
                color: "grey",
              }
            }
            disabled={
              !restaurant?.ordering?.orderNow || !schedule?.data?.length
            }
          />
        )}

        {restaurant?.ordering?.orderLater && restaurant?.ordering?.orderNow && (
          <div className="mx-3 schedule_divider"></div>
        )}
        {restaurant?.ordering?.orderLater && (
          <ScheduleComponent
            label={
              restaurant?.ordering?.orderForLaterBtnText
                ? restaurant?.ordering?.orderForLaterBtnText
                : "Order for later"
            }
            desc={"Select a future date & time"}
            theme={theme}
            onSelect={() => {
              if (restaurant?.ordering?.orderLater) {
                history.push("/" + pathname[1] + "/" + pathname[2] + "/later");
                setTiming("later");
              } else {
              }
            }}
            descStyles={
              !restaurant?.ordering?.orderLater && {
                fontSize: 18,
                fontWeight: "600",
                color: "grey",
              }
            }
            waitingTimes={
              method === "pickup"
                ? location?.pickupDelivery?.pickupPrepTime
                : getDeliveryTime(location, deliveryZone)
            }
            disabled={!restaurant?.ordering?.orderLater}
          />
        )}
      </div>

      {!restaurant?.ordering?.orderNow && !restaurant?.ordering?.orderLater && (
        <div>
          <EmptyPlaceholder text="Currently not accepting orders" />
        </div>
      )}
    </OrderingLayout>
  );
};

export default Schedule;
