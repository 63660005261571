import { Col, Container, Row } from "reactstrap";

const Mqtt = ({ handleChange, values }) => {
  return (
    <div style={{ zIndex: 999, position: "relative" }}>
      <Container fluid>
        <Row className="mt-3">
          <Col md="6">
            <Row className="text-left">
              <Col
                md="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Enable Menuocity
              </Col>
              <Col md="6">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableMenuocity}
                    value={values?.enableMenuocity}
                    onChange={handleChange("enableMenuocity")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Mqtt;
