import { useContext } from "react";
import { Button } from "reactstrap";
import { AuthContext } from "../../../../context";
import useOrdering from "../../../../store/ordering";
import { useTheme } from "../../../../store/theme";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";

const UserButtons = ({ activeTab, setActiveTab }) => {
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();

  const { theme } = useTheme();
  const { method, setActiveTab: setZustandActiveTab, gateway } = useOrdering();

  return (
    <>
      {!authContext?.user?._id && (
        <div className="mt-3">
          {method !== "walkup" && (
            <>
              <Button
                onClick={() => {
                  setActiveTab("sign-in");
                  setZustandActiveTab("sign-in");
                }}
                style={{
                  backgroundColor:
                    activeTab === "sign-in" ? theme?.primary : "white",
                  color: activeTab === "sign-in" ? "white" : "#666666",
                  border:
                    activeTab === "sign-in"
                      ? theme?.primary
                      : "1px solid #666666",
                  textTransform: "uppercase",
                  letterSpacing: "0.1em",
                  fontWeight: "400",
                }}
              >
                Sign in
              </Button>
              <Button
                onClick={() => {
                  setActiveTab("register");
                  setZustandActiveTab("register");
                }}
                style={{
                  backgroundColor:
                    activeTab === "register" ? theme?.primary : "white",
                  color: activeTab === "register" ? "white" : "#666666",
                  border:
                    activeTab === "register"
                      ? theme?.primary
                      : "1px solid #666666",
                  textTransform: "uppercase",
                  letterSpacing: "0.1em",
                  fontWeight: "400",
                }}
              >
                Register
              </Button>
            </>
          )}
          {(restaurant?.enableGuestPaythere || gateway) &&
            !restaurant?.disableGuest && (
              <Button
                onClick={() => {
                  setActiveTab("guest");
                  setZustandActiveTab("guest");
                }}
                style={{
                  backgroundColor:
                    activeTab === "guest" ? theme?.primary : "white",
                  color: activeTab === "guest" ? "white" : "#666666",
                  border:
                    activeTab === "guest"
                      ? theme?.primary
                      : "1px solid #666666",
                  textTransform: "uppercase",
                  letterSpacing: "0.1em",
                  fontWeight: "400",
                }}
              >
                Guest
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default UserButtons;
