import { FaTrash } from "react-icons/fa";
import { RiMastercardLine, RiVisaLine } from "react-icons/ri";
import { useTheme } from "../../../../store/theme";
import EmptyPlaceholder from "../../menu/components/EmptyPlaceholder";

const CardsTable = ({ cards, setSelectedIndex, selectedIndex, showRadio }) => {
  const { theme } = useTheme();
  return (
    <div>
      <table style={{ width: "100%" }}>
        {" "}
        <tr
          style={{ color: theme?.primary, lineHeight: 2 }}
          className="payment_table_header"
        >
          <th className="text-left">Select</th>
          <th>Number</th>
          {/* <th className="text-left">CVC</th> */}
          <th>Type</th>
        </tr>
        {(cards?.data?.length === 0 || !cards?.data) && (
          <tr>
            <td colSpan={4} className="text-center p-4">
              <EmptyPlaceholder />
            </td>
          </tr>
        )}
        {cards?.data?.map((card, i) => {
          return (
            <>
              <tr
                style={{
                  textAlign: "left",
                  lineHeight: 3,
                  borderBottom:
                    !showRadio &&
                    i !== cards?.data?.length - 1 &&
                    "2px solid #ededed",
                }}
              >
                {showRadio && (
                  <td>
                    <input
                      onClick={() => {
                        setSelectedIndex(i);
                      }}
                      checked={i === selectedIndex}
                      type="radio"
                    />
                  </td>
                )}
                <td>{card.truncatedCardNumber}</td>

                <td>
                  {card?.cardLogo === "Visa" ? (
                    <RiVisaLine size={35} />
                  ) : card?.cardLogo === "Mastercard" ? (
                    <RiMastercardLine size={35} />
                  ) : (
                    card.cardLogo
                  )}
                </td>
                {!showRadio && (
                  <td className="mx-6 text-center">
                    <FaTrash color={theme?.primary} />
                  </td>
                )}
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );
};

export default CardsTable;
