import { ItemOptions } from "../../../../components/ProductCard";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import { truncateString } from "../../../../utils/truncate";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

const GridCardProduct = ({ onClick, maxText = 150, data }) => {
  const { restaurant } = useOrderingRestaurant();
  const { theme } = useTheme();

  return (
    <div
      onClick={onClick}
      className=" pointer menu-grid-card"
      style={{
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
      }}
    >
      <div>
        <img
          src={data?.imageUrl !== "" ? data?.imageUrl : restaurant?.logo}
          alt="item_image"
          style={{
            width: "100%",
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            filter: data?.imageUrl === "" && "grayscale(100)",
            opacity: data?.imageUrl === "" && "10%",
            objectFit: "cover",
          }}
          height={200}
          width={"100%"}
        />
      </div>
      <div className="px-3 pt-3">
        <h1 style={{ fontSize: 18 }}>{data?.name}</h1>
        <p className="">{truncateString(data?.description, maxText)}</p>
      </div>
      <div className="px-2 py-2 grid_card_bottom_wrapper">
        <div>
          <button
            className="prod-button-grid bold"
            style={{
              backgroundColor: theme.primary,
              color: "white",
              textTransform: "uppercase",
              fontSize: "15px",
              fontWeight: "600",
              borderRadius: 6,
              borderColor: "white",
            }}
          >
            {data?.price > 0 ? `$${data?.price.toFixed(2)}` : "Select"}
          </button>
        </div>

        <div className="mt-3">
          <ItemOptions modal={false} data={data} />
        </div>
      </div>
    </div>
  );
};

export default GridCardProduct;
