import { useState } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const SecureInput = ({
  icon,
  value,
  onChange,
  className,
  ordering = false,
  placeholder = "",
}) => {
  const [visible, setVisible] = useState(false);

  // const onCopy = useCallback(() => {
  //   toast.info("Password Copied");
  // }, []);

  return (
    <InputGroup>
      {icon && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon} aria-hidden="true"></i>
          </InputGroupText>
        </InputGroupAddon>
      )}
      {ordering ? (
        <input
          type={visible ? "text" : "password"}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
        />
      ) : (
        <Input
          type={visible ? "text" : "password"}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
        />
      )}
      {/* {value?.passowrd !== "" && (
        <CopyToClipboard text={value} onCopy={onCopy}>
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <i
                style={{ padding: 2, cursor: "pointer" }}
                className={"fa fa-clone"}
                aria-hidden="true"
              ></i>
            </InputGroupText>
          </InputGroupAddon>
        </CopyToClipboard>
      )} */}
      <InputGroupAddon addonType="append">
        <InputGroupText onClick={() => setVisible(!visible)}>
          <i
            style={{
              padding: 2,
              cursor: "pointer",
              color: ordering && "#525f7f",
            }}
            className={visible ? "fa fa-eye-slash" : "fa fa-eye"}
            aria-hidden="true"
          ></i>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default SecureInput;
