import Logout from "../../screens/auth/Logout";
import Resturants from "../../screens/partners/Resturants";
import Dashboard from "../../screens/partners/home/DashboardPartner";
import Coupons from "../../screens/partners/marketing/coupons/Coupons";
import PushNotifications from "../../screens/partners/mobile-app/PushNotifications";
import RestaurantCategories from "../../screens/partners/mobile-app/RestaurantCategories";
import Restaurants from "../../screens/partners/mobile-app/Restaurants";
import SalesReportPartner from "../../screens/partners/reports/SalesReport";
import Payments from "../../screens/payments/Payments";
import OrderFeeReports from "../../screens/reports/OrderFeeReports";
import Snapshot from "../../screens/reports/Snapshot";
import MicrosaleConfig from "../../screens/settings/MicrosaleConfig";

const routesPartner = [
  {
    path: "",
    name: "Dashboard",
    icon: "fa fa-home",
    layout: "/partners",
    component: Dashboard,
  },
  {
    path: "/resturants",
    name: "Restaurants",
    icon: "fa fa-building",
    layout: "/partners",
    component: Resturants,
  },
  {
    collapse: true,
    path: "/reports",
    name: "Reports",
    layout: "/partners",
    icon: "ni ni-single-copy-04",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/reports/sales",
        name: "Sales Report",
        miniName: "SR",
        component: SalesReportPartner,
        layout: "/partners",
        icon: "fa fa-wpforms",
      },
      {
        path: "/reports/snapshot",
        name: "Order Report",
        miniName: "D",
        component: Snapshot,
        layout: "/partners",
        icon: "fa fa-image",
      },
      {
        path: "/reports/order-fee",
        name: "Order Fee Report",
        miniName: "SR",
        component: OrderFeeReports,
        layout: "/partners",
        icon: "fa fa-wpforms",
      },
      // {
      //   path: "/reports/transactions",
      //   name: "Transactions",
      //   miniName: "D",
      //   component: Transactions,
      //   layout: "/partners",
      //   icon: "fa fa-exchange",
      // },
      // {
      //   path: "/reports/orders",
      //   name: "Orders",
      //   miniName: "D",
      //   component: Orders,
      //   layout: "/partners",
      //   icon: "fa fa-shopping-cart",
      // },
      // {
      //   path: "/reports/normal-orders",
      //   name: "Normal Orders",
      //   miniName: "NO",
      //   component: NormalOrders,
      //   layout: "/partners",
      //   icon: "fa fa-shopping-cart",
      // },
    ],
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "fa fa-dollar",
    layout: "/partners",
    component: Payments,
  },
  {
    collapse: true,
    path: "/mobile-app",
    name: "Mobile App",
    layout: "/partners",
    icon: "ni ni-app",
    state: "dashboardsCollapse2",
    views: [
      {
        path: "/mobile-app/categories",
        name: "Categories",
        miniName: "Cat",
        component: RestaurantCategories,
        layout: "/partners",
        icon: "fa fa-wpforms",
      },
      {
        path: "/mobile-app/restaurants",
        name: "Restaurants",
        miniName: "D",
        component: Restaurants,
        layout: "/partners",
        icon: "fa fa-spoon text-sm",
      },
      {
        path: "/mobile-app/push-notifications",
        name: "Push Notifications",
        miniName: "PN",
        component: PushNotifications,
        layout: "/partners",
        icon: "fa fa-bell",
      },
    ],
  },
  {
    collapse: true,
    path: "/marketing",
    name: "Marketing",
    layout: "/partners",
    icon: "fa fa-bar-chart",
    state: "dashboardsCollapse22",
    views: [
      {
        path: "/marketing/coupons",
        name: "Coupons",
        icon: "fa fa-gift",
        layout: "/partners",
        component: Coupons,
      },
    ],
  },
  {
    path: "/foodtronix",
    name: "Foodtronix Config",
    icon: "fa fa-circle",
    layout: "/partners",
    component: () => (
      <MicrosaleConfig view="partner" title="Foodtronix" type="foodtronix" />
    ),
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-sign-out text-danger",
    layout: "/partners",
    component: Logout,
  },
];

export default routesPartner;
