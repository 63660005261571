/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { Spinner } from "reactstrap";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { useEntity } from "../../../hooks/useEntity";
import useScreenSize from "../../../hooks/useScreenSize";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import LocationRow from "../components/LocationRow";

const Location = ({ history }) => {
  const screenSize = useScreenSize();

  const [showMap, setShowMap] = useState(screenSize.width > 500);
  const [markers, setMarkers] = useState([]);
  const { theme } = useTheme();
  const { restaurant } = useOrderingRestaurant();
  const [highlightedLocation, setHighLightedLocation] = useState("");
  const { method } = useOrdering();
  const [, setMap] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const mapRef = useRef();

  const {
    find,
    entities: locations,
    loading: isLoading,
  } = useEntity("eat/ordering/location");

  useEffect(() => {
    if (restaurant?.userId?._id) {
      find({ restaurant: restaurant?.userId?._id, orderMethod: method });
    }
  }, [restaurant]);

  const locationData = locations?.data;
  console.log(method, locationData, "METHOD");

  useEffect(() => {
    if (!isInit && locationData) {
      let arr = [];
      locationData?.map((it) => {
        if (it?.latLng?.length === 2) {
          const latLng = { lat: it?.latLng?.[0], lng: it?.latLng?.[1] };
          arr.push(latLng);
        }
      });
      setMarkers([...arr]);
      setIsInit(true);
    }
  }, [locationData]);

  const initMap = () => {
    if (!window?.google) {
      return;
    }
    let google = window.google;
    let map = mapRef.current;
    let lat = "17.385";
    let lng = "78.4867";
    const myLatlng = new google.maps.LatLng(lat, lng);

    const mapOptions = {
      maxZoom: 17,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: false,
      fullscreenControl: false,
      disableDefaultUI: true,

      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    markers?.map((marker) => {
      const newMarker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(marker?.lat, marker?.lng),
        map,
      });

      newMarker.addListener("click", function () {
        const findLocation = locations?.data?.find(
          (loc) =>
            loc?.latLng[0] === marker?.lat && loc?.latLng[1] === marker?.lng
        );

        setHighLightedLocation(findLocation?._id);
      });
    });

    var bounds = new window.google.maps.LatLngBounds();

    for (var i = 0, LtLgLen = markers.length; i < LtLgLen; i++) {
      bounds.extend(markers[i]);
    }

    map.fitBounds(bounds);
    setMap(map);
  };

  React.useEffect(() => {
    if (
      showMap &&
      (restaurant?.ordering?.enableMap === true ||
        restaurant?.ordering?.enableMap === undefined)
    ) {
      initMap();
    }
  }, [showMap, markers, restaurant]);

  useEffect(() => {
    if (Object.keys(restaurant).length === 0) {
      const url = window.location.href?.replace(/\/location$/, "");
      let hostUrl = new URL(url);
      history.push(hostUrl.pathname);
      return;
    }
    if (!method && Object.keys(restaurant).length > 0) {
      let url = new URL(restaurant?.url);
      history.push(url.pathname);
    }
  }, []);

  const { width } = useScreenSize();

  return (
    <OrderingLayout
      isCenter={true}
      loading={isLoading}
      title="Choose your location"
      history={history}
      classname="main_ordering_card_location"
      layout={true}
      renderRight={
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          {(restaurant?.ordering?.enableMap === true ||
            restaurant?.ordering?.enableMap === undefined) &&
            width > 500 && (
              <>
                <IoLocationOutline size={25} color="black" />
                <label className="custom-toggle">
                  <input
                    checked={showMap}
                    onChange={() => setShowMap(!showMap)}
                    type="checkbox"
                  />
                  <span
                    style={{ color: theme?.primary }}
                    className="custom-toggle-slider rounded-circle"
                  />
                </label>
              </>
            )}
        </div>
      }
    >
      <div
        className="map_ordering"
        style={{
          display:
            showMap &&
            screenSize.width > 500 &&
            (restaurant?.ordering?.enableMap === true ||
              restaurant?.ordering?.enableMap === undefined)
              ? "flex"
              : "inline",
        }}
      >
        {isLoading ? (
          <div
            style={{
              height: "50vh",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size="25" />
          </div>
        ) : (
          <>
            <div className="location_wrapper_ordering">
              {locationData
                ?.filter((l) => {
                  if (method === "pickup" && l.pickupDelivery.enablePickup) {
                    return true;
                  } else if (
                    (method === "delivery" &&
                      l.pickupDelivery.enableDelivery) ||
                    l.enableIhd
                  ) {
                    return true;
                  } else if (
                    method === "dine-in" &&
                    l?.ordering?.enableDineIn
                  ) {
                    return true;
                  } else if (method === "catering" && l?.cateringShop) {
                    return true;
                  }
                })
                ?.map((d, i) => {
                  return (
                    <LocationRow
                      data={d}
                      length={locationData?.length}
                      index={i}
                      key={d?._id}
                      history={history}
                      origin={origin}
                      highlightedLocation={highlightedLocation}
                    />
                  );
                })}
            </div>
          </>
        )}

        {locationData?.filter((l) => {
          if (method === "pickup" && l.pickupDelivery.enablePickup) {
            return true;
          } else if (
            (method === "delivery" && l.pickupDelivery.enableDelivery) ||
            l.enableIhd
          ) {
            return true;
          } else if (method === "dine-in" && l?.ordering?.enableDineIn) {
            return true;
          } else if (method === "catering" && l?.cateringShop) {
            return true;
          }
        })?.length <= 0 && (
          <div>
            <h3 style={{ color: "#212121" }}>Currently no locations defined</h3>
          </div>
        )}

        <div
          className="map_wrapper_location"
          style={{
            display:
              showMap &&
              screenSize.width > 500 &&
              (restaurant?.ordering?.enableMap === true ||
                restaurant?.ordering?.enableMap === undefined)
                ? "block"
                : "none",
          }}
        >
          <div className="map-canvas" id="map-custom" ref={mapRef} />
        </div>
      </div>
    </OrderingLayout>
  );
};

export default Location;
