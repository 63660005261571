/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
// reactstrap components
import classnames from "classnames";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

import ConfirmModal from "../../../components/ConfirmModal";
// core components
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import SuperTable from "../../../widgets/SuperTable";
import SuperTableDnd from "../../../widgets/SuperTableDnd";
import TableWrapper from "../../../widgets/TableWrapper";
import EmptyPlaceholder from "../../ordering/menu/components/EmptyPlaceholder";
import { MobileProductRow } from "./components/MobileProductRow";
import { ProductRow } from "./components/ProductRow";
import TableRowUpdatePlu from "./components/TableRowUpdatePlu";
import useItemsFilter from "../../../store/items-filter";

function Items(props) {
  const [tabs, setTabs] = useState(1);
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [open, setOpen] = useState(false);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);

  const { create: updateSortOrder } = useEntity("item/sort-order");

  const {
    source,
    setSource,
    location,
    setLocation,
    tags: tagFilter,
    setTags: setTagFilter,
    category: categorySearch,
    setCategory: setCategorySearch,
  } = useItemsFilter();

  const { find: findLocation, entities: locations } = useEntity("location");

  useEffect(() => {
    findLocation({
      resturant: authRest || restaurant,
      page: 0,
      limit: 10,
      sorting,
    });
  }, []);

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData([...items]);
    setIsSortOrderChanged(true);
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handleSaveSortOrder = async () => {
    const IdsAndPos = data.map((d, idx) => {
      const obj = { _id: d._id, sortOrder: idx };
      return obj;
    });

    try {
      await updateSortOrder({ sortOrders: IdsAndPos });
      toast.success("Sort order updated");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
    setIsSortOrderChanged(false);
  };

  const { find, entities, loading: isLoading } = useEntity("item");

  const { find: findCategories, entities: categories } = useEntity("category");

  const { find: findTags, entities: tags } = useEntity("tag");

  useEffect(() => {
    const query = {};
    if (location) {
      query.location = location;
    }
    find({
      ...query,
      searchQuery,
      restaurant: authRest || restaurant,
      page,
      limit: rowsPerPage,
      sorting,
      categories: categorySearch || "",
      status: tabs === 1 ? true : tabs === 2 ? false : "all",
      tag: tagFilter,
      filterDsp: "false",
      source: source || "local",
    });
  }, [
    page,
    rowsPerPage,
    authRest,
    sorting,
    searchQuery,
    categorySearch,
    tabs,
    tagFilter,
    source,
    tabs,
    location,
  ]);

  useEffect(() => {
    findCategories({
      restaurant: authRest || restaurant,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findTags({ restaurant: authRest || restaurant });
  }, [restaurant]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = !restaurantDoc?.restaurant?.enable_deliverect
    ? isMobile
      ? ["Name", "Image"]
      : [
          "ID",
          "Name",
          "Price",
          "PLU",
          "Location",
          "Category",
          "Tag",
          "Status",
          "Action",
        ]
    : ["ID", "Name", "Price", "PLU", "Location", "Category", "Tag", "Status"];

  const PluUpdateHeaders = ["Name", "PLU", "Action"];

  const toggleNavs = (e, index) => {
    setIsSortOrderChanged(false);
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {
    if (entities?.data?.data) {
      setData(entities?.data?.data);
    }
  }, [entities?.data?.data]);

  const TableDefault =
    categorySearch === "" || !categorySearch ? SuperTable : SuperTableDnd;

  console.log("FLTR", categorySearch);

  return (
    <>
      <ConfirmModal open={open} toggle={() => setOpen(!open)} />
      <SimpleHeader
        showSearch={true}
        path="/resturants"
        name="Items"
        parentName="Dashboard"
        showBtn={true}
        getSearchQuery={(val) => setSearchQuery(val)}
        placeholder="Search with name, plu...."
        filterByCategories={true}
        categories={categories?.data?.data}
        getCategory={(val) => setCategorySearch(val)}
        tagsFilter={true}
        locationFilter={true}
        tags={tags.data}
        selectedTag={() => {
          if (tagFilter === [] || !tagFilter)
            return [{ label: "All Tags", value: [] }];
          const loc = tags?.data?.find((d) => d._id === tagFilter);
          return { label: loc?.name, value: loc?._id };
        }}
        selectedCategory={() => {
          if (categorySearch === "" || !categorySearch)
            return [{ label: "All Categories", value: [] }];
          const loc = categories?.data?.data?.find(
            (d) => d._id === categorySearch
          );
          return { label: loc?.categoryName, value: loc?._id };
        }}
        getTag={(val) => setTagFilter(val)}
        streamFilter={restaurantDoc?.restaurant?.stream?.enableStreamDSP}
        source={[
          { name: "Stream", _id: "stream" },
          { name: "Local", _id: "local" },
        ]}
        locations={locations?.data?.data?.map((d) => {
          return { name: d.name, _id: d._id };
        })}
        selectedLocation={() => {
          if (location === "all")
            return [{ label: "All Location", value: "all" }];
          const loc = locations?.data?.data?.find((d) => d._id === location);
          return { label: loc?.name, value: loc?._id };
        }}
        selectedSource={() => {
          if (source === "stream") {
            return { label: "Source: Stream", value: source };
          } else {
            return { label: "Source: Local", value: source };
          }
        }}
        getSource={(val) => setSource(val)}
        getLocation={(val) => setLocation(val)}
      >
        {!restaurantDoc?.restaurant?.enable_deliverect && (
          <Button
            onClick={() => {
              props.history.push({
                pathname: `/resturants/add-item`,
                search: props.location.search,
              });
            }}
            color="primary"
            size="md"
            disabled={restaurantDoc?.restaurant?.enable_deliverect}
          >
            Add new
          </Button>
        )}
      </SimpleHeader>

      <TableWrapper customStyles="px-4 py-3">
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Active
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Inactive
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 3}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 3,
              })}
              onClick={(e) => toggleNavs(e, 3)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-product-hunt mr-2" />
              PLU Update
            </NavLink>
          </NavItem>
        </Nav>
        {isSortOrderChanged && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
            }}
            className="mr-3 mt-3"
          >
            <Button
              onClick={handleSaveSortOrder}
              outline
              color="warning"
              size="sm"
            >
              Save
            </Button>
          </div>
        )}
        <TabContent className="mt-3" activeTab={"tabs" + tabs}>
          {" "}
          <TabPane tabId="tabs1">
            {!isLoading ? (
              <>
                {isMobile ? (
                  <table id="product_mobile_table" responsive>
                    <thead className="thead-light text-left">
                      <tr>
                        {Headers?.map((head) => {
                          return <th>{head}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((d, i) => {
                        return (
                          <MobileProductRow
                            history={props.history}
                            key={i}
                            index={i}
                            data={d}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <TableDefault
                    sort={handleSort}
                    showCheckboxes={false}
                    data={data}
                    onPageChange={handlePageChange}
                    OnRowPerPageChange={handleRowsPerPageChange}
                    total={entities?.data?.length}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    loading={isLoading}
                    history={props.history}
                    row={ProductRow}
                    columns={Headers}
                    isLoading={isLoading}
                    emptyMessage="Currently no Items"
                    prevPage={entities?.data?.prevPage}
                    nextPage={entities?.data?.nextPage}
                    currentPage={entities?.data?.currentPage}
                    handleDragEnd={handleDragEnd}
                  />
                )}
              </>
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
          </TabPane>
          <TabPane tabId="tabs2">
            {!isLoading ? (
              <>
                {isMobile ? (
                  <table id="product_mobile_table" responsive>
                    <thead className="thead-light text-left">
                      <tr>
                        {Headers?.map((head) => {
                          return <th>{head}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((d, i) => {
                        return (
                          <MobileProductRow
                            history={props.history}
                            key={i}
                            index={i}
                            data={d}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <TableDefault
                    sort={handleSort}
                    showCheckboxes={false}
                    data={data}
                    onPageChange={handlePageChange}
                    OnRowPerPageChange={handleRowsPerPageChange}
                    total={entities?.data?.length}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    loading={isLoading}
                    history={props.history}
                    row={ProductRow}
                    columns={Headers}
                    isLoading={isLoading}
                    emptyMessage="Currently no Items"
                    prevPage={entities?.data?.prevPage}
                    nextPage={entities?.data?.nextPage}
                    currentPage={entities?.data?.currentPage}
                    handleDragEnd={handleDragEnd}
                  />
                )}
              </>
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
          </TabPane>
          <TabPane tabId="tabs3">
            {!isLoading ? (
              <>
                <SuperTable
                  sort={handleSort}
                  showCheckboxes={false}
                  data={entities?.data?.data}
                  onPageChange={handlePageChange}
                  OnRowPerPageChange={handleRowsPerPageChange}
                  total={entities?.data?.length}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  rowsPerPage={rowsPerPage}
                  loading={isLoading}
                  history={props.history}
                  row={TableRowUpdatePlu}
                  columns={PluUpdateHeaders}
                  isLoading={isLoading}
                  emptyMessage="Currently no Items"
                  prevPage={entities?.data?.prevPage}
                  nextPage={entities?.data?.nextPage}
                  currentPage={entities?.data?.currentPage}
                  handleDragEnd={handleDragEnd}
                  tabs={tabs}
                />
              </>
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
            {entities?.data?.data?.length <= 0 && !isLoading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <EmptyPlaceholder text={"Currently no items"} />
              </div>
            )}
          </TabPane>
        </TabContent>
      </TableWrapper>
    </>
  );
}

export default Items;
