import { useFormik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import swal from "sweetalert";

export default function ItemEditModal({
  isVisible,
  toggle,
  onClose,
  data,
  categories,
  selectedCategory,
  tag,
  updateItem,
  handleChangeCategory,
}) {
  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        name: data?.name,
        category: selectedCategory,
        tag: data?.tag?.map((t) => {
          return {
            label: t?.name,
            value: t?._id,
          };
        }),
        price: data?.price,
        plu: data?.plu,
      },
      onSubmit: async () => {
        try {
          if (values.category !== selectedCategory) {
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to change category?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willDelete) => {
              if (willDelete) {
                handleChangeCategory(values.category, values);
              }
            });
          }
          updateItem(values);
          toggle();
        } catch (error) {
          console.log(error);
          toast.error(error.message);
        }
      },
    });

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isVisible}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <h1 className="display-6">Edit Menu Item</h1>
          </CardHeader>
          <CardBody className="">
            <Row>
              <Col md="12" className="mt-2">
                <FormGroup className="text-left">
                  <label className="text-left">Name</label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="name"
                    value={values.name}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="text-left">
                  <label className="text-left">Category</label>
                  {/* <Input
                    onChange={handleChange}
                    type="text"
                    name="category"
                    value={values.category}
                  /> */}
                  <Input
                    multiple=""
                    type="select"
                    onChange={handleChange("category")}
                    value={values?.category}
                    className={`form-control ${
                      errors.category && touched.category ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Select Destination</option>
                    {categories.map((c) => {
                      return <option value={c._id}>{c.categoryName}</option>;
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" style={{ padding: 0 }}>
                <FormGroup>
                  <Col md="12">
                    <label style={{ fontSize: 15, fontWeight: "600" }}>
                      Tag
                    </label>
                  </Col>
                  <Col md="12">
                    <Select
                      onChange={(val) => setFieldValue("tag", val)}
                      isMulti
                      name="tag"
                      isSearchable={false}
                      options={tag?.map((loca) => {
                        return {
                          label: loca?.name,
                          value: loca?._id,
                        };
                      })}
                      value={values.tag}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="text-left">
                  <label className="text-left">Price</label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="price"
                    value={values.price}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="text-left">
                  <label className="text-left">PLU</label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="plu"
                    value={values.plu}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-4"
                color="primary"
                type="button"
                onClick={handleSubmit}
                style={{ width: 150 }}
              >
                Save
              </Button>

              <Button
                onClick={onClose}
                style={{ width: 150 }}
                className="mt-4"
                color="warning"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
