// reactstrap components
import {
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import DeliverectModal from "../../../components/modal/DeliverectModal";
import Integration from "../settings/tabs/Integrations";
import JKSoftIntegration from "../settings/tabs/JKSoftIntegration";
import StreamPOSIntegration from "../settings/tabs/Stream-Integrations";
import Mqtt from "../settings/tabs/Mqtt";

export default function Integrations(props) {
  const { handleChange, errors, touched, values, setFieldValue } = props;
  return (
    <>
      <ListGroup className="tal" flush>
        <ListGroupItem
          className="list-group-item-action"
          href="#collapseExample"
          id="linkToggler"
          tag="a"
        >
          <Row className="align-items-center">
            <Col className="col-auto">
              <i className="ni ni-air-baloon"></i>
            </Col>
            <div className="col ml--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0 text-sm">Deliverect</h4>
                </div>
                <div className="text-right text-muted">
                  <i className="ni ni-settings-gear-65"></i>
                </div>
              </div>
              <p className="text-sm mb-0">
                Customize deliverect and optimize customer follow up
              </p>
            </div>
          </Row>
        </ListGroupItem>
        <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
          <Card>
            <CardBody>
              <Integration
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
            </CardBody>
          </Card>
        </UncontrolledCollapse>

        <ListGroupItem
          className="list-group-item-action"
          href="#collapseExample"
          id="linkToggler10"
          tag="a"
        >
          <Row className="align-items-center">
            <Col className="col-auto">
              <i className="fa fa-print"></i>
            </Col>
            <div className="col ml--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0 text-sm">MQTT</h4>
                </div>
                <div className="text-right text-muted">
                  <i className="ni ni-settings-gear-65"></i>
                </div>
              </div>
              <p className="text-sm mb-0">Configure MQTT Print</p>
            </div>
          </Row>
        </ListGroupItem>
        <UncontrolledCollapse toggler="#linkToggler10,#buttonToggler">
          <Card>
            <CardBody>
              <Mqtt
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
            </CardBody>
          </Card>
        </UncontrolledCollapse>

        <ListGroupItem
          className="list-group-item-action"
          href="#pablo"
          id="linkToggler2"
          onClick={(e) => e.preventDefault()}
          tag="a"
        >
          <Row className="align-items-center">
            <Col className="col-auto">
              <i className="ni ni-spaceship"></i>
            </Col>
            <div className="col ml--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0 text-sm">JKSoft</h4>
                </div>
                <div className="text-right text-muted">
                  <i className="ni ni-settings-gear-65"></i>
                </div>
              </div>
              <p className="text-sm mb-0">
                Configure the JKSoft integration for your restaurant and achieve
                the best results
              </p>
            </div>
          </Row>
        </ListGroupItem>
        <UncontrolledCollapse toggler="#linkToggler2">
          <Card>
            <CardBody>
              <JKSoftIntegration
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
            </CardBody>
          </Card>
        </UncontrolledCollapse>
        <ListGroupItem
          className="list-group-item-action"
          href="#pablo"
          onClick={(e) => e.preventDefault()}
          tag="a"
          disabled
        >
          <Row className="align-items-center">
            <Col className="col-auto">
              <i className="ni ni-tie-bow"></i>
            </Col>
            <div className="col ml--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0 text-sm">Webhook</h4>
                </div>
                <div className="text-right text-muted">
                  <i className="ni ni-settings-gear-65"></i>
                </div>
              </div>
              <p className="text-sm mb-0">
                The most efficient way to seamlessly integrate your restaurant
                with the best ever reporting system
              </p>
            </div>
          </Row>
        </ListGroupItem>
        <ListGroupItem
          className="list-group-item-action"
          href="#collapseExample"
          id="linkToggler5"
          tag="a"
        >
          <Row className="align-items-center">
            <Col className="col-auto">
              <i className="ni ni-air-baloon"></i>
            </Col>
            <div className="col ml--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0 text-sm">Stream</h4>
                </div>
                <div className="text-right text-muted">
                  <i className="ni ni-settings-gear-65"></i>
                </div>
              </div>
              <p className="text-sm mb-0">
                Enable Stream POS / DSP Integration
              </p>
            </div>
          </Row>
        </ListGroupItem>
        <UncontrolledCollapse toggler="#linkToggler5,#buttonToggler">
          <Card>
            <CardBody>
              <StreamPOSIntegration
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
              />
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      </ListGroup>
      <DeliverectModal />
    </>
  );
}
