import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTableDnd from "../../../widgets/SuperTableDnd";
import TableWrapper from "../../../widgets/TableWrapper";
import CreateCategoryModal from "./components/CreateCategoryModal";
import TableRowRestaurantCategories from "./components/TableRowRestaurantCategories";

const RestaurantCategories = (props) => {
  const {
    entities,
    loading: isLoadingRest,
    find,
  } = useEntity("restaurant-categories");
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const { partner } = useAdminStore();
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const { create: updateSortOrder } = useEntity(
    "restaurant-categories/sort-order"
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Category", "Restaurants assigned", "Sort", "Actions"];

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData([...items]);
    setIsSortOrderChanged(true);
  };

  useEffect(() => {
    find({ partner: partner || authContext?.user?._id });
  }, []);

  useEffect(() => {
    if (entities?.data) {
      setData(entities?.data);
    }
  }, [entities?.data]);

  const handleSaveSortOrder = async () => {
    const IdsAndPos = data.map((d, idx) => {
      const obj = { ...d, sort: idx };
      return obj;
    });

    setData(IdsAndPos);

    try {
      updateSortOrder({ sortOrders: IdsAndPos });
      toast.success("Sort order updated");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }

    setIsSortOrderChanged(false);
  };

  return (
    <div>
      <SimpleHeader
        path="/admin/partners"
        name="Categorize Restaurants"
        parentName="Partners"
        showBtn={true}
      >
        <Button onClick={() => setOpenModal(true)} size="md" color="primary">
          Create
        </Button>
      </SimpleHeader>

      <TableWrapper>
        {isSortOrderChanged && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
            }}
            className="mr-5"
          >
            <Button
              onClick={handleSaveSortOrder}
              outline
              color="warning"
              size="sm"
            >
              Save
            </Button>
          </div>
        )}
        {!isLoadingRest ? (
          <SuperTableDnd
            sort={handleSort}
            showCheckboxes={false}
            data={data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoadingRest}
            history={props.history}
            row={TableRowRestaurantCategories}
            columns={Headers}
            isLoading={isLoadingRest}
            emptyMessage="Currently no restaurants"
            handleDragEnd={handleDragEnd}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
      <CreateCategoryModal
        openModal={openModal}
        toggle={() => setOpenModal(false)}
      />
    </div>
  );
};

export default RestaurantCategories;
