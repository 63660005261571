import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTable from "../../../widgets/SuperTable";

const GatewayRow = ({ data: t, i, history }) => {
  const { deleteEntity } = useEntity("gateway");
  const location = useLocation();

  return (
    <tr className="text-left">
      <td>{t.name}</td>
      <td>{t.locations.map((location) => location.address)}</td>
      <td>
        <i
          className="fa fa-edit mx-3"
          style={{ fontSize: 20, cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: "/resturants/payment/create-gateway",
              state: `${t?._id}`,
              search: location.search,
            })
          }
        />
        <i
          className="fa fa-trash text-danger"
          style={{ fontSize: 20, cursor: "pointer" }}
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(t?._id);
                  toast.error("Gateway deleted succesfully");
                }
              });
            } catch (error) {
              toast(error.message);
            }
          }}
        />
      </td>
    </tr>
  );
};

const Headers = ["Gateway", "Location", "Action"];

export default function PaymentGateway(props) {
  const {
    find: findGateway,
    entities: gateways,
    loading: isLoading,
  } = useEntity("gateway");
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { restaurant } = useAdminStore();

  useEffect(() => {
    findGateway({ resturant: restaurant });
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  return (
    <>
      {!isLoading ? (
        <SuperTable
          sort={handleSort}
          showCheckboxes={false}
          data={gateways?.data}
          onPageChange={handlePageChange}
          OnRowPerPageChange={handleRowsPerPageChange}
          total={gateways?.length}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          loading={isLoading}
          history={props.history}
          row={GatewayRow}
          columns={Headers}
          isLoading={isLoading}
          emptyMessage="Currently no gateways"
        />
      ) : (
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      )}
    </>
  );
}
