import { useContext } from "react";
import { AuthContext } from "../context";

export const useAdminStore = () => {
  const context = useContext(AuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  const restaurantId =
    context?.user?.role === "restaurant"
      ? context?.user?._id
      : urlParams.get("restaurantId");
  const partnerId =
    context?.user?.role === "partner"
      ? context?.user?._id
      : urlParams.get("partnerId");
  const orderType = urlParams.get("orderType"); // external internal orders
  return { restaurant: restaurantId, partner: partnerId, orderType };
};
