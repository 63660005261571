import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";
import * as Yup from "yup";
import { SelectionComponent } from "../../../../components/SelectionComponent";
import { generateTimeslots } from "../../../../utils/Timeslot";

const AddAvailabilityHours = ({ open, toggle, value, onSubmit }) => {
  const {
    resetForm,
    setFieldValue,
    values,
    handleSubmit,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      toSlots: [],
      fromSlots: [],
      from: {
        label: "",
        value: "",
      },
      to: {
        label: "",
        value: "",
      },
    },
    onSubmit: (values, { resetForm }) => {
      onSubmit({ from: values.from.value, to: values.to.value });
      resetForm();
      toggle();
    },
    validationSchema: Yup.object({
      from: Yup.object({
        label: Yup.string().required("From is required"),
        value: Yup.string().required(),
      }).required(),
      to: Yup.object({
        label: Yup.string().required("To is required"),
        value: Yup.string().required(),
      }).required(),
    }),
  });

  useEffect(() => {
    setFieldValue("fromSlots", generateTimeslots(15, "00:00", "23:59"));
  }, [open]);

  useEffect(() => {
    if (values.from.value) {
      const splittedTime = values.from.value?.split(":");
      const transformed = `${Number(splittedTime[0]) + 1}:${splittedTime[1]}`;

      setFieldValue("toSlots", generateTimeslots(15, transformed, "23:59"));
    }
  }, [values.from.value]);

  useEffect(() => {
    setFieldValue("to", {
      label: "",
      value: "",
    });
  }, [values.from.value]);

  useEffect(() => {
    setFieldValue("to", {
      label: value?.endDate,
      value: value?.endDate,
    });
    setFieldValue("from", {
      label: value?.startDate,
      value: value?.startDate,
    });
  }, [value, open]);

  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-white text-dark"
        isOpen={open}
        toggle={() => toggle()}
      >
        <div className="modal-header" style={{ display: "flex" }}>
          <h2>Add Availability Time</h2>
        </div>

        <div
          className="modal-body"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            className="mt-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="mr-3">From</div>
            <div style={{ width: 150 }}>
              <SelectionComponent
                isSearchable={false}
                multi={false}
                onBlur={handleBlur}
                value={{
                  label:
                    moment(
                      new Date(
                        0,
                        0,
                        0,
                        values?.from?.label?.split(":")[0],
                        values?.from?.label?.split(":")[1]
                      )
                    ).format("LT") === "Invalid date"
                      ? undefined
                      : moment(
                          new Date(
                            0,
                            0,
                            0,
                            values?.from?.label?.split(":")[0],
                            values?.from?.label?.split(":")[1]
                          )
                        ).format("LT"),
                  value: values.from.value || undefined,
                }}
                onSelect={(e) => {
                  setFieldValue("from", { label: e.value, value: e.value });
                }}
                options={values.fromSlots?.map?.((s) => {
                  var splittedDate = s?.split(":");
                  return {
                    label: moment(
                      new Date(0, 0, 0, splittedDate[0], splittedDate[1])
                    ).format("LT"),
                    value: s,
                  };
                })}
              />
              {errors?.from?.label && touched?.from?.label && (
                <small className="text-red">{errors.from.label}</small>
              )}
            </div>
          </div>
          <div
            className="mt-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="mr-3">To</div>
            <div style={{ width: 150 }}>
              <SelectionComponent
                multi={false}
                isSearchable={false}
                onBlur={handleBlur}
                value={{
                  label:
                    moment(
                      new Date(
                        0,
                        0,
                        0,
                        values?.to?.label?.split(":")[0],
                        values?.to?.label?.split(":")[1]
                      )
                    ).format("LT") === "Invalid date"
                      ? undefined
                      : moment(
                          new Date(
                            0,
                            0,
                            0,
                            values?.to?.label?.split(":")[0],
                            values?.to?.label?.split(":")[1]
                          )
                        ).format("LT"),
                  value: values.to.value,
                }}
                onSelect={(e) => {
                  setFieldValue("to", { label: e.value, value: e.value });
                }}
                options={values.toSlots?.map((s) => {
                  var splittedDate = s?.split(":");

                  //convert time into date
                  // const date = new Date(
                  //   0,
                  //   0,
                  //   0,
                  //   s.split(":")[0],
                  //   s.split(":")[1]
                  // );

                  // const endDate = new Date(
                  //   0,
                  //   0,
                  //   0,
                  //   values?.from?.label?.split(":")[0],
                  //   values?.from?.label?.split(":")[1]
                  // );

                  //compare date if in past

                  return {
                    label: moment(
                      new Date(0, 0, 0, splittedDate[0], splittedDate[1])
                    ).format("LT"),
                    value: s,
                  };
                })}
              />
              {errors?.to?.label && touched?.to?.label && (
                <small className="text-red">{errors.to.label}</small>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button
            onClick={() => {
              resetForm();
              toggle();
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddAvailabilityHours;
