import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal, Spinner } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
import { validationSchemaAddTaxRate } from "../../../../utils/schemas/AddTaxRate";
import ModalHeader from "../../../ordering/components/modals/ModalHeader";
import { ErrorLable } from "../AddLocation";

const TaxInputField = ({ setValue, label, type, value }) => {
  return (
    <div className="mt-3 mb-1">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "right",
          }}
        >
          {label}
        </div>
        <div
          className="mt-1"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          {type !== "select" ? (
            <Input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          ) : (
            <Input
              value={value}
              type="select"
              onChange={(e) => setValue(e.target.value)}
            >
              <option value="">Select</option>
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed Amount</option>
            </Input>
          )}
        </div>
      </div>
    </div>
  );
};

export default function AddTaxRateModal({ isVisible, toggle, id, data }) {
  const { create: addTaxRate, updateEntity } = useEntity("tax-rate");
  const { findOne, entity } = useEntity("tax-rate");

  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();

  useEffect(() => {
    if (id) {
      findOne(id);
    }
  }, [id]);

  const {
    handleSubmit,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      rate: 0,
      type: "",
      fixedAmount: 0,
    },
    validationSchema: validationSchemaAddTaxRate,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!id) {
          await addTaxRate({
            resturant: authRest || restaurant,
            name: values?.name,
            rate: values?.rate,
            type: values?.type,
            fixedAmount: values?.type === "fixed" && values?.fixedAmount,
          });

          toast.success("Tax rate added successfully");
          resetForm();
        } else {
          await updateEntity(id, {
            name: values?.name,
            rate: values?.rate,
            type: values?.type,
            fixedAmount: values?.type === "fixed" && values?.fixedAmount,
          });
          toast.success("Tax rate updated successfully");
          resetForm();
        }
        toggle();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        name: entity?.data?.name,
        rate: entity?.data?.rate,
        type: entity?.data?.type,
        fixedAmount: entity?.data?.fixedAmount,
      });
    }
  }, [entity]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isVisible}
      toggle={() => {
        toggle();
        resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
        }}
        title={entity ? "Update Tax rate" : "Add Tax rate"}
      />
      <div className="px-3 py-2">
        <TaxInputField
          value={values?.name}
          setValue={(val) => setFieldValue("name", val)}
          label="Tax Name"
          errors={errors}
          touched={touched}
        />
        {errors.name && touched.name && <ErrorLable message={errors.name} />}
        <TaxInputField
          type="select"
          value={values?.type}
          setValue={(val) => setFieldValue("type", val)}
          label="Tax Type"
          errors={errors}
          touched={touched}
        />
        {errors.type && touched.type && <ErrorLable message={errors.type} />}
        {values?.type === "percentage" ? (
          <>
            <TaxInputField
              value={values?.rate}
              setValue={(val) => setFieldValue("rate", val)}
              label="Tax Rate"
              errors={errors}
              touched={touched}
            />
            {errors.rate && touched.rate && (
              <ErrorLable message={errors.rate} />
            )}
          </>
        ) : (
          <>
            <TaxInputField
              type="text"
              setValue={(val) => setFieldValue("fixedAmount", val)}
              label="Fixed Amount"
              value={values?.fixedAmount}
              errors={errors}
              touched={touched}
            />
            {errors.fixedAmount && touched.fixedAmount && (
              <ErrorLable message={errors.fixedAmount} />
            )}
          </>
        )}{" "}
      </div>
      <div className="modal-footer">
        <div>
          <Button
            disabled={isSubmitting}
            color="primary"
            onClick={async () => {
              handleSubmit();
            }}
            type="button"
          >
            {isSubmitting ? (
              <Spinner size="sm" color="white" />
            ) : id ? (
              "Save Changes"
            ) : (
              "Add"
            )}
          </Button>

          <Button
            onClick={() => {
              resetForm();
              toggle();
            }}
            color="neutral"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
