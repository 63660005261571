import React, { useEffect, useMemo } from "react";
import Loader from "../components/Loader";

export default function OAuthLanding(props) {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );
  useEffect(() => {
    const url = localStorage.getItem("oauth_return_url");

    const query = [...searchParams.entries()]
      .map((kv) => {
        return `${kv[0]}=${kv[1]}`;
      })
      .join("&");

    window.location.replace(`${url}?${query}`);
  }, [searchParams]);
  return <Loader />;
}
