export default function getCirclePath(center, radius, points) {
  var a = [],
    p = 360 / points,
    d = 0;
  for (var i = 0; i < points; ++i, d += p) {
    a.push(
      window.google.maps.geometry.spherical.computeOffset(center, radius, d)
    );
  }
  return a;
}
