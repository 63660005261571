import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import swal from "sweetalert";

import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import logger from "../../../utils/logger";
import useItemsFilter from "../../../store/items-filter";
import useCategoryFilter from "../../../store/category-filter";

export const TableRowShops = ({ history, data }) => {
  const { partner } = useAdminStore();
  const location = useLocation();
  const { deleteEntity } = useEntity("restaurant");
  const { updateEntity, loading } = useEntity("restaurant");
  const { setCategory, setLocation, setTags, setSource } = useItemsFilter();
  const {
    setCategory: setCategory1,
    setLocation: setLocation1,
    setTags: setTags1,
    setSource: setSource1,
  } = useCategoryFilter();

  const { findOne, entity } = useEntity("partner");

  useEffect(() => {
    findOne(partner);
  }, [partner]);

  useEffect(() => {
    document.title = entity?.data?.partner?.name || "Loading..";
  }, [entity]);

  return (
    <tr className="text-left">
      <th scope="row">
        <span
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            let query = `restaurantId=${data.userId?._id}`;
            if (location.search.split("=")[1]) {
              query = query + `&partnerId=${location.search.split("=")[1]}`;
            }
            setCategory("");
            setTags(null);
            setLocation("all");
            setSource("local");
            setCategory1("");
            setTags1(null);
            setLocation1("all");
            setSource1("local");
            history.push({
              pathname: "/resturants",
              search: query,
            });
          }}
          className="name mb-0 text-sm text-center"
        >
          {data?.name}
        </span>
      </th>
      <td>{data?.username}</td>

      <td>{data?.email}</td>
      <td>{data?.phone}</td>
      {data?.stream?.enableStreamPOS && !data?.stream?.enableStreamDSP && (
        <td>{data?.stream?.enableStreamPOS && "POS"}</td>
      )}
      {data?.stream?.enableStreamDSP && !data?.stream?.enableStreamPOS && (
        <td>{data?.stream?.enableStreamDSP && "DSP"}</td>
      )}

      {data?.stream?.enableStreamDSP && data?.stream?.enableStreamPOS && (
        <td>{"POS & DSP"}</td>
      )}
      {!data?.stream?.enableStreamDSP && !data?.stream?.enableStreamPOS && (
        <td>{"NA"}</td>
      )}
      <td>
        {loading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.userId?.status === "active"}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?.userId?._id, {
                    status:
                      data?.userId?.status === "active" ? "inactive" : "active",
                  });
                  toast("Restaurant Updated Successfully");
                } catch (error) {
                  logger.push({ error, info: "TableRowRestaurants" });
                  toast(error.message);
                }
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Off"
              data-label-on="On"
            />
          </label>
        )}
      </td>

      <td>
        <UncontrolledDropdown group>
          <DropdownToggle style={{ backgroundColor: "#fff", padding: "0px" }}>
            <i
              className="fa fa-ellipsis-v text-muted"
              style={{ fontSize: 18 }}
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                let query = `restaurantId=${data.userId?._id}`;
                if (location.search.split("=")[1]) {
                  query = query + `&partnerId=${location.search.split("=")[1]}`;
                }
                history.push({
                  pathname: "/resturants",
                  search: query,
                });
              }}
            >
              View
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                history.push({
                  pathname: `/partners/resturants/add-resturant`,
                  search: location.search,
                  state: {
                    restaurantId: data?.userId?._id,
                    id: data?.partner,
                  },
                })
              }
            >
              Edit
            </DropdownItem>
            <DropdownItem
              className="text-danger"
              onClick={async () => {
                try {
                  swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willDelete) => {
                    if (willDelete) {
                      await deleteEntity(data?.userId?._id);
                      toast.error("Restaurant Deleted Succesfully");
                    }
                  });
                } catch (error) {
                  logger.push({ error, info: "TableRowRestaurantsDelete" });
                  toast(error.message);
                }
              }}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};
