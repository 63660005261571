import { Card, CardBody, Modal } from "reactstrap";
import ModalHeader from "./ModalHeader";

export default function GenericModal({ isOpen, toggle, data, title }) {
  return (
    <Modal className="modal-dialog-top" isOpen={isOpen} toggle={toggle}>
      <ModalHeader title={title} toggle={toggle} />

      <Card
        className="card-stat"
        style={{ background: "#f6f6f6", padding: 20, minHeight: 100 }}
      >
        <CardBody style={{ margin: 0, padding: 0, background: "none" }}>
          <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </CardBody>
      </Card>
    </Modal>
  );
}
