/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import classnames from "classnames";

// reactstrap components
import { Button, Nav, NavItem, NavLink, Spinner } from "reactstrap";
// core components
import SimpleHeader from "../../components/Header/SimpleHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useAdminStore } from "../../store/admin";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import { TableRowShops } from "./components/TableRowShops";

function Resturants(props) {
  const { entities, loading: isLoadingRest, find } = useEntity("restaurant");
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { partner } = useAdminStore();
  const [tabs, setTabs] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    let filter = {
      partner: partner,
      page: page,
      limit: rowsPerPage,
      sort: sorting,
      status: tabs === 1 ? "active" : "inactive",
      q: searchQuery,
    };

    if (authContext?.user?.role === "partner") {
      filter["partner"] = authContext?.user?._id;
    }

    find({ ...filter });
  }, [page, sorting, rowsPerPage, tabs, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = [
    "Shop",
    "shop username",
    "Email",
    "Phone",
    "Stream Status",
    "Status",
    "Actions",
  ];

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <>
      <SimpleHeader
        path="/admin/partners"
        name="Restaurants"
        parentName="Partners"
        showBtn={true}
        showSearch={true}
        getSearchQuery={(val) => setSearchQuery(val)}
        placeholder="Search"
      >
        <Button
          onClick={() => {
            props.history.push({
              pathname: `${location.pathname}/add-restaurant`,
              state: {
                mode: "add",
                id: props.location.state || authContext?.user?._id,
              },
              search: props.location.search,
            });
          }}
          className="btn-primary"
          color="primary"
          size="md"
        >
          Add new
        </Button>
      </SimpleHeader>

      <TableWrapper customStyles="px-4">
        <Nav
          className="nav-fill flex-column mt-3 mb-4 flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Active
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Inactive
            </NavLink>
          </NavItem>
        </Nav>
        {!isLoadingRest ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoadingRest}
            history={props.history}
            row={TableRowShops}
            columns={Headers}
            isLoading={isLoadingRest}
            emptyMessage="Currently no restaurants"
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}

export default Resturants;
