import { useContext, useEffect } from "react";
import { Progress } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";

const LoyaltyModalComponent = () => {
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();

  const { find: findLoyalty, entities: loyaltyPoints } = useEntity(
    "eat/ordering/loyalty-points"
  );

  useEffect(() => {
    if (authContext?.user) {
      findLoyalty({
        customer: authContext?.user?.customer?._id,
        restaurant: restaurant?.userId?._id,
      });
    }
  }, [authContext]);

  return (
    <div
      className="p-3"
      style={{
        boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
        borderRadius: "6px",
      }}
    >
      <div className="d-flex justify-content-between">
        <div>
          <h5>Order History</h5>
        </div>
        <div>
          <h5>$1 = {restaurant?.loyalty?.pointsPerDollar} PTS</h5>
        </div>
      </div>
      <Progress style={{ height: 30, marginTop: 10 }} multi>
        <Progress
          style={{ backgroundColor: "#5F9B0A" }}
          bar
          value={loyaltyPoints?.data?.points}
          max={2.5 * loyaltyPoints?.data?.points}
        >
          {Math.ceil(loyaltyPoints?.data?.points) || 0} PTS
        </Progress>
      </Progress>
      <div className="d-flex">
        <div>
          {" "}
          <h3>{Math.ceil(loyaltyPoints?.data?.points) || 0} </h3>
        </div>
        <div className="mx-1 mt-1">
          <h5>total points</h5>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyModalComponent;
