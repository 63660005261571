import React from "react";

const TableRowRestCouponReport = ({ data, history }) => {
  return (
    <tr className="text-left">
      <td>{data?.couponName}</td>
      <td>{data?.couponCode}</td>
      <td>{data?.totalOrders}</td>
      <td>${data?.totalDiscount}</td>
    </tr>
  );
};

export default TableRowRestCouponReport;
