import React, { useState } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";

export default function EditHolidays(props) {
  const [timeSlotOption, setTimeSlotOption] = useState("Closed All Day");
  return (
    <>
      <TimelineHeader name="Holidays" parentName="Settings"></TimelineHeader>
      <Container className="mt--4" fluid>
        <Row>
          <div className="col">
            <Card>
              <br></br>
              <Form className="tal">
                <Row className="mlr-15">
                  <Col md="3" sm="6">
                    <FormGroup>
                      <Label className="form-control-label">Title</Label>
                      <Input type="text"></Input>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="6">
                    <FormGroup>
                      <Label className="form-control-label">Date</Label>
                      <Input type="date"></Input>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="6">
                    <FormGroup>
                      <Label className="form-control-label">Time</Label>
                      <Input
                        type="select"
                        onChange={(event) =>
                          setTimeSlotOption(event.target.value)
                        }
                      >
                        <option value="Closed All Day">Closed All Day</option>
                        <option value="Set Custom Hours">
                          Set Custom Hours
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  {timeSlotOption === "Set Custom Hours" ? (
                    <Col md="3" sm="6">
                      <FormGroup>
                        <Label className="form-control-label">Time From</Label>
                        <Input type="text"></Input>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {timeSlotOption === "Set Custom Hours" ? (
                    <Col md="3" sm="6">
                      <FormGroup>
                        <Label className="form-control-label">Time To</Label>
                        <Input type="text"></Input>
                      </FormGroup>
                    </Col>
                  ) : null}
                </Row>
                <Row className="mlr-15">
                  <Col md="3" sm="6">
                    <FormGroup>
                      <Button color="primary">Submit</Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Card>
              <Table responsive className="align-items-center">
                <thead className="thead-light">
                  <tr>
                    <th>Title</th>
                    <th>Date</th>
                    <th>From Time</th>
                    <th>To Time</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2].map((t) => {
                    return (
                      <tr>
                        <td>{t === 1 ? "Christmas" : "New Year"}</td>
                        <td>
                          {t === 1 ? "25 December 2021" : "01 January 2022"}
                        </td>
                        <td>- -</td>
                        <td>- -</td>

                        <td>
                          <i style={{ fontSize: 20 }} className="fa fa-trash" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
