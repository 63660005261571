import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import WalkUpOrderingQR from "../../components/WalkUpOrderingQR"

export default function WalkupOrdering({
  data,
  values,
  handleChange,
  handleWalkUpOrderingPaymentMethods,
  locationId,
  errors,
}) {
  return (
    <>
      <FormGroup className="row" style={{ alignItems: "center" }}>
        <Label
          className="form-control-label text-left"
          htmlFor="example-text-input"
          md="4"
        >
          Enable Walk-Up
        </Label>
        <Col md="8">
          <label className="custom-toggle">
            <input
              checked={values?.enableWalkupOrdering}
              value={values?.enableWalkupOrdering}
              onChange={handleChange("enableWalkupOrdering")}
              type="checkbox"
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </Col>
      </FormGroup>

      {data?.ordering?.enableWalkupOrdering && (
        <>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Payment
            </Label>
            <Row
              style={{
                marginLeft: 19,
              }}
            >
              <Col md="12">
                <Label check>
                  <Input
                    type="checkbox"
                    data-value="pay-there"
                    checked={
                      !!values.walkupOrderingPaymentMethods.find(
                        (v) => v === "pay-there"
                      )
                    }
                    onChange={handleWalkUpOrderingPaymentMethods}
                  />{" "}
                  Pay There
                </Label>
              </Col>
              <Col md="12">
                <Label check>
                  <Input
                    type="checkbox"
                    data-value="pay-online"
                    checked={
                      !!values.walkupOrderingPaymentMethods.find(
                        (v) => v === "pay-online"
                      )
                    }
                    onChange={handleWalkUpOrderingPaymentMethods}
                  />{" "}
                  Pay Online
                </Label>
              </Col>
              <span
                style={{
                  color: "#fc3903",
                  marginLeft: -6,
                }}
              >
                {errors.walkupOrderingPaymentMethods
                  ? errors.walkupOrderingPaymentMethods
                  : ""}
              </span>
            </Row>
          </FormGroup>
          <FormGroup className="row" style={{ alignItems: "center" }}>
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Disable Normal Ordering
            </Label>
            <Col md="8">
              <label className="custom-toggle">
                <input
                  checked={values?.disableNormalOrdering}
                  value={values?.disableNormalOrdering}
                  onChange={handleChange("disableNormalOrdering")}
                  type="checkbox"
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </FormGroup>
          <WalkUpOrderingQR locationId={locationId} />
        </>
      )}
    </>
  )
}
