import { useState } from "react";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";
import Select2 from "react-select2-wrapper";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import SecureInput from "../../../components/Inputs/SecureInput";
import TieredInput from "../../../components/Restaurant/tiered-inputt";
import ErrorLable from "../../../utils/ErrorLable";
import timezones from "../../../utils/timezones";
import Compressor from "compressorjs";
import apiCaller from "../../../api/apiCaller";
import { useQueryClient } from "react-query";

export default function General({
  errors,
  touched,
  header,
  setFieldValue,
  data,
  handleChange,
  values,
}) {
  const [headerLogo, setHeaderLogo] = useState("");
  const queryClient = useQueryClient();
  const [seed, setSeed] = useState(false);

  const handleDeleteImage = async (uri) => {
    await apiCaller("/upload/delete-image", {
      method: "DELETE",
      body: {
        imageUrl: uri, //Replace with image url
        id: data?.partner?._id, //Replace With Partner Restaurant and Item Id
        entityName: "partner", //Replace it with relevant entity name "partner,restaurant or item"
      },
    });
    queryClient.invalidateQueries("find-one-partner");
    setSeed(!seed);
  };

  return (
    <>
      <Row>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              Partner URL
            </Col>
            <Col
              md="8"
              className={isMobile && "my-2"}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-globe" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={values?.partnerUrl}
                  onChange={handleChange("partnerUrl")}
                  className={`form-control ${
                    errors.partnerUrl && touched.partnerUrl ? "is-invalid" : ""
                  }`}
                />
              </InputGroup>
              {errors?.partnerUrl && touched?.partnerUrl && (
                <ErrorLable message={errors?.partnerUrl} />
              )}
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Owner
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className="fa fa-user"
                      style={{ fontSize: 17 }}
                      aria-hidden="true"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={values?.name}
                  onChange={handleChange("name")}
                  className={`form-control ${
                    errors.name && touched.name ? "is-invalid" : ""
                  }`}
                />
              </InputGroup>
              {errors?.name && touched?.name && (
                <ErrorLable message={errors?.name} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Email
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  value={values?.email}
                  onChange={handleChange("email")}
                  className={`form-control ${
                    errors.email && touched.email ? "is-invalid" : ""
                  }`}
                />
              </InputGroup>
              {errors?.email && touched?.email && (
                <ErrorLable message={errors?.email} />
              )}
            </Col>
          </Row>
        </Col>
        <Col md="6" className="my-2">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              Password
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-lock"}
                value={values?.password}
                onChange={handleChange("password")}
                className={`form-control ${
                  errors.password && touched.password ? "is-invalid" : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Phone
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-phone-square" aria-hidden="true"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  value={values?.phone}
                  onChange={handleChange("phone")}
                  className={`form-control ${
                    errors.phone && touched.phone ? "is-invalid" : ""
                  }`}
                />
              </InputGroup>
              {errors?.email && touched?.email && (
                <ErrorLable message={errors?.email} />
              )}
            </Col>
          </Row>
        </Col>{" "}
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Timezone
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Select2
                isSearchable={false}
                className="form-control"
                options={{
                  placeholder: values?.timezone || "Select timezone",
                }}
                value={values.timezone || ""}
                data={timezones}
                onSelect={(e) => {
                  setFieldValue("timezone", e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                paddingTop: 8,
              }}
            >
              Address
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Input
                type="textarea"
                rows="3"
                onChange={handleChange("address")}
                value={values.address}
                placeholder="Address"
                className={`form-control ${
                  errors.addresss && touched.address ? "is-invalid" : ""
                }`}
              />
              {errors?.address && touched?.address && (
                <ErrorLable message={errors?.address} />
              )}
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Partner Mode
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Input
                onChange={handleChange("partnerMode")}
                value={values?.partnerMode}
                type="select"
              >
                <option value="">Select</option>
                <option value="live">Live</option>
                <option value="test">Test</option>
              </Input>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                paddingTop: 8,
              }}
            >
              Notification Email
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Input
                type="text"
                onChange={handleChange("notificationEmail")}
                value={values.notificationEmail}
                placeholder="Notification Email"
                className={`form-control ${
                  errors.notificationEmail && touched.notificationEmail
                    ? "is-invalid"
                    : ""
                }`}
              />
              {errors?.notificationEmail && touched?.notificationEmail && (
                <ErrorLable message={errors?.notificationEmail} />
              )}
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row style={{ textAlign: "left" }}>
            <FormGroup
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Label md={8} className="text-left" htmlFor="example-text-input">
                Enable Foodtronix
              </Label>
              <InputGroup className="mx-4">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableFoodTronix}
                    value={values?.enableFoodTronix}
                    onChange={handleChange("enableFoodTronix")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </InputGroup>
            </FormGroup>
          </Row>
          {/* <Row style={{ textAlign: "left" }}>
            <FormGroup
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Label md={8} className="text-left" htmlFor="example-text-input">
                Dynamic Fee Menuocity
              </Label>
              <InputGroup className="mx-4">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableMenuocityDynamicFee}
                    value={values?.enableMenuocityDynamicFee}
                    onChange={handleChange("enableMenuocityDynamicFee")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </InputGroup>
            </FormGroup>
          </Row> */}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <Row style={{ textAlign: "left" }}>
            <FormGroup
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Label md={8} className="text-right" htmlFor="example-text-input">
                Dynamic Fee Menuocity
              </Label>
              <InputGroup className="mx-4">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableMenuocityDynamicFee}
                    value={values?.enableMenuocityDynamicFee}
                    onChange={handleChange("enableMenuocityDynamicFee")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </InputGroup>
            </FormGroup>
          </Row>
        </Col>
        {values?.enableMenuocityDynamicFee && (
          <Col md="6">
            <Row>
              <Col
                md="3"
                style={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-end",
                  paddingTop: 8,
                }}
              >
                Dynamic Fee
              </Col>
              <Col md="8" className={isMobile && "my-2"}>
                <Input
                  type="number"
                  onChange={handleChange("dynamicFeeMenuocity")}
                  value={values.dynamicFeeMenuocity}
                  placeholder="$1.0"
                  className={`form-control ${
                    errors.dynamicFeeMenuocity && touched.dynamicFeeMenuocity
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {errors?.dynamicFeeMenuocity &&
                  touched?.dynamicFeeMenuocity && (
                    <ErrorLable message={errors?.dynamicFeeMenuocity} />
                  )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Order Fee
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Input
                onChange={handleChange("orderFee")}
                value={values?.orderFee}
                type="select"
              >
                <option value="">Select</option>
                <option value="Fixed">Fixed</option>
                <option value="Tiered">Tiered</option>
              </Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col sm="1"></Col>
        <Col md="6" className="mt-3 mx-5">
          {values.orderFee === "Tiered" && (
            <TieredInput
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              values={values}
            />
          )}
        </Col>
      </Row>
      {values.orderFee === "Fixed" && (
        <Row className="mt-2">
          <Col md="6">
            <Row>
              <Col
                md="3"
                style={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-end",
                  alignItems: "center",
                }}
              >
                Fixed Value
              </Col>
              <Col md="8" className={isMobile && "my-2"}>
                <Input
                  onChange={handleChange("fixedValue")}
                  value={values?.fixedValue}
                  type="number"
                />
                {errors.fixedValue && touched.fixedValue && (
                  <ErrorLable message={errors.fixedValue} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row className="mt-2">
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
              }}
            >
              Header Logo
            </Col>
            <Col md={!headerLogo ? "6" : "9"} className={isMobile && "my-2"}>
              <ImageUploader
                withIcon={true}
                buttonText="Choose images"
                fileContainerStyle={{ elevation: 0, shadow: 0 }}
                singleImage
                onChange={(f) => {
                  const image = f[0];
                  if (image) {
                    new Compressor(image, {
                      quality: 0.6,
                      retainExif: false,
                      success: (compressedResult) => {
                        setHeaderLogo(compressedResult);
                        header(compressedResult);
                      },
                    });
                  }
                }}
                imgExtension={[".jpg", ".gif", ".png", ".gif", "webp", "jpeg"]}
                maxFileSize={10002880}
                withPreview
                defaultImage={`${data?.partner?.headerLogo}`}
              />
            </Col>
            {!headerLogo && data?.partner?.headerLogo && (
              <Col
                md="3"
                className="row"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <img
                  style={{ height: 100, width: 100 }}
                  alt="logo"
                  src={`${data?.partner?.headerLogo}`}
                />
                <span
                  onClick={() => {
                    handleDeleteImage(data?.partner?.headerLogo);
                  }}
                  style={{
                    position: "absolute",
                    top: 20,
                    right: 10,
                    zIndex: 9999,
                    color: "red",
                    cursor: "pointer",
                    fontSize: 20,
                  }}
                  className="fa fa-trash"
                ></span>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {data?._id === "638a16934c13dfcd67deb3f3" && (
        <Col md="6">
          <Row style={{ textAlign: "left" }}>
            <FormGroup className="d-flex mt-3 mx-3">
              <Label
                md={8}
                className="form-control-label text-left p-0 mb-3 mx-1"
                htmlFor="example-text-input"
              >
                Enable Cut Off
              </Label>
              <InputGroup className="mx-4">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.cutOff}
                    value={values?.cutOff}
                    onChange={(e) => {
                      setFieldValue("url", "");
                      handleChange("cutOff")(e);
                    }}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </InputGroup>
            </FormGroup>
          </Row>
        </Col>
      )}
      <Row className="mt-2">
        <Col md="6" className="mt-3">
          <Row style={{ textAlign: "left" }}>
            <FormGroup
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <Label md={8} className="text-left" htmlFor="example-text-input">
                Forward To New Arch.
              </Label>
              <InputGroup className="mx-4">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableNewArch}
                    value={values?.enableNewArch}
                    onChange={handleChange("enableNewArch")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </InputGroup>
            </FormGroup>
          </Row>
        </Col>
      </Row>
    </>
  );
}
