/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
// react library for routing
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Logo from "../assets/LogoV2.svg";
import { AuthContext } from "../context";
import AddPartner from "../screens/partners/AddPartner";
import AddRestaurant from "../screens/partners/AddRestaurant";
import EditPartner from "../screens/partners/EditPartner";
import Resturants from "../screens/partners/Resturants";
import CreateCustomers from "../screens/resturants/customers/CreateCustomers";
import AddCoupon from "../screens/resturants/marketting/AddCoupon";
import EditCoupon from "../screens/resturants/marketting/EditCoupon";
import AddCategory from "../screens/resturants/menu/AddCategory";
import AddEditGroup from "../screens/resturants/menu/AddEditGroup";
import AddEditModifiers from "../screens/resturants/menu/AddEditModifiers";
import AddEditModifiersGroup from "../screens/resturants/menu/AddEditModifiersGroup";
import AddItem from "../screens/resturants/menu/AddItem";
import EditGroup from "../screens/resturants/menu/tabs/EditGroup";
import ViewOrder from "../screens/resturants/orders/ViewOrder";
import AddGateway from "../screens/resturants/settings/AddGateway";
import AddLocation from "../screens/resturants/settings/AddLocation";
import AddTaxRate from "../screens/resturants/settings/AddTaxRate";
import EditHolidays from "../screens/resturants/settings/EditHolidays";
import { DBKeys } from "../utils/Keys";
// core components
import { useEntity } from "../hooks/useEntity";
import EditCustomer from "../screens/resturants/customers/components/EditCustomer";
import AddMoreInfo from "../screens/resturants/marketting/AddMoreInfo";
import { useAdminStore } from "../store/admin";
import AdminNavbar from "./Navbar/AdminNavbar";
import PageNotFound from "./PageNotFound";
import Sidebar from "./Sidebar/Index";
import routesAdmin from "./Sidebar/routesAdmin";
import routesPartner from "./Sidebar/routesPartner";
import routesResturant, {
  mobileRestaurantRoutes,
} from "./Sidebar/routesResturant";
import AddPushNotification from "../screens/partners/mobile-app/AddPushNotification";
import AddReEngage from "../screens/resturants/marketting/AddReEngage";
import AddPartnerCoupon from "../screens/partners/marketing/coupons/AddPartnerCoupon";
import EnquiryOrderDetails from "../screens/resturants/orders/tabs/EnquiryOrderDetails";
import ViewEnquiryOrder from "../screens/resturants/orders/ViewEnquiryOrder";
import MenuManagement from "../screens/resturants/menu/MenuManagement";

function CommonLayout(props) {
  let history = useHistory();
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const mainContentRef = React.useRef(null);
  const [sidebarLogo, setSidebarLogo] = useState(Logo);
  const user = authContext?.user;
  let routes;
  const { findOne, entity } = useEntity("partner");
  const { findOne: findRestaurant, entity: restaurantDoc } =
    useEntity("restaurant");
  const { partner, restaurant } = useAdminStore();
  const [isMobile, setIsMobile] = useState(false);
  const [mobileRoutes, setMobileRoutes] = useState(false);

  useEffect(() => {
    if (partner) {
      findOne(partner);
    }
  }, [partner]);

  useEffect(() => {
    if (restaurant) {
      findRestaurant(restaurant);
    }
  }, [restaurant]);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setIsMobile(true);
    }
  });

  useEffect(() => {
    if (window.innerWidth <= 700) {
      setMobileRoutes(true);
    }
  });

  if (location.pathname.indexOf("/partners") == 0) {
    if (user?.role === "restaurant") {
      routes = mobileRoutes ? mobileRestaurantRoutes : routesResturant;
    } else {
      routes = routesPartner;
    }
  } else if (location.pathname.indexOf("/admin") == 0) {
    if (user?.role === "partner") {
      routes = routesPartner;
    } else if (user?.role === "restaurant") {
      routes = mobileRoutes ? mobileRestaurantRoutes : routesResturant;
    } else {
      routes = routesAdmin;
    }
  } else {
    if (user?.role)
      routes = mobileRoutes ? mobileRestaurantRoutes : routesResturant;
  }

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return (
        <Route
          exact
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes?.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned") && !isMobile) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-pinned");
    } else if (
      document.body.classList.contains("g-sidenav-pinned") &&
      isMobile
    ) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  useEffect(() => {
    if (!location.pathname.split("/").includes("admin")) {
      setSidebarLogo(
        entity?.data?.partner?.headerLogo ||
          restaurantDoc?.data?.restaurant?.logo
      );
    }
  }, [entity, location, restaurantDoc]);

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          imgSrc: !location.pathname.split("/").includes("admin")
            ? sidebarLogo
            : Logo,
          imgAlt: "...",
        }}
      />
      <div
        className="main-content"
        style={{ minHeight: "100vh" }}
        ref={mainContentRef}
      >
        <AdminNavbar
          history={history}
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          user={JSON.parse(localStorage.getItem(DBKeys.USER))}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Route path="/admin/edit-partner" component={EditPartner} />
          <Route
            exact
            path="/admin/partners/resturants"
            component={Resturants}
          />
          <Route
            path="/admin/partners/resturants/add-restaurant"
            component={AddRestaurant}
          />
          <Route
            path="/partners/resturants/add-restaurant"
            component={AddRestaurant}
          />

          <Route
            path="/partners/mobile-app/add-notification"
            component={AddPushNotification}
          />

          <Route
            path="/partners/marketing/add-coupon"
            component={AddPartnerCoupon}
          />

          <Route path="/resturants/add-location" component={AddLocation} />

          <Route path="/resturants/add-item" component={AddItem} />
          <Route path="/resturants/add-modifier" component={AddEditModifiers} />
          <Route
            path="/resturants/add-modifier-group"
            component={AddEditModifiersGroup}
          />

          <Route path="/admin/add-partner" component={AddPartner} />
          <Route
            path="/partners/resturants/add-resturant"
            component={AddRestaurant}
          />

          <Route path="/resturants/add-category" component={AddCategory} />
          <Route
            path="/resturants/add-group-category"
            component={AddEditGroup}
          />
          <Route path="/resturants/edit-group-category" component={EditGroup} />
          <Route path="/resturants/order/view" component={ViewOrder} />
          <Route
            path="/resturants/enquiry-order/view"
            component={ViewEnquiryOrder}
          />
          <Route
            path="/resturants/customer/create"
            component={CreateCustomers}
          />
          <Route path="/resturants/customer/edit" component={EditCustomer} />
          <Route
            path="/resturants/marketing/add-coupon"
            component={AddCoupon}
          />
          <Route
            path="/resturants/marketing/add-re-engage"
            component={AddReEngage}
          />

          <Route
            path="/resturants/marketing/edit-coupon"
            component={EditCoupon}
          />
          <Route path="/resturants/add-tax" component={AddTaxRate} />
          <Route
            path="/resturants/payment/create-gateway"
            component={AddGateway}
          />
          <Route
            path="/resturants/menu-management"
            component={MenuManagement}
          />
          <Route
            path="/resturants/holidays/edit-holidays"
            component={EditHolidays}
          />
          <Route
            path="/resturants/more-info/add-more-info"
            component={AddMoreInfo}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
      {!sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default CommonLayout;
