/* eslint-disable jsx-a11y/alt-text */
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";
import { Badge, UncontrolledTooltip } from "reactstrap";
import Spicy from "../assets/icons/1-pepper.png";
import Spicy2 from "../assets/icons/2-pepper.png";
import Spicy3 from "../assets/icons/3-peppers.png";
import Gluten from "../assets/icons/gluten_free.svg";
import Veg from "../assets/icons/veg.svg";
import Vegan from "../assets/icons/vegan.png";
import useScreenSize from "../hooks/useScreenSize";
import { useOrderingRestaurant } from "../store/ordering/useOrderingRestaurant";
import { useTheme } from "../store/theme";
import { truncateString } from "../utils/truncate";

export const ItemOptions = ({ data, size, modal }) => {
  if (!data) {
    return;
  }
  const styles = {
    borderRadius: "100%",
    height: size === "s" ? 27 : 32,
    width: size === "s" ? 27 : 32,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EBEBEB",
  };

  const levelSpicy =
    data?.spicyLevel === "spicy"
      ? [1]
      : data?.spicyLevel === "very_spicy"
      ? [1, 2]
      : data?.spicyLevel === "extra_spicy"
      ? [1, 2, 3]
      : [];

  return (
    <div className="item_options_container mx-2 mb-2">
      {data?.glutenFree && (
        <>
          <UncontrolledTooltip
            delay={0}
            placement="top"
            target={`tooltip611234742gluten${modal}`}
          >
            Gluten Free{" "}
          </UncontrolledTooltip>
          <div
            data-placement="top"
            id={`tooltip611234742gluten${modal}`}
            className="px-2 mr-2"
            style={styles}
          >
            <img alt="gluten" src={Gluten} />
          </div>
        </>
      )}
      {data?.vegetarian && (
        <>
          <UncontrolledTooltip
            delay={0}
            placement="top"
            target={`tooltip611234742veg${modal}`}
          >
            Vegetarian
          </UncontrolledTooltip>
          <div
            data-placement="top"
            id={`tooltip611234742veg${modal}`}
            className="px-2 mr-2"
            style={styles}
          >
            <img
              alt="veg"
              style={{
                height: 12,
                width: 20,
              }}
              src={Veg}
            />
          </div>
        </>
      )}
      {data?.vegan && (
        <>
          <UncontrolledTooltip
            delay={0}
            placement="top"
            target={`tooltip611234742vega${modal}`}
          >
            Vegan
          </UncontrolledTooltip>
          <div
            data-placement="top"
            id={`tooltip611234742vega${modal}`}
            className="px-2 mr-2"
            style={styles}
          >
            <img
              alt="vegan"
              style={{
                width: 40,
              }}
              src={Vegan}
            />
          </div>
        </>
      )}

      {levelSpicy?.length >= 1 && (
        <div className="px-2 mr-2" style={styles}>
          {levelSpicy?.length === 3 ? (
            <>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target={`tooltip611234742extra${modal}`}
              >
                Extra spicy
              </UncontrolledTooltip>
              <img
                data-placement="top"
                id={`tooltip611234742extra${modal}`}
                style={{ height: 28, width: 28 }}
                src={Spicy3}
                alt="spicy3"
              />
            </>
          ) : levelSpicy?.length === 2 ? (
            <>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target={`tooltip611234742very${modal}`}
              >
                Very spicy
              </UncontrolledTooltip>
              <img
                data-placement="top"
                id={`tooltip611234742very${modal}`}
                style={{ height: 28, width: 28 }}
                src={Spicy2}
                alt="spicy2"
              />
            </>
          ) : levelSpicy?.length === 1 ? (
            <>
              <UncontrolledTooltip
                delay={0}
                placement="top"
                target={`tooltip611234742spicy${modal}`}
              >
                Spicy
              </UncontrolledTooltip>
              <img
                data-placement="top"
                id={`tooltip611234742spicy${modal}`}
                style={{ height: 28, width: 28 }}
                src={Spicy}
                alt="spicy"
              />
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default function ProductCard({
  onClick,
  maxText = 70,
  data,
  ind,
  length,
}) {
  const { theme } = useTheme();

  const { restaurant } = useOrderingRestaurant();

  const { width } = useScreenSize();

  return (
    <div
      onClick={() => {
        onClick();
      }}
      className="mobile-product-card-wrapper"
    >
      <div
        className="product-new mr-2"
        style={{
          cursor: "pointer",
          flex: 1,
        }}
      >
        <div
          className="product-card-divider product_card_menu"
          style={{
            paddingBottom: ind !== length - 1 && "15px",
            paddingTop: ind !== 0 && "15px",
            borderBottom: ind === length - 1 ? "none" : "",
            height: "100%",
          }}
        >
          <div className="prod-image">
            <img
              src={
                data?.imageUrl !== "" && data?.imageUrl
                  ? data?.imageUrl
                  : restaurant?.logo
              }
              alt="item_image"
              style={{
                height: 120,
                width: 180,
                borderRadius: 6,
                filter: data?.imageUrl === "" && "grayscale(100)",
                opacity: data?.imageUrl === "" && "10%",
                objectFit: "cover",
              }}
            />
          </div>

          <div
            style={{ width: "100%" }}
            className={`${width < 600 ? "mx-2 mt-1" : "mx-2"}`}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  fontSize: 17,
                  textTransform: "capitalize",
                  fontWeight: "600",
                  color: theme.primary,
                  textAlign: "left",
                }}
              >
                {data?.name}
              </h3>
              {data?.isCombo && (
                <Badge className="mx-2" color="warning">
                  Combo Meal
                </Badge>
              )}
            </span>

            <span
              style={{
                fontSize: 16,
                lineHeight: 1.1,
                fontWeight: "400",
              }}
              className="longer_text"
            >
              {truncateString(data?.description, 70)}
            </span>
          </div>

          <div className="select_button_points_wrapper">
            <div>
              <button
                className="prod-button bold mx-2"
                style={{
                  outline: "none",
                  backgroundColor: width < 600 ? "white" : theme?.primary,
                  color: width < 600 ? "black" : "white",
                  textTransform: "uppercase",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {Number(data?.price) > 0
                  ? `$${Number(data?.price).toFixed(2)}`
                  : "Select"}
              </button>
            </div>
            <ItemOptions modal={false} data={data} />
          </div>
        </div>
      </div>
      <div className="mobile-product-card-image">
        <img
          alt="product_mobile_image"
          PlaceholderSrc={restaurant?.logo}
          style={{
            height: 170,
            width: "100%",
            objectFit: "cover",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
            filter: data?.imageUrl === "" && "grayscale(100)",
            opacity: data?.imageUrl === "" && "10%",
          }}
          effect="blur"
          src={data?.imageUrl !== "" ? data?.imageUrl : restaurant?.logo}
        />
      </div>
    </div>
  );
}
