import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { getSeparatedValues } from "../../../utils/commaSeparator";
import SuperTableDnd from "../../../widgets/SuperTableDnd";
import TableWrapper from "../../../widgets/TableWrapper";
import { ErrorLable } from "./tabs/GeneralTabItem";
import { validationSchemaAddGroup } from "../../../utils/schemas/AddGroupCategory";
import { useRestaurantStore } from "../../../store/restaurant";

const CategoryRow = ({ data, ...props }) => {
  return (
    <tr {...props} ref={props.refDoc} className="text-left">
      <th>{data?.label || data?.category?.categoryName}</th>
      <td>{data?.sortOrder}</td>
      <td>
        {data?.category?.location
          ? getSeparatedValues(data?.category?.location?.map((op) => op?.name))
          : getSeparatedValues(data?.location?.map((op) => op?.name))}
      </td>
    </tr>
  );
};

export default function AddEditGroup(props) {
  const { entities, find, loading: isLoading } = useEntity("category");
  const queryClient = useQueryClient();
  const {
    create: createGroup,
    findOne,
    entity,
    updateEntity,
    refetch,
  } = useEntity("group-category");
  const { find: findLoc, entities: location } = useEntity("location");

  const [isInit, setIsInit] = useState(false);

  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { create: updateSortOrder } = useEntity(
    "group-category/category/sort-order"
  );

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData([...items]);
    setIsSortOrderChanged(true);
  };

  const handleSaveSortOrder = async () => {
    const IdsAndPos = data.map((d, idx) => {
      const obj = {
        category: d.category?._id,
        sortOrder: idx,
      };
      return obj;
    });

    try {
      await updateSortOrder({
        sortOrders: IdsAndPos,
        groupCatId: props.location.state,
      });
      await refetch();
      toast.success("Sort order updated");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
    setIsSortOrderChanged(false);
  };

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findLoc({
      resturant: authRest || restaurant,
    });
  }, []);

  useEffect(() => {
    if (props.location.state) {
      findOne(props.location.state);
    }
  }, [props.location.state]);

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      restaurant: authRest || restaurant,
      title: "",
      categories: [],
      sort: 0,
      status: "active",
      methodChosen: "all",
      location: [{ label: "All", value: "all" }],
    },
    validationSchema: validationSchemaAddGroup,
    onSubmit: async (values) => {
      if (values?.categories?.length < 2) {
        toast.error("Minimum 2 categories must be added");
        return;
      }

      const data = {
        restaurant: authRest || restaurant,
        name: values?.title,
        categories: values.categories.map((cat) => ({
          category: cat.value,
          sortOrder: cat?.sortOrder,
        })),
        sort: values.sort,
        status: values?.status,
        location: values?.location?.map((it) => {
          return it?.value;
        }),
        methodChosen: values.methodChosen,
      };

      if (data?.categories?.length <= 0) {
        toast.error("Please select atleast one category");
        return;
      }
      if (data?.location?.length <= 0) {
        toast.error("Please select atleast one location");
        return;
      }
      try {
        if (entity) {
          await updateEntity(entity?.data?._id, { ...data });
        } else {
          await createGroup({
            ...data,
          });
        }
        toast("Group successfully created");
        props.history.goBack();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        title: entity?.data?.name,
        location: entity?.data?.location?.map((it) => {
          return { label: it?.displayAddress, value: it?._id };
        }),
        categories: entity?.data?.categories?.map((it) => {
          return {
            label: it?.category?.categoryName,
            value: it?.category?._id,
            sortOrder: it?.sortOrder,
            location: it?.category?.location,
          };
        }),
        status: entity?.data?.status,
        sort: entity?.data?.sort,
        methodChosen: entity?.data?.methodChosen,
      });
      setIsInit(true);
    }
  }, [entity]);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Name", "Sort", "Location"];

  useEffect(() => {
    if (entity?.data?.categories) {
      setData(entity?.data?.categories);
    }
  }, [entity?.data?.categories]);

  return (
    <>
      <TimelineHeader
        name="Add Group Category"
        parentName="Categories"
        showBtn={true}
      >
        <>
          <Button
            onClick={handleSubmit}
            size="md"
            color="primary"
            type="button"
          >
            {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
          </Button>
        </>

        <Button
          className="btn-neutral text-black"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back
        </Button>
      </TimelineHeader>

      <TableWrapper customStyles="py-3">
        {!isLoading ? (
          <>
            <Col>
              <Form>
                <FormGroup className="text-left">
                  <Label
                    className="form-control-label ml-3"
                    htmlFor="example-text-input"
                  >
                    Title
                  </Label>
                  <Col>
                    <Input
                      onChange={handleChange("title")}
                      value={values?.title}
                      type="text"
                      className={`form-control ${
                        errors.title && touched.title ? "is-invalid" : ""
                      }`}
                    />
                    {errors.title && touched.title && (
                      <small className="text-red">{errors.title}</small>
                    )}
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col>
              <FormGroup className="col text-left">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Method
                </Label>
                <Input
                  multiple=""
                  type="select"
                  onChange={handleChange("methodChosen")}
                  value={values?.methodChosen}
                  className={`form-control ${
                    errors.methodChosen && touched.methodChosen
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Method</option>
                  <option value="pickup">Pickup</option>
                  <option value="delivery">Delivery</option>
                  <option value="walkup">Walk Up</option>
                  <option value="all">All</option>
                </Input>
                {errors.methodChosen && touched.methodChosen && (
                  <ErrorLable message={errors.methodChosen} />
                )}
              </FormGroup>
            </Col>
            <Col>
              <Form>
                <FormGroup className="col text-left">
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                  >
                    Assigned Location
                  </Label>

                  <Select
                    isMulti
                    name="location"
                    isSearchable={false}
                    onChange={(val) => setFieldValue("location", val)}
                    options={
                      location?.data?.data?.length > 0
                        ? [
                            { displayAddress: "All", _id: "all" },
                            ...location?.data?.data,
                          ].map((it) => {
                            return {
                              label: it?.displayAddress,
                              value: it?._id,
                            };
                          })
                        : []
                    }
                    value={values?.location}
                  />
                  {errors.location && touched.location && (
                    <ErrorLable message={errors.location} />
                  )}
                </FormGroup>
              </Form>
            </Col>
            <Col>
              <Form>
                <FormGroup className="col text-left">
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                  >
                    Categories
                  </Label>
                  <Select
                    onChange={(cat) => {
                      console.log(cat, "CAT");
                      setData([...cat]);
                      setFieldValue("categories", cat);
                    }}
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    value={values.categories}
                    isMulti
                    options={entities?.data?.data?.map((d) => {
                      return {
                        label: d?.categoryName,
                        value: d?._id,
                        sortOrder: 0,
                        location: d?.location,
                      };
                    })}
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col>
              <Form>
                <FormGroup className="text-left">
                  <Label
                    className="form-control-label ml-3"
                    htmlFor="example-text-input"
                  >
                    Sort Order
                  </Label>
                  <Col>
                    <Input
                      onChange={handleChange("sort")}
                      value={values?.sort}
                      type="number"
                      placeholder="Sort"
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col>
              <Form>
                <FormGroup className="row mx-3 text-left">
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                  >
                    Status
                  </Label>
                  <label className="custom-toggle mx-5">
                    <input
                      checked={values?.status === "active"}
                      onChange={(e) => {
                        setFieldValue(
                          "status",
                          e.target.checked ? "active" : "inactive"
                        );
                      }}
                      value={values?.status === "active"}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </FormGroup>
              </Form>
            </Col>
          </>
        ) : (
          <div
            style={{
              padding: 20,
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
        <>
          {isSortOrderChanged && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
              }}
              className="mr-6 pr-3 my-3"
            >
              <Button
                onClick={handleSaveSortOrder}
                outline
                color="warning"
                size="sm"
              >
                Save
              </Button>
            </div>
          )}

          <SuperTableDnd
            sort={handleSort}
            showCheckboxes={false}
            data={data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={false}
            history={props.history}
            row={CategoryRow}
            columns={Headers}
            isLoading={false}
            emptyMessage="Currently no Items"
            prevPage={data?.prevPage}
            nextPage={data?.nextPage}
            currentPage={data?.currentPage}
            handleDragEnd={handleDragEnd}
          />
        </>
      </TableWrapper>
    </>
  );
}
