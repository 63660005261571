export default function groupByNameParent(data) {
  const groupedData = [];
  const nameMap = {};

  for (const item of data) {
    const key = [item.name];
    if (!nameMap[key]) {
      nameMap[key] = { name: key[0], data: [] };
      groupedData.push(nameMap[key]);
    }
    nameMap[key].data.push({ ...item }); // Spread operator to copy item object
  }

  console.log("GROUPED", groupedData);

  return groupedData;
}
