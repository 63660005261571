/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import TimelineHeader from "../../components/Header/SimpleHeader";
import { useEntity } from "../../hooks/useEntity";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import ReportsHeader from "../resturants/reports/components/ReportsHeader";
import TableRowSmsReports from "./components/TableRowSmsReports";
import { Button } from "reactstrap";
import { exportAsCSV } from "../../utils/expoAsCSV";

const SmsReport = (props) => {
  const { entities, find } = useEntity("admin/reports/sms");
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  useEffect(() => {
    find({ startDate: startDate || firstDay, endDate: endDate || lastDay });
  }, [startDate, endDate]);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Partner", "Restaurant", "Order Count", "SMS Count"];

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };
    // needed for a bug in react-date-range which does not
    // set correct end date time

    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const handleExport = useCallback(() => {
    const data = entities?.data?.map((data, idx) => ({
      partner: data?.partnerName,
      restaurant: data?.restaurantName,
      orderCount: data?.orderCount?.length,
      smsCount: data?.count,
    }));
    exportAsCSV({ arrayOfObject: data, fileName: "sms-report" });
  }, [entities?.data]);

  return (
    <>
      <TimelineHeader name={"SMS Report"} parentName={"Reports"} showBtn={true}>
        <Button onClick={handleExport} color="primary">
          Export
        </Button>
      </TimelineHeader>
      <TableWrapper customStyles={"px-1 py-1"}>
        <ReportsHeader
          endDate={endDate || lastDay}
          startDate={startDate || firstDay}
          handleSelectDates={handleSelectDates}
          locationField={false}
        />
        <SuperTable
          sort={handleSort}
          showCheckboxes={false}
          data={entities?.data}
          onPageChange={handlePageChange}
          OnRowPerPageChange={handleRowsPerPageChange}
          total={entities?.data?.length}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          loading={false}
          history={props.history}
          row={TableRowSmsReports}
          columns={Headers}
          isLoading={false}
          emptyMessage="Currently no data available"
        />
      </TableWrapper>
    </>
  );
};

export default SmsReport;
