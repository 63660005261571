import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  url: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Required"),
  user: Yup.string().max(50, "Too Long!").required("Required"),
  password: Yup.string().required("Required"),
  jupiterBasic: Yup.string().required("Required"),
  vaultId: Yup.string().required("Required"),
});
