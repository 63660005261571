import { useState } from "react"
import AmexLogo from "../../../assets/amex-icon-checkout.png"
import DiscoverLogo from "../../../assets/discover-icon-checkout.png"
import MasterCardLogo from "../../../assets/mastercard-icon-checkout.png"
import VisaLogo from "../../../assets/visa-icon-checkout.png"
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant"
import GenericModal from "./modals/GenericModal"

export default function Footer({ showCardLogo = false }) {
  const logo = [VisaLogo, MasterCardLogo, AmexLogo, DiscoverLogo]
  const [open, setOpen] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)
  const { restaurant } = useOrderingRestaurant()

  return (
    <>
      {showCardLogo && (
        <div className="d-flex justify-content-center">
          {logo.map((lo) => {
            return <img className="m-1" style={{ height: 50 }} src={lo} />
          })}
        </div>
      )}
      <div className="footer_content_wrapper_ordering text-black">
        <div>
          <span style={{ fontWeight: "bold", color: "#000" }}>Powered by</span>
          <span
            className="ml-1"
            onClick={() => {
              window.location.href = `https://${restaurant?.partner?.partner?.url}`
            }}
            style={{ fontWeight: "bold", color: "#000", cursor: "pointer" }}
          >
            {restaurant?.partner?.partner?.name}
          </span>
        </div>
        <div>
          <span
            onClick={(e) => {
              if (restaurant?.termsUrl?.length > 0) {
                window.location.href = restaurant?.termsUrl
                return
              }
              setOpenTerms(true)
            }}
            style={{
              color: "#000",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            TERMS
          </span>
          &nbsp;|&nbsp;
          <span
            onClick={(e) => {
              if (restaurant?.privacyUrl?.length > 0) {
                window.location.href = restaurant?.privacyUrl
                return
              }
              setOpen(true)
            }}
            style={{
              color: "#000",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            POLICY
          </span>
        </div>
      </div>
      <GenericModal
        isOpen={open}
        title="Privacy"
        toggle={() => setOpen(!open)}
        data={
          restaurant?.privacy !== ""
            ? restaurant?.privacy
            : restaurant?.partner?.partner?.privacy
        }
      />
      <GenericModal
        isOpen={openTerms}
        title="Terms"
        toggle={() => setOpenTerms(!openTerms)}
        data={
          restaurant?.terms !== ""
            ? restaurant?.terms
            : restaurant?.partner?.partner?.terms
        }
      />
    </>
  )
}
