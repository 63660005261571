import { useFormik } from "formik";
import { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { ErrorLable } from "../../AddLocation";
import Map from "../../components/Map";

export default function DeliveryZone({
  zone,
  handleCopy,
  onChange,
  showExtendWithIHD,
  showDeliveryListing,
  location,
  latLng,
}) {
  const zoneFormik = useFormik({
    initialValues: {
      // ...zone,
      deliveryFee: "",
      minimumDeliveryAmount: "",
      enableDelivery: "",
      deliveryFeeSetting: "always",
      deliveryReadyTime: "",
      deliveryRadius: 10,
      deliveryOption: "geofence",
      latLng: [],
      geofencing: "",
      inited: false,
      key: zone.key,
      extendWithIHD: false,
      ihdClientId: "",
      ihdClientSecret: "",
      ihdLocationId: "",
      deliveryListingAddresses: [],
      currentDeliveryAddressInput: "",
    },
  });

  useEffect(() => {
    onChange(zoneFormik.values);
  }, [zoneFormik.values]);

  useEffect(() => {
    if (zone && !zoneFormik.values.inited) {
      zoneFormik.setValues({
        deliveryFeeSetting: "always",
        deliveryRadius: 10,
        ...zone,
        inited: true,
      });
    }
  }, [zone]);

  const handleListingAdd = (address) => {
    let listings = zoneFormik.values.deliveryListingAddresses || [];
    listings.push(address);
    zoneFormik.setFieldValue("deliveryListingAddresses", listings);
  };

  const handleAddressRemove = (index) => {
    let listings = zoneFormik.values.deliveryListingAddresses || [];
    listings.splice(index, 1);
    zoneFormik.setFieldValue("deliveryListingAddresses", listings);
  };

  return (
    <>
      {/* <Col md="12">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Delivery URL
            </Label>
            <Col md="8">
              <Button size="sm" onClick={handleCopy}>
                Click to copy
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </Col> */}
      <Col md="12">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Delivery Fee
            </Label>
            <Col md="8">
              <Input
                type="number"
                value={zoneFormik.values.deliveryFee}
                onChange={zoneFormik.handleChange("deliveryFee")}
                disabled={zoneFormik.values?.enableDelivery === false}
                className={`form-control ${
                  zoneFormik.errors.deliveryFee &&
                  zoneFormik.touched.deliveryFee
                    ? "is-invalid"
                    : ""
                }`}
              ></Input>
              {zoneFormik.errors.deliveryFee &&
                zoneFormik.touched.deliveryFee && (
                  <ErrorLable message={zoneFormik.errors.deliveryFee} />
                )}
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col md="12">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Minimum Delivery Amount
            </Label>
            <Col md="8">
              <Input
                type="number"
                value={zoneFormik.values.minimumDeliveryAmount}
                onChange={zoneFormik.handleChange("minimumDeliveryAmount")}
                disabled={zoneFormik.values?.enableDelivery === false}
              ></Input>
              {zoneFormik.errors.minimumDeliveryAmount &&
                zoneFormik.touched.minimumDeliveryAmount && (
                  <ErrorLable
                    message={zoneFormik.errors.minimumDeliveryAmount}
                  />
                )}
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col md="12">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Delivery Fee Settings
            </Label>
            <Col md="8">
              <Input
                type="select"
                value={zoneFormik.values.deliveryFeeSetting}
                onChange={zoneFormik.handleChange("deliveryFeeSetting")}
              >
                <option value="always">Always Apply Delivery Fee</option>
                <option value="Apply Deliver Fee if subtotal is less than the delivery minimum">
                  Apply Deliver Fee if subtotal is less than the delivery
                  minimum
                </option>
                <option value="Do not allow ordering until the subtotal is more than the delivery amount">
                  Do not allow ordering until the subtotal is more than the
                  delivery amount
                </option>
                <option value="Do not allow ordering until the subtotal is more than the delivery minimum and also add delivery fee">
                  Do not allow ordering until the subtotal is more than the
                  delivery minimum and also add delivery fee
                </option>
                <option value="none">None of the above</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col md="12">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Delivery Ready Time
            </Label>
            <Col md="8">
              <Input
                type="number"
                value={zoneFormik.values.deliveryReadyTime}
                onChange={zoneFormik.handleChange("deliveryReadyTime")}
                disabled={zoneFormik.values?.enableDelivery === false}
              ></Input>
              {zoneFormik.errors.deliveryReadyTime &&
                zoneFormik.touched.deliveryReadyTime && (
                  <ErrorLable message={zoneFormik.errors.deliveryReadyTime} />
                )}
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col md="12">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Delivery Radius
            </Label>
            <Col md="8">
              <Input
                type="select"
                disabled={zoneFormik.values?.enableDelivery === false}
                value={zoneFormik.values.deliveryRadius}
                onChange={(e) => {
                  zoneFormik.setFieldValue("geofencing", null);
                  zoneFormik.setFieldValue("deliveryRadius", e.target.value);
                }}
              >
                {[
                  1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
                  8.5, 9, 9.5, 10, 10.5,
                ].map((radius) => {
                  return <option value={radius}>{radius}</option>;
                })}
              </Input>
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col md={12}>
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md={4}
            >
              Delivery Option
            </Label>
            <Col md={8}>
              <Input
                type="select"
                value={zoneFormik.values.deliveryOption}
                onChange={zoneFormik.handleChange("deliveryOption")}
              >
                <option value="geofence">Geofence</option>
                <option disabled={!showDeliveryListing} value="listing">
                  Listing
                </option>
              </Input>
              {!showDeliveryListing && (
                <small>
                  Delivery listings are optimized to function seamlessly with a
                  single designated zone. Delete one or more zones to enable it.
                </small>
              )}
            </Col>
          </FormGroup>
        </Form>
      </Col>
      {zoneFormik.values.deliveryOption === "geofence" && (
        <>
          <Col md="12">
            <Form>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Delivery Zone
                </Label>
                <Col md="8">
                  <Map
                    center={{
                      lat: location?.latLng?.[0] || latLng?.[0],
                      lng: location?.latLng?.[1] || latLng?.[1],
                    }}
                    radius={zoneFormik.values.deliveryRadius || 10}
                    geofencing={zoneFormik.values.geofencing}
                    setGeofencing={(polygonBounds) => {
                      zoneFormik.setFieldValue("geofencing", polygonBounds);
                    }}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          {showExtendWithIHD && (
            <Col md="12">
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Extend with IHD
                  </Label>
                  <Col md="1">
                    <label className="custom-toggle">
                      <input
                        checked={zoneFormik.values?.extendWithIHD}
                        value={zoneFormik.values?.extendWithIHD}
                        onChange={(e) => {
                          console.log("enableihd form", e.target.checked);
                          zoneFormik.setFieldValue(
                            "extendWithIHD",
                            e.target.checked
                          );
                        }}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          )}
        </>
      )}

      {zoneFormik.values.deliveryOption === "listing" && (
        <>
          <Col md="12">
            <Form>
              <FormGroup className="row">
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Delivery Listing
                </Label>
                <Col md="8">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <Input
                      value={zoneFormik.values.currentDeliveryAddressInput}
                      onChange={zoneFormik.handleChange(
                        "currentDeliveryAddressInput"
                      )}
                    ></Input>
                    <Button
                      disabled={!zoneFormik.values.currentDeliveryAddressInput}
                      onClick={() => {
                        if (!zoneFormik.values.currentDeliveryAddressInput)
                          return;
                        handleListingAdd({
                          formatted_address:
                            zoneFormik.values.currentDeliveryAddressInput,
                          address1: "",
                          address2: "",
                          telephone: "",
                          city: "",
                          state: "",
                          zip: "",
                        });
                        zoneFormik.setFieldValue(
                          "currentDeliveryAddressInput",
                          ""
                        );
                      }}
                      color="primary"
                      outline
                    >
                      Add
                    </Button>
                  </div>
                  <ListGroup className="mt-2">
                    {zoneFormik.values.deliveryListingAddresses?.map(
                      (listing, index) => {
                        return (
                          <ListGroupItem>
                            <Row>
                              <Col md={11}>{listing.formatted_address}</Col>
                              <Col md={1}>
                                <Button
                                  onClick={() => {
                                    handleAddressRemove(index);
                                  }}
                                  color="warning"
                                  outline
                                  size="sm"
                                >
                                  ✕
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        );
                      }
                    )}
                  </ListGroup>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </>
      )}

      <>
        {zoneFormik.values.extendWithIHD && (
          <Col md="12">
            <FormGroup className="row" style={{ alignItems: "center" }}>
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                IHD Client ID
              </Label>
              <Col md="8">
                <Input
                  onChange={zoneFormik.handleChange("ihdClientId")}
                  type="text"
                  value={zoneFormik.values?.ihdClientId}
                  placeholder="Enter Client ID received from IHD"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row" style={{ alignItems: "center" }}>
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                IHD Client Secret
              </Label>
              <Col md="8">
                <Input
                  onChange={zoneFormik.handleChange("ihdClientSecret")}
                  type="text"
                  value={zoneFormik.values?.ihdClientSecret}
                  placeholder="Enter Client Secret received from IHD"
                />
              </Col>
            </FormGroup>
            <FormGroup className="row" style={{ alignItems: "center" }}>
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                IHD Location ID
              </Label>
              <Col md="8">
                <Input
                  onChange={zoneFormik.handleChange("ihdLocationId")}
                  type="text"
                  value={zoneFormik.values?.ihdLocationId}
                  placeholder="Enter Location ID received from IHD"
                />
              </Col>
            </FormGroup>
          </Col>
        )}
      </>
    </>
  );
}
