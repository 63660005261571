import { useContext, useEffect, useState } from "react"
import { Button, Spinner } from "reactstrap"
import SimpleHeader from "../../../components/Header/SimpleHeader"
import { AuthContext } from "../../../context"
import { useEntity } from "../../../hooks/useEntity"
import { useAdminStore } from "../../../store/admin"
import SuperTable from "../../../widgets/SuperTable"
import TableWrapper from "../../../widgets/TableWrapper"
import TableRowCoupons from "./components/TableRowCoupons"

export default function Coupons(props) {
  const { find, entities, loading } = useEntity("coupon")
  const { restaurant } = useAdminStore()
  const authContext = useContext(AuthContext)
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id
  const [sorting, setSorting] = useState(-1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
    })
  }, [])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }
  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1)
    } else {
      setSorting(-1)
    }
  }

  const Headers = [
    "Coupon",
    "Code",
    "Type",
    "Discount",
    "Date Start",
    "Date End",
    "Status",
    "Action",
  ]
  return (
    <>
      <SimpleHeader
        path="/resturants"
        name="Coupons"
        parentName="Dashboard"
        showBtn={true}
      >
        <Button
          onClick={() => {
            props.history.push({
              pathname: "/resturants/marketing/add-coupon",
              search: props.location.search,
            })
          }}
          color="primary"
          size="md"
        >
          Add new
        </Button>
      </SimpleHeader>

      <TableWrapper>
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRowCoupons}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no coupons"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  )
}
