import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { useEntity } from "../../../hooks/useEntity";
import TableWrapper from "../../../widgets/TableWrapper";
import { toast } from "react-toastify";
import { useAdminStore } from "../../../store/admin";
import { useLocation } from "react-router-dom";
import Select from "react-select";

const InputField = ({ label, type, onChange, value, placeholder, options }) => {
  return (
    <FormGroup className="text-left">
      <Row md="8">
        <Col md="4">
          <label
            style={{ fontSize: 15, fontWeight: "600" }}
            htmlFor="productName"
          >
            {label}
          </label>
        </Col>
        <Col md="8">
          <Input
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={label}
            type={type}
          >
            {type === "select" && options?.length > 0 && (
              <>
                <option>Select</option>
                {options?.map((op) => {
                  return <option value={op?._id}>{op?.text}</option>;
                })}
              </>
            )}
          </Input>
        </Col>
      </Row>
    </FormGroup>
  );
};

const AddPushNotification = (props) => {
  const { entities, find } = useEntity("restaurant");
  const { create, findOne, entity, updateEntity } =
    useEntity("push-notification");
  const { partner } = useAdminStore();
  const locationID = useLocation();

  useEffect(() => {
    if (locationID?.state) {
      findOne(locationID?.state);
    }
  }, [locationID?.state]);

  const { handleSubmit, handleChange, values, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        restaurants: "",
        audience: "",
        sendNow: true,
        sendLater: false,
        date: "",
        time: "",
        message: "",
      },

      onSubmit: async (values, {}) => {
        const data = {
          ...values,
          partner,
          status: values?.sendLater ? "scheduled" : "completed",
          restaurant: values?.restaurants?.map((e) => e.value),
        };

        try {
          if (locationID?.state) {
            await updateEntity(locationID?.state, {
              ...values,
            });
            toast.success("Notification Created successfully");
          } else {
            await create(data);
            toast.success("Notification Created successfully");
          }
          props.history.push("push-notifications");
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    let filter = {
      partner: "638667fea3bd29bee53deab4",
      status: "active",
    };

    find({ ...filter });
  }, []);

  useEffect(() => {
    if (entities?.data) {
      const data = entities?.data
        ?.filter((fil) => fil?.showInPartnerApp)
        ?.map((op) => {
          return {
            label: op?.name,
            value: op?.userId?._id,
          };
        });

      setFinalData(data);
    }
  }, [entities?.data]);

  useEffect(() => {
    if (entity) {
      setValues({
        restaurant: entity?.data?.restaurant?._id,
        audience: entity?.data?.audience,
        sendNow: entity?.data?.sendNow,
        sendLater: entity?.data?.sendLater,
        date: entity?.data?.date,
        time: entity?.data?.time,
        message: entity?.data?.message,
      });
    }
  }, [entity]);

  return (
    <div>
      <SimpleHeader
        path="/admin/partners"
        name="Add Push Notifications"
        parentName="Partners"
        showBtn={true}
      >
        <Button
          onClick={() => props.history.push("push-notifications")}
          color="primary"
        >
          Back
        </Button>
      </SimpleHeader>
      <TableWrapper customStyles="py-3 px-5">
        <Row>
          <Col className="col-sm text-left">
            <FormGroup className="text-left">
              <Row md="8">
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "600" }}
                    htmlFor="productName"
                  >
                    Select Restaurants
                  </label>
                </Col>
                <Col md="8">
                  <Select
                    options={entities?.data
                      ?.filter((fil) => fil?.showInPartnerApp)
                      ?.map((op) => {
                        return {
                          label: op?.name,
                          value: op?.userId?._id,
                        };
                      })}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={(e) => setFieldValue("restaurants", e)}
                  />
                </Col>
              </Row>
            </FormGroup>

            <InputField
              placeholder="Select Audience"
              label="Audience"
              type="select"
              options={[
                { text: "Send to everyone", _id: "everyone" },
                {
                  text: "Send to people who have not ordered in last 14 Days",
                  _id: "no-order-14-days",
                },
                {
                  text: "Send to people who have not ordered in last 21 Days",
                  _id: "no-order-21-days",
                },
              ]}
              onChange={handleChange("audience")}
              value={values?.audience}
            />
            <FormGroup className="text-left">
              <Row md="8">
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "600" }}
                    htmlFor="productName"
                  >
                    Send Now
                  </label>
                </Col>
                <Col md="8">
                  <label className="custom-toggle">
                    <input
                      onChange={(e) => {
                        setFieldValue("sendNow", e.target.checked);
                        setFieldValue("sendLater", false);
                      }}
                      checked={values?.sendNow}
                      value={values?.sendNow}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="text-left">
              <Row md="8">
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "600" }}
                    htmlFor="productName"
                  >
                    Send Later
                  </label>
                </Col>
                <Col md="8">
                  <label className="custom-toggle">
                    <input
                      onChange={(e) => {
                        setFieldValue("sendLater", e.target.checked);
                        setFieldValue("sendNow", false);
                      }}
                      checked={values?.sendLater}
                      value={values?.sendLater}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </FormGroup>
            {values?.sendLater && (
              <div>
                <FormGroup className="text-left">
                  <Row md="8">
                    <Col md="4">
                      <label
                        className="form-control-label"
                        htmlFor="example-date-input"
                      >
                        Date
                      </label>
                    </Col>
                    <Col md="8">
                      <Input
                        defaultValue={new Date().getFullYear() + "-11-23"}
                        type="date"
                        onChange={handleChange("date")}
                        checked={values?.date}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="text-left">
                  <Row md="8">
                    <Col md="4">
                      <label
                        className="form-control-label"
                        htmlFor="example-date-input"
                      >
                        Time
                      </label>
                    </Col>
                    <Col md="8">
                      <Input
                        defaultValue="10:00:00"
                        type="time"
                        onChange={handleChange("time")}
                        checked={values?.time}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </div>
            )}
          </Col>
          <Col className="col-sm">
            <Row md="8">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="productName"
                >
                  Message
                </label>
              </Col>
              <Col md="8">
                <Input
                  placeholder={"Enter Message"}
                  name="Message"
                  type={"textarea"}
                  rows="10"
                  onChange={handleChange("message")}
                  value={values?.message}
                ></Input>
              </Col>
            </Row>
            <div className="my-4 d-flex justify-content-end ">
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </TableWrapper>
    </div>
  );
};

export default AddPushNotification;
