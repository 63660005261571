import Logout from "../../screens/auth/Logout";
import Dashboard from "../../screens/home/Dashboard";
import Partners from "../../screens/partners/Partners";
import Payments from "../../screens/payments/Payments";
import Orders from "../../screens/reports/Orders";
import SmsReport from "../../screens/reports/Sms";
import SMS from "../../screens/reports/Sms";
import Snapshot from "../../screens/reports/Snapshot";
import Transactions from "../../screens/reports/Transactions";
import MicrosaleConfig from "../../screens/settings/MicrosaleConfig";

const routesAdmin = [
  {
    path: "",
    name: "Dashboard",
    icon: "fa fa-home",
    layout: "/admin",
    component: Dashboard,
  },
  {
    path: "/partners",
    name: "Partners",
    icon: "fa fa-building",
    layout: "/admin",
    component: Partners,
  },
  {
    collapse: true,
    path: "/reports",
    name: "Reports",
    layout: "/admin",
    icon: "ni ni-single-copy-04",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/reports/sms",
        name: "SMS",
        miniName: "D",
        component: SmsReport,
        layout: "/admin",
        icon: "fa fa-comment",
      },
      {
        path: "/reports/snapshot",
        name: "Snapshot",
        miniName: "D",
        component: Snapshot,
        layout: "/admin",
        icon: "fa fa-image",
      },
      {
        path: "/reports/transactions",
        name: "Transactions",
        miniName: "D",
        component: Transactions,
        layout: "/admin",
        icon: "fa fa-exchange",
      },
      {
        path: "/reports/orders",
        name: "Orders",
        miniName: "D",
        component: Orders,
        layout: "/admin",
        icon: "fa fa-shopping-cart",
      },
    ],
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "fa fa-dollar",
    layout: "/admin",
    component: Payments,
  },
  {
    collapse: true,
    path: "/settings",
    name: "Settings",
    layout: "/admin",
    icon: "ni ni-single-copy-04",
    state: "dashboardsCollapse1",
    views: [
      {
        path: "/reports/microsale-config",
        name: "POS Config",
        miniName: "M",
        component: MicrosaleConfig,
        layout: "/admin",
        icon: "fa fa-image",
      },
      // {
      //   path: "/reports/transactions",
      //   name: "Transactions",
      //   miniName: "D",
      //   component: Transactions,
      //   layout: "/admin",
      //   icon: "fa fa-exchange",
      // },
      // {
      //   path: "/reports/orders",
      //   name: "Orders",
      //   miniName: "D",
      //   component: Orders,
      //   layout: "/admin",
      //   icon: "fa fa-shopping-cart",
      // },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-sign-out text-danger",
    layout: "/admin",
    component: Logout,
  },
];

export default routesAdmin;
