import * as Yup from "yup";

export const validationSchemaAddTaxRate = Yup.object().shape({
  name: Yup.string().trim().required("Required"),
  type: Yup.string().required("Required"),
  rate: Yup.number()
    .when("type", {
      is: "percentage",
      then: Yup.number()
        .typeError("must be number type")
        .min(0, "Cannot be less than 0")
        .max(99, "cannot be more than 99")
        .required("Required"),
    })
    .typeError("must be number type"),
  fixedAmount: Yup.number()
    .when("type", {
      is: "fixed",
      then: Yup.number()
        .typeError("must be number type")
        .min(0, "cannot be less than 0")
        .max(999, "cannot be more tha 999")
        .required("Required"),
    })
    .typeError("must be number type"),
});
