import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import Select from "react-select";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../../components/Header/SimpleHeader";
import FormLoader from "../../../../components/Loaders/FormLoader";
import { HOST } from "../../../../config";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useUploadImage } from "../../../../hooks/useUploadImage";
import { useAdminStore } from "../../../../store/admin";
import { useRestaurantStore } from "../../../../store/restaurant";

export default function EditGroup(props) {
  const { upload } = useUploadImage();

  const { entities, find } = useEntity("category");
  const { findOne, entity } = useEntity("group-category");
  const { updateEntity } = useEntity("group-category");
  const [loading] = useState(false);
  const [image, setImage] = useState();

  const [categories, setCategories] = useState();
  const [finalCat, setFinalCat] = useState([]);
  const authContext = useContext(AuthContext);
  const [newImage, setNewImage] = useState();
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const [cats, setCat] = useState([]);
  const [categoryEdits, setCategoryEdits] = useState([]);

  useEffect(() => {
    find({
      resturant: authRest || restaurant,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
  }, []);

  useEffect(() => {
    let cats = [];

    entities?.data?.map((e) => {
      let a = {
        value: e._id,
        label: e.categoryName,
      };

      cats?.push(a);
    });

    setCat(cats);
  }, [entities]);

  const handleChangeCat = (category) => {
    setCategories(category);
  };

  useEffect(() => {
    categories?.map((loc) => {
      setFinalCat((prev) => [...prev, loc?.value]);
    });
  }, [categories]);

  const { handleSubmit, handleChange, values, isSubmitting, setValues } =
    useFormik({
      initialValues: {
        restaurant: authRest || restaurant,
        title: "",
        image: "",
        categories: [],
        status: "",
      },
      onSubmit: async (values) => {
        let imagePath = "";
        imagePath = await upload({
          file: image,
          owner: "restaurant",
        });
        const data = {
          restaurant: authRest || restaurant,
          groupTitle: values?.title,
          categories: values?.categories || finalCat,
          image: imagePath,
          status: values?.status,
        };

        try {
          await updateEntity(props.location.state, {
            ...data,
          });
          toast("Group successfully created");
          props.history.goBack();
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  useEffect(() => {
    findOne(props.location?.state);
  }, [props.location.state]);

  useEffect(() => {
    if (entity) {
      setValues({
        title: entity?.data?.groupTitle,
        categories: entity?.data?.categories,
        image: entity?.data?.image,
        status: entity?.data?.status,
      });
    }
  }, [entity]);

  useEffect(() => {
    let categories = [];

    values?.categories?.map((e) => {
      let a = {
        value: e._id,
        label: e.categoryName,
      };

      categories?.push(a);
    });
    setCategoryEdits(categories);
  }, [values?.categories]);

  return (
    <>
      <TimelineHeader
        name="Add Group Category"
        parentName="Categories"
        showBtn={true}
      >
        <>
          <Button
            onClick={handleSubmit}
            size="md"
            color="primary"
            type="button"
          >
            {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
          </Button>
        </>

        <Button
          className="btn-neutral text-black mr-4"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back
        </Button>
      </TimelineHeader>
      {loading ? (
        <FormLoader />
      ) : (
        <div
          className="pt-3 mt--6 card container"
          style={{ zIndex: 999, position: "relative" }}
        >
          <Col>
            <Form>
              <FormGroup className="text-left">
                <Label
                  className="form-control-label ml-3"
                  htmlFor="example-text-input"
                >
                  Title
                </Label>
                <Col>
                  <Input
                    onChange={handleChange("title")}
                    value={values?.title}
                    type="text"
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <FormGroup className="row" style={{ marginLeft: 10 }}>
            <Col>
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
              >
                Image
              </Label>
            </Col>
            <Col md={!newImage ? "8" : "8"}>
              <div
                className="px-2 rounded"
                style={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: "#ededed",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  fileContainerStyle={{
                    elevation: 5,
                    shadow: 10,
                  }}
                  singleImage
                  imgExtension={[
                    ".jpg",
                    ".gif",
                    ".png",
                    ".gif",
                    "webp",
                    "jpeg",
                  ]}
                  maxFileSize={5242880}
                  withPreview
                  onChange={(f) => {
                    setImage(f[0]);
                    setNewImage(f[0]);
                  }}
                />
              </div>
            </Col>
            <Col md="3">
              {!newImage && (
                <div
                  className="row"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <img
                    alt="a"
                    style={{
                      height: 180,
                      width: 180,
                      borderRadius: 15,
                      marginTop: 5,
                    }}
                    src={`${HOST}/static/${values?.image}`}
                  />
                </div>
              )}
            </Col>
          </FormGroup>

          <Col>
            <Form>
              <FormGroup className="col text-left">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Categories
                </Label>
                <Select
                  value={categoryEdits}
                  onChange={handleChangeCat}
                  isMulti
                  isSearchable={false}
                  options={cats}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <Form>
              <FormGroup className="row mx-3 text-left">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Status
                </Label>
                <label className="custom-toggle mx-5">
                  <input
                    checked={values?.status === true ? true : false}
                    onChange={handleChange("status")}
                    value={values?.status}
                    type="checkbox"
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </FormGroup>
            </Form>
          </Col>
        </div>
      )}
    </>
  );
}
