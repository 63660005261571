import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import Home from "./Home";
import HomeV2 from "./Homev2";

export default function MainHome(props) {
  const { restaurant } = useOrderingRestaurant();
  if (restaurant?.ordering?.enableLargeMap) return <HomeV2 {...props} />;

  return <Home {...props} />;
}
