export const TableRowOrders = ({ data }) => {
  return (
    <tr className="text-left">
      <td>{data?.restaurantId?.restaurant?.name}</td>
      <td>{data?.today}</td>
      <td>{data?.week}</td>
      <td>{data?.month}</td>
      <td>{data?.year}</td>
    </tr>
  );
};
