import React from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
let classNames = {
  primary: "bg-gradient-primary border-0",
  secondary: "bg-gradient-secondary border-0",
  danger: "bg-gradient-danger border-0",
  success: "bg-gradient-success border-0",
  info: "bg-gradient-info border-0",
  default: "bg-gradient-default border-0",
};

export default function StatCards({ value, title, bgColor }) {
  return (
    <Card className={classNames[bgColor]}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0 text-white"
            >
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-white">{value}</span>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
}
