/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import Delivery from "../../../assets/delivery.svg";
import CateringLogo from "../../../assets/icons/catering_icon.svg";
import DineIn from "../../../assets/icons/dine_in.svg";
import PickupLogo from "../../../assets/pickup_logo.svg";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import Notification from "../../../components/Popups/Notification";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import { useCartStore } from "../../../store";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import getOptionalValue from "../../../utils/getOptionalValue";
import "../styles/Home/home.css";
import IconButtonLarge from "./components/IconButtonLarge";

const Home = ({ history }) => {
  const { restaurant } = useOrderingRestaurant();
  const { clearCart } = useCart();
  const { setCoupon } = useCartStore();

  const { find, entities, loading } = useEntity("eat/ordering/method");

  const {
    setMethod,
    setLocation,
    setTiming,
    setLastAlertContent,
    lastAlertContent,
    setCateringData,
  } = useOrdering();

  const [homeModal, setHomeModal] = useState(false);

  const pathname = window.location.pathname.replace(/\/+$/, "");

  useEffect(() => {
    setHomeModal(true);
  }, [restaurant]);

  useEffect(() => {
    if (restaurant) {
      find({ restaurant: restaurant?.userId?._id });
    }
  }, [restaurant]);

  useEffect(() => {
    if (
      entities?.data?.availableMethods?.length === 1 &&
      restaurant?.ordering?.skipStepMethod &&
      restaurant?.orderingApp
    ) {
      setMethod(
        entities?.data?.availableMethods[0] === "dinein"
          ? "dine-in"
          : entities?.data?.availableMethods[0]
      );
      history.push({
        pathname: `${pathname}/location`,
      });
    }
  }, [entities, restaurant]);

  useEffect(() => {
    clearCart();
    setCoupon(null);
    setCateringData();
  }, []);

  const handleClick = (method) => {
    console.log("METHOD", method);
    if (
      entities?.data?.location?.length === 1 &&
      restaurant?.ordering?.skipStep &&
      restaurant?.ordering?.skipStepTimeM &&
      (!restaurant?.ordering?.orderNow || !restaurant.ordering.orderLater)
    ) {
      setLocation({ ...entities?.data?.location[0] });
      if (!restaurant?.ordering?.orderNow) {
        setTiming("later");
        history.push(`${pathname}/later`);
      } else {
        setTiming("now");
        history.push(`${pathname}/menu`);
      }
    } else if (
      entities?.data?.location?.length === 1 &&
      restaurant?.ordering?.skipStep &&
      !(!restaurant?.ordering?.orderNow && !restaurant?.ordering?.orderLater)
    ) {
      setLocation({ ...entities?.data?.location[0] });
      history.push(`${pathname}/timing`);
    } else if (
      !restaurant?.ordering?.orderNow &&
      !restaurant?.ordering?.orderLater
    )
      history.push(`${pathname}/location`);
    else history.push(`${pathname}/location`);
    setMethod(method);
  };

  //TODO: Give provision to change logo of methods
  const methods = {
    pickup: {
      title: getOptionalValue(restaurant?.ordering?.pickupTitle, "Pickup"),
      value: "pickup",
      logo: PickupLogo,
    },
    delivery: {
      title: getOptionalValue(restaurant?.ordering?.deliveryTitle, "Delivery"),
      value: "delivery",
      logo: Delivery,
    },
    dinein: {
      title: getOptionalValue(null, "Dine In"),
      value: "dine-in",
      logo: CateringLogo,
    },
    catering: {
      title: getOptionalValue(restaurant?.ordering?.catering, "Catering"),
      value: "catering",
      logo: DineIn,
    },
  };

  return (
    <>
      <OrderingLayout
        layout={true}
        title="Start your order"
        showStartOrder={
          entities?.data?.availableMethods?.length === 0 ||
          !restaurant?.orderingApp ||
          restaurant?.userId?.status === "inactive"
        }
        history={history}
      >
        {loading ? (
          <div
            style={{
              minHeight: 350,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            {entities?.data?.availableMethods?.map((method) => {
              if (
                !restaurant?.orderingApp ||
                restaurant?.userId?.status === "inactive"
              )
                return <></>;
              return (
                <IconButtonLarge
                  key={methods[method].title}
                  title={methods[method].title}
                  style={{ marginBottom: 15 }}
                  setMethod={() => handleClick(methods[method].value)}
                  image={methods[method].logo}
                />
              );
            })}
            {(entities?.data?.availableMethods?.length === 0 ||
              !restaurant?.orderingApp ||
              restaurant?.userId?.status === "inactive") && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{ fontSize: 24, fontWeight: "800", marginTop: 10 }}
                >
                  {restaurant?.ordering?.shopCloseTitle}
                </span>
                <span style={{ fontSize: 18, marginTop: 10 }}>
                  {restaurant?.ordering?.shopCloseText ||
                    "Online ordering isn’t available now."}
                </span>
              </div>
            )}
          </div>
        )}
        {restaurant?.pop_up_alerts?.home_popup?.home_popup &&
          restaurant?.orderingApp &&
          lastAlertContent?.home !==
            restaurant?.pop_up_alerts?.home_popup?.message && (
            <Notification
              btnTitle={restaurant?.pop_up_alerts?.home_popup?.title}
              popupTitle={restaurant?.pop_up_alerts?.home_popup?.title}
              popupHeading={restaurant?.pop_up_alerts?.home_popup?.title}
              popupDescription={restaurant?.pop_up_alerts?.home_popup?.message}
              toggle={() => {
                setLastAlertContent(
                  {
                    home: restaurant?.pop_up_alerts?.home_popup?.message,
                  },
                  lastAlertContent
                );
                setHomeModal(!homeModal);
              }}
              open={homeModal}
              origin="home"
            />
          )}
      </OrderingLayout>
    </>
  );
};
export default Home;
