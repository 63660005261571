import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { getSeparatedValues } from "../../../../utils/commaSeparator";

export const GroupRow = ({ data, history, ...props }) => {
  const { deleteEntity } = useEntity("group-category");
  const location = useLocation();
  const { updateEntity, loading: updateLoading } = useEntity("group-category");

  return (
    <tr className="text-left" {...props} ref={props.refDoc}>
      <th scope="row">
        <span className="name mb-0 text-sm ">{data?.name}</span>
      </th>
      <td scope="row">
        <span className="name mb-0 text-sm ">{data?.sort}</span>
      </td>
      <td className="text-left">
        {data?.categories?.length === 0 && "Not selected"}
        {getSeparatedValues(
          data?.categories
            ?.slice(0, 3)
            ?.map((item) => item?.category?.categoryName)
        )}
        {data?.categories.length > 3 && "..."}
      </td>

      <td>
        {updateLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.status === "active"}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: data?.status === "active" ? "inactive" : "active",
                  });
                  toast.success("Group Category updated successfully");
                } catch (error) {
                  toast(error.message);
                }
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Off"
              data-label-on="On"
            />
          </label>
        )}
      </td>
      <td>
        <i
          className="fa fa-edit"
          style={{ fontSize: 20, cursor: "pointer" }}
          onClick={() => {
            history.push({
              pathname: "/resturants/add-group-category",
              state: data?._id,
              search: location.search,
            });
          }}
        />
        <i
          className="fa fa-trash mx-2 text-danger"
          style={{ fontSize: 20, cursor: "pointer" }}
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(data?._id);
                  toast.error("Group deleted succesfully");
                }
              });
            } catch (error) {
              toast(error.message);
            }
          }}
        />
      </td>
    </tr>
  );
};
