/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Card, Row, Spinner } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
import SuperTable from "../../../../widgets/SuperTable";
import { LocationsRow } from "../components/LocationsRow";

export default function Locations(props) {
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const authContext = useContext(AuthContext);
  const { restaurant } = useAdminStore();
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const { find, entities, loading: isLoading } = useEntity("location");

  useEffect(() => {
    find({
      resturant: authRest || restaurant,
      page,
      limit: rowsPerPage,
      sorting,
    });
  }, [page, rowsPerPage, authRest, sorting]);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Owner", "Sort Order", "Status", "QR", "Devices", "Action"];

  return (
    <>
      <Row>
        <div className="col">
          <Card>
            {!isLoading ? (
              <SuperTable
                sort={handleSort}
                showCheckboxes={false}
                data={entities?.data?.data}
                onPageChange={handlePageChange}
                OnRowPerPageChange={handleRowsPerPageChange}
                total={entities?.data?.length}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                loading={isLoading}
                history={props.history}
                row={LocationsRow}
                columns={Headers}
                isLoading={isLoading}
                emptyMessage="Currently no locations"
                prevPage={entities?.data?.prevPage}
                nextPage={entities?.data?.nextPage}
                currentPage={entities?.data?.currentPage}
              />
            ) : (
              <div style={{ padding: 20 }}>
                <center>
                  <Spinner size="xl" color="primary" type="border" />
                </center>
              </div>
            )}
          </Card>
        </div>
      </Row>
    </>
  );
}
