import { useFormik } from "formik";
import moment from "moment";
import { useEffect } from "react";

import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import { useEntity } from "../../../../hooks/useEntity";
import ErrorLable from "../../../../utils/ErrorLable";
import ModalHeader from "../../../ordering/components/modals/ModalHeader";
import DatePicker from "react-datepicker";

function getCurrentTime() {
  const now = new Date();

  const hours = String(now.getHours()).padStart(2, "0");
  const mins = String(now.getMinutes() + 1).padStart(2, "0");
  return `${hours}:${mins}`;
}

export default function AddCustomHoursModal({
  isVisible,
  toggle,
  selectedLocation,
}) {
  const { create: addCustomHour } = useEntity("restaurant/custom-hour");

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      date: null,
      type: "",
      from: null,
      to: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      date: Yup.date().required("Date is required").nullable(),
      type: Yup.string().required("Type is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (
        values.from === "" ||
        values.to === "" ||
        !values.from ||
        !values.to
      ) {
        swal("Failed", "Please select all the required fields", "error");
        return;
      }

      const dateStr = values.date;
      const timeFromStr = values.from;
      const timeToStr = values.to;

      const date = moment(dateStr, "YYYY-MM-DD").toDate();
      const timeFrom = moment(timeFromStr, "HH:mm").toDate();
      const timeTo = moment(timeToStr, "HH:mm").toDate();
      const fromDate = moment(date)
        .set({
          hours: timeFrom.getHours(),
          minutes: timeFrom.getMinutes(),
          seconds: timeFrom.getSeconds(),
        })
        .toDate();

      const toDate = moment(date)
        .set({
          hours: timeTo.getHours(),
          minutes: timeTo.getMinutes(),
          seconds: timeTo.getSeconds(),
        })
        .toDate();

      //Call API
      const obj = {
        ...values,
        location: selectedLocation,
        fromDate,
        toDate,
      };
      try {
        await addCustomHour({ ...obj });
        swal("Success", "Custom Hours Added", "success");
        toggle();
      } catch (error) {}

      console.log(obj);
    },
  });

  useEffect(() => {
    if (values.type === "CLOSED_ALL_DAY") {
      let fromTime = "00:00";
      if (moment(values.date).startOf("day").isSame(moment().startOf("day"))) {
        fromTime = getCurrentTime();
      }

      setFieldValue("from", fromTime);
      setFieldValue("to", "23:59");
    }
  }, [values]);

  useEffect(() => {
    resetForm();
  }, [isVisible]);

  return (
    <Modal
      className="modal-dialog"
      size="lg"
      isOpen={isVisible}
      toggle={() => {
        toggle();
        resetForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
        }}
        title={"Add Custom Hour"}
      />

      <div className="modal-body">
        <Row>
          <Col md={4} className={"my-1"}>
            <Input
              type="text"
              value={values.title}
              name="title"
              onChange={handleChange}
              placeholder="Title"
            />
            {errors?.title && touched?.title && (
              <ErrorLable message={errors.title} />
            )}
          </Col>
          <Col md={4} className={"my-1"}>
            <DatePicker
              key={"custom"}
              selected={values.date}
              customInput={
                <Input style={{ width: "80%", cursor: "pointer" }} />
              }
              onChange={(date) => setFieldValue("date", date)}
              placeholderText="MM/DD/YYYY"
            />

            {errors?.date && touched?.date && (
              <ErrorLable message={errors.date} />
            )}
          </Col>
          <Col md={4} className={"my-1"}>
            <Input
              type="select"
              value={values.type}
              name="type"
              onChange={handleChange}
              placeholder="Type"
            >
              <option value={""}>Select...</option>
              <option value={"CUSTOM_HOURS"}>Custom Hours</option>
              <option value={"CLOSED_ALL_DAY"}>Close All Day</option>
            </Input>
            {errors?.type && touched?.type && (
              <ErrorLable message={errors.type} />
            )}
          </Col>
        </Row>
        {values.type !== "" && values.type === "CUSTOM_HOURS" ? (
          <Row className="mt-4">
            <Col md={12}>
              <Label className="text-bold" style={{ fontWeight: "bold" }}>
                Please Select Time Range
              </Label>
            </Col>
            <Col md={4} className={"my-1"}>
              <Input
                type="time"
                value={values.from}
                name="from"
                onChange={handleChange}
                placeholder="From"
              />
            </Col>
            <Col md={4} className={"my-1"}>
              <Input
                type="time"
                disabled={values.from === "" || !values.from}
                value={values.to}
                min={values.from}
                name="to"
                onChange={handleChange}
                placeholder="To"
              />
            </Col>
          </Row>
        ) : (
          values.type !== "" && (
            <Label className="mx-1 my-2 text-dark">
              <span className="fa fa-info-circle text-primary text-lg"></span>{" "}
              Resturant will be closed all day on selected date.
            </Label>
          )
        )}
      </div>

      <div className="modal-footer">
        <div>
          <Button
            disabled={isSubmitting}
            color="primary"
            onClick={async () => {
              handleSubmit();
            }}
            type="button"
          >
            {isSubmitting ? <Spinner size="sm" color="white" /> : "Add"}
          </Button>

          <Button
            onClick={() => {
              resetForm();
              toggle();
            }}
            color="neutral"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
