export const getAlphabeticalOrderArray = (arr, field = "product_name") => {
  const sortedArray = arr?.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });

  return sortedArray?.filter((f) => f !== undefined);
};
