import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { FaTimes } from "react-icons/fa";
import { Col, FormGroup, Input, Row } from "reactstrap";

const ReportsHeader = ({
  startDate,
  endDate,
  setLocationFilter,
  locationFilter,
  handleSelectDates,
  locationsData,
  locationField = false,
}) => {
  const [showDate, setShowDate] = useState(false);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  return (
    <div className="px-4 py-4">
      <div className="shadow-none">
        <Row>
          {locationField && (
            <Col md="3">
              <FormGroup className="text-left">
                <label>Locations</label>
                <Input
                  type="select"
                  onChange={(e) => setLocationFilter(e.target.value)}
                  value={locationFilter}
                >
                  <option value="">Select</option>
                  {locationsData?.data?.map((item) => {
                    return <option value={item?._id}>{item?.name}</option>;
                  })}
                </Input>
              </FormGroup>
            </Col>
          )}
          <Col md="3">
            <FormGroup className="text-left">
              <label className="text-left">Date</label>
              <div
                className="rounded"
                style={{
                  border: "1px solid #ededed",
                  height: "45px",
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setShowDate(!showDate)}
              >
                {moment(startDate).format("MM/DD/YYYY")} -{" "}
                {moment(endDate).format("MM/DD/YYYY")}
              </div>
            </FormGroup>
          </Col>

          {showDate && (
            <div
              className="px-3 py-3 shadow"
              style={{
                position: "absolute",
                zIndex: 999,
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
            >
              <FaTimes
                className="mb-5"
                color="black"
                onClick={() => setShowDate(!showDate)}
              />
              <DateRangePicker
                ranges={[selectionRange]}
                rangeColors={["#0FCDEF"]}
                onChange={handleSelectDates}
                moveRangeOnFirstSelection={false}
              />
            </div>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ReportsHeader;
