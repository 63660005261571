import { useContext, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { FiRefreshCcw } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdLogin, MdLogout } from "react-icons/md";
import swal from "sweetalert";
import { AuthContext } from "../../context";
import LoginModal from "../../screens/ordering/components/LoginModal";
import MyAccount from "../../screens/ordering/home/components/MyAccount";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../store/theme";
import DB from "../../utils/DB";
import { DBKeys } from "../../utils/Keys";

const MobileSidebarContent = ({ toggle, history }) => {
  const { restaurant } = useOrderingRestaurant();
  const { clearCart, setCoupon } = useCartStore();
  const [accountModal, setAccountModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { method } = useOrdering();
  return (
    <div>
      <div
        style={{ textAlign: "right" }}
        className="px-3 py-2"
        onClick={toggle}
      >
        <IoClose size={22} color="#000" />
      </div>
      <div style={{ textAlign: "left" }}>
        {!authContext?.user && (
          <div
            className="px-4 py-4"
            style={{
              borderBottom: "1px solid #DBDBDB",
              display: "flex",
              alignItems: "center",
              color: "black",
            }}
          >
            <div>
              <MdLogin size={20} />
            </div>
            <div
              onClick={() => {
                setLoginModal(true);
              }}
              style={{ fontSize: 18, fontWeight: "600" }}
              className="px-3"
            >
              Login
            </div>
          </div>
        )}
        {method !== "walkup" && (
          <div
            className="px-4 py-4"
            style={{
              borderBottom: "1px solid #DBDBDB",
              display: "flex",
              alignItems: "center",
              color: "black",
            }}
          >
            <div>
              <FiRefreshCcw size={20} />
            </div>
            <div
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  text: "Are you sure you want to startover?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    let url = new URL(restaurant?.url);
                    history.push(url.pathname);
                    setCoupon(null);
                    clearCart();
                  }
                });
              }}
              style={{ fontSize: 18, fontWeight: "600" }}
              className="px-3"
            >
              Start Over
            </div>
          </div>
        )}
        {authContext?.user && (
          <>
            <div
              className="px-4 py-4"
              style={{
                borderBottom: "1px solid #DBDBDB",
                display: "flex",
                alignItems: "center",
                color: "black",
              }}
              onClick={() => {
                let url = new URL(restaurant?.url);

                history.push(url.pathname + "/my-account");
              }}
            >
              <div>
                <BiUserCircle size={25} />
              </div>
              <div style={{ fontSize: 18, fontWeight: "600" }} className="px-3">
                Account
              </div>
            </div>
            <div
              className="px-4 py-4"
              style={{
                display: "flex",
                alignItems: "center",
                color: "black",
              }}
              onClick={() => {
                authContext.logout();
                DB?.removeData(DBKeys?.CUSTOMER_USER);
                DB.removeData(DBKeys?.TOKEN);
              }}
            >
              <div
                onClick={() => {
                  authContext.logout();
                  DB?.removeData(DBKeys?.CUSTOMER_USER);
                  DB.removeData(DBKeys?.TOKEN);
                }}
              >
                <MdLogout size={25} />
              </div>
              <div
                onClick={() => {
                  DB?.removeData(DBKeys?.USER);
                  DB.removeData(DBKeys?.TOKEN);
                  authContext.logout();
                }}
                style={{ fontSize: 18, fontWeight: "600" }}
                className="px-3"
              >
                Sign Out
              </div>
            </div>
          </>
        )}
      </div>
      <MyAccount
        open={accountModal}
        toggle={() => setAccountModal(!accountModal)}
        theme={theme}
      />
      <LoginModal
        history={history}
        open={loginModal}
        toggle={() => setLoginModal(!loginModal)}
        theme={theme}
      />
    </div>
  );
};

export default MobileSidebarContent;
