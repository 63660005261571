import * as Yup from "yup";

export const validationSchemaAddCategory = Yup.object().shape({
  categoryName: Yup.string().trim().required("Required"),
  methodChosen: Yup.string().required("Required"),
  sortOrder: Yup.number()
    .moreThan(-1, "Must be any positive value")
    .required("Required"),
  status: Yup.string().required("Required"),
  location: Yup.array().required("Required"),
});
