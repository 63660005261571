import { Spinner } from "reactstrap";

const TableLoader = () => {
  return (
    <div>
      {" "}
      <div
        style={{
          padding: 20,
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <center>
          <Spinner size="lg" color="primary" type="border" />
        </center>
      </div>
    </div>
  );
};

export default TableLoader;
