/* eslint-disable array-callback-return */
import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";

export default function EditCoupon(props) {
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { findOne, entity, loading } = useEntity("coupon");
  const { updateEntity } = useEntity("coupon");
  const { find: findCategories, entities: categories } = useEntity("category");
  const { find: findLocations, entities: locations } = useEntity("location");
  const { find: findItems, entities: products } = useEntity("item");
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const [options, setOptions] = useState([]);
  const [cat, setCat] = useState([]);
  const [items, setItems] = useState([]);
  const [optionsE, setOptionsE] = useState([]);
  const [catE, setCatE] = useState([]);
  const [itemsE, setItemsE] = useState([]);
  const [finalCategories, setFinalCategories] = useState([]);
  const [finalLocations] = useState([]);
  const [finalItems, setfinalItems] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [locationId, setLocationId] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  useEffect(() => {
    findCategories({
      resturant: authRest || restaurant,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findItems({
      restaurant: authRest || restaurant,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findLocations({
      resturant: authRest || restaurant,
    });
  }, [authRest, restaurant]);

  useEffect(() => {
    let option = [];
    let cats = [];
    let product = [];

    products?.data?.map((e) => {
      let c = {
        value: e?._id,
        label: e?.productName,
      };
      product.push(c);
    });

    categories?.data?.map((e) => {
      let b = {
        value: e?._id,
        label: e?.categoryName,
      };
      cats.push(b);
    });

    locations?.data?.map((e) => {
      let a = {
        value: e._id,
        label: e.displayAddress,
      };

      option?.push(a);
    });
    setOptions(option);
    setCat(cats);
    setItems(product);
  }, [locations, categories, products]);

  const handleChangeCat = (cat) => {
    setFinalCategories(cat);
  };

  const handleChangeItems = (its) => {
    setfinalItems(its);
  };

  useEffect(() => {
    finalLocations?.map((loc) => {
      setLocationId((prev) => [...prev, loc?.value]);
    });
    finalCategories?.map((loc) => {
      setCategoryId((prev) => [...prev, loc?.value]);
    });
    finalItems?.map((loc) => {
      setItemId((prev) => [...prev, loc?.value]);
    });
  }, [finalCategories, finalLocations, finalItems]);
  useEffect(() => {
    findOne(props?.location?.state);
  }, [props.location?.state]);

  const { handleSubmit, handleChange, values, setValues } = useFormik({
    initialValues: {
      restaurant: "",
      couponName: "",
      couponCode: "",
      couponType: "",
      couponDiscount: "",
      subTotal: "",
      products: "",
      categories: "",
      locations: "",
      startDate: "",
      endDate: "",
      usesPerCoupon: "",
      usesPerCustomer: "",
      status: "",
      method: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const data = {
        resturant: authRest || restaurant,
        couponName: values?.couponName,
        couponCode: values?.couponCode,
        couponType: values?.couponType,
        couponDiscount: values?.couponDiscount,
        subTotal: values?.subTotal,
        items: [...new Set(itemId)],
        categories: [...new Set(categoryId)],
        location: [...new Set(locationId)],
        duration: {
          startDate: values?.startDate,
          endDate: values?.endDate,
        },
        usesPerCoupon: values?.usesPerCoupon,
        usesPerCustomer: values?.usesPerCustomer,
        status: values?.status,
        method: values?.method,
      };
      try {
        await updateEntity(props.location?.state, {
          ...data,
        });

        props.history.goBack();
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (entity) {
      let optionE = [];
      let catsE = [];
      let productE = [];

      let categories = entity?.data?.categories;
      let items = entity?.data?.items;
      if (restaurantDoc?.restaurant?.stream?.enableStreamDSP) {
        categories = categories.filter((cat) => cat.source === "stream");
        items = items.filter((cat) => cat.source === "stream");
      }

      items?.map((e) => {
        let c = {
          value: e?._id,
          label: e?.productName,
        };
        productE.push(c);
      });

      categories?.map((e) => {
        let b = {
          value: e?._id,
          label: e?.categoryName,
        };
        catsE.push(b);
      });

      entity?.data?.location?.map((e) => {
        let a = {
          value: e._id,
          label: e.displayAddress,
        };

        optionE?.push(a);
      });
      setOptionsE(optionE);
      setCatE(catsE);
      setItemsE(productE);
      setValues({
        couponName: entity?.data?.couponName,
        couponCode: entity?.data?.couponCode,
        couponType: entity?.data?.couponType,
        couponDiscount: entity?.data?.couponDiscount,
        subTotal: entity?.data?.subTotal,
        startDate: moment(entity?.data?.duration?.startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(entity?.data?.duration?.endDate).format("YYYY-MM-DD"),
        usesPerCoupon: entity?.data?.usesPerCoupon,
        usesPerCustomer: entity?.data?.usesPerCustomer,
        status: entity?.data?.status,
      });
    }
  }, [entity]);

  return (
    <>
      <TimelineHeader name="Create Coupon" parentName="Coupons" showBtn={true}>
        <Button
          onClick={() => {
            props.history.goBack();
          }}
          className="btn-neutral"
          color="default"
          size="md"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          color="primary"
          size="md"
        >
          Save
        </Button>
      </TimelineHeader>
      {!loading ? (
        <div className="container card p-6 mt--6">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Coupon name
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponName")}
                  value={values?.couponName}
                  id="example-text-input"
                  type="text"
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Code
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponCode")}
                  value={values?.couponCode}
                  id="example-text-input"
                  type="text"
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Type
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponType")}
                  value={values?.couponType}
                  id="example-text-input"
                  type="select"
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed Amount</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Discount
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponDiscount")}
                  value={values?.couponDiscount}
                  id="example-text-input"
                  type="text"
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Total Amount
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("subTotal")}
                  value={values?.subTotal}
                  id="example-text-input"
                  type="text"
                ></Input>
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Products
              </Label>
              <Col md="9">
                <Select
                  isSearchable={false}
                  onChange={(val) => handleChangeItems(val)}
                  isMulti
                  options={items}
                  value={itemsE && [...itemsE]}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Category
              </Label>
              <Col md="9">
                <Select
                  onChange={(val) => handleChangeCat(val)}
                  isMulti
                  options={cat}
                  value={catE && [...catE]}
                  name="category"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isSearchable={false}
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Location
              </Label>
              <Col md="9">
                <Select
                  onChange={(val) => handleChangeItems(val)}
                  isMulti
                  options={options}
                  value={optionsE && [...optionsE]}
                  isSearchable={false}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Date Start
              </Label>
              <Col md="9">
                <Input
                  id="example-text-input"
                  type="date"
                  onChange={handleChange("startDate")}
                  value={values?.startDate}
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Date End
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("endDate")}
                  value={values?.endDate}
                  id="example-text-input"
                  type="date"
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Uses Per Coupon
              </Label>
              <Col md="9">
                <Input
                  id="example-text-input"
                  type="text"
                  onChange={handleChange("usesPerCoupon")}
                  value={values?.usesPerCoupon}
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Uses Per Customer
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("usesPerCustomer")}
                  value={values?.usesPerCustomer}
                  id="example-text-input"
                  type="text"
                ></Input>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label
                className="form-control-label text-right"
                htmlFor="example-text-input"
                md="2"
              >
                Status
              </Label>
              <Col md="1">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("status")}
                      checked={values?.status}
                      value={values?.status}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </FormGroup>
          </Form>
        </div>
      ) : (
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      )}
      <div style={{ height: 100 }} />
    </>
  );
}
