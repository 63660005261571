import { Button, Card, Modal } from "reactstrap";
import ModalHeader from "./ModalHeader";

export default function ErrorModal({
  open,
  toggle,
  onClickSignup,
  theme,
  restaurant,
  toggleModal,
}) {
  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={open}
      toggle={toggle}
    >
      <div className="modal-body p-0 ">
        <Card
          className="border-0 modalcard"
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: "#fff",
            zIndex: 9999,
          }}
        >
          <ModalHeader toggle={toggle} />
          <div
            className="modal-body mt-2"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-info-circle"
              style={{ fontSize: 120, color: theme?.primary }}
            />
            <h3 className="pt-4">We don't deliver to this address</h3>
          </div>
          <div className="modal-footer">
            <Button
              onClick={toggle}
              style={{ backgroundColor: theme?.primary, color: "#fff" }}
            >
              Close
            </Button>
          </div>
        </Card>
      </div>
    </Modal>
  );
}
