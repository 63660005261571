import React from "react";

const TableRowRestaurantOrdersReport = ({ data }) => {
  return (
    <tr className="text-left">
      <td>{data?.orderNum}</td>
      <td>{data?.items.map((it) => it.name).join(",")}</td>
      <td>${data?.payment?.total?.toFixed(2)}</td>
      <td>${data?.payment?.subTotal?.toFixed(2)}</td>
      <td>${data?.payment?.tax?.toFixed(2)}</td>
      <td>${data?.payment?.tip?.toFixed(2)}</td>
      <td>${data?.payment?.deliveryFee?.toFixed(2)}</td>
      <td>${data?.payment?.orderFee?.toFixed(2)}</td>
    </tr>
  );
};

export default TableRowRestaurantOrdersReport;
