import React from "react";
import Select from "react-select";

export const SelectionComponent = ({
  options,
  onSelect,
  multi = false,
  value = null,
  onBlur,
}) => (
  <Select
    menuPortalTarget={document.body}
    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    options={options}
    onChange={onSelect}
    isMulti={multi}
    value={value}
    onBlur={onBlur}
  />
);
