import { useFormik } from "formik";
import { useContext, useState } from "react";
import { FaLock, FaUserCircle } from "react-icons/fa";
import { Button, Card, CardBody, Spinner } from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import DB from "../../../../utils/DB";
import { DBKeys } from "../../../../utils/Keys";
import logger from "../../../../utils/logger";
import SocialComponent from "../../auth/components/SocialComponent";
import ForgotPassword from "../../components/modals/ForgotPassword";

const LoginCustomer = () => {
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();

  const { theme } = useTheme();
  const { create: customerLogin } = useEntity("eat/auth/local");
  const { create } = useEntity("checkout-session");
  const [openModal, setOpenModal] = useState(false);

  const { handleChange, values, handleSubmit, errors, isSubmitting, touched } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      onSubmit: async (values) => {
        try {
          const lowerCaseEmail = values.email.toLowerCase();
          const r = await customerLogin({
            restaurant: restaurant?.userId?._id,
            email: lowerCaseEmail,
            password: values.password,
          });
          if (
            (await DB.storeData(DBKeys.CUSTOMER_USER, r?.data?.user)) &&
            (await DB.storeData(DBKeys.CUSTOMER_TOKEN, r?.data?.accessToken))
          ) {
            if (restaurant?.enable2fa) {
              window.localStorage.removeItem("otpDoc");

              const checkoutSessionData = await create(r?.data);

              window.localStorage.setItem(
                "checkoutSessionData",
                JSON.stringify(checkoutSessionData)
              );
            }
            authContext.login(r?.data);
          }
          swal("Success!", "Logged in", "success");
        } catch (error) {
          logger.push({ error, info: "LoginCustomer" });
          swal("Failed!", error.message, "error");
        }
      },
      validationSchema: Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      }),
    });

  return (
    <div>
      <Card style={{ boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)" }}>
        <CardBody>
          <h1>Sign in</h1>
          <div>
            <div className="input_group_checkout">
              <div
                style={{
                  flex: 1,
                }}
              >
                <div
                  className="px-3 mt-2"
                  style={{
                    backgroundColor: "#ededed",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: errors?.email && touched.email && "1px solid red",
                  }}
                >
                  <input
                    className=" py-3"
                    style={{
                      flex: 1,
                      border: "none",
                      fontSize: "18px",
                      background: "transparent",
                    }}
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                  />

                  <FaUserCircle size={25} />
                </div>
                {errors.email && touched.email ? (
                  <span className="text-red mb-2">{errors.email}</span>
                ) : (
                  <span></span>
                )}
              </div>
              <span className="mx-3"></span>

              <div style={{ flex: 1 }}>
                <div
                  className="px-3 mt-2"
                  style={{
                    backgroundColor: "#ededed",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border:
                      errors?.password && touched.password && "1px solid red",
                  }}
                >
                  <input
                    onChange={handleChange}
                    name="password"
                    value={values.password}
                    className=" py-3"
                    style={{
                      border: "none",

                      flex: 1,
                      fontSize: "18px",
                      background: "transparent",
                    }}
                    type="password"
                    placeholder="Password"
                  />

                  <FaLock size={25} />
                </div>
                {errors.password && touched.password ? (
                  <span className="text-red">{errors.password}</span>
                ) : (
                  <span></span>
                )}
              </div>
            </div>
          </div>

          <div className="mt-3 d-flex justify-content-between align-items-center">
            <div
              className="text-underline pointer"
              onClick={() => setOpenModal(true)}
            >
              Forgot Password?
            </div>
            <Button
              style={{
                backgroundColor: theme.primary,
                color: "white",
                fontWeight: "400",
                letterSpacing: "0.1em",
              }}
              onClick={handleSubmit}
            >
              {isSubmitting ? <Spinner size={"sm"} /> : "Sign in"}
            </Button>
          </div>
          {restaurant?.ordering?.socialLogin && (
            <SocialComponent showTextTop={true} className="mt-4" />
          )}
        </CardBody>
      </Card>
      <ForgotPassword
        open={openModal}
        theme={theme}
        toggle={() => setOpenModal(false)}
      />
    </div>
  );
};

export default LoginCustomer;
