import * as Yup from "yup";

export const validationSchemaAddItem = Yup.object().shape({
  productName: Yup.string().trim().required("Required"),
  description: Yup.string()
    .trim()
    .max(600, "more than 600 characters not allowed"),

  isCombo: Yup.boolean(),

  loyalityPointEnabled: Yup.boolean(),
  catering: Yup.boolean(),
  loyalityPoint: Yup.number().when("loyalityPointEnabled", {
    is: true,
    then: Yup.number().min(1, "Loyalty point cannot be less than 1"),
  }),
  moqPerOrder: Yup.number().when("catering", {
    is: true,
    then: Yup.number().min(1, "MOQ per order cannot be less than 1"),
  }),
  feed: Yup.number().when("catering", {
    is: true,
    then: Yup.number().min(1, "Feed per order cannot be less than 1"),
  }),
  assignedToLocation: Yup.array().min(1, "Please select atleast one location."),
  categories: Yup.array().min(1, "Please select atleast one category."),
  subProducts: Yup.array().when("isCombo", {
    is: true,
    then: Yup.array().min(2, "select atleast two items").required("Required"),
  }),
  price: Yup.number().min(-1, "Price cannot be less than 1"),

  redeemPoint: Yup.number().min(0, "Redeem points time cannot be less than 0"),

  preparationTime: Yup.number()
    .min(0, "Preparation time cannot be less than 0")
    .max(999, "Preparation time cannot be greater than 999"),
});
