function ungroupDaysByTime(groupedDays) {
  const days = [];

  // Loop through each group and add days to the array
  for (let group of groupedDays) {
    for (let day of group.days) {
      days.push({
        day: day,
        startDate: group.schedule.startDate,
        endDate: group.schedule.endDate,
      });
    }
  }

  return days;
}

export default ungroupDaysByTime;
