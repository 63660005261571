import React from "react";

const TableRowRestTaxReport = ({ data, history }) => {
  return (
    <tr className="text-left">
      <td>{data?.name}</td>
      <td>{data?.count}</td>
      <td>${Number(data?.total || 0).toFixed(2)}</td>
    </tr>
  );
};

export default TableRowRestTaxReport;
