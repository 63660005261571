import moment from "moment";
import { FaTrash } from "react-icons/fa";
import { Button } from "reactstrap";
import { days } from "../screens/resturants/menu/tabs/AvailablityTabItem";

export const LocationHoursRow = ({
  data,
  handleDeleteSchedule,
  index,
  handleEditSchedule,
}) => {
  return (
    <div className="py-2 px-4 mt-4 availability_row_slots">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {days?.map((day, i) => {
          return (
            <div className={`${i === 0 ? "" : "ml-3"}`}>
              <Button
                style={{ fontSize: 12 }}
                color={data.days.includes(day) ? "primary" : "secondary"}
                disabled
              >
                {day.substring(0, 3)}
              </Button>
            </div>
          );
        })}

        <div
          className="mx-6"
          style={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ width: 100, fontWeight: "600", fontSize: 12 }}>
            Pickup Time
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {data?.schedules.pickupFrom !== "" &&
            data?.schedules?.pickupTo !== "" ? (
              <>
                {
                  <span className="text-sm mt-2">
                    {moment(
                      new Date(
                        0,
                        0,
                        0,
                        data?.schedules?.pickupFrom?.split(":")[0],
                        data?.schedules?.pickupFrom?.split(":")[1]
                      )
                    ).format("LT")}{" "}
                    -{" "}
                    {moment(
                      new Date(
                        0,
                        0,
                        0,
                        data?.schedules?.pickupTo?.split(":")[0],
                        data?.schedules?.pickupTo.split(":")[1]
                      )
                    ).format("LT")}
                  </span>
                }
              </>
            ) : (
              <span className="text-sm mt-2">Not defined</span>
            )}
            <i
              onClick={() => {
                handleEditSchedule(index, "pickup");
              }}
              style={{ cursor: "pointer" }}
              className="fa fa-pencil ml-1"
            />
          </div>
        </div>

        <div
          style={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ width: 100, fontWeight: "600", fontSize: 12 }}>
            Delivery Time
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {data?.schedules.deliveryFrom !== "" &&
            data?.schedules?.deliveryTo !== "" ? (
              <>
                {
                  <span className="text-sm mt-2">
                    {moment(
                      new Date(
                        0,
                        0,
                        0,
                        data?.schedules.deliveryFrom?.split(":")[0],
                        data?.schedules?.deliveryFrom?.split(":")[1]
                      )
                    ).format("LT")}{" "}
                    -{" "}
                    {moment(
                      new Date(
                        0,
                        0,
                        0,
                        data?.schedules?.deliveryTo?.split(":")[0],
                        data?.schedules?.deliveryTo?.split(":")[1]
                      )
                    ).format("LT")}
                  </span>
                }
              </>
            ) : (
              <span className="text-sm mt-2">Not defined</span>
            )}
            <i
              className="fa fa-pencil ml-1"
              onClick={() => {
                handleEditSchedule(index, "delivery");
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <Button
          className="bg-red px-3 py-2 rounded"
          style={{ cursor: "pointer" }}
          onClick={() => handleDeleteSchedule(index)}
        >
          <FaTrash color="white" />
        </Button>
      </div>
    </div>
  );
};
