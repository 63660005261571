import { toast } from "react-toastify";
import { Media, Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { useRestaurantStore } from "../../../../store/restaurant";
import { useLocation } from "react-router-dom";
import { getSeparatedValues } from "../../../../utils/commaSeparator";

export const MenuRow = ({ history, data, ...props }) => {
  const { updateEntity, loading: updateLoading } = useEntity("menu");
  const { deleteEntity } = useEntity("menu");
  const location = useLocation();

  return (
    <tr className="text-left" {...props} ref={props.refDoc}>
      <th
        className="bold"
        style={{ cursor: "pointer" }}
        onClick={() =>
          history.push({
            pathname: "/resturants/menu-management",
            state: data?._id,
            search: location.search,
          })
        }
      >
        <Media style={{ cursor: "pointer", textTransform: "capitalize" }}>
          <span className="name mb-0 text-sm ">{data?.name}</span>
        </Media>
      </th>

      <td className="text-left">
        {data?.location?.map((t) => t?.name)?.join(",")}
      </td>
      <td>
        {updateLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.status}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: !data?.status,
                  });
                  toast("Menu updated successfully");
                } catch (error) {
                  toast(error.message);
                }
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Off"
              data-label-on="On"
            />
          </label>
        )}
      </td>
      <td className="text-left" style={{ textTransform: "capitalize" }}>
        {data?.source || "Local"}
      </td>
      <td className="text-left">
        <i
          className="fa fa-trash text-danger mx-2"
          style={{ fontSize: 20, cursor: "pointer" }}
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(data?._id);
                  toast("Menu Deleted Succesfully");
                }
              });
            } catch (error) {
              toast(error.message);
            }
          }}
        />
      </td>
    </tr>
  );
};
