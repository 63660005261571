// reactstrap components
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Spinner } from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import SuperTableDnd from "../../../widgets/SuperTableDnd";
import TableWrapper from "../../../widgets/TableWrapper";
import { MenuRow } from "./components/MenuRow";

export default function MenuList(props) {
  const [tabs, setTabs] = useState(1);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { restaurant } = useAdminStore();
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const [searchQuery, setSearchQuery] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  const [source, setSource] = useState("local");
  const query = useQueryClient();

  useEffect(() => {
    if (
      restaurantDoc?.restaurant?.stream?.enableStreamPOS ||
      restaurantDoc?.restaurant?.stream?.enableStreamDSP
    ) {
      setSource("stream");
    }
  }, [restaurantDoc]);

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData([...items]);
    setIsSortOrderChanged(true);
  };

  const { find, entities, loading: isLoading } = useEntity("menu");

  useEffect(() => {
    if (restaurant) {
      find({
        restaurant: authRest || restaurant,
        source: source,
      });
    }
  }, [restaurant, source]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Name", "Location", "Status", "Type", "Action"];

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  useEffect(() => {
    if (entities?.data?.data) {
      setData([...entities?.data?.data]);
    }
  }, [entities?.data?.data]);

  return (
    <>
      <TimelineHeader
        parentPath="/resturants"
        name="Menu"
        parentName="Dashboard"
        showBtn={true}
        showSearch={false}
        placeholder="Search"
        getSearchQuery={(val) => setSearchQuery(val)}
        path="/resturants"
        tagsFilter={false}
        tags={[]}
        getTag={(val) => setTagFilter(val)}
        streamFilter={
          restaurantDoc?.restaurant?.stream?.enableStreamDSP ||
          restaurantDoc?.restaurant?.stream?.enableStreamPOS
        }
        source={[
          { name: "Stream", _id: "stream" },
          { name: "Local", _id: "local" },
        ]}
        getSource={(val) => setSource(val)}
        isCategory={true}
        selectedSource={() => {
          if (source === "stream") {
            return { label: "Stream", value: source };
          } else {
            return { label: "Local", value: source };
          }
        }}
      >
        <>
          {!restaurantDoc?.restaurant?.enable_deliverect && (
            <Button
              color="primary"
              type="button"
              size="md"
              onClick={() => {
                props.history.push({
                  pathname: "/resturants/menu-management",
                  search: props.location.search,
                });
              }}
              disabled={restaurantDoc?.restaurant?.enableDeliverect}
            >
              Add new
            </Button>
          )}
        </>
      </TimelineHeader>

      <TableWrapper customStyles="px-4 py-3">
        {!isLoading ? (
          <SuperTableDnd
            sort={handleSort}
            showCheckboxes={false}
            handleDragEnd={handleDragEnd}
            data={data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoading}
            history={props.history}
            row={MenuRow}
            columns={Headers}
            isLoading={isLoading}
            prevPage={data?.prevPage}
            nextPage={data?.nextPage}
            currentPage={data?.currentPage}
            emptyMessage="Currently no menu "
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}
