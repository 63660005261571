import Alert from "../../screens/alerts/customAlerts";
import Logout from "../../screens/auth/Logout";
import DashboardResturant from "../../screens/resturants/DashboardResturant.jsx";
import EnquiryOrders from "../../screens/resturants/catering-orders/enquiry-orders";
import Customers from "../../screens/resturants/customers/Customers";
import Coupons from "../../screens/resturants/marketting/Coupons";
import Loyalty from "../../screens/resturants/marketting/Loyalty";
import MoreInfo from "../../screens/resturants/marketting/MoreInfo";
import ReEngage from "../../screens/resturants/marketting/ReEngage";
import Categories from "../../screens/resturants/menu/Categories";
import CategoriesBulk from "../../screens/resturants/menu/CategoriesBulk";
import GroupCategories from "../../screens/resturants/menu/GroupCategories";
import Items from "../../screens/resturants/menu/Items";
import MenuList from "../../screens/resturants/menu/MenuList";
import Options from "../../screens/resturants/menu/Options";
import Tags from "../../screens/resturants/menu/Tags";
import Orders from "../../screens/resturants/orders/Orders";
import CateringReport from "../../screens/resturants/reports/CateringReport";
import RestaurantCouponReport from "../../screens/resturants/reports/RestaurantCouponReport";
import RestaurantIndividualOrder from "../../screens/resturants/reports/RestaurantIndividualOrder";
import RestaurantOrders from "../../screens/resturants/reports/RestaurantOrders";
import RestaurantTaxReport from "../../screens/resturants/reports/RestaurantTaxReport";
import Settings from "../../screens/resturants/settings/Settings";

export const mobileRestaurantRoutes = [
  {
    path: "",
    name: "Dashboard",
    icon: "fa fa-home",
    layout: "/resturants",
    component: DashboardResturant,
  },
  {
    path: "/items",
    name: "Items",
    miniName: "D",
    component: Items,
    layout: "/resturants",
    icon: "ni ni-bold-right",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-cart",
    layout: "/resturants",
    component: Orders,
  },
  {
    name: "Settings",
    icon: "ni ni-ui-04",
    layout: "/resturants",
    path: "/settings",
    component: Settings,
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-sign-out text-danger",
    layout: "/resturants",
    component: Logout,
  },
];

const routesResturant = [
  {
    path: "",
    name: "Dashboard",
    icon: "fa fa-home",
    layout: "/resturants",
    component: DashboardResturant,
  },
  {
    collapse: true,
    path: "/menu",
    name: "Menu",
    layout: "/resturants",
    icon: "fa fa-list-alt",
    state: "dashboardsCollapse",
    views: [
      {
        path: "/menu",
        name: "Menu",
        miniName: "ME",
        component: MenuList,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/categories",
        name: "Categories",
        miniName: "D",
        component: Categories,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/items",
        name: "Items",
        miniName: "D",
        component: Items,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },

      {
        path: "/modifiers",
        name: "Modifiers",
        miniName: "D",
        component: Options,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/categories/bulk",
        name: "Bulk Item Category",
        miniName: "B",
        component: CategoriesBulk,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/group-category",
        name: "Group Categories",
        miniName: "G",
        component: GroupCategories,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/tags",
        name: "Tags",
        miniName: "T",
        component: Tags,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
    ],
  },

  {
    collapse: true,
    name: "Orders",
    icon: "fa fa-shopping-bag",
    layout: "/resturants",
    state: "dashboardsCollapseOrder",
    views: [
      {
        path: "/orders",
        name: "Orders",
        miniName: "C",
        component: Orders,
        layout: "/resturants",
        icon: "ni ni-cart",
      },
      {
        path: "/catering",
        name: "Catering",
        miniName: "En",
        component: EnquiryOrders,
        layout: "/resturants",
        icon: "ni ni-cart",
      },
    ],
  },

  // {
  //   path: "/orders",
  //   name: "Orders",
  //   icon: "ni ni-cart",
  //   layout: "/resturants",
  //   component: Orders,
  // },

  {
    collapse: true,
    name: "Customers",
    icon: "fa fa-users",
    layout: "/resturants",
    state: "dashboardsCollapse2",
    views: [
      {
        path: "/customers",
        name: "Customers",
        miniName: "C",
        component: Customers,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
    ],
  },

  {
    collapse: true,
    name: "Order Reports",
    icon: "ni ni-single-copy-04",
    layout: "/resturants",
    state: "dashboardsCollapse4",
    views: [
      {
        path: "/order-reports",
        name: "Orders",
        miniName: "D",
        component: RestaurantOrders,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/normal-order-reports",
        name: "Normal Orders",
        miniName: "D",
        component: RestaurantIndividualOrder,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/tax-reports",
        name: "Tax",
        miniName: "D",
        component: RestaurantTaxReport,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/coupon-reports",
        name: "Coupon",
        miniName: "D",
        component: RestaurantCouponReport,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      {
        path: "/catering-report",
        name: "Catering Orders",
        miniName: "catering-report",
        component: CateringReport,
        layout: "/resturants",
        icon: "ni ni-bold-right",
      },
      // {
      //   path: "/catering-normal-orders",
      //   name: "Catering Normal Orders",
      //   miniName: "catering-report",
      //   component: CateringNormalReport,
      //   layout: "/resturants",
      //   icon: "ni ni-bold-right",
      // },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Catering Reports",
  //   icon: "ni ni-single-copy-04",
  //   layout: "/resturants",
  //   state: "dashboardsCollapse9",
  //   views: [

  //     // {
  //     //   path: "/catering-normal-report",
  //     //   name: "Normal Orders",
  //     //   miniName: "catering-report",
  //     //   component: CateringNormalReport,
  //     //   layout: "/resturants",
  //     //   icon: "ni ni-bold-right",
  //     // },
  //   ],
  // },
  {
    name: "Settings",
    icon: "ni ni-ui-04",
    layout: "/resturants",
    path: "/settings",
    component: Settings,
  },

  {
    collapse: true,
    path: "/customAlert",
    name: "Marketing",
    layout: "/admin",
    icon: "fa fa-bar-chart",
    state: "dashboardsCollapse22",
    views: [
      {
        path: "/loyalty",
        name: "Loyalty",
        icon: "fa fa-trophy",
        layout: "/resturants",
        component: Loyalty,
      },
      {
        path: "/coupons",
        name: "Coupons",
        icon: "fa fa-tag",
        layout: "/resturants",
        component: Coupons,
      },
      {
        path: "/re-engage",
        name: "Re-Engage",
        icon: "fa fa-bullhorn",
        layout: "/resturants",
        component: ReEngage,
      },
      {
        path: "/customAlert",
        name: "Custom Alert",
        icon: "fa fa-bell",
        layout: "/resturants",
        component: Alert,
      },
      {
        path: "/more-info",
        name: "More Info",
        icon: "fa fa-info-circle",
        layout: "/resturants",
        component: MoreInfo,
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "fa fa-sign-out text-danger",
    layout: "/resturants",
    component: Logout,
  },
];

export default routesResturant;
