import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal, Spinner } from "reactstrap";
import * as Yup from "yup";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
import { ErrorLable } from "../../settings/AddLocation";

const AddTagModal = ({ toggle, open, id }) => {
  const { create: createTag, updateEntity } = useEntity("tag");
  const { findOne, entity } = useEntity("tag");

  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();

  useEffect(() => {
    if (id) {
      findOne(id);
    }
  }, [id, open]);

  const {
    handleSubmit,
    values,
    isSubmitting,
    setValues,
    resetForm,
    handleChange,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!id) {
          await createTag({
            restaurant: authRest || restaurant,
            name: values?.name,
          });

          toast.success("Tag created successfully");
          resetForm();
        } else {
          await updateEntity(id, {
            name: values?.name,
          });
          toast.success("Tag updated successfully");
          resetForm();
        }
        toggle();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        name: entity?.data?.name,
      });
    }
  }, [entity, open]);
  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={open}
      toggle={() => {
        toggle();
        resetForm();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Add Tag
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              textAlign: "right",
            }}
          >
            Name
          </div>
          <div
            className="mt-1"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Input
              type="text"
              value={values.name}
              onChange={handleChange("name")}
            />
          </div>
          {errors.name && touched.name && <ErrorLable message={errors.name} />}
        </div>
      </div>
      <div className="modal-footer">
        <div>
          <Button
            disabled={isSubmitting}
            color="primary"
            onClick={async () => {
              handleSubmit();
            }}
            type="button"
          >
            {isSubmitting ? (
              <Spinner size="sm" color="white" />
            ) : id ? (
              "Save Changes"
            ) : (
              "Add"
            )}
          </Button>

          <Button
            onClick={() => {
              resetForm();
              toggle();
            }}
            color="neutral"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddTagModal;
