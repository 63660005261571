import { useFormik } from "formik";
import { forwardRef, useContext, useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { IoMdLock } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import SecureInput from "../../../components/Inputs/SecureInput";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import DB from "../../../utils/DB";
import ErrorLable from "../../../utils/ErrorLable";
import getDaysInMonth from "../../../utils/getDaysInMonth";
import { DBKeys } from "../../../utils/Keys";
import logger from "../../../utils/logger";
import { validationSchemaACustomerRegister } from "../../../utils/schemas/CustomerRegister";
import "../styles/Home/home.css";
import SocialComponent from "./components/SocialComponent";
import GenericModal from "../components/modals/GenericModal";

export const CustomDateInput = forwardRef(
  ({ value, onClick, placeholder }, ref) => {
    return (
      <div
        className="example-custom-input"
        style={{ flex: 1 }}
        onClick={onClick}
        ref={ref}
      >
        {value || placeholder}
      </div>
    );
  }
);

const Register = (props) => {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();
  const { create: registerCustomer } = useEntity("eat/auth");
  const { create: login } = useEntity("eat/auth/local");
  const { create } = useEntity("checkout-session");
  const [openTerms, setOpenTerms] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      birthday: "",
      password: "",
      loyalityPoints: 0,
      anniversary: "",
      anniversaryMonth: "",
      smsForCouponAndInfo: false,
      emailForCouponAndInfo: true,
      agreeTermsAndConditions: false,
      birthMonth: "",
    },

    validationSchema: validationSchemaACustomerRegister,
    validateOnChange: true,

    onSubmit: async (values) => {
      try {
        await registerCustomer({
          email: values.email.toString().toLowerCase(),
          password: values.password,
          phone: values.phone,
          name: values.name,
          restaurant: restaurant?.userId,
          emailForCouponAndInfo: values.emailForCouponAndInfo,
          agreeTermsAndConditions: values?.agreeTermsAndConditions,
          smsForCouponAndInfo: values.smsForCouponAndInfo,
          birthday: `${
            values.birthMonth.toString().length === 1
              ? `0${values?.birthMonth.toString()}`
              : values?.birthMonth.toString()
          }/${
            values?.birthday.toString().length === 1
              ? `0${values?.birthday.toString()}`
              : values?.birthday.toString()
          }`,
          anniversary: `${
            values?.anniversary.toString().length === 1
              ? `0${values?.anniversaryMonth.toString()}`
              : values?.anniversaryMonth.toString()
          }/${
            values?.anniversary.toString().length === 1
              ? `0${values?.anniversary.toString()}`
              : values?.anniversary.toString()
          }`,
          status: "active",
          source: "web",
          loyalty: {
            restaurant: restaurant?.userId,
            points: 0,
          },
        });
        const r = await login({
          restaurant: restaurant?.userId?._id,
          email: values?.email.toString().toLowerCase(),
          password: values?.password,
        });
        if (
          (await DB.storeData(DBKeys.CUSTOMER_USER, r?.data?.user)) &&
          (await DB.storeData(DBKeys.CUSTOMER_TOKEN, r?.data?.accessToken))
        ) {
          if (restaurant?.enable2fa) {
              window.localStorage.removeItem("otpDoc");

            const checkoutSessionData = await create(r?.data);

            window.localStorage.setItem(
              "checkoutSessionData",
              JSON.stringify(checkoutSessionData)
            );
          }
          authContext.login(r?.data);
          toast.success("Registered Successfully");
          let url = new URL(restaurant?.url);
          props.history.push(url.pathname);
        }
      } catch (error) {
        logger.push({ error, info: "Customer_Register" });
        toast.error(error.message);
      }
    },
  });

  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  return (
    <OrderingLayout
      isCenter={true}
      loading={isSubmitting}
      title="Register"
      history={props.history}
      classname="main_ordering_card_wide"
      layout={true}
    >
      <div>
        {restaurant?.ordering?.socialLogin && (
          <SocialComponent className="mt-3" text="- Or Sign up with -" />
        )}
        <div
          className={`${errors.name ? "mt-4 mb-1" : "my-4"} rounded`}
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <input
            className="signin_input_ordering"
            type="text"
            value={values?.name}
            onChange={handleChange("name")}
            placeholder="Name"
          />

          <FaUser className="icons_signin" size={22} />
        </div>
        {touched.name && errors.name && (
          <div className="mb-2">
            <ErrorLable message={errors.name} />
          </div>
        )}
        <div
          className={`${errors.email ? "mt-4 mb-1" : "my-4"} rounded`}
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <input
            className="signin_input_ordering"
            type="email"
            value={values?.email}
            onChange={handleChange("email")}
            placeholder="Email address"
          />

          <MdEmail className="icons_signin" size={30} />
        </div>
        {touched.email && errors.email && (
          <div className="mb-2">
            <ErrorLable message={errors.email} />
          </div>
        )}
        <div
          className={`${errors.password ? "mt-4 mb-1" : "my-4"} rounded`}
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <SecureInput
            ordering
            onChange={(val) => handleChange("password")(val)}
            onBlur={(e) => console.log(e)}
            isValid={touched.password && !errors.password}
            label="Password"
            name="password"
            placeholder="Password"
            value={values.password}
            className={"signin_input_ordering"}
          />

          <IoMdLock className="icons_signin" size={30} />
        </div>
        {touched.password && errors.password && (
          <div className="mb-2">
            <ErrorLable message={errors.password} />
          </div>
        )}
        <div
          className={`${errors.phone ? "mt-4 mb-1" : "my-4"} rounded`}
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <input
            className="signin_input_ordering"
            type="number"
            value={values?.phone}
            onChange={handleChange("phone")}
            placeholder="Phone"
          />

          <BsTelephoneFill className="icons_signin" size={22} />
        </div>

        {touched.phone && errors.phone && (
          <div className="mb-2">
            <ErrorLable message={errors.phone} />
          </div>
        )}

        <div className="input_group_checkout mt-3">
          <div
            className="px-3 py-3 input_checkout rounded"
            style={{
              backgroundColor: "#ededed",
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                zIndex: 99,
              }}
            >
              <span className="mb-2 bold">BirthDay </span>{" "}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "null",
                      },
                    })}
                    name="month"
                    placeholder="Month"
                    options={months}
                    onChange={(val) => setFieldValue("birthMonth", val?.value)}
                  />
                  {touched.birthMonth && errors.birthMonth && (
                    <ErrorLable message={errors.birthMonth} />
                  )}
                </div>
                <span className="mx-2"></span>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "null",
                      },
                    })}
                    name="day"
                    placeholder="Day"
                    options={[
                      ...Array(getDaysInMonth(values.birthMonth)).keys(),
                    ].map((it) => {
                      return {
                        label: it + 1,
                        value: it + 1,
                      };
                    })}
                    onChange={(val) => setFieldValue("birthday", val?.value)}
                  />
                  {touched.birthday && errors.birthday && (
                    <ErrorLable message={errors.birthday} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <span className="mx-1"></span>
          <div
            className="px-3 py-3 input_checkout rounded"
            style={{
              backgroundColor: "#ededed",
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                zIndex: 9,
              }}
            >
              {" "}
              <span className="mb-2 bold">Anniversary </span>{" "}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    name="month"
                    placeholder="Month"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "null",
                      },
                    })}
                    options={months}
                    onChange={(val) =>
                      setFieldValue("anniversaryMonth", val?.value)
                    }
                  />
                  {touched.anniversaryMonth && errors.anniversaryMonth && (
                    <ErrorLable message={errors.anniversaryMonth} />
                  )}
                </div>
                <span className="mx-2"></span>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    name="day"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "null",
                      },
                    })}
                    placeholder="Day"
                    options={[
                      ...Array(getDaysInMonth(values.anniversaryMonth)).keys(),
                    ].map((it) => {
                      return {
                        label: it + 1,
                        value: it + 1,
                      };
                    })}
                    onChange={(val) => setFieldValue("anniversary", val?.value)}
                  />
                  {touched.anniversary && errors.anniversary && (
                    <ErrorLable message={errors.anniversary} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="custom-control custom-checkbox mt-3">
          <input
            className="custom-control-input"
            id="customCheck4"
            type="checkbox"
            onChange={handleChange("smsForCouponAndInfo")}
            value={values.smsForCouponAndInfo}
          />
          <label
            className="custom-control-label"
            style={{ marginTop: 3 }}
            htmlFor="customCheck4"
          >
            Sign up for SMS coupons and info
          </label>
        </div>

        <div className="custom-control custom-checkbox mt-3">
          <input
            className="custom-control-input"
            id="customCheck3"
            type="checkbox"
            onChange={handleChange("agreeTermsAndConditions")}
            value={values.agreeTermsAndConditions}
          />
          <label
            className="custom-control-label"
            style={{ marginTop: 3 }}
            htmlFor="customCheck3"
          >
            I agree to
          </label>
          <label className="text-sm pointer mx-1">
            <span
              style={{ textDecoration: "underline" }}
              onClick={() => setOpenTerms(true)}
            >
              terms
            </span>{" "}
            and{" "}
            <span
              style={{ textDecoration: "underline" }}
              onClick={() => setOpen(true)}
            >
              policies
            </span>
          </label>
        </div>

        <Button
          className="mt-4 py-3"
          block
          onClick={handleSubmit}
          disabled={!values.agreeTermsAndConditions}
          style={{
            backgroundColor: theme.primary,
            color: "white",
            textTransform: "uppercase",
            fontWeight: "400",
          }}
        >
          {isSubmitting ? <Spinner size="sm" color="white" /> : "Sign up"}
        </Button>
      </div>
      <GenericModal
        isOpen={open}
        title="Privacy"
        toggle={() => setOpen(!open)}
        data={
          restaurant?.privacy !== ""
            ? restaurant?.privacy
            : restaurant?.partner?.partner?.privacy
        }
      />
      <GenericModal
        isOpen={openTerms}
        title="Terms"
        toggle={() => setOpenTerms(!openTerms)}
        data={
          restaurant?.terms !== ""
            ? restaurant?.terms
            : restaurant?.partner?.partner?.terms
        }
      />
    </OrderingLayout>
  );
};

export default Register;
