/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { AuthContext } from "../../../context";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import HistoryCard from "./components/HistoryCard";
import { Button } from "reactstrap";
import { useTheme } from "../../../store/theme";

const OrderHistory = (props) => {
  const { find, entities, loading } = useEntity("eat/ordering/history");
  const { theme } = useTheme();
  const styles = {
    fontSize: 13,
    padding: 10,
    transform: "none",
    boxShadow: "none",
    border: `1px solid ${theme.primary}`,
    textTransform: "uppercase",
  };

  const { find: findItems, entities: allItems } =
    useEntity("eat/ordering/items");

  const { restaurant } = useOrderingRestaurant();
  const authContext = useContext(AuthContext);
  const { addToCart } = useCart();
  const [page] = useState(1);
  const [rowsPerPage] = useState(10);
  const { location, timing, laterTime, laterDate, method } = useOrdering();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    findItems({
      restaurant: restaurant?.userId?._id,
      location: location?._id,
      method,
    });
  }, [restaurant, location, method]);

  useEffect(() => {
    let dateString;
    if (timing === "later") {
      dateString = new Date(laterDate);
      const splittedTime = laterTime.split(":");
      dateString.setHours(splittedTime[0], splittedTime[1]);
    }
    find({
      customerId: authContext?.user?.customer?._id,
      schedule: timing === "later" ? dateString : undefined,
      restaurant: restaurant?.userId?._id,
      limit: rowsPerPage,
      page: page,
      locationId: location?._id,
      orders: activeTab === 0 ? "all" : "inquiry",
    });
  }, [
    authContext,
    restaurant,
    rowsPerPage,
    page,
    location,
    laterDate,
    laterTime,
    activeTab,
  ]);

  return (
    <OrderingLayout
      isCenter={true}
      title="Order History"
      history={props.history}
      classname="main_ordering_card_wide"
      layout={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          width: "100%",
          maxWidth: "100%",
          overflowY: "hidden",
          WebkitOverflowScrolling: "touch",
        }}
        className="mb-3 cutomer_profile_nav_buttons"
      >
        <Button
          onClick={() => setActiveTab(0)}
          style={{
            backgroundColor: activeTab === 0 ? theme.primary : "#fff",
            color: activeTab === 0 ? "#fff" : theme.primary,
            ...styles,
          }}
        >
          Orders
        </Button>
        <Button
          onClick={() => setActiveTab(1)}
          style={{
            backgroundColor: activeTab === 1 ? theme.primary : "#fff",
            color: activeTab === 1 ? "#fff" : theme.primary,
            border:
              activeTab === 1 ? "1px solid #fff" : `1px solid ${theme.primary}`,
            ...styles,
          }}
        >
          {"Catering (Inquiry)"}
        </Button>
      </div>
      {loading ? (
        <div>
          <Skeleton width={"15%"} height="35px" />
          <Skeleton inline width={"20%"} height="35px" />
          <Skeleton style={{ marginLeft: "55%" }} width={"20%"} height="35px" />
          <Skeleton width={"25%"} height="35px" />
        </div>
      ) : (
        <div className="mt-5">
          <>
            {" "}
            {entities?.data?.data?.map((o, index) => {
              return (
                <HistoryCard
                  history={props.history}
                  length={entities?.data?.length}
                  index={index}
                  data={o}
                  key={index}
                  allItems={allItems?.data?.data}
                  availablity={entities?.data?.itemsAvailable[index]}
                  onClick={() => {
                    let url = new URL(restaurant?.url);
                    entities?.data?.itemsAvailable[index]?.map((op) => {
                      // delete op.price;
                      // delete op.name;
                      addToCart({
                        ...op,
                        modifiers: op?.modifiers || [],
                        note: op?.note || "",
                        availableQty: undefined,
                      });
                    });
                    props.history.push(`${url.pathname}/menu`);
                  }}
                />
              );
            })}
          </>
        </div>
      )}
    </OrderingLayout>
  );
};

export default OrderHistory;
