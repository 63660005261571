import getSortedArray from "./getSortedArray";

export default function convertSelectedParentValue(arr, advancePizzaOptions) {
  const result = [];
  const map = {};

  for (let i = 0; i < arr?.length; i++) {
    const {
      product_id,
      product_name,
      selectedParentValue,
      price,
      halfPrice,
      extraPrice,
      defaultSelected,
      subModifiers,
      jkSoftId,
      sort,
      plu,
      allowExchange,
    } = arr[i];

    const key = advancePizzaOptions
      ? `${product_id}-${product_name}-${price}-${halfPrice}-${extraPrice}`
      : `${product_id}-${product_name}-${price}`;

    if (!map[key]) {
      map[key] = {
        rowId: selectedParentValue?.value + product_id,
        product_id,
        product_name,
        jkSoftId,
        price,
        halfPrice,
        extraPrice,
        sort,
        selectedParentValue: [],
        defaultSelected,
        advancePizzaOptions,
        subModifiers,
        plu,
        allowExchange,
      };
    }

    map[key].selectedParentValue.push({ ...selectedParentValue, i });
  }

  for (const key in map) {
    result.push(map[key]);
  }

  const sortedArray = getSortedArray(result, "sort");

  return sortedArray.filter((f) => f !== undefined);
}
