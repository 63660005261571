// nodejs library that concatenates classes
import React, { useState } from "react";
// react library for routing
import { ErrorMessage, Formik } from "formik";
import { Link, Switch, useLocation } from "react-router-dom";
import * as Yup from "yup";
// reactstrap components
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import swal from "sweetalert";
// core components
import AuthHeader from "../../components/Header/AuthHeader";
import { useEntity } from "../../hooks/useEntity";
import logger from "../../utils/logger";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

function Register({ history }) {
  const { create: register } = useEntity("auth");

  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [, setLoading] = useState(false);

  const handleRegister = async (values) => {
    setLoading(true);
    const { email, role, username, phone, password, permissions, status } =
      values;
    try {
      await register({
        email,
        username,
        phone,
        password,
        role,
        permissions,
        status,
      });

      swal("Successfully Register");
      history.push("/auth");
    } catch (error) {
      logger.push({ error, info: "Register" });
      swal(error.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content bg-default" ref={mainContentRef}>
        <Switch>
          <>
            <AuthHeader title="Create New Account" lead="" />
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Create a New Account</small>
                      </div>
                      <Formik
                        initialValues={{
                          username: "",
                          email: "",
                          phone: "",
                          password: "",
                        }}
                        validationSchema={LoginSchema}
                        onSubmit={async (values) => {
                          values.permissions = [];
                          values.status = "active";
                          values.role = "customer";
                          handleRegister(values);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="form-styling">
                              <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.username && !errors.username}
                                label="Username"
                                name="username"
                                placeholder="Username"
                              />
                              <ErrorMessage
                                name="username"
                                render={(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              />
                              <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.email && !errors.email}
                                label="Email"
                                name="email"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="email"
                                render={(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              />
                              <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.phone && !errors.phone}
                                label="Phone"
                                name="phone"
                                placeholder="Phone"
                              />
                              <ErrorMessage
                                name="phone"
                                render={(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              />
                              <Input
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.password && !errors.password}
                                label="Password"
                                name="password"
                                placeholder="Password"
                              />
                              <ErrorMessage
                                name="password"
                                render={(msg) => (
                                  <div className="error-msg">{msg}</div>
                                )}
                              />
                            </div>
                            <br></br>
                            <div textAlign="center">
                              <Button type="submit" className="btn-info">
                                Register
                              </Button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <Link className="text-light" to="/login">
                        <small>Sign in</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </>
        </Switch>
      </div>
    </>
  );
}

export default Register;
