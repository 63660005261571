/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Spinner } from "reactstrap";
import { useEntity } from "../../../hooks/useEntity";
import compareArrays from "../../../utils/compareArray";
import { toCapitalize } from "../../../utils/toCapitalize";
import GroupedPizzaToppings from "../menu/components/GroupPizzaToppings";

const TableRow = ({ label, value }) => {
  return (
    <tr style={{ color: "#000", textAlign: "left" }}>
      <td>{label}</td>
      <td style={{ textAlign: "right", color: "#000" }}>{value}</td>
    </tr>
  );
};

const TemporaryOrderDetails = () => {
  const { findOne, entity, loading } = useEntity("eat/ordering/order-details");

  const { find, entities: allItems } = useEntity("eat/ordering/items");

  const [data, setData] = useState({});

  const location = useLocation();

  useEffect(() => {
    findOne(location?.pathname?.split("/")[2]);
  }, [location]);

  useEffect(() => {
    find({
      restaurant: data?.location?.resturant?._id,
      location: data?.location?._id,
      method: data?.method,
    });
  }, [data]);

  useEffect(() => {
    if (entity) {
      setData(entity?.data);
    }
  }, [entity]);

  useEffect(() => {
    if (data?.items?.length > 0) {
      document.title = `Your ${data?.location?.resturant?.restaurant?.name} online order ${data?.orderNum}`;
    } else document.title = "Link is expired";
  }, [data]);

  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "robots";
    metaTag.content = "noindex";
    document.head.appendChild(metaTag);
  }, []);

  const serviceCharge = data?.payment?.taxDetails?.find(
    (tx) => tx.taxId === "SERVICE_TAX_ID"
  );

  const {
    orderNum,
    customer,
    payment,
    orderNowPickupDate,
    createdAt,
    method,
    tableNo,
    note,
    scheduledOn,
    orderTiming,
    deliveryMethod,
    provider,
    location: locationDoc,
    paymentStatus,
    __typename,
    source,
    moreInfoData,
  } = data;

  if (loading) {
    return (
      <div
        style={{
          padding: 20,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <center>
          <Spinner size="lg" color="black" />
        </center>
      </div>
    );
  }

  if (entity?.data?.message) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 18,
        }}
      >
        Link is expired
      </div>
    );
  }

  return (
    <div style={{ width: "380px", margin: "auto", paddingBottom: 60 }}>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "20px",
          padding: "10px",
        }}
      >
        {locationDoc?.resturant?.restaurant?.name}
      </div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          padding: "10px",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        YOUR ORDER ID IS - #{orderNum}
      </div>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          padding: "10px",

          borderBottom: "1px solid black",
        }}
      >
        {toCapitalize(method)} -{" "}
        {method === "delivery" ? (
          <span>
            {orderTiming === "later" ? (
              <span>{moment(scheduledOn).format("MMM Do, hh:mm A")}</span>
            ) : (
              <span>
                {moment(orderNowPickupDate).format("MMM DD, YYYY hh:mm A")}
              </span>
            )}
          </span>
        ) : (
          <span>
            {orderTiming === "later" ? (
              <span>{moment(scheduledOn).format("MMM Do, hh:mm A")}</span>
            ) : (
              <span>
                {moment(orderNowPickupDate).format("MMM DD, YYYY hh:mm A")}
              </span>
            )}
          </span>
        )}
      </div>
      <div style={{ marginTop: 10, textAlign: "left", color: "#000" }}>
        CUSTOMER DETAILS
      </div>
      <table style={{ marginTop: "10px", width: "100%", fontSize: "16px" }}>
        <TableRow label={"Name:"} value={customer?.name} />
        <TableRow label={"Email:"} value={customer?.email} />
        <TableRow label={"Phone:"} value={customer?.phone} />
      </table>
      {Object.keys(moreInfoData || {}).length > 0 && (
        <>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              padding: "10px",

              borderBottom: "1px solid black",
            }}
          />
          <div style={{ marginTop: 10, textAlign: "left", color: "#000" }}>
            MORE INFO
          </div>
          <table style={{ marginTop: "10px", width: "100%", fontSize: "16px" }}>
            {Object.keys(moreInfoData || {}).map((key) => {
              return <TableRow label={key} value={moreInfoData[key]} />;
            })}
          </table>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              padding: "10px",

              borderBottom: "1px solid black",
            }}
          />
        </>
      )}
      <div style={{ textAlign: "left", marginTop: 10 }}>SUMMARY</div>
      <table style={{ marginTop: "10px", width: "100%", fontSize: "16px" }}>
        <TableRow label={"Order: #"} value={orderNum} />
        <TableRow
          label={"Order Date:"}
          value={moment(createdAt).format("MMM Do, hh:mm A")}
        />
        <TableRow
          label={"Order Total:"}
          value={`$${payment?.total?.toFixed(2)}`}
        />
        <TableRow
          label={"Payment Status:"}
          value={
            data?.cateringDetails?.enquiry
              ? "N/A Inquiry"
              : toCapitalize(paymentStatus)
          }
        />
        {method === "dine-in" && (
          <TableRow label={"Table No: "} value={tableNo} />
        )}
      </table>
      {note && (
        <div
          style={{
            backgroundColor: "#ededed",
            marginTop: "10px",
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          <div style={{ color: "#000" }}>Order Notes :</div>
          <div style={{ fontSize: 16 }}>{note}</div>
        </div>
      )}
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          marginTop: "20px",
          fontSize: "16px",
        }}
      >
        <table style={{ width: "100%" }}>
          {method === "catering" && data?.cateringDetails?.enquiry && (
            <div>
              <div style={{ color: "#000", textAlign: "left" }}>
                CATERING (INQUIRY) -{" "}
                {moment(data?.scheduledOn).format("MMM Do, hh:mm A")}
              </div>
            </div>
          )}
          {(method === "pickup" ||
            (method === "catering" &&
              data?.cateringDetails?.method === "pickup")) && (
            <div>
              <div style={{ color: "#000", textAlign: "left" }}>
                PICKUP ADDRESS: {locationDoc?.address}
              </div>
            </div>
          )}
          {(method === "delivery" ||
            (method === "catering" &&
              data?.cateringDetails?.method === "delivery")) && (
            <>
              <tr style={{ marginTop: 20 }}>
                <td
                  style={{
                    textAlign: "left",
                    color: "#000",
                    textDecoration: "none",
                  }}
                >
                  DELIVERY ADDRESS:{" "}
                  {data?.method === "catering" &&
                  data?.cateringDetails?.method === "delivery"
                    ? (data?.cateringDetails?.apartment?.length > 0
                        ? data?.cateringDetails?.apartment
                        : "") +
                      "," +
                      data?.cateringDetails?.address
                    : data?.method === "delivery"
                    ? (data?.deliveryAddress?.apartmentNo?.length > 0
                        ? data?.deliveryAddress?.apartmentNo + " "
                        : " ") + data?.deliveryAddress?.formatted_address
                    : data?.location?.address}
                </td>
              </tr>
              <tr style={{ marginTop: 20 }}>
                <td
                  style={{
                    textAlign: "left",
                    color: "#000",
                    textDecoration: "none",
                  }}
                >
                  DELIVERY PROVIDER:{" "}
                  {provider?.id === "self" || !provider?.id
                    ? deliveryMethod
                    : provider?.id?.toUpperCase()}
                </td>
              </tr>
            </>
          )}
        </table>
      </div>
      <div style={{ marginTop: 20, marginBottom: 100 }}>
        <div
          style={{
            fontSize: "16px",
            paddingBottom: "20px",
            borderBottom: "1px solid #000",
            textAlign: "left",
            color: "#000",
          }}
        >
          ITEMS ORDERED
          <div style={{ marginTop: 10 }}>
            {entity?.data?.items?.map((it, i) => {
              let o = allItems?.data?.data?.find(
                (itm) => itm?._id === it?.itemId
              );

              const allSubprods = Object.values(o?.modifiers || {}).flatMap(
                (modif) => modif?.subProducts
              );

              const allSelectedModifiers = allSubprods.filter(
                (subProd) =>
                  (subProd?.defaultSelected || subProd?.advancedPizzaOptions) &&
                  (!subProd?.allowExchange ||
                    !it?.modifiers?.find(
                      (t) => t.exchangedWith === subProd?.product_id
                    ))
              );

              const missingSelected = compareArrays(
                allSelectedModifiers,
                it?.modifiers
              );

              const filteredBasedOnParentModifs = missingSelected.filter(
                (f) => {
                  const idx = it?.modifiers?.findIndex(
                    (t) =>
                      f.selectedParentValue.label === t.product_name ||
                      f?.selectedParentValue?.label === ""
                  );
                  return idx !== -1;
                }
              );

              const finalMissingSelected = filteredBasedOnParentModifs.filter(
                (obj, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.product_id === obj.product_id &&
                      t.product_name === obj.product_name
                  )
              );

              return (
                <div key={i}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {it?.name} {it?.qty > 1 && `${it?.qty}x`}
                    </div>
                    <div>${it?.price.toFixed(2)}</div>
                  </div>
                  {finalMissingSelected?.map((selected) => {
                    return (
                      <div
                        key={selected?.product_id}
                        className="d-flex text-left justify-content-between  align-items-center"
                      >
                        <div>
                          <div style={{ fontSize: 14 }}>
                            No {selected?.product_name}
                          </div>
                        </div>

                        <div></div>
                      </div>
                    );
                  })}
                  <GroupedPizzaToppings it={it} data={it.modifiers} />

                  {
                    <div style={{ marginLeft: 10 }}>
                      {it?.modifiers
                        ?.filter(
                          (it) =>
                            !it?.defaultSelected ||
                            (it?.defaultSelected && it?.qty > 1)
                        )
                        .map((op) => {
                          let newPrice;
                          if (op?.qty && op?.qty > 1 && !op?.defaultSelected) {
                            newPrice = op?.price * op?.qty;
                          } else if (
                            op?.qty &&
                            op?.qty > 1 &&
                            op?.defaultSelected
                          ) {
                            const quantity = Number(op?.qty) - 1;
                            newPrice = op?.price * quantity;
                          } else newPrice = op?.price * 1;

                          const modifierSelectdPrice =
                            op?.selectedModifier?.label?.split("$")[1] ===
                            undefined
                              ? 0
                              : op?.selectedModifier?.label?.split("$")[1];

                          return (
                            <>
                              {!op?.advancedPizzaOptions && (
                                <div style={{ marginBottom: 5 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      - {op?.product_name}{" "}
                                      {`${
                                        (!op.defaultSelected && op?.qty > 1) ||
                                        (op.defaultSelected && op?.qty > 2)
                                          ? ` - x${
                                              op?.defaultSelected
                                                ? op?.qty - 1
                                                : op?.qty
                                            }`
                                          : ""
                                      }`}
                                    </div>
                                    <div>
                                      {newPrice !== 0 && (
                                        <div>
                                          $
                                          <>
                                            {Number(
                                              Number(
                                                Number(newPrice) -
                                                  Number(
                                                    op?.selectedModifier?.label.split(
                                                      "$"
                                                    )[1] || 0
                                                  )
                                              ) * it?.qty
                                            ).toFixed(2)}
                                          </>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {op?.selectedModifier?.label && (
                                      <div
                                        className="d-flex"
                                        style={{
                                          width: "100%",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="text-left">
                                          -{" "}
                                          {
                                            op?.selectedModifier?.label
                                              .split("$")[0]
                                              .split(" -")[0]
                                          }
                                        </div>
                                        {modifierSelectdPrice !== 0 && (
                                          <div>
                                            $
                                            {Number(
                                              modifierSelectdPrice * it?.qty
                                            ).toFixed(2)}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  }
                  {it?.instruction && (
                    <div style={{ marginTop: 20 }}>
                      <p
                        className="py-2 px-3"
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          color: "#666666",
                          textAlign: "left",
                          backgroundColor: "#ededed",
                        }}
                      >
                        {it?.instruction}
                      </p>
                    </div>
                  )}
                  {entity?.data?.items?.length - 1 !== i && <hr />}
                  {it?.note && (
                    <>
                      <h1
                        style={{
                          fontWeight: "500",
                          fontSize: 18,
                          color: "#212121",
                          textAlign: "left",
                        }}
                      >
                        Special Instructions
                      </h1>
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: 15,
                          color: "#666666",
                          textAlign: "left",
                        }}
                      >
                        {it?.note}
                      </p>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <table
          style={{
            width: "100%",
            fontSize: "16px",
            marginTop: "15px",
            marginBottom: 15,
            paddingBottom: "15px",
          }}
        >
          <TableRow
            label={"Subtotal"}
            value={`$${payment?.subTotal?.toFixed(2)}`}
          />
          <TableRow
            label={"Tax"}
            value={`$${Number(
              __typename === "StreamOrder" || source === "stream"
                ? payment?.tax
                : payment?.tax -
                    payment?.orderFee -
                    payment?.deliveryFee -
                    (serviceCharge?.amount || 0)
            ).toFixed(2)}`}
          />
          {serviceCharge?.amount > 0 && (
            <TableRow
              label={
                payment?.taxDetails?.find((t) => t.taxId === "SERVICE_TAX_ID")
                  ?.name || "Service Charge"
              }
              value={`$${serviceCharge?.amount?.toFixed(2) || 0}`}
            />
          )}
          <TableRow
            label={"Ordering Fee"}
            value={`$${payment?.orderFee?.toFixed(2)}`}
          />
          {payment?.deliveryFee > 0 && (
            <TableRow
              label={"Delivery Fee"}
              value={`$${payment?.deliveryFee?.toFixed(2)}`}
            />
          )}

          {payment?.loyaltyDiscount?.toFixed(2) > 0 && (
            <TableRow
              label={"Loyalty Discount"}
              value={`-$${payment?.loyaltyDiscount?.toFixed(2)}`}
            />
          )}
          {payment?.discount?.toFixed(2) > 0 && (
            <TableRow
              label={"Coupon Discount"}
              value={`-$${payment?.discount?.toFixed(2) || 0}`}
            />
          )}
          <TableRow label={"Tip"} value={`$${payment?.tip?.toFixed(2)}`} />
        </table>

        <table
          style={{
            width: "100%",
            fontSize: "16px",
            paddingTop: "15px",
            borderTop: "1px solid #000",
            color: "#000",
          }}
        >
          <tr>
            <td style={{ textAlign: "left" }}>Total</td>
            <td style={{ textAlign: "right" }}>
              ${payment?.total?.toFixed(2)}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default TemporaryOrderDetails;
