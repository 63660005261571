import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Container, Row, Spinner } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { exportAsCSV } from "../../../utils/expoAsCSV";
import SuperTable from "../../../widgets/SuperTable";
import ReportsHeader from "./components/ReportsHeader";
import TableRowRestTaxReport from "./components/TableRowRestTaxReport";
import moment from "moment";

const RestaurantTaxReport = (props) => {
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const {
    find,
    entities,
    loading: isLoading,
  } = useEntity("restaurant-report/tax", {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    find({
      restaurantId: authRest || restaurant,
      startDate: startDate,
      endDate: endDate,
    });
  }, [startDate, endDate, restaurant, authRest]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Tax Title", "Total Order", "Tax"];

  const transformedData = useMemo(() => {
    return entities?.data?.map((data, idx) => {
      return {
        "Tax Title": data?.name,
        "Total Orders": data?.count,
        Tax: `$${data?.total.toFixed(2)}`,
      };
    });
  }, [entities?.data]);

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };
    // needed for a bug in react-date-range which does not
    // set correct end date time
    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setStartDate(range.from);
    setEndDate(range.to);
  };

  return (
    <div>
      <SimpleHeader
        path="/resturants"
        name="Tax Report"
        parentName="Restaurant"
        showBtn={true}
      >
        <Button
          color="primary"
          size="md"
          onClick={() =>
            exportAsCSV({
              arrayOfObject: transformedData,
              fileName: `Tax Report (${new Date().toDateString()}).csv`,
            })
          }
        >
          CSV
        </Button>
      </SimpleHeader>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ReportsHeader
                endDate={endDate}
                startDate={startDate}
                handleSelectDates={handleSelectDates}
                locationField={false}
              />
              <CardBody className="mt--5">
                {!isLoading ? (
                  <SuperTable
                    sort={handleSort}
                    showCheckboxes={false}
                    data={entities?.data}
                    onPageChange={handlePageChange}
                    OnRowPerPageChange={handleRowsPerPageChange}
                    total={entities?.data?.length}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    loading={isLoading}
                    history={props.history}
                    row={TableRowRestTaxReport}
                    columns={Headers}
                    isLoading={isLoading}
                    emptyMessage="No records"
                  />
                ) : (
                  <div
                    style={{
                      padding: 20,
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <center>
                      <Spinner size="lg" color="primary" type="border" />
                    </center>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default RestaurantTaxReport;
