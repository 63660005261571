/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// reactstrap components
import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import { AuthContext } from "../../../context";
import { useAdminStore } from "../../../store/admin";
import { useEntity } from "../../../hooks/useEntity";
import TableWrapper from "../../../widgets/TableWrapper";
import SuperTable from "../../../widgets/SuperTable";
import TableRowOrders from "../orders/components/TableRowOrders";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import classnames from "classnames";
import FilterModal from "../orders/components/FilterModal";
// core components

function EnquiryOrders(props) {
  const authContext = useContext(AuthContext);

  const { restaurant } = useAdminStore();
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const { entities, find, loading } = useEntity(
    tabs === 1 ? "order" : "enquiry-order"
  );

  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterData, setFilterData] = useState({});

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      limit: rowsPerPage,
      page: page,
      ...filterData,
      orderType: "all",
      inquiry: tabs === 2 && true,
      method: "catering",
    });
  }, [page, rowsPerPage, filterData, tabs]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = [
    "ID",
    "Customer",
    "Status",
    "Total",
    "Method",
    "Location Name",
    "Date Added",
    "Action",
  ];

  return (
    <div>
      <TimelineHeader
        path="/resturants"
        name="Catering"
        parentName="Dashboard"
        showBtn={true}
      >
        <FilterModal
          onChangeData={(data) => {
            setFilterData(data);
          }}
          showMethod={false}
        />
      </TimelineHeader>

      <TableWrapper customStyles="px-2">
        <Nav
          className="nav-fill flex-column flex-md-row mb-3"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Order
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Inquiry
            </NavLink>
          </NavItem>
        </Nav>
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRowOrders}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no Orders"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </div>
  );
}

export default EnquiryOrders;
