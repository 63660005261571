import { useFormik } from "formik";
import { useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Input, Modal, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useEntity } from "../../../hooks/useEntity";
import ErrorLable from "../../../utils/ErrorLable";

const EditMacModal = ({ toggle, open, data, partner, provider }) => {
  const { updateEntity, create } = useEntity("microsale");

  const { find: getPartners, entities: partnersList } = useEntity("partner");
  const { find: getRest, entities: rests = [] } =
    useEntity("restaurant/search");
  const { find: getLocs, entities: locs = [] } = useEntity("location");

  const {
    handleSubmit,
    setFieldValue,
    values,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: {
      partner: partner ? partner : data?.partner?._id,
      restaurant: data?.restaurant,
      location: data?.location?._id,
      ip: data?.ip,
      provider: provider ? provider : data?.provider || "microsale",
      mac: data?.mac || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      partner: Yup.string()
        .required("Please select a partner")
        .typeError("Please select a partner"),
      restaurant: Yup.string()
        .required("Please select a restaurant")
        .typeError("Please select a restaurant"),
      location: Yup.string()
        .required("Please select a location")
        .typeError("Please select a location"),
      ip: Yup.string(),
      // .test(
      //   "ip",
      //   "Invalid IP. It must be a IPv4 in format X.X.X.X",
      //   (text) => {
      //     if (!text) return true;
      //     console.log({ text });
      //     const ips = text.split(", ");
      //     return ips.every((ip) => {
      //       return ip.split(".").length === 4;
      //     });
      //   }
      // ),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        if (data?._id) {
          await updateEntity(data?._id, {
            ...values,
          });
        } else {
          await create(values);
        }
        toast.success("Mac updated successfully");
        resetForm();
        toggle();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    if (!values.partner) return;
    getRest({ limit: 1000, partner: values.partner });
  }, [values.partner]);

  useEffect(() => {
    if (!values.restaurant) return;
    getLocs({ resturant: values.restaurant, limit: 1000 });
  }, [values.restaurant]);

  const restaurants =
    rests?.data?.map?.((it) => ({
      label: it.name,
      value: it.userId,
    })) || [];

  const locations =
    locs?.data?.data?.map?.((it) => ({
      label: it.name,
      value: it._id,
    })) || [];

  const partners =
    partnersList?.data?.map((it) => ({
      label: it.partner?.name,
      value: it._id,
    })) || [];

  const providers = [
    {
      label: "Foodtronix",
      value: "foodtronix",
    },
    {
      label: "Microsale",
      value: "microsale",
    },
  ];

  console.log("values", values);

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => {
          toggle();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {data ? "Update" : "Create"} POS Restaurant
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <small>Mac Address</small>
          <Input
            type="text"
            placeholder="Mac Address"
            onChange={handleChange}
            onBlur={handleBlur}
            name="mac"
            value={values.mac}
            disabled={data?._id && data?.mac}
          />
          {touched.mac && errors.mac && <ErrorLable message={errors.mac} />}
        </div>

        {!partner && (
          <div className="modal-body">
            <small>Partner</small>
            <Select
              isSearchable
              options={partners}
              onChange={(option) => {
                setFieldValue("partner", option.value);
                setFieldValue("location", null);
                setFieldValue("restaurant", null);
              }}
              value={partners.find((it) => it.value === values.partner)}
              onBlur={handleBlur("partner")}
            />
            {touched.partner && errors.partner && (
              <ErrorLable message={errors.partner} />
            )}
          </div>
        )}
        <div className="modal-body">
          <small>Restaurant</small>
          <Select
            isSearchable
            options={restaurants}
            onChange={(option) => {
              setFieldValue("restaurant", option.value);
              setFieldValue("location", null);
            }}
            value={
              restaurants.find((it) => it.value === values.restaurant) || {}
            }
            onBlur={handleBlur("restaurant")}
          />
          {touched.restaurant && errors.restaurant && (
            <ErrorLable message={errors.restaurant} />
          )}
        </div>
        <div className="modal-body">
          <small>Location</small>
          <Select
            isSearchable
            options={locations}
            onChange={(option) => {
              setFieldValue("location", option.value);
            }}
            value={locations.find((it) => it.value === values.location) || {}}
            onBlur={handleBlur("location")}
          />
          {touched.location && errors.location && (
            <ErrorLable message={errors.location} />
          )}
        </div>
        {!provider && (
          <div className="modal-body">
            <small>Provider</small>
            <Select
              isDisabled={true}
              isSearchable
              options={providers}
              onChange={(option) => {
                setFieldValue("provider", option.value);
              }}
              value={providers.find((it) => it.value === values.provider) || {}}
              onBlur={handleBlur("provider")}
            />
            {touched.provider && errors.provider && (
              <ErrorLable message={errors.provider} />
            )}
          </div>
        )}

        {provider !== "foodtronix" && (
          <div className="modal-body">
            <small>IP</small>
            <Input
              type="text"
              placeholder="POS IP"
              onChange={handleChange}
              onBlur={handleBlur}
              name="ip"
              value={values.ip}
            />
            {touched.ip && errors.ip && <ErrorLable message={errors.ip} />}
          </div>
        )}

        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary" type="button">
            {isSubmitting ? <Spinner /> : "Save changes"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditMacModal;
