import React, { useState } from "react";
import { Media } from "reactstrap";
import { getSeparatedValues } from "../../../../utils/commaSeparator";
import CreateCategoryModal from "./CreateCategoryModal";
import { useEntity } from "../../../../hooks/useEntity";
import { toast } from "react-toastify";
import swal from "sweetalert";

const TableRowRestaurantCategories = ({ data, ...props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  const { deleteEntity } = useEntity("restaurant-categories");

  return (
    <tr {...props} ref={props.refDoc} className="text-left">
      <td>
        <Media className="align-items-center">
          <a
            className="avatar rounded-circle mr-3"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <img alt="..." src={data?.image} />
          </a>
          <Media>
            <span className="mb-0 text-sm">{data?.name}</span>
          </Media>
        </Media>
      </td>
      <td>
        {data?.restaurants?.length === 0 && "Not selected"}
        {getSeparatedValues(
          data?.restaurants?.slice(0, 3)?.map((res) => res?.restaurant?.name)
        )}
        {data?.restaurants?.length > 3 && "..."}
      </td>
      <td>{data?.sort}</td>
      <td>
        <i
          onClick={() => {
            setActiveItem(data);
            setOpenModal(true);
          }}
          className="fa fa-edit text-xl pointer"
        />
        <i
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(data?._id);
                  toast.error("Category Deleted Successfully");
                }
              });
            } catch (error) {}
          }}
          className="fa fa-trash text-danger mx-4 mb-1 text-xl pointer"
        />
      </td>
      <CreateCategoryModal
        openModal={openModal}
        toggle={() => setOpenModal(false)}
        activeItem={activeItem}
        edit={true}
      />
    </tr>
  );
};

export default TableRowRestaurantCategories;
