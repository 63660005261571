import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import ErrorLable from "../../utils/ErrorLable";

const InputWithLabel = ({
  label,
  handleChange,
  values,
  fieldName,
  errors,
  errorsOrder,
  touched,
  touchedOrder,
  catering = false,
}) => {
  if (catering) {
    return (
      <Row style={{ textAlign: "left" }}>
        <Col md="6">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: 150 }} className="text-sm">
                  {label}
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={
                  fieldName === "cateringFirstTierUpto" ? (
                    values?.cateringFirstTierUpto
                  ) : fieldName === "cateringSecondTierUpto" ? (
                    values?.cateringSecondTierUpto
                  ) : fieldName === "cateringThirdTierUpto" ? (
                    values?.cateringThirdTierUpto
                  ) : fieldName === "cateringFourthTierUpto" ? (
                    values?.cateringFourthTierUpto
                  ) : (
                    <></>
                  )
                }
                onChange={
                  fieldName === "cateringFirstTierUpto" ? (
                    handleChange("cateringFirstTierUpto")
                  ) : fieldName === "cateringSecondTierUpto" ? (
                    handleChange("cateringSecondTierUpto")
                  ) : fieldName === "cateringThirdTierUpto" ? (
                    handleChange("cateringThirdTierUpto")
                  ) : fieldName === "cateringFourthTierUpto" ? (
                    handleChange("cateringFourthTierUpto")
                  ) : (
                    <></>
                  )
                }
                type="number"
                size="md"
                className={`form-control ${
                  errors && touched ? "is-invalid" : ""
                }`}
              />
            </InputGroup>
            {errors && touched && <ErrorLable message={errors} />}
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="text-sm">Fee</InputGroupText>
              </InputGroupAddon>
              <Input
                value={
                  fieldName === "cateringFirstTierUpto"
                    ? values?.cateringFirstTierOnlineOrder
                    : fieldName === "cateringSecondTierUpto"
                    ? values?.cateringSecondTierOnlineOrder
                    : fieldName === "cateringThirdTierUpto"
                    ? values?.cateringThirdTierOnlineOrder
                    : values?.cateringFourthTierOnlineOrder
                }
                onChange={
                  fieldName === "cateringFirstTierUpto" ? (
                    handleChange("cateringFirstTierOnlineOrder")
                  ) : fieldName === "cateringSecondTierUpto" ? (
                    handleChange("cateringSecondTierOnlineOrder")
                  ) : fieldName === "cateringThirdTierUpto" ? (
                    handleChange("cateringThirdTierOnlineOrder")
                  ) : fieldName === "cateringFourthTierUpto" ? (
                    handleChange("cateringFourthTierOnlineOrder")
                  ) : (
                    <></>
                  )
                }
                type="number"
                size="md"
                className={`form-control ${
                  errorsOrder && touchedOrder ? "is-invalid" : ""
                }`}
              />
            </InputGroup>
            {errorsOrder && touchedOrder && (
              <ErrorLable message={errorsOrder} />
            )}
          </FormGroup>
        </Col>
      </Row>
    );
  }
  return (
    <Row style={{ textAlign: "left" }}>
      <Col md="6">
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ width: 150 }} className="text-sm">
                {label}
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={
                fieldName === "firstTierUpto" ? (
                  values?.firstTierUpto
                ) : fieldName === "secondTierUpto" ? (
                  values?.secondTierUpto
                ) : fieldName === "thirdTierUpto" ? (
                  values?.thirdTierUpto
                ) : fieldName === "fourthTierUpto" ? (
                  values?.fourthTierUpto
                ) : (
                  <></>
                )
              }
              onChange={
                fieldName === "firstTierUpto" ? (
                  handleChange("firstTierUpto")
                ) : fieldName === "secondTierUpto" ? (
                  handleChange("secondTierUpto")
                ) : fieldName === "thirdTierUpto" ? (
                  handleChange("thirdTierUpto")
                ) : fieldName === "fourthTierUpto" ? (
                  handleChange("fourthTierUpto")
                ) : (
                  <></>
                )
              }
              type="number"
              size="md"
              className={`form-control ${
                errors && touched ? "is-invalid" : ""
              }`}
            />
          </InputGroup>
          {errors && touched && <ErrorLable message={errors} />}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="text-sm">Fee</InputGroupText>
            </InputGroupAddon>
            <Input
              value={
                fieldName === "firstTierUpto"
                  ? values?.firstTierOnlineOrder
                  : fieldName === "secondTierUpto"
                  ? values?.secondTierOnlineOrder
                  : fieldName === "thirdTierUpto"
                  ? values?.thirdTierOnlineOrder
                  : values?.fourthTierOnlineOrder
              }
              onChange={
                fieldName === "firstTierUpto" ? (
                  handleChange("firstTierOnlineOrder")
                ) : fieldName === "secondTierUpto" ? (
                  handleChange("secondTierOnlineOrder")
                ) : fieldName === "thirdTierUpto" ? (
                  handleChange("thirdTierOnlineOrder")
                ) : fieldName === "fourthTierUpto" ? (
                  handleChange("fourthTierOnlineOrder")
                ) : (
                  <></>
                )
              }
              type="number"
              size="md"
              className={`form-control ${
                errorsOrder && touchedOrder ? "is-invalid" : ""
              }`}
            />
          </InputGroup>
          {errorsOrder && touchedOrder && <ErrorLable message={errorsOrder} />}
        </FormGroup>
      </Col>
    </Row>
  );
};
export default function TieredInput({
  handleChange,
  values,
  errors,
  touched,
  catering = false,
}) {
  if (catering) {
    return (
      <div className="mb-5">
        <InputWithLabel
          fieldName="cateringFirstTierUpto"
          onlineField="cateringFirstTierOnlineOrder"
          handleChange={handleChange}
          values={values}
          label={"First Tier: Up to"}
          errors={errors.cateringFirstTierUpto}
          errorsOrder={errors.cateringFirstTierOnlineOrder}
          touched={touched.cateringFirstTierUpto}
          touchedOrder={touched.cateringFirstTierOnlineOrder}
          catering={catering}
        />

        <InputWithLabel
          fieldName="cateringSecondTierUpto"
          onlineField="cateringSecondTierOnlineOrder"
          handleChange={handleChange}
          values={values}
          label={"Second Tier: Up to"}
          errors={errors.cateringSecondTierUpto}
          errorsOrder={errors.cateringSecondTierOnlineOrder}
          touched={touched.cateringSecondTierUpto}
          touchedOrder={touched.cateringSecondTierOnlineOrder}
          catering={catering}
        />
        <InputWithLabel
          fieldName="cateringThirdTierUpto"
          onlineField="cateringThirdTierOnlineOrder"
          handleChange={handleChange}
          values={values}
          label={"Third Tier: Up to"}
          errors={errors.cateringThirdTierUpto}
          errorsOrder={errors.cateringThirdTierOnlineOrder}
          touched={touched.cateringThirdTierUpto}
          touchedOrder={touched.cateringThirdTierOnlineOrder}
          catering={catering}
        />
        <InputWithLabel
          fieldName="cateringFourthTierUpto"
          onlineField="cateringFourthTierOnlineOrder"
          handleChange={handleChange}
          values={values}
          label={"Fourth Tier: Up to"}
          errors={errors.cateringFourthTierUpto}
          errorsOrder={errors.cateringFourthTierOnlineOrder}
          touched={touched.cateringFourthTierUpto}
          touchedOrder={touched.cateringFourthTierOnlineOrder}
          catering={catering}
        />
      </div>
    );
  }
  return (
    <div className="mb-5">
      <InputWithLabel
        fieldName="firstTierUpto"
        onlineField="firstTierOnlineOrder"
        handleChange={handleChange}
        values={values}
        label={"First Tier: Up to"}
        errors={errors.firstTierUpto}
        errorsOrder={errors.firstTierOnlineOrder}
        touched={touched.firstTierUpto}
        touchedOrder={touched.firstTierOnlineOrder}
      />

      <InputWithLabel
        fieldName="secondTierUpto"
        onlineField="secondTierOnlineOrder"
        handleChange={handleChange}
        values={values}
        label={"Second Tier: Up to"}
        errors={errors.secondTierUpto}
        errorsOrder={errors.secondTierOnlineOrder}
        touched={touched.secondTierUpto}
        touchedOrder={touched.secondTierOnlineOrder}
      />
      <InputWithLabel
        fieldName="thirdTierUpto"
        onlineField="thirdTierOnlineOrder"
        handleChange={handleChange}
        values={values}
        label={"Third Tier: Up to"}
        errors={errors.thirdTierUpto}
        errorsOrder={errors.thirdTierOnlineOrder}
        touched={touched.thirdTierUpto}
        touchedOrder={touched.thirdTierOnlineOrder}
      />
      <InputWithLabel
        fieldName="fourthTierUpto"
        onlineField="fourthTierOnlineOrder"
        handleChange={handleChange}
        values={values}
        label={"Fourth Tier: Up to"}
        errors={errors.fourthTierUpto}
        errorsOrder={errors.fourthTierOnlineOrder}
        touched={touched.fourthTierUpto}
        touchedOrder={touched.fourthTierOnlineOrder}
      />
    </div>
  );
}
