import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { Button, Col, Container, Input, Row, Spinner } from "reactstrap";
import TimelineHeader from "../../components/Header/SimpleHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import usePartnerId from "../../hooks/usePartnerId";
import logger from "../../utils/logger";
import { validationSchema } from "../../utils/schemas/Payment";

export default function Payments(props) {
  const { updateEntity } = useEntity("auth");
  const { findOne, entity } = useEntity("auth");
  const authContext = useContext(AuthContext);
  const partnerId = usePartnerId();
  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      url: "",
      user: "",
      password: "",
      jupiterBasic: "",
      vaultId: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      const data = {
        url: values?.url,
        user: values?.user,
        password: values?.password,
        jupiterBasic: values?.jupiterBasic,
        vaultId: values?.vaultId,
      };
      try {
        await updateEntity(
          authContext?.user?.role === "super-admin" &&
            window.location.pathname.includes("/partners/payments")
            ? partnerId
            : authContext?.user?._id,
          {
            paymentConfig: { ...data },
          }
        );
        toast.success("Payment Details Updated");
      } catch (error) {
        logger.push({ error, info: "AdminPaymentUpdate" });
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        url:
          entity?.data?.paymentConfig?.url ||
          entity?.data?.paymentConfig?.apiUrl,
        user:
          entity?.data?.paymentConfig?.user ||
          entity?.data?.paymentConfig?.apiUser,
        password:
          entity?.data?.paymentConfig?.password ||
          entity?.data?.paymentConfig?.apiPassword,
        jupiterBasic: entity?.data?.paymentConfig?.jupiterBasic,
        vaultId: entity?.data?.paymentConfig?.vaultId,
      });
    }
  }, [entity]);

  useEffect(() => {
    if (
      authContext?.user?.role === "super-admin" &&
      window.location.pathname.includes("/partners/payments")
    ) {
      findOne(partnerId);
    } else {
      findOne(authContext?.user?._id);
    }
  }, [authContext?.user?._id, partnerId]);
  console.log(entity);

  return (
    <>
      <TimelineHeader name="Payments" parentName="Dashboard" showBtn={true}>
        <Button color="primary" onClick={handleSubmit} size="md" type="submit">
          {isSubmitting ? <Spinner /> : "Save"}
        </Button>
      </TimelineHeader>
      <Container fluid className="mt--6">
        <div className="card p-4 px-6" style={{ minHeight: 400 }}>
          <Row>
            <Col md="12" className="mb-4 text-left">
              <span
                style={{
                  textAlign: "left",
                  fontSize: 20,
                  fontWeight: "bolder",
                  paddingBottom: 20,
                }}
              >
                Jupiter Settings
              </span>
              <div className="mt--3">
                <hr />
              </div>
            </Col>
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Jupiter Api Url
                </Col>
                <Col md="9" className={isMobile && "my-2"}>
                  <Input
                    onChange={handleChange("url")}
                    placeholder="API URL"
                    value={values?.url}
                    className={`form-control ${
                      errors.url && touched.url ? "is-invalid" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  API User
                </Col>
                <Col md="9" className={isMobile && "my-2"}>
                  <Input
                    onChange={handleChange("user")}
                    value={values?.user}
                    placeholder="API User"
                    className={`form-control ${
                      errors.user && touched.user ? "is-invalid" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Password
                </Col>
                <Col md="9" className={isMobile && "my-2"}>
                  <Input
                    onChange={handleChange("password")}
                    placeholder="Password"
                    value={values?.password}
                    className={`form-control ${
                      errors.password && touched.password ? "is-invalid" : ""
                    }`}
                    type="password"
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  Jupiter Basic
                </Col>
                <Col md="9" className={isMobile && "my-2"}>
                  <Input
                    onChange={handleChange("jupiterBasic")}
                    value={values?.jupiterBasic}
                    className={`form-control ${
                      errors.jupiterBasic && touched.jupiterBasic
                        ? "is-invalid"
                        : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                    // textAlign: "right",
                  }}
                >
                  Sandbox Vault ID
                </Col>
                <Col md="9" className={isMobile && "my-2"}>
                  <Input
                    onChange={handleChange("vaultId")}
                    value={values?.vaultId}
                    placeholder={"VGS Vault ID"}
                    className={`form-control ${
                      errors.vaultId && touched.vaultId ? "is-invalid" : ""
                    }`}
                    type="text"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
