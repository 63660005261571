function check_slots_after_selected(slots, selected_slot) {
  const cutOffIdx = slots.findIndex((slt) => slt === "23:45");
  if (cutOffIdx !== -1) {
    const selectedSlotIndex = slots.findIndex((slt) => slt === selected_slot);
    if (selectedSlotIndex > cutOffIdx) {
      return true;
    }
  }
  return false;
}

export default check_slots_after_selected;
