import moment from "moment";

export const TableRowOrderFeeReports = ({ index, data }) => {
  return (
    <tr className="text-left">
      <td>{data?.restaurant.restaurant.name}</td>
      <td>{data?.orderNum}</td>
      <td>{data.transactionId}</td>
      <td>${Number(data?.subTotal).toFixed(2)}</td>
      <td>${Number(data?.total || 0).toFixed(2)}</td>
      <td>${Number(data?.orderFee || 0).toFixed(2)}</td>
      <td>{moment(data?.createdAt).format("MM/DD/YYYY")}</td>
    </tr>
  );
};
