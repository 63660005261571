import { Axiom } from "@axiomhq/js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import { AuthContext } from "../../context";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../store/theme";
import logCustomerSession from "../../utils/axiom-log";

const CardForm = ({
  clientSecret,
  onSuccess,
  stripeCustomerId,
  formik,
  guestFormik,
  guestErrors,
  guestRef,
  guestData,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { activeTab, gateway } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  const { cartItems } = useCartStore();

  const [loading, setLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const handleSubmit = async (e) => {
    try {
      if (loading) {
        return;
      }
      e.preventDefault();

      setLoading(true);

      await logCustomerSession({
        email: authContext?.user?.customer?.email || guestData?.email || "N/A",
        context: "stripepaymentsubmit",
        screen: "checkout",
        message: `sendPaymentDataToStripe-stripe-payment-submit`,
      });

      if (!stripe || !elements) {
        await logCustomerSession({
          email:
            authContext?.user?.customer?.email || guestData?.email || "N/A",
          context: "stripepaymentsubmit",
          screen: "checkout",
          message: `sendPaymentDataToStripe-stripe-payment-submit-failed: no stripe or element ${JSON.stringify(
            stripe,
            elements
          )}`,
        });

        setLoading(false);
        return;
      }

      if (activeTab === "guest") {
        await logCustomerSession({
          email:
            authContext?.user?.customer?.email || guestData?.email || "N/A",
          context: "stripepaymentsubmit",
          screen: "checkout",
          message: `sendPaymentDataToStripe-stripe-payment-submit: guest validation called!`,
        });
        guestFormik();
        if (Object.keys(guestErrors).length !== 0) {
          guestRef.current.scrollIntoView();
          await logCustomerSession({
            email:
              authContext?.user?.customer?.email || guestData?.email || "N/A",
            context: "stripepaymentsubmit",
            screen: "checkout",
            message: `sendPaymentDataToStripe-stripe-payment-submit-failed:guest validation failed ${JSON.stringify(
              guestErrors
            )}`,
          });
          setLoading(false);
          return;
        }
      }

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement("card"),
          },
          save_payment_method: true,
        });

      await logCustomerSession({
        email: authContext?.user?.customer?.email || guestData?.email || "N/A",
        context: "stripepaymentsubmit",
        screen: "checkout",
        message: `sendPaymentDataToStripe-stripe-payment-submit-success ${JSON.stringify(
          paymentIntent
        )}`,
      });

      if (stripeError) {
        toast.error(stripeError.message);
        await logCustomerSession({
          email:
            authContext?.user?.customer?.email || guestData?.email || "N/A",
          context: "stripepaymentsubmit",
          screen: "checkout",
          message: `sendPaymentDataToStripe-stripe-payment-submit-failed ${JSON.stringify(
            stripeError
          )}`,
        });
        setLoading(false);
        return;
      }

      if (activeTab === "guest") {
        await logCustomerSession({
          email:
            authContext?.user?.customer?.email || guestData?.email || "N/A",
          context: "stripepaymentsubmit",
          screen: "checkout",
          message: `sendPaymentDataToStripe-stripe-payment-submit-guest-success ${JSON.stringify(
            paymentIntent
          )}`,
        });

        onSuccess({ ...paymentIntent, saveCard, stripeCustomerId });
      }

      if (paymentIntent.status === "succeeded" && activeTab !== "guest") {
        await logCustomerSession({
          email:
            authContext?.user?.customer?.email || guestData?.email || "N/A",
          context: "stripepaymentsubmit",
          screen: "checkout",
          message: `sendPaymentDataToStripe-stripe-payment-submit-guest-user ${JSON.stringify(
            paymentIntent
          )}`,
        });

        onSuccess({ ...paymentIntent, saveCard, stripeCustomerId });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <form
        id="payment-form"
        style={{ paddingTop: 20, paddingBottom: 20 }}
        onSubmit={handleSubmit}
      >
        <CardElement
          options={{
            hidePostalCode: true,
            disableLink: true,
            style: {
              base: {
                margin: 40,
                paddingBottom: 40,
                fontSize: "18px",
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: "antialiased",
              },
            },
          }}
        />

        {authContext?.user && (
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={(e) => setSaveCard(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Save this card for future checkout
            </label>
          </div>
        )}

        <Button
          block
          disabled={
            loading ||
            formik?.values?.billing?.disableOrdering ||
            cartItems?.length <= 0 ||
            activeTab === "sign-in" ||
            activeTab === "register" ||
            (formik.values.payment_method === "saved-card" &&
              formik.values.selected_card === "") ||
            ((formik.values.payment_method === "new-card" ||
              formik.values.payment_method === "saved-card") &&
              !gateway) ||
            formik.isSubmitting
          }
          className="mt-4"
          style={{
            backgroundColor: theme.primary,
            color: "white",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
          }}
          type="submit"
        >
          {loading ? <Spinner size={"sm"} /> : "Place Order"}
        </Button>
      </form>
    </>
  );
};

export default CardForm;
