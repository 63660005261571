import { useState } from "react";
import { useCookies } from "react-cookie";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Input, Spinner } from "reactstrap";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import Timer from "../../../components/Timer";
import { useEntity } from "../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import logger from "../../../utils/logger";

const OtpVerify = (props) => {
  let history = props.history;

  const location = useLocation();
  const { theme } = useTheme();
  const { restaurant } = useOrderingRestaurant();
  const [otp, setOtp] = useState();
  const [password, setPassword] = useState("");
  const [, setCookie] = useCookies(["secondsLeft"]);

  const { create, loading } = useEntity("eat/auth/verify");
  const { create: resendEmail, loading: resendLoading } =
    useEntity("eat/auth/reset");

  return (
    <OrderingLayout
      isCenter={true}
      loading={loading}
      title="Verify OTP"
      history={history}
      classname="main_ordering_card_wide"
      layout={true}
    >
      <div>
        <div className="text-center forgot_pass_text">
          Please enter 4 digit code sent to your mail.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <OtpInput
            onChange={(otp) => {
              setOtp(otp);
            }}
            numInputs={4}
            separator={<span style={{ marginLeft: 10 }}></span>}
            inputStyle={{
              backgroundColor: "#ededed",
              border: "1px solid #ededed",
              fontSize: 25,
              height: "70px",
              width: "70px",
              color: "black",
              fontWeight: "600",
            }}
            focusStyle={{
              color: "black",
              backgroundColor: "#fff",
              border: `1px solid ${theme?.primary}`,
            }}
            value={otp}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",

            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ textAlign: "left", marginTop: 15, marginBottom: 15 }}>
            New Password
          </span>
          <Form autoComplete="off">
            <Input
              type="password"
              placeholder="Please enter new password"
              style={{ width: 310 }}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
          </Form>
        </div>
        <Timer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            If you don{"'"}t receive the code?{" "}
            <span
              onClick={async () => {
                setCookie("secondsLeft", 30, { path: "/" });
                try {
                  await resendEmail({
                    email: location.state.customer.data.email,
                    restaurant: restaurant?.userId?._id,
                  });
                  toast.success("OTP sent successfully");
                } catch (error) {
                  logger.push({ error, info: "Customer_OTP_VERIFY" });
                  toast.error(error.message);
                }
              }}
              className="ml-1"
              style={{ color: theme?.primary, cursor: "pointer" }}
            >
              Resend{resendLoading && <Spinner size="sm" />}
            </span>
          </div>
        </Timer>

        <div className="mx-4 mt-4 text-center">
          <Button
            disabled={password.length < 4}
            className="px-5"
            style={{ backgroundColor: theme?.primary, color: "white" }}
            onClick={async () => {
              if (otp?.length === 4) {
                try {
                  await create({
                    email: location.state.customer.data.email,
                    restaurant: restaurant?.userId?._id,
                    otp,
                    password,
                  });
                  toast.success("Password changed successfully");

                  let url = new URL(restaurant?.url);

                  history.push(url.pathname + "/sign-in");
                } catch (error) {
                  logger.push({ error, info: "Customer_Otp_Verify" });
                  toast.error(error.message);
                }
              } else {
                toast.error("Please fill otp ");
              }
            }}
          >
            {loading ? <Spinner size="sm" /> : "VERIFY"}{" "}
          </Button>
        </div>
      </div>
    </OrderingLayout>
  );
};

export default OtpVerify;
