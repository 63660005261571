import { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { Col, Form, Input } from "reactstrap";

const MoreInfoForm = ({ subInfo, onSubmit, onChange }) => {
  const [formData, setFormData] = useState({});

  const handleChange = (e, label) => {
    const { type, value, checked, name } = e.target;
    if (type === "checkbox") {
      // If the checkbox has a group name, update the corresponding array value
      if (name) {
        setFormData((prevState) => {
          const groupValues = [...(prevState[name] || [])];

          if (checked) {
            groupValues.push(value);
          } else {
            const index = groupValues.indexOf(value);
            if (index > -1) {
              groupValues.splice(index, 1);
            }
          }
          return { ...prevState, [name]: groupValues };
        });
      } else {
        // If the checkbox does not have a group name, update the value directly
        setFormData((prevState) => ({ ...prevState, [label]: checked }));
      }
    } else {
      // For non-checkbox inputs, update the form data value directly
      setFormData((prevState) => ({ ...prevState, [label]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //   const requiredFields = subInfo.filter(info => info.required);
    // const missingFields = requiredFields.filter(info => !formData[info.label]);
    // if (missingFields.length > 0) {
    //   const errorMessage = `Please fill in the required fields: ${missingFields
    //     .map(info => info.label)
    //     .join(', ')}`;
    //   alert(errorMessage);
    //   return;
    // }

    onSubmit(formData);
  };

  useEffect(() => {
    onChange(formData);
  }, [formData]);

  return (
    <Form onSubmit={handleSubmit}>
      {subInfo.map((info) => (
        <Col md={10} key={info.label} className="my-3">
          <FormLabel style={{ fontWeight: "bold" }}>
            {info.required ? <span className="text-red">*</span> : ""}
            {info.label}
          </FormLabel>
          {info.type === "select" ? (
            <Input
              type="select"
              value={formData[info.label] || ""}
              onChange={(e) => handleChange(e, info.label)}
              required={info.required}
              placeholder={info?.required ? "(Required)*" : "(Optional)*"}
            >
              <option value="">Select</option>
              {info.options.map((option) => (
                <option key={option}>{option}</option>
              ))}
            </Input>
          ) : info.type === "checkbox" ? (
            <>
              {info.options.map((option) => (
                <div key={option} className="mx-4">
                  <Input
                    type="checkbox"
                    id={`${info.label}_${option}`}
                    value={option}
                    checked={
                      Array.isArray(formData[info.label])
                        ? formData[info.label].includes(option)
                        : false
                    }
                    onChange={(e) => {
                      let selectArr = formData?.[info?.label];
                      if (e.target.checked) {
                        if (!selectArr || selectArr === undefined) {
                          setFormData((prevState) => ({
                            ...prevState,
                            [info.label]: [option],
                          }));
                        } else {
                          selectArr.push(option);
                          setFormData((prevState) => ({
                            ...prevState,
                            [info.label]: selectArr,
                          }));
                        }
                      } else {
                        if (selectArr && selectArr !== undefined) {
                          selectArr = selectArr.filter(
                            (item) => item !== option
                          );
                          setFormData((prevState) => ({
                            ...prevState,
                            [info.label]: selectArr,
                          }));
                        }
                      }
                    }}
                    required={info.required}
                    placeholder={info?.required ? "(Required)*" : "(Optional)*"}
                  />
                  <FormLabel htmlFor={`${info.label}_${option}`}>
                    {option}
                  </FormLabel>
                </div>
              ))}
            </>
          ) : (
            <Input
              type="text"
              value={formData[info.label] || ""}
              onChange={(e) => handleChange(e, info.label)}
              required={info.required}
              placeholder={info?.required ? "(Required)*" : "(Optional)*"}
            />
          )}
        </Col>
      ))}
    </Form>
  );
};

export default MoreInfoForm;
