import { useFormik } from "formik";
import { useContext } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import SecureInput from "../../../../components/Inputs/SecureInput";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import DB from "../../../../utils/DB";
import ErrorLable from "../../../../utils/ErrorLable";
import getDaysInMonth from "../../../../utils/getDaysInMonth";
import { DBKeys } from "../../../../utils/Keys";
import logger from "../../../../utils/logger";
import { validationSchemaACustomerRegister } from "../../../../utils/schemas/CustomerRegister";
import SocialComponent from "../../auth/components/SocialComponent";
import ModalHeader from "./ModalHeader";

export default function CreateAccountModal({ open, toggle, theme }) {
  const { create: registerCustomer } = useEntity("eat/auth");
  const authContext = useContext(AuthContext);
  const { create: login } = useEntity("eat/auth/local");

  const { restaurant } = useOrderingRestaurant();

  const months = [
    { month: "January", value: 1, label: "January" },
    { month: "February", value: 2, label: "February" },
    { month: "March", value: 3, label: "March" },
    { month: "April", value: 4, label: "April" },
    { month: "May", value: 5, label: "May" },
    { month: "June", value: 6, label: "June" },
    { month: "July", value: 7, label: "July" },
    { month: "August", value: 8, label: "August" },
    { month: "September", value: 9, label: "September" },
    { month: "October", value: 10, label: "October" },
    { month: "November", value: 11, label: "November" },
    { month: "December", value: 12, label: "December" },
  ];

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      birthday: "",
      password: "",
      loyalityPoints: 0,
      anniversary: "",
      anniversaryMonth: "",
      smsForCouponAndInfo: false,
      emailForCouponAndInfo: true,
      birthMonth: "",
      agreeTermsAndConditions: false,
    },
    validationSchema: validationSchemaACustomerRegister,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        await registerCustomer({
          email: values?.email.toString().toLowerCase(),
          password: values?.password,
          phone: values?.phone,
          name: values?.name,
          role: "customer",
          loyalityPoints: values?.loyalityPoints,
          restaurant: restaurant?.userId?._id,
          birthday: `${
            values?.birthMonth.toString().length === 1
              ? `0${values?.birthMonth.toString()}`
              : values?.birthMonth.toString()
          }/${
            values?.birthday.toString().length === 1
              ? `0${values?.birthday.toString()}`
              : values?.birthday.toString()
          }`,
          anniversary: `${
            values?.anniversary.toString().length === 1
              ? `0${values?.anniversaryMonth.toString()}`
              : values?.anniversaryMonth.toString()
          }/${
            values?.anniversary.toString().length === 1
              ? `0${values?.anniversary.toString()}`
              : values?.anniversary.toString()
          }`,
          status: "active",
          emailForCouponAndInfo: values?.emailForCouponAndInfo,
          smsForCouponAndInfo: values?.smsForCouponAndInfo,
          agreeTermsAndConditions: values?.agreeTermsAndConditions,
          source: "web",
          loyalty: {
            restaurant: restaurant?.userId,
            points: 0,
          },
        });

        const r = await login({
          email: values?.email.toString().toLowerCase(),
          password: values?.password,
          restaurant: restaurant?.userId?._id,
        });
        if (
          (await DB.storeData(DBKeys.CUSTOMER_USER, r?.data?.user)) &&
          (await DB.storeData(DBKeys.CUSTOMER_TOKEN, r?.data?.accessToken))
        ) {
          authContext.login(r?.data);
          toast.success("Registered Successfully");
        }
      } catch (error) {
        logger.push({ error, info: "CreateAccountModal" });
        toast.error(error.message);
      }
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader title="Register" toggle={toggle} />
      {restaurant?.ordering?.socialLogin && (
        <SocialComponent className="mt-3" text="- Or Sign up with -" />
      )}
      <div className="modal-body">
        <Form role="form">
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Name"
                type="text"
                onChange={handleChange("name")}
                value={values?.name}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-user" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {errors?.name && touched.name && (
              <ErrorLable message={errors.name} />
            )}
          </FormGroup>

          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Email"
                type="email"
                onChange={handleChange("email")}
                value={values?.email}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            {errors?.email && touched.email && (
              <ErrorLable message={errors.email} />
            )}
          </FormGroup>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Phone"
                type="number"
                onChange={handleChange("phone")}
                value={values?.phone}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-phone" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>

            {errors?.phone && touched.phone && (
              <ErrorLable message={errors.phone} />
            )}
          </FormGroup>

          <FormGroup>
            <SecureInput
              onChange={(val) => handleChange("password")(val)}
              onBlur={(e) => console.log(e)}
              isValid={touched.password && !errors.password}
              label="Password"
              name="password"
              placeholder="Password"
              className={"signin_input_ordering"}
            />

            {errors?.password && touched.password && (
              <ErrorLable message={errors.password} />
            )}
          </FormGroup>
          <Row className="mt-3 mx-1">
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <span className="mb-2 bold">Birthday </span>{" "}
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Select
                    name="month"
                    isSearchable={false}
                    placeholder="Month"
                    options={months}
                    onChange={(val) => setFieldValue("birthMonth", val?.value)}
                  />
                </div>
                <span className="mx-2"></span>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    name="day"
                    placeholder="Day"
                    options={[
                      ...Array(getDaysInMonth(values.birthMonth)).keys(),
                    ].map((it) => {
                      return {
                        label: it + 1,
                        value: it + 1,
                      };
                    })}
                    onChange={(val) => setFieldValue("birthday", val?.value)}
                  />
                </div>
              </div>
            </div>{" "}
          </Row>
          <Row className="mx-1 mt-3">
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <span className="mb-2 bold">Anniversary </span>{" "}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {" "}
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    name="month"
                    placeholder="Month"
                    options={months}
                    onChange={(val) =>
                      setFieldValue("anniversaryMonth", val?.value)
                    }
                  />
                </div>
                <span className="mx-2"></span>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    name="day"
                    placeholder="Day"
                    options={[
                      ...Array(getDaysInMonth(values.anniversaryMonth)).keys(),
                    ].map((it) => {
                      return {
                        label: it + 1,
                        value: it + 1,
                      };
                    })}
                    onChange={(val) => setFieldValue("anniversary", val?.value)}
                  />
                </div>
              </div>
            </div>
          </Row>
          <div
            className="custom-control custom-checkbox mt-3"
            style={{ zIndex: 0 }}
          >
            <input
              className="custom-control-input"
              id="customCheck4"
              type="checkbox"
              onChange={handleChange("smsForCouponAndInfo")}
              value={values.smsForCouponAndInfo}
            />
            <label
              className="custom-control-label"
              style={{ marginTop: 3 }}
              htmlFor="customCheck4"
            >
              Sign up for SMS coupons and info
            </label>
          </div>
          <div
            style={{ zIndex: 0 }}
            className="custom-control custom-checkbox mb-3 mt-3"
          >
            <input
              className="custom-control-input"
              id="customeCheck3"
              type="checkbox"
              onChange={handleChange("agreeTermsAndConditions")}
              value={values?.agreeTermsAndConditions}
            />
            <label className="custom-control-label" htmlFor="customeCheck3">
              I agree to terms and conditions
            </label>
          </div>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Button
                disabled={!values?.agreeTermsAndConditions}
                onClick={handleSubmit}
                style={{
                  width: "100%",
                  backgroundColor: theme.primary,
                  color: "#fff",
                }}
              >
                {isSubmitting ? <Spinner size="sm" /> : "Register"}
              </Button>
            </InputGroup>
          </FormGroup>
        </Form>
      </div>
    </Modal>
  );
}
