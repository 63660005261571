import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import ConfirmModal from "../../../components/ConfirmModal";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import SuperTable from "../../../widgets/SuperTable";
import TableWrapper from "../../../widgets/TableWrapper";
import { CategoryBulkRow } from "./components/CategoryBulkRow";
import { RowContext } from "./components/categoryBulkRowContext";

function CategoriesBulk(props) {
  const [rowContext, setRowContext] = useState({});
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [open, setOpen] = useState(false);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };
  const { create, loading: isUpdating } = useEntity("item/bulk");
  const { find, entities, loading: isLoading } = useEntity("item");
  const { find: findCat, entities: entitiesCat } = useEntity("category");

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      page,
      limit: rowsPerPage,
      sorting,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findCat({
      restaurant: authRest || restaurant,
      page,
      limit: 1000,
      sorting,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
  }, [page, rowsPerPage, authRest, sorting]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Name", "Category"];

  const items = entities?.data?.data;

  const handleChange = (item, catOpt, removed) => {
    setRowContext((state) => {
      const itemCat = JSON.parse(JSON.stringify(state.itemCat));
      const catFound = itemCat[item._id].findIndex(
        (it) => it.value === catOpt.value
      );
      if (removed && catFound !== -1) {
        itemCat[item._id].splice(catFound, 1);
        return {
          ...state,
          itemCat,
        };
      } else if (catFound === -1) {
        itemCat[item._id].push(catOpt);
        return {
          ...state,
          itemCat,
        };
      }
      return state;
    });
  };

  useEffect(() => {
    if (entitiesCat?.data?.data && items) {
      setRowContext({
        onChange: handleChange,
        categories: (entitiesCat?.data?.data || []).map((it) => ({
          label: it.categoryName,
          value: it._id,
        })),
        itemCat: items.reduce((pv, cv) => {
          if (!pv[cv._id]) pv[cv._id] = [];
          pv[cv._id].push(
            ...cv.categories.map((cat) => ({
              label: cat.categoryName,
              value: cat._id,
            }))
          );
          return pv;
        }, {}),
        init: true,
      });
    }
  }, [entitiesCat, items]);

  const handleSave = useCallback(() => {
    if (Object.values(rowContext.itemCat).length > 0) {
      create(rowContext.itemCat)
        .then(() => {
          toast("Successful saved");
        })
        .catch(() => {});
    }
  }, [rowContext]);

  return (
    <>
      <ConfirmModal open={open} toggle={() => setOpen(!open)} />
      <SimpleHeader
        path="/resturants"
        name="Bulk Category Assign"
        parentName="Dashboard"
        showBtn={true}
      >
        {!restaurantDoc?.restaurant?.enable_deliverect && (
          <Button
            onClick={() => {
              handleSave();
            }}
            color="primary"
            size="md"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {isUpdating ? (
              <center>
                <Spinner size="sm" color="primary" type="border" />
              </center>
            ) : (
              "Save"
            )}
          </Button>
        )}
      </SimpleHeader>
      <RowContext.Provider value={rowContext}>
        <TableWrapper>
          {!isLoading ? (
            <SuperTable
              sort={handleSort}
              showCheckboxes={false}
              data={items}
              onPageChange={handlePageChange}
              OnRowPerPageChange={handleRowsPerPageChange}
              total={entities?.data?.length}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              loading={isLoading}
              history={props.history}
              row={CategoryBulkRow}
              columns={Headers}
              isLoading={isLoading}
              emptyMessage="Currently no Items"
              prevPage={entities?.data?.prevPage}
              nextPage={entities?.data?.nextPage}
              currentPage={entities?.data?.currentPage}
            />
          ) : (
            <div
              style={{
                padding: 20,
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center>
                <Spinner size="lg" color="primary" type="border" />
              </center>
            </div>
          )}
        </TableWrapper>
      </RowContext.Provider>
    </>
  );
}

export default CategoriesBulk;
