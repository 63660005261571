import { useContext, useEffect, useState } from "react"
import { Button, Input, Modal, Spinner } from "reactstrap"
import ImageUploader from "react-images-upload"
import { useEntity } from "../../../../hooks/useEntity"
import Select from "react-select"
import { useUploadImage } from "../../../../hooks/useUploadImage"
import { toast } from "react-toastify"
import { useAdminStore } from "../../../../store/admin"
import { AuthContext } from "../../../../context"

const CreateCategoryModal = ({
  openModal,
  toggle,
  activeItem,
  edit = false,
}) => {
  const [name, setName] = useState("")
  const [restaurants, setRestaurants] = useState([])
  const [image, setImage] = useState("")
  const [sort, setSort] = useState()
  const { entities, find } = useEntity("restaurant")
  const { upload } = useUploadImage()
  const { create, updateEntity } = useEntity("restaurant-categories")
  const [loading, setLoading] = useState(false)
  const { partner } = useAdminStore()
  const authContext = useContext(AuthContext)
  const [newImage, setNewImage] = useState()

  useEffect(() => {
    find({ partner: partner || authContext?.user?._id, status: "active" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    setLoading(true)

    if (!name) {
      toast.error("Please fill required details")
      setLoading(false)
      return
    }

    if (!image) {
      toast.error("Please choose image to upload")
      setLoading(false)
      return
    }

    if (restaurants?.length <= 0) {
      toast.error("Please select atlease one restaurant")
      setLoading(false)
      return
    }

    let imagePath = ""

    if (image && typeof image !== "string") {
      imagePath = await upload({
        file: image,
        owner: "restaurant",
      })
    }

    const data = {
      name,
      restaurants: restaurants?.map((rest) => rest?.value),
      image: typeof image !== "string" ? imagePath : image,
      partner: partner || authContext?.user?._id,
      sort: sort || 0,
    }

    try {
      if (!edit && !activeItem) {
        await create({ ...data })
        toast.success("Category created successfully")
      } else {
        await updateEntity(activeItem?._id, { ...data })
        toast.success("Category updated successfully")
      }
      setImage("")
      setName("")
      setRestaurants([])
      toggle()
    } catch (error) {
      toast.error(error.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (edit && activeItem) {
      setName(activeItem?.name)
      setRestaurants(
        activeItem?.restaurants?.map((op) => {
          return { label: op?.restaurant?.name, value: op?._id }
        })
      )
      setImage(activeItem?.image)
      setSort(activeItem?.sort)
    }
  }, [edit, activeItem, openModal])

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        isOpen={openModal}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Create Category
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            className="mt-3"
            placeholder="Sort order"
            type="number"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          />
          <div className="mt-3">
            <ImageUploader
              withIcon={true}
              buttonText="Choose category image"
              fileContainerStyle={{
                backgroundColor: "#efefef",
              }}
              singleImage
              maxFileSize={10002880}
              imgExtension={[".jpg", ".gif", ".png", ".gif", "webp", "jpeg"]}
              withPreview
              onChange={(f) => {
                setImage(f[0])
                setNewImage(f[0])
              }}
              defaultImage={image}
            />
          </div>
          {!newImage && image !== "" && (
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <img
                alt="a"
                style={{
                  height: 180,
                  width: 180,
                  borderRadius: 15,
                  marginTop: 5,
                }}
                src={image}
              />
            </div>
          )}
          <div>
            <Select
              name="restaurants"
              isSearchable={false}
              onChange={(val) => {
                setRestaurants(val)
              }}
              isMulti
              closeMenuOnSelect={false}
              value={restaurants}
              options={entities?.data
                ?.filter((fil) => fil?.showInPartnerApp)
                ?.map((rest) => {
                  return {
                    label: rest?.name,
                    value: rest?.userId,
                  }
                })}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            Close
          </Button>
          <Button onClick={() => handleSubmit()} color="primary" type="button">
            {loading ? <Spinner size="sm" /> : "Save changes"}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default CreateCategoryModal
