import { useFormik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";

export default function GroupCatModal({
  isVisible,
  toggle,
  onClose,
  data,
  saveItem,
  restaurant,
  restaurantDoc,
  method,
  location,
  groupCategories,
}) {
  const [selectedGroupcat, setSelectedGroupcat] = useState([]);

  const authContext = useContext(AuthContext);

  const { values, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        _id: "",
        categories: [],
        name: "",
        status: "active",
        method: "",
        location: "",
        sort: "",
      },
      onSubmit: async () => {
        try {
          await saveItem(values);
          toggle();
        } catch (error) {
          console.log(error);
          toast.error(error.message);
        }
      },
    });

  useEffect(() => {
    if (selectedGroupcat) {
      const data = groupCategories?.find(
        (t) => t.value === selectedGroupcat?.value
      );

      setValues({
        _id: data?.value,
        name: data?.label,
        status: data?.status,
        categories: data?.categories,
        sort: data?.sort,
      });
    }
  }, [selectedGroupcat]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isVisible}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <h1 className="display-4">Category Groups</h1>
          </CardHeader>
          <CardBody className="">
            <Row>
              <Col md="12">
                <FormGroup className="text-left">
                  <label className="text-left">Select Group Category</label>
                  <Select
                    onChange={(val) => {
                      setSelectedGroupcat(val);
                    }}
                    name="items"
                    isSearchable={true}
                    options={groupCategories}
                    value={selectedGroupcat}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <label className="text-left">Status</label>
              </Col>
              <Col md="6">
                <label className="custom-toggle">
                  <input
                    checked={values.status === "active"}
                    type="checkbox"
                    onClick={async () => {
                      setFieldValue(
                        "status",
                        values.status == "active" ? "inactive" : "active"
                      );
                    }}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="Off"
                    data-label-on="On"
                  />
                </label>
              </Col>
              <Col md="1"></Col>
              <Col
                md="5"
                style={{
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Button
                  style={{
                    borderWidth: 1,
                    borderColor: "#5e72e4",
                    color: "#5e72e4",
                  }}
                  onClick={() => {
                    toggle();
                  }}
                  type="button"
                >
                  Cancel
                </Button>

                <Button onClick={handleSubmit} color="primary" type="button">
                  Save Changes
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup className="text-left">
                  <label className="text-left">Title</label>
                  <Input
                    onChange={handleChange}
                    type="text"
                    name="name"
                    value={values.name}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="text-left">
                  <label className="text-left">Sort Order</label>
                  <Input
                    id="sort"
                    type="text"
                    onChange={handleChange("sort")}
                    value={values?.sort}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
