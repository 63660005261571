/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import MobileNavMenu from "../../../components/Navbar/MobileNavMenu";
import Notification from "../../../components/Popups/Notification";
import { useEntity } from "../../../hooks/useEntity";
import useScreenSize from "../../../hooks/useScreenSize";
import "../../../screens/ordering/styles/Menu.css";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import MenuMainSections from "./components/MenuMainSections";
import MenuSidebar from "./components/MenuSidebar";

const Menu = (props) => {
  const screenSize = useScreenSize();
  const [cat, setCat] = useState(null);
  const { theme } = useTheme();
  const [catName, setCatName] = useState();
  const { restaurant } = useOrderingRestaurant();
  const [menuProductfilter, setMenuProductfilter] = useState([]);
  const { laterDate, laterTime, cateringData, setMenuId } = useOrdering();

  // const {
  //   find: findGroupCategories,
  //   entities: groupCatsData,
  //   loading: isLoadingGroupCats,
  // } = useEntity("eat/ordering/group-categories");

  const {
    find,
    entities,
    loading: isLoading,
  } = useEntity("eat/ordering/ordering-menu");

  const [homeModal, setHomeModal] = useState(false);
  const { method, location, setOrderingFee, timing, setGateway } =
    useOrdering();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobileSearch, setIsMobileSearch] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchStringDesktop, setSearchStringDesktop] = useState("");
  const [selectedGroupCategories, setSelectedGroupCategories] = useState(null);

  useEffect(() => {
    const targetElement = document.getElementById("main_menu_container");

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, []);

  useEffect(() => {
    let dateString;
    if (timing === "later") {
      dateString = new Date(laterDate);
      const splittedTime = laterTime.split(":");
      dateString.setHours(splittedTime[0], splittedTime[1]);
    }

    find({
      restaurant: restaurant?.userId?._id,
      location: location?._id,
      method: method,
      schedule: timing === "later" ? dateString : undefined,
      filterDSP: location?.connectedToStream ? "true" : "false",
      catering: cateringData?.catering ? "true" : "false",
      groupCategory: selectedGroupCategories,
      isMobile: "false",
    });
  }, [selectedGroupCategories]);

  function handleChangeSearchProduct(searchText) {
    const items = entities?.data?.categories?.flatMap((c) => c.items);
    if (screenSize.width < 750) {
      return;
    }

    if (searchText.trim() === "") {
      setCatName({
        name: entities?.data?.categories[0]?.categoryName,
        description: entities?.data?.categories[0]?.description,
      });
      setMenuProductfilter(items);
      return;
    }

    searchText = searchText.toLowerCase();

    const results = items
      ?.filter((item) => {
        const nameMatch = item?.name.toLowerCase().includes(searchText);
        const descriptionMatch =
          item?.description &&
          item?.description.toLowerCase().includes(searchText);

        return nameMatch || descriptionMatch;
      })
      .filter(
        (function () {
          const seen = new Set();
          return function (item) {
            if (!seen.has(item._id)) {
              seen.add(item._id);
              return true;
            }
            return false;
          };
        })()
      );

    setMenuProductfilter(results);
    setCatName({ name: "Search Results" });

    return results;
  }

  useEffect(() => {
    setHomeModal(true);
  }, [restaurant]);

  useEffect(() => {
    if (restaurant?.restaurant?.orderFee === "Fixed") {
      setOrderingFee(Number(restaurant?.fixedValue));
    }
  }, [restaurant]);

  useEffect(() => {
    console.log("SET MENU ID", entities?.data?._id);
    setMenuId(entities?.data?._id);
    setCat(entities?.data?.categories?.[0]?._id);
    setCatName({
      name: entities?.data?.categories?.[0]?.categoryName,
      description: entities?.data?.categories?.[0]?.description,
    });
  }, [selectedGroupCategories, entities]);
  // useEffect(() => {
  //   if (Object.keys(restaurant || {}).length === 0) {
  //     const url = window.location.href?.replace(/\/menu$/, "");
  //     let hostUrl = new URL(url);
  //     props.history.push(hostUrl.pathname);
  //     return;
  //   }
  //   if (
  //     (!location || !method || !timing) &&
  //     Object.keys(restaurant).length > 0
  //   ) {
  //     let url = new URL(restaurant?.url);
  //     props.history.push(url.pathname);
  //   }
  // }, []);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setIsMobileSearch(event.detail.mobileSearch);
    };

    document.addEventListener("mobileSeachEnabled", handleCustomEvent);

    return () => {
      document.removeEventListener("mobileSeachEnabled", handleCustomEvent);
    };
  }, []);

  const { findOne, entity } = useEntity("eat/ordering/gateway");

  useEffect(() => {
    setGateway(entity?.data);
  }, [entity]);

  useEffect(() => {
    findOne(location?._id);
  }, [location]);

  useEffect(() => {
    if (screenSize.width > 750) {
      const items = entities?.data?.categories?.find(
        (c) => c._id === cat
      )?.items;

      setMenuProductfilter(items);
    }
  }, [entities, screenSize, cat]);

  if (Object.keys(restaurant || {}).length === 0) {
    return <></>;
  }

  return (
    <RestaurantWrapper>
      <div
        id="main_menu_container"
        style={{ maxWidth: "1440px", margin: "auto" }}
      >
        <div
          className="mobile_top_nav_menu"
          style={{ marginBottom: isMobileSearch ? 140 : 60 }}
        >
          <MobileNavMenu
            searchString={(val) => {
              setSearchString(val);
            }}
            history={props.history}
            setState={(val) => setSidebarOpen(val)}
            searchValues={searchString}
            hideBack={false}
            menu={true}
          />
        </div>
        <div className="main_menu_screen_wrapper">
          <div style={{ flex: 1, borderRight: "1px solid #ededed" }}>
            <MenuSidebar
              data={entities?.data?.categories}
              onClick={(val) => setCat(val)}
              hasExtraCats={entities?.data?.hasExtraCats}
              catName={(val) => {
                setCatName(val);
              }}
              catSelected={catName}
              searchString={(val) => {
                setSearchStringDesktop(val);
                handleChangeSearchProduct(val);
              }}
              groupCategories={entities?.data?.groupCategories || []}
              onGroupCategoryChange={(id) => {
                setSelectedGroupCategories(id);
              }}
              selectedGroupCategories={selectedGroupCategories || null}
              sidebarOpen={sidebarOpen}
              history={props.history}
              categories={entities?.data?.categories}
            />
          </div>

          <div
            style={{
              flex: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
            }}
          >
            <MenuMainSections
              sidebarOpen={sidebarOpen}
              history={props.history}
              data={menuProductfilter?.filter((t) => t.status)}
              catName={(val) => {
                setCatName(val);
              }}
              searchText={searchString}
              searchTextDesktop={searchStringDesktop}
              onClick={(val) => setCat(val)}
              catSelected={catName}
              groupCategories={entities?.data?.groupCategories || []}
              onGroupCategoryChange={(id) => {
                setSelectedGroupCategories(id);
              }}
              hasExtraCats={entities?.data?.hasExtraCats}
              selectedGroupCategories={selectedGroupCategories}
              isLoading={isLoading}
              total={
                entities?.data?.categories?.find(
                  (catDoc) => catDoc?._id === cat
                )?.items?.length || 0
              }
              allData={entities?.data?.categories || []}
              allItems={
                entities?.data?.data?.flatMap((catDoc) => catDoc.items) || []
              }
              categories={entities?.data?.categories || []}
            />
          </div>
        </div>

        {restaurant?.restaurant?.pop_up_alerts?.category_popup
          ?.category_popup && (
          <Notification
            popupTitle={restaurant?.pop_up_alerts?.category_popup?.title}
            popupHeading={restaurant?.pop_up_alerts?.category_popup?.title}
            popupDescription={
              restaurant?.pop_up_alerts?.category_popup?.message
            }
            toggle={() => setHomeModal(!homeModal)}
            open={homeModal}
            origin="home"
            theme={theme}
          />
        )}
      </div>
    </RestaurantWrapper>
  );
};

export default Menu;
