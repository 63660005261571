import create from "zustand";
import { persist } from "zustand/middleware";

const url = window.location.href.replace(/(\/ordering\/\w+).*/, "$1");

const useOrdering = create(
  persist(
    (set, get) => ({
      tip: {
        amount: 0,
        percentage: 0,
        other: false,
      },

      method: "",
      location: undefined,
      timing: "",
      laterDate: "",
      dineIn: {},
      menuId: "",
      tableNo: {},
      deliveryConfirmAddress: {},
      laterTime: "",
      deliveryConfirmAddressC: {},
      orderingFee: 0,
      deliveryFee: 0,
      gateway: null,
      paymentMethod: "pay-there",
      walkupURL: "",
      totalLocation: null,
      skippedLocation: false,
      lastAlertContent: {
        home: "",
        menu: "",
        checkout: "",
      },
      deliveryZone: "",
      deliveryMethod: "",
      cateringData: {},
      activeTab: "",
      mobileSearch: false,
      setDeliveryZone: (zone) => set({ deliveryZone: zone }),
      setMenuId: (id) => set({ menuId: id }),
      setCateringData: (cateringData) => set({ cateringData: cateringData }),
      setDeliveryFee: (fee) => set({ deliveryFee: fee }),
      setMobileSearch: (mobileSearch) => set({ mobileSearch }),
      setDeliveryMethod: (method) => set({ deliveryMethod: method }),
      setWalkupURL: (url) => set({ walkupURL: url }),
      setPaymentMethod: (date) => set({ paymentMethod: date }),
      setSkippedLocation: (data) => set({ skippedLocation: data }),
      setMethod: (method) => set({ method: method }),
      setLocation: (location) => set({ location: location }),
      setTotalLocation: (number) => set({ totalLocation: number }),
      setTiming: (timing) => set({ timing: timing }),
      setLaterDate: (laterDetails) => set({ laterDate: laterDetails }),
      setLaterTime: (laterDetails) => set({ laterTime: laterDetails }),
      setDineIn: (dine) => set({ dineIn: dine }),
      setTableNo: (table) => set({ tableNo: table }),
      setDeliveryAddressC: (address) =>
        set({ deliveryConfirmAddressC: address }),
      setConfirmDeliveryAddress: (address) =>
        set({ deliveryConfirmAddress: address }),
      setOrderingFee: (orderingFee) => set({ orderingFee: orderingFee }),
      setGateway: (data) => set({ gateway: data }),
      setLastAlertContent: (alert, lastAlertContent) =>
        set({ lastAlertContent: { ...lastAlertContent, ...alert } }),
      setTip: (tipped) =>
        set({
          tip: {
            ...tipped,
          },
        }),
      clearTip: () =>
        set({
          tip: {
            amount: 0,
            percentage: 0,
            other: false,
          },
        }),
      setActiveTab: (data) => set({ activeTab: data }),
    }),
    {
      name: "ORDERING:" + url,
      getStorage: () => localStorage,
    }
  )
);

export default useOrdering;
