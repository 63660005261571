import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function ConfirmModal({ open, toggle }) {
  return (
    <Modal className="modal-dialog fade" isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody>Are you sure you want to proceed?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Continue
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
