// nodejs library that concatenates classes
import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
// react library for routing
import { useLocation } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import apiCaller from "../../api/apiCaller";
// core components
import { isMobile } from "react-device-detect";
import Logo from "../../assets/LogoV2.svg";
import AuthHeader from "../../components/Header/AuthHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import DB from "../../utils/DB";
import { DBKeys } from "../../utils/Keys";
import { isAdminUrl } from "../../utils/isAdminUrl";
import logger from "../../utils/logger";
import log from "../../utils/rollbar/log";
import RollbarConstants from "../../utils/rollbar/rollbar_constant";

const MyOrderingInput = ({
  focusedState,
  icon,
  type,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  value,
}) => {
  return (
    <FormGroup
      className={classnames("mb-3", {
        focused: focusedState,
      })}
    >
      <InputGroup className="input-group-merge input-group-alternative">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{icon}</InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder={placeholder}
          type={type}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      </InputGroup>
    </FormGroup>
  );
};

const MyOrderingPrimaryButton = ({ loading, title, onClick }) => {
  return (
    <Button block className="my-4" color="info" type="button" onClick={onClick}>
      {loading ? <Spinner size="sm" color="white" /> : title}
    </Button>
  );
};

function Login({ history, partner }) {
  const { create: login } = useEntity("auth/local");
  // const { find: getMe } = useEntity("auth/me");
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const mainContentRef = React.useRef(null);
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { find, entities } = useEntity("auth/partner");
  const urls = window.location.href.split("/")[2];

  useEffect(() => {
    find({ host: urls });
  }, [urls, window]);

  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    DB.retrieveData(DBKeys.USER)
      .then((res) => {
        authContext.login({ user: res });
        redirectToDashboard(res);
      })
      .catch((err) => {});
  }, []);

  const redirectToDashboard = (user) => {
    if (user?.role === "super-admin") {
      history.push("/admin");
    } else if (user?.role === "partner") {
      history.push("/partners");
    } else if (user?.role === "restaurant" && !isMobile) {
      history.push("/resturants");
    } else if (user.role === "restaurant" && isMobile) {
      history.push("/resturants/items");
    } else {
      history.push("/login");
    }
  };

  const handleLogin = async () => {
    setLoading(true);

    try {
      const res = await login({
        email,
        password,
        url: window.location.href.split("/")[3],
      });
      if (
        (await DB.storeData(DBKeys.USER, res?.data?.user)) &&
        (await DB.storeData(DBKeys.TOKEN, res?.data?.accessToken))
      ) {
        redirectToDashboard(res?.data?.user);
        authContext.login(res?.data);
      }
    } catch (error) {
      logger.push({ error, info: "Login" });
      swal(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginByToken = async (token) => {
    if (!token) return;

    setLoading(true);
    await DB.storeData(DBKeys.TOKEN, token);
    try {
      const res = await apiCaller("/auth/me", {
        headers: { token },
        auth: false,
        method: "GET",
      });
      if (res?.data?._id) {
        await DB.storeData(DBKeys.USER, res?.data);
        redirectToDashboard(res?.data);
        authContext.login({ user: res?.data });
      }
    } catch (error) {
      logger.push({ error, info: "Login" });
      swal(error.message);
    } finally {
      setLoading(false);
    }
  };

  // React.useEffect(() => {
  //   document.body.classList.add("bg-default");
  //   return function cleanup() {
  //     document.body.classList.remove("bg-default");
  //   };
  // });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  React.useEffect(() => {
    if (searchParams.get("error")) {
      swal(searchParams.get("error"));
    } else if (searchParams.get("idt")) {
      handleLoginByToken(searchParams.get("idt"));
    }
  }, [searchParams]);

  useEffect(() => {
    log("TEST", RollbarConstants.info);
  }, []);

  return (
    <>
      <div ref={mainContentRef}>
        <div
          className="mt-4 mx-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button size="sm">Contact</Button>
          </div>
        </div>

        <div
          className="d-flex"
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Container
            style={{
              maxWidth: "448px",
            }}
          >
            <AuthHeader title="Welcome Back" lead="" />
            <Card
              style={{ boxShadow: "0px 1px 3px 0px rgba(50, 50, 93, 0.15)" }}
            >
              <CardBody>
                <div>
                  {entities?.data?.headerLogo && !isAdminUrl() && (
                    <img
                      style={{
                        maxHeight: 200,
                        maxWidth: 200,
                        objectFit: "contain",
                      }}
                      alt="partner_logo"
                      src={entities?.data?.headerLogo}
                    />
                  )}
                  {isAdminUrl() && (
                    <img
                      className="mb-3"
                      style={{
                        maxHeight: 200,
                        maxWidth: 200,
                        objectFit: "contain",
                      }}
                      alt="partner_logo"
                      src={Logo}
                    />
                  )}
                </div>
                <div className="text-center text-muted mb-4">
                  <small>Please Sign In To Continue.</small>
                </div>
                <Form role="form">
                  <MyOrderingInput
                    placeholder="Email"
                    type="email"
                    onFocus={() => setfocusedEmail(true)}
                    onBlur={() => setfocusedEmail(true)}
                    onChange={(val) => setEmail(val)}
                    focusedState={focusedEmail}
                    value={email}
                    icon={<i className="ni ni-email-83" />}
                  />
                  <MyOrderingInput
                    placeholder="Password"
                    type="password"
                    onFocus={() => setfocusedPassword(true)}
                    onBlur={() => setfocusedPassword(true)}
                    onChange={(val) => setPassword(val)}
                    focusedState={focusedPassword}
                    value={password}
                    icon={<i className="ni ni-lock-circle-open" />}
                  />

                  <div className="text-center">
                    <MyOrderingPrimaryButton
                      onClick={handleLogin}
                      title="Sign in"
                      loading={loading}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
            <div
              onClick={() => {
                history.push("/forget-password");
              }}
              className="text-left mt-3 pointer"
            >
              <small>Forgot password?</small>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Login;
