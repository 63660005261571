// reactstrap components
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { useRestaurantStore } from "../../../store/restaurant";
import SuperTableDnd from "../../../widgets/SuperTableDnd";
import TableWrapper from "../../../widgets/TableWrapper";
import { GroupRow } from "./components/GroupRow";

export default function GroupCategories(props) {
  const [tabs, setTabs] = useState(1);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { restaurant } = useAdminStore();
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const [searchQuery, setSearchQuery] = useState("");
  const { create: updateSortOrder } = useEntity("group-category/sort-order");

  const { find, entities, loading: isLoading } = useEntity("group-category");

  useEffect(() => {
    find({
      searchQuery,
      restaurant: authRest || restaurant,
      page,
      limit: rowsPerPage,
      sorting,
      status: tabs === 1 ? "active" : "inactive",
    });
  }, [page, rowsPerPage, sorting, searchQuery, tabs]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Name", "Sort", "Categories", "Status", "Action"];

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {
    if (entities?.data?.data) {
      setData(entities?.data?.data);
    }
  }, [entities?.data?.data]);

  const handleSaveSortOrder = async () => {
    const IdsAndPos = data.map((d, idx) => {
      const obj = { _id: d._id, sortOrder: idx };
      return obj;
    });

    try {
      await updateSortOrder({ sortOrders: IdsAndPos });
      toast.success("Sort order updated");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }

    setIsSortOrderChanged(false);
  };

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setData([...items]);
    setIsSortOrderChanged(true);
  };

  return (
    <>
      <TimelineHeader
        parentPath="/resturants"
        name="Group Category"
        parentName="Dashboard"
        showBtn={true}
        showSearch={true}
        placeholder="Search"
        getSearchQuery={(val) => setSearchQuery(val)}
        path="/resturants"
      >
        <>
          {!restaurantDoc?.restaurant?.enable_deliverect && (
            <Button
              color="primary"
              type="button"
              size="md"
              onClick={() => {
                props.history.push({
                  pathname: "/resturants/add-group-category",
                  search: props.location.search,
                });
              }}
              disabled={restaurantDoc?.restaurant?.enableDeliverect}
            >
              Add new
            </Button>
          )}
        </>
      </TimelineHeader>

      <TableWrapper customStyles="px-4 py-3">
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Active
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Inactive
            </NavLink>
          </NavItem>
        </Nav>
        {isSortOrderChanged && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
            }}
            className="mr-3 mt-3"
          >
            <Button
              onClick={handleSaveSortOrder}
              outline
              color="warning"
              size="sm"
            >
              Save
            </Button>
          </div>
        )}
        <TabContent className="mt-3" activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            {!isLoading ? (
              <SuperTableDnd
                sort={handleSort}
                showCheckboxes={false}
                data={data}
                onPageChange={handlePageChange}
                OnRowPerPageChange={handleRowsPerPageChange}
                total={data?.length}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                loading={isLoading}
                history={props.history}
                row={GroupRow}
                columns={Headers}
                isLoading={isLoading}
                handleDragEnd={handleDragEnd}
                prevPage={data?.prevPage}
                nextPage={data?.nextPage}
                currentPage={data?.currentPage}
                emptyMessage="Currently no Group Categories "
              />
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
          </TabPane>
          <TabPane tabId="tabs2" style={{ textAlign: "left" }}>
            {!isLoading ? (
              <SuperTableDnd
                sort={handleSort}
                showCheckboxes={false}
                handleDragEnd={handleDragEnd}
                data={data}
                onPageChange={handlePageChange}
                OnRowPerPageChange={handleRowsPerPageChange}
                total={data?.length}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                loading={isLoading}
                history={props.history}
                row={GroupRow}
                columns={Headers}
                isLoading={isLoading}
                prevPage={data?.prevPage}
                nextPage={data?.nextPage}
                currentPage={data?.currentPage}
                emptyMessage="Currently no Categories "
              />
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "60vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
          </TabPane>
        </TabContent>
      </TableWrapper>
    </>
  );
}
