import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import apiCaller from "../../../../api/apiCaller";
import Select from "react-select";

export default function AddNewItemModal({
  isVisible,
  toggle,
  onClose,
  data,
  saveItem,
  method,
}) {
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      items: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        values.items.forEach((element) => {
          const itm = data.find((d) => d._id === element.value);
          const item = {
            _id: itm._id,
            streamId: itm.streamId,
            jkSoftId: itm.jkSoftId,
            name: itm.name,
            imageUrl: itm.imageUrl,
            description: itm.description,
            price: Number(itm?.price || 0),
            isAvailableDaily: itm?.isAvailableDaily || false,
            availability: itm?.availability || [],
            plu: itm.plu,
            sort: 0,
            status: false,
            tag:
              itm?.tag?.map((t) => {
                return { _id: t._id, name: t.name };
              }) || [],
            modifiers: itm.modifiers,
          };
          saveItem(item);
          resetForm();
        });
        toggle();
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isVisible}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <h1 className="display-4">Add Items</h1>
          </CardHeader>
          <CardBody className="">
            <Row>
              <Col md="12" className="my-2">
                <FormGroup className="text-left">
                  <label className="text-left">Select Items</label>
                  <Select
                    onChange={(val) => setFieldValue("items", val)}
                    isMulti
                    name="items"
                    isSearchable={true}
                    options={data
                      .filter((t) => {
                        if (method === "catering" && !t.catering) {
                          return false;
                        }
                        return true;
                      })
                      ?.map((loca) => {
                        return {
                          label: loca?.name,
                          value: loca?._id,
                        };
                      })
                      .filter((t) => t)}
                    value={values.items}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-4"
                color="primary"
                type="button"
                onClick={handleSubmit}
                style={{ width: 150 }}
              >
                Add
              </Button>

              <Button
                onClick={onClose}
                style={{ width: 150 }}
                className="mt-4"
                color="warning"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
