import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const GuestValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "should be more than 2 character")
    .required("Guest name is required"),
  email: Yup.string().email()?.required("Guest email is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required")
    .min(10, "cannot be less than 10")
    .max(10, "cannot be more than 10"),
});

export const GuestValidationWalkup = Yup.object().shape({
  name: Yup.string()
    .min(2, "should be more than 2 character")
    .required("Guest name is required"),
});
