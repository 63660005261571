import { useEffect } from "react";
import { useCookies } from "react-cookie";

const Timer = ({ children }) => {
  const [cookies, setCookie] = useCookies(["secondsLeft"]);

  useEffect(() => {
    if (!cookies.secondsLeft) {
      setCookie("secondsLeft", 30, { path: "/" });
    }
    let intervalId;
    if (cookies.secondsLeft > 0) {
      intervalId = setInterval(() => {
        setCookie("secondsLeft", cookies.secondsLeft - 1, { path: "/" });
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [cookies, setCookie]);

  if (cookies.secondsLeft <= 0) {
    return children;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      Resend in {cookies.secondsLeft} seconds
    </div>
  );
};

export default Timer;
