import { Badge } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const TableRowPushNotification = ({ data, history }) => {
  const { deleteEntity } = useEntity("push-notification");

  const location = useLocation();

  return (
    <tr className="text-left">
      <td>
        {data?.restaurant?.map((rest) => {
          return <>{rest.restaurant.name}, </>;
        })}
      </td>
      <td>
        {data?.audience === "no-order-14-days"
          ? "Send to user who have not ordered from last 14 days"
          : data?.audience === "no-order-21-days"
          ? "Send to user who have not ordered from last 14 days"
          : "Send to everyone"}
      </td>
      <td>{data?.sendLater ? "Later" : "Now"}</td>
      <td>
        {data?.status === "scheduled" ? (
          <Badge color="info">Scheduled</Badge>
        ) : (
          <Badge color="success">Completed</Badge>
        )}
      </td>
      <td>{data?.message}</td>
      {data?.sendLater && (
        <td>
          <i
            style={{ fontSize: 18, cursor: "pointer" }}
            className="fa fa-edit mx-1"
            aria-hidden="true"
            onClick={async () => {
              try {
                history.push({
                  pathname: "add-notification",
                  state: data?._id,
                  search: location.search,
                });
              } catch (error) {}
            }}
          />

          <i
            className="fa fa-trash text-danger mx-2"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id);
                    toast("Notification Deleted Succesfully");
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
          />
        </td>
      )}
    </tr>
  );
};

export default TableRowPushNotification;
