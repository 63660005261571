import { isMobile } from "react-device-detect";
import { Col, Row } from "reactstrap";
import SecureInput from "../../../components/Inputs/SecureInput";

export default function Payments({ errors, touched, handleChange, values }) {
  return (
    <>
      <Row>
        <Col
          md="12"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <h2 className="mb-4">Jupiter</h2>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              API URL
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-envelope"}
                value={values?.paymentUrl}
                onChange={handleChange("paymentUrl")}
                className={`form-control ${
                  errors.paymentUrl && touched.paymentUrl ? "is-invalid" : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              API User
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-user"}
                value={values?.apiUser}
                onChange={handleChange("apiUser")}
                className={`form-control ${
                  errors.apiUser && touched.apiUser ? "is-invalid" : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6" className="my-2">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Password
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-envelope"}
                value={values?.paymentPassword}
                onChange={handleChange("paymentPassword")}
                className={`form-control ${
                  errors.paymentPassword && touched.paymentPassword
                    ? "is-invalid"
                    : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row className="mt-2">
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Jupiter Basic
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-bars"}
                value={values?.jupiterBasic}
                onChange={handleChange("jupiterBasic")}
                className={`form-control ${
                  errors.jupiterBasic && touched.jupiterBasic
                    ? "is-invalid"
                    : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Valut ID
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-shield"}
                value={values?.vaultId}
                onChange={handleChange("vaultId")}
                className={`form-control ${
                  errors.vaultId && touched.vaultId ? "is-invalid" : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          md="12"
          style={{
            marginTop: 50,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <h2 className="mb-4">Tilled</h2>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Secret Key
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-shield"}
                value={values?.tilledSecretKey}
                onChange={handleChange("tilledSecretKey")}
                className={`form-control ${
                  errors.tilledSecretKey && touched.tilledSecretKey
                    ? "is-invalid"
                    : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Publishable Key
            </Col>
            <Col md="9" className={isMobile && "my-2"}>
              <SecureInput
                icon={"fa fa-user"}
                value={values?.tilledPublishableKey}
                onChange={handleChange("tilledPublishableKey")}
                className={`form-control ${
                  errors.tilledPublishableKey && touched.tilledPublishableKey
                    ? "is-invalid"
                    : ""
                }`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
