import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import logger from "../../../utils/logger";

const Loyalty = () => {
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const { findOne, loading, entity } = useEntity("restaurant");
  const { restaurant } = useAdminStore();
  const { updateEntity } = useEntity("restaurant");

  useEffect(() => {
    findOne(authRest || restaurant);
  }, [restaurant]);

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setValues,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      minSubtotal: "",
      minRedeemableAmount: "",
      pointsPerDollar: "",
      visitThreshold: "",
      rewardPoints: "",
      allowLoyaltyTransaction: false,
    },
    validationSchema: Yup.object({
      minSubtotal: Yup.number("Field is required").when(
        "allowLoyaltyTransaction",
        {
          is: true,
          then: Yup.number()
            .min(0, "Cannot be less than 0")
            .max(9999, "Cannot be more than 999")
            .required("Field is required"),
        }
      ),
      minRedeemableAmount: Yup.number("Field is required").when(
        "allowLoyaltyTransaction",
        {
          is: true,
          then: Yup.number()
            .min(0, "Cannot be less than 0")
            .max(9999, "Cannot be more than 999")
            .required("Field is required"),
        }
      ),
      pointsPerDollar: Yup.number("Field is required").when(
        "allowLoyaltyTransaction",
        {
          is: true,
          then: Yup.number()
            .min(0, "Cannot be less than 0")
            .max(9999, "Cannot be more than 999")
            .required("Field is required"),
        }
      ),
      visitThreshold: Yup.number("Field is required").when(
        "allowLoyaltyTransaction",
        {
          is: true,
          then: Yup.number()
            .min(0, "Cannot be less than 0")
            .max(9999, "Cannot be more than 999")
            .required("Field is required"),
        }
      ),
      rewardPoints: Yup.number("Field is required").when(
        "allowLoyaltyTransaction",
        {
          is: true,
          then: Yup.number()
            .min(0, "Cannot be less than 0")
            .max(9999, "Cannot be more than 999")
            .required("Field is required"),
        }
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        minSubtotal: values?.minSubtotal,
        minRedeemableAmount: values?.minRedeemableAmount,
        pointsPerDollar: values?.pointsPerDollar,
        visitThreshold: values?.visitThreshold,
        rewardPoints: values.rewardPoints,
        allowLoyaltyTransaction: values.allowLoyaltyTransaction,
      };
      try {
        if (authContext.user.restaurant || restaurant) {
          await updateEntity(restaurant || authContext.user.restaurant, {
            loyalty: { ...data },
            ordering: {
              ...entity.data.restaurant.ordering,
              allowLoyaltyTransaction: values.allowLoyaltyTransaction,
            },
          });
        }
        toast.success("Settings has been updated successfully");
        resetForm();
      } catch (error) {
        logger.push({ error, info: "LoyaltyRestaurantError" });
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        minSubtotal: entity?.data?.restaurant?.loyalty?.minSubtotal || 0,
        minRedeemableAmount:
          entity?.data?.restaurant?.loyalty?.minRedeemableAmount || 0,
        pointsPerDollar:
          entity?.data?.restaurant?.loyalty?.pointsPerDollar || 0,
        visitThreshold: entity?.data?.restaurant?.loyalty?.visitThreshold || 0,
        rewardPoints: entity?.data?.restaurant?.loyalty?.rewardPoints || 0,
        allowLoyaltyTransaction:
          entity.data.restaurant.ordering.allowLoyaltyTransaction,
      });
    }
  }, [entity]);

  return (
    <div>
      <TimelineHeader
        path="/resturants"
        showBtn
        parentName="Dashboard"
        name="Loyalty"
      >
        <Button onClick={handleSubmit} color="primary">
          {isSubmitting ? <Spinner color="white" size="sm" /> : "Save Changes"}
        </Button>
      </TimelineHeader>
      <Container fluid>
        <Card className="mt--6">
          <CardBody>
            {!entity?.data?.restaurant?.ordering?.allowLoyaltyTransaction && (
              <Alert className="alert-warning">
                Loyalty Transactions are disabled from restaurant ordering
                settings.
              </Alert>
            )}
            {!loading ? (
              <div>
                <Form className="text-left">
                  <FormGroup>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Row>
                          <Col md="3">
                            <label
                              style={{
                                fontSize: 15,
                                fontWeight: "400",
                                width: "100%",
                              }}
                            >
                              Allow Loyalty
                            </label>
                          </Col>
                          <Col
                            md="9"
                            className={isMobile && "my-2"}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <label className="custom-toggle">
                              <input
                                checked={values.allowLoyaltyTransaction}
                                onChange={handleChange(
                                  "allowLoyaltyTransaction"
                                )}
                                value={values?.allowLoyaltyTransaction}
                                type="checkbox"
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FormGroup>
                  {values.allowLoyaltyTransaction && (
                    <>
                      {" "}
                      <FormGroup>
                        <Row>
                          <Col md="3">
                            <label
                              style={{
                                fontSize: 15,
                                fontWeight: "400",
                                width: "100%",
                              }}
                            >
                              Minimum Subtotal
                            </label>
                          </Col>
                          <Col md="9">
                            <Input
                              value={values.minSubtotal}
                              onChange={handleChange("minSubtotal")}
                              type="number"
                              className={`form-control ${
                                errors.minSubtotal && touched.minSubtotal
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.minSubtotal && touched.minSubtotal ? (
                              <small className={`${"text-red"}`}>
                                {errors.minSubtotal}
                              </small>
                            ) : (
                              <small>
                                *Minimum subtotal amount required before points
                                are eligible for redemption
                              </small>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md="3">
                            <label
                              style={{
                                fontSize: 15,
                                fontWeight: "400",
                                width: "100%",
                              }}
                              htmlFor="exampleFormControlSelect2"
                            >
                              Minimum Redeemable Points
                            </label>
                          </Col>
                          <Col md="9">
                            <Input
                              onChange={handleChange("minRedeemableAmount")}
                              value={values.minRedeemableAmount}
                              type="number"
                              className={`form-control ${
                                errors.minRedeemableAmount &&
                                touched.minRedeemableAmount
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.minRedeemableAmount &&
                            touched.minRedeemableAmount ? (
                              <small className={`${"text-red"}`}>
                                {errors.minRedeemableAmount}
                              </small>
                            ) : (
                              <small>
                                *Minimum amount of points user must have before
                                they are eligible for redemption.
                              </small>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md="3">
                            <label
                              style={{
                                fontSize: 15,
                                fontWeight: "400",
                                width: "100%",
                              }}
                              htmlFor="exampleFormControlSelect2"
                            >
                              Points Per $
                            </label>
                          </Col>
                          <Col md="9">
                            <Input
                              onChange={handleChange("pointsPerDollar")}
                              value={values.pointsPerDollar}
                              type="number"
                              className={`form-control ${
                                errors.pointsPerDollar &&
                                touched.pointsPerDollar
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.pointsPerDollar &&
                            touched.pointsPerDollar ? (
                              <small className={`${"text-red"}`}>
                                {errors.pointsPerDollar}
                              </small>
                            ) : (
                              <small>*How many points equal $1</small>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md="3">
                            <label
                              style={{
                                fontSize: 15,
                                fontWeight: "400",
                                width: "100%",
                              }}
                              htmlFor="exampleFormControlSelect2"
                            >
                              Visit Threshold
                            </label>
                          </Col>
                          <Col md="9">
                            <Input
                              onChange={handleChange("visitThreshold")}
                              value={values.visitThreshold}
                              type="number"
                              className={`form-control ${
                                errors.visitThreshold && touched.visitThreshold
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.visitThreshold && touched.visitThreshold ? (
                              <small className={`${"text-red"}`}>
                                {errors.visitThreshold}
                              </small>
                            ) : (
                              <small>
                                *How many order earns points defined below
                              </small>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md="3">
                            <label
                              style={{
                                fontSize: 15,
                                fontWeight: "400",
                                width: "100%",
                              }}
                              htmlFor="exampleFormControlSelect2"
                            >
                              Reward Points
                            </label>
                          </Col>
                          <Col md="9">
                            <Input
                              onChange={handleChange("rewardPoints")}
                              value={values.rewardPoints}
                              type="number"
                              className={`form-control ${
                                errors.rewardPoints && touched.rewardPoints
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.rewardPoints && touched.rewardPoints ? (
                              <small className={`${"text-red"}`}>
                                {errors.rewardPoints}
                              </small>
                            ) : (
                              <small>
                                *How many points are rewarded when user orders
                                the above value times
                              </small>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  )}
                </Form>
              </div>
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Loyalty;
