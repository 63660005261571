/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Container, Row, Spinner } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTable from "../../../widgets/SuperTable";
import ReportsHeader from "./components/ReportsHeader";
import TableRowRestCouponReport from "./components/TableRowRestCouponReport";
import moment from "moment";
import { exportAsCSV } from "../../../utils/expoAsCSV";

const RestaurantCouponReport = (props) => {
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const {
    find,
    entities,
    loading: isLoading,
  } = useEntity("restaurant-report/coupon", {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    find({
      restaurantId: authRest || restaurant,
      startDate: startDate || firstDay,
      endDate: endDate || lastDay,
    });
  }, [startDate, endDate, restaurant, authRest]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Coupon Name", "Coupon Code", " Orders", "Total"];

  const transformedData = useMemo(() => {
    return entities?.data?.map((data, idx) => {
      return {
        "Coupon Name": data?.couponName,
        "Coupon Code": data?.couponCode,
        Orders: data?.totalOrders,
        "Total Discount": Number(data?.totalDiscount)?.toFixed(2),
      };
    });
  }, [entities?.data]);

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };
    // needed for a bug in react-date-range which does not
    // set correct end date time

    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setStartDate(range.from);
    setEndDate(range.to);
  };

  return (
    <div>
      <SimpleHeader
        path="/resturants"
        name="Coupon Report"
        parentName="Restaurant"
        showBtn={true}
      >
        <Button
          color="primary"
          size="md"
          onClick={() =>
            exportAsCSV({
              arrayOfObject: transformedData,
              fileName: `Restaurant Coupon Report (${new Date().toDateString()})`,
            })
          }
        >
          CSV
        </Button>
      </SimpleHeader>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ReportsHeader
                endDate={endDate || lastDay}
                startDate={startDate || firstDay}
                handleSelectDates={handleSelectDates}
                locationField={false}
              />
              <CardBody className="mt--5">
                {!isLoading ? (
                  <SuperTable
                    sort={handleSort}
                    showCheckboxes={false}
                    data={entities?.data}
                    onPageChange={handlePageChange}
                    OnRowPerPageChange={handleRowsPerPageChange}
                    total={entities?.data?.length}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    loading={isLoading}
                    history={props.history}
                    row={TableRowRestCouponReport}
                    columns={Headers}
                    isLoading={isLoading}
                    emptyMessage="No records"
                  />
                ) : (
                  <div
                    style={{
                      padding: 20,
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <center>
                      <Spinner size="lg" color="primary" type="border" />
                    </center>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default RestaurantCouponReport;
