/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import { Button } from "reactstrap";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import Notification from "../../../components/Popups/Notification";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import useScreenSize from "../../../hooks/useScreenSize";
import { useCartStore } from "../../../store";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import getOptionalValue from "../../../utils/getOptionalValue";
import HeaderV2 from "../auth/components/HeaderV2";
import Footer from "../components/Footer";
import LocationRowV2 from "../components/LocationRowV2";
import "../styles/Home/home.css";

const HomeV2 = ({ history }) => {
  const { width } = useScreenSize();

  const { restaurant } = useOrderingRestaurant();
  const { clearCart } = useCart();
  const { setCoupon } = useCartStore();
  const { find: findMethod, entities } = useEntity("eat/ordering/method");
  const [showMap, setShowMap] = useState(width > 500);
  const [markers, setMarkers] = useState([]);
  const { theme } = useTheme();
  const [highlightedLocation, setHighLightedLocation] = useState("");
  const { method, setCateringData } = useOrdering();
  const [, setMap] = useState(null);
  const [tab, setTab] = useState("pickup");
  const [isInit, setIsInit] = useState(false);
  const mapRef = useRef();
  const {
    find,
    entities: locations,
    loading: isLoading,
  } = useEntity("eat/ordering/location");

  useEffect(() => {
    if (restaurant?.userId?._id) {
      find({ restaurant: restaurant?.userId?._id, orderMethod: tab });
    }
  }, [restaurant, tab]);

  const locationData = locations?.data;

  useEffect(() => {
    if (!isInit && locationData) {
      let arr = [];
      locationData?.map((it) => {
        if (it?.latLng?.length === 2) {
          const latLng = { lat: it?.latLng?.[0], lng: it?.latLng?.[1] };
          arr.push(latLng);
        }
      });
      setMarkers([...arr]);
      setIsInit(true);
    }
  }, [locationData]);

  const initMap = () => {
    if (!window?.google) {
      return;
    }
    let google = window.google;
    let map = mapRef.current;
    let lat = "17.385";
    let lng = "78.4867";
    const myLatlng = new google.maps.LatLng(lat, lng);

    const mapOptions = {
      maxZoom: 17,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: false,
      fullscreenControl: false,
      disableDefaultUI: true,

      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    markers?.map((marker) => {
      const newMarker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(marker?.lat, marker?.lng),
        map,
      });

      newMarker.addListener("click", function () {
        const findLocation = locations?.data?.find(
          (loc) =>
            loc?.latLng[0] === marker?.lat && loc?.latLng[1] === marker?.lng
        );

        setHighLightedLocation(findLocation?._id);

        console.log(findLocation);
      });
    });

    var bounds = new window.google.maps.LatLngBounds();

    for (var i = 0, LtLgLen = markers.length; i < LtLgLen; i++) {
      bounds.extend(markers[i]);
    }

    map.fitBounds(bounds);
    setMap(map);
  };

  React.useEffect(() => {
    if (showMap) {
      initMap();
    }
  }, [showMap, markers]);

  useEffect(() => {
    if (Object.keys(restaurant).length === 0) {
      const url = window.location.href?.replace(/\/location$/, "");
      let hostUrl = new URL(url);
      history.push(hostUrl.pathname);
      return;
    }
    if (!method && Object.keys(restaurant).length > 0) {
      let url = new URL(restaurant?.url);
      history.push(url.pathname);
    }
  }, []);

  const { setMethod, setLastAlertContent, lastAlertContent } = useOrdering();

  const [homeModal, setHomeModal] = useState(false);

  const pathname = window.location.pathname.replace(/\/+$/, "");

  useEffect(() => {
    setHomeModal(true);
  }, [restaurant]);

  useEffect(() => {
    if (restaurant) {
      findMethod({ restaurant: restaurant?.userId?._id });
    }
  }, [restaurant]);

  useEffect(() => {
    if (
      entities?.data?.availableMethods?.length === 1 &&
      restaurant?.ordering?.skipStepMethod
    ) {
      setMethod(entities?.data?.availableMethods[0]);
      history.push({
        pathname: `${pathname}/location`,
      });
    }
  }, [entities]);

  useEffect(() => {
    clearCart();
    setCoupon(null);
    setCateringData();
  }, []);

  useEffect(() => {
    setMethod(tab);
  }, [tab]);

  const getActiveTab = (value) => {
    if (value === "dinein") return "dine-in";
    else return value;
  };

  return (
    <RestaurantWrapper loading={isLoading}>
      <div
        className="map_ordering"
        style={{
          display: showMap && width > 500 ? "flex" : "inline",
          maxWidth: "1440px",
          margin: "auto",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <HeaderV2 account={true} history={history} />
          <div style={{ flex: 1, padding: "0px 10px" }}>
            <div
              className="home_v2_method_button"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 12,
              }}
            >
              {entities?.data?.availableMethods?.map((method) => {
                if (
                  !restaurant?.orderingApp ||
                  restaurant?.userId?.status === "inactive"
                )
                  return <></>;
                return (
                  <div
                    onClick={() => {
                      if (method === "dinein") {
                        setTab("dine-in");
                      } else setTab(method);
                    }}
                    style={{
                      padding: 12,
                      border: `1px solid ${
                        tab === getActiveTab(method) ? theme.primary : "#ededed"
                      }`,
                      backgroundColor:
                        tab === getActiveTab(method) ? theme.primary : "#fff",
                      color:
                        tab === getActiveTab(method) ? "white" : theme.primary,
                      flex: 1,
                      textTransform: "uppercase",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                  >
                    {method === "pickup"
                      ? getOptionalValue(
                          restaurant?.ordering?.pickupTitle,
                          "Pickup"
                        )
                      : method === "delivery"
                      ? getOptionalValue(
                          restaurant?.ordering?.deliveryTitle,
                          "Delivery"
                        )
                      : method === "dinein"
                      ? getOptionalValue("Dine In", "Dine In")
                      : getOptionalValue("Catering", "Catering")}
                  </div>
                );
              })}
              {(entities?.data?.availableMethods?.length === 0 ||
                !restaurant?.orderingApp ||
                restaurant?.userId?.status === "inactive") && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{ fontSize: 24, fontWeight: "800", marginTop: 10 }}
                  >
                    {restaurant?.ordering?.shopCloseTitle}
                  </span>
                  <span style={{ fontSize: 18, marginTop: 10 }}>
                    {restaurant?.ordering?.shopCloseText ||
                      "Online ordering isn’t available now."}
                  </span>
                </div>
              )}
            </div>

            {restaurant?.pop_up_alerts?.home_popup?.home_popup &&
              restaurant?.orderingApp &&
              lastAlertContent?.home !==
                restaurant?.pop_up_alerts?.home_popup?.message && (
                <Notification
                  btnTitle={restaurant?.pop_up_alerts?.home_popup?.title}
                  popupTitle={restaurant?.pop_up_alerts?.home_popup?.title}
                  popupHeading={restaurant?.pop_up_alerts?.home_popup?.title}
                  popupDescription={
                    restaurant?.pop_up_alerts?.home_popup?.message
                  }
                  toggle={() => {
                    setLastAlertContent(
                      {
                        home: restaurant?.pop_up_alerts?.home_popup?.message,
                      },
                      lastAlertContent
                    );
                    setHomeModal(!homeModal);
                  }}
                  open={homeModal}
                  origin="home"
                />
              )}
            {isLoading ? (
              <>
                <div>
                  <Skeleton height={50} className="mb-3" />
                </div>
                <div
                  className={"mb-3"}
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    border: `2px solid ${theme?.primary}`,
                    cursor: "pointer",
                  }}
                >
                  <div className="p-3 location_card">
                    <div
                      style={{ maxWidth: "280px", minWidth: "280px" }}
                      className="flex-1 text-left"
                    >
                      <h3
                        style={{
                          fontSize: "18px",
                          fontWeigth: 600,
                          textAlign: "left",
                          color: "#212121",
                        }}
                      >
                        <Skeleton width={200} />
                      </h3>
                      <span
                        style={{
                          color: "#666666",
                          textAlign: "left",
                          width: 100,
                        }}
                      >
                        <Skeleton width={200} />
                      </span>
                    </div>

                    <div
                      className="flex-1 location_availability text-left"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#fff",
                        padding: 5,
                        borderRadius: "5px",
                        boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
                        color: "#212121",
                      }}
                    >
                      <Skeleton height={120} />
                    </div>
                  </div>

                  <Button
                    block
                    style={{
                      backgroundColor: theme?.primary,
                      color: "#fff",
                      fontWeight: "400",
                      border: "0px",
                      fontSize: 16,
                      marginTop: 10,
                      padding: 8,
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: 0,
                      transform: "none",
                      textTransform: "uppercase",
                    }}
                  >
                    Select
                  </Button>
                </div>
              </>
            ) : (
              <div className="mt-4">
                {locationData
                  ?.filter((l) => {
                    if (method === "pickup" && l.pickupDelivery.enablePickup) {
                      return true;
                    } else if (
                      (method === "delivery" &&
                        l.pickupDelivery.enableDelivery) ||
                      l.enableIhd
                    ) {
                      return true;
                    } else if (
                      method === "dine-in" &&
                      l?.ordering?.enableDineIn
                    ) {
                      return true;
                    } else if (method === "catering" && l?.cateringShop) {
                      return true;
                    }
                  })
                  ?.map((d, i) => {
                    return (
                      <LocationRowV2
                        v2={true}
                        data={d}
                        length={locationData?.length}
                        index={i}
                        key={d?._id}
                        history={history}
                        origin={origin}
                        highlightedLocation={highlightedLocation}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          <div className="mb-8">
            <Footer />
          </div>
        </div>

        {locationData?.length <= 0 && (
          <div>
            <h3 style={{ color: "#212121" }}>Currently no locations defined</h3>
          </div>
        )}

        <div
          style={{
            display: showMap && width > 500 ? "block" : "none",
            flex: 1.6,
            margin: 20,
            borderRadius: 7,
            border: `1px solid ${theme.primary}`,
            height: "95vh",
          }}
        >
          <div
            style={{ height: "94.70vh" }}
            className="map-canvas"
            id="map-custom"
            ref={mapRef}
          />
        </div>
      </div>
    </RestaurantWrapper>
  );
};
export default HomeV2;
