/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from "reactstrap";
import LineChart from "../../components/Charts/LineChart";
import SimpleHeader from "../../components/Header/SimpleHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useAdminStore } from "../../store/admin";
import { useRestaurantStore } from "../../store/restaurant";
import { toFixed } from "../../utils/toFixed";
import { isMobileOnly } from "react-device-detect";

export default function DashboardResturant(props) {
  const authContext = useContext(AuthContext);
  const { restaurant } = useAdminStore();
  const authRest =
    authContext?.user?.role === "restaurant" ? authContext?.user?._id : null;
  const { setRestaurant } = useRestaurantStore();

  const { findOne: findStat, entity: stats } = useEntity("stats/restaurant");
  const { find: findOrders, entities: orders } = useEntity(
    "stats/latest-orders"
  );

  const { findOne: findRestaurant, entity: restaurantDoc } =
    useEntity("restaurant");

  useEffect(() => {
    document.title = restaurantDoc?.data?.restaurant?.name || "Loading..";
  }, [restaurantDoc]);

  useEffect(() => {
    findRestaurant(authRest || restaurant);
  }, [restaurant]);

  useEffect(() => {
    if (restaurantDoc?.data) {
      setRestaurant(restaurantDoc?.data);
      findStat(authRest || restaurantDoc?.data?._id);
      findOrders({
        restaurant: authRest || restaurantDoc?.data?._id,
      });
    }
  }, [restaurantDoc]);

  return (
    <>
      <SimpleHeader name="Home" parentName="Restaurant" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="8" xl="4">
            <Card className="border-0 card-color">
              <CardBody>
                <div className="custom-card">
                  <div className="order-main">
                    <div className="order-val">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 "
                      >
                        Total Orders
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {stats?.data?.totalOrders}
                      </span>
                    </div>
                    <div className="order-icon">
                      <i className="fa fa-telegram bg-orange"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" xl="4">
            <Card className="border-0 card-color">
              <CardBody>
                <div className="custom-card">
                  <div className="order-main">
                    <div className="order-val">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 "
                      >
                        Total Sales
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        ${toFixed(stats?.data?.totalSales || 0, false)}
                      </span>
                    </div>
                    <div className="order-icon">
                      <i className="fa fa-bar-chart bg-green"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" xl="4">
            <Card className=" border-0 card-color">
              <CardBody>
                <div className="custom-card">
                  <div className="order-main">
                    <div className="order-val">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 "
                      >
                        Total Customers
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {stats?.data?.totalCustomers || 0}
                      </span>
                    </div>
                    <div className="order-icon">
                      <i className="fa fa-money bg-purple"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="8">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="h3 mb-0"> Analytics</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <LineChart
                    data={[
                      {
                        label: "Orders",
                        labels: stats?.data?.customerVsOrders?.labels,
                        data: stats?.data?.customerVsOrders?.ordersByMonth,
                        color: "#5e72e4",
                      },
                      {
                        label: "Customers",
                        labels: stats?.data?.customerVsOrders?.labels,
                        data: stats?.data?.customerVsOrders?.customersByMonth,
                        color: "#fb6340",
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card style={{ minHeight: 485 }}>
              <CardHeader>
                <h5 className="h3 mb-0">Recent Activities</h5>
              </CardHeader>
              <CardBody>
                <ListGroup flush>
                  {stats?.data?.activities?.map((item) => {
                    return (
                      <ListGroupItem className="px-0">
                        <Row className="align-items-center justify-content-start">
                          <Col className="col-auto">
                            <div className="order-icon">
                              <i className="fa fa-user bg-purple"></i>
                            </div>
                          </Col>
                          <div className="col  text-left">
                            <h4 className="mb-0">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                {item?.guestDetails?.isGuest
                                  ? item?.guestDetails?.name
                                  : item?.customer?.name}
                              </a>
                            </h4>
                            <span className="text-success mr-1">●</span>
                            <small>
                              {item?.actionType === "signup"
                                ? "Created a new account"
                                : "Placed an order"}
                            </small>
                          </div>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                  {false && (
                    <>
                      {[1, 2, 3, 4].map((ot) => {
                        return (
                          <ListGroupItem className="px-0">
                            <Row className="align-items-center justify-content-start">
                              <Col className="col-auto">
                                <div className="order-icon">
                                  <Skeleton
                                    style={{ borderRadius: "100%" }}
                                    width={50}
                                    height={50}
                                  />
                                </div>
                              </Col>
                              <div className="col  text-left">
                                <h4 className="mb-0">
                                  <Skeleton width={100} height={20} />
                                </h4>

                                <small>
                                  <Skeleton width={200} height={20} />
                                </small>
                              </div>
                            </Row>
                          </ListGroupItem>
                        );
                      })}
                    </>
                  )}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 text-left">Latest Orders</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      OrderID
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Customer
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Status
                    </th>
                    <th className="sort" data-sort="completion" scope="col">
                      Order Date
                    </th>
                    <th scope="col">Total</th>
                    <th scope="col">Method</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {orders.data?.map?.((order) => {
                    return (
                      <tr>
                        <th scope="row">{order.orderNum}</th>
                        <td>{order.customer.name}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={
                                order.status === "pending"
                                  ? "bg-info"
                                  : "bg-success"
                              }
                            />
                            <span className="status">{order.status}</span>
                          </Badge>
                        </td>
                        <td>{moment(order.createdAt).format("MM/DD/YYYY")}</td>
                        <td>${toFixed(order.payment.total)}</td>
                        <td>{order.method}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {isMobileOnly && <div style={{ height: 200 }}></div>}
    </>
  );
}
