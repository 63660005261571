import { useContext, useEffect, useState } from "react";
import apiCaller from "../../api/apiCaller";
import { AuthContext } from "../../context";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useTheme } from "../../store/theme";
import { Button, Spinner } from "reactstrap";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { ACCEPT_JS_URL, authorizeNetHost } from "../../config";
import { toast } from "react-toastify";
import logCustomerSession from "../../utils/axiom-log";


const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

const getLineItems = (cart) => {
  if (!Array.isArray(cart) || cart.length === 0) {
    return undefined;
  }

  return {
    lineItem: cart.map((item) => ({
      itemId: item?._id?.toString() || "",
      name: item?.name || "",
      description: item?.description || "",
      quantity: item?.qty?.toString() || "1",
      unitPrice: item?.price?.toFixed(2) || "0.00",
    })),
  };
};

const AuthnetTokenization = ({
  formik,
  onSuccess,
  guestFormik,
  guestErrors,
  guestRef,
  guestData
}) => {
  const [formData, setFormData] = useState({
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cardCode: "",
  });

  const {
    gateway,
    activeTab,
    deliveryConfirmAddress,
    location: orderingLocation,
    deliveryZone,
    deliveryMethod,
    cateringData,
    method,
    menuId,
  } = useOrdering();

  

  const { cartItems, coupon,moreInfoResponse } = useCartStore();

  const authContext = useContext(AuthContext);

  const { restaurant } = useOrderingRestaurant();

  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const [error, setError] = useState("");



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  

  useEffect(() => {
    loadScript(ACCEPT_JS_URL).catch(() => {
      setError("Failed to load Authorize.net script");
    });
    
  }, []);

  

  const sendPaymentDataToAnet = async () => {
    if (moreInfoResponse?.[0]?.length > 0) {
      toast.error(moreInfoResponse?.[0]);
      return;
    }
    await logCustomerSession({
      email:authContext?.user?.customer?.email || guestData.email,
      context:"sendPaymentDataToAnet",
      screen:"checkout",
      message:"sendPaymentDataToAnet called!"
     })
   

    setIsLoading(true);
    setError("");

    if (
      formData.cardNumber.trim() === "" ||
      formData.expMonth.trim() === "" ||
      formData.expYear.trim() === "" ||
      formData.cardCode.trim() === "" ||
      formData.cardHolderName.trim() === ""
    ) {
      setError("Please fill in all the required fields.");
      await logCustomerSession({
        email:authContext?.user?.customer?.email || guestData.email,
        context:"sendPaymentDataToAnet",
        screen:"checkout",
         message:"sendPaymentDataToAnet-ERROR:Please fill in all the required fields."
       })
      setIsLoading(false);
      return;
    }

    if (activeTab === "guest") {
      guestFormik();
      await logCustomerSession({
        email:authContext?.user?.customer?.email || guestData.email,
        context:"sendPaymentDataToAnet",
        screen:"checkout",
        message:"sendPaymentDataToAnet-SUBMITTED:Form submitted as guest."
       })
      if (Object.keys(guestErrors).length !== 0) {
        guestRef.current.scrollIntoView();
        setIsLoading(false);
        return;
      }
    }

    const merchantDetails = await apiCaller(
      "/eat/partner-app/ordering/authnet/merchant-details",
      {
        method: "GET",
        query: {
          gatewayId: gateway?._id,
        },
      }
    );

    await logCustomerSession({
      email:authContext?.user?.customer?.email || guestData.email,
      context:"sendPaymentDataToAnet",
      screen:"checkout",
      message:`sendPaymentDataToAnet-MerchantDetailsFetched:${JSON.stringify(merchantDetails)}.`
     })

    const authData = {
      clientKey: merchantDetails?.publicClientKey,
      apiLoginID: gateway?.apiLoginId,
    };

    const cardData = {
      cardNumber: formData.cardNumber,
      month: formData.expMonth,
      year: formData.expYear,
      cardCode: formData.cardCode,
    };

    const secureData = {
      authData: authData,
      cardData: cardData,
    };

    const invoiceNumber = await apiCaller(
      "/eat/partner-app/ordering/ref-sequence",
      {
        method: "GET",
      }
    );

    await logCustomerSession({
      email:authContext?.user?.customer?.email || guestData.email,
      context:"sendPaymentDataToAnet",
      screen:"checkout",
      message:`sendPaymentDataToAnet-RefSequenceFetched:${JSON.stringify(invoiceNumber)}.`
     });

    try {
      const response = await new Promise((resolve, reject) => {
        window.Accept.dispatchData(secureData, (response) => {
          if (response.messages.resultCode === "Error") {
            reject(new Error(response.messages.message[0].text));
          } else {
            resolve(response);
          }
        });
      });

      await logCustomerSession({
        email:authContext?.user?.customer?.email || guestData.email,
        context:"sendPaymentDataToAnet",
        screen:"checkout",
        message:`sendPaymentDataToAnet-tokenization:${JSON.stringify(response)}.`
       });

      const processPayment = await apiCaller(
        "/eat/partner-app/ordering/payment/authnet",
        {
          method: "POST",
          body: {
            token: response.opaqueData.dataValue,
            gateway: gateway?._id,
            email: authContext?.user?.customer?.email || guestData?.email,
            name: authContext?.user?.customer?.name||  guestData?.name,
            phone: authContext?.user?.customer?.phone ||  guestData?.phone,
            refId: invoiceNumber?.data,
            lineItems: getLineItems(cartItems),
            billingPayload: {
              data: [...cartItems],
              tip: formik.values?.tip,
              coupon: coupon?._id || formik.values?.coupon?._id,
              points: formik.values?.loyaltyPoints,
              location: orderingLocation?._id,
              restaurant: restaurant?._id,
              method: method,
              customer: authContext?.user?.customer?._id,
              address: deliveryConfirmAddress,
              deliveryZoneId: deliveryZone?._id,
              deliveryMethod,
              catering: cateringData?.catering,
              menuId,
            },
          },
        }
      );

      await logCustomerSession({
        email:authContext?.user?.customer?.email || guestData.email,
        context:"sendPaymentDataToAnet",
        screen:"checkout",
        message:`sendPaymentDataToAnet-payment-processed:${JSON.stringify(response)}.`
       });

     
      if (saveCard) {
        await logCustomerSession({
          email:authContext?.user?.customer?.email || guestData.email,
          context:"sendPaymentDataToAnet",
          screen:"checkout",
          message:`sendPaymentDataToAnet-savecard-checked.`
         });
        const customerProfilePayload = {
          createCustomerProfileRequest: {
            merchantAuthentication: {
              name: gateway?.apiLoginId,
              transactionKey: gateway?.transactionKey,
            },
            profile: {
              merchantCustomerId: `${invoiceNumber?.data}`,
              description: authContext?.user?.customer?.name,
              email: authContext?.user?.customer?.email,
              paymentProfiles: {
                customerType: "individual",
                payment: {
                  creditCard: {
                    cardNumber: formData.cardNumber,
                    expirationDate: `${formData.expYear}-${formData.expMonth}`,
                  },
                },
              },
            },
            validationMode: "testMode",
          },
        };

      

        const createCustomerProfile = await fetch(authorizeNetHost, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customerProfilePayload),
        });

       

        const responseTextCustomerProfile = await createCustomerProfile.text();

        const resultCustomerProfile = JSON.parse(
          responseTextCustomerProfile.trim()
        );

        await logCustomerSession({
          email:authContext?.user?.customer?.email || guestData.email,
          context:"sendPaymentDataToAnet",
          screen:"checkout",
          message:`sendPaymentDataToAnet-customer profile created:${customerProfilePayload}`
         });

        const cardDetails = {
          token: null,
          truncatedCardNumber: `XXXXXXXX${processPayment?.response?.transactionResponse?.accountNumber}`,
          cardHolderName: formData.cardHolderName || "John Doe",
          expirationMonth: formData.expMonth,
          expirationYear: formData.expYear,
          cardLogo: processPayment?.response?.transactionResponse?.accountType,
          provider: "authorize.net",
          customer: authContext?.user?.customer?._id,
          authnetPaymentProfileId:
            resultCustomerProfile?.customerPaymentProfileIdList[0],
          authnetProfileId: resultCustomerProfile?.customerProfileId,

          restaurant: restaurant?.userId?._id,
        };

        await apiCaller("/eat/partner-app/cards/authnet/save-card", {
          method: "POST",
          body: {
            ...cardDetails,
          },
        });
        await logCustomerSession({
          email:authContext?.user?.customer?.email || guestData.email,
          context:"sendPaymentDataToAnet",
          screen:"checkout",
          message:`sendPaymentDataToAnet-savecard-completed.`
         });
      }

      setFormData({
        cardNumber: "",
        expMonth: "",
        expYear: "",
        cardCode: "",
      });

      if (processPayment?.success) {
        await logCustomerSession({
          email:authContext?.user?.customer?.email || guestData.email,
          context:"sendPaymentDataToAnet",
          screen:"checkout",
          message:`sendPaymentDataToAnet-savecard-onsuccess called.`
         });
        onSuccess({ transactionDoc: processPayment });
      }
    } catch (err) {
      
      toast.error(
        JSON.stringify(err.message) || "Payment failed. Please try again"
      );
      await logCustomerSession({
        email:authContext?.user?.customer?.email || guestData.email,
        context:"sendPaymentDataToAnet",
        screen:"checkout",
        message:`sendPaymentDataToAnet-ERROR:${JSON.stringify(err.message)}`
       });
      setIsLoading(false);
    }
  };

  console.log("===-----===--===--====---",moreInfoResponse)

  const styles = {
    container: {
      maxWidth: "100%",
      borderRadius: "10px",
    },
    title: {
      textAlign: "center",
      color: "#333",
      marginBottom: "30px",
      fontSize: "24px",
      fontWeight: "bold",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      color: "#555",
      fontSize: "14px",
      fontWeight: "bold",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ddd",
      fontSize: "16px",
      transition: "border-color 0.3s ease",
    },
    row: {
      display: "flex",
      gap: "10px",
    },
    column: {
      flex: 1,
    },
    button: {
      color: "white",
      border: "none",
      padding: "12px",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#45a049",
    },
    error: {
      color: "#ff0000",
      fontSize: "14px",
      marginTop: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
        <div style={styles.row}>
          <div style={styles.column}>
            <label style={styles.label} htmlFor="cardHolderName">
              Cardholder Name
            </label>
            <input
              type="text"
              id="cardHolderName"
              name="cardHolderName"
              value={formData.cardHolderName}
              onChange={handleInputChange}
              style={styles.input}
              placeholder="John Doe"
            />
          </div>
          <div style={styles.column}>
            <label style={styles.label} htmlFor="cardNumber">
              Card Number
            </label>
            <input
              type="text"
              id="cardNumber"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleInputChange}
              style={styles.input}
              placeholder="1234 5678 9012 3456"
            />
          </div>
        </div>
        <div style={styles.row}>
          <div style={styles.column}>
            <label style={styles.label} htmlFor="expMonth">
              Exp Month
            </label>
            <input
              type="text"
              id="expMonth"
              name="expMonth"
              value={formData.expMonth}
              onChange={handleInputChange}
              style={styles.input}
              placeholder="MM"
            />
          </div>
          <div style={styles.column}>
            <label style={styles.label} htmlFor="expYear">
              Exp Year
            </label>
            <input
              type="text"
              id="expYear"
              name="expYear"
              value={formData.expYear}
              onChange={handleInputChange}
              style={styles.input}
              placeholder="YYYY"
            />
          </div>
          <div style={styles.column}>
            <label style={styles.label} htmlFor="cardCode">
              CVV
            </label>
            <input
              type="text"
              id="cardCode"
              name="cardCode"
              value={formData.cardCode}
              onChange={handleInputChange}
              style={styles.input}
              placeholder="123"
            />
          </div>
        </div>
        {authContext?.user && (
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={(e) => setSaveCard(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Save this card for future checkout
            </label>
          </div>
        )}{" "}
        {error && <p style={styles.error}>{error}</p>}
        <Button
          type="button"
          onClick={sendPaymentDataToAnet}
          disabled={
            isLoading ||
            formik?.values?.billing?.disableOrdering ||
            cartItems?.length <= 0 ||
            activeTab === "sign-in" ||
            activeTab === "register" ||
            (formik.values.payment_method === "saved-card" &&
              formik.values.selected_card === "") ||
            ((formik.values.payment_method === "new-card" ||
              formik.values.payment_method === "saved-card") &&
              !gateway) ||
            formik.isSubmitting
          }
          style={{
            backgroundColor: theme.primary,
            color: "white",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
          }}
        >
          {isLoading ? <Spinner size={"sm"} /> : "Place Order"}
        </Button>
      </form>
    </div>
  );
};

export default AuthnetTokenization;
