import { useEffect, useState } from "react";
import Select from "react-select";
import { CardFooter, Table } from "reactstrap";

import EmptyPlaceholder from "../screens/ordering/menu/components/EmptyPlaceholder";
import Paginations from "./Paginations";

export default function SuperTable({
  data,
  columns,
  row: TableRow,
  emptyMessage,
  history,
  refetch,
  showCheckboxes,
  onItemsChecked,
  total,
  setRowsPerPage,
  setPage,
  rowsPerPage,
  page,
  isLoading,
  sort,
  randomPass,
  prevPage,
  nextPage,
  currentPage,
  showFilter = false,
  filterComponent,
  rowProps = {},
  tabs,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const options = [
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
  ];
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(total / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Reset selected items when items change
  useEffect(() => {
    if (selectedItems.length) {
      setSelectedItems([]);
    }
  }, [data]);

  const handleSelectAllItems = (event) => {
    setSelectedItems(event.target.checked ? data.map((item) => item._id) : []);
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId)
      );
    }
  };

  useEffect(() => {
    showCheckboxes && onItemsChecked(selectedItems);
  }, [selectedItems, onItemsChecked, showCheckboxes]);

  useEffect(() => {
    if (data?.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [data?.length, page]);

  return (
    <>
      {data?.length === 0 && (
        <Table className="align-items-center " responsive>
          <thead className="thead-light text-left">
            <tr>
              {showCheckboxes && (
                <th>
                  <input type="checkbox" onChange={handleSelectAllItems} />
                </th>
              )}
              {columns?.map((c, i) => {
                return (
                  <th key={i} scope="col">
                    {c}
                  </th>
                );
              })}
            </tr>
          </thead>
        </Table>
      )}
      {data?.length !== 0 ? (
        <>
          <Table
            className="align-items-center table-flush hide_scroll"
            responsive
          >
            <thead className="thead-light text-left">
              {showFilter && filterComponent}
              <tr>
                {showCheckboxes && (
                  <th>
                    <input type="checkbox" onChange={handleSelectAllItems} />
                  </th>
                )}
                {columns?.map((c, i) => {
                  return (
                    <th key={i} scope="col">
                      {c}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="hide_scroll">
              {data?.map((d, i) => {
                return (
                  <TableRow
                    isLoading={isLoading}
                    key={i}
                    index={i}
                    showCheckboxes={showCheckboxes}
                    handleSelectOneItem={handleSelectOneItem}
                    data={d}
                    item={d}
                    history={history}
                    refetch={refetch}
                    checkedItems={selectedItems}
                    pass={randomPass}
                    tabs={tabs}
                    {...rowProps}
                  />
                );
              })}
            </tbody>
          </Table>
          {total > 50 && (
            <CardFooter>
              <div className="d-flex justify-content-end">
                <div className="d-flex align-items-center">
                  {total > 50 && <div className="mt-4 mr-2">Rows per page</div>}
                  {total > 50 && (
                    <div style={{ width: "85px" }} className="mx-2 mt-4">
                      <Select
                        size="sm"
                        isSearchable={false}
                        menuPlacement="top"
                        onChange={(e) => {
                          setRowsPerPage(e.value);
                        }}
                        options={options}
                        value={{ label: rowsPerPage, value: rowsPerPage }}
                      />
                    </div>
                  )}
                  {total > 50 && (
                    <div className="d-flex mt-4 mr-2">
                      {(page - 1) * rowsPerPage + 1} -{" "}
                      {data?.length === 10 && (
                        <div> {(page - 1) * rowsPerPage + rowsPerPage}</div>
                      )}
                      {data?.length !== 10 && (
                        <div> {(page - 1) * rowsPerPage + data?.length}</div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mt--1">
                  <Paginations
                    records={data?.length}
                    total={total}
                    page={page}
                    pageNumbers={pageNumbers}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </CardFooter>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <EmptyPlaceholder text={emptyMessage} />
        </div>
      )}
    </>
  );
}
