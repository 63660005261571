/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify"
import { Button, Col, Modal, Row, Spinner } from "reactstrap"
import swal from "sweetalert"
import { useEntity } from "../../../../hooks/useEntity"
import { useHistory } from "react-router-dom"
import QRCode from "react-qr-code"
import { useContext, useEffect, useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import { AuthContext } from "../../../../context"
import { useLocation } from "react-router-dom"
import { useAdminStore } from "../../../../store/admin"
import useLegacyPrinterStatus from "../../../../hooks/useLegacyPrinterStatus"
import moment from "moment"

export const LocationsRow = ({ data, enableIhd }) => {
  const { updateEntity, loading, deleteEntity } = useEntity("location")
  const { find, entities } = useEntity("location/devices")
  const { findOne: findIpadDevice, entity: ipadDevice } =
    useEntity("location/ipad")
  const [qrOpen, setQrOpen] = useState(false)
  const componentRef = useRef()
  const authContext = useContext(AuthContext)
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id
  const { restaurant } = useAdminStore()
  const { findOne, entity: restaurantDoc } = useEntity("restaurant")
  const location = useLocation()
  const { result, findLegacyStatus } = useLegacyPrinterStatus()

  let history = useHistory()

  useEffect(() => {
    findOne(authRest || restaurant)
  }, [])

  useEffect(() => {
    findIpadDevice(data._id)
  }, [])

  useEffect(() => {
    if (data.macAddress) {
      if (data?.forwardPrint) {
        findLegacyStatus(data?.macAddress)
      }
      find({ printerMac: data?.macAddress })
    }
  }, [data])

  return (
    <>
      <Modal isOpen={qrOpen} toggle={() => setQrOpen(false)}>
        <div
          id="qrcode"
          ref={componentRef}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            paddingTop: 50,
            paddingBottom: 40,
          }}
        >
          {/* TODO:CHANGE HARDCODED URL */}
          <QRCode
            value={`https://ordering.devzlab.in/ordering/${restaurantDoc?.data?.username}?location=${data?._id}`}
          />
        </div>
        <div style={{ display: "flex", padding: 20 }}>
          <ReactToPrint
            trigger={() => (
              <Button style={{ flex: 1 }} color="primary">
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button
            onClick={() => {
              setQrOpen(false)
            }}
            style={{ flex: 1 }}
            color="warning"
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <tr className="text-left">
        <td
          className="text-left"
          style={{ cursor: "pointer", padding: 5 }}
          onClick={() => {
            history.push({
              pathname: "/resturants/add-location",
              state: data?._id,
              search: location.search,
            })
          }}
        >
          {data?.name}
        </td>
        <td className="text-left">
          <span className="ml-4">{data?.sortOrder}</span>
        </td>
        <td>
          {loading ? (
            <Spinner size="sm" color="primary" />
          ) : (
            <label className="custom-toggle">
              <input
                checked={data?.status === true}
                type="checkbox"
                onChange={async () => {
                  try {
                    await updateEntity(data?._id, {
                      status: data?.status === true ? false : true,
                    })
                    toast("Location Updated Successfully")
                  } catch (error) {
                    toast(error.message)
                  }
                }}
              />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Off"
                data-label-on="On"
              />
            </label>
          )}
        </td>
        <td>
          {data?.ordering?.enableDineIn ? (
            <i
              className="fa fa-qrcode mx-2"
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                setQrOpen(true)
              }}
            />
          ) : (
            "N/A"
          )}
        </td>
        <td>
          <Row>
            {data?.print ? (
              <i
                className="fa fa-cloud"
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  marginLeft: 9,
                  color: (
                    entities?.lastPingedAt
                      ? moment(new Date()).diff(
                          entities?.lastPingedAt,
                          "minutes"
                        ) < 10
                      : result?.status === "Online"
                  )
                    ? "green"
                    : "red",
                }}
                title={
                  (
                    entities?.lastPingedAt
                      ? moment(new Date()).diff(
                          entities?.lastPingedAt,
                          "minutes"
                        ) < 10
                      : result?.status === "Online"
                  )
                    ? "Printer is online"
                    : "Printer is offline"
                }
              />
            ) : (
              <i
                className="fa fa-cloud"
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  color: "grey",
                  marginLeft: 9,
                }}
                title="Print is not enabled"
              />
            )}
          </Row>
          {ipadDevice?.data[0]?.lastPingedAt && (
            <Row className="mt-2">
              <Col>
                <i
                  className="fa fa-tablet"
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    color:
                      moment(new Date()).diff(
                        ipadDevice?.data[0]?.lastPingedAt,
                        "minutes"
                      ) < 10 && ipadDevice?.data[0]?.lastPingedAt !== undefined
                        ? "green"
                        : "red",
                  }}
                  title={
                    moment(new Date()).diff(
                      ipadDevice?.data[0]?.lastPingedAt,
                      "minutes"
                    ) < 10 && ipadDevice?.data[0]?.lastPingedAt !== undefined
                      ? "Device is online"
                      : "Device is offline"
                  }
                />
              </Col>
              <Col>
                <span>OS: {ipadDevice?.data[0]?.osVersion || "NA"}</span>
              </Col>
              <Col>
                <span>App: {ipadDevice?.data[0]?.appVersion || "NA"}</span>
              </Col>
            </Row>
          )}
        </td>
        {enableIhd && <td>{data?.ihdLocationId}</td>}
        <td className="text-left">
          <i
            className="fa fa-trash text-danger mx-3"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id)
                    toast.error("Location Deleted Succesfully")
                  }
                })
              } catch (error) {
                toast(error.message)
              }
            }}
          />
        </td>
      </tr>
    </>
  )
}
