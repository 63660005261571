import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import * as yup from "yup";
import { JUPITER } from "../../config";
import { AuthContext } from "../../context";
import useOrdering from "../../store/ordering";
import logger from "../../utils/logger";
import { ErrorText } from "../ErrorText";

const JupiterTokenization = forwardRef(
  ({ paymentFormRef, onSuccess, formik }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [form, setForm] = useState(null);
    const [saveCard, setSaveCard] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [showError, setShowError] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const { gateway } = useOrdering();
    const authContext = useContext(AuthContext);
    const [sessionId, setSessionId] = useState(null);
    const [errors, setErrors] = useState([]);

    const validationSchema = yup.object().shape({
      zip: yup
        .string()
        .matches(/^\d{5}(?:-\d{4})?$/, "Invalid zip code")
        .required("Zip code is required"),
    });

    //Submitting form
    useImperativeHandle(paymentFormRef, () => ({
      async handleSubmit() {
        const { state } = form;

        setShowError(true);

        if (
          !state.CardHolderName.isValid ||
          !state.CreditCardNumber.isValid ||
          !state.Cvv.isValid ||
          !state.ExpirationDate.isValid
        ) {
          formik.setSubmitting(false);
        }
        const subMerchantId = gateway?.subMerchantId;
        try {
          if (gateway?.enableEnhancedSecurity) {
            await validationSchema.validate({ zip: zipCode });
          }
        } catch (error) {
          setErrors(["Invalid Zipcode"]);
          formik.setSubmitting(false);

          return;
        }

        try {
          form.submit(
            JUPITER.tokenizationEndpoint + subMerchantId,
            { data: { sessionToken: sessionId } },
            (status, response) => {
              if (status === 200) {
                delete response?.data?.subMerchantId;
                onSuccess({
                  ...response?.data,
                  saveCard,
                  billingAddress: { zipCode },
                });
              } else {
                logger.push({
                  error: "TOKENIZATION STATUS CODE ERROR",
                  info: "JupiterTokenization",
                  response: response,
                });

                swal("Error", "Payment Failed", "error");
                formik.setSubmitting(false);
              }
            }
          );
        } catch (error) {
          formik.setSubmitting(false);
          logger.push({ error, info: "JupiterTokenization" });
          console.log(error.message, "ERROR");
          swal("Error", error.message, "error");
        }
      },
    }));

    //Loading VGS Collect Script
    useEffect(() => {
      if (!isInitialized) {
        const script = document.createElement("script");
        script.src =
          "https://js.verygoodvault.com/vgs-collect/1/ACgtnCohNkFmTpkULj2jaRvX.js";
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener("load", () => setIsLoading(false));
      }
    }, [isInitialized]);

    //Initializing Form
    useEffect(() => {
      if (!isLoading && !isInitialized) {
        console.log("jupiter env", JUPITER.environment);
        setForm(
          window.VGSCollect.create(
            JUPITER.vaultId,
            JUPITER.environment,
            function (state) {}
          )
        );
      }
    }, [isLoading]);

    useEffect(() => {
      if (isInitialized && gateway?.subMerchantId) {
        (async () => {
          let resp = await fetch(
            `${JUPITER.sessionHost}${gateway?.subMerchantId}/session`
          );
          let sessionData = await resp.json();

          if (
            sessionData.data &&
            (sessionData.data.status === "valid" ||
              sessionData.data.status === "active")
          ) {
            setSessionId(sessionData.data.sessionId);
          }
        })();
      }
    }, [isInitialized, gateway]);

    //Initilizing Form Fields
    useEffect(() => {
      if (form && !isInitialized) {
        const css = {
          border: "1px solid #ccc",
          boxSizing: "border-box",
          paddingLeft: 10,
          "&:focus": {
            border: "1px solid #eee",
          },
          backgroundColor: "#EDEDED",
        };

        form.field("#cc-name .fake-input", {
          type: "text",
          name: "CardHolderName",
          placeholder: "Joe Business",
          validations: ["required"],
          css: css,
          autoComplete: "cc-name",
          successColor: "#3c763d",
          errorColor: "#a94442",
          classes: {
            invalid: "vgs__input--invalid",
            empty: "vgs_input--empty",
          },
        });

        form.field("#cc-number .fake-input", {
          type: "card-number",
          name: "CreditCardNumber",
          successColor: "#4F8A10",
          errorColor: "#D8000C",
          placeholder: "4111 1111 1111 1111",
          validations: ["required", "validCardNumber"],
          autoComplete: "cc-number",
          css: css,
          showCardIcon: true,
          classes: {
            invalid: "vgs__input--invalid",
            empty: "vgs__input--empty",
          },
        });

        form.field("#cc-cvc .fake-input", {
          type: "card-security-code",
          name: "Cvv",
          placeholder: "344",
          autoComplete: "cc-cvc",
          validations: ["required", "validCardSecurityCode"],
          successColor: "#3c763d",
          errorColor: "#a94442",
          css: css,
          classes: {
            invalid: "vgs__input--invalid",
            empty: "vgs__input--empty",
          },
        });

        form.field("#cc-expiration-date .fake-input", {
          type: "card-expiration-date",
          name: "ExpirationDate",
          autoComplete: "cc-expiration-date",
          yearLength: 2,
          placeholder: "12 / 24",
          successColor: "#3c763d",
          errorColor: "#a94442",
          serializers: [
            form.SERIALIZERS.separate({
              monthName: "ExpirationMonth",
              yearName: "ExpirationYear",
            }),
          ],
          validations: ["required", "validCardExpirationDate"],
          css: css,
          classes: {
            invalid: "vgs__input--invalid",
            empty: "vgs__input--empty",
          },
        });

        setIsInitialized(true);
      }
    }, [form]);

    /* showing loader until form is being loaded */
    if (isLoading) return <Spinner size="lg" />;

    return (
      <div>
        <div className=" mt-4">
          <div className="form-row">
            <div className="text-left col-md-6 mt-2">
              <label htmlFor="cc-name">Card Holder Name</label>
              <div id="cc-name" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                  {showError && (
                    <ErrorText
                      isValid={form?.state?.CardHolderName?.isValid}
                      message={"Cardholder name is required and must be valid"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="text-left col-md-6 mt-2">
              <label htmlFor="cc-number">Card Number</label>
              <div id="cc-number" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                  {showError && (
                    <ErrorText
                      isValid={form?.state?.CreditCardNumber?.isValid}
                      message={"Card number is required and must be valid"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="text-left col-md-6 mt--3">
              <label htmlFor="cc-cvc">CVV</label>
              <div id="cc-cvc" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                  {showError && (
                    <ErrorText
                      isValid={form?.state?.Cvv?.isValid}
                      message={"CVV is required and must be valid"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`text-left mt--3 ${
                gateway?.enableEnhancedSecurity ? "col-md-3" : "col-md-6"
              }`}
            >
              <label htmlFor="cc-expiration-date">Expiration Date</label>
              <div id="cc-expiration-date" className="form-group">
                <div className="form-control-static">
                  <span className="fake-input"></span>
                  {showError && (
                    <ErrorText
                      isValid={form?.state?.ExpirationDate?.isValid}
                      message={"Expiration date is required and must be valid"}
                    />
                  )}
                </div>
              </div>
            </div>
            {gateway?.enableEnhancedSecurity && (
              <div className="text-left col-md-3 mt--3">
                <label htmlFor="zip-code">Zip Code</label>
                <div id="zip-code" className="form-group">
                  <div className="form-control-static">
                    <div
                      className="px-3 input_checkout"
                      style={{
                        backgroundColor: "#ededed",
                        border: "0.2px light grey",
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        required
                        style={{
                          flex: 1,
                          border: "none",
                          background: "transparent",
                          paddingTop: 13,
                          paddingBottom: 13,
                        }}
                        placeholder="90001"
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          setErrors([]);
                        }}
                        type="text"
                      />
                    </div>
                  </div>
                  {errors?.length > 0 && (
                    <div style={{ marginTop: 5 }}>
                      <ErrorText
                        isValid={errors.length === 0}
                        message={"Zipcode must be valid"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* showing this option when user is logged in */}
            {authContext?.user && (
              <div className="custom-control custom-checkbox ml-2">
                <input
                  className="custom-control-input"
                  id="customCheck1"
                  type="checkbox"
                  onChange={(e) => setSaveCard(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Save this card for future checkout
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default JupiterTokenization;
