export default function findMatchingIndexes(arr1, obj) {
  const indexes = arr1.flatMap((element, i) => {
    return obj.selectedParentValue
      .map((val) => {
        if (
          element.selectedParentValue.label === val.label &&
          element.product_id === obj.product_id
        ) {
          return i;
        }
      })
      .filter((idx) => idx >= 0);
  });
  return indexes;
}
