/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from "react";
import { IoMdLock } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { URL as FRONTENDURL, HOST } from "../../../config";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useCartStore } from "../../../store";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import DB from "../../../utils/DB";
import { DBKeys } from "../../../utils/Keys";
import getCookie from "../../../utils/getCookie";
import logger from "../../../utils/logger";
import "../styles/Home/home.css";
import SocialComponent from "./components/SocialComponent";

export const handleSocialLogin = (provider, restaurant) => {
  localStorage.setItem("oauth_return_url", window.location.href.split("?")[0]);
  localStorage.setItem("oauth_return_page", "customer");
  global.window.location.href = `${HOST}/auth/${provider}?rest=${restaurant._id}`;
};

const Signin = (props) => {
  let history = props.history;
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { theme } = useTheme();
  const { create: login } = useEntity("eat/auth/local");
  const { create: loginByToken } = useEntity("eat/auth/login-by-idt");
  const [rememberMe, setRememberMe] = useState(false);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { restaurant } = useOrderingRestaurant();
  const { clearCart } = useCartStore();
  const { create } = useEntity("checkout-session");

  const yupValidateObject = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    email: Yup.string()
      .email("Email must be a valid format")
      .required("Email is required"),
  });

  const validationObj = {
    email,
    password,
  };

  useEffect(() => {
    if (authContext?.user?.customer) {
      setIsLoading(true);
      let url = new URL(restaurant?.url);
      props.history.push(url.pathname);
    }
  }, [authContext]);

  const handleLogin = async () => {
    if (rememberMe) {
      document.cookie = `email=${email};path=${FRONTENDURL}`;
      document.cookie = `password=${password};path=${FRONTENDURL}`;
    }

    setIsLoading(true);

    if (email === "" && password === "") {
      swal("Error", "Email & password are required", "error");
      setIsLoading(false);

      return;
    }

    try {
      await yupValidateObject.validate(validationObj);

      const lowerCaseEmail = email.toString().toLowerCase();

      const r = await login({
        restaurant: restaurant?.userId?._id,
        email: lowerCaseEmail,
        password,
      });
      if (
        (await DB.storeData(DBKeys.CUSTOMER_USER, r?.data?.user)) &&
        (await DB.storeData(DBKeys.CUSTOMER_TOKEN, r?.data?.accessToken))
      ) {
        clearCart();

        authContext.login(r?.data);
        if (restaurant?.enable2fa) {
          window.localStorage.removeItem("otpDoc");

          const checkoutSessionData = await create(r?.data);

          window.localStorage.setItem(
            "checkoutSessionData",
            JSON.stringify(checkoutSessionData)
          );
        }
      }
      setIsLoading(false);

      toast.success("Logged in Successfully");
      let url = new URL(restaurant?.url);
      if (location?.search !== "") {
        props.history.push(
          `${url.pathname}/${location?.search?.split("=")[1]}`
        );
      } else props.history.push(`${url.pathname}`);
    } catch (error) {
      logger.push({ error, info: "Customer_Login" });
      swal("Failed!", error.message, "error");
      setIsLoading(false);
    }
  };

  const handleLoginByToken = async (token) => {
    setIsLoading(true);
    await DB.storeData(DBKeys.CUSTOMER_TOKEN, token);
    try {
      const res = await loginByToken({
        restaurant: restaurant._id,
      });

      if (
        (await DB.storeData(DBKeys.CUSTOMER_USER, res?.data?.user)) &&
        (await DB.storeData(DBKeys.CUSTOMER_TOKEN, token))
      ) {
        authContext.login({
          user: res?.data?.user,
          token,
        });
        setIsLoading(false);

        let url = new URL(restaurant?.url);
        if (res?.data?.user?.phone) {
          props.history.push(url.pathname);
        } else {
          props.history.push(url.pathname + "/my-account");
        }
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      logger.push({ error, info: "Login" });
      swal(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    if (searchParams.has("error")) {
      swal(searchParams.get("error"));
    } else if (searchParams.has("idt")) {
      handleLoginByToken(searchParams.get("idt"));
    }
  }, [searchParams]);

  useEffect(() => {
    const emailCookie = getCookie("email");
    const passwordCookie = getCookie("password");
    if (emailCookie && passwordCookie) {
      setEmail(emailCookie);
      setPassword(passwordCookie);
    }
  }, []);

  return (
    <OrderingLayout
      isCenter={true}
      loading={isLoading}
      title="Sign in"
      history={history}
      classname="main_ordering_card_wide"
      layout={true}
    >
      <div className="mt-3">
        {restaurant?.ordering?.socialLogin && (
          <SocialComponent className="mb-3" />
        )}
        <div
          className="rounded"
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <input
            className="signin_input_ordering"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
          />

          <MdEmail className="icons_signin" size={30} />
        </div>
        <div
          className="mt-3 mb-4 rounded"
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <input
            className="signin_input_ordering"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

          <IoMdLock className="icons_signin" size={30} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              value={rememberMe}
              onChange={(e) => {
                setRememberMe(e.target.checked);
              }}
            />
            <label
              className="text-md custom-control-label"
              htmlFor="customCheck1"
            >
              Remember me
            </label>
          </div>
          <div
            onClick={() => {
              history.push("forgot-password");
            }}
            style={{
              color: theme.primary,
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Forgot password
          </div>
        </div>
        <Button
          className="mt-4"
          block
          onClick={handleLogin}
          style={{
            backgroundColor: theme.primary,
            color: "white",
            textTransform: "uppercase",
            fontWeight: "400",
            padding: 12,
          }}
        >
          {isLoading ? <Spinner size="sm" color="white" /> : "Sign in"}
        </Button>
        <div className="mt-3">
          Don't have an account?{" "}
          <span
            onClick={() => history.push(`register`)}
            style={{ color: theme.primary, cursor: "pointer" }}
          >
            Sign up
          </span>
        </div>
      </div>
    </OrderingLayout>
  );
};

export default Signin;
