import { toast } from "react-toastify";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useRestaurantStore } from "../../../../store/restaurant";
import { Media } from "reactstrap";

export const OptionsRow = ({ data, ...props }) => {
  const { deleteEntity } = useEntity("modifier-group");
  const { restaurant } = useRestaurantStore();

  let history = useHistory();
  const location = useLocation();

  return (
    <tr {...props} ref={props.refDoc}>
      <th className="text-left">
        <Media>
          <span
            style={{ cursor: "pointer", textTransform: "capitalize" }}
            onClick={() => {
              history.push({
                pathname: "/resturants/add-modifier",
                state: data?._id,
                search: location.search,
              });
            }}
            className="name mb-0 text-sm "
          >
            {data?.name}
          </span>
        </Media>
      </th>
      <td className="text-left">{data.sortOrder}</td>
      <td className="text-left">{data?.jkSoftId || "NA"}</td>

      <td className="text-left">{data.type}</td>

      {!restaurant?.restaurant?.enable_deliverect && (
        <td className="text-left">
          <i
            style={{ fontSize: 17, cursor: "pointer" }}
            className="fa fa-clone mx-1"
            aria-hidden="true"
            onClick={async () => {
              try {
                history.push({
                  pathname: "/resturants/add-modifier",
                  state: { data, clone: true },
                });
                toast.success("Modifier cloned success");
              } catch (error) {}
            }}
          />

          <i
            className="fa fa-trash text-danger mx-2"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id);
                    toast("Modifier Deleted Succesfully");
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
          />
        </td>
      )}
    </tr>
  );
};
