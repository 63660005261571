const HomeOrderingSVG = ({ fill = "#212121" }) => {
  return (
    <div>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 14.3333C15.2091 14.3333 17 12.5425 17 10.3333C17 8.1242 15.2091 6.33334 13 6.33334C10.7909 6.33334 9 8.1242 9 10.3333C9 12.5425 10.7909 14.3333 13 14.3333Z"
          stroke={fill}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.6668 19.6667C19.6668 16.72 16.6815 14.3333 13.0002 14.3333C9.31883 14.3333 6.3335 16.72 6.3335 19.6667"
          stroke={fill}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 1H5C2.79086 1 1 2.79086 1 5V21C1 23.2091 2.79086 25 5 25H21C23.2091 25 25 23.2091 25 21V5C25 2.79086 23.2091 1 21 1Z"
          stroke={fill}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

export default HomeOrderingSVG
