const EmptyPlaceholder = ({ text }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <lottie-player
        src="https://assets4.lottiefiles.com/packages/lf20_dmw3t0vg.json"
        background="transparent"
        speed="1"
        style={{ maxWidth: 300, maxHeight: 200 }}
        autoplay
      ></lottie-player>
      <h1 className="mt-3" style={{ textAlign: "center" }}>
        {text || "Currently no data here"}
      </h1>
    </div>
  );
};

export default EmptyPlaceholder;
