import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import swal from "sweetalert"
import apiCaller from "../../api/apiCaller"
import { env } from "../../config"
import { AuthContext } from "../../context"
import useOrdering from "../../store/ordering"
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant"
import logger from "../../utils/logger"

function TilledTokenization({ formik, onSuccess, paymentFormRef }) {
  const { gateway } = useOrdering()
  const { restaurant: orderingRestaurant } = useOrderingRestaurant()
  const authContext = useContext(AuthContext)
  const [tilled, setTilled] = useState()
  const [saveCard, setSaveCard] = useState(false)

  const paymentMethodTypes = {
    creditCard: {
      type: "card",
      fields: {
        cardNumber: {
          id: "#card-number-element",
          placeholder: "4111 1111 1111 1111",
        },
        cardExpiry: {
          id: "#card-expiration-element",
          placeholder: "MM/YY",
        },
        cardCvv: {
          id: "#card-cvv-element",
          placeholder: "123",
        },
      },
    },
  }

  useImperativeHandle(
    paymentFormRef,
    () => ({
      async handleSubmit() {
        try {
          const payload = {
            amount: formik.values.billing.total,
            gatewayId: gateway?._id,
            partnerId: orderingRestaurant?.partner?.partner?._id,
          }
          console.log("tilled submitting", payload)
          // create intent
          const response = await apiCaller(
            "/eat/ordering/payment/create-intent",
            {
              method: "POST",
              body: payload,
            }
          )

          console.log("intent response", response, paymentMethodTypes)
          if (response.code !== "SUCCESS") {
            throw new Error("Invalid response from server")
          }
          const el = document.querySelector("#zip-pm")
          if (el.value.length > 10) {
            throw new Error(
              "billing_details.address.zip must be shorter than or equal to 10 characters"
            )
          }
          const paymentMethod = {
            // form: paymentMethodTypes.creditCard.form,
            type: paymentMethodTypes.creditCard.type,
            billing_details: {
              name:
                authContext?.user?.customer?.name ||
                formik.values.guest_details?.name ||
                "unnamed",
              address: {
                country: "US",
                zip: el?.value || "",
              },
            },
          }
          console.log("start tokenisation", tilled)
          const pmDoc = await tilled.createPaymentMethod(paymentMethod)
          if (pmDoc.id) {
            onSuccess({
              paymentMethod: pmDoc,
              saveCard,
              intent: response.data,
            })
          } else {
            throw new Error("Invalid response from server")
          }
        } catch (error) {
          console.log("tilled error", error)
          logger.push({ error, info: "TilledTokenisation" })
          swal(
            "Error",
            error.message?.[0] || error?.message || "Tokenization failed.",
            "error"
          )
          formik.setSubmitting(false)
          swal("Error", error.message, "error")
        }
      },
    }),
    [gateway, formik, orderingRestaurant, tilled, saveCard]
  )

  const fieldOptions = {
    styles: {
      base: {
        fontFamily:
          '-apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        color: "#304166",
        fontWeight: "400",
        fontSize: "16px",
        padding: "10px",
      },
      invalid: {
        ":hover": {
          textDecoration: "underline dotted red",
        },
        color: "#777777",
      },
      valid: {
        color: "#32CD32",
      },
    },
  }

  const paymentTypeObj = paymentMethodTypes.creditCard
  const public_key = gateway?.tilledPublishableKey
  const account_id = gateway?.tilledAccountId

  async function initTilled() {
    console.log("Initializing Tilled", paymentTypeObj)

    // Create a new tilled instance
    paymentTypeObj.tilled = new window.Tilled(public_key, account_id, {
      sandbox: env !== "production",
      log_level: 0,
    })

    // await the form
    paymentTypeObj.form = await paymentTypeObj.tilled.form({
      payment_method_type: paymentTypeObj.type,
    })

    // loop through fields and inject them
    Object.entries(paymentTypeObj.fields).forEach((entry) => {
      const [field, options] = entry
      const fieldId = options.id
      const placeholder = options.placeholder
      const fieldElement = document.getElementById(fieldId.slice(1))
      if (!fieldElement) return

      if (fieldElement.childElementCount === 0)
        paymentTypeObj.form
          .createField(
            field,
            fieldOptions ? { ...fieldOptions, placeholder } : {}
          )
          .inject(fieldElement)
    })

    // Build the form
    paymentTypeObj.form.build()
    console.log("Tilled initialized", paymentTypeObj)
    return paymentTypeObj.tilled
  }

  function teardown() {
    if (paymentTypeObj.form) {
      paymentTypeObj.form.teardown((success) => {
        paymentTypeObj.form = undefined
        console.log("tilled: teardown has run successfully:", success)
        const script = document.getElementById("tilledjs-script")
        if (script) {
          script.remove()
          console.log("tilled: removed script")
        }
      })
    }
  }

  useEffect(() => {
    if (!tilled) {
      console.log("tilled: script not loaded")
      const script = document.createElement("script")
      script.id = "tilledjs-script"
      script.src = "https://js.tilled.com/v2"
      script.async = true
      document.body.appendChild(script)
      script.addEventListener("load", () => {
        console.log("tilled: script loaded,initing tilled")
        initTilled().then((t) => {
          console.log("got tilled", t)
          setTilled(t)
        })
        // setIsLoading(false);
      })
    }

    return () => {
      console.log("tilled: unmounting")
      teardown()
    }
  }, [])

  // useEffect(() => {
  //   if (!isLoading && !isInitialized) {
  //     initTilled().then((t) => {
  //       console.log("got tilled", t);
  //       setTilled(t);
  //     });
  //   }
  //   return () => {
  //     teardown();
  //   };
  // }, [isLoading]);

  // useEffect(() => {
  //   if (tilled && !isInitialized) {
  //     setIsInitialized(true);
  //   }
  // }, [tilled]);

  return (
    <div>
      <div className=" mt-4">
        <div className="form-row">
          <div className="text-left col-md-6 mt-2">
            <label htmlFor="inputAddress2">Card Number</label>
            <div
              className="form-group tilled-js_input border"
              style={{
                backgroundColor: "#eee",
                paddingLeft: 10,
              }}
            >
              <div
                id="card-number-element"
                className="fake-input"
                style={{
                  height: "48px",
                }}
              ></div>
              <div className="card-brand-icon"></div>
            </div>
          </div>
          <div className="text-left col-md-6 mt-2">
            <label htmlFor="inputAddress2">Card Holder Name</label>
            <div
              className="form-group border"
              style={{
                backgroundColor: "#eee",
                paddingLeft: 10,
              }}
            >
              <input
                placeholder="Joe Business"
                style={{
                  height: "48px",
                  background: "none",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="text-left col-md-6 mt-2">
            <label htmlFor="inputAddress2">Expiration</label>
            <div
              className="form-group tilled-js_input border"
              style={{
                backgroundColor: "#eee",
                paddingLeft: 10,
              }}
            >
              <div
                id="card-expiration-element"
                className="fake-input"
                style={{
                  height: "48px",
                }}
              ></div>
            </div>
          </div>
          <div className="text-left col-md-3 mt-2">
            <label htmlFor="inputAddress2">CVV</label>
            <div
              className="form-group tilled-js_input border"
              style={{
                backgroundColor: "#eee",
                paddingLeft: 10,
              }}
            >
              <div
                id="card-cvv-element"
                className="fake-input"
                style={{
                  height: "48px",
                }}
              ></div>
            </div>
          </div>
          <div className="text-left col-md-3 mt-2">
            <label htmlFor="zip-pm">ZIP</label>
            <div
              className="form-group border"
              style={{
                backgroundColor: "#eee",
                paddingLeft: 10,
              }}
            >
              <input
                id="zip-pm"
                placeholder="12345"
                style={{
                  height: "48px",
                  background: "none",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
          </div>
          {/* showing this option when user is logged in */}
          {authContext?.user && (
            <div className="custom-control custom-checkbox ml-4 col-md-12">
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
                onChange={(e) => setSaveCard(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Save this card for future checkout
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default forwardRef(TilledTokenization)
