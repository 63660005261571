import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { useRestaurantStore } from "../../../../store/restaurant";
import { getSeparatedValues } from "../../../../utils/commaSeparator";
import { truncateString } from "../../../../utils/truncate";

export const ProductRow = ({ history, data, ...props }) => {
  const { deleteEntity } = useEntity("item");
  const { updateEntity, loading: updateLoading } = useEntity("item");
  const { restaurant } = useRestaurantStore();

  const location = useLocation();

  return (
    <tr {...props} ref={props.refDoc}>
      <td className="text-left">{data?.jkSoftId || "NA"}</td>
      <th
        onClick={() => {
          history.push({
            pathname: "/resturants/add-item",
            state: data?._id,
            search: location.search,
          });
        }}
        scope="row"
        className="text-left pointer"
      >
        <span className="name mb-0 text-sm ">
          {truncateString(data?.name, 50)}
        </span>
      </th>
      <td className="text-left">${data?.price}</td>
      <td className="text-left">{data?.plu}</td>
      <td className="text-left">
        {!data?.allLocationsSelected ? (
          <>
            {getSeparatedValues(
              data?.assignedToLocations?.slice(0, 3)?.map((item) => item?.name)
            )}
            {data?.assignedToLocations.length > 3 && "..."}
          </>
        ) : (
          <span className="name mb-0 text-sm ">All</span>
        )}
      </td>
      <td className="text-left">
        {data?.categories?.length === 0 && "Not selected"}
        {getSeparatedValues(
          data?.categories?.slice(0, 3)?.map((item) => item?.categoryName)
        )}
        {data?.categories.length > 3 && "..."}
      </td>
      <td className="text-left">
        {data?.tag?.length === 0 && "Not selected"}
        {getSeparatedValues(data?.tag?.slice(0, 3)?.map((item) => item?.name))}
        {data?.tag.length > 3 && "..."}
      </td>
      <td>
        {updateLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.status}
              type="checkbox"
              disabled={restaurant?.restaurant?.enable_deliverect}
              onChange={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: data?.status === true ? false : true,
                  });
                  toast("Item updated successfully");
                } catch (error) {
                  toast(error.message);
                }
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Off"
              data-label-on="On"
            />
          </label>
        )}
      </td>
      {!restaurant?.restaurant?.enable_deliverect && !isMobile && (
        <td className="text-left">
          <i
            style={{ fontSize: 17, cursor: "pointer" }}
            className="fa fa-clone mx-1"
            aria-hidden="true"
            onClick={async () => {
              try {
                history.push({
                  pathname: "/resturants/add-item",
                  state: { data, clone: true },
                  search: location.search,
                });
                toast.success("Items cloned success");
              } catch (error) {}
            }}
          />

          <i
            className="fa fa-trash text-danger mx-2"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id);
                    toast("Item Deleted Succesfully");
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
          />
        </td>
      )}
    </tr>
  );
};
