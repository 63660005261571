import { useFormik } from "formik";
import { useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { useEntity } from "../../../hooks/useEntity";
import { useLocation } from "react-router-dom";

import { useAdminStore } from "../../../store/admin";
import ErrorLable from "../../../utils/ErrorLable";
import { validationSchemaReEngage } from "../../../utils/schemas/ReEngage";

const toDate = (iso) => {
  return new Date(iso).toISOString().split("T")[0];
};

const AddReEngage = ({ history }) => {
  const { restaurant } = useAdminStore();
  const urlLoc = useLocation();
  const {
    findOne,
    loading: locationLoading,
    entity: restaurantDoc,
    refetch,
  } = useEntity("restaurant");
  const { find: findLocations, entities: locations } = useEntity("location");
  const {
    entity: campaign,
    findOne: findCampaign,
    create: createCampaign,
    updateEntity: updateCampaign,
    loading,
  } = useEntity("reengage");

  useEffect(() => {
    findOne(restaurant);
    findLocations({
      resturant: restaurant,
    });
    if (urlLoc?.state) {
      findCampaign(urlLoc?.state);
    }
  }, [restaurant]);

  const {
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
    setValues,
    errors,
    touched,
    setFieldValue,
    handleBlur,
  } = useFormik({
    initialValues: {
      campaignName: "",
      startDate: "",
      endDate: "",
      dayOfTheWeekToRun: "sunday",
      maxCount: "",
      intervalValue: "",
      intervalType: "week",
      usesPerCoupon: "",
      deleteUnusedCoupons: false,
      locations: [],
      orderCondition: "placed",
      conditionWithinValue: 0,
      conditionWithinInterval: "week",
      couponDiscountType: "5",
      couponValidityValue: "",
      couponValidityInterval: "week",
      runImmediately: false,
    },
    onSubmit: async (values, { resetForm }) => {
      const allLocation = !!values.locations.find((it) => it.value === "all");
      let data = {
        campaignName: values.campaignName,
        startDate: values.startDate,
        endDate: values.endDate,
        dayOfTheWeekToRun: values.dayOfTheWeekToRun,
        couponSetting: {
          maxCount: values.maxCount,
          interval: values.intervalValue,
          intervalType: values.intervalType,
          usesPerCoupon: values.usesPerCoupon,
          deleteUnusedCoupons: values.deleteUnusedCoupons,
        },
        conditions: {
          orderCondition: values.orderCondition,
          conditionWithinValue: values.conditionWithinValue,
          conditionWithinInterval: values.conditionWithinInterval,
        },
        action: {
          couponValidityValue: values.couponValidityValue,
          couponValidityInterval: values.couponValidityInterval,
          couponDiscountType: values.couponDiscountType,
        },
        locations: allLocation ? [] : values.locations.map((it) => it.value),
        allLocation,
        partner: restaurantDoc?.data?.partner,
        restaurant: restaurantDoc?.data?._id,
        runImmediately: values.runImmediately,
      };
      console.log({ data });
      try {
        if (urlLoc?.state) {
          await updateCampaign(urlLoc?.state, data);
          toast.success("Campaign has been updated successfully");
        } else {
          await createCampaign(data);
          toast.success("Campaign has been added successfully");
        }
        refetch();
        // history.back();
        history.goBack();
      } catch (error) {
        toast.error(error.message);
      }
    },
    validationSchema: validationSchemaReEngage,
  });

  useEffect(() => {
    if (campaign) {
      const entity = campaign?.data;
      setValues({
        campaignName: entity?.campaignName,
        startDate: toDate(entity?.startDate),
        endDate: toDate(entity?.endDate),
        dayOfTheWeekToRun: entity?.dayOfTheWeekToRun,
        maxCount: entity?.couponSetting?.maxCount,
        intervalValue: entity?.couponSetting?.interval,
        intervalType: entity?.couponSetting?.intervalType,
        usesPerCoupon: entity?.couponSetting?.usesPerCoupon,
        deleteUnusedCoupons: entity?.couponSetting?.deleteUnusedCoupons,
        locations: entity?.allLocation
          ? [
              {
                label: "All",
                value: "all",
              },
            ]
          : entity.locations.map((it) => ({
              label: it.displayAddress,
              value: it._id,
            })),
        // locations: [],
        orderCondition: entity?.conditions?.orderCondition,
        conditionWithinValue: entity?.conditions?.conditionWithinValue,
        conditionWithinInterval: entity?.conditions?.conditionWithinInterval,
        couponDiscountType: entity?.action?.couponDiscountType,
        couponValidityValue: entity?.action?.couponValidityValue,
        couponValidityInterval: entity?.action?.couponValidityInterval,
      });
    }
  }, [campaign]);

  console.log("doc", restaurantDoc);

  return (
    <>
      <TimelineHeader
        name="ReEngage"
        parentName="Marketing"
        showBtn={true}
        path="/resturants"
      >
        <>
          <Button color="primary" onClick={handleSubmit}>
            {isSubmitting ? (
              <Spinner color="white" size="sm" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </>
      </TimelineHeader>
      <Container fluid>
        <Card className="mt--6">
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <Form>
                <FormGroup className="row">
                  <Label className="form-control-label text-left" md="3">
                    Campaign name
                  </Label>
                  <Col md="9">
                    <Input
                      onChange={handleChange("campaignName")}
                      value={values?.campaignName}
                      type="text"
                      onBlur={handleBlur("campaignName")}
                      className={`form-control ${
                        errors.campaignName && touched.campaignName
                          ? "is-invalid"
                          : ""
                      }`}
                    ></Input>
                    {errors.campaignName && touched.campaignName && (
                      <ErrorLable message={errors.campaignName} />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="form-control-label text-left" md="3">
                    Date Start
                  </Label>
                  <Col md="9">
                    <Input
                      min={new Date().toISOString().split("T")[0]}
                      max={
                        values?.endDate &&
                        new Date(values?.endDate).toISOString().split("T")[0]
                      }
                      onBlur={handleBlur("startDate")}
                      type="date"
                      onChange={(e) => {
                        setFieldValue("startDate", e.target.value);
                      }}
                      value={values?.startDate}
                      className={`form-control ${
                        errors.startDate && touched.startDate
                          ? "is-invalid"
                          : ""
                      }`}
                    ></Input>
                    {errors.startDate && touched.startDate && (
                      <ErrorLable message={errors.startDate} />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="form-control-label text-left" md="3">
                    Date End
                  </Label>
                  <Col md="9">
                    <Input
                      min={
                        values.startDate &&
                        new Date(values?.startDate).toISOString().split("T")[0]
                      }
                      onChange={(e) => {
                        setFieldValue("endDate", e.target.value);
                      }}
                      onBlur={handleBlur("endDate")}
                      value={values?.endDate}
                      type="date"
                      className={`form-control ${
                        errors.endDate && touched.endDate ? "is-invalid" : ""
                      }`}
                    ></Input>
                    {errors.endDate && touched.endDate && (
                      <ErrorLable message={errors.endDate} />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="form-control-label text-left" md="3">
                    Day of week to send
                  </Label>
                  <Col md="9">
                    <Input
                      onChange={handleChange("dayOfTheWeekToRun")}
                      value={values?.dayOfTheWeekToRun}
                      type="select"
                      className={`form-control ${
                        errors.dayOfTheWeekToRun && touched.dayOfTheWeekToRun
                          ? "is-invalid"
                          : ""
                      }`}
                      onBlur={handleBlur("dayOfTheWeekToRun")}
                    >
                      <option value="sunday">Sunday</option>{" "}
                      <option value="monday">Monday</option>{" "}
                      <option value="tuesday">Tuesday</option>{" "}
                      <option value="wednesday">Wednesday</option>{" "}
                      <option value="thursday">Thursday</option>{" "}
                      <option value="friday">Friday</option>{" "}
                      <option value="saturday">Saturday</option>{" "}
                    </Input>
                    {errors.dayOfTheWeekToRun && touched.dayOfTheWeekToRun && (
                      <ErrorLable message={errors.dayOfTheWeekToRun} />
                    )}
                    <div
                      style={{
                        display: "flex",
                        marginLeft: 21,
                        marginTop: 10,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Input
                        type="checkbox"
                        id="reengage-rn-immediately"
                        value={values.runImmediately}
                        onChange={(e) => {
                          setFieldValue("runImmediately", e.target.checked);
                        }}
                      />
                      <Label for="reengage-rn-immediately">
                        Run immediately
                      </Label>
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup
                  className="row text-left"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Label className="form-control-label text-left" md="3">
                    Location
                  </Label>

                  <Col md="9">
                    <Select
                      isSearchable={false}
                      name="locations"
                      onChange={(val) => setFieldValue("locations", val)}
                      isMulti
                      onBlur={handleBlur("locations")}
                      closeMenuOnSelect={false}
                      value={values?.locations}
                      isLoading={locationLoading}
                      loadingMessage={() => "Loading..."}
                      options={
                        locations?.data?.data?.length > 0
                          ? [
                              { displayAddress: "All", _id: "all" },
                              ...locations?.data?.data,
                            ].map((it) => {
                              return {
                                label: it?.displayAddress,
                                value: it?._id,
                              };
                            })
                          : locationLoading
                          ? [
                              {
                                label: "Loading...",
                                value: "loading",
                              },
                            ]
                          : []
                      }
                    />
                    {errors.locations && touched.locations && (
                      <ErrorLable message={errors.locations} />
                    )}
                  </Col>
                </FormGroup>

                <FormGroup
                  className="row"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Label className="form-control-label text-left" md="3">
                    Uses per customer
                  </Label>

                  <Col md={2}>
                    <Input
                      value={values.usesPerCoupon}
                      onChange={handleChange("usesPerCoupon")}
                      onBlur={handleBlur("usesPerCoupon")}
                    />
                    {errors.usesPerCoupon && touched.usesPerCoupon && (
                      <ErrorLable message={errors.usesPerCoupon} />
                    )}
                  </Col>
                  <Col md={1}>
                    <Label>times</Label>
                  </Col>
                </FormGroup>
                <FormGroup
                  className="row"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Label
                    className="form-control-label text-left"
                    md="3"
                  ></Label>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 20,
                    }}
                  >
                    <Input
                      type="checkbox"
                      checked={values.deleteUnusedCoupons}
                      onChange={handleChange("deleteUnusedCoupons")}
                      id="reengage-delete-unused-coupons"
                    />
                    <Label for="reengage-delete-unused-coupons" check>
                      Disable all unused coupons when a new coupon is generated
                    </Label>
                  </Col>
                </FormGroup>

                <FormGroup
                  className="row"
                  style={{
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Label className="form-control-label text-left" md="3">
                    Customer Condition
                  </Label>
                  <Col md={2}>
                    <Label>If customer</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      onChange={handleChange("orderCondition")}
                      value={values?.orderCondition}
                      type="select"
                      className={`form-control ${
                        errors.orderCondition && touched.orderCondition
                          ? "is-invalid"
                          : ""
                      }`}
                      onBlur={handleBlur("orderCondition")}
                    >
                      <option value="placed">has</option>{" "}
                      <option value="not_placed">has not</option>{" "}
                    </Input>
                    {errors.orderCondition && touched.orderCondition && (
                      <ErrorLable message={errors.orderCondition} />
                    )}
                  </Col>
                  <Col md={2}>
                    <Label>ordered within</Label>
                  </Col>
                  <Col md={1}>
                    <Input
                      value={values.conditionWithinValue}
                      onChange={handleChange("conditionWithinValue")}
                      onBlur={handleBlur("conditionWithinValue")}
                    />
                    {errors.conditionWithinValue &&
                      touched.conditionWithinValue && (
                        <ErrorLable message={errors.conditionWithinValue} />
                      )}
                  </Col>
                  <Col md={2}>
                    <Input
                      onChange={handleChange("conditionWithinInterval")}
                      value={values?.conditionWithinInterval}
                      type="select"
                      className={`form-control ${
                        errors.conditionWithinInterval &&
                        touched.conditionWithinInterval
                          ? "is-invalid"
                          : ""
                      }`}
                      onBlur={handleBlur("conditionWithinInterval")}
                    >
                      <option value="day">Days(s)</option>{" "}
                      <option value="week">Week(s)</option>{" "}
                      <option value="month">Month(s)</option>{" "}
                      <option value="year">Year(s)</option>{" "}
                    </Input>
                    {errors.conditionWithinInterval &&
                      touched.conditionWithinInterval && (
                        <ErrorLable message={errors.conditionWithinInterval} />
                      )}
                  </Col>
                </FormGroup>
                <FormGroup
                  className="row"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Label className="form-control-label text-left" md="3">
                    Coupon
                  </Label>
                  <Col md={2}>
                    <Label>Send an email with a</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      onChange={handleChange("couponDiscountType")}
                      value={values?.couponDiscountType}
                      type="select"
                      className={`form-control ${
                        errors.couponDiscountType && touched.couponDiscountType
                          ? "is-invalid"
                          : ""
                      }`}
                      onBlur={handleBlur("couponDiscountType")}
                    >
                      {[5, 10, 15, 20, 30, 40, 50, 75].map((it) => {
                        return <option value={it}>{it}%</option>;
                      })}
                      <option value="free_delivery">Free Delivery</option>{" "}
                    </Input>
                    {errors.couponDiscountType &&
                      touched.couponDiscountType && (
                        <ErrorLable message={errors.couponDiscountType} />
                      )}
                  </Col>
                  <Col md={2}>
                    <Label>coupon valid for</Label>
                  </Col>
                  <Col md={1}>
                    <Input
                      value={values.couponValidityValue}
                      onChange={handleChange("couponValidityValue")}
                      onBlur={handleBlur("couponValidityValue")}
                    />
                    {errors.couponValidityValue &&
                      touched.couponValidityValue && (
                        <ErrorLable message={errors.couponValidityValue} />
                      )}
                  </Col>
                  <Col md={2}>
                    <Input
                      onChange={handleChange("couponValidityInterval")}
                      value={values?.couponValidityInterval}
                      type="select"
                      className={`form-control ${
                        errors.couponValidityInterval &&
                        touched.couponValidityInterval
                          ? "is-invalid"
                          : ""
                      }`}
                      onBlur={handleBlur("couponValidityInterval")}
                    >
                      <option value="day">Days(s)</option>{" "}
                      <option value="week">Week(s)</option>{" "}
                      <option value="month">Month(s)</option>{" "}
                      <option value="year">Year(s)</option>{" "}
                    </Input>
                    {errors.couponValidityInterval &&
                      touched.couponValidityInterval && (
                        <ErrorLable message={errors.couponValidityInterval} />
                      )}
                  </Col>
                </FormGroup>
              </Form>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AddReEngage;
