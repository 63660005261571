import { isMobile } from "react-device-detect";
import { Card, Col, Input, Row } from "reactstrap";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import useOrdering from "../../../store/ordering";
import { useTheme } from "../../../store/theme";
import Header from "../auth/components/Header";
import Footer from "../components/Footer";
import DetailsPanel from "./components/DetailsPanel";
import useScreenSize from "../../../hooks/useScreenSize";
import { useLayoutEffect } from "react";

const CateringHome = (props) => {
  const { theme } = useTheme();

  const { setCateringData, cateringData } = useOrdering();

  const { width } = useScreenSize();

  return (
    <RestaurantWrapper>
      <div
        className="main_ordering_layout_wrapper"
        style={{ maxWidth: "1440px", margin: "auto" }}
      >
        <Header startOver={true} account={true} history={props.history} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="mt--6"
        >
          <Card
            style={{
              boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.25)",
              marginTop: 30,
              borderRadius: "8px ",
              padding: "0px",
              backgroundColor: "#fff",
            }}
            className="catering_details_panel mb--2"
          >
            <Row>
              <Col md={6}>
                <h3 className="px-3 py-2 mt-1">Catering Options</h3>
              </Col>
              <Col md={6}>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: width < 500 ? 10 : 0,
                    justifyContent: width < 500 ? "start" : "end",
                  }}
                >
                  <div
                    className="mx-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label className="mr-3">I want to:</label>
                    <div>
                      <Input
                        style={{ minHeight: 18 }}
                        checked={cateringData?.enquiry}
                        onChange={(e) => {
                          setCateringData({
                            method: "pickup",
                            date: "",
                            time: "",
                            count: 0,
                            occasion: "",
                            enquiry:
                              e.target.value === "enquiry" ? true : false,
                          });
                        }}
                        type="select"
                      >
                        <option value={"order"}>Place an order</option>
                        <option value="enquiry">Submit order inquiry</option>
                      </Input>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12} className="mt-2" style={{}}>
                <div
                  style={{ width: "100%", height: 1, backgroundColor: "#eee" }}
                />
              </Col>
            </Row>

            <DetailsPanel history={props.history} theme={theme} />
          </Card>
        </div>
      </div>

      {width < 500 ? (
        <Footer />
      ) : (
        <div style={{ position: "absolute", bottom: 20, left: "45%" }}>
          <Footer />
        </div>
      )}
    </RestaurantWrapper>
  );
};

export default CateringHome;
