import { useFormik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { Button, Col, Form, FormGroup, Input, Modal } from "reactstrap";
import groupDaysByTime from "../../../../utils/groupedDays";
import ungroupDaysByTime from "../../../../utils/ungroupDays";
import AddAvailabilityHours from "../../settings/components/AddAvailablityHours";
import { toast } from "react-toastify";

export const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function AvailablityMenuModal({
  onChangeSlots,
  slots,
  onIsAvailableDailyChange,
  isAvailableDaily,
  open,
  toggle,
}) {
  const { setFieldValue, values, setValues, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        isAvailableDaily: false,
        openModal: false,
        selectedDays: [],
        startDate: "",
        endDate: "",
        groupedSlots: [],
        activeIndex: null,
      },
      onSubmit: (values, { setFieldValue }) => {
        if (values?.selectedDays?.length <= 0) {
          toast.error("Please select days");
          return;
        }

        if (values?.startDate === "" || values?.endDate === "") {
          toast.error("Please select valid time");
          return;
        }

        const slotsArray = values.selectedDays.map((day) => {
          return {
            day: day,
            startDate: values.startDate,
            endDate: values.endDate,
          };
        });
        onChangeSlots([...slots, ...slotsArray]);
        setFieldValue("startDate", "");
        setFieldValue("endDate", "");
        setFieldValue("selectedDays", []);
      },
    });

  const handleDeleteRow = (index) => {
    values.groupedSlots.splice(index, 1);
    setFieldValue("groupedSlots", [...values.groupedSlots]);
    const days = ungroupDaysByTime(values.groupedSlots);
    onChangeSlots(days);
  };

  const handleEditRow = (index) => {
    setFieldValue("openModal", true);
    setFieldValue("activeIndex", index);
  };

  useEffect(() => {
    if (slots.length > 0) {
      const daysArray = groupDaysByTime(slots);
      setFieldValue("groupedSlots", [...daysArray]);
    }
  }, [slots]);

  console.log(values.selectedDays);
  return (
    <Modal
      isOpen={open}
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-white text-dark"
      size="lg"
    >
      <div
        style={{
          background: "#fff",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Form>
          <FormGroup className="row">
            <Col md="4">
              <label
                style={{ fontSize: 15, fontWeight: "700" }}
                htmlFor="availability"
              >
                Availability
              </label>
            </Col>
            <Col md="8">
              <Input
                id="status"
                multiple=""
                type="select"
                value={isAvailableDaily ? "All Days" : "Specific Time"}
                onChange={(e) => {
                  setFieldValue(
                    "isAvailableDaily",
                    e.target.value === "All Days"
                  );
                  onIsAvailableDailyChange(e.target.value === "All Days");
                }}
              >
                <option value="Specific Time">Specific Time</option>
                <option value="All Days">All Days</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
        <div style={{ marginTop: 10 }} />
        {isAvailableDaily ? (
          <div>
            <span className="text-success">
              This menu will be available everyday
            </span>
          </div>
        ) : (
          <>
            <div className="py-2 px-4 mt-4 availability_row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {days.map((day, index) => (
                  <div className={`${index === 0 ? "" : "ml-3"}`}>
                    <Button
                      style={{ fontSize: 12 }}
                      disabled={
                        slots.filter((slt) => slt.day === day).length >= 2
                      }
                      color={
                        values.selectedDays.includes(day)
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => {
                        if (values.selectedDays.includes(day)) {
                          const indx = values.selectedDays.findIndex(
                            (d) => d === day
                          );
                          values.selectedDays.splice(indx, 1);
                          setValues(values);
                        } else {
                          const dayData = [...values.selectedDays, day];
                          console.log(dayData);
                          setFieldValue("selectedDays", [...dayData]);
                        }
                      }}
                      size="sm"
                    >
                      {day.substring(0, 3)}
                    </Button>
                  </div>
                ))}
              </div>

              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {values.endDate !== "" ? (
                    <span className="text-sm">
                      {moment(
                        new Date(
                          0,
                          0,
                          0,
                          values?.startDate?.split(":")[0],
                          values?.startDate?.split(":")[1]
                        )
                      ).format("LT")}{" "}
                      -{" "}
                      {moment(
                        new Date(
                          0,
                          0,
                          0,
                          values?.endDate?.split(":")[0],
                          values?.endDate?.split(":")[1]
                        )
                      ).format("LT")}
                    </span>
                  ) : (
                    <Button
                      className="mt-2"
                      size="sm"
                      disabled={values.selectedDays?.length === 0}
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        setFieldValue("openModal", true);
                      }}
                    >
                      Add
                    </Button>
                  )}
                </div>
              </div>

              <div className="mt-2">
                <Button
                  disabled={values.selectedDays?.length === 0}
                  className="bg-red px-3 py-2 rounded"
                  style={{ cursor: "pointer" }}
                  onClick={handleSubmit}
                  size="sm"
                >
                  <FaPlus color="white" />
                </Button>
              </div>
            </div>
            {values.groupedSlots.map((groupSlot, i) => {
              return (
                <div className="py-2 px-4 mt-4 availability_row">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {days.map((day, index) => (
                      <div className={`${index === 0 ? "" : "ml-3"}`}>
                        <Button
                          style={{ fontSize: 12 }}
                          size="sm"
                          disabled={true}
                          color={
                            groupSlot.days.includes(day)
                              ? "primary"
                              : "secondary"
                          }
                        >
                          {day.substring(0, 3)}
                        </Button>
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <span className="text-sm">
                        {groupSlot?.schedule?.startDate !== "" &&
                          moment(
                            new Date(
                              0,
                              0,
                              0,
                              groupSlot?.schedule?.startDate?.split(":")[0],
                              groupSlot?.schedule?.startDate?.split(":")[1]
                            )
                          ).format("LT")}{" "}
                        -{" "}
                        {groupSlot?.schedule?.endDate !== "" &&
                          moment(
                            new Date(
                              0,
                              0,
                              0,
                              groupSlot?.schedule?.endDate?.split(":")[0],
                              groupSlot?.schedule?.endDate?.split(":")[1]
                            )
                          ).format("LT")}
                      </span>
                      <FaPencilAlt
                        onClick={() => handleEditRow(i)}
                        style={{ cursor: "pointer" }}
                        className="mx-2"
                        color="black"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <Button
                      className="bg-red px-3 py-2 rounded"
                      style={{ cursor: "pointer" }}
                      size="sm"
                      onClick={() => handleDeleteRow(i)}
                    >
                      <FaTrash color="white" />
                    </Button>
                  </div>
                </div>
              );
            })}
          </>
        )}

        <AddAvailabilityHours
          open={values.openModal}
          value={values?.groupedSlots?.[values?.activeIndex]?.schedule}
          toggle={() => setFieldValue("openModal", false)}
          onSubmit={(data) => {
            if (values?.groupedSlots?.[values?.activeIndex]?.schedule) {
              const schedule =
                values?.groupedSlots?.[values?.activeIndex]?.schedule;
              schedule.startDate = data?.from;
              schedule.endDate = data?.to;
              setFieldValue("groupedSlots", [...values.groupedSlots]);
              const slotsArr = ungroupDaysByTime(values.groupedSlots);
              onChangeSlots(slotsArr);

              setFieldValue("activeIndex", null);
            } else {
              setFieldValue("startDate", data?.from);
              setFieldValue("endDate", data?.to);
            }
          }}
        />
      </div>
      <div className="modal-footer">
        <Button
          size="md"
          onClick={() => {
            if (slots.length === 0 && !values.isAvailableDaily) {
              toast.error("Please add atleast one schedule");
              return;
            }
            toast.success("Availability Saved");
            toggle();
          }}
          color="success"
        >
          Save
        </Button>
        <Button
          color="danger"
          size="md"
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
