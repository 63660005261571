import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

export default function Account({ errors, touched, values, handleChange }) {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col md="6">
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md="4" style={{ textAlign: "left" }}>
              Restaurant ID
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <Input
                  type="text"
                  name="externalLinkId"
                  value={values?.externalLinkId}
                  className={`form-control ${
                    errors.externalLinkId && touched.externalLinkId
                      ? "is-invalid"
                      : ""
                  }`}
                  disabled
                />
                <InputGroupAddon
                  addonType="append"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => copyToClipboard(values?.externalLinkId)}
                >
                  <InputGroupText>
                    <i
                      className="fa fa-copy"
                      style={{ fontSize: 12, cursor: "pointer" }}
                      aria-hidden="true"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md="4" style={{ textAlign: "left" }}>
              Name
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className="fa fa-bank"
                      style={{ fontSize: 12 }}
                      aria-hidden="true"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="account_name"
                  onChange={handleChange}
                  value={values?.account_name}
                  className={`form-control ${
                    errors.account_name && touched.account_name
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>

        <Col md="6">
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md="4" style={{ textAlign: "left" }}>
              Email
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className="fa fa-envelope"
                      style={{ fontSize: 14 }}
                      aria-hidden="true"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="account_email"
                  value={values?.account_email}
                  onChange={handleChange}
                  className={`form-control ${
                    errors.account_email && touched.account_email
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="6">
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md="4" style={{ textAlign: "left" }}>
              Owner Name
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className="fa fa-bank"
                      style={{ fontSize: 12 }}
                      aria-hidden="true"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="owner_name"
                  onChange={handleChange}
                  value={values?.owner_name}
                  className={`form-control ${
                    errors.owner_name && touched.owner_name ? "is-invalid" : ""
                  }`}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>

        <Col md="6">
          <Row>
            <Col md="4" style={{ textAlign: "left" }}>
              Master Order Email
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className="fa fa-envelope"
                      style={{ fontSize: 14 }}
                      aria-hidden="true"
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="master_order_email"
                  value={values?.master_order_email}
                  onChange={handleChange}
                  className={`form-control ${
                    errors.master_order_email && touched.master_order_email
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
        <Col md="12" className="mt-4">
          <Row>
            <Col md="2" style={{ textAlign: "left" }}>
              Blocked Emails
            </Col>
            <Col md="10" className={isMobile && "my-2"}>
              <textarea
                type="text"
                name="blockedEmails"
                value={values?.blockedEmails}
                onChange={handleChange}
                className={`form-control ${
                  errors.blockedEmails && touched.blockedEmails
                    ? "is-invalid"
                    : ""
                }`}
              />
              <small style={{ textAlign: "left", margin: 20 }}>
                * Please use comma for separating multiple emails
              </small>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
