import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  Row,
} from "reactstrap";
import Tabs from "../../../components/Tabs";
import { useTheme } from "../../../store/theme";
import LocationRowDetails from "./LocationRowDetails";

export default function EditDetailsModal({ isOpen, toggle }) {
  const [mode, setMode] = useState(0);
  const { theme } = useTheme();
  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle}>
      <div className="modal-header" style={{ borderBottom: "1px solid #eee" }}>
        <h5
          style={{ fontSize: "1.25rem", fontWeight: "bold", color: "black" }}
          className="modal-title"
          id="exampleModalLabel"
        >
          Edit your order settings
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span style={{ color: theme.primary }} aria-hidden={true}>
            ×
          </span>
        </button>
      </div>

      <div>
        <Row className="p-3">
          <Col md={12}>
            <h3 style={{ color: theme.primary }}>CHOOSE YOUR ORDER TYPE</h3>
          </Col>
          <Col md={8}>
            <Tabs theme={theme} onChange={(e) => setMode(e)} />
          </Col>
          <Col md={4}></Col>
          {mode == 0 && (
            <Col md={12} className="mt-3">
              <FormGroup>
                <h3 style={{ color: theme.primary }}>DELIVERY ADDRESS</h3>
                <InputGroup>
                  <Input type="text" placeholder="Enter your address" />
                  <InputGroupAddon addonType="prepend">
                    <Button
                      style={{ backgroundColor: theme.primary, color: "#fff" }}
                    >
                      Verify
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          )}

          <Col
            md={12}
            className={mode == 0 ? "p-3" : "mt-3 p-3"}
            style={{ backgroundColor: "#eee" }}
          >
            <h3 style={{ color: theme.primary }}>CHOOSE A LOCATION</h3>

            <Row className="pl-3 pr-3">
              <LocationRowDetails theme={theme} checked />
              <LocationRowDetails theme={theme} />
              <LocationRowDetails theme={theme} />
            </Row>
          </Col>
          <Col md={12} className="mt-3">
            <h3 style={{ color: theme.primary }}>CHOOSE A TIME</h3>
          </Col>
          <Col md={6} className="">
            <Input type="select">
              <option value="1">Today</option>
              <option value="2">...</option>
            </Input>
          </Col>
          <Col md={6} className="">
            <Input type="select" color="secondary">
              <option value="1">11:35</option>
              <option value="2">...</option>
            </Input>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          style={{
            backgroundColor: theme.primary,
            color: "#fff",
            width: "100%",
          }}
          type="button"
        >
          Save changes
        </Button>
      </div>
    </Modal>
  );
}
