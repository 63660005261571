/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { FaTable } from "react-icons/fa";
import Horizontal from "../../../../assets/icons/horizontal_line.svg";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import AddressFormatter from "../../components/AddressFormatter";

const getDeliveryTime = (location, deliveryZone) => {
  let deliveryReadyTime = 0;
  if (location?.enableIhd) {
    deliveryReadyTime = location?.ihdReadyTime || 0;
  } else {
    deliveryReadyTime = deliveryZone?.deliveryReadyTime || 0;
  }

  return deliveryReadyTime;
};

const MenuBannerDetails = ({ popover }) => {
  const {
    method: methodOrdering,
    location: locationOrdering,
    laterTime,
    laterDate,
    tableNo,
    timing,
    deliveryZone,
    deliveryConfirmAddressC,
    cateringData,
  } = useOrdering();

  const [time, setTime] = useState(new Date());

  const { restaurant } = useOrderingRestaurant();

  const { theme } = useTheme();

  useEffect(() => {
    if (timing === "later") {
      let date = new Date(laterDate);
      let splittedTime = laterTime.split(":");
      date.setHours(parseInt(splittedTime[0]));
      date.setMinutes(parseInt(splittedTime[1]));
      setTime(date);
    } else {
      let date = new Date();
      date.setMinutes(date.getMinutes() + Number(getReadyTime()));
      setTime(date);
    }
  }, [timing]);

  const getReadyTime = () => {
    if (methodOrdering === "pickup" || methodOrdering === "dine-in") {
      return locationOrdering?.pickupDelivery?.pickupPrepTime || 10;
    }
    return getDeliveryTime(locationOrdering, deliveryZone);
  };

  return (
    <div>
      {!popover ? (
        <div className="banner_main_section">
          <div
            style={{
              minHeight: 100,
            }}
            className="text-left pl-2"
          >
            <h2
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
                color: theme.primary,
              }}
            >
              Method
            </h2>
            <div>
              {methodOrdering === "delivery" ? (
                <div style={{ width: 200 }}>
                  <span>Delivery</span>
                  <div>
                    {(deliveryConfirmAddressC?.apartmentNo?.length > 0
                      ? deliveryConfirmAddressC.apartmentNo + ","
                      : "") + deliveryConfirmAddressC?.formatted_address}
                  </div>
                </div>
              ) : methodOrdering === "catering" ? (
                <>
                  <span>Catering</span>
                </>
              ) : methodOrdering === "dine-in" ? (
                <>
                  <span
                    style={{
                      textAlign: "left",
                      lineHeight: 1.8,
                      fontSize: 14,
                      color: "#000",
                    }}
                  >
                    Dine In
                  </span>
                  <br />
                  <span
                    style={{
                      textAlign: "left",
                      lineHeight: 1.8,
                      fontSize: 14,
                      color: "#000",
                    }}
                  >
                    Table No {tableNo}
                  </span>
                </>
              ) : methodOrdering === "walkup" ? (
                <>
                  <span>Walk Up</span>
                </>
              ) : (
                <>
                  <span>Pickup</span>
                </>
              )}
            </div>
          </div>

          <div
            style={{
              minHeight: 100,
              borderLeft: `1px solid ${theme.primary}`,
            }}
            className="text-left pl-2"
          >
            <h2
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
                textAlign: "left",
                color: theme.primary,
              }}
            >
              Location
            </h2>
            <div style={{ textAlign: "left", lineHeight: 1.8 }}>
              <span style={{ color: "#000", textAlign: "left" }}>
                <p
                  className="m-0"
                  style={{ maxWidth: "200px", fontWeight: "500", fontSize: 14 }}
                >
                  <AddressFormatter
                    address={locationOrdering?.displayAddress}
                  />
                </p>
              </span>
            </div>
          </div>

          <div
            style={{
              minHeight: 100,
              borderLeft: `1px solid ${theme.primary}`,
            }}
            className="text-left pl-2 mr-5"
          >
            <h2
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
                textAlign: "left",
                color: theme.primary,
              }}
            >
              Time
            </h2>
            <div
              style={{
                textAlign: "left",
                lineHeight: 1.8,
                fontSize: 14,
                color: "#000",
              }}
            >
              <span>
                {moment(time).format("DD") === moment(new Date()).format("DD")
                  ? `Today - ${moment(time).format("hh:mm A")}`
                  : ""}
              </span>

              <div> {moment(time).format("MMMM DD")}</div>
              {timing === "later" && (
                <div>{moment(time).format("hh:mm A")}</div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ color: "#000" }}>
          <div className="text-left">
            <h2
              style={{
                color: "#000",
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
              }}
            >
              Method
            </h2>
            <div style={{ color: "#000" }} className="mt-4 mb-3">
              {methodOrdering === "delivery" &&
              (!cateringData || cateringData?.method === "") ? (
                <>
                  <span>Delivery</span>
                </>
              ) : methodOrdering === "delivery" &&
                cateringData?.method === "delivery" ? (
                <>
                  <span className="">Catering - Delivery</span>
                </>
              ) : methodOrdering === "pickup" &&
                cateringData?.method === "pickup" ? (
                <>
                  <span>Catering - Pickup</span>
                </>
              ) : methodOrdering === "dine-in" ? (
                <>
                  <span
                    style={{
                      textAlign: "left",
                      lineHeight: 1.8,
                      fontSize: 14,
                      color: "#000",
                    }}
                  >
                    Dine In
                  </span>
                  <br />
                  <span
                    style={{
                      textAlign: "left",
                      lineHeight: 1.8,
                      fontSize: 14,
                      color: "#000",
                    }}
                  >
                    Table No {tableNo}
                  </span>
                </>
              ) : methodOrdering === "walkup" ? (
                <>
                  <span>Walkup</span>
                </>
              ) : (
                <>
                  <span>Pickup</span>
                </>
              )}
            </div>
          </div>
          <div>
            <img src={Horizontal} alt="" style={{ width: "100%" }} />
          </div>
          <div className="mt-4 mb-4">
            <h2
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
                textAlign: "left",
              }}
            >
              Location
            </h2>
            <div style={{ textAlign: "left", lineHeight: 1.8 }}>
              <span style={{ color: "#000", textAlign: "left" }}>
                <p
                  className="m-0"
                  style={{ maxWidth: "200px", fontWeight: "500", fontSize: 14 }}
                >
                  <AddressFormatter
                    address={locationOrdering?.displayAddress}
                  />
                </p>
              </span>
            </div>
          </div>
          <div>
            <img src={Horizontal} alt="" style={{ width: "100%" }} />
          </div>
          <div className="mt-4 mb-4">
            {" "}
            <h2
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
                textAlign: "left",
              }}
            >
              Time
            </h2>
            <div
              style={{
                width: 200,
                textAlign: "left",
                lineHeight: 1.8,
                fontSize: 14,
                color: "#000",
              }}
            >
              {timing === "now" ? (
                <span>
                  {timing === "now" && (
                    <div>{`Today - ${moment(time).format("hh:mm A")}`}</div>
                  )}
                  {moment(new Date()).format("MMM DD, YYYY")} &nbsp;
                  {/* {moment(new Date())
                    .add(
                      locationOrdering?.pickupDelivery?.pickupPrepTime ||
                        restaurant?.ordering?.pickupPrepTime,
                      "minutes"
                    )
                    .format("hh:mm")} */}
                </span>
              ) : (
                <span>
                  {moment(laterDate).format("MMM DD, YYYY")} &nbsp; {laterTime}
                </span>
              )}
            </div>
          </div>
          <div>
            <img src={Horizontal} alt="" style={{ width: "100%" }} />
          </div>
          <div className="mt-4 mb-4">
            {" "}
            <h2
              style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "16px",
                letterSpacing: "0.15em",
                textTransform: "uppercase",
                textAlign: "left",
              }}
            >
              Note
            </h2>
            <div
              style={{
                width: 200,
                textAlign: "left",
                lineHeight: 1.8,
                fontSize: 14,
                color: "#000",
              }}
            >
              {restaurant?.pop_up_alerts?.category_popup?.note !== "" &&
              restaurant?.pop_up_alerts?.category_popup?.note !== undefined &&
              restaurant?.pop_up_alerts?.category_popup?.note
                ? restaurant?.pop_up_alerts?.category_popup?.note
                : "Welcome to our online ordering site."}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuBannerDetails;
