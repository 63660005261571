import React from "react";
import useOrdering from "../../../store/ordering";

function AddressFormatter({ address }) {
  const orderingStore = useOrdering();
  const addressComponents = address?.split(", ");
  const street = addressComponents?.[0];
  const restOfAddress = addressComponents?.slice?.(1)?.join(", ");

  return (
    <div>
      <div>{street}</div>
      {restOfAddress && <div>{restOfAddress}</div>}
    </div>
  );
}

export default AddressFormatter;
