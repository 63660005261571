/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import apiCaller from "../../../../api/apiCaller";
import { ErrorText } from "../../../../components/ErrorText";
import useScreenSize from "../../../../hooks/useScreenSize";
import useOrdering from "../../../../store/ordering";
import { useTheme } from "../../../../store/theme";
import { extractAddress } from "../../../../utils/extractAddress";
import LocationSearchInput from "../LocationSearchInput";
import ErrorModal from "./ErrorModal";
import ModalHeader from "./ModalHeader";

export default function DeliveryAddressModal({
  isOpen,
  toggle,
  onAddressSelect,

  location,
}) {
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [apartmentNo, setApartmentNo] = useState("");
  const [coordinates, setCoordinates] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const { theme } = useTheme();
  const { width } = useScreenSize();

  const {
    setDeliveryAddressC,
    setConfirmDeliveryAddress,
    location: locationDoc,
    setDeliveryZone,
    setDeliveryMethod,
    setDeliveryFee,
    setLocation,
  } = useOrdering();

  function isPointInPolygon(latLng, geofencing) {
    const coordinate = new window.google.maps.LatLng(latLng[0], latLng[1]);
    const polygon = new window.google.maps.Polygon({ paths: geofencing });
    const result = window.google.maps.geometry.poly.containsLocation(
      coordinate,
      polygon
    );

    return result;
  }

  const toggleModal = (e) => {
    e.preventDefault();
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (coordinates) {
      setError(false);
    }
  }, [coordinates]);

  async function checkIHD() {
    const address = extractAddress(deliveryAddress);
    const deliveryFee = await apiCaller("/eat/ordering/check-delivery", {
      method: "POST",
      auth: true,
      body: {
        address: address,
        locationId: location,
      },
    });
    return deliveryFee;
  }

  async function handleVerify(e) {
    setLoading(true);
    if (!locationDoc.enableIhd) {
      if (!coordinates) {
        setLoading(false);
        setError(true);
        return;
      }
      const finalCords = Object.values(coordinates);
      const zones = locationDoc.pickupDelivery.deliveryZones;
      const zone = zones.find((zone) => {
        console.log("checking zone", zone);
        const isInZone = isPointInPolygon(finalCords, zone.geofencing);
        return isInZone;
      });
      const extendWithIHD = zones[zones.length - 1].extendWithIHD;
      if (zone) {
        setDeliveryZone(zone);
        setDeliveryMethod("native");
      } else if (extendWithIHD) {
        try {
          const deliveryFee = await checkIHD();
          if (deliveryFee.data === -1) {
            toggleModal(e);
            setLoading(false);
            return;
          }
          debugger;
          setDeliveryZone(zones[zones.length - 1]);
          setLocation({ ...locationDoc, enableIhd: true });
          setDeliveryFee(deliveryFee.data);
          setDeliveryMethod("ihd");
        } catch (error) {
          toast.error(error?.message || "DELIVERY_ERROR");
          setLoading(false);
          return;
        }
      } else if (!extendWithIHD) {
        setLoading(false);
        toast.error(
          "Restaurant can't deliver on this address. You may be out of our delivery range."
        );
        return;
      }
    } else {
      try {
        const address = extractAddress(deliveryAddress);
        const deliveryFee = await apiCaller("/eat/ordering/check-delivery", {
          method: "POST",
          auth: true,
          body: {
            address: address,
            locationId: location,
          },
        });

        if (deliveryFee.data === -1) {
          toggleModal(e);
          setLoading(false);
          return;
        }

        setDeliveryFee(deliveryFee.data);
        setDeliveryMethod("ihd");
      } catch (error) {
        toggleModal(e);
        setLoading(false);
        toast.error(error);
        return;
      }
    }

    setLoading(false);
    onAddressSelect(deliveryAddress);
    setDeliveryAddressC(deliveryAddress);
    setConfirmDeliveryAddress(deliveryAddress);
  }

  async function handleDeliveryAddress(address) {
    setDeliveryAddress(address);
    setDeliveryZone(locationDoc.pickupDelivery?.deliveryZones?.[0]);
    setDeliveryMethod("native");
    onAddressSelect(address);
    setDeliveryAddressC(address);
    setConfirmDeliveryAddress(address);
    setLoading(false);
  }

  useEffect(() => {
    if (deliveryAddress) {
      deliveryAddress["apartmentNo"] = apartmentNo;
    }
  }, [apartmentNo]);

  const isDeliveryListing =
    locationDoc?.pickupDelivery?.deliveryZones?.[0]?.deliveryOption ===
    "listing";

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle}>
      <ModalHeader title="Delivery Address" toggle={toggle} />
      <div className="modal-body">
        {isDeliveryListing && (
          <ListGroup className="mt-2">
            {locationDoc.pickupDelivery?.deliveryZones?.[0]?.deliveryListingAddresses?.map(
              (listing, index) => {
                return (
                  <ListGroupItem>
                    <Row>
                      <Col md={10}>{listing.formatted_address}</Col>
                      <Col md={1}>
                        <Button
                          onClick={() => {
                            // handleAddressRemove(index);
                            handleDeliveryAddress(listing);
                          }}
                          style={{
                            backgroundColor: theme.primary,
                            color: "#fff",
                          }}
                          outline
                          size="sm"
                        >
                          Select
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              }
            )}
          </ListGroup>
        )}
        {!isDeliveryListing && (
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Col md={12}>
              <LocationSearchInput
                setFieldValue={(field, data) => {}}
                onChangeAddress={(addr) => {
                  setDeliveryAddress(addr);
                }}
                getCoordinates={(latlng) => {
                  setCoordinates(latlng);
                }}
              />
              {error && (
                <ErrorText
                  isValid={!error}
                  message={"Delivery Address is Required"}
                />
              )}
            </Col>
            {deliveryAddress && (
              <Col md={12} className="my-2">
                <Input
                  onChange={(e) => {
                    setApartmentNo(e.target.value);
                  }}
                  placeholder="Apartment or Suite Number"
                />
              </Col>
            )}
            <Col md={12} className="mt-2">
              <Button
                disabled={loading || deliveryAddress === ""}
                onClick={handleVerify}
                block={width < 600}
                style={{
                  backgroundColor: theme?.primary,
                  color: "#fff",
                  marginTop: width < 600 ? 10 : 0,
                  fontFamily: "Inter",
                  width: "100%",
                }}
              >
                {loading ? <Spinner size={"sm"} /> : "Verify"}
              </Button>
            </Col>
          </Row>
        )}
      </div>
      <ErrorModal open={openModal} toggle={toggleModal} theme={theme} />
    </Modal>
  );
}
