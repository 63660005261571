import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Modal,
  Row,
} from "reactstrap";
import { AuthContext } from "../../../../context";
import { useAdminStore } from "../../../../store/admin";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  optionName: Yup.string().trim().required("Option name is required."),
  sortOrder: Yup.string().trim().required("Sort order is required."),
  price: Yup.string().trim().required("Price is required."),
});

export default function AddChildModifier({ isVisible, toggle, onClose }) {
  const [optionName, setOptionName] = useState("");
  const [sortOrder, setSortOrder] = useState("0");
  const authContext = useContext(AuthContext);
  const [price, setPrice] = useState();
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();

  const resetForm = () => {
    setOptionName("");
    setSortOrder("0");
    setPrice(0);
  };

  useEffect(() => {
    resetForm();
  }, [isVisible]);

  //TODO:Create Modifier on save button click
  // const { create: addModifier, loading } = useEntity("modifier");

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isVisible}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <h1 className="display-4">Add Child Options</h1>
          </CardHeader>
          <CardBody className="">
            <Row>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "center" : "flex-end",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Name
                  </Col>
                  <Col
                    md="8"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      onChange={(e) => setOptionName(e.target.value)}
                      type="text"
                      value={optionName}
                      className="ml-3"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "center" : "flex-end",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Price
                  </Col>
                  <Col
                    md="8"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      className="ml-3"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "center" : "flex-end",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Sort Order
                  </Col>
                  <Col
                    md="8"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="number"
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      className="ml-3"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-4"
                color="primary"
                type="button"
                onClick={async () => {
                  try {
                    await validationSchema.validate({
                      optionName,
                      price,
                      sortOrder,
                    });

                    toggle({
                      restaurant: authRest || restaurant,
                      name: optionName,
                      sortOrder: sortOrder,
                      price: price !== undefined ? Number(price) : 0,
                      type: "child",
                      source: "local",
                    });
                    resetForm();
                  } catch (error) {
                    toast.error("Please fill all the details");
                  }
                }}
                style={{ width: 150 }}
              >
                Add
              </Button>

              <Button
                onClick={onClose}
                style={{ width: 150 }}
                className="mt-4"
                color="warning"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
