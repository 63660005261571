import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { Input } from "reactstrap";
import useOrdering from "../../../store/ordering";

const LocationSearchInputCatering = ({
  onChangeAddress,
  disabled,
  value = "",
}) => {
  const [isInit, setIsInit] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");

  const handleChange = (address) => {
    setAddress(address);
  };

  const { setConfirmDeliveryAddress, setDeliveryAddressC } = useOrdering();

  const handleSelect = async (address, placeId, suggestions) => {
    geocodeByPlaceId(placeId)
      .then((results) => {
        if (results?.length > 0) {
          let obj = {
            formatted_address: address,
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "",
            zip: "",
            district: "",
            status: "active",
            street: "",
            type: "delivery_address",
          };

          results[0]?.address_components?.map((res) => {
            if (res?.types?.includes("country")) {
              obj.country = res?.long_name || "";
            } else if (res?.types?.includes("administrative_area_level_1")) {
              obj.state = res?.long_name || "";
            } else if (res?.types?.includes("administrative_area_level_2")) {
              obj.district = res?.long_name || "";
            } else if (res?.types?.includes("locality")) {
              obj.city = res?.long_name || "";
            } else if (res?.types?.includes("postal_code")) {
              obj.zip = res?.long_name || "";
            } else if (res?.types?.includes("sublocality_level_2")) {
              obj.address1 = res?.long_name || "";
            } else if (res?.types?.includes("sublocality_level_1")) {
              obj.address2 = res?.long_name || "";
            }
          });

          setDeliveryAddressC({ ...obj });

          setConfirmDeliveryAddress({ ...obj });

          setSelectedAddress(results);

          setAddress(address);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedAddress?.length > 0) {
      onChangeAddress(selectedAddress);
    }
  }, [selectedAddress]);

  useEffect(() => {
    setAddress(value);
    if (value !== "" && !isInit) {
      setAddress(value);
      setIsInit(true);
    }
  }, [value]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      disabled={disabled}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input
            style={{
              fontFamily: "Inter",
              height: 45,
              border: "1px solid #ededed !important",
            }}
            {...getInputProps({
              placeholder: "Enter your address",
              className: "location-search-input",
            })}
            value={address}
            disabled={disabled === true ? true : false}
          />
          <div
            style={{
              position: "absolute",
              width: "300px",
              padding: 10,
              borderRadius: 5,
              zIndex: 9999,
            }}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    padding: 5,
                    borderBottom: "1px solid #ededed",
                  }
                : {
                    backgroundColor: "#ffffff",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    padding: 5,
                    borderBottom: "1px solid #ededed",
                  };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInputCatering;
