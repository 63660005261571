/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Button, Container } from "reactstrap";
import { isAdminUrl } from "../../utils/isAdminUrl";
// core components
import AdminNavbar from "./components/AdminNavbar";
import LandingHeader from "./components/LandingHeader";
import Sections from "./components/Sections";
import Testimonials from "./components/Testimonials";

function Landing({ history }) {
  if (!isAdminUrl()) {
    history.push("/login");
  }
  return (
    <>
      <AdminNavbar />
      <LandingHeader />
      <Container className=" pb-5">
        <Testimonials />
        <h1 style={{ marginTop: 30, marginBottom: 10, fontSize: 50 }}>
          Your business, our software
        </h1>
        <Sections
          title="Upload their menu"
          description="With our simple DIY dashboard, you can upload a menu in no time! You can add items, descriptions, images, modifiers, the works!"
          image="https://myordering.app/assets/images/landing/w.svg"
          button={false}
          reverse={false}
        />
        <Sections
          title="Your Website"
          description="Add an order online button to your their website and social media pages."
          image="https://myordering.app/assets/images/landing/w1.svg"
          button={false}
          reverse={true}
        />
        <Sections
          title="Promote"
          description="Now that you have your own ordering service, tell the world! Post on social media, send out emails, and show some marketing within your restaurant."
          image="https://myordering.app/assets/images/landing/w3.svg"
          button={false}
          reverse={false}
        />
        <Sections
          title="View Incoming Order"
          description="Placed orders can be viewed in several ways: Printer (optional), Free iPad app called Order Boss, SMS, email, fax, auto phone call, owl, fox...you get it! We will make sure you get the order 😊"
          image="https://myordering.app/assets/images/landing/w4.svg"
          button={false}
          reverse={true}
        />
        <Button style={{ marginBottom: 30 }} size="xl" color="primary">
          Become a Partner
        </Button>
        <footer className="mt-5" id="footer-main">
          <div className="container">
            <div className="align-items-center justify-content-xl-between row">
              <div className="col-xl-6">
                <div className="copyright text-center text-xl-left text-muted">
                  © 2022
                  <a
                    className="font-weight-bold ml-1"
                    href="javascript:void(0)"
                  >
                    My Ordering App
                  </a>
                </div>
              </div>
              <div className="col-xl-6">
                <ul className="nav-footer justify-content-center justify-content-xl-end nav">
                  <li className="nav-item">
                    <a href="javascript:void(0)" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)" className="nav-link">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)" className="nav-link">
                      Dashboard
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </Container>
    </>
  );
}

export default Landing;
