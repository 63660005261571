/* eslint-disable react-hooks/exhaustive-deps */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import apiCaller from "../../api/apiCaller";
import { AuthContext } from "../../context";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import PaymentForm from "./PaymentForm";
import { ErrorBoundary, Provider } from "@rollbar/react";
import logCustomerSession from "../../utils/axiom-log";

const StripeTokenization = ({
  formik,
  paymentFormRef,
  onSuccess,
  guestFormik,
  guestErrors,
  guestRef,
  guestData,
}) => {
  const { restaurant: orderingRestaurant } = useOrderingRestaurant();

  const { gateway, activeTab } = useOrdering();

  const stripePromise = loadStripe(gateway?.stripePublishableKey);

  const [clientSecret, setClientSecret] = useState("");

  const [stripeCustomerId, setStripeCustomerId] = useState("");

  const authContext = useContext(AuthContext);

  const { cartItems } = useCartStore();

  async function createPaymentIntent() {
    try {
      const payload = {
        amount: formik.values.billing.total,
        gatewayId: gateway?._id,
        partnerId: orderingRestaurant?.partner?.partner?._id,
        userId: authContext?.user?._id,
        line_items: cartItems,
        guestData: guestData,
      };

      await logCustomerSession({
        email: authContext?.user?.customer?.email || guestData?.email || "N/A",
        context: "stripeintentcreate",
        screen: "checkout",
        message: `sendPaymentDataToStripe-stripe-intent-create-payload: ${JSON.stringify(
          payload
        )}`,
      });

      // create intent
      const response = await apiCaller("/stripe/create-intent", {
        method: "POST",
        body: payload,
      });

      await logCustomerSession({
        email: authContext?.user?.customer?.email || guestData?.email || "N/A",
        context: "stripeintentcreate",
        screen: "checkout",
        message: `sendPaymentDataToStripe-stripe-intent-create-response: ${JSON.stringify(
          response
        )}`,
      });

      setClientSecret(response?.data?.client_secret);
      setStripeCustomerId(response?.data?.stripeCustomerId);

      return response;
    } catch (error) {}
  }

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <ErrorBoundary>
            <PaymentForm
              guestData={guestData}
              clientSecret={clientSecret}
              formik={formik}
              paymentFormRef={paymentFormRef}
              onSuccess={onSuccess}
              stripeCustomerId={stripeCustomerId}
              guestFormik={guestFormik}
              guestErrors={guestErrors}
              guestRef={guestRef}
            />
          </ErrorBoundary>
        </Elements>
      ) : activeTab === "guest" &&
        guestData?.name == "" &&
        guestData?.email == "" &&
        guestData?.phone === "" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Please enter your details to continue</h3>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"lg"} />
        </div>
      )}
    </>
  );
};

export default StripeTokenization;
