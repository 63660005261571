import { useState } from "react";
import { FaArrowLeft, FaEnvelope } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import AuthHeader from "../../components/Header/AuthHeader";
import { useEntity } from "../../hooks/useEntity";

const ForgetPassword = ({ history }) => {
  const { create, loading } = useEntity("auth/reset");
  const [email, setEmail] = useState("");

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email can't be empty"),
  });

  return (
    <div className="bg-white" style={{ height: "100vh" }}>
      <>
        <div
          className="mt-4 mx-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button size="sm">Contact</Button>
          </div>
        </div>
        <div
          className="d-flex"
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Container
            style={{
              maxWidth: "448px",
            }}
          >
            <AuthHeader title="Reset Password" lead="" />
            <Card
              style={{ boxShadow: "0px 1px 3px 0px rgba(50, 50, 93, 0.15)" }}
            >
              <div
                onClick={() => history.push("/login")}
                className="text-left mb-3 px-3 mt-3"
                style={{ color: "gray", cursor: "pointer" }}
              >
                <FaArrowLeft className="mr-2" style={{ color: "gray" }} />{" "}
              </div>

              <div className="text-center text-muted mb-2">
                <small>Please enter email continue</small>
              </div>
              <CardBody className="">
                <div>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FaEnvelope />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </InputGroup>
                    <Button
                      onClick={async () => {
                        try {
                          await schema.validate({ email });
                          await create({ email });
                          history.push({
                            pathname: "/verify-otp",
                            state: email,
                          });
                        } catch (error) {
                          toast.error(error.message);
                        }
                      }}
                      block
                      className="my-4"
                      color="info"
                      type="button"
                    >
                      {loading ? (
                        <Spinner size="sm" color="white" />
                      ) : (
                        "Send OTP"
                      )}
                    </Button>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    </div>
  );
};

export default ForgetPassword;
