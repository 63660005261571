import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { useEntity } from "../../../../hooks/useEntity";
import AddPointsModal from "./AddPointsModal";
export const CustomersRow = ({ data }) => {
  const { updateEntity, loading } = useEntity("customer");

  const [open, setOpen] = useState(false);

  return (
    <tr className="text-left">
      <td className="text-left">{data?.name}</td>
      <td className="text-left">{data?.email}</td>
      <td>{data?.customerGroup ? data?.customerGroup : "-"}</td>
      <td>
        {loading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.userId?.status === "active"}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status:
                      data?.userId?.status === "active" ? "inactive" : "active",
                  });
                  toast("Customer Updated Successfully");
                } catch (error) {
                  toast(error.message);
                }
              }}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        )}
      </td>

      <td>{moment(data?.userId?.createdAt).format(" MM/DD/YYYY")}</td>
      <td className="text-left" onClick={() => setOpen(true)}>
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-plus-square text-lg "
        />
      </td>
      <AddPointsModal open={open} toggle={() => setOpen(!open)} data={data} />
    </tr>
  );
};
