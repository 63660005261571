import React from "react";
import { defaults, Line } from "react-chartjs-2";
defaults.global.maintainAspectRatio = false;

export default function LineChart({ data, label, color }) {
  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  return (
    <div>
      <Line
        id="chart-points"
        className="chart-canvas"
        height={334}
        data={{
          labels: data[0].labels,
          datasets: [
            {
              label: data[0].label,
              data: data[0].data,
              borderColor: data[0].color,
              fill: false,
              tension: 0.5,
            },
            {
              label: data[1].label,
              data: data[1].data,
              borderColor: data[1].color,
              fill: false,
              tension: 0.5,
            },
          ],
        }}
        options={{
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: colors.gray[200],
                  zeroLineColor: colors.gray[200],
                },
                ticks: {},
              },
            ],
          },
        }}
      />
    </div>
  );
}
