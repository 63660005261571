import create from "zustand";
import { persist } from "zustand/middleware";

export const useRestaurantStore = create(
  persist(
    (set, get) => ({
      restaurant: {},
      setRestaurant: (doc) => set({ restaurant: doc }),
    }),
    {
      name: "restaurant",
      getStorage: () => localStorage,
    }
  )
);
