import create from "zustand";
import { persist } from "zustand/middleware";
const url = window.location.href.replace(/(\/ordering\/\w+).*/, "$1");

export const useTheme = create(
  persist(
    (set, get) => ({
      theme: {},
      setTheme: (theme) => set({ theme: theme }),
    }),
    {
      name: "THEME:" + url,
      getStorage: () => localStorage,
    }
  )
);
