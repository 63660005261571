import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import Facebook from "../../../../assets/icons/facebook_icon.svg";
import Gmail from "../../../../assets/icons/gmail_login.svg";
import Instagram from "../../../../assets/icons/instagram_icon.svg";
import Twitter from "../../../../assets/icons/twitter_icon.svg";
import { handleSocialLogin } from "../Signin";

const SocialComponent = ({
  className,
  showTextTop = false,
  text = "- Or Sign in with -",
}) => {
  const { restaurant } = useOrderingRestaurant();
  return (
    <div>
      {showTextTop && (
        <div
          className={`text-center text-sm ${className}`}
          style={{ color: "#000" }}
        >
          {text}
        </div>
      )}
      <div
        className={className}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div onClick={() => handleSocialLogin("facebook", restaurant)}>
          <img
            style={{ cursor: "pointer" }}
            alt="facebook icon"
            src={Facebook}
          />
        </div>
        <div
          className="mx-2"
          onClick={() => handleSocialLogin("instagram", restaurant)}
        >
          <img
            style={{ cursor: "pointer" }}
            alt="instagran icon"
            src={Instagram}
          />
        </div>
        <div onClick={() => handleSocialLogin("google", restaurant)}>
          <img style={{ cursor: "pointer" }} alt="gmail icon" src={Gmail} />
        </div>
        <div
          className="ml-2"
          onClick={() => handleSocialLogin("twitter", restaurant)}
        >
          <img style={{ cursor: "pointer" }} alt="twitter icon" src={Twitter} />
        </div>
      </div>
      {!showTextTop && (
        <div
          className={`text-center text-sm ${className}`}
          style={{ color: "#000" }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default SocialComponent;
