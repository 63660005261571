import { useEffect } from "react";
import { Spinner } from "reactstrap";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";

function RedirectComponent({ shouldRedirect, to }) {
  const { clearCart } = useCart();
  useEffect(() => {
    if (shouldRedirect) {
      clearCart();
      window.location.href = to;
    }
  }, [shouldRedirect]);

  return <></>;
}

export default function DeliveryOrdering(props) {
  const orderingStore = useOrdering();
  const urlParams = new URLSearchParams(window.location.search);

  const {
    findOne,
    entity: location,
    loading,
  } = useEntity("eat/ordering/delivery/location");
  useEffect(() => {
    if (location) {
      orderingStore.setLocation(location?.data);
      orderingStore.setMethod("delivery");
    } else {
      findOne(urlParams.get("id"));
    }
  }, [location]);

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="25" />
      </div>
    );
  }

  if (!location?.data && !loading) {
    return (
      <div
        style={{
          marginTop: 50,
        }}
      >
        <h1>We're not accepting the order</h1>
        <h4>
          We're not accepting the orders at the moment. Please check back later.
        </h4>
      </div>
    );
  }

  return (
    <RestaurantWrapper>
      <RedirectComponent
        shouldRedirect={
          orderingStore.location && orderingStore.method && !loading
        }
        to={`${urlParams.get("url")}/location?modal=open`}
      />
    </RestaurantWrapper>
  );
}
