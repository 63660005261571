import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Table } from "reactstrap";
import EmptyPlaceholder from "../screens/ordering/menu/components/EmptyPlaceholder";

const SuperTableDnd = ({
  data,
  columns,
  row: TableRow,
  emptyMessage,
  history,
  refetch,
  showCheckboxes,
  onItemsChecked,
  total,
  setRowsPerPage,
  setPage,
  rowsPerPage,
  page,
  isLoading,
  sort,
  randomPass,
  prevPage,
  nextPage,
  currentPage,
  showFilter = false,
  filterComponent,
  handleDragEnd,
}) => {
  if (data?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <EmptyPlaceholder text={emptyMessage} />
      </div>
    );
  }
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Table className="align-items-center " responsive>
        <thead className="thead-light text-left">
          <tr>
            {columns?.map((c, i) => {
              return (
                <th key={i} scope="col">
                  {c}
                </th>
              );
            })}
          </tr>
        </thead>
        <Droppable droppableId="table">
          {(provided, snapshot) => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {data?.map((data, index) => (
                <Draggable key={data._id} draggableId={data._id} index={index}>
                  {(provided) => (
                    <TableRow
                      history={history}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      refDoc={provided.innerRef}
                      data={data}
                      className={snapshot.isDraggingOver ? "dragging" : ""}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};

export default SuperTableDnd;
