import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Label } from "reactstrap";
import { useRestaurantStore } from "../../../../store/restaurant";

export default function WalkUpOrderingQR({ locationId }) {
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const urlString = new URL(restaurantDoc.restaurant.url);
  const host = urlString.host;

  function handleDownload() {
    const svgElem = document.querySelector("#_walkupqr");
    const serializer = new XMLSerializer();
    let svgData = serializer.serializeToString(svgElem);
    svgData = '<?xml version="1.0" standalone="no"?>\r\n' + svgData;
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    let DOMURL = window.URL || window.webkitURL || window;
    const url = DOMURL.createObjectURL(svgBlob);

    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const domRect = svgElem.getBBox();
      canvas.width = domRect.width;
      canvas.height = domRect.height;
      ctx.drawImage(img, 0, 0, domRect.width, domRect.height);
      DOMURL.revokeObjectURL(url);

      const imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      download(imgURI);
    };
    img.onerror = (e) => {
      console.error("Image not loaded", e);
    };

    img.src = url;
  }

  function download(href) {
    let download = document.createElement("a");
    download.href = href;
    download.download = "img.png";
    download.click();
    download.remove();
  }
  const splits = restaurantDoc?.restaurant?.url?.split("/");

  const restaurantEndpoint = splits?.[splits?.length - 1];
  const url = `${
    host.startsWith("http") ? host : `http://${host}`
  }/ordering/${restaurantEndpoint}/walkup?id=${locationId}&url=${
    restaurantDoc?.restaurant?.url
  }`;
  const handleCopy = async () => {
    await navigator.clipboard.writeText(url);
    toast.success("Copied");
  };
  const isError = url.includes("undefined");

  if (!locationId) return <></>;

  return (
    <>
      <FormGroup className="row" style={{ alignItems: "center" }}>
        <Label
          className="form-control-label text-left"
          htmlFor="example-text-input"
          md="4"
        >
          QR Code
        </Label>
        <Col md="8">{!isError && <QRCode id="_walkupqr" value={url} />}</Col>
      </FormGroup>
      <FormGroup className="row">
        <Col md="4"></Col>
        <Col md="8">
          <a href={url}>{url}</a>
        </Col>
      </FormGroup>
      <FormGroup className="row mt--1" style={{ alignItems: "center" }}>
        <Label
          className="form-control-label text-left"
          htmlFor="example-text-input"
          md="4"
        ></Label>
        <Col md="8">
          <Button onClick={handleCopy}>Copy URL</Button>
          <Button onClick={handleDownload}>Download</Button>
        </Col>
      </FormGroup>
    </>
  );
}
