import Compressor from "compressorjs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { useEntity } from "../../../../hooks/useEntity";
import { useUploadImage } from "../../../../hooks/useUploadImage";

export const MobileProductRow = ({ history, data, ...props }) => {
  const { updateEntity } = useEntity("item");
  const [selectedFile, setSelectedFile] = useState(null);
  const { upload } = useUploadImage();
  const [imageUploading, setImageUploading] = useState(false);

  const location = useLocation();

  const handleFileInputChange = async (e) => {
    const image = e.target.files[0];

    new Compressor(image, {
      quality: 0.6,
      retainExif: false,
      height: 1000,
      success: (compressedResult) => {
        setSelectedFile(compressedResult);
      },
    });
  };

  useEffect(() => {
    const uploadImage = async () => {
      setImageUploading(true);
      if (selectedFile && selectedFile !== null) {
        const image = await upload({
          file: selectedFile,
          owner: "restaurant",
        });
        try {
          await updateEntity(data?._id, { imageUrl: image });
          setSelectedFile(null);
          toast.success("Image added successfully");
        } catch (error) {
          setImageUploading(false);
          setSelectedFile(null);
          toast.error(error.message);
        }
      }
      setImageUploading(false);
    };

    uploadImage();
  }, [selectedFile]);

  return (
    <tr {...props} ref={props.refDoc}>
      <td
        onClick={() => {
          history.push({
            pathname: "/resturants/add-item",
            state: data?._id,
            search: location.search,
          });
        }}
        scope="row"
        className="text-left"
      >
        <span className="name mb-0 text-sm ">{data?.name}</span>
      </td>

      <td>
        {imageUploading ? (
          <Spinner color="primary" size={14} />
        ) : (
          <div class="input--file">
            <i className="fa fa-camera" />
            <input
              id="file-input"
              type="file"
              accept="image/*"
              capture
              onChange={handleFileInputChange}
            />
          </div>
        )}
      </td>
    </tr>
  );
};
