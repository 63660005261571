import { Button } from "reactstrap";

const CopyOptionsRow = ({ prod }) => {
  return (
    <>
      <tr>
        <td>
          <div
            className="text-left"
            style={{
              fontWeight: prod?.subModifiers?.length > 0 ? "bold" : "normal",
            }}
          >
            {prod?.product_name}
          </div>

          {prod?.subModifiers?.length > 0 && (
            <>
              <div className="text-left">
                {prod?.subModifiers?.length > 0 && (
                  <small className="text-left">* Child Modifiers</small>
                )}
              </div>
              {prod?.subModifiers?.map((subModif, i) => (
                <Button
                  className="my-1"
                  size="sm"
                  color="secondary"
                  onClick={() => {}}
                >
                  <span>{`${subModif.name} - ($${subModif.price})`}</span>
                </Button>
              ))}
            </>
          )}
        </td>

        <td>${prod?.price}</td>

        <td>{prod?.sort}</td>
      </tr>
    </>
  );
};

export default CopyOptionsRow;
