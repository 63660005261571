import moment from "moment";
import { useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { toast } from "react-toastify";
import { Button, Collapse, UncontrolledCollapse } from "reactstrap";
import { useTheme } from "../../../../store/theme";
import compareArrays from "../../../../utils/compareArray";
import GroupedPizzaToppings from "../../menu/components/GroupPizzaToppings";

const HistoryCard = ({
  data,
  index,
  length,
  onClick,
  availablity,
  allItems,
  disable,
}) => {
  const { theme } = useTheme();

  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  return (
    <div
      style={{
        borderBottom: index === length - 1 ? "none" : `1px solid #ededed`,
      }}
      className="history_card_wrapper"
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            color: theme?.primary,
            fontWeight: "600",
            textTransform: "uppercase",
            letterSpacing: "0.1em",
            fontSize: "17px",
          }}
        >
          Order #{data?.orderNum}
        </div>
        <div className="mt-2" style={{ color: "#666666", fontWeight: "400" }}>
          {moment(data?.createdAt).format("MMMM D, YYYY h:mm a")}
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="my-3 d-flex justify-content-between align-items-center"
        >
          <div
            onClick={toggle}
            style={{ fontSize: 20, color: "#212121", fontWeight: "400" }}
          >
            Summary <FaCaretDown size={20} />
          </div>{" "}
          <div style={{ fontWeight: "600", color: "#000", fontSize: 20 }}>
            ${data?.payment?.total.toFixed(2)}
          </div>
        </div>
        <Collapse isOpen={collapse}>
          <div>
            <div>
              {data?.items?.map((it, i) => {
                let o = allItems?.find((itm) => itm?._id === it?.itemId?._id);

                const allSubprods = Object.values(o?.modifiers || {}).flatMap(
                  (modif) => modif?.subProducts
                );

                const allSelectedModifiers = allSubprods.filter(
                  (subProd) => subProd?.defaultSelected
                );

                const missingSelected = compareArrays(
                  allSelectedModifiers,
                  it?.modifiers
                );

                const filteredBasedOnParentModifs = missingSelected.filter(
                  (f) => {
                    const idx = it?.modifiers?.findIndex(
                      (t) =>
                        f.selectedParentValue.label === t.product_name ||
                        f?.selectedParentValue?.label == "" ||
                        !f?.selectedParentValue?.label
                    );
                    return idx !== -1;
                  }
                );

                const finalMissingSelected = filteredBasedOnParentModifs.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.product_id === obj.product_id &&
                        t.product_name === obj.product_name
                    )
                );

                return (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "500",
                          fontSize: 18,
                          color: "#000",
                        }}
                      >
                        {it?.name}
                      </h1>
                      <h3
                        style={{
                          fontSize: 20,
                          color: "#212121",
                          fontWeight: "600",
                        }}
                      >
                        ${Number(it?.price).toFixed(2)}
                      </h3>
                    </div>
                    <div
                      className="py-2 rounded"
                      style={{
                        backgroundColor: "#ededed",
                        color: "#666666",
                        maxWidth: 100,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <span
                        className="mx-4"
                        style={{ fontWeight: "700", color: "#000" }}
                      >
                        {it?.qty}
                      </span>
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        fontSize: 15,
                      }}
                    >
                      {finalMissingSelected?.map((selected) => {
                        return (
                          <div className="d-flex text-left justify-content-between  align-items-center">
                            <div>
                              <div className={"normal"}>
                                No {selected?.product_name}
                              </div>
                            </div>

                            <div></div>
                          </div>
                        );
                      })}
                      <GroupedPizzaToppings it={it} data={it.modifiers} />

                      {it?.modifiers
                        ?.filter((it) => !it?.defaultSelected)
                        ?.map((op) => {
                          let newPrice;
                          if (op?.qty && op?.qty > 1) {
                            newPrice = op?.price * op?.qty;
                          } else newPrice = op?.price * 1;

                          const modifierSelectdPrice =
                            op?.selectedModifier?.label?.split("$")[1] ===
                            undefined
                              ? 0
                              : op?.selectedModifier?.label?.split("$")[1];

                          return (
                            <>
                              {!op.advancedPizzaOptions && (
                                <>
                                  <div className="d-flex text-left justify-content-between mb-2 align-items-center">
                                    <div>
                                      <div
                                        className={
                                          op?.selectedModifier?.label
                                            ? "bold"
                                            : "normal"
                                        }
                                      >
                                        {op?.product_name}
                                        {`${
                                          op?.qty > 1
                                            ? ` - x${
                                                op?.defaultSelected
                                                  ? op?.qty - 1
                                                  : op?.qty
                                              }`
                                            : ""
                                        }`}
                                      </div>
                                    </div>
                                    {newPrice !== 0 && (
                                      <div>
                                        $
                                        <>
                                          {Number(
                                            Number(
                                              Number(newPrice) -
                                                Number(
                                                  op?.selectedModifier?.label.split(
                                                    "$"
                                                  )[1] || 0
                                                )
                                            ) * it?.qty
                                          ).toFixed(2)}
                                        </>
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    {op?.selectedModifier?.label && (
                                      <div
                                        className="d-flex"
                                        style={{
                                          width: "100%",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="text-left">
                                          -{" "}
                                          {
                                            op?.selectedModifier?.label
                                              .split("$")[0]
                                              .split(" -")[0]
                                          }
                                        </div>
                                        {modifierSelectdPrice !== 0 && (
                                          <div>
                                            $
                                            {Number(
                                              modifierSelectdPrice * it?.qty
                                            ).toFixed(2)}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          );
                        })}
                    </div>
                    {it?.instruction && (
                      <div>
                        <h1
                          style={{
                            fontWeight: "500",
                            fontSize: 18,
                            color: "#212121",
                            textAlign: "left",
                          }}
                        >
                          Special Instructions
                        </h1>
                        <p
                          className="py-2 px-3"
                          style={{
                            fontWeight: "500",
                            fontSize: 15,
                            color: "#666666",
                            textAlign: "left",
                            backgroundColor: "#ededed",
                          }}
                        >
                          {it?.instruction}
                        </p>
                      </div>
                    )}
                    {data?.items?.length - 1 !== i && <hr />}
                    {it?.note && (
                      <>
                        <h1
                          style={{
                            fontWeight: "500",
                            fontSize: 18,
                            color: "#212121",
                            textAlign: "left",
                          }}
                        >
                          Special Instructions
                        </h1>
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: 15,
                            color: "#666666",
                            textAlign: "left",
                          }}
                        >
                          {it?.note}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
              <hr />
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <h3 style={{ fontWeight: "600" }}>Subtotal :</h3>
                  <div>
                    <h3 style={{ fontWeight: "600" }} className="mr-1">
                      ${data?.payment?.subTotal?.toFixed(2)}
                    </h3>
                  </div>
                </div>
                <div
                  id="linkToggler"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ cursor: "pointer" }} className=" mt-1">
                    Tax & Fees <FaCaretDown size={20} />
                  </h3>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                    }}
                  >
                    <div>
                      {" "}
                      <h3 className="mr-1">
                        ${parseFloat(data?.payment?.tax).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>
                <div>
                  <UncontrolledCollapse toggler="#linkToggler">
                    <div
                      style={{
                        fontSize: "16px",
                        backgroundColor: "inherit",
                      }}
                      className="py-2"
                    >
                      {data?.payment?.taxDetails?.map((taxName) => {
                        return (
                          <div className="d-flex justify-content-between mb-2">
                            <div>{taxName?.name}</div>
                            <div>${taxName?.amount.toFixed(2)}</div>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-between mb-2">
                        <div>Ordering Fee</div>
                        <div>${data?.payment?.orderFee.toFixed(2)}</div>
                      </div>

                      {data?.method === "delivery" && (
                        <div className="d-flex justify-content-between mb-2">
                          <div>Delivery Fee</div>
                          <div>
                            ${(data?.payment?.deliveryFee || 0)?.toFixed(2)}
                          </div>
                        </div>
                      )}
                    </div>
                  </UncontrolledCollapse>
                </div>
                <div className="bold" style={{ fontSize: 18 }}>
                  {data?.payment.discount > 0 && (
                    <div className="d-flex justify-content-between">
                      <h3>Discount</h3>
                      <h3>-${(data?.payment?.discount || 0)?.toFixed(2)}</h3>
                    </div>
                  )}
                  {data?.payment.loyaltyDiscount > 0 && (
                    <div className="d-flex justify-content-between">
                      <h3>Loyalty Discount</h3>
                      <h3>
                        -${(data?.payment?.loyaltyDiscount || 0)?.toFixed(2)}
                      </h3>
                    </div>
                  )}
                  <div className="d-flex justify-content-between mt-2">
                    <h3>Tip :</h3>
                    <h3>${(data?.payment?.tip || 0)?.toFixed(2)}</h3>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3
                        style={{
                          color: "black",
                        }}
                      >
                        Total :
                      </h3>
                    </div>

                    <div style={{ fontWeight: "600" }}>
                      <h3 style={{ color: "#000" }}>
                        ${data?.payment?.total.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </Collapse>
        <div className="mt-2">
          <Button
            disabled={
              availablity?.some((op) => !op?.isAvailable) ||
              data?.method === "catering"
            }
            style={{
              backgroundColor: theme?.primary,
              color: "white",
              letterSpacing: "0.1em",
              fontSize: 17,
              fontWeight: "400",
              textTransform: "uppercase",
              boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => {
              onClick();
              toast.info("Item added to cart");
            }}
          >
            REORDER
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HistoryCard;
