import create from "zustand";
import { persist } from "zustand/middleware";
const url = window.location.href.replace(/(\/ordering\/\w+).*/, "$1");

export const useOrderingRestaurant = create(
  persist(
    (set, get) => ({
      restaurant: {},
      setRestaurant: (doc) => set({ restaurant: doc }),
    }),
    {
      name: "RESTAURANT: " + url,
      getStorage: () => localStorage,
    }
  )
);
