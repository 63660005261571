import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import swal from "sweetalert";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import DB from "../../../utils/DB";
import { DBKeys } from "../../../utils/Keys";

const RequestAccountDeletion = (props) => {
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();
  const { theme } = useTheme();
  const { create: requestDeletion, loading } = useEntity(
    "eat/auth/request-account-deletion"
  );

  useEffect(() => {
    if (!authContext?.user) {
      let url = new URL(restaurant?.url);
      props.history.push(`${url.pathname}/sign-in`);
    }
  }, [authContext]);

  const { handleSubmit } = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      const data = {
        email: authContext?.user?.email,
        name: authContext?.user?.customer?.name,
        phone: authContext?.user?.customer?.phone,
        userId: authContext?.user?._id,
        restaurant: restaurant?._id,
      };
      try {
        let url = new URL(restaurant?.url);
        await requestDeletion(data);
        toast.success("Account deletion requested successfully");
        DB?.removeData(DBKeys?.CUSTOMER_USER);
        DB.removeData(DBKeys?.CUSTOMER_TOKEN);
        authContext.logout();
        props.history.push(`${url.pathname}/sign-in`);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  return (
    <OrderingLayout
      isCenter={true}
      title="Request Account Deletion"
      history={props.history}
      classname="main_ordering_card_wide"
      layout={true}
      hideLogo={true}
    >
      <div>
        <div className="my-3">
          <input
            className="px-3 py-3 rounded"
            style={{
              width: "100%",
              backgroundColor: "#ededed",
              border: "none",
              fontSize: "18px",
            }}
            type="text"
            placeholder="Name"
            disabled
            value={authContext?.user?.customer?.name}
          />
        </div>
        <div className="my-3">
          <input
            className="px-3 py-3 rounded"
            style={{
              width: "100%",
              backgroundColor: "#ededed",
              border: "none",
              fontSize: "18px",
            }}
            type="email"
            placeholder="Email"
            disabled
            value={authContext?.user?.email}
          />
        </div>
        <div className="my-3">
          <input
            className="px-3 py-3 rounded"
            style={{
              width: "100%",
              backgroundColor: "#ededed",
              border: "none",
              fontSize: "18px",
            }}
            type="number"
            placeholder="Phone"
            value={authContext?.user?.customer?.phone}
            disabled
          />
        </div>

        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              try {
                swal({
                  title: "Are you sure?",

                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    handleSubmit();
                  } else {
                    return;
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
            style={{ backgroundColor: theme.primary, color: "white" }}
            type="button"
          >
            {loading ? <Spinner size="sm" /> : "SUBMIT"}
          </Button>
        </div>
      </div>
    </OrderingLayout>
  );
};

export default RequestAccountDeletion;
