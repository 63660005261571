import moment from "moment";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";

const TableRowOrders = ({ data, history }) => {
  const location = useLocation();
  const { deleteEntity } = useEntity("order");
  return (
    <tr className="text-left">
      <td
        style={{ cursor: "pointer" }}
        onClick={async () => {
          history.push({
            pathname:
              data?.method === "catering"
                ? "/resturants/enquiry-order/view"
                : "/resturants/order/view",
            state: { ...data },
            search: location.search,
          });
        }}
        className="text-center"
      >
        {data?.orderNum}
      </td>
      <td
        style={{ cursor: "pointer" }}
        onClick={async () => {
          history.push({
            pathname:
              data?.method === "catering"
                ? "/resturants/enquiry-order/view"
                : "/resturants/order/view",
            state: { ...data },
            search: location.search,
          });
        }}
      >
        {`${data?.customer?.name}` || "Guest"}
      </td>

      <td style={{ textTransform: "capitalize" }}>{data?.status}</td>
      <td style={{ textTransform: "capitalize" }}>
        ${data?.payment?.total?.toFixed(2)}
      </td>
      <td style={{ textTransform: "capitalize" }}>{data?.method}</td>
      <td>{data?.location?.name}</td>
      <td>{moment(data?.createdAt).format("MM/DD/YYYY hh:mm A")}</td>
      <td>
        <i
          className="fa fa-eye  mx-2"
          style={{ fontSize: 20, cursor: "pointer" }}
          onClick={async () => {
            history.push({
              pathname:
                data?.method === "catering"
                  ? "/resturants/enquiry-order/view"
                  : "/resturants/order/view",
              state: { ...data },
              search: location.search,
            });
          }}
        />
        {data?.method !== "catering" && (
          <i
            className="fa fa-trash  mx-2"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id);
                    toast.error("Order Deleted Succesfully");
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
          />
        )}
      </td>
    </tr>
  );
};

export default TableRowOrders;
