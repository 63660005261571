import { useState } from "react";
import serviceCaller from "../api/serviceCaller";

export default function useLegacyPrinterStatus() {
  const [result, setResult] = useState();

  function findLegacyStatus(macAddress) {
    serviceCaller("legacy", "printerStatus", { macAddress })
      .then((res) => {
        setResult(res);
      })
      .catch((err) => {
        console.log("TEST", err);
      });
  }

  return {
    findLegacyStatus,
    result,
  };
}
