import create from "zustand";
import { persist } from "zustand/middleware";

const useItemsFilter = create(
  persist(
    (set) => ({
      source: "local",
      setSource: (src) => set({ source: src }),
      category: null,
      setCategory: (src) => set({ category: src }),
      tags: null,
      setTags: (src) => set({ tags: src }),
      location: null,
      setLocation: (src) => set({ location: src }),
      reset: () =>
        set({ source: null, category: null, location: null, tags: null }),
    }),
    {
      name: "filter-store",
    }
  )
);

export default useItemsFilter;
