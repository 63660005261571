import { useFormik } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Modal,
  Row,
} from "reactstrap";
import apiCaller from "../../../../api/apiCaller";

export default function AddNewCategoryModal({
  isVisible,
  toggle,
  onClose,
  data,
  saveItem,
  restaurant,
  restaurantDoc,
  method,
  location,
}) {
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      categories: [],
    },
    onSubmit: async () => {
      try {
        values.categories.map(async (c) => {
          const item = await apiCaller("/menu/item-options", {
            query: {
              restaurant,
              locationId: location.map((l) => l.value),
              categoryId: c.value,
              method: method.map((m) => m.value),
              source:
                restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
                !restaurantDoc?.restaurant?.stream?.enableStreamPOS
                  ? "stream"
                  : "local",
            },
          });

          const itemsArray = item?.data
            ?.map((itm) => {
              if (method === "catering" && !itm.catering) {
                return false;
              }
              return {
                _id: itm._id,
                streamId: itm.streamId,
                name: itm.name,
                imageUrl: itm.imageUrl,
                description: itm.description,
                isAvailableDaily: itm.isAvailableDaily,
                availability: itm.availability,
                price: Number(itm?.price || 0),
                plu: itm.plu,
                sort: 0,
                status: true,
                tag:
                  itm?.tag?.map((t) => {
                    return { _id: t._id, name: t.name };
                  }) || [],
                modifiers: itm.modifiers,
              };
            })
            .filter((t) => t);

          console.log("CAPTURE", c);

          saveItem({
            _id: c.value,
            streamId: c.streamId,
            categoryName: c.label,
            sort: 0,
            status: true,
            items: itemsArray,
            isAvailableDaily: c?.isAvailableDaily,
            availability: c?.availability,
          });
        });
        toggle();
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isVisible}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <h1 className="display-4">Add Category</h1>
          </CardHeader>
          <CardBody className="">
            <Row>
              <Col md="12" className="my-2">
                <FormGroup className="text-left">
                  <label className="text-left">Select Categories To Add</label>
                  <Select
                    onChange={(val) => setFieldValue("categories", val)}
                    isMulti
                    name="items"
                    isSearchable={true}
                    options={data
                      ?.map((loca) => {
                        if (method == "catering" && !loca.catering) {
                          return null;
                        }
                        return {
                          label: loca?.categoryName,
                          value: loca?._id,
                          streamId: loca?.streamId,
                          isAvailableDaily: loca?.isAvailableDaily,
                          availability: loca?.availability || [],
                        };
                      })
                      .filter((t) => t)}
                    value={values.items}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-4"
                color="primary"
                type="button"
                onClick={handleSubmit}
                style={{ width: 150 }}
              >
                Add
              </Button>

              <Button
                onClick={onClose}
                style={{ width: 150 }}
                className="mt-4"
                color="warning"
                type="button"
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
