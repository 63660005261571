import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ListGroup, Modal, Spinner } from "reactstrap";
import ProductCard from "../../../components/ProductCard";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";
import { useRestaurantStore } from "../../../store/restaurant";
import { useTheme } from "../../../store/theme";
import getRestaurantPathFromUrl from "../../../utils/getRestaurantPathFromUrl";
import logger from "../../../utils/logger";
import EmptyPlaceholder from "../menu/components/EmptyPlaceholder";
import ModalHeader from "./modals/ModalHeader";
import ViewDishModal from "./ViewDishModal";
import ViewDishModalCatering from "./ViewDishModalCatering";

export default function PopularDishesModal({ isOpen, toggle, origin }) {
  const { theme } = useTheme();
  const { addToCart } = useCart();
  const { restaurant } = useRestaurantStore();
  const { cateringData } = useOrdering();
  const history = useHistory();
  const [dish, setDish] = useState({});
  const [openDish, setOpenDish] = useState(false);
  const {
    find: findPopularPicks,
    entities,
    loading,
  } = useEntity("eat/ordering/popular");
  const { location } = useOrdering();

  useEffect(() => {
    findPopularPicks({
      locationId: location?._id,
      catering: cateringData?.method ? true : false,
      filterDSP: location?.connectedToStream ? "true" : "false",
    });
  }, [location, cateringData]);

  useEffect(() => {
    return () => {
      const url = restaurant?.restaurant?.url;
      // && history.location.pathname === "any specific path")
      if (history.action === "POP") {
        toggle();
        history.push(`/ordering/${getRestaurantPathFromUrl(url)}/${origin}`);
      }
    };
  }, [history]);

  return (
    <Modal
      style={{
        fontFamily: "Inter",
        maxWidth: "600px",
        maxHeight: "700px",
      }}
      size="lg"
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader title="Popular Picks" toggle={toggle} />

      {loading ? (
        <div
          style={{
            alignItems: "center",
            width: "600px",
            display: "flex",
            justifyContent: "center",
            padding: 40,
          }}
        >
          <Spinner
            style={{
              justifySelf: "center",
            }}
            size={"small"}
          />
        </div>
      ) : (
        <div className="popular-modal">
          <ListGroup className="mx-3 my-2" flush>
            {entities?.data?.map((d, i) => {
              return (
                <ProductCard
                  key={i}
                  onClick={() => {
                    setDish(d);
                    setOpenDish(true);
                  }}
                  maxText={50}
                  theme={theme}
                  data={d}
                />
              );
            })}
          </ListGroup>
          {entities?.data?.length <= 0 && (
            <div className="mb-4">
              <EmptyPlaceholder />
            </div>
          )}
        </div>
      )}
      {Object.keys(dish).length > 0 && !cateringData?.catering ? (
        <ViewDishModal
          theme={theme}
          onAddToCart={(op, notes, qty) => {
            try {
              addToCart(
                {
                  ...dish,
                  _id: dish?._id,
                  qty,
                  modifiers: op,
                  note: notes,
                  availableQty: dish?.qty,
                },
                true
              );
            } catch (error) {
              logger.push({ error, info: "MainMenuSectionViewDish" });
              toast.error(error.message);
            }
          }}
          isOpen={openDish}
          dish={dish}
          toggle={() => setOpenDish(!openDish)}
        />
      ) : Object.keys(dish).length > 0 && cateringData?.catering ? (
        <ViewDishModalCatering
          theme={theme}
          onAddToCart={(op, notes, qty) => {
            try {
              addToCart(
                {
                  ...dish,
                  _id: dish?._id,
                  qty,
                  modifiers: op,
                  note: notes,
                  availableQty: dish?.qty,
                },
                true
              );
            } catch (error) {
              logger.push({ error, info: "MainMenuSectionViewDish" });
              toast.error(error.message);
            }
          }}
          isOpen={openDish}
          dish={dish}
          toggle={() => setOpenDish(!openDish)}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
}
