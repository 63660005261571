import React from "react";

const Sections = ({ title, image, description, button, reverse }) => {
  return (
    <section className="py-3">
      <div className="container">
        <div className="row-grid align-items-center text-left row">
          {reverse === true && (
            <>
              <div className="order-md-1 col-md-6">
                <img alt="..." className="img-fluid" src={image} />
              </div>
              <div className="order-md-1 col-md-6">
                <div className="pr-md-5">
                  <h1 className="text-left">{title}</h1>
                  <p>{description}</p>
                  {button && (
                    <a
                      className="font-weight-bold text-info mt-5"
                      href="#/admin/widgets"
                    >
                      Explore widgets
                    </a>
                  )}
                </div>
              </div>
            </>
          )}

          {reverse === false && (
            <>
              <div className="order-md-2 col-md-6">
                <img alt="..." className="img-fluid" src={image} />
              </div>
              <div className="order-md- col-md-6">
                <div className="pr-md-5">
                  <h1 className="text-left">{title}</h1>
                  <p>{description}</p>
                  {button && (
                    <a
                      className="font-weight-bold text-info mt-5"
                      href="#/admin/widgets"
                    >
                      Explore widgets
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Sections;
