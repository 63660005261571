/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { Button, Progress, Spinner } from "reactstrap";
import Line from "../../../../assets/icons/line.svg";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";

const LoyaltyBalance = ({ onsubmit, billingLoading, billing }) => {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();
  const {
    find: findLoyalty,
    entities: loyaltyPoints,
    loading: loyaltyLoading,
  } = useEntity("eat/ordering/loyalty-points");

  useEffect(() => {
    if (authContext?.user) {
      findLoyalty({
        customer: authContext?.user?.customer?._id,
        restaurant: restaurant?.userId?._id,
      });
    }
  }, [authContext]);

  return (
    <>
      {!billingLoading ? (
        <div
          className="px-4 py-4 mb-4"
          style={{
            background: " #FFFFFF",
            boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
            borderRadius: 6,
          }}
        >
          <h1
            style={{
              fontWeight: "600",
              color: theme.primary,
            }}
          >
            Loyalty Balance
          </h1>

          <div className="loyalty_wrapper">
            <div style={{ flex: 0.3, width: "100%" }}>
              <h4
                style={{
                  fontWeight: "600",
                  color: theme.primary,
                  textTransform: "uppercase",
                }}
              >
                Available Points
              </h4>
              <span className="text-muted">
                {Math.ceil(loyaltyPoints?.data?.points) || 0} PTS Available.
              </span>
            </div>
            <div className="mx-3 hide_mobile">
              <img src={Line} alt="" height={"100px"} />
            </div>

            <div style={{ flex: 1, width: "100%" }}>
              <Progress style={{ height: 30, marginTop: 10 }} multi>
                <Progress
                  style={{ backgroundColor: "#5F9B0A" }}
                  bar
                  value={Math.ceil(loyaltyPoints?.data?.points) || 0}
                  max={2.5 * loyaltyPoints?.data?.points}
                >
                  {Math.ceil(
                    loyaltyPoints?.data?.points - billing?.pointsUsed
                  ) || 0}{" "}
                  PTS
                </Progress>
              </Progress>
              {restaurant?.loyalty?.minSubtotal > billing?.subTotal && (
                <div>
                  <small className="text-red">
                    Doesn't meet loyalty requirements (Min. $
                    {restaurant?.loyalty?.minSubtotal} Subtotal required )
                  </small>
                </div>
              )}
              {loyaltyPoints?.data?.points <
                restaurant?.loyalty?.minRedeemableAmount && (
                <div>
                  <small className="text-red">
                    You don't have enough points to redeem. (Min.{" "}
                    {restaurant?.loyalty?.minRedeemableAmount} PTS required for
                    redemption)
                  </small>
                </div>
              )}
            </div>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="mt-3 "
                style={{
                  backgroundColor: theme?.primary,
                  color: "white",
                  textTransform: "uppercase",
                  letterSpacing: "0.1em",
                }}
                onClick={() => {
                  onsubmit(Math.ceil(loyaltyPoints?.data?.points));
                  toast.success("Loyalty points redeemed successfully");
                }}
                disabled={
                  billing?.loyaltyDiscount > 0 ||
                  billingLoading ||
                  !authContext?.user?.customer ||
                  !restaurant?.ordering?.allowLoyaltyTransaction ||
                  restaurant?.loyalty?.minSubtotal > billing?.subTotal ||
                  loyaltyPoints?.data?.points <
                    restaurant?.loyalty?.minRedeemableAmount
                }
              >
                {billingLoading || loyaltyLoading ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  "Use now"
                )}
              </Button>

              {billing?.loyaltyDiscount > 0 && (
                <Button
                  className="mt-3 "
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    textTransform: "uppercase",
                    letterSpacing: "0.1em",
                  }}
                  onClick={() => {
                    onsubmit("");
                  }}
                >
                  Remove
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            background: " #FFFFFF",
            boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
            borderRadius: 6,
          }}
          className="px-4 py-4 mb-4 single_card_checkout"
        >
          <Skeleton height="30px" width="20%" />
          <Skeleton style={{ marginTop: 10 }} height="30px" width="60%" />
          <Skeleton style={{ marginTop: 10 }} height="30px" width="70%" />
          <Skeleton style={{ marginTop: 10 }} height="30px" width="80%" />
          <Skeleton style={{ marginTop: 10 }} height="30px" width="100%" />
        </div>
      )}
    </>
  );
};

export default LoyaltyBalance;
