import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import ModalHeader from "../../screens/ordering/components/modals/ModalHeader";
import { BiRefresh } from "react-icons/bi";
import ErrorLable from "../../utils/ErrorLable";
import { useFormik } from "formik";
import { useEntity } from "../../hooks/useEntity";
import { generateUsername } from "friendly-username-generator";
import swal from "sweetalert";
import * as Yup from "yup";

const DeviceSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required("Username is required")
    .matches(/^\S+$/, "Username cannot contain spaces"),
  name: Yup.string("Name is required").trim().required("Required"),
  password: Yup.string().trim().required("Required"),
});

export default function CreateIpadDevice({ isOpen, toggle, location }) {
  const { create } = useEntity("ipad-device");
  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      password: "",
      location,
    },
    validationSchema: DeviceSchema,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        await create(values);
        swal("Success", "Device created", "success");
        toggle();
      } catch (error) {
        swal("Error", error.message || "Unable to create device", "error");
      }
    },
  });

  const handleGenerate = (type) => {
    const generated = generateUsername({ useHyphen: false });
    formik.setFieldValue(type, generated);
  };

  useEffect(() => {
    formik.setValues({
      name: "",
      username: "",
      password: "",
      location,
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={"Create IPAD Device"} />
      <Form className="mt-4">
        <Col md="12">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="3"
              >
                Name
              </Label>
              <Col md="9">
                <Input
                  type="text"
                  placeholder="Username"
                  value={formik.values.name}
                  onChange={formik.handleChange("name")}
                  className={`form-control ${
                    formik.errors.name && formik.touched.name
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {formik.errors.name && formik.touched.name ? (
                  <ErrorLable message={formik.errors.name} />
                ) : (
                  <small>Enter a name for tablet</small>
                )}
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col md="12">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="3"
              >
                Username
              </Label>
              <Col md="7">
                <Input
                  type="text"
                  placeholder="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange("username")}
                  className={`form-control ${
                    formik.errors.username && formik.touched.username
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {formik.errors.username && formik.touched.username ? (
                  <ErrorLable message={formik.errors.username} />
                ) : (
                  <small>Enter a username for tablet</small>
                )}
              </Col>
              <Col md="2">
                <Button onClick={() => handleGenerate("username")}>
                  <BiRefresh />
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col md="12">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="3"
              >
                Password
              </Label>
              <Col md="7">
                <Input
                  type="text"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                  className={`form-control ${
                    formik.errors.password && formik.touched.password
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {formik.errors.password && formik.touched.password ? (
                  <ErrorLable message={formik.errors.password} />
                ) : (
                  <small>Enter a password for tablet</small>
                )}
              </Col>
              <Col md="2">
                <Button onClick={() => handleGenerate("password")}>
                  <BiRefresh />
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Form>
      <Row className="py-2">
        <Col md={7}></Col>
        <Col md={2}>
          <Button onClick={formik.handleSubmit}>
            {formik.isSubmitting ? <Spinner size={"sm"} /> : "Save"}
          </Button>
        </Col>
        <Col md={2}>
          <Button
            color="warning"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
