import classnames from "classnames";
import { useState } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import TableWrapper from "../../../widgets/TableWrapper";
import OrderDetails from "./tabs/OrderDetails";
import Products from "./tabs/Products";

export default function ViewOrder({ history, location }) {
  const { state: data } = location;

  const [tabs, setTabs] = useState(1);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <>
      <TimelineHeader
        path="/resturants/orders"
        name={`Order #${data?.orderNum}`}
        parentName="Orders"
        showBtn={true}
      >
        <Button
          className="btn-icon btn-2"
          color="neutral"
          type="button"
          onClick={() => {
            history.goBack();
          }}
        >
          <span>Back</span>
        </Button>
      </TimelineHeader>

      <TableWrapper customStyles="px-4 py-3">
        {" "}
        <Nav
          className="nav-fill flex-column flex-md-row "
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Order Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Product
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="mt-5" activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <OrderDetails data={data} history={history} />
          </TabPane>
          <TabPane tabId="tabs2">
            <Products data={data} />
          </TabPane>
        </TabContent>
      </TableWrapper>
    </>
  );
}
