import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router";
import { AuthContext } from "../../context";
import DB from "../../utils/DB";
import { DBKeys } from "../../utils/Keys";
import useItemsFilter from "../../store/items-filter";
import useCategoryFilter from "../../store/category-filter";

export default function Logout() {
  const authContext = useContext(AuthContext);
  const { setCategory, setLocation, setTags, setSource } = useItemsFilter();
  const {
    setCategory: setCategory1,
    setLocation: setLocation1,
    setTags: setTags1,
    setSource: setSource1,
  } = useCategoryFilter();
  useEffect(() => {
    //Removing TOKEN and USER
    DB.removeData(DBKeys.USER);
    DB.removeData(DBKeys.TOKEN);
    setCategory("");
    setTags(null);
    setLocation("all");
    setSource("local");
    setCategory1("");
    setTags1(null);
    setLocation1("all");
    setSource1("local");
    authContext.logout();
  }, []);

  return <Redirect to="/login" />;
}
