import moment from "moment";
import React, { useCallback, useContext, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { isMobile } from "react-device-detect";
import { FaTimes } from "react-icons/fa";
import AsyncSelect from "react-select/async";
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Row,
  Table,
} from "reactstrap";
import apiCaller from "../../../api/apiCaller";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import Loader from "../../../components/Loader";
import usePartnerId from "../../../hooks/usePartnerId";
import { toFixed } from "../../../utils/toFixed";

/** @typedef {object} Report
 * @property {number} totalOrders
 * @property {number} totalSales
 * @property {number} totalOrderFee
 * @property {number} totalSubTotal
 * @property {number} tip
 * @property {number} totalServiceFee
 * @property {number} totalTax
 * @property {number} pickupTotalOrders
 * @property {number} pickupTotalSales
 * @property {number} pickupTotalOrderFee
 * @property {number} pickupTotalSubTotal
 * @property {number} pickupTip
 * @property {number} pickupTotalServiceFee
 * @property {number} pickupTotalTax
 * @property {number} deliveryTotalOrders
 * @property {number} deliveryTotalSales
 * @property {number} deliveryTotalOrderFee
 * @property {number} deliveryTotalSubTotal
 * @property {number} deliveryTip
 * @property {number} deliveryTotalServiceFee
 * @property {number} deliveryTotalTax
 * @property {number} totalOrdersIHD
 * @property {number} totalDeliveryFeeIHD
 * @property {number} totalTipsIHD
 * @property {number} totalOrdersNativeDelivery
 * @property {number} totalDeliveryFeeNativeDelivery
 * @property {number} totalTipsNativeDelivery
 */

export default function SalesReportPartner(props) {
  /** @type {[Report?, React.Dispatch<Report>]} */
  const [report, setReport] = useState();
  const partnerId = usePartnerId();

  const [isLoading, setIsLoading] = useState(false);

  const [dateRange, setDateRange] = useState({
    from: moment().startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  });
  const [restaurants, setRestaurants] = useState([]);
  const [showDate, setShowDate] = useState(false);

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };
    // needed for a bug in react-date-range which does not
    // set correct end date time
    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setDateRange(range);
  };

  const selectionRange = {
    startDate: dateRange.from,
    endDate: dateRange.to,
    key: "selection",
  };

  const runReport = useCallback(() => {
    setIsLoading(true);
    setShowDate(false);
    apiCaller(`/reports/partner/sales`, {
      query: {
        partner: partnerId,
        restaurants,
        date: {
          ...dateRange,
          // from: moment(dateRange.from).startOf("day").toDate(),
          // to: moment(dateRange.to).endOf("day").toDate(),
        },
      },
    })
      .then((res) => {
        setReport(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [restaurants, dateRange]);

  const promiseOptions = (inputValue) => {
    return apiCaller(
      `/restaurant/search?_q=${encodeURIComponent(
        inputValue
      )}&partner=${partnerId}`
    )
      .then((res) => {
        console.log(res);
        return res?.data?.map((item) => ({
          value: item.userId,
          label: item.name,
        }));
      })
      .catch((err) => []);
  };

  const exportReport = () => {
    if (!report) return;

    const csv = [
      [
        "Overview",
        "",
        "Total Orders",
        "Total Sales",
        "Total Order Fee",
        "Average Order Fee",
        "Total Sub Total",
        "Average Sub Total",
        "Total Service Fee",
        "Total Tax",
        "",
        "Pickup",
        "",
        "Pickup Total Orders",
        "Pickup Total Sales",
        "Pickup Total Order Fee",
        "Pickup Average Order Fee",
        "Pickup Total Sub Total",
        "Pickup Average Sub Total",
        "Pickup Tip",
        "",
        "Delivery",
        "",
        "Delivery Total Orders",
        "Delivery Total Sales",
        "Delivery Total Order Fee",
        "Delivery Average Order Fee",
        "Delivery Total Sub Total",
        "Delivery Average Sub Total",
        "Delivery Tip",
        "IHD Orders",
        "IHD Integration Fee",
        "IHD Delivery Fee",
        "IHD Delivery Tips",
        "Native Delivery Orders",
        "Native Delivery Tip",
      ],
      [
        "",
        "",
        report.totalOrders,
        report.totalSales,
        report.totalOrderFee,
        report.totalOrderFee / report.totalOrders,
        report.totalSubTotal,
        report.totalSubTotal / report.totalOrders,
        report.totalServiceFee,
        report.totalTax,
        "",
        "",
        "",
        report.pickupTotalOrders,
        report.pickupTotalSales,
        report.pickupTotalOrderFee,
        report.pickupTotalOrderFee / report.pickupTotalOrders,
        report.pickupTotalSubTotal,
        report.pickupTotalSubTotal / report.pickupTotalOrders,
        report.pickupTip,
        "",
        "",
        "",

        report.deliveryTotalOrders,
        report.deliveryTotalSales,
        report.deliveryTotalOrderFee,
        report.deliveryTotalOrderFee / report.deliveryTotalOrders,
        report.deliveryTotalSubTotal,
        report.deliveryTotalSubTotal / report.deliveryTotalOrders,
        report.deliveryTip,
        report.totalOrdersIHD,
        toFixed(report.totalIHDFee),
        report.totalDeliveryFeeIHD,
        report.totalTipsIHD,
        report.totalOrdersNativeDelivery,
        report.totalTipsNativeDelivery,
      ],
    ];

    const csvData = csv[0]
      .map((e, i) => {
        let value = toFixed(csv[1][i], true);
        if (e === "Overview" || e === "Pickup" || e === "Delivery" || e === "")
          value = "";
        return `${csv[0][i]},${value}`;
      })
      .join("\n");

    const csvContent = `data:text/csv;charset=utf-8,${csvData}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `sales-report-${moment(dateRange.from).format("DD-MM-YYYY")}-${moment(
        dateRange.to
      ).format("DD-MM-YYYY")}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <>
      <SimpleHeader
        name="Sales report"
        parentName="Dashboard"
        showBtn={true}
      ></SimpleHeader>
      <Container fluid>
        <Card className="py-4 px-3 mt--6">
          <Row>
            <Col md="6">
              <FormGroup className="text-left">
                <label>Restaurants</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  isMulti
                  loadOptions={promiseOptions}
                  onChange={(options = []) => {
                    if (options.length > 0) {
                      setRestaurants(options.map((opt) => opt.value));
                    } else {
                      setRestaurants([]);
                    }
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup className="text-left">
                <label className="text-left">Date</label>
                <div
                  className="rounded"
                  style={{
                    border: "1px solid hsl(0, 0%, 80%)",
                    height: "40px",
                    width: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setShowDate(!showDate)}
                >
                  {moment(dateRange.from).format("MM/DD/YYYY")} -{" "}
                  {moment(dateRange.to).format("MM/DD/YYYY")}
                </div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup className="text-left">
                <label></label>
                <div>
                  <Button style={{ marginTop: 5 }} onClick={runReport}>
                    Run Report
                  </Button>
                  <Button style={{ marginTop: 5 }} onClick={exportReport}>
                    Export
                  </Button>
                </div>
              </FormGroup>
            </Col>
            {showDate && (
              <div
                className="px-3 py-3 shadow"
                style={{
                  position: "absolute",
                  zIndex: 999,
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  right: 150,
                  top: 100,
                }}
              >
                <FaTimes
                  className="mb-5"
                  color="black"
                  onClick={() => setShowDate(!showDate)}
                />
                <DateRangePicker
                  ranges={[selectionRange]}
                  rangeColors={["#0FCDEF"]}
                  onChange={handleSelectDates}
                />
              </div>
            )}
          </Row>
          {isLoading && <Loader />}
          {report && !isLoading && (
            <Row>
              <Col md="12">
                <Row>
                  <Col md="12" className="text-left">
                    <h2>Total</h2>
                  </Col>
                  <Col
                    md="12"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Table bordered className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Orders</th>
                          <th scope="col">Sales</th>
                          <th scope="col">Order Fees</th>
                          <th scope="col">Avg Order Fee</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Avg Subtotal</th>
                          <th scope="col">Service Fee</th>
                          <th scope="col">Tax</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{report.totalOrders}</td>
                          <td>${toFixed(report.totalSales)}</td>
                          <td>${toFixed(report.totalOrderFee)}</td>
                          <td>
                            $
                            {toFixed(report.totalOrderFee / report.totalOrders)}
                          </td>
                          <td>${toFixed(report.totalSubTotal)}</td>
                          <td>
                            $
                            {toFixed(report.totalSubTotal / report.totalOrders)}
                          </td>
                          <td>${toFixed(report.totalServiceFee)}</td>
                          <td>${toFixed(report.totalTax)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col md="12" className="text-left">
                    <h2>Pickup</h2>
                  </Col>
                  <Col
                    md="12"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Table bordered className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Orders</th>
                          <th scope="col">Sales</th>
                          <th scope="col">Order Fees</th>
                          <th scope="col">Avg Order Fee</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Avg Subtotal</th>
                          <th scope="col">Tips</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{report.pickupTotalOrders}</td>
                          <td>${toFixed(report.pickupTotalSales)}</td>
                          <td>${toFixed(report.pickupTotalOrderFee)}</td>
                          <td>
                            $
                            {toFixed(
                              report.pickupTotalOrderFee /
                                report.pickupTotalOrders
                            )}
                          </td>
                          <td>${toFixed(report.pickupTotalSubTotal)}</td>
                          <td>
                            $
                            {toFixed(
                              report.pickupTotalSubTotal /
                                report.pickupTotalOrders
                            )}
                          </td>
                          <td>${toFixed(report.pickupTip)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col md="12" className="text-left">
                    <h2>Delivery</h2>
                  </Col>
                  <Col
                    md="12"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Table bordered className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Total Orders</th>
                          <th scope="col">Total Sales</th>
                          <th scope="col">Total Order Fees</th>
                          <th scope="col">Avg Order Fee</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Avg Subtotal</th>
                          <th scope="col">Tips</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{report.deliveryTotalOrders}</td>
                          <td>${toFixed(report.deliveryTotalSales)}</td>
                          <td>${toFixed(report.deliveryTotalOrderFee)}</td>
                          <td>
                            $
                            {toFixed(
                              report.deliveryTotalOrderFee /
                                report.deliveryTotalOrders
                            )}
                          </td>
                          <td>${toFixed(report.deliveryTotalSubTotal)}</td>
                          <td>
                            $
                            {toFixed(
                              report.deliveryTotalSubTotal /
                                report.deliveryTotalOrders
                            )}
                          </td>
                          <td>${toFixed(report.deliveryTip)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col
                    md="12"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Table bordered className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">IHD Orders</th>
                          <th scope="col">IHD Integration Fee</th>
                          <th scope="col">IHD Delivery Fees</th>
                          <th scope="col">IHD Delivery Tips</th>
                          <th scope="col">Native Delivery Orders</th>
                          <th scope="col">Native Delivery Tip</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{report.totalOrdersIHD}</td>
                          <td>${toFixed(report.totalIHDFee)}</td>
                          <td>${toFixed(report.totalDeliveryFeeIHD)}</td>
                          <td>${toFixed(report.totalTipsIHD)}</td>
                          <td>{toFixed(report.totalOrdersNativeDelivery)}</td>
                          <td>${toFixed(report.totalTipsNativeDelivery)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col md="12" className="text-left">
                    <h2>Dinein</h2>
                  </Col>
                  <Col
                    md="12"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Table bordered className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Orders</th>
                          <th scope="col">Sales</th>
                          <th scope="col">Order Fees</th>
                          <th scope="col">Avg Order Fee</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Avg Subtotal</th>
                          <th scope="col">Tips</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{report?.dineInTotalOrders || 0}</td>
                          <td>${toFixed(report?.dineInTotalSales || 0)}</td>
                          <td>${toFixed(report?.dineInTotalOrderFee || 0)}</td>
                          <td>
                            $
                            {toFixed(
                              (report?.dineInTotalOrderFee || 0) /
                                (report?.dineInTotalOrders || 0)
                            )}
                          </td>
                          <td>${toFixed(report?.dineInTotalSubTotal || 0)}</td>
                          <td>
                            $
                            {toFixed(
                              (report?.dineInTotalSubTotal || 0) /
                                (report?.dineInTotalOrders || 0)
                            )}
                          </td>
                          <td>${toFixed(report?.dineInTip || 0)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  <Col md="12" className="text-left">
                    <h2>Catering</h2>
                  </Col>
                  <Col
                    md="12"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Table bordered className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Orders</th>
                          <th scope="col">Sales</th>
                          <th scope="col">Order Fees</th>
                          <th scope="col">Avg Order Fee</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Avg Subtotal</th>
                          <th scope="col">Tips</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{report.cateringTotalOrders}</td>
                          <td>${toFixed(report.cateringTotalSales)}</td>
                          <td>${toFixed(report.cateringTotalOrderFee)}</td>
                          <td>
                            $
                            {toFixed(
                              report.cateringTotalOrderFee /
                                report.cateringTotalOrders
                            )}
                          </td>
                          <td>${toFixed(report.cateringTotalSubTotal)}</td>
                          <td>
                            $
                            {toFixed(
                              report.cateringTotalSubTotal /
                                report.cateringTotalOrders
                            )}
                          </td>
                          <td>${toFixed(report.cateringTip)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Card>
      </Container>
    </>
  );
}
