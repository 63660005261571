/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import Line from "../../../../assets/icons/line.svg";
import Horizontal from "../../../../assets/icons/horizontal_line.svg";
import { FaTable, FaWalking } from "react-icons/fa";
import AddressFormatter from "../../components/AddressFormatter";

const getDeliveryTime = (location, deliveryZone) => {
  let deliveryReadyTime = 0;
  if (location?.enableIhd) {
    deliveryReadyTime = location?.ihdReadyTime || 0;
  } else {
    deliveryReadyTime = deliveryZone?.deliveryReadyTime || 0;
  }

  return deliveryReadyTime;
};

const OrderDetails = () => {
  const [time, setTime] = useState(new Date());

  const {
    laterTime,
    laterDate,
    deliveryConfirmAddress,
    tableNo,
    method: methodOrdering,
    location: locationOrdering,
    timing,
    deliveryZone,
    cateringData,
  } = useOrdering();

  const getReadyTime = () => {
    if (methodOrdering === "pickup" || methodOrdering === "dine-in") {
      return locationOrdering?.pickupDelivery?.pickupPrepTime || 10;
    }
    return getDeliveryTime(locationOrdering, deliveryZone);
  };

  useEffect(() => {
    if (timing === "later") {
      let date = new Date(laterDate);
      let splittedTime = laterTime.split(":");
      date.setHours(parseInt(splittedTime[0]));
      date.setMinutes(parseInt(splittedTime[1]));
      setTime(date);
    } else {
      let date = new Date();
      date.setMinutes(date.getMinutes() + Number(getReadyTime()));
      setTime(date);
    }
  }, [timing]);

  return (
    <div className="menu_order_details mb-2">
      <div className="text-left">
        <h2
          style={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            letterSpacing: "0.15em",
            textTransform: "uppercase",
          }}
        >
          Method
        </h2>
        <div>
          {methodOrdering === "delivery" &&
          (!cateringData || cateringData?.method === "") ? (
            <>
              <div style={{ textAlign: "left" }}>
                {" "}
                <span>Delivery</span>
                <span style={{ color: "#666666", textAlign: "left" }}>
                  <p
                    className="m-0"
                    style={{
                      maxWidth: "400px",
                      fontWeight: "500",
                      fontSize: 14,
                    }}
                  >
                    {(deliveryConfirmAddress?.apartmentNo?.length > 0
                      ? deliveryConfirmAddress.apartmentNo + ","
                      : "") + deliveryConfirmAddress?.formatted_address}
                  </p>
                </span>
              </div>
            </>
          ) : methodOrdering === "pickup" &&
            cateringData?.method === "pickup" ? (
            <>
              <span>Catering - Pickup</span>
            </>
          ) : methodOrdering === "delivery" &&
            cateringData?.method === "delivery" ? (
            <>
              <span>Catering - Delivery</span>
              <span style={{ color: "#666666", textAlign: "left" }}>
                <p
                  className="m-0"
                  style={{
                    maxWidth: "400px",
                    fontWeight: "500",
                    fontSize: 14,
                  }}
                >
                  {(cateringData?.apartment?.length > 0
                    ? cateringData?.apartment + ","
                    : "") + cateringData?.address}
                </p>
              </span>
            </>
          ) : methodOrdering === "dine-in" ? (
            <>
              <span
                style={{
                  textAlign: "left",
                  lineHeight: 1.8,
                  fontSize: 14,
                  color: "#000",
                }}
              >
                Dine In
              </span>
              <br />
              <span
                style={{
                  textAlign: "left",
                  lineHeight: 1.8,
                  fontSize: 14,
                  color: "#000",
                }}
              >
                Table No {tableNo}
              </span>
            </>
          ) : methodOrdering === "walkup" ? (
            <>
              <span>
                <FaWalking size={25} />
              </span>
              <span className="mx-3">Walk Up</span>
            </>
          ) : (
            <>
              <span>Pickup</span>
            </>
          )}
        </div>
      </div>
      <div>
        <img
          className="show_mobile py-4"
          src={Horizontal}
          alt=""
          style={{ height: "90%", maxWidth: "100%" }}
        />
        <img className="hide_mobile  mx-3" src={Line} alt="" height={"100px"} />
      </div>
      <div>
        <h2
          style={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            letterSpacing: "0.15em",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
          Location
        </h2>
        <div style={{ textAlign: "left", lineHeight: 1.8 }}>
          <span style={{ color: "#666666", textAlign: "left" }}>
            <p
              className="m-0"
              style={{ maxWidth: "400px", fontWeight: "500", fontSize: 14 }}
            >
              <AddressFormatter address={locationOrdering?.displayAddress} />
            </p>
          </span>
        </div>
      </div>
      <div>
        <img
          className="show_mobile py-4"
          src={Horizontal}
          alt=""
          style={{ height: "90%", maxWidth: "100%" }}
        />
        <img className="hide_mobile  mx-3" src={Line} alt="" height={"100px"} />
      </div>
      <div>
        <h2
          style={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            letterSpacing: "0.15em",
            textTransform: "uppercase",
            textAlign: "left",
          }}
        >
          Time
        </h2>
        <div
          style={{
            width: 200,
            textAlign: "left",
            lineHeight: 1.8,
            fontSize: 14,
          }}
        >
          <span>
            <span>{moment(time).format("MMMM DD, YYYY hh:mm A")}</span>
          </span>

          {timing === "now" && (
            <div>{`Today - ${moment(time).format("hh:mm A")}`}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
