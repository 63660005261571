function groupDaysByTime(days) {
  const groupedDays = {};
  console.log("DIBBA", days);

  // Loop through each day and group by startDate and endDate
  for (let day of days) {
    const timeRange = `${day.startDate}-${day.endDate}`;
    if (groupedDays[timeRange]) {
      groupedDays[timeRange].days.push(day.day);
    } else {
      groupedDays[timeRange] = {
        days: [day.day],
        schedule: {
          startDate: day.startDate,
          endDate: day.endDate,
        },
      };
    }
  }

  // Convert object to array of sub-objects
  return Object.values(groupedDays);
}

export default groupDaysByTime;
