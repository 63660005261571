import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropzone-component/styles/filepicker.css";

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import "./App.css";
import "./assets/vendor/select2/index.css";
import CommonLayout from "./components/CommonLayout";
import { AuthContext } from "./context/index";
// import { fetchToken } from "./firebase";
import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import Landing from "./screens/landing/Landing";
import OrderingRoutes from "./screens/ordering/routes";
import { getTheme } from "./utils/Appearance";
import { on } from "./utils/custom-event";
import DB from "./utils/DB";
import { DBKeys } from "./utils/Keys";
// import { LogglyProvider } from "react-loggly-jslogger";
import "react-quill/dist/quill.snow.css";
import ForgetPassword from "./screens/auth/ForgetPassword";
import UpdatePassword from "./screens/auth/UpdatePassword";
import VerifyOTP from "./screens/auth/VerifyOTP";
import OAuthLanding from "./screens/OAuthLanding";
import TemporaryOrderDetails from "./screens/ordering/checkout/TemporaryOrderDetails";
import OAuthLandingStreamDsp from "./screens/settings/stream-dsp/OAuthLanding";
import OAuthLandingStream from "./screens/settings/stream/OAuthLanding";
import logger from "./utils/logger";

function App() {
  const [user, setUser] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("#BC351B");
  const [isLoading, setIsLoading] = useState(true);
  const theme = getTheme(primaryColor);
  const pathname = window.location.pathname;

  useEffect(() => {
    let key = pathname.includes("/ordering/")
      ? DBKeys.CUSTOMER_USER
      : DBKeys.USER;
    DB.retrieveData(key)
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        logger.push({ error: err, info: "App.js" });
        console.error(err);
      });

    setIsLoading(false);
  }, [pathname]);

  const authContext = {
    login: (data) => {
      setUser(data.user);
    },
    logout: () => {
      setUser(null);
    },
    user: user,
    token: localStorage.getItem(DBKeys.TOKEN),
    theme: theme,
    changeLook: (color) => {
      DB.storeData("primary", color || "red");
      setPrimaryColor(color);
    },
  };

  useEffect(() => {
    on("logout", () => {
      let key = pathname.includes("/ordering/")
        ? DBKeys.CUSTOMER_USER
        : DBKeys.USER;
      let token = pathname.includes("/ordering/")
        ? DBKeys.CUSTOMER_TOKEN
        : DBKeys.TOKEN;
      localStorage.removeItem(key);
      localStorage.removeItem(token);
      setUser(null);
    });
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          padding: 20,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <center>
          <Spinner size="lg" color="black" />
        </center>
      </div>
    );
  }

  return (
    <div className="App">
      <ToastContainer position="bottom-right" />
      <AuthContext.Provider value={authContext}>
        <Router>
          <Switch>
            <Route exact path="/" render={(props) => <Landing {...props} />} />

            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/oauth"
              render={(props) => <OAuthLanding {...props} />}
            />
            <Route
              exact
              path="/stream/oauth"
              render={(props) => <OAuthLandingStream {...props} />}
            />
            <Route
              exact
              path="/stream-dsp/oauth"
              render={(props) => <OAuthLandingStreamDsp {...props} />}
            />

            <Route
              exact
              path="/register"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forget-password"
              render={(props) => <ForgetPassword {...props} />}
            />
            <Route
              exact
              path="/verify-otp"
              render={(props) => <VerifyOTP {...props} />}
            />
            <Route
              exact
              path="/update-password"
              render={(props) => <UpdatePassword {...props} />}
            />

            <Route
              exact
              path="/o/:alias"
              render={(props) => <TemporaryOrderDetails {...props} />}
            />

            {user ? (
              <Switch>
                {user?.role === "super-admin" && (
                  <Switch>
                    <Route
                      exact
                      path="*"
                      render={(props) => <CommonLayout {...props} />}
                    />
                    <OrderingRoutes />
                  </Switch>
                )}
                {user?.role === "customer" && (
                  <>
                    <OrderingRoutes />
                  </>
                )}
                {user?.role === "partner" && (
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/partners" />}
                    />

                    <Route
                      exact
                      path="*"
                      render={(props) => <CommonLayout {...props} />}
                    />

                    <OrderingRoutes />
                  </Switch>
                )}

                {user?.role === "restaurant" && (
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/resturants" />}
                    />

                    <Route
                      exact
                      path="*"
                      render={(props) => <CommonLayout {...props} />}
                    />

                    <OrderingRoutes />
                  </Switch>
                )}
              </Switch>
            ) : (
              <>
                <Route exact path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <OrderingRoutes />
              </>
            )}
          </Switch>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
