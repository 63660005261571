import { useContext, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiArrowBack, BiRefresh, BiUserCircle } from "react-icons/bi";
import { FiShoppingCart } from "react-icons/fi";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import swal from "sweetalert";
import { AuthContext } from "../../context";
import CartModal from "../../screens/ordering/components/CartModal";
import LoginModal from "../../screens/ordering/components/LoginModal";
import MyAccount from "../../screens/ordering/home/components/MyAccount";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../store/theme";
import DB from "../../utils/DB";
import { DBKeys } from "../../utils/Keys";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import InfoModal from "./InfoModal";

const TopNav = ({ history, startOver, cart, menu, checkout, tip }) => {
  const { theme } = useTheme();
  const { restaurant } = useOrderingRestaurant();
  const { location } = useOrdering();
  const { cartItems, clearCart, setCoupon } = useCartStore();
  const [isOpenCart, setIsOpenCart] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const { method, walkupURL } = useOrdering();

  const toggle = () => {
    setOpenDropdown(!openDropdown);
  };

  const authContext = useContext(AuthContext);
  const [accountModal, setAccountModal] = useState(false);

  const sumQty = cartItems.reduce((sum, obj) => sum + obj.qty, 0);

  return (
    <div
      className={`px-4 py-3 ${
        menu ? "top_nav_ordering_menu" : "top_nav_ordering_checkout"
      }`}
    >
      <div
        onClick={() => {
          swal({
            title: "Are you sure?",
            text: "Are you sure you want to startover?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              clearCart();
              setCoupon(null);
              if (method === "walkup") {
                window.location.href = walkupURL;
              } else {
                let url = new URL(restaurant?.url);
                history.push(url.pathname);
              }
            }
          });
        }}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <div id="tooltip611234743">
          <BiRefresh color={theme.secondary} size={23} />
        </div>
        <UncontrolledTooltip
          delay={0}
          placement="top"
          target="tooltip611234743"
        >
          Start over
        </UncontrolledTooltip>
      </div>
      {startOver && (
        <div
          className="mx-3"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flex: 1,
            cursor: "pointer",
          }}
          onClick={() => {
            let url = new URL(restaurant?.url);
            history.push(url.pathname + "/menu", { fromFlow: true });
          }}
        >
          <div>
            <BiArrowBack size={23} color="#212121" />
          </div>
          <div style={{ color: "#212121" }} className="mx-2 text-sm bold">
            Back to menu
          </div>
        </div>
      )}

      <div
        className="text-sm top_nav_icons"
        style={{ flex: 1.5, display: "flex", justifyContent: "flex-end" }}
      >
        <div className="text_top_nav">
          <span style={{ color: theme?.primary }}>Need Help? </span>
          <span className="mx-2">
            <a style={{ color: "inherit" }} href={`tel:${location?.phone}`}>
              {formatPhoneNumber(location?.phone)}
            </a>{" "}
            or{" "}
            <a style={{ color: "inherit" }} href={`mailto:${location?.email}`}>
              {location?.email}
            </a>
          </span>
        </div>
        {!authContext?.user && (
          <span
            className="mx-3"
            onClick={() => setLoginModal(true)}
            style={{ cursor: "pointer" }}
          >
            <BiUserCircle size={25} color={theme.secondary} />
          </span>
        )}
        {authContext?.user && (
          <>
            <Dropdown direction="left" isOpen={openDropdown} toggle={toggle}>
              <DropdownToggle
                style={{
                  boxShadow: "none",
                  backgroundColor: "white",
                  border: "none",
                  margin: "0px",
                }}
                className="py-0 "
                size="sm"
              >
                <span style={{ cursor: "pointer" }}>
                  <BiUserCircle size={25} color={theme.secondary} />
                </span>
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    let url = new URL(restaurant?.url);

                    history.push(url.pathname + "/my-account");
                  }}
                >
                  My Account
                </DropdownItem>
                <DropdownItem onClick={() => history.push("history")}>
                  Order History
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    DB?.removeData(DBKeys?.CUSTOMER_USER);
                    DB.removeData(DBKeys?.CUSTOMER_TOKEN);
                    setCoupon(null);
                    authContext.logout();
                  }}
                  className="text-danger"
                >
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        )}

        <span onClick={() => setOpenModal(true)} className="info_button_topnav">
          <span className="mx-3 hide_desktop">
            <AiOutlineInfoCircle size={25} color={theme.secondary} />
          </span>
        </span>
        <span
          style={{}}
          className="mx-3"
          onClick={() => {
            setIsOpenCart(true);
          }}
        >
          <FiShoppingCart size={22} color={theme.secondary} />
          <span
            style={{
              padding: "2px 6px",

              backgroundColor: theme.primary,
              color: "#fff",
              borderRadius: 16,
              fontSize: 12,
              position: "relative",
              bottom: 8,
              right: 6,
            }}
          >
            {sumQty}
          </span>
        </span>
      </div>
      <CartModal
        isOpen={isOpenCart}
        toggle={() => setIsOpenCart(!isOpenCart)}
        cartItems={cartItems}
        theme={theme}
        history={history}
        checkout={checkout}
        tip={tip}
      />
      <InfoModal toggleModal={toggleModal} openModal={openModal} />
      <LoginModal
        open={loginModal}
        toggle={() => setLoginModal(!loginModal)}
        theme={theme}
        history={history}
      />
      <MyAccount
        open={accountModal}
        toggle={() => setAccountModal(!accountModal)}
        theme={theme}
      />
    </div>
  );
};

export default TopNav;
