import { useEffect, useState } from "react"
import { Button } from "reactstrap"
import TimelineHeader from "../../../../components/Header/SimpleHeader"
import TableLoader from "../../../../components/TableLoader"
import { useEntity } from "../../../../hooks/useEntity"
import usePartnerId from "../../../../hooks/usePartnerId"
import SuperTable from "../../../../widgets/SuperTable"
import TableWrapper from "../../../../widgets/TableWrapper"
import TableRowCoupons from "../../../resturants/marketting/components/TableRowCoupons"

const Coupons = (props) => {
  const [sorting, setSorting] = useState(-1)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { find, entities, loading } = useEntity("coupon")
  const partnerId = usePartnerId()

  const Headers = [
    "Coupon",
    "Code",
    "Type",
    "Discount",
    "Date Start",
    "Date End",
    "Status",
    "Action",
  ]

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }
  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1)
    } else {
      setSorting(-1)
    }
  }

  useEffect(() => {
    find({
      partner: partnerId,
      level: "partner",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <TimelineHeader
        path="/partners"
        name="Partner Coupons"
        parentName="Dashboard"
        showBtn={true}
      >
        <Button
          onClick={() => {
            props.history.push({
              pathname: "/partners/marketing/add-coupon",
              search: props.location.search,
            })
          }}
          color="primary"
          size="md"
        >
          Add new
        </Button>
      </TimelineHeader>
      <TableWrapper>
        {loading ? (
          <TableLoader />
        ) : (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRowCoupons}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no coupons"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        )}
      </TableWrapper>
    </div>
  )
}

export default Coupons
