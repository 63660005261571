import { toast } from "react-toastify";
import { HOST } from "../config";
import DB from "../utils/DB";
import { DBKeys } from "../utils/Keys";
import logger from "../utils/logger";

export function useUploadImage() {
  async function upload({
    owner,
    file,
    height = 1080,
    width = 1280,
    method = "cover",
    resize = true,
  }) {
    let formdata = new FormData();
    formdata.append("owner", owner);
    formdata.append("file", file);
    formdata.append("height", height);
    formdata.append("width", width);
    formdata.append("method", method);
    formdata.append("resize", resize);

    let token = await DB.retrieveData(DBKeys.TOKEN);
    let authorizationHeader = {};
    const pathname = window.location.pathname;

    if (pathname.split("/")[1] === "ordering") {
      authorizationHeader["Authorization"] = `Bearer ${token}`;
    } else {
      authorizationHeader["token"] = token;
    }
    let requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        ...authorizationHeader,
      },
    };

    const isImage = file.type.split("/").includes("image");

    const isJson = file?.type?.split("/").includes("json");

    const requestUrl = `${HOST}${
      isImage ? "/upload" : isJson ? "/upload/json" : "/upload/xlsx"
    }`;

    try {
      const response = await fetch(requestUrl, requestOptions);
      const json = await response.json();
      return json.data;
    } catch (err) {
      logger.push({ err, info: "useImageUpload" });
      toast.error("Error uploading image.");
      throw new Error("Error uploading image");
    }
  }

  return {
    upload,
  };
}
