import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchemaCustomerUpdateProfile = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .min(4)
    .required("Required"),

  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required")
    .min(10, "cannot be less than 10")
    .max(10, "cannot be more than 10"),

  email: Yup.string()
    .trim()
    .email("Email must be valid email")
    .required("Required"),

  birthday: Yup.string().required("Birth date is required"),
  birthMonth: Yup.string().required("Birth Month is required"),
  anniversary: Yup.string().required("Anniversary is required"),
  anniversaryMonth: Yup.string().required("Anniversary Month is required"),
});
