/* eslint-disable array-callback-return */
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Spinner } from "reactstrap";
import { DATA_IMPORTER_URL, HOST } from "../../../../config";
import { useAdminStore } from "../../../../store/admin";
import DB from "../../../../utils/DB";
import { DBKeys } from "../../../../utils/Keys";

async function postData(url = "", data = {}, authorizationHeader) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const message = `An error has occurred: ${response.statusText}`;
    throw new Error(message);
  }

  return response.json();
}

const formatModifiers = (prices, parentModifierDoc) => {
  const finalMods = [];
  const parentMods = {
    name: "Select a variant",
    subProducts: prices?.map((price) => {
      return {
        name: price?.name,
        price: price?.price,
        plu: price?.id,
        id: price?.id,
      };
    }),
    advancedPizzaModifier: false,
    parentModifier: "",
    parentModifierId: "",
    priceIncludedInPrice: false,
    plu: "",
  };

  finalMods.push(parentMods);

  prices
    ?.map((price) => {
      return price?.modifiers?.map((modifier) => {
        const object = {
          name: modifier?.name,
          advancedPizzaModifier: modifier?.module_type === "pizza",
          parentModifier: "Select a variant",
          parentModifierId: parentModifierDoc?.id,
          subProducts: modifier?.options?.map((option) => {
            return {
              name:
                modifier?.module_type === "pizza" ? option.name : option?.side,
              selectedParentValue: {
                label: price?.name,
                parentModifier: "Select a variant",
                value: price?.id,
              },
              price:
                modifier?.module_type === "pizza"
                  ? Number(option.full)
                  : Number(option?.cost),
              plu: `${option?.id}-${price?.name}`,
              halfPrice:
                modifier?.module_type === "pizza"
                  ? Number(option?.half) || Number(option?.full) / 2
                  : 0,
              extraPrice:
                modifier?.module_type === "pizza"
                  ? Number(option?.extra) || Number(option?.full) * 2
                  : 0,
            };
          }),
          priceIncludedInPrice: false,
        };

        finalMods.push(object);
        return object;
      });
    })
    ?.filter((op) => op?.length);

  function mergeByName(array) {
    let mergedObjects = {};

    array.forEach((obj) => {
      if (!mergedObjects[obj.name]) {
        mergedObjects[obj.name] = { ...obj, subProducts: [...obj.subProducts] };
      } else {
        mergedObjects[obj.name].subProducts = [
          ...mergedObjects[obj.name].subProducts,
          ...obj.subProducts,
        ];
      }
    });

    return Object.values(mergedObjects);
  }

  let resultArray = mergeByName(finalMods);

  return resultArray;
};

const transformData = async (data) => {
  const modifierData = {
    name: "Select a variant",
    type: "select",
    min: "",
    max: "",
    subProducts: [],
    source: "local",
  };

  let authorizationHeader = {};

  const token = await DB.retrieveData(DBKeys.TOKEN);

  authorizationHeader["token"] = token;

  const url = `${HOST}/modifier-group/record`;

  const modifierDoc = await postData(url, modifierData, authorizationHeader);

  return data?.map((payload) => {
    return payload?.categories?.map((cat) => {
      return cat?.items?.map((item) => {
        return {
          name: item.name,
          description: item.description,
          price: 0.0,
          categoryName: cat.name,
          categoryDescription: cat.description,
          modifierGroups: formatModifiers(item?.prices, modifierDoc?.data),
          plu: "",
        };
      });
    });
  });
};

// price: item?.prices?.length > 1 ? 0.0 : item?.prices[0]?.price,

const MenuImport = () => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState(null);
  const { restaurant, partner } = useAdminStore();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setFileName(file.name);

    if (file && file.type === "application/json") {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        try {
          const parsedData = JSON.parse(e.target.result);
          setJsonData(parsedData);
          setError(null);
        } catch (parseError) {
          setError("Error parsing JSON: " + parseError.message);
          setJsonData(null);
        }
      };

      fileReader.onerror = () => {
        setError("Error reading file");
        setJsonData(null);
      };

      fileReader.readAsText(file, "UTF-8");
    } else {
      setError("Please select a valid JSON file.");
      setJsonData(null);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleImport = async () => {
    try {
      setLoading(true);
      const transform = await transformData(jsonData);

      const flattenData = transform?.map((d) => d?.flatMap((ma) => ma));

      const object = {
        menuData: jsonData,
        restaurant,
        partner,
        jsonData: flattenData.map((fla) => {
          return { items: fla };
        }),
      };

      let authorizationHeader = {};

      const token = await DB.retrieveData(DBKeys.TOKEN);

      authorizationHeader["token"] = token;

      const url = `${DATA_IMPORTER_URL}/import/menuocity/menu`;

      await postData(url, object, authorizationHeader);

      toast.success("Menu imported successfully");

      setLoading(false);

      setFile(null);
      setFileName("");
      setJsonData(null);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const styles = {
    fileInput: {
      position: "absolute",
      width: "1px",
      height: "1px",
      padding: 0,
      margin: "-1px",
      overflow: "hidden",
      clip: "rect(0,0,0,0)",
      border: 0,
    },
    uploadButton: {
      display: "inline-block",
      padding: "10px 20px",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    uploadButtonHover: {
      backgroundColor: "#0056b3",
    },
    file: {
      marginLeft: "10px",
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
    },
  };

  return (
    <div
      style={{
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "5px dashed primary",
      }}
    >
      <div>
        <div className="file-upload mt-3">
          <Input
            type="file"
            id="fileInput"
            style={styles.fileInput}
            onChange={handleFileChange}
            accept="application/JSON"
          />
          <div onClick={handleButtonClick}>
            <i
              style={{ fontSize: 100 }}
              className="fa fa-cloud-upload text-primary"
            />
            <div
              style={{ cursor: "pointer" }}
              className="text-xl text-muted cursor-pointer"
            >
              <u>Select</u> files to upload
            </div>
          </div>

          {fileName && (
            <div className="p-2 bg-primary rounded text-white mt-2">
              {" "}
              {file && <i className=" text-sm fa fa-file" />}
              <span style={styles.file}>{fileName}</span>
            </div>
          )}
        </div>
        <div className="mt-4">
          <>
            <Button disabled={loading || !jsonData} onClick={handleImport}>
              {loading ? (
                <>
                  <Spinner size={"sm"} />
                </>
              ) : (
                "Import"
              )}
            </Button>
            {loading && (
              <div>
                <small>
                  NOTE: Import in progress, Please do not close the tab or press
                  back!
                </small>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default MenuImport;
