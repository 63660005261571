import { useContext, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { RiMastercardLine, RiVisaLine } from "react-icons/ri";
import { Button, Spinner } from "reactstrap";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useTheme } from "../../../../store/theme";
import EmptyPlaceholder from "../../menu/components/EmptyPlaceholder";
import { AuthContext } from "../../../../context";
import apiCaller from "../../../../api/apiCaller";

const Table = ({
  cards,
  showRadio,
  setSelectedIndex,
  selectedIndex,
  onSuccess,

  formik,
}) => {
  const { theme } = useTheme();

  const { activeTab, gateway } = useOrdering();
  const { cartItems } = useCartStore();

  const [loading, setLoading] = useState(false);

  const authContext = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const token = cards?.data[selectedIndex];

    const invoiceNumber = await apiCaller("/bridgepay/invoice", {
      method: "GET",
    });

    const data = {
      payment: {
        token: token?.token?.token,
        expirationDate: token.expirationMonth + token.expirationYear,
      },
      accountHolder: {
        name: authContext?.user?.customer?.name,
        email: authContext?.user?.customer?.email,
        phone: authContext?.user?.customer?.phone,
      },
      merchantAccountCode: gateway?.merchantAccountCode,
      transactionType: "sale",
      invoiceNumber: invoiceNumber.data,
      transIndustryType: "RE",
      holderType: "P",
      accountType: "R",
      amount: formik?.values?.billing?.total,
      gateway: gateway?._id,
    };

    const transaction = await apiCaller("/bridgepay/transaction", {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (transaction?.transactionDoc?.providerResponseMessage === "Approved") {
      onSuccess({
        ...transaction,
        invoiceNumber: invoiceNumber.data,
      });
    }
  };

  return (
    <div>
      <div>
        <table style={{ width: "100%" }}>
          <tr
            style={{ color: theme?.primary, lineHeight: 2 }}
            className="payment_table_header"
          >
            <th className="text-left">Select</th>
            <th>Number</th>

            <th>Type</th>
          </tr>
          {(cards?.data?.length === 0 || !cards?.data) && (
            <tr>
              <td colSpan={4} className="text-center p-4">
                <EmptyPlaceholder />
              </td>
            </tr>
          )}
          {cards?.data?.map((card, i) => {
            return (
              <>
                <tr
                  style={{
                    textAlign: "left",
                    lineHeight: 3,
                    borderBottom:
                      !showRadio &&
                      i !== cards?.data?.length - 1 &&
                      "2px solid #ededed",
                  }}
                >
                  {showRadio && (
                    <td>
                      <input
                        onClick={() => {
                          setSelectedIndex(i);
                        }}
                        checked={i === selectedIndex}
                        type="radio"
                      />
                    </td>
                  )}
                  <td>{card.truncatedCardNumber}</td>
                  <td>
                    {card?.cardLogo === "Visa" ? (
                      <RiVisaLine size={35} />
                    ) : card?.cardLogo === "Mastercard" ? (
                      <RiMastercardLine size={35} />
                    ) : (
                      card.cardLogo
                    )}
                  </td>
                  {!showRadio && (
                    <td className="mx-6 text-center">
                      <FaTrash color={theme?.primary} />
                    </td>
                  )}
                </tr>
              </>
            );
          })}
        </table>
      </div>
      <Button
        onClick={handleSubmit}
        block
        disabled={
          loading ||
          formik?.values?.billing?.disableOrdering ||
          cartItems?.length <= 0 ||
          activeTab === "sign-in" ||
          activeTab === "register" ||
          formik.isSubmitting
        }
        className="mt-4"
        style={{
          backgroundColor: theme.primary,
          color: "white",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        }}
      >
        {loading ? <Spinner size={"sm"} /> : "Place Order"}
      </Button>
    </div>
  );
};

const CardsTableBridgePay = ({
  cards,
  setSelectedIndex,
  selectedIndex,
  showRadio,
  onSuccess,
  formik,
}) => {
  return (
    <>
      <Table
        cards={cards}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        showRadio={showRadio}
        onSuccess={onSuccess}
        formik={formik}
      />
    </>
  );
};

export default CardsTableBridgePay;
