import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "reactstrap";
import * as Yup from "yup";
import checkItemCount from "../../../../utils/checkItemCount";

const AddModifiersRow = ({
  prod,
  handleEditModifier,
  handleChange,
  modifiers,
  selectedGroup,
  advancedPizzaModifiers = false,
  ...props
}) => {
  console.log("Add Modifiers DOTA", prod);
  const [editModifier, setEditModifier] = useState(false);
  const [defaultDisbaled, setDefaultDisbaled] = useState(false);

  const editFormik = useFormik({
    initialValues: {
      price: 0,
      sort: 0,
      halfPrice: 0,
      extraPrice: 0,
      defaultSelected: false,
      allowExchange: false,
      init: false,
      disabled: [],
      isAdvanced: false,
      plu: "",
    },
    validationSchema: Yup.object({
      price: Yup.number()

        .max(999, "maximum 999")
        .required("Is required"),
      halfPrice: Yup.number()
        .max(999, "maximum 999")
        .when("isAdvanced", {
          is: true,
          then: Yup.number().required("Is required"),
          otherwise: Yup.number(),
        }),
      extraPrice: Yup.number()
        .max(999, "maximum 999")
        .when("isAdvanced", {
          is: true,
          then: Yup.number().required("Is required"),
          otherwise: Yup.number(),
        }),
      sort: Yup.number()
        .min(0, "cannot be less than 0")
        .max(99, "Cannot be grater than 99"),
      plu: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      handleEditModifier({
        ...prod,
        price: Number(values.price),
        sort: values.sort,
        halfPrice: values.halfPrice,
        extraPrice: values.extraPrice,
        defaultSelected: values.defaultSelected,
        plu: values.plu,
        allowExchange: values.allowExchange,
      });
      setEditModifier(false);
    },
  });

  useEffect(() => {
    if (prod) {
      editFormik.setValues({
        defaultSelected: prod.defaultSelected,
        price: editFormik.values.price,
        sort: editFormik.values.sort,
        plu: editFormik.values.plu,
        halfPrice: editFormik.values.halfPrice || 0,
        extraPrice: editFormik.values.extraPrice || 0,
        isAdvanced: advancedPizzaModifiers,
        allowExchange: prod.allowExchange,
      });
    }
  }, [prod]);

  useEffect(() => {
    setDefaultDisbaled(false);
    if (
      (modifiers[selectedGroup]?.type === "select" ||
        modifiers[selectedGroup]?.type === "radio") &&
      !modifiers[selectedGroup].enableParentModifier
    ) {
      let foundDefaultSelected = false;
      modifiers[selectedGroup]?.subProducts.forEach((subProduct) => {
        if (subProduct.defaultSelected) {
          foundDefaultSelected = true;
        }
      });
      if (foundDefaultSelected) {
        setDefaultDisbaled(true);
      } else {
        setDefaultDisbaled(false);
      }
    }

    if (
      modifiers[selectedGroup]?.type === "checkbox" &&
      !modifiers[selectedGroup].enableParentModifier
    ) {
      let foundDefaultSelected = 0;
      modifiers[selectedGroup]?.subProducts.forEach((subProduct) => {
        if (subProduct.defaultSelected) {
          foundDefaultSelected++;
        }
      });

      if (modifiers[selectedGroup].max === 0 || !modifiers[selectedGroup].max) {
        return;
      } else {
        if (foundDefaultSelected > modifiers[selectedGroup]?.max) {
          modifiers[selectedGroup]?.subProducts.forEach((subProduct) => {
            subProduct.defaultSelected = false;
          });
        }
        if (foundDefaultSelected === modifiers[selectedGroup]?.max) {
          setDefaultDisbaled(true);
        } else {
          setDefaultDisbaled(false);
        }
      }
    }
  }, [modifiers, selectedGroup, prod, editFormik]);

  const checkIfAddAllowed = (checked) => {
    if (
      (modifiers[selectedGroup].enableParentModifier &&
        modifiers[selectedGroup]?.max &&
        modifiers[selectedGroup]?.max !== 0) ||
      modifiers[selectedGroup]?.type === "select" ||
      modifiers[selectedGroup]?.type === "radio"
    ) {
      const count = checkItemCount(
        modifiers[selectedGroup]?.subProducts,
        prod?.selectedParentValue
      );

      if (count > modifiers[selectedGroup]?.max && checked) {
        toast.error(`You can select ${modifiers[selectedGroup]?.max || 1} max`);
        return false;
      }
    }
    return true;
  };

  return (
    <tr {...props} ref={props.refDoc}>
      <td style={{ textAlign: "left" }}>{prod?.jkSoftId || "NA"}</td>
      <td style={{ textAlign: "left" }}>
        <div
          className="text-left"
          style={{
            fontWeight: prod?.subModifiers?.length > 0 ? "bold" : "normal",
          }}
        >
          {prod?.product_name}
        </div>

        {prod?.subModifiers?.length > 0 && (
          <div className="text-left">
            <div className="text-left">
              {prod?.subModifiers?.length > 0 && (
                <small className="text-left">* Child Modifiers</small>
              )}
            </div>
            <div className="d-flex flex-column align-items-start">
              {prod?.subModifiers?.map((subModif, i) => (
                <Button
                  className="my-1 m-0"
                  size="sm"
                  color="secondary"
                  onClick={() => {}}
                >
                  <span>{`${subModif.name} - ($${subModif.price})`}</span>
                </Button>
              ))}
            </div>
          </div>
        )}
      </td>

      {modifiers[selectedGroup]?.enableParentModifier && (
        <td style={{ textAlign: "left" }}>
          {prod?.selectedParentValue?.map((p) => {
            return (
              <Button
                className="m-1"
                size="sm"
                style={{ background: "none" }}
                color="secondary"
                onClick={() => {
                  const idx = modifiers[selectedGroup]?.subProducts?.findIndex(
                    (md) =>
                      md.product_id === prod.product_id &&
                      md.selectedParentValue?.label === p?.label
                  );
                  modifiers[selectedGroup]?.subProducts?.splice(idx, 1);
                  handleChange(modifiers);
                }}
              >
                <span>{`${p.label}`}</span>
                <i className="fa fa-close text-red"></i>
              </Button>
            );
          })}
        </td>
      )}

      {editModifier ? (
        <td style={{ textAlign: "left" }}>
          <Input
            type="number"
            value={editFormik.values.price}
            onChange={editFormik.handleChange("price")}
            placeholder="Price"
            style={{ height: 38, minWidth: 200 }}
            className={`form-control ${
              editFormik.errors.price && editFormik.touched.price
                ? "is-invalid"
                : ""
            }`}
          />
          {editFormik.errors.price && editFormik.touched.price && (
            <span className="text-danger mt-3">{editFormik.errors.price}</span>
          )}
        </td>
      ) : (
        <td style={{ textAlign: "left" }}>${prod?.price}</td>
      )}
      {modifiers[selectedGroup].advancedPizzaOptions && (
        <>
          {" "}
          {editModifier ? (
            <td style={{ textAlign: "left" }}>
              <Input
                type="number"
                value={editFormik.values.halfPrice}
                onChange={editFormik.handleChange("halfPrice")}
                placeholder="Half price"
                style={{ height: 38, minWidth: 200 }}
                className={`form-control ${
                  editFormik.errors.halfPrice && editFormik.touched.halfPrice
                    ? "is-invalid"
                    : ""
                }`}
              />
              {editFormik.errors.halfPrice && editFormik.touched.halfPrice && (
                <span className="text-danger mt-3">
                  {editFormik.errors.halfPrice}
                </span>
              )}
            </td>
          ) : (
            <td>${prod?.halfPrice || 0}</td>
          )}
          {editModifier ? (
            <td style={{ textAlign: "left" }}>
              <Input
                type="number"
                value={editFormik.values.extraPrice}
                onChange={editFormik.handleChange("extraPrice")}
                placeholder="Extra Price"
                style={{ height: 38, minWidth: 200 }}
                className={`form-control ${
                  editFormik.errors.extraPrice && editFormik.touched.extraPrice
                    ? "is-invalid"
                    : ""
                }`}
              />
              {editFormik.errors.extraPrice &&
                editFormik.touched.extraPrice && (
                  <span className="text-danger mt-3">
                    {editFormik.errors.extraPrice}
                  </span>
                )}
            </td>
          ) : (
            <td style={{ textAlign: "left" }}>${prod?.extraPrice || 0}</td>
          )}
        </>
      )}

      {editModifier ? (
        <td style={{ textAlign: "left" }}>
          <Input
            type="text"
            value={editFormik.values.plu}
            onChange={editFormik.handleChange("plu")}
            placeholder="PLU"
            onKeyDown={(e) => {
              if (e.key === ".") {
                e.preventDefault();
              }
            }}
            style={{ height: 38 }}
            className={`form-control ${
              editFormik.errors.plu && editFormik.touched.plu
                ? "is-invalid"
                : ""
            }`}
          />
          {editFormik.errors.plu && editFormik.touched.plu && (
            <span className="text-danger mt-3">{editFormik.errors.plu}</span>
          )}
        </td>
      ) : (
        <td style={{ textAlign: "left" }}>{prod?.plu}</td>
      )}

      {editModifier ? (
        <td style={{ textAlign: "left" }}>
          <Input
            type="number"
            value={editFormik.values.sort}
            onChange={editFormik.handleChange("sort")}
            placeholder="Sort"
            onKeyDown={(e) => {
              if (e.key === ".") {
                e.preventDefault();
              }
            }}
            style={{ height: 38 }}
            className={`form-control ${
              editFormik.errors.sort && editFormik.touched.sort
                ? "is-invalid"
                : ""
            }`}
          />
          {editFormik.errors.sort && editFormik.touched.sort && (
            <span className="text-danger mt-3">{editFormik.errors.sort}</span>
          )}
        </td>
      ) : (
        <td style={{ textAlign: "left" }}>{prod?.sort || props?.i}</td>
      )}

      {editModifier ? (
        <td style={{ textAlign: "left" }}>
          <Button onClick={editFormik.handleSubmit}>Save</Button>
        </td>
      ) : (
        <td
          style={{ textAlign: "left" }}
          className="d-flex align-items-center justify-content-between"
        >
          {
            <div className="custom-control custom-checkbox">
              <input
                onChange={(e) => {
                  if (typeof editFormik.values.defaultSelected === "array") {
                    editFormik.setFieldValue("defaultSelected", false);
                  }

                  try {
                    if (!checkIfAddAllowed(e.target.checked)) {
                      return;
                    }
                  } catch (error) {
                    window.alert(JSON.stringify(error.message));
                  }

                  editFormik.setFieldValue("defaultSelected", e.target.checked);
                }}
                onClick={() => {
                  editFormik.setValues({
                    price: Number(prod.price),
                    sort: prod.sort,
                    halfPrice: prod.halfPrice,
                    extraPrice: prod.extraPrice,
                    defaultSelected: editFormik.values.defaultSelected,
                  });
                  editFormik.handleSubmit();
                }}
                className="custom-control-input"
                id={`customCheck${prod.product_id}${
                  modifiers[selectedGroup]?.enableParentModifier
                    ? prod?.selectedParentValue?.[0]?.label
                    : ""
                }`}
                type="checkbox"
                checked={editFormik.values?.defaultSelected}
                disabled={
                  !editFormik.values?.defaultSelected && defaultDisbaled
                }
              />
              <label
                className="custom-control-label"
                htmlFor={`customCheck${prod.product_id}${
                  modifiers[selectedGroup].enableParentModifier
                    ? prod?.selectedParentValue?.[0]?.label
                    : ""
                }`}
              >
                Included
              </label>
            </div>
          }
          {editFormik.values.defaultSelected &&
            modifiers[selectedGroup]?.type === "checkbox" && (
              <div className="custom-control custom-checkbox mx-2">
                <input
                  onChange={(e) => {
                    if (typeof editFormik.values.allowExchange === "array") {
                      editFormik.setValues({
                        price: Number(prod.price),
                        sort: prod.sort,
                        halfPrice: prod.halfPrice,
                        extraPrice: prod.extraPrice,
                        defaultSelected: prod.defaultSelected,
                        allowExchange: false,
                      });
                      editFormik.handleSubmit();
                    }
                    editFormik.setValues({
                      price: Number(prod.price),
                      sort: prod.sort,
                      halfPrice: prod.halfPrice,
                      extraPrice: prod.extraPrice,
                      defaultSelected: prod.defaultSelected,
                      allowExchange: e.target.checked ? true : false,
                    });

                    editFormik.handleSubmit();
                  }}
                  className="custom-control-input"
                  id={`customCheck${prod.product_id}exchange${
                    modifiers[selectedGroup]?.enableParentModifier
                      ? prod?.selectedParentValue?.[0]?.label
                      : ""
                  }`}
                  type="checkbox"
                  checked={editFormik.values?.allowExchange}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customCheck${prod.product_id}exchange${
                    modifiers[selectedGroup]?.enableParentModifier
                      ? prod?.selectedParentValue?.[0]?.label
                      : ""
                  }`}
                >
                  Allow Exchange
                </label>
              </div>
            )}
          <Button
            color="danger"
            onClick={() => {
              const subProd = modifiers[selectedGroup]?.subProducts;
              console.log("subProd before", subProd);
              prod?.selectedParentValue?.map((parentValue) => {
                subProd.splice(parentValue.i, 1, null);
                console.log("subProd after", subProd);
              });

              modifiers[selectedGroup].subProducts = subProd.filter(
                (it) => !!it
              );

              handleChange({ ...modifiers });
            }}
          >
            {" "}
            <i className="fa fa-trash" />
          </Button>
          <Button
            color="info"
            onClick={() => {
              editFormik.setValues({
                price: prod.price,
                sort: prod.sort,
                halfPrice: prod.halfPrice,
                extraPrice: prod.extraPrice,
                defaultSelected: prod.defaultSelected,
                plu: prod.plu,
              });
              setEditModifier(true);
            }}
          >
            <i className="fa fa-pencil-square-o" />
          </Button>
        </td>
      )}
    </tr>
  );
};

export default AddModifiersRow;
