import { useContext, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { RiMastercardLine, RiVisaLine } from "react-icons/ri";
import { Button, Spinner } from "reactstrap";
import apiCaller from "../../../../api/apiCaller";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useTheme } from "../../../../store/theme";
import EmptyPlaceholder from "../../menu/components/EmptyPlaceholder";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { AuthContext } from "../../../../context";
import { toast } from "react-toastify";

const Table = ({
  cards,
  showRadio,
  setSelectedIndex,
  selectedIndex,
  onSuccess,

  formik,
}) => {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);

  const {
    activeTab,
    gateway,
    location: orderingLocation,
    deliveryZone,
    method,
    menuId,
    deliveryMethod,
    deliveryConfirmAddress,
    cateringData,
  } = useOrdering();
  const { cartItems, coupon, moreInfoResponse } = useCartStore();
  const { restaurant } = useOrderingRestaurant();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (moreInfoResponse?.[0]?.length > 0) {
      toast.error(moreInfoResponse?.[0]);
      return;
    }

    setLoading(true);

    try {
      const token = cards?.data[selectedIndex];

      const invoiceNumber = await apiCaller("/authnet/ref-sequence", {
        method: "GET",
      });

      const payload = {
        cardId: token?._id,
        gatewayId: gateway?._id,
        total: formik?.values?.billing?.total?.toFixed(2),
        refId: invoiceNumber?.data,
        billingPayload: {
          data: [...cartItems],
          tip: formik.values?.tip,
          coupon: coupon?._id || formik.values?.coupon?._id,
          points: formik.values?.loyaltyPoints,
          location: orderingLocation?._id,
          restaurant: restaurant?._id,
          method: method,
          customer: authContext?.user?.customer?._id,
          address: deliveryConfirmAddress,
          deliveryZoneId: deliveryZone?._id,
          deliveryMethod,
          catering: cateringData?.catering,
          menuId,
        },
      };

      const processPayment = await apiCaller(
        "/eat/partner-app/ordering/payment/authnet/save-card",
        {
          method: "POST",
          body: payload,
        }
      );

      onSuccess({
        transactionDoc: processPayment,
      });

      setLoading(false);

      console.log(processPayment);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div>
        <table style={{ width: "100%" }}>
          <tr
            style={{ color: theme?.primary, lineHeight: 2 }}
            className="payment_table_header"
          >
            <th className="text-left">Select</th>
            <th>Number</th>

            <th>Type</th>
          </tr>
          {(cards?.data?.length === 0 || !cards?.data) && (
            <tr>
              <td colSpan={4} className="text-center p-4">
                <EmptyPlaceholder />
              </td>
            </tr>
          )}
          {cards?.data?.map((card, i) => {
            return (
              <>
                <tr
                  style={{
                    textAlign: "left",
                    lineHeight: 3,
                    borderBottom:
                      !showRadio &&
                      i !== cards?.data?.length - 1 &&
                      "2px solid #ededed",
                  }}
                >
                  {showRadio && (
                    <td>
                      <input
                        onClick={() => {
                          setSelectedIndex(i);
                        }}
                        checked={i === selectedIndex}
                        type="radio"
                      />
                    </td>
                  )}
                  <td>{card.truncatedCardNumber}</td>
                  <td>
                    {card?.cardLogo === "Visa" ? (
                      <RiVisaLine size={35} />
                    ) : card?.cardLogo === "Mastercard" ? (
                      <RiMastercardLine size={35} />
                    ) : (
                      card.cardLogo
                    )}
                  </td>
                  {!showRadio && (
                    <td className="mx-6 text-center">
                      <FaTrash color={theme?.primary} />
                    </td>
                  )}
                </tr>
              </>
            );
          })}
        </table>
      </div>
      <Button
        onClick={handleSubmit}
        block
        disabled={
          loading ||
          formik?.values?.billing?.disableOrdering ||
          cartItems?.length <= 0 ||
          activeTab === "sign-in" ||
          activeTab === "register" ||
          formik.isSubmitting
        }
        className="mt-4"
        style={{
          backgroundColor: theme.primary,
          color: "white",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
        }}
      >
        {loading ? <Spinner size={"sm"} /> : "Place Order"}
      </Button>
    </div>
  );
};

const CardsTableAuthnet = ({
  cards,
  setSelectedIndex,
  selectedIndex,
  showRadio,
  onSuccess,
  formik,
}) => {
  return (
    <>
      <Table
        cards={cards}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        showRadio={showRadio}
        onSuccess={onSuccess}
        formik={formik}
      />
    </>
  );
};

export default CardsTableAuthnet;
