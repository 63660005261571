import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "reactstrap";
import SimpleHeader from "../../components/Header/SimpleHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useAdminStore } from "../../store/admin";
import { exportAsCSV } from "../../utils/expoAsCSV";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import ReportsHeader from "../resturants/reports/components/ReportsHeader";
import { TableRowOrders } from "./components/TableRowOrders";

function Orders(props) {
  const authContext = useContext(AuthContext);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const authPartner = authContext?.user?._id;
  const { partner } = useAdminStore();
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Client", "Today", "Week", "Month", "Year"];

  const { entities, find, loading } = useEntity("order-report");

  useEffect(() => {
    find({
      partner: partner || authPartner,
      limit: rowsPerPage,
      page: page,
      startDate: startDate || firstDay,
      endDate: endDate || lastDay,
    });
  }, [rowsPerPage, page, startDate, endDate]);

  const transformedData = useMemo(() => {
    let arr = [];
    entities?.data?.data?.map((data, idx) => {
      arr.push({
        ID: idx,
        "Restaurant Name": data?.restaurantId?.username,
        today: data?.today,
        week: data?.week,
        month: data?.month,
        year: data?.year,
      });
    });

    return arr;
  }, [entities?.data?.data]);

  const handleSelectDates = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  return (
    <>
      <SimpleHeader name="Total Orders" parentName="Dashboard" showBtn={true}>
        <Button
          onClick={() =>
            exportAsCSV({
              arrayOfObject: transformedData,
              fileName: "orders.csv",
            })
          }
          className="btn-primary"
          color="primary"
          size="md"
        >
          CSV
        </Button>
      </SimpleHeader>

      <TableWrapper>
        {" "}
        <ReportsHeader
          endDate={endDate || lastDay}
          startDate={startDate || firstDay}
          handleSelectDates={handleSelectDates}
          locationField={false}
        />
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRowOrders}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no Items"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}

export default Orders;
