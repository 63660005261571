import { useContext } from "react";
import { AuthContext } from "../context";

const usePartnerId = () => {
  const context = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.search);

  const partnerId =
    context?.user?.role === "partner"
      ? context?.user?._id
      : urlParams.get("partnerId");
  return partnerId;
};

export default usePartnerId;
