/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { GoGift } from "react-icons/go";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useCartStore } from "../../../../store";
import useOrdering from "../../../../store/ordering";
import { useTheme } from "../../../../store/theme";
import logger from "../../../../utils/logger";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";

const Coupon = ({ setCoupons, subtotal, discount, getCouponDetails }) => {
  const { theme } = useTheme();

  const { create } = useEntity("eat/ordering/coupon");

  const { cartItems, setCoupon, coupon } = useCartStore();

  const { location, method } = useOrdering();

  const { restaurant } = useOrderingRestaurant();

  const authContext = useContext(AuthContext);

  const { handleSubmit, handleChange, values, isSubmitting, setFieldValue } =
    useFormik({
      initialValues: {
        coupon: "",
      },
      onSubmit: async (values, { resetForm }) => {
        try {
          const res = await create({
            coupon: values?.coupon,
            location: location?._id,
            items: cartItems,
            method,
            customer: authContext.user ? authContext.user.customer._id : null,
            partner: restaurant?.partner?._id,
          });
          if (res?.data?.subTotal <= subtotal) {
            setCoupons(res?.data);
            //zustand state
            setCoupon(res?.data);

            getCouponDetails(res?.data);
            toast.success("Coupon applied");
          } else {
            toast.error("Please check your subtotal");
          }
        } catch (error) {
          logger.push({ error, info: "CouponError" });
          toast.error(error.message);
        }
      },
    });

  console.log(discount, "DISC");

  useEffect(() => {
    if (cartItems?.length <= 0) {
      setCoupons("");
      setCoupon("");
      setFieldValue("coupon", "");
    }
  }, [cartItems]);

  return (
    <div
      className="px-4 py-4 single_card_checkout"
      style={{
        background: " #FFFFFF",
        boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
        borderRadius: 6,
      }}
    >
      <h1
        style={{
          fontWeight: "600",
          color: theme.primary,
        }}
      >
        Coupon Code
      </h1>
      <span className="text-muted">Have a coupon code?</span>
      <div className="d-flex ">
        <div
          className="mt-3 px-2 py-3 d-flex justify-content-between align-items-center"
          style={{ background: "#ededed", flex: 1 }}
        >
          <input
            onChange={handleChange("coupon")}
            value={values?.coupon || coupon?.couponName || ""}
            style={{
              border: "none",
              background: "transparent",
              outline: "none",
              width: "100%",
            }}
            type="text"
            placeholder="Enter code"
          />
          <span className="mx-2">
            <GoGift />
          </span>
        </div>
        <div
          className="ml-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {discount ? (
            <Button
              className="mt-3 "
              style={{
                backgroundColor: "red",
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.1em",
              }}
              onClick={() => {
                setCoupons("");
                setCoupon("");
                setFieldValue("coupon", "");
              }}
            >
              Remove
            </Button>
          ) : (
            <Button
              className="mt-3 "
              style={{
                backgroundColor: theme?.primary,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.1em",
              }}
              onClick={handleSubmit}
              disabled={cartItems?.length <= 0 || !values?.coupon}
            >
              {isSubmitting ? <Spinner size="sm" color="#fff" /> : "Apply"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Coupon;
