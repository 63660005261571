import React from "react";
import { Col } from "reactstrap";

export default function LocationRowDetails({ checked, theme }) {
  return (
    <Col md={12} className="p-3 mt-2" style={{ border: "1px solid #000" }}>
      <h3>Benny&Co - Ange-Gardien (Ville)</h3>
      <span>109 quebec 235 Lorem ipsum dolor lorem</span>
      {checked && (
        <i
          className="fa fa-check-circle"
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            color: theme.primary,
            fontSize: 24,
          }}
        ></i>
      )}
    </Col>
  );
}
