import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
  Table,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { getSeparatedValues } from "../../../utils/commaSeparator";
import TableWrapper from "../../../widgets/TableWrapper";
import AddFormModal from "./components/AddFormModal";
import { validationSchemaAddMoreInfo } from "../../../utils/schemas/AddMoreInfo";
import { toast } from "react-toastify";

const AddMoreInfo = (props) => {
  const authContext = useContext(AuthContext);
  const { restaurant } = useAdminStore();
  const [fieldType, setFieldType] = useState("");

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  const { find: findLoc, entities: location } = useEntity("location");

  const { create } = useEntity("more-info");
  const { updateEntity } = useEntity("more-info");
  const { findOne: findOne, entity: singleInfo } = useEntity("more-info");

  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    findLoc({
      resturant: authRest || restaurant,
    });
    if (props.location.state) {
      findOne(props.location.state);
    }
  }, [props.location.state]);

  const [modalOpen, setmodalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      restaurant: authRest || restaurant,
      subInfo: [],
      location: [],
    },
    validationSchema: validationSchemaAddMoreInfo,
    onSubmit: async (values) => {
      const data = {
        title: values?.title,
        restaurant: authRest || restaurant,
        subInfo: values.subInfo,
        location: values?.location?.map((it) => {
          return it?.value;
        }),
      };

      if (data?.location?.length <= 0) {
        toast.error("Please select at least one location");
        return;
      }

      if (data?.subInfo?.length <= 0) {
        toast.error("Please add at least one info field");
        return;
      }

      try {
        if (!props.location.state) {
          await create({ ...data });
          toast.success("More info created successfully");
          resetForm();
        } else {
          await updateEntity(props.location.state, { ...data });
          toast.success("More info updated successfully");

          resetForm();
        }
        props.history.goBack();
      } catch (error) {}
    },
  });

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    setValues,
    resetForm,
  } = formik;

  useEffect(() => {
    if (singleInfo) {
      setValues({
        restaurant: authRest || restaurant,
        title: singleInfo?.data?.title,
        subInfo: singleInfo?.data?.subInfo,
        location: singleInfo?.data?.location.map((op) => {
          return { label: op.name, value: op._id };
        }),
      });
    }
  }, [singleInfo]);

  const handleSubInfo = (info) => {
    const arr = values?.subInfo;
    arr.push(info);
    setFieldValue("subInfo", arr);
  };

  return (
    <>
      <TimelineHeader
        name="Add More Info"
        parentName="Info"
        showBtn={true}
        path="/resturants/more-info"
      >
        <Button color="primary" onClick={handleSubmit}>
          {" "}
          {isSubmitting ? <Spinner size="sm" /> : "Save Changes"}
        </Button>
        <Button
          className="btn-neutral text-black"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back
        </Button>
      </TimelineHeader>
      <TableWrapper customStyles="py-2 pt-3 px-2">
        <Col>
          <Form>
            <FormGroup className="text-left">
              <Label
                className="form-control-label ml-3"
                htmlFor="example-text-input"
              >
                Title
              </Label>
              <Col>
                <Input
                  onChange={handleChange("title")}
                  value={values.title}
                  type="text"
                  className={`form-control ${
                    errors.title && touched.title ? "is-invalid" : ""
                  }`}
                />
                {errors.title && touched.title && (
                  <small className="text-red">{errors.title}</small>
                )}
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col>
          <Form>
            <FormGroup className="col text-left">
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
              >
                Location
              </Label>

              <Select
                isMulti
                name="location"
                isSearchable={false}
                onChange={(cat) => {
                  setFieldValue("location", cat);
                }}
                value={values.location}
                options={
                  location?.data?.data?.length > 0
                    ? [
                        { name: "All", _id: "all" },
                        ...location?.data?.data,
                      ].map((it) => {
                        return {
                          label: it?.name,
                          value: it?._id,
                        };
                      })
                    : []
                }
              />
            </FormGroup>
          </Form>
        </Col>
        <Col>
          <Form>
            <FormGroup className="col text-left">
              <Label
                className="form-control-label"
                htmlFor="example-text-input"
              >
                Form
              </Label>

              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    setFieldType("text");
                    setmodalOpen(true);
                  }}
                >
                  + Add Text Field
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setFieldType("select");
                    setActiveItem(undefined);
                    setmodalOpen(true);
                  }}
                >
                  + Add Select Field
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setFieldType("checkbox");
                    setActiveItem(undefined);
                    setmodalOpen(true);
                  }}
                >
                  + Add Checkbox Field
                </Button>
              </div>
            </FormGroup>
          </Form>
        </Col>
        <Table className="align-items-center" responsive>
          <thead className="thead-light">
            <tr className="text-left">
              <th>Label</th>
              <th>Type</th>
              <th>Options</th>
              <th>Required</th>
              <th>Actions</th>
            </tr>
          </thead>

          {values.subInfo?.map((info, index) => {
            return (
              <tr className="text-left">
                <td className="bold">{info.label}</td>
                <td className="bold">{info.type}</td>
                <td className="bold">{getSeparatedValues(info?.options)}</td>
                <td className="bold">
                  {info.required ? "Required" : "Not required"}
                </td>
                <td className="bold">
                  <i
                    className="fa fa-trash text-danger mx-2"
                    style={{ fontSize: 20, cursor: "pointer" }}
                    onClick={async () => {
                      const arr = values.subInfo;
                      arr.splice(index, 1);
                      setFieldValue("subInfo", [...arr]);
                    }}
                  />
                  <i
                    className="fa fa-edit mx-2"
                    style={{ fontSize: 18, cursor: "pointer" }}
                    onClick={async () => {
                      setmodalOpen(true);
                      setActiveItem(info);
                      setFieldType(info.type);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      </TableWrapper>
      <AddFormModal
        inputType={fieldType}
        toggle={() => setmodalOpen(false)}
        open={modalOpen}
        handleSubInfo={(val) => handleSubInfo(val)}
        activeItem={activeItem}
        subInfo={values.subInfo}
      />
    </>
  );
};

export default AddMoreInfo;
