import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { useEntity } from "../../../../hooks/useEntity";
import GeneralForm from "./GeneralForm";
import HistoryForm from "./HistoryForm";
import IPAddressTable from "./IPAddressTable";
import RewardPoints from "./RewardPoints";
import Transactions from "./Transactions";
import { useAdminStore } from "../../../../store/admin";
import { useFormik } from "formik";
import swal from "sweetalert";
import TimelineHeader from "../../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../../context";

export default function EditCustomer(props) {
  const location = useLocation();
  const [tabs, setTabs] = useState(1);
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const [addresses, setAddresses] = useState();

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const { entity, findOne } = useEntity("customer");
  const { updateEntity } = useEntity("customer");

  useEffect(() => {
    if (location?.state?.userId) {
      findOne(location.state.userId);
    }
  }, [location.state.userId]);

  const {
    handleChange,
    values,
    isSubmitting,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      telephone: "",
      fax: "",
      newsLetter: false,
      approved: false,
      safe: false,
      address: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.name,
        email: values.email,
        status: values?.status,
        customerGroup: values?.customerGroup,
        phone: values.telephone,
        fax: values.fax,
        safe: values.safe,
        newsLetter: values.newsLetter,
        approved: values.approved,
        restaurant: authRest || restaurant,
        role: "customer",
        address: addresses,
      };

      try {
        await updateEntity(location.state.id, { ...data });
        swal("Success", "Customer Created!", "success");
        resetForm();
      } catch (error) {
        swal("Error", error.message, "error");
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        name: entity?.data?.customer?.name,
        email: entity?.data?.customer?.email,
        status: entity?.data?.customer?.status,
        customerGroup: entity?.data?.customer?.customerGroup,
        phone: entity?.data?.customer?.phone,
        fax: entity?.data?.customer?.fax,
        safe: entity?.data?.customer?.safe,
        newsLetter: entity?.data?.customer?.newsLetter,
        approved: entity?.data?.customer?.approved,
      });
    }
    setFieldValue("address", entity?.data?.customer?.address);
  }, [entity]);

  useEffect(() => {
    setFieldValue("address", addresses);
  }, [addresses]);

  return (
    <div style={{ zIndex: 999, position: "relative" }}>
      <TimelineHeader name="Edit Customer" parentName="Item" showBtn={true}>
        <Button
          onClick={() => {
            props.history.goBack();
          }}
          className="btn-neutral"
          color="default"
          size="md"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          color="primary"
          size="md"
        >
          {isSubmitting ? <Spinner size="sm" /> : "Save"}
        </Button>
      </TimelineHeader>

      <Container fluid className="mt--6">
        <Card className="px-4 py-4">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 1,
                })}
                onClick={(e) => toggleNavs(e, 1)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-book mr-2" />
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 2,
                })}
                onClick={(e) => toggleNavs(e, 2)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-print mr-2" />
                History
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 3,
                })}
                onClick={(e) => toggleNavs(e, 3)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-envelope mr-2" /> {/* <General /> */}
                Transactions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 4}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 4,
                })}
                onClick={(e) => toggleNavs(e, 4)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-envelope mr-2" /> {/* <General /> */}
                Reward Points
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 5}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 5,
                })}
                onClick={(e) => toggleNavs(e, 5)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-envelope mr-2" /> {/* <General /> */}
                IP Address
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent className="mt-5" activeTab={"tabs" + tabs}>
            <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
              <GeneralForm
                handleChange={handleChange}
                values={values}
                address={(val) => setAddresses(val)}
                addressValues={values.address}
              />
            </TabPane>
            <TabPane tabId="tabs2">
              <HistoryForm />
            </TabPane>
            <TabPane tabId="tabs3">
              <Transactions />
            </TabPane>
            <TabPane tabId="tabs4" style={{ textAlign: "left" }}>
              <RewardPoints />
            </TabPane>
            <TabPane tabId="tabs5">
              <IPAddressTable />
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  );
}
