import { Checkbox } from "pretty-checkbox-react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import CustomCheckbox from "../../../components/Inputs/CustomCheckbox";
import { useTheme } from "../../../store/theme";

const ModifierQty = ({
  max,
  getModifierQty,
  isSelected,
  enableQtyUpdate,
  subProd,
  handleAddOptions,
  handleRemoveOption,
  isNestedModifier,
  index,
  modifierGroupId,
  selectedOptions,
  name,
  sortOrder = 0,
}) => {
  const [modifierQty, setModifierQty] = useState(1);

  const [finalPrice, setFinalPrice] = useState(subProd?.price);

  const { theme } = useTheme();

  const { product_name } = subProd;

  useEffect(() => {
    getModifierQty(modifierQty);
  }, [modifierQty]);

  useEffect(() => {
    let qty = modifierQty;
    if (subProd?.defaultSelected) qty = qty - 1;
    const price = parseFloat(subProd?.price * qty);
    setFinalPrice(price);
  }, [modifierQty, subProd]);

  return (
    <>
      <div key={index} className="">
        <div
          className="d-flex align-items-center"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              zIndex: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              marginTop: 3,
            }}
          >
            <CustomCheckbox
              className="cb1"
              key={product_name}
              type={"checkbox"}
              id={product_name}
              name={product_name}
              value={JSON.stringify(subProd)}
              onChange={(e) => {
                if (e.target.checked) {
                  if (subProd?.subModifiers?.length > 0) {
                    subProd["selectedModifier"] = {
                      label: subProd?.subModifiers[0]?.name,
                      value: subProd?.subModifiers[0]?.price,
                    };
                    subProd["price"] = Number(subProd?.subModifiers[0]?.price);
                  }

                  const allSelected = selectedOptions.filter(
                    (sel) => sel.name === name
                  );

                  const allQty = allSelected.reduce(
                    (prev, cur) => prev + (cur.qty || 1),
                    0
                  );
                  if (Number(max) !== 0 && allQty >= Number(max) && max) {
                    toast.error(`You can select ${max} maximum.`);
                    return;
                  }
                  subProd["parent"] = name;
                  subProd["name"] = name;

                  if (subProd?.defaultSelected) {
                    handleAddOptions(
                      { ...subProd, price: 0, sort: Number(sortOrder || 0) },
                      1
                    );
                  } else {
                    handleAddOptions(
                      { ...subProd, sort: Number(sortOrder || 0) },
                      1
                    );
                  }
                } else {
                  setModifierQty(1);
                  handleRemoveOption(subProd);
                }
              }}
              checked={isSelected(subProd)}
              style={{
                marginBottom: 10,
                fontSize: 15,
                fontWeight: "400",
              }}
            />
            <label
              className="my-0"
              style={{ fontSize: 16, marginLeft: 5, flex: 1 }}
              htmlFor={product_name}
            >
              {product_name}
            </label>
          </div>

          {!isNestedModifier && (
            <div style={{ fontSize: 16 }}>
              {finalPrice === 0 ? "" : `$${finalPrice.toFixed(2)}`}
            </div>
          )}
        </div>
      </div>
      {isSelected(subProd) && enableQtyUpdate && (
        <div className="mt-1 mb-3">
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              className="bold py-0"
              style={{
                backgroundColor: theme.primary,
                color: "white",
                fontSize: 17,
              }}
              onClick={() => {
                if (modifierQty > 1) {
                  setModifierQty(modifierQty - 1);
                } else {
                  handleRemoveOption(subProd);
                }
              }}
            >
              -
            </Button>
            <Button
              size="sm"
              className="bold py-0"
              style={{
                color: "black",
                fontSize: 17,
                boxShadow: "none",
                margin: 0,
                padding: 0,
                transform: "none",
              }}
            >
              {modifierQty}
            </Button>
            <Button
              size="sm"
              className="bold py-0"
              style={{
                backgroundColor: theme.primary,
                color: "white",
                fontSize: 17,
              }}
              onClick={() => {
                const allSelected = selectedOptions.filter(
                  (sel) => sel.name === name
                );

                const allQty = allSelected.reduce(
                  (prev, cur) => prev + (cur.qty || 1),
                  0
                );
                if (Number(max) > 0 && allQty >= Number(max) && max) {
                  toast.error(`You can select ${max} maximum.`);
                  return;
                } else {
                  if (modifierQty < max || Number(max) === 0) {
                    setModifierQty(modifierQty + 1);
                  }
                }
              }}
            >
              +
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModifierQty;
