import { Modal } from "reactstrap";
import MenuBannerDetails from "../../screens/ordering/menu/components/MenuBannerDetails";

const InfoModal = ({ openModal, toggleModal }) => {
  return (
    <div>
      <Modal
        className="modal-dialog-top"
        isOpen={openModal}
        toggle={() => toggleModal("exampleModal")}
      >
        <div className="modal-body">
          <span style={{ position: "absolute", right: 20 }}>
            <i
              onClick={() => toggleModal("exampleModal")}
              className="fa fa-close"
              style={{ padding: 10 }}
            />
          </span>
          <MenuBannerDetails popover={true} />
        </div>
      </Modal>
    </div>
  );
};

export default InfoModal;
