import { toast } from "react-toastify";
import { Media, Spinner } from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import { useRestaurantStore } from "../../../../store/restaurant";
import { useLocation } from "react-router-dom";
import { getSeparatedValues } from "../../../../utils/commaSeparator";

export const CategoryRow = ({ history, data, ...props }) => {
  const { updateEntity, loading: updateLoading } = useEntity("category");
  const { deleteEntity } = useEntity("category");
  const location = useLocation();
  const { restaurant } = useRestaurantStore();
  return (
    <tr className="text-left" {...props} ref={props.refDoc}>
      <th
        className="bold"
        style={{ cursor: "pointer" }}
        onClick={() =>
          history.push({
            pathname: "/resturants/add-category",
            state: data?._id,
            search: location.search,
          })
        }
      >
        <Media style={{ cursor: "pointer", textTransform: "capitalize" }}>
          <span className="name mb-0 text-sm ">{data?.categoryName}</span>
        </Media>
      </th>
      <td>
        {updateLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.status === "enabled"}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: data?.status === "enabled" ? "disabled" : "enabled",
                  });
                  toast("Category updated successfully");
                } catch (error) {
                  toast(error.message);
                }
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Off"
              data-label-on="On"
            />
          </label>
        )}
      </td>
      <td className="text-left">
        {!data?.allLocationsSelected ? (
          <>
            {getSeparatedValues(
              data?.location?.slice(0, 3)?.map((item) => item?.name)
            )}
            {data?.location.length > 3 && "..."}
          </>
        ) : (
          <span className="name mb-0 text-sm ">All</span>
        )}
      </td>
      <td className="text-left">
        {data?.sortOrder === null ? "- " : data?.sortOrder}
      </td>
      <td className="text-left">
        {data?.tag?.length === 0 && "Not selected"}
        {getSeparatedValues(data?.tag?.slice(0, 3)?.map((item) => item?.name))}
        {data?.tag.length > 3 && "..."}
      </td>

      {!restaurant?.restaurant?.enable_deliverect && (
        <td className="text-left">
          <i
            className="fa fa-trash text-danger mx-2"
            style={{ fontSize: 20, cursor: "pointer" }}
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id);
                    toast("Category Deleted Succesfully");
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
          />
        </td>
      )}
    </tr>
  );
};
