import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";
import AddTagModal from "./AddTagModal";

const TableRowTags = ({ data }) => {
  const [openTagModal, setOpenTagModal] = useState(false);
  const { deleteEntity } = useEntity("tag");
  return (
    <>
      <tr className="text-left">
        <th>{data.name}</th>
        <td>{moment(data.createdAt).format("MM/DD/YYYY")}</td>
        <td>
          <i
            className="fa fa-edit text-lg pointer"
            onClick={() => {
              setOpenTagModal(true);
            }}
          />
          <i
            className="fa fa-trash text-danger ml-2 text-lg pointer"
            onClick={async () => {
              try {
                swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then(async (willDelete) => {
                  if (willDelete) {
                    await deleteEntity(data?._id);
                    toast.error("Tag deleted succesfully");
                  }
                });
              } catch (error) {
                toast(error.message);
              }
            }}
          />
        </td>
      </tr>
      <AddTagModal
        id={data?._id}
        toggle={() => setOpenTagModal(false)}
        open={openTagModal}
      />
    </>
  );
};

export default TableRowTags;
