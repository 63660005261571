export const ErrorText = ({ isValid, message }) => {
  return (
    !isValid && (
      <span
        className="text-red"
        style={{ fontSize: 10, position: "relative", bottom: 10, left: 10 }}
      >
        {message}
      </span>
    )
  );
};
