/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, FormGroup, Input, Table } from "reactstrap";

const CateringPrepTimes = ({ values, setFieldValue }) => {
  const [formData, setFormData] = useState([]);
  const [newItem, setNewItem] = useState({
    minHeadCount: "",
    maxHeadCount: "",
    prepTimeValue: "",
    prepTimeUnit: "",
  });

  const [edit, setEdit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const filteredValue = value.replace(/[^0-9]/g, "");
    setNewItem({
      ...newItem,
      [name]: filteredValue,
    });
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;

    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const handleAdd = () => {
    if (
      newItem.minHeadCount &&
      newItem.prepTimeValue &&
      newItem.maxHeadCount &&
      newItem.prepTimeUnit
    ) {
      setFormData([...formData, newItem]);

      setNewItem({
        minHeadCount: "",
        maxHeadCount: "",
        prepTimeValue: "",
        prepTimeUnit: "",
      });
    }
  };

  const handleEdit = (value, index, field) => {
    const items = [...formData];
    console.log(items, value, field, "AMDSMD");
    if (field === "minHeadCount") {
      items[index].minHeadCount = value;
    }
    if (field === "maxHeadCount") {
      items[index].maxHeadCount = value;
    }
    if (field === "prepTimeValue") {
      items[index].prepTimeValue = value;
    }
    if (field === "prepTimeUnit") {
      items[index].prepTimeUnit = value;
    }
    setFormData([...items]);
  };

  const handleDelete = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
    setFieldValue("cateringPrepTimes", formData);
  };

  useEffect(() => {
    if (values?.cateringPrepTimes?.length > 0) {
      setFormData(values?.cateringPrepTimes);
    }
  }, [values]);

  useEffect(() => {
    setFieldValue("cateringPrepTimes", formData);
  }, [formData]);

  return (
    <div>
      <Table className="align-items-center" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Head Count</th>
            <th scope="col">Prep Times</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <div className="d-flex align-items-center">
                <FormGroup>
                  <Input
                    name="minHeadCount"
                    onChange={handleChange}
                    style={{ maxWidth: 100 }}
                    value={newItem.minHeadCount}
                    type="text"
                  />
                </FormGroup>

                <FormGroup className="text-xl mx-3">-</FormGroup>
                <FormGroup>
                  <Input
                    name="maxHeadCount"
                    onChange={handleChange}
                    value={newItem.maxHeadCount}
                    style={{ maxWidth: 100 }}
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === ".") {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormGroup>
              </div>
            </th>
            <th scope="row">
              <div className="d-flex align-items-center">
                <FormGroup>
                  <Input
                    name="prepTimeValue"
                    onChange={handleChange}
                    style={{ maxWidth: 100 }}
                    value={newItem.prepTimeValue}
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === ".") {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormGroup>

                <FormGroup className="text-xl mx-3">-</FormGroup>
                <FormGroup>
                  <Input
                    name="prepTimeUnit"
                    onChange={handleChangeValue}
                    value={newItem.prepTimeUnit}
                    style={{ maxWidth: 300 }}
                    type="select"
                  >
                    <option value={""}>Select</option>
                    <option value={"hours"}>Hours</option>
                    <option value={"days"}>Days</option>
                  </Input>
                </FormGroup>
              </div>
            </th>
            <th scope="row">
              <FormGroup>
                <Button onClick={handleAdd}>
                  <i className="fa fa-plus text-lg text-danger" />
                </Button>
              </FormGroup>
            </th>
          </tr>

          {values?.cateringPrepTimes?.map((form, index) => {
            return (
              <tr>
                <th scope="row">
                  <div className="d-flex align-items-center">
                    <FormGroup>
                      <Input
                        name="minHeadCount"
                        onChange={(e) =>
                          handleEdit(e.target.value, index, "minHeadCount")
                        }
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                        disabled={!edit}
                        style={{ maxWidth: 100 }}
                        value={form.minHeadCount}
                        type="text"
                      />
                    </FormGroup>

                    <FormGroup className="text-xl mx-3">-</FormGroup>
                    <FormGroup>
                      <Input
                        name="maxHeadCount"
                        onChange={(e) =>
                          handleEdit(e.target.value, index, "maxHeadCount")
                        }
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                        disabled={!edit}
                        value={form.maxHeadCount}
                        style={{ maxWidth: 100 }}
                        type="text"
                      />
                    </FormGroup>
                  </div>
                </th>
                <th scope="row">
                  <div className="d-flex align-items-center">
                    <FormGroup>
                      <Input
                        name="prepTimeValue"
                        onChange={(e) =>
                          handleEdit(e.target.value, index, "prepTimeValue")
                        }
                        onKeyDown={(e) => {
                          if (e.key === ".") {
                            e.preventDefault();
                          }
                        }}
                        disabled={!edit}
                        style={{ maxWidth: 100 }}
                        value={form.prepTimeValue}
                        type="number"
                      />
                    </FormGroup>

                    <FormGroup className="text-xl mx-3">-</FormGroup>
                    <FormGroup>
                      <Input
                        name="prepTimeUnit"
                        onChange={(e) =>
                          handleEdit(e.target.value, index, "prepTimeUnit")
                        }
                        disabled={!edit}
                        value={form.prepTimeUnit}
                        style={{ maxWidth: 300 }}
                        type="select"
                      >
                        <option value={""}>Select</option>
                        <option value={"hours"}>Hours</option>
                        <option value={"days"}>Days</option>
                      </Input>
                    </FormGroup>
                  </div>
                </th>
                <th scope="row">
                  <FormGroup>
                    {edit ? (
                      <Button size="md" onClick={() => setEdit(false)}>
                        <i className="fa fa-save text-lg text-primary" />
                      </Button>
                    ) : (
                      <Button size="md" onClick={() => setEdit(true)}>
                        <i className="fa fa-edit text-lg text-primary" />
                      </Button>
                    )}
                    <Button size="md" onClick={() => handleDelete(index)}>
                      <i className="fa fa-minus text-lg text-danger" />
                    </Button>
                  </FormGroup>
                </th>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CateringPrepTimes;
