/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Spinner } from "reactstrap";
import TimelineHeader from "../../../../components/Header/SimpleHeader";
import { useEntity } from "../../../../hooks/useEntity";
import usePartnerId from "../../../../hooks/usePartnerId";
import { validationSchemaAddCoupon } from "../../../../utils/schemas/AddCoupon";
import TableWrapper from "../../../../widgets/TableWrapper";
import ErrorLable from "../../../../utils/ErrorLable";
import TableLoader from "../../../../components/TableLoader";
import { validationSchemaAddPartnerCoupon } from "../../../../utils/schemas/AddPartnerCoupons";

const AddPartnerCoupon = (props) => {
  const locationID = useLocation();

  const { findOne, entity, loading } = useEntity("coupon");
  const { create, updateEntity } = useEntity("coupon");
  const partnerId = usePartnerId();

  useEffect(() => {
    if (locationID.state) {
      findOne(locationID.state);
    }
  }, [locationID.state]);

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      restaurant: null,
      partner: partnerId,
      couponName: "",
      couponCode: "",
      couponType: "percentage",
      couponDiscount: 0,
      subTotal: "",
      startDate: "",
      endDate: "",
      usesPerCoupon: "",
      usesPerCustomer: "",
      status: true,
      method: "",
      customerLogin: true,
    },
    validationSchema: validationSchemaAddPartnerCoupon,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        partner: partnerId,
        couponName: values?.couponName,
        couponCode: values?.couponCode,
        couponType: values?.couponType,
        couponDiscount:
          values?.couponType !== "free_delivery" ? values?.couponDiscount : 0,
        subTotal: values?.subTotal,
        items: values?.products?.map((pro) => {
          return pro?.value;
        }),
        categories: values?.categories?.map((cat) => {
          return cat?.value;
        }),
        location: values?.locations?.map((loc) => {
          return loc?.value;
        }),
        duration: {
          startDate: moment(values?.startDate).tz("UTC").toDate(),
          endDate: moment(values?.endDate).tz("UTC").toDate(),
        },
        usesPerCoupon: values?.usesPerCoupon,
        usesPerCustomer: values?.usesPerCustomer,
        status: values?.status,
        method:
          values?.couponType !== "free_delivery" ? values?.method : "delivery",
        customerLogin: values?.customerLogin,
        level: "partner",
      };

      try {
        if (!locationID.state) {
          await create({
            ...data,
          });
          toast.success("Coupon created successfully");
        } else {
          await updateEntity(locationID.state, {
            ...data,
          });
          toast.success("Coupon updated successfully");
        }

        resetForm();
        props.history.goBack();
      } catch (error) {}
    },
  });
  console.log(values, "LOGGE");

  useEffect(() => {
    if (entity) {
      setValues({
        restaurant: entity?.data?.restaurant,
        partner: entity?.data?.partner,
        couponName: entity?.data?.couponName,
        couponCode: entity?.data?.couponCode,
        couponType: entity?.data?.couponType,
        couponDiscount: entity?.data?.couponDiscount,
        subTotal: entity?.data?.subTotal,
        startDate: entity?.data?.duration?.startDate,
        endDate: entity?.data?.duration?.endDate,
        usesPerCoupon: entity?.data?.usesPerCoupon,
        usesPerCustomer: entity?.data?.usesPerCustomer,
        status: entity?.data?.status,
        method: entity?.data?.method,
        customerLogin: entity?.data?.customerLogin,
      });
    }
  }, [entity]);

  return (
    <div>
      <TimelineHeader
        path="/partners"
        name="Partner Coupons"
        parentName="Dashboard"
        showBtn={true}
      >
        <Button onClick={() => props.history.goBack()}>Back</Button>
        <Button onClick={handleSubmit} color="primary">
          {isSubmitting ? <Spinner size={"sm"} /> : "Save Changes"}
        </Button>
      </TimelineHeader>
      {locationID.state && loading ? (
        <TableLoader />
      ) : (
        <TableWrapper customStyles={"px-3 py-3 "}>
          <Form className="text-left">
            <FormGroup>
              <label htmlFor="couponName">Coupon Name</label>
              <Input
                onChange={handleChange("couponName")}
                value={values?.couponName}
                id="couponName"
                placeholder="Coupon Name"
                type="text"
                className={`form-control ${
                  errors.couponName && touched.couponName ? "is-invalid" : ""
                }`}
              />
              {errors.couponName && touched.couponName && (
                <ErrorLable message={errors.couponName} />
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="exampleFcouponCodeormControlInput1">
                Coupon Code
              </label>
              <Input
                onChange={handleChange("couponCode")}
                value={values?.couponCode}
                id="couponCode"
                placeholder="Coupon Code"
                type="text"
                className={`form-control ${
                  errors.couponCode && touched.couponCode ? "is-invalid" : ""
                }`}
              />
              {errors.couponCode && touched.couponCode && (
                <ErrorLable message={errors.couponCode} />
              )}
            </FormGroup>

            <FormGroup>
              <label htmlFor="couponType">Coupon Type</label>
              <Input
                onChange={handleChange("couponType")}
                value={values?.couponType}
                id="couponType"
                type="select"
                className={`form-control ${
                  errors.couponType && touched.couponType ? "is-invalid" : ""
                }`}
              >
                <option value={null}>Select</option>{" "}
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed Amount</option>
                <option value="free_delivery">Free Delivery</option>
              </Input>
              {errors.couponType && touched.couponType && (
                <ErrorLable message={errors.couponType} />
              )}
            </FormGroup>

            {values?.couponType !== "free_delivery" && (
              <FormGroup>
                <label htmlFor="totalAmount">Discount</label>
                <Input
                  onChange={handleChange("couponDiscount")}
                  value={values?.couponDiscount}
                  id="couponDiscount"
                  multiple=""
                  type="text"
                  className={`form-control ${
                    errors.couponDiscount && touched.couponDiscount
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {errors.couponDiscount && touched.couponDiscount && (
                  <ErrorLable message={errors.couponDiscount} />
                )}
              </FormGroup>
            )}
            <FormGroup>
              <label htmlFor="totalAmount">Total Amount</label>
              <Input
                onChange={handleChange("subTotal")}
                value={values?.subTotal}
                id="subTotal"
                multiple=""
                type="text"
                className={`form-control ${
                  errors.subTotal && touched.subTotal ? "is-invalid" : ""
                }`}
              />
              {errors.subTotal && touched.subTotal ? (
                <ErrorLable message={errors.subTotal} />
              ) : (
                <small className="text-left">
                  *The total amount that must be reached before the coupon is
                  valid
                </small>
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="couponMethod">Method</label>
              <Input
                onChange={handleChange("method")}
                value={values?.method}
                id="couponMethod"
                type="select"
                className={`form-control ${
                  errors.method && touched.method ? "is-invalid" : ""
                }`}
              >
                <option value={null}>Select</option>{" "}
                {values?.couponType !== "free_delivery" && (
                  <>
                    <option value="all">All</option>
                    <option value="pickup">Pickup</option>
                    <option value="dine-in">Dine in</option>
                    <option value="walkup">Walkup</option>
                  </>
                )}
                <option value="delivery">Delivery</option>
              </Input>
              {errors.method && touched.method && (
                <ErrorLable message={errors.method} />
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="dateStart">Date Start</label>
              <Input
                id="dateStart"
                min={new Date().toISOString().split("T")[0]}
                max={
                  values?.startDate &&
                  new Date(values?.startDate).toISOString().split("T")[0]
                }
                type="date"
                onChange={(e) => {
                  setFieldValue("startDate", e.target.value);
                }}
                value={moment(values?.startDate).format("YYYY-MM-DD")}
                className={`form-control ${
                  errors.startDate && touched.startDate ? "is-invalid" : ""
                }`}
              />
              {errors.startDate && touched.startDate && (
                <ErrorLable message={errors.startDate} />
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="dateEnd">Date End</label>
              <Input
                id="dateEnd"
                min={new Date().toISOString().split("T")[0]}
                max={
                  values?.endDate &&
                  new Date(values?.endDate).toISOString().split("T")[0]
                }
                type="date"
                onChange={(e) => {
                  setFieldValue("endDate", e.target.value);
                }}
                value={moment(values?.endDate).format("YYYY-MM-DD")}
                className={`form-control ${
                  errors.endDate && touched.endDate ? "is-invalid" : ""
                }`}
              />
              {errors.endDate && touched.endDate && (
                <ErrorLable message={errors.endDate} />
              )}
            </FormGroup>
            <FormGroup>
              <div className="d-flex align-items-center">
                <label>Status</label>
                <div className="custom-control custom-checkbox">
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("status")}
                      value={values?.status}
                      checked={values?.status ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <label htmlFor="usesPerCoupon">Uses Per Coupon</label>
              <Input
                onChange={handleChange("usesPerCoupon")}
                value={values?.usesPerCoupon}
                id="usesPerCoupon"
                multiple=""
                type="text"
                className={`form-control ${
                  errors.usesPerCoupon && touched.usesPerCoupon
                    ? "is-invalid"
                    : ""
                }`}
              ></Input>
              {errors.usesPerCoupon && touched.usesPerCoupon ? (
                <ErrorLable message={errors.usesPerCoupon} />
              ) : (
                <small className="text-left">
                  *The maximum number of times the coupon can be used by any
                  customer, leave blank for unlimited
                </small>
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="usesPerCustomer">Uses Per Customer</label>
              <Input
                onChange={handleChange("usesPerCustomer")}
                value={values?.usesPerCustomer}
                id="usesPerCustomer"
                multiple=""
                disabled={!values?.customerLogin}
                type="text"
                className={`form-control ${
                  errors.usesPerCustomer && touched.usesPerCustomer
                    ? "is-invalid"
                    : ""
                }`}
              ></Input>
              {errors.usesPerCustomer && touched.usesPerCustomer ? (
                <ErrorLable message={errors.usesPerCustomer} />
              ) : (
                <small className="text-left">
                  *The maximum number of times the coupon can be used by a
                  single customer, leave blank for unlimited
                </small>
              )}
            </FormGroup>
            <FormGroup>
              <div className="d-flex align-items-center">
                <label>Customer Login</label>
                <div className="custom-control custom-checkbox">
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("customerLogin")}
                      value={values?.customerLogin}
                      checked={values?.customerLogin ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </div>
              </div>
            </FormGroup>
          </Form>
        </TableWrapper>
      )}
    </div>
  );
};

export default AddPartnerCoupon;
