import { useContext, useState } from "react";
import { HiOutlineUser } from "react-icons/hi";
import { AuthContext } from "../../../../context";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import MyAccount from "../../home/components/MyAccount";
import "../../styles/Header/Header.css";

const HeaderV2 = ({ title, account, history, className, hideLogo = false }) => {
  const { restaurant } = useOrderingRestaurant();
  const [accountModal, setAccountModal] = useState(false);
  const authContext = useContext(AuthContext);
  const { theme } = useTheme();

  return (
    <div
      className={`main_header_wrapper_parent_v2 ${className}
      }`}
      style={{
        position: "relative",
      }}
    >
      {restaurant?.ordering?.headerImage !== "" &&
      restaurant?.ordering?.headerImage ? (
        <img
          src={restaurant?.ordering?.headerImage}
          style={{
            height: 200,
            objectFit: "cover",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: 0,
          }}
          alt="headerImage"
        />
      ) : (
        <div
          style={{
            height: 200,
            objectFit: "cover",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: 0,
            backgroundColor: theme.bannerColor,
          }}
        ></div>
      )}
      <div
        className={`main_header_wrapper_v2 ${hideLogo && "hide_logo_profile"}`}
      >
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 1, zIndex: 2 }} className="header_image_wrapper">
          <a href={restaurant?.homeLink || restaurant?.url}>
            <div className="centered_div_image">
              <div
                style={{
                  backgroundColor: restaurant?.ordering?.logoWhiteBackground
                    ? "white"
                    : "transparent",
                  maxHeight: restaurant?.ordering?.logoWhiteBackground
                    ? "155px"
                    : "185px",
                  maxWidth: "220px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  padding: 10,
                }}
              >
                <img
                  className="header_image_ordering"
                  src={`${restaurant?.logo}`}
                  style={{ cursor: "pointer" }}
                  alt="logo"
                />
              </div>
            </div>
          </a>
        </div>
        {account && (
          <div className="account_header">
            {!authContext?.user ? (
              <div className="pt-3 px-2">
                <span
                  className=" profile_icon"
                  style={{
                    color: "white",
                    fontSize: 18,
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let url = new URL(restaurant?.url);
                    history.push(url.pathname + "/sign-in");
                  }}
                >
                  Sign in
                </span>
                <HiOutlineUser
                  onClick={() => {
                    let url = new URL(restaurant?.url);
                    history.push(url.pathname + "/sign-in");
                  }}
                  size={25}
                  className="mx-1"
                  color="white"
                />
              </div>
            ) : (
              <>
                <div
                  className="pt-3 px-2"
                  style={{ display: "flex", justifyContent: "center" }}
                  onClick={() => {
                    let url = new URL(restaurant?.url);
                    history.push(url.pathname + "/my-account");
                  }}
                >
                  <span
                    className="profile_icon"
                    style={{
                      color: "white",
                      fontSize: 18,
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    Account
                  </span>
                  <HiOutlineUser className="mx-1" size={25} color="white" />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <MyAccount
        history={history}
        open={accountModal}
        toggle={() => setAccountModal(!accountModal)}
        theme={theme}
      />
    </div>
  );
};

export default HeaderV2;
