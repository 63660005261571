/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import FavIcon from "../../assets/cart.png";
import { AuthContext } from "../../context";
import { useRestaurant } from "../../hooks/restaurant/useRestaurant";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../store/theme";
import PageNotFound from "../PageNotFound";

const RestaurantWrapper = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { setRestaurant, restaurant } = useOrderingRestaurant();
  const pathname = location.pathname.substr(1);
  const authContext = useContext(AuthContext);
  const { setTheme } = useTheme();

  const {
    find: findRestaurant,
    entity: restaurantDoc,
    loading: loadingRestaurant,
  } = useRestaurant();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (authContext.user) {
      if (
        Object.keys(authContext.user).length !== 0 &&
        authContext.user.role !== "customer"
      )
        authContext.logout();
    }
  }, []);

  useEffect(() => {
    findRestaurant({
      partnerUrl: window.location.host,
      restaurantId: pathname.split("/")[1],
    });
  }, []);

  useEffect(() => {
    if (Object.keys(restaurantDoc).length > 0) {
      setRestaurant(restaurantDoc?.data);
    }
  }, [restaurantDoc]);

  useEffect(() => {
    if (!restaurant?.orderingApp) {
      if (restaurant?.url) {
        let url = new URL(restaurant?.url);
        history.push(url.pathname);
      }
    }
  }, [restaurant]);

  useEffect(() => {
    setTheme({
      primary: restaurant?.ordering?.backgroundColor,
      secondary: restaurant?.ordering?.secondaryColor,
      bannerColor:
        restaurant?.ordering?.bannerColor ||
        restaurant?.ordering?.backgroundColor,
    });
  }, [restaurant]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = restaurant?.name
      ? `${restaurant?.name} Online Ordering`
      : "Online Ordering";

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = FavIcon;
  }, [restaurant]);

  if (loadingRestaurant && Object.keys(restaurant || {}).length === 0) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size="25" />
      </div>
    );
  }

  if (!restaurant && !loadingRestaurant) {
    return <PageNotFound />;
  }

  return children;
};

export default RestaurantWrapper;
