export default function compareArrays(array1, array2) {
  console.log(array1);
  console.log(array2);
  const missingItems = [];

  // Get objects from array1 with defaultSelected: true
  const selectedItems = array1.filter((item) => item.defaultSelected);

  // Check if selected items from array1 are present in array2
  for (let selectedItem of selectedItems) {
    if (!array2.some((item2) => compareObjects(selectedItem, item2))) {
      missingItems.push(selectedItem);
    }
  }

  return missingItems;
}

function compareObjects(obj1, obj2) {
  // Compare objects by their properties
  return obj1.product_id === obj2.product_id;
}
