import classnames from "classnames";
import { useEffect, useState } from "react";
import { Button, Nav, NavItem, NavLink, Spinner, TabContent } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTable from "../../../widgets/SuperTable";
import TableWrapper from "../../../widgets/TableWrapper";
import TableRowPushNotification from "./components/TableRowPushNotification";

const PushNotifications = (props) => {
  const [tabs, setTabs] = useState(1);

  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { partner } = useAdminStore();

  const { find, entities, loading } = useEntity("push-notification");

  useEffect(() => {
    find({
      partner,
      status: tabs === 1 ? "scheduled" : "completed",
    });
  }, [tabs]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = [
    "Restaurants",
    "Audience",
    "Timing",
    "Status",
    "Message",
    "Action",
  ];

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <>
      <SimpleHeader
        path="/admin/partners"
        name="Push Notifications"
        parentName="Partners"
        showBtn={true}
      >
        <Button
          onClick={() => {
            props.history.push("add-notification");
          }}
          color="primary"
        >
          Create
        </Button>
      </SimpleHeader>
      <TableWrapper customStyles="pb-2 px-2">
        <Nav
          className="nav-fill flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="ni ni-watch-time" /> Schedules
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="ni ni-check-bold" /> Completed
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="mt-3" activeTab={"tabs" + tabs}>
          {!loading ? (
            <SuperTable
              sort={handleSort}
              showCheckboxes={false}
              data={entities?.data}
              onPageChange={handlePageChange}
              OnRowPerPageChange={handleRowsPerPageChange}
              total={entities?.length}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              history={props.history}
              row={TableRowPushNotification}
              columns={Headers}
              isLoading={loading}
              emptyMessage="Currently no notifications"
            />
          ) : (
            <div
              style={{
                padding: 20,
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <center>
                <Spinner size="lg" color="primary" type="border" />
              </center>
            </div>
          )}
        </TabContent>
      </TableWrapper>
    </>
  );
};

export default PushNotifications;
