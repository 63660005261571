import { useFormik } from "formik";
import { useContext, useState } from "react";
import { Button, Card, CardBody, Container, Spinner } from "reactstrap";
import swal from "sweetalert";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import EditCustomer from "./components/EditCustomer";
import GeneralForm from "./components/GeneralForm";

export default function CreateCustomers(props) {
  const mode = props?.location?.state?.mode;
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const [addresses, setAddresses] = useState();

  const { create: createCustomer } = useEntity("customer");
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      fax: "",
      password: "",
      newsLetter: false,
      approved: false,
      safe: false,
      address: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        status: "active",
        customerGroup: "default",
        phone: values.phone,
        fax: values.fax,
        safe: values.safe,
        newsLetter: values.newsLetter,
        approved: values.approved,
        restaurant: authRest || restaurant,
        role: "customer",
        address: addresses,
      };

      try {
        await createCustomer({ ...data });
        swal("Success", "Customer Created!", "success");
        props.history.goBack();
        resetForm();
      } catch (error) {
        swal("Error", error.message, "error");
      }
    },
  });

  return (
    <>
      <TimelineHeader
        name="Create Customer"
        parentName="Customers"
        showBtn={true}
      >
        <Button
          onClick={() => {
            props.history.goBack();
          }}
          className="btn-neutral"
          color="default"
          size="md"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
          color="primary"
          size="md"
        >
          {formik.isSubmitting ? <Spinner size="sm" /> : "Save"}
        </Button>
      </TimelineHeader>
      <Container fluid className="mt--6">
        <Card>
          <CardBody>
            <div>
              {mode === "edit" ? (
                <EditCustomer
                  handleChange={formik.handleChange}
                  values={formik.values}
                />
              ) : (
                <GeneralForm
                  handleChange={formik.handleChange}
                  values={formik.values}
                  address={(val) => setAddresses(val)}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
      <div style={{ height: 100 }} />
    </>
  );
}
