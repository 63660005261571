/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import useDebounce from "../../../../hooks/useDebounce";
import { useEntity } from "../../../../hooks/useEntity";

const TableRowUpdatePlu = ({ item, tabs }) => {
  const [plu, setPlu] = useState("");
  const debouncedQuery = useDebounce(plu, 500);
  const { updateEntity } = useEntity("item");

  useEffect(async () => {
    if (plu !== "" && plu !== item.plu) {
      const res = await updateEntity(item._id, {
        plu,
      });

      setPlu(res.plu);
    }
  }, [debouncedQuery, tabs]);

  useEffect(() => {
    setPlu(item?.plu);
  }, [item]);

  return (
    <tr className="text-left">
      <th>{item?.name}</th>
      <th>
        <Input
          value={plu}
          onChange={(e) => {
            setPlu(e.target.value);
          }}
        />
      </th>
      <th>
        <Button
          onClick={async () => {
            await updateEntity(item._id, { plu: "" });
            setPlu("");
          }}
          size="sm"
        >
          Remove
        </Button>
      </th>
    </tr>
  );
};

export default TableRowUpdatePlu;
