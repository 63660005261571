import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "../../components/PageNotFound";
import { AuthContext } from "../../context";
import ForgotPassword from "./auth/ForgotPassword";
import OtpVerify from "./auth/OtpVerify";
import Register from "./auth/Register";
import Signin from "./auth/Signin";
import CateringHome from "./catering/CateringHome";
import Checkout from "./checkout/Checkout";
import PostCheckout from "./checkout/PostCheckout";
import TableScreen from "./components/TableScreen";
import DeliveryOrdering from "./delivery/DeliveryOrdering";
import DineinOrdering from "./dine-in/DineOrdering";
import OrderHistory from "./history/OrderHistory";
import MainHome from "./home/Main";
import RequestAccountDeletion from "./home/RequestAccountDeletion";
import CustomerProfile from "./home/components/CustomerProfile";
import Location from "./location/Location";
import Menu from "./menu/Menu";
import Schedule from "./schedule/Schedule";
import TimePicker from "./schedule/TimePicker";
import WalkupOrdering from "./walkup/WalkupOrdering";
import MenuMobile from "./menu/MenuMobile";
import useScreenSize from "../../hooks/useScreenSize";

export default function OrderingRoutes(props) {
  const authContext = useContext(AuthContext);

  const { width } = useScreenSize();

  return (
    <Switch>
      <Route
        exact
        path="/ordering/:restaurant/walkup"
        component={WalkupOrdering}
        {...props}
      />
      <Route
        exact
        path="/ordering/:restaurant/dine-in"
        component={DineinOrdering}
        {...props}
      />

      <Route
        exact
        path="/ordering/:restaurant/delivery"
        component={DeliveryOrdering}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant"
        component={MainHome}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/catering"
        component={CateringHome}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/location"
        component={Location}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/timing"
        component={Schedule}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/later"
        component={TimePicker}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/menu"
        component={width < 600 ? MenuMobile : Menu}
      />
      <Route
        exact
        path="/ordering/:resturant/checkout"
        component={Checkout}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/success"
        component={PostCheckout}
        {...props}
      />
      <Route
        exact
        path="/ordering/:resturant/table"
        component={TableScreen}
        {...props}
      />
      <Route exact path="/ordering/:restaurant/sign-in" component={Signin} />
      <Route
        exact
        path="/ordering/:restaurant/forgot-password"
        component={ForgotPassword}
      />
      <Route
        exact
        path="/ordering/:restaurant/verify-otp"
        component={OtpVerify}
      />
      <Route exact path="/ordering/:restaurant/register" component={Register} />

      <Route
        exact
        path="/ordering/:resturant/history"
        component={authContext.user ? OrderHistory : Signin}
        {...props}
      />

      <Route
        exact
        path="/ordering/:restaurant/my-account"
        component={authContext.user ? CustomerProfile : Signin}
      />

      <Route
        exact
        path="/ordering/:restaurant/request-account-deletion"
        component={RequestAccountDeletion}
      />

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}
