/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

function LandingHeader({ title, lead }) {
  return (
    <>
      <div className="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
        <Container>
          <div className="header-body text-left mb-7">
            <Row className="justify-content-left row align-items-center">
              <Col>
                <div className="pr-5">
                  <h1
                    className=" text-white font-weight-bold mb-0"
                    style={{ fontSize: 50 }}
                  >
                    Online Ordering Service
                  </h1>
                  <h2 className="display-4 text-white font-weight-light">
                    Become a Reseller Today!
                  </h2>
                  <p className="text-white mt-4">
                    Sell online ordering software with your brand and your
                    rules!
                  </p>
                  <div className="mt-2">
                    <a
                      href="javascript:void(0)"
                      className="my-2 btn btn-success"
                      style={{ textTransform: "uppercase" }}
                    >
                      Downloa Presentation
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <img
                  style={{ height: "100%", width: "100%" }}
                  alt="hero_image"
                  src="https://myordering.app/assets/images/landing/img1.svg"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

LandingHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default LandingHeader;
