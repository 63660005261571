import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";

export default function LocationIntegrations({ formik }) {
  const [tabs, setTabs] = useState(1);
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const { findOne, entity: restaurantDoc } = useEntity("restaurant");

  useEffect(() => {
    findOne(authRest || restaurant);
  }, []);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <div style={{ zIndex: 999, position: "relative", minHeight: "60vh" }}>
      <Card
        className="flex-md-row flex-sm-col"
        style={{
          display: "flex",
          boxShadow: "none",
        }}
      >
        <Nav id="tabs-icons-text" pills vertical style={{ flex: 1 }}>
          {/* <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 1}
              className={classnames(
                "mb-sm-3 mb-md-0 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 1,
                }
              )}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-list mr-2" />
              Reports
            </NavLink>
          </NavItem> */}
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 1}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 1,
                }
              )}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-gift mr-2" />
              OrderHeist
            </NavLink>
          </NavItem>
          {restaurantDoc?.data?.restaurant?.enableJKSoft && (
            <NavItem className="text-left">
              <NavLink
                aria-selected={tabs === 2}
                className={classnames(
                  "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                  {
                    active: tabs === 2,
                  }
                )}
                onClick={(e) => toggleNavs(e, 2)}
                href="#pablo"
                role="tab"
              >
                <i className="fa fa-gear mr-2" />
                JKSoft
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent style={{ flex: 2 }} activeTab={"tabs" + tabs}>
          {/* <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <Col md="12">
              <Form>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Transaction Report
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        checked={formik.values?.txnReport}
                        value={formik.values?.txnReport}
                        onChange={formik.handleChange("txnReport")}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
                {formik.values.txnReport && (
                  <>
                    <FormGroup className="row" style={{ alignItems: "center" }}>
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Frequency
                      </Label>
                      <Col md="8">
                        <Select
                          value={formik.values?.txnReportFrequency}
                          isSearchable={false}
                          placeholder="Select frequency"
                          options={[
                            { label: "Daily", value: "daily" },
                            { label: "Weekly", value: "weekly" },
                            { label: "Monthly", value: "monthly" },
                          ]}
                          onSelect={(e) => {
                            formik.setFieldValue(
                              "txnReportFrequency",
                              e.target.value
                            );
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row" style={{ alignItems: "center" }}>
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Email
                      </Label>
                      <Col md="8">
                        <Input
                          onChange={formik.handleChange("txnReportEmail")}
                          type="text"
                          value={formik.values?.txnReportEmail}
                          placeholder="Enter email to recieve transaction reports"
                        />
                      </Col>
                    </FormGroup>
                  </>
                )}
              </Form>
              <Form>
                <FormGroup className="row" style={{ alignItems: "center" }}>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Close Of Business
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        checked={formik.values?.closeOfBusiness}
                        value={formik.values?.closeOfBusiness}
                        onChange={formik.handleChange("closeOfBusiness")}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
                {formik.values.closeOfBusiness && (
                  <>
                    <FormGroup className="row" style={{ alignItems: "center" }}>
                      <Label
                        className="form-control-label text-left"
                        htmlFor="example-text-input"
                        md="4"
                      >
                        Email
                      </Label>
                      <Col md="8">
                        <Input
                          onChange={formik.handleChange("closeOfBusinessEmail")}
                          type="text"
                          value={formik.values.closeOfBusinessEmail}
                          placeholder="Enter email to recieve close of business reports"
                        />
                      </Col>
                    </FormGroup>
                  </>
                )}
              </Form>
            </Col>
          </TabPane> */}
          <TabPane tabId={"tabs1"} style={{ textAlign: "left" }}>
            <Col md="12" className="my-2">
              <FormGroup className="row" style={{ alignItems: "center" }}>
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="5"
                >
                  Enable OrderHeist Integration
                </Label>
                <Col md="7">
                  <label className="custom-toggle">
                    <input
                      checked={formik.values?.enableKitchenHub}
                      value={formik.values?.enableKitchenHub}
                      onChange={formik.handleChange("enableKitchenHub")}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </FormGroup>
              {formik.values?.enableKitchenHub && (
                <Row>
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="5"
                  >
                    OrderHeist Location ID
                  </Label>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="text"
                      value={formik.values.kitchenHubLocationId}
                      onChange={formik.handleChange("kitchenHubLocationId")}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </TabPane>
          <TabPane tabId={"tabs2"} style={{ textAlign: "left" }}>
            <Col md="12" className="my-2">
              <Row>
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="3"
                >
                  JKSoft Location ID
                </Label>
                <Col
                  md="6"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.JKSoftLocationId}
                    disabled
                    // onChange={formik.handleChange("kitchenHubLocationId")}
                  />
                </Col>
                <Button
                  className="md-2"
                  onClick={() => {
                    if (formik.values.JKSoftLocationId) {
                      navigator.clipboard.writeText(
                        formik.values.JKSoftLocationId
                      );
                      toast.success("Copied to clipboard");
                      return;
                    }
                  }}
                >
                  Copy
                </Button>
              </Row>
            </Col>
          </TabPane>
        </TabContent>
      </Card>
    </div>
  );
}
