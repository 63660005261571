/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { FaFire, FaTag } from "react-icons/fa";
import Select from "react-select";
import { Button } from "reactstrap";
import HorizontalListMobile from "../../../../components/List/HorizontalListMobile";
import { AuthContext } from "../../../../context";
import useIsInViewport from "../../../../hooks/useInViewPort";
import useScreenSize from "../../../../hooks/useScreenSize";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import getRestaurantPathFromUrl from "../../../../utils/getRestaurantPathFromUrl";
import LoginModal from "../../components/LoginModal";
import PopularDishesModal from "../../components/PopularDishesModal";
import UsualDishesModal from "../../components/UsualDishesModal";
import CategoryList from "./CategoryList";
import LoyalityComponent from "./LoyalityComponent";

export const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#ededed",

    // match with the menu
    borderColor: "#ededed",

    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap

    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    textAlign: "left",

    borderRadius: "2px",
  }),
};

const MenuSidebar = ({
  onClick,
  data,
  catName,
  searchString,
  catSelected,
  classname,
  history,
  groupCategories,
  onGroupCategoryChange,
  selectedGroupCategories,
  categories,
  hasExtraCats,
}) => {
  const { restaurant } = useOrderingRestaurant();
  const { theme } = useTheme();
  const { method, cateringData } = useOrdering();
  const { width } = useScreenSize();
  const [showPopular, setShowPopular] = useState(false);
  const [showUsual, setShowUsual] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dropDownCat, setDropDownCat] = useState();
  const authContext = useContext(AuthContext);
  const inputRef = useRef(null);
  const [loginModal, setLoginModal] = useState(false);
  const [isMobileSearch, setIsMobileSearch] = useState(false);
  const [dropdownSelect, setDropdownSelect] = useState();
  const catRef = useRef(null);
  const ref1 = useRef(null);
  const isInViewport1 = useIsInViewport(ref1);

  useEffect(() => {
    if (dropDownCat) {
      onClick(dropDownCat);
      let obj = data?.find((o) => o._id === dropDownCat);
      catName({ name: obj?.categoryName, description: obj?.description });
    }
  }, [dropDownCat]);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setIsMobileSearch(event.detail.mobileSearch);
    };

    document.addEventListener("mobileSeachEnabled", handleCustomEvent);

    return () => {
      document.removeEventListener("mobileSeachEnabled", handleCustomEvent);
    };
  }, []);

  const showOrderUsualAndPopular = !(
    restaurant?.ordering?.hideOrderUsualAndPopular || method === "walkup"
  );

  return (
    <div>
      <LoginModal
        open={loginModal}
        toggle={() => setLoginModal(!loginModal)}
        theme={theme}
      />
      <div
        className={`${classname} menu_sidebar_image_container mb-3`}
        style={{
          padding: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          backgroundColor: theme.bannerColor,
        }}
      >
        {restaurant?.ordering?.headerImage !== "" &&
          restaurant?.ordering?.headerImage && (
            <img
              className="header_image_sidebar"
              src={restaurant?.ordering?.headerImage}
              style={{
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                width: "100%",
              }}
              alt="headerImage"
            />
          )}
        <a
          className="mobile_menu_sidebar_image"
          style={{
            zIndex: 1,
            backgroundColor: restaurant?.ordering?.logoWhiteBackground
              ? "white"
              : "transparent",
          }}
          href={restaurant?.homeLink || restaurant?.url}
        >
          <div
            style={{
              zIndex: 1,
              backgroundColor: restaurant?.ordering?.logoWhiteBackground
                ? "white"
                : "transparent",
            }}
          >
            <img
              src={`${restaurant?.logo}`}
              alt="logo"
              style={{
                cursor: "pointer",
                zIndex: 1,
              }}
              className="header_image_ordering_menu  "
            />
          </div>
        </a>
      </div>
      <div
        style={{
          borderTop: `2px solid ${theme.primary}`,
        }}
        className="mx-3 banner_main_section_note"
      >
        <div className="text-left">
          <div
            style={{
              color: theme.primary,
              fontWeight: "600",
              marginBottom: "10px",
            }}
          >
            NOTE :
          </div>
          <div>
            {restaurant?.pop_up_alerts?.category_popup?.note !== "" &&
            restaurant?.pop_up_alerts?.category_popup?.note !== undefined &&
            restaurant?.pop_up_alerts?.category_popup?.note
              ? restaurant?.pop_up_alerts?.category_popup?.note
              : "Welcome to our online ordering site."}
          </div>
        </div>
      </div>
      {restaurant?.ordering?.allowLoyaltyTransaction &&
        authContext?.user?.role === "customer" && (
          <div
            className={"px-3 py-2"}
            style={{ borderBottom: "1px solid #ededed", cursor: "pointer" }}
          >
            <LoyalityComponent />
          </div>
        )}
      <div
        className="px-3 py-2 search_bar_menu"
        style={{ borderBottom: "1px solid #ededed" }}
      >
        <div
          className="px-2 py-2"
          style={{
            backgroundColor: "#ededed",
            display: "flex",
            alignItems: "center",
            borderBottom: "2px solid #666666",
          }}
        >
          <div>
            <BsSearch size={20} color="#666666" />
          </div>
          <div className="px-2">|</div>
          <div className="input_custom_over">
            <input
              className="px-2 py-2"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
              }}
              type="text"
              onChange={(e) => {
                setSearchText(e.target.value);
                searchString(e.target?.value);
              }}
              placeholder="Search"
              ref={inputRef}
            />
          </div>

          <div
            onClick={() => {
              inputRef.current.value = "";
              searchString("");
            }}
            style={{ flex: "1", textAlign: "right" }}
          >
            <div style={{ width: 17 }}></div>
            {searchText?.length > 0 && (
              <AiOutlineClose style={{ cursor: "pointer" }} size={20} />
            )}
          </div>
        </div>
      </div>
      {showOrderUsualAndPopular && (
        <div
          className="padding-horizontal no_border padding-vertical"
          style={{ borderBottom: "1px solid #ededed" }}
        >
          <div className={classname ? classname : "order_usual_wrapper"}>
            {!cateringData?.method && (
              <div style={{ flex: 1 }}>
                <Button
                  block
                  className="py-3 text-xs order_usual_btn order_usual_btn_margin"
                  style={{
                    backgroundColor: "white",
                    color: theme.primary,
                  }}
                  onClick={() =>
                    authContext?.user ? setShowUsual(true) : setLoginModal(true)
                  }
                >
                  {width > 600 && (
                    <FaTag
                      className="mx-1"
                      style={{ color: theme.secondary }}
                    />
                  )}
                  My Usual
                </Button>
              </div>
            )}
            {!cateringData?.method && <div className="space_usual"></div>}
            <div style={{ flex: 1 }}>
              <Button
                block
                className="py-3 order_usual_btn"
                style={{
                  backgroundColor: "white",
                  color: theme.primary,
                }}
                onClick={() => setShowPopular(true)}
              >
                {width > 600 && (
                  <FaFire className="mx-1" style={{ color: theme.secondary }} />
                )}{" "}
                Popular Picks
              </Button>
            </div>
          </div>
        </div>
      )}

      {
        <div ref={ref1} className="show_mobile_group_cats">
          {groupCategories?.length > 0 && (
            <HorizontalListMobile
              selected={selectedGroupCategories}
              onChangeItem={(id) => {
                catRef?.current?.setValue([], "clear");
                onGroupCategoryChange(id);
              }}
              items={groupCategories || []}
              cats={categories?.data?.availCats}
              hasExtraCats={hasExtraCats}
            />
          )}
        </div>
      }

      {(restaurant?.ordering?.categoryLayout === "select" ||
        !restaurant?.ordering?.categoryLayout ||
        restaurant?.ordering?.categoryLayout === "") &&
        restaurant?.ordering?.categoryLayout !== "horizontal" && (
          <div
            className={
              isInViewport1
                ? "category_dropdown_menu mx-3 my-2 "
                : "category_dropdown_menu_fixed mx-3"
            }
            style={{
              backgroundColor: "#ededed",
              borderRadius: 0,
              zIndex: isInViewport1 ? 999 : 9,
              marginTop:
                !isInViewport1 && !isMobileSearch
                  ? 60
                  : !isInViewport1 && isMobileSearch
                  ? 140
                  : 0,
            }}
          >
            <div style={{ width: "98%" }}>
              <Select
                isSearchable={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "null",
                  },
                })}
                onChange={(val) => {
                  setDropdownSelect(val);
                  setDropDownCat(val?.value);
                }}
                name="category"
                styles={customStyles}
                value={dropdownSelect}
                ref={catRef}
                options={data?.map((cat) => {
                  return {
                    label: cat?.categoryName,
                    value: cat?._id,
                  };
                })}
              />
            </div>
          </div>
        )}

      <div className={classname ? classname : "category_list_menu"}>
        {data?.map((cats, i) => (
          <CategoryList
            catName={catName}
            catSelected={catSelected}
            cats={cats}
            onClick={onClick}
            key={i}
          />
        ))}
      </div>

      <UsualDishesModal
        theme={theme}
        isOpen={showUsual}
        usualdata={data?.data}
        toggle={() => setShowUsual(!showUsual)}
      />
      {showPopular && (
        <PopularDishesModal
          origin={getRestaurantPathFromUrl(window.location.pathname)}
          theme={theme}
          isOpen={showPopular}
          toggle={() => setShowPopular(!showPopular)}
        />
      )}
    </div>
  );
};

export default MenuSidebar;
