import moment from "moment";
import React from "react";
import { Button, Col, FormGroup, Input, Row, Table } from "reactstrap";

export default function RewardPoints() {
  return (
    <>
      <Table className="align-items-center" responsive bordered>
        <thead className="thead-light">
          <tr>
            <th scope="col">Date Added</th>
            <th scope="col">Description</th>
            <th scope="col">Status</th>
            <th scope="col">Points</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4].map((d, i) => {
            return (
              <tr key={i}>
                <td>{moment().format("LLL")}</td>
                <td>Description {i}</td>
                <td>Status {i}</td>
                <td>{i * 100} points</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Row className="text-left mt-2">
        <Col md={12} className="mt-2">
          <FormGroup>
            <label htmlFor="exampleFormControlInput1">Points</label>
            <Input type="text" />
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <label htmlFor="exampleFormControlInput1">Description</label>
            <Input type="textarea" />
          </FormGroup>
        </Col>
        <Col
          md={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button color="primary">Add Points</Button>
        </Col>
      </Row>
    </>
  );
}
