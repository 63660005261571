import moment from "moment-timezone"
import { toast } from "react-toastify"
import swal from "sweetalert"
import { useEntity } from "../../../../hooks/useEntity"
import { useLocation } from "react-router-dom"

const TableRowCoupons = ({ data, history }) => {
  const { updateEntity, deleteEntity } = useEntity("coupon")
  const location = useLocation()

  console.log(data?.duration, "DURATION")
  return (
    <tr className="text-left">
      <td
        style={{ cursor: "pointer", fontWeight: "bold" }}
        onClick={() => {
          if (data.reEngageCoupon) return

          if (data?.level === "partner") {
            history.push({
              pathname: "/partners/marketing/add-coupon",
              state: data?._id,
              search: location.search,
            })
          } else
            history.push({
              pathname: "/resturants/marketing/add-coupon",
              state: data?._id,
              search: location.search,
            })
        }}
      >
        {data?.couponName}
      </td>
      <td>{data?.couponCode}</td>
      <td style={{ textTransform: "capitalize" }}>
        {data?.couponType === "free_delivery"
          ? "Free Delivery"
          : data?.couponType}
      </td>
      <td>{data?.couponDiscount}</td>
      <td>
        {moment(data?.duration?.startDate)
          .tz("America/Chicago")
          .format("MM/DD/YYYY")}
      </td>
      <td>
        {moment(data?.duration?.endDate)
          .tz("America/Chicago")
          .format("MM/DD/YYYY")}
      </td>
      <td>
        <>
          <label className="custom-toggle">
            <input
              checked={data?.status === true}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: data?.status === true ? false : true,
                  })
                  toast.success("Coupon updated successfully")
                } catch (error) {
                  toast(error.message)
                }
              }}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </>
      </td>
      <td>
        <span
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(data?._id)
                  toast.error("Coupon deleted succesfully")
                }
              })
            } catch (error) {
              toast(error.message)
            }
          }}
        >
          <i className="fa fa-trash text-lg text-danger" />
        </span>
      </td>
    </tr>
  )
}

export default TableRowCoupons
