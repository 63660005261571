import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import { ErrorText } from "../../../components/ErrorText";
import { URL as FRONTENDURL } from "../../../config";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useCartStore } from "../../../store";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import DB from "../../../utils/DB";
import getCookie from "../../../utils/getCookie";
import { DBKeys } from "../../../utils/Keys";
import logger from "../../../utils/logger";
import SocialComponent from "../auth/components/SocialComponent";
import CreateAccountModal from "./modals/CreateAccountModal";
import ForgotPassword from "./modals/ForgotPassword";
import ModalHeader from "./modals/ModalHeader";

const loginSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
});

export default function LoginModal({ open, toggle, theme }) {
  const [openModal, setOpenModal] = useState(false);
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [, setUser] = useState({});
  const [, setIsLoading] = useState(false);
  const { create: login } = useEntity("eat/auth/local");
  const { create: loginByToken } = useEntity("eat/auth/login-by-idt");
  const { create } = useEntity("checkout-session");
  const { clearCart } = useCartStore();
  const location = useLocation();
  const history = useHistory();
  const {
    handleChange,
    errors,
    touched,
    values,
    handleSubmit,
    setFieldValue,
    handleBlur,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    onSubmit: async (values) => {
      if (values.rememberMe) {
        document.cookie = `email=${values.email};path=${FRONTENDURL}`;
        document.cookie = `password=${values.password};path=${FRONTENDURL}`;
      }

      const lowerCaseEmail = values.email.toString().toLowerCase();

      try {
        const r = await login({
          restaurant: restaurant?.userId?._id,
          email: lowerCaseEmail,
          password: values.password,
        });
        if (
          (await DB.storeData(DBKeys.CUSTOMER_USER, r?.data?.user)) &&
          (await DB.storeData(DBKeys.CUSTOMER_TOKEN, r?.data?.accessToken))
        ) {
          clearCart();
          if (restaurant?.enable2fa) {
            window.localStorage.removeItem("otpDoc");

            const checkoutSessionData = await create(r?.data);

            window.localStorage.setItem(
              "checkoutSessionData",
              JSON.stringify(checkoutSessionData)
            );
          }
          authContext.login(r?.data);
        }

        swal("Success!", "Logged in", "success");
      } catch (error) {
        logger.push({ error, info: "LoginModal" });
        swal("Failed!", error.message, "error");
        setIsLoading(false);
      }
    },
    validationSchema: loginSchema,
  });

  const { restaurant } = useOrderingRestaurant();

  useEffect(() => {
    const emailCookie = getCookie("email");
    const passwordCookie = getCookie("password");
    if (emailCookie && passwordCookie) {
      setFieldValue("email", emailCookie);
      setFieldValue("password", passwordCookie);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("customer")) {
      setUser(JSON.parse(localStorage.getItem("customer")));
    }
  }, [open]);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const authContext = useContext(AuthContext);

  const handleLoginByToken = async (token) => {
    setIsLoading(true);
    await DB.storeData(DBKeys.CUSTOMER_TOKEN, token);
    try {
      const res = await loginByToken({
        restaurant: restaurant._id,
      });
      if (
        (await DB.storeData(DBKeys.CUSTOMER_USER, res?.data?.user)) &&
        (await DB.storeData(DBKeys.CUSTOMER_TOKEN, token))
      ) {
        authContext.login({
          user: res?.data?.user,
          token,
        });

        setIsLoading(false);

        let url = new URL(restaurant?.url);
        if (res?.data?.user?.phone) {
          history.push(url.pathname);
        } else {
          history.push(url.pathname + "/my-account");
        }
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      logger.push({ error, info: "Login" });
      swal(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchParams.has("error")) {
      swal(searchParams.get("error"));
    } else if (searchParams.has("idt")) {
      handleLoginByToken(searchParams.get("idt"));
    }
  }, [searchParams]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={open}
      toggle={toggle}
    >
      <div className="modal-body p-0 ">
        <Card className="border-0 modalcard " style={{ margin: 0, padding: 0 }}>
          <ModalHeader title="Sign In" toggle={toggle} />
          {restaurant?.ordering?.socialLogin && (
            <SocialComponent className="mt-3" />
          )}
          <CardBody className="px-lg-4 pb-lg-3 pt-lg-3">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    handleBlue={handleBlur}
                  />
                </InputGroup>
              </FormGroup>
              {touched.email && errors.email && (
                <ErrorText isValid={false} message={"Email is required"} />
              )}
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    handleBlue={handleBlur}
                    value={values.password}
                  />
                </InputGroup>
              </FormGroup>
              {touched.password && errors.password && (
                <ErrorText isValid={false} message={"Password is required"} />
              )}
              <Row>
                <Col span={12}>
                  <div className="custom-control custom-control-alternative custom-checkbox-primary">
                    <input
                      onChange={handleChange}
                      value={values.rememberMe}
                      name="remeberMe"
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted text-sm">Remember me</span>
                    </label>
                  </div>
                </Col>
                <Col span={12} className="text-right">
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      textAlign: "right",
                      color: theme.primary,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenForgotModal(true);
                    }}
                  >
                    Forgot Password ?
                  </span>
                </Col>
              </Row>

              <div className="text-center">
                <Button
                  className="my-4"
                  style={{ backgroundColor: theme.primary, color: "#fff" }}
                  type="button"
                  onClick={handleSubmit}
                >
                  {isSubmitting ? <Spinner size="sm" /> : "Sign In"}
                </Button>
              </div>

              <div className="text-center navlink">
                <a
                  href="#"
                  style={{ color: theme.primary }}
                  onClick={(e) => {
                    setOpenModal(true);
                    // toggle(e);
                  }}
                >
                  Create Account
                </a>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <CreateAccountModal
        open={openModal}
        toggle={() => setOpenModal(false)}
        theme={theme}
        history={history}
      />
      <ForgotPassword
        history={history}
        open={openForgotModal}
        theme={theme}
        toggle={() => setOpenForgotModal(false)}
      />
    </Modal>
  );
}
