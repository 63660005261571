import classNames from "classnames";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import ErrorLable from "../../../utils/ErrorLable";
import { getSeparatedValues } from "../../../utils/commaSeparator";
import logger from "../../../utils/logger";
import { validationSchemaAddCategory } from "../../../utils/schemas/AddCategory";
import SuperTableDnd from "../../../widgets/SuperTableDnd";
import TableWrapper from "../../../widgets/TableWrapper";
import EmptyPlaceholder from "../../ordering/menu/components/EmptyPlaceholder";
import AvailablityTabItem from "./tabs/AvailablityTabItem";

export const initialAvailability = [
  {
    day: "Monday",
    startDate: "",
    endDate: "",
  },
  {
    day: "Tuesday",
    startDate: "",
    endDate: "",
  },
  {
    day: "Wednesday",
    startDate: "",
    endDate: "",
  },
  {
    day: "Thursday",
    startDate: "",
    endDate: "",
  },
  {
    day: "Friday",
    startDate: "",
    endDate: "",
  },
  {
    day: "Saturday",
    startDate: "",
    endDate: "",
  },
  {
    day: "Sunday",
    startDate: "",
    endDate: "",
  },
];

const CategoryItemRow = ({ data, ...props }) => {
  return (
    <tr {...props} ref={props.refDoc} className="text-left">
      <th>{data?.name}</th>
      <td>${data?.price.toFixed(2)}</td>
      <td>{data?.sortOrder}</td>
      <td>
        {" "}
        {getSeparatedValues(
          data?.assignedToLocations?.slice(0, 3)?.map((item) => item?.name)
        )}
        {data?.assignedToLocations.length > 3 && "..."}
      </td>
    </tr>
  );
};

const AddCategory = (props) => {
  let history = props.history;
  const locationID = useLocation();
  let id = new URLSearchParams(locationID.search).get("id");
  const { create: createCategory, updateEntity } = useEntity("category");
  const [tabs, setTabs] = useState(1);
  const authContext = useContext(AuthContext);
  const { restaurant } = useAdminStore();

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { findOne, entity } = useEntity("category");
  const { find: findLoc, entities: location } = useEntity("location");
  const { find, entities } = useEntity("tax-rate");
  const { find: findTags, entities: tags } = useEntity("tag");
  const { find: findItems, entities: items } = useEntity("item");
  const [data, setData] = useState([]);
  const [isSortOrderChanged, setIsSortOrderChanged] = useState(false);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { create: updateSortOrder } = useEntity("item/sort-order");
  const { findOne: findRestaurant, entity: restaurantDoc } =
    useEntity("restaurant");

  useEffect(() => {
    findRestaurant(authRest || restaurant);
  }, []);

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData([...items]);
    setIsSortOrderChanged(true);
  };

  const handleSaveSortOrder = async () => {
    const IdsAndPos = data.map((d, idx) => {
      const obj = { _id: d._id, sortOrder: idx };
      return obj;
    });

    try {
      await updateSortOrder({ sortOrders: IdsAndPos });
      toast.success("Sort order updated");
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
    setIsSortOrderChanged(false);
  };

  useEffect(() => {
    if (props.location.state) {
      findOne(props.location?.state);
    }
  }, [props.location.state]);

  useEffect(() => {
    findLoc({
      resturant: authRest || restaurant,
    });

    find({
      resturant: authRest || restaurant,
    });
    findTags({
      restaurant: authRest || restaurant,
    });
    if (props.location.state) {
      findItems({
        restaurant: authRest || restaurant,
        categories: props.location.state,
      });
    }
  }, [restaurant]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      restaurant: authRest || restaurant,
      categoryName: "",
      description: "",
      isAvailableDaily: true,
      methodChosen: "all",
      status: "enabled",
      availability: [],
      location: [{ label: "All", value: "all" }],
      taxRate: "",
      catering: false,
      isAlcohol: false,
      sortOrder: 0,
      tag: [],
      printerId: null,
      allLocationsSelected: false,
      assignedMac: [],
    },
    validationSchema: validationSchemaAddCategory,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const locationValues = values?.location?.map((val) => val?.value);

      if (locationValues.includes("all")) {
        values.allLocationsSelected = true;
      } else {
        values.allLocationsSelected = false;
      }

      const dat = {
        restaurant: authRest || restaurant,
        categoryName: values.categoryName,
        description: values?.description,
        printerId: values.printerId,
        type: "category",
        subCategories: [],
        methodChosen: values.methodChosen,
        status: values.status,
        assignedMac: values.assignedMac.map((a) => a.value),
        location: values?.location?.map((it) => {
          return it?.value;
        }),
        tag: values?.tag?.map((it) => {
          return it?.value;
        }),
        availability: values.isAvailableDaily ? [] : values.availability,
        isAvailableDaily: values.isAvailableDaily,
        taxRate: values?.taxRate || entities?.data?.[0]?._id,
        catering: values?.catering,
        isAlcohol: values?.isAlcohol,
        sortOrder: values?.sortOrder,
        allLocationsSelected: values?.allLocationsSelected,
      };
      try {
        if (props.location.state) {
          await updateEntity(props.location.state, {
            ...dat,
          });
          toast.success("Category updated successfully");
          history.goBack();
        } else {
          await createCategory({ ...dat, source: "local" });

          toast.success("Category created successfully");
          history.goBack();
        }
        resetForm();
      } catch (error) {
        logger.push({ error, info: "AddCategory" });
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        restaurant: authRest || restaurant,
        categoryName: entity?.data?.categoryName,
        description: entity?.data?.description,
        printerId: entity?.data?.printerId,
        methodChosen: entity?.data?.methodChosen,
        isAvailableDaily: entity?.data?.isAvailableDaily,
        status: "enabled",
        location: entity?.data?.location?.map((it) => {
          return { label: it?.name, value: it?._id };
        }),
        tag: entity?.data?.tag?.map((it) => {
          return { label: it?.name, value: it?._id };
        }),
        availability:
          entity?.data?.availability?.length > 0
            ? entity?.data?.availability
            : initialAvailability,
        taxRate: entity?.data?.taxRate?._id,
        catering: entity?.data?.catering,
        isAlcohol: entity?.data?.isAlcohol,
        assignedMac:
          entity?.data?.assignedMac?.map((a) => {
            return { label: a, value: a };
          }) || [],
        sortOrder: entity?.data?.sortOrder,
        allLocationsSelected: entity?.data?.allLocationsSelected,
      });
      if (entity?.data?.allLocationsSelected) {
        setFieldValue(
          "location",
          [{ name: "All", _id: "all" }, ...entity?.data?.location]?.map(
            (location) => {
              return {
                label: location?.name,
                value: location?._id,
              };
            }
          )
        );
      }
    }
  }, [entity]);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Name", "Price", "Sort", "Location"];

  const getMacOptions = () => {
    if (location?.data?.data?.length <= 0 || !location?.data?.data) return [];
    const locationIds = values.location.map((l) => l.value);
    const array = [...location?.data?.data]
      .filter((it) => locationIds?.includes(it._id))
      .flatMap((it) => {
        return it?.categoryMacAddress?.map((cat) => {
          return {
            label: cat,
            value: cat,
          };
        });
      });

    console.log("ARRAY", array, values.location);
    return array || [];
  };

  useEffect(() => {
    if (items?.data?.data) {
      setData(items?.data?.data);
    }
  }, [items?.data?.data]);

  return (
    <div>
      <TimelineHeader
        name={id ? "Edit Category" : "Add Category"}
        parentName="Categories"
        showBtn={true}
        path="/resturants/categories"
      >
        <>
          <Button
            size="md"
            className="btn-icon btn-2"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner size="sm" color="white" /> : "Save"}
          </Button>
        </>

        <Button
          className="btn-neutral text-black"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back
        </Button>
      </TimelineHeader>

      <TableWrapper customStyles="px-3 py-3">
        <Nav
          className="nav-fill flex-column flex-md-row "
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classNames("mb-sm-3 mb-md-0", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              General
            </NavLink>
          </NavItem>
          {entity && (
            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classNames("mb-sm-3 mb-md-0", {
                  active: tabs === 2,
                })}
                onClick={(e) => toggleNavs(e, 2)}
                href="#pablo"
                role="tab"
              >
                <i
                  className={
                    "fa fa-cutlery mr-2" +
                    ` ${tabs === 2 ? "text-white" : "text-primary"}`
                  }
                  style={{ fontSize: 16 }}
                  aria-hidden="true"
                />
                Items
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              aria-selected={tabs === 3}
              className={classNames("mb-sm-3 mb-md-0", {
                active: tabs === 3,
              })}
              onClick={(e) => toggleNavs(e, 3)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Availablity
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="mt-5" activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <Container fluid>
              <Form>
                <FormGroup>
                  <Row>
                    <Col md="4">
                      <label style={{ fontSize: 15, fontWeight: "500" }}>
                        Category Name
                      </label>
                    </Col>
                    <Col md="8">
                      <Input
                        multiple=""
                        type="text"
                        onChange={handleChange("categoryName")}
                        value={values?.categoryName}
                        className={`form-control ${
                          errors.categoryName && touched.categoryName
                            ? "is-invalid"
                            : ""
                        }`}
                      ></Input>
                      {errors.categoryName && touched.categoryName && (
                        <ErrorLable message={errors.categoryName} />
                      )}
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row className="my-2">
                    <Col md="4">
                      <label style={{ fontSize: 15 }}>Description</label>
                    </Col>
                    <Col md="8">
                      <Input
                        rows="5"
                        type="textarea"
                        onChange={handleChange("description")}
                        value={values?.description}
                        className={`form-control ${
                          errors.description && touched.description
                            ? "is-invalid"
                            : ""
                        }`}
                      ></Input>
                      {errors.description && touched.description ? (
                        <small className="text-red">{errors.description}</small>
                      ) : (
                        <small className={`${"text-muted"}`}>
                          Brief description of the category*
                        </small>
                      )}
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="row">
                  <Col md="4">
                    <label style={{ fontSize: 15, fontWeight: "500" }}>
                      Tax Rate
                    </label>
                  </Col>
                  <Col md="8">
                    <Input
                      type="select"
                      onChange={handleChange("taxRate")}
                      value={values?.taxRate || entities?.data?.[0]?._id}
                      className={`form-control ${
                        errors.taxRate && touched.taxRate ? "is-invalid" : ""
                      }`}
                    >
                      {entities?.data?.map((tax) => {
                        return <option value={tax?._id}>{tax?.name}</option>;
                      })}
                    </Input>
                    {errors.taxRate && touched.taxRate && (
                      <ErrorLable message={errors.taxRate} />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col md="4">
                    <label style={{ fontSize: 15, fontWeight: "500" }}>
                      Method Availability
                    </label>
                  </Col>
                  <Col md="8">
                    <Input
                      multiple=""
                      type="select"
                      onChange={handleChange("methodChosen")}
                      value={values?.methodChosen}
                      className={`form-control ${
                        errors.methodChosen && touched.methodChosen
                          ? "is-invalid"
                          : ""
                      }`}
                    >
                      <option value="">Select Method</option>
                      <option value="pickup">Pickup</option>
                      <option value="delivery">Delivery</option>
                      <option value="walkup">Walk Up</option>
                      <option value="dine-in">Dine In</option>
                      <option value="all">All</option>
                    </Input>
                    {errors.methodChosen && touched.methodChosen && (
                      <ErrorLable message={errors.methodChosen} />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Row className="my-2">
                    <Col md="4">
                      <label style={{ fontSize: 15 }} htmlFor="catering">
                        Catering
                      </label>
                    </Col>
                    <Col md="8">
                      <>
                        <label className="custom-toggle">
                          <input
                            onChange={handleChange("catering")}
                            value={values?.catering}
                            checked={values?.catering}
                            type="checkbox"
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row className="my-2">
                    <Col md="4">
                      <label style={{ fontSize: 15 }} htmlFor="isAlcohol">
                        Is Alcohol
                      </label>
                    </Col>
                    <Col md="8">
                      <>
                        <label className="custom-toggle">
                          <input
                            onChange={handleChange("isAlcohol")}
                            value={values?.isAlcohol}
                            checked={values?.isAlcohol ? true : false}
                            type="checkbox"
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md="4">
                      <label style={{ fontSize: 15, fontWeight: "500" }}>
                        Sort Order
                      </label>
                    </Col>
                    <Col md="8">
                      <Input
                        type="number"
                        onChange={handleChange("sortOrder")}
                        value={values?.sortOrder}
                      ></Input>
                      {errors.sortOrder && touched.sortOrder && (
                        <ErrorLable message={errors.sortOrder} />
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup className="row">
                  <Col md="4">
                    <label style={{ fontSize: 15, fontWeight: "500" }}>
                      Assigned Location
                    </label>
                  </Col>
                  <Col md="8">
                    <Select
                      isMulti
                      name="location"
                      isSearchable={false}
                      onChange={(val) => setFieldValue("location", val)}
                      options={
                        location?.data?.data?.length > 0
                          ? [
                              { name: "All", _id: "all" },
                              ...location?.data?.data,
                            ].map((it) => {
                              return {
                                label: it?.name,
                                value: it?._id,
                              };
                            })
                          : []
                      }
                      value={values?.location}
                    />
                    {errors.location && touched.location && (
                      <ErrorLable message={errors.location} />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md="4">
                      <label style={{ fontSize: 15 }} htmlFor="productName">
                        Assigned Printer
                      </label>
                    </Col>
                    <Col md="8">
                      <Select
                        isMulti
                        name="assingedMac"
                        isSearchable={false}
                        onChange={(val) => setFieldValue("assignedMac", val)}
                        options={getMacOptions()}
                        value={values?.assignedMac}
                      />
                      {errors.assignedMac && touched.assignedMac && (
                        <ErrorLable message={errors.assignedMac} />
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                {restaurantDoc?.data?.restaurant?.enableJKSoft && (
                  <FormGroup>
                    <Row>
                      <Col md="4">
                        <label style={{ fontSize: 15 }} htmlFor="productName">
                          Printer ID
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          placeholder="Printer ID"
                          name="printerId"
                          className={`form-control ${
                            errors.printerId && touched.printerId
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          onChange={handleChange}
                          value={values?.printerId}
                        ></Input>
                        {errors.printerId && touched.printerId ? (
                          <small className="text-red">{errors.printerId}</small>
                        ) : (
                          <small>Printer ID For JKSoft</small>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                )}
                <FormGroup>
                  <Row className="my-2">
                    <Col md="4">
                      <label style={{ fontSize: 15, fontWeight: "500" }}>
                        Tag
                      </label>
                    </Col>
                    <Col md="8">
                      <Select
                        onChange={(val) => setFieldValue("tag", val)}
                        isMulti
                        name="tag"
                        isSearchable={false}
                        closeMenuOnSelect={false}
                        options={tags?.data?.map((loca) => {
                          return {
                            label: loca?.name,
                            value: loca?._id,
                          };
                        })}
                        value={values?.tag}
                      />
                      {errors.tag && touched.tag && (
                        <ErrorLable message={errors.tag} />
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </Form>
            </Container>
          </TabPane>
          <TabPane tabId="tabs2">
            {isSortOrderChanged && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                }}
                className="mr-3 mt-3"
              >
                <Button
                  onClick={handleSaveSortOrder}
                  outline
                  color="warning"
                  size="sm"
                >
                  Save
                </Button>
              </div>
            )}
            {props?.location?.state ? (
              <SuperTableDnd
                sort={handleSort}
                showCheckboxes={false}
                data={data}
                onPageChange={handlePageChange}
                OnRowPerPageChange={handleRowsPerPageChange}
                total={data?.length}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                loading={false}
                history={props.history}
                row={CategoryItemRow}
                columns={Headers}
                isLoading={false}
                emptyMessage="Currently no Items"
                prevPage={data?.prevPage}
                nextPage={data?.nextPage}
                currentPage={data?.currentPage}
                handleDragEnd={handleDragEnd}
              />
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EmptyPlaceholder text="Currently no items assigned to this category" />
              </div>
            )}
          </TabPane>
          <TabPane tabId="tabs3">
            <AvailablityTabItem
              isAvailableDaily={values.isAvailableDaily}
              onIsAvailableDailyChange={(val) =>
                setFieldValue("isAvailableDaily", val)
              }
              onChangeSlots={(slots) =>
                setFieldValue("availability", [...slots])
              }
              slots={values.availability}
            />
          </TabPane>
        </TabContent>
      </TableWrapper>
    </div>
  );
};

export default AddCategory;
