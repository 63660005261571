import moment from "moment";
import { useCallback, useContext, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { FaTimes } from "react-icons/fa";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import ConfirmModal from "../../components/ConfirmModal";
import SimpleHeader from "../../components/Header/SimpleHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useAdminStore } from "../../store/admin";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import { TableRow } from "./components/TableRow";
import { toFixed } from "../../utils/toFixed";
import { exportAsCSV } from "../../utils/expoAsCSV";

function Snapshot(props) {
  const [open, setOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const authPartner = authContext?.user?._id;
  const { partner } = useAdminStore();

  const { entities, loading, find } = useEntity("reports/partner/orders");
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateRange, setDateRange] = useState({
    from: moment().startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  });
  const [showDate, setShowDate] = useState(false);

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };
    // bug in date-range lib
    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setDateRange(range);
  };

  const selectionRange = {
    startDate: dateRange.from,
    endDate: dateRange.to,
    key: "selection",
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Restaurant", "Order Fee", "New Customers", "Orders"];

  const handleExport = useCallback(() => {
    const data = entities?.data?.map((data, idx) => ({
      Restaurant: data?.restaurantName,
      "Order Fee": toFixed(data?.totalOrderFee),
      "New Customers": toFixed(data?.newCustomers),
      Orders: toFixed(data?.totalOrders),
    }));
    exportAsCSV({ arrayOfObject: data, fileName: "order-report.csv" });
  }, [entities?.data]);

  const runReport = () => {
    find({
      partner: partner || authPartner,
      date: {
        ...dateRange,
      },
    });
  };

  return (
    <>
      <ConfirmModal open={open} toggle={() => setOpen(!open)} />
      <SimpleHeader name="Order Report" parentName="Dashboard" showBtn={true}>
        <Button onClick={() => handleExport()} color="primary" size="md">
          CSV
        </Button>
      </SimpleHeader>

      <TableWrapper customStyles="px-4 py-3">
        <Row>
          <Col md="3">
            <FormGroup className="text-left">
              <label className="text-left">Date</label>
              <div
                className="rounded"
                style={{
                  border: "1px solid hsl(0, 0%, 80%)",
                  height: "40px",
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setShowDate(!showDate)}
              >
                {moment(dateRange.from).format("MM/DD/YYYY")} -{" "}
                {moment(dateRange.to).format("MM/DD/YYYY")}
              </div>
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="text-left">
              <label></label>
              <div>
                <Button style={{ marginTop: 5 }} onClick={runReport}>
                  Run Report
                </Button>
              </div>
            </FormGroup>
          </Col>
          {showDate && (
            <div
              className="px-3 py-3 shadow"
              style={{
                position: "absolute",
                zIndex: 999,
                backgroundColor: "#fff",
                cursor: "pointer",
                right: 150,
                top: 100,
              }}
            >
              <FaTimes
                className="mb-5"
                color="black"
                onClick={() => setShowDate(!showDate)}
              />
              <DateRangePicker
                ranges={[selectionRange]}
                rangeColors={["#0FCDEF"]}
                onChange={handleSelectDates}
                moveRangeOnFirstSelection={false}
                inputRanges={[]}
              />
            </div>
          )}
        </Row>
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRow}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no data available"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}

export default Snapshot;
