import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Button, Card, Col, Input, Row, Spinner, Table } from "reactstrap";
import swal from "sweetalert";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
import EmptyPlaceholder from "../../../ordering/menu/components/EmptyPlaceholder";
import AddCustomHoursModal from "../components/CustomHoursModal";

const CustomHours = ({ onSelect }) => {
  const authContext = useContext(AuthContext);
  const [showAdd, setShowAdd] = useState(false);
  const { find, entities, loading } = useEntity("location");
  const {
    find: findCustomHour,
    entities: customHours,
    deleteEntity: deleteCustomHour,
  } = useEntity("restaurant/custom-hour");
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const formik = useFormik({
    initialValues: {
      selectedLocation: "",
      hoursOfOperation: [],
      selectedDays: [],
      pickupFrom: "",
      pickupTo: "",
      deliveryFrom: "",
      deliveryTo: "",
      openModal: false,
      method: "",
      groupedHours: [],
      activeIndex: -1,
    },
    onSubmit: (values, { setValues }) => {
      if (
        values.selectedDays?.length <= 0 ||
        (values.pickupTo === "" &&
          values.pickupFrom === "" &&
          values.deliveryTo === "" &&
          values.deliveryTo === "")
      ) {
        swal({
          title: "Please select all the required fields",
          icon: "warning",
          dangerMode: true,
        });
        return;
      }
      const ops = values.selectedDays.map((day) => {
        return {
          day,
          pickupTo: values.pickupTo,
          pickupFrom: values.pickupFrom,
          deliveryTo: values.deliveryTo,
          deliveryFrom: values.deliveryFrom,
        };
      });

      setValues({
        ...values,
        hoursOfOperation: [...values.hoursOfOperation, ...ops],
        selectedDays: [],
        pickupTo: "",
        pickupFrom: "",
        deliveryTo: "",
        deliveryFrom: "",
      });
    },
  });

  const { findOne, entity } = useEntity("location");

  useEffect(() => {
    find({
      resturant: authRest || restaurant,
    });
    if (formik.values.selectedLocation) {
      findOne(
        formik.values.selectedLocation !== "" && formik.values.selectedLocation
      );
    }
  }, [authRest, formik.values.selectedLocation]);

  useEffect(() => {
    if (entity) {
      formik.setFieldValue("hoursOfOperation", entity?.data?.hoursOfOperation);
    }
  }, [entity, formik.values.selectedLocation]);

  useEffect(() => {
    if (formik.values.selectedLocation) {
      findCustomHour({ location: formik.values.selectedLocation });
    }
  }, [formik.values.selectedLocation]);

  const handleScheduleChange = (schedule) => {
    const { from, to } = schedule;
    if (formik.values.method === "pickup") {
      formik.setValues({
        ...formik.values,
        pickupFrom: from?.value,
        pickupTo: to?.value,
        deliveryFrom: formik.values.deliveryFrom,
        deliveryTo: formik.values.deliveryTo,
      });
    } else {
      formik.setValues({
        ...formik.values,
        pickupFrom: formik.values.pickupFrom,
        pickupTo: formik.values.pickupTo,
        deliveryFrom: from?.value,
        deliveryTo: to?.value,
      });
    }
  };

  useEffect(() => {
    if (entities?.data?.data?.length > 0) {
      formik.setFieldValue("selectedLocation", entities?.data?.data?.[0]?._id);
    }
  }, [entities]);

  return (
    <>
      {!loading ? (
        <Card className="shadow-none">
          <Row>
            <Col
              md="4"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "right",
                fontWeight: "bolder",
              }}
            >
              Locations
            </Col>
            <Col
              md="8"
              className={isMobile && "my-2"}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Input
                onChange={(e) => {
                  formik.setFieldValue("selectedLocation", e.target.value);
                }}
                type="select"
                value={formik.values.selectedLocation}
              >
                <option value={null}>Select</option>
                {entities?.data?.data?.map((item) => {
                  return (
                    <option key={item?._id} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="4"></Col>
            <Col md="8" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => {
                  setShowAdd(true);
                }}
                size="sm"
                type="submit"
              >
                Add
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Date</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customHours?.data?.map((customHour) => {
                    return (
                      <tr key={1}>
                        <td>{customHour?.title}</td>
                        <td>{moment(customHour?.date).format("MM/DD/YYYY")}</td>
                        <td>{customHour?.from}</td>
                        <td>{customHour?.to}</td>
                        <td>
                          <Button
                            onClick={async () => {
                              await deleteCustomHour(customHour?._id);
                              swal("Success", "Custom Hour Deleted", "success");
                            }}
                            size="sm"
                            type="button"
                            color="danger"
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {customHours?.data?.length === 0 && (
                <center>
                  <EmptyPlaceholder text={"No Data"} />
                </center>
              )}
            </Col>
          </Row>
        </Card>
      ) : (
        <div>
          <Spinner />
        </div>
      )}

      <AddCustomHoursModal
        isVisible={showAdd}
        toggle={() => setShowAdd(false)}
        selectedLocation={formik.values.selectedLocation}
      />
    </>
  );
};

export default CustomHours;
