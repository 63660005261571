import classnames from "classnames";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
// reactstrap components
import { FaFileDownload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  Button,
  CardBody,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useUploadImage } from "../../../hooks/useUploadImage";
import { useAdminStore } from "../../../store/admin";
import { validationSchemaSettings } from "../../../utils/schemas/Settings";
import TableWrapper from "../../../widgets/TableWrapper";
import ModalHeader from "../../ordering/components/modals/ModalHeader";
import Privacy from "../../partners/tabs/Privacy";
import Integrations from "../menu/Integrations";
import PaymentGateway from "./PaymentGateway";
import TaxRates from "./TaxRates";
import AddTaxRateModal from "./components/AddTaxModalRate";
import Account from "./tabs/Account";
import Hours from "./tabs/Hours";
import Locations from "./tabs/Locations";
import Ordering from "./tabs/Ordering";
import { useRestaurantStore } from "../../../store/restaurant";
import MQTTLogs from "./MQTTLogs";

export default function Settings(props) {
  const historyData = useLocation();
  const [tabs, setTabs] = useState(historyData?.state?.activeTab || 1);
  window.history.replaceState({}, document.title);
  const [submitting, setSubmitting] = useState(false);
  const [orderingData, setOrderingData] = useState({});
  const authContext = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const [visibleRate, setVisibleRate] = useState(false);
  const [hoursData, setHoursData] = useState();
  const { updateEntity: locationUpdate } = useEntity("location");
  const { create: importXlsx } = useEntity("import");
  const { findOne: findBlockList, entity: list } = useEntity("blocklist");
  const [isImporting, setIsImporting] = useState(false);
  const [updateHoursLoading, setUpdateHoursLoading] = useState(false);
  const { restaurant: restaurantDoc, setRestaurant } = useRestaurantStore();

  const { upload } = useUploadImage();
  const [importFile, setImportFile] = useState(null);

  const {
    findOne,
    entity: settings,
    loading: isLoadingRest,
  } = useEntity("restaurant");

  useEffect(() => {
    findOne(authRest || restaurant);
  }, []);

  useEffect(() => {
    findBlockList(authRest || restaurant);
  }, [authRest, restaurant]);

  const handleUploadFile = async () => {
    setIsImporting(true);
    try {
      const res = await upload({
        file: importFile,
        owner: "restaurant",
      });
      await importXlsx({ restaurantId: restaurant, url: res });
      toast.success("Import Started. You'll recieve email when done.");
      setIsOpen(false);
    } catch (error) {
      console.log("Error uploading file. Please try again");
    }
    setIsImporting(false);
  };
  const { updateEntity } = useEntity("restaurant");
  const { updateEntity: updateEmailList } = useEntity("blocklist");
  const {
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    setValues,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      account_name: "",
      account_address: "",
      account_email: "",
      master_order_email: "",
      owner_name: "",
      order_partnerUrl: "",
      order_username: "",
      order_partner: "",
      order_password: "",
      order_contact: "",
      order_email: "",
      order_homelink: "",
      enable_deliverect: false,
      enableJKSoft: false,
      hideModifierTitleJKSoft: false,
      clientId: false,
      JKSoftLoginId: "",
      webhookSecret: "",
      deliverect_client_secret: "",
      deliverect_client_id: "",
      terms: "",
      privacy: "",
      externalLinkId: "",
      privacyUrl: "",
      termsUrl: "",
      enableStreamPOS: false,
      enableStreamDSP: false,
      streamClientId: "",
      streamClientSecret: "",
      blockedEmails: "",
      username: "",
      password: "",
      host: "",
      port: "",
      enableMenuocity: false,
      uberDelivery: "",
      doorDashDelivery: "",
      grubHubDelivery: "",
    },
    validationSchema: validationSchemaSettings,
    onSubmit: async (values) => {
      const data = {
        name: values.account_name,
        shopTitle: values.account_name,
        email: values.account_email,
        privacy: values?.privacy,
        terms: values?.terms,
        deliverect_client_secret: values.deliverect_client_secret,
        deliverect_client_id: values.deliverect_client_id,
        enable_deliverect: values.enable_deliverect,
        owner_name: values.owner_name,
        master_order_email: values.master_order_email,
        enableJKSoft: values.enableJKSoft,
        hideModifierTitleJKSoft: values.hideModifierTitleJKSoft,
        JKSoftLoginId: values.JKSoftLoginId,
        privacyUrl: values?.privacyUrl,
        termsUrl: values?.termsUrl,
        stream: {
          enableStreamPOS: values?.enableStreamPOS,
          enableStreamDSP: values?.enableStreamDSP,
        },
        enableMenuocity: values?.enableMenuocity,
        mqtt: {
          host: values?.host,
          port: values?.port,
          username: values?.username,
          password: values?.password,
        },
        providerDeliveryMethod: {
          grubHub: values?.grubHubDelivery,
          doorDash: values?.doorDashDelivery,
          uber: values?.uberDelivery,
        },
      };

      try {
        await updateEmailList(authRest || restaurant, {
          data: values.blockedEmails,
        });
        const res = await updateEntity(authRest || restaurant, { ...data });
        toast.success("Restaurant Updated Successfully");
        setRestaurant({ ...restaurantDoc, restaurant: res.data });
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const handleSubmitOrdering = async (data) => {
    setSubmitting(true);

    const schema = Yup.object().shape({
      pickupPrepTime: Yup.number()
        .moreThan(0, "Pikcup prep time can't be negative")
        .required("Required"),
    });

    try {
      await schema.validate(orderingData);
      let imagePath = "";
      if (
        typeof orderingData?.headerImage !== "string" &&
        orderingData.headerImage
      ) {
        imagePath = await upload({
          file: orderingData?.headerImage,
          owner: "restaurant",
          method: "cover",
          width: 1980,
          height: 850,
        });
      }
      await updateEntity(authRest || restaurant, {
        showModifierName: orderingData?.showModifierName || false,
        enableSMSForExternalOrder:
          orderingData?.enableSMSForExternalOrder || false,
        ordering: {
          ...orderingData,
          headerImage:
            typeof orderingData?.headerImage !== "string"
              ? imagePath
              : orderingData?.headerImage,
        },
      });
      toast.success("Updated Successfully");
      setTabs(3);
    } catch (error) {
      toast.error(error.message);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (settings) {
      const doc = {
        account_name: settings?.data?.restaurant?.name,
        account_address: settings?.data?.restaurant?.address,
        account_email: settings?.data?.restaurant?.email,
        owner_name: settings?.data?.restaurant?.owner_name,
        master_order_email: settings?.data?.restaurant?.master_order_email,
        privacy: settings?.data?.restaurant?.privacy || "",
        terms: settings?.data?.restaurant?.terms || "",
        order_username: settings?.data?.restaurant?.username,
        enable_deliverect: settings?.data?.restaurant?.enable_deliverect,
        deliverect_client_secret:
          settings?.data?.restaurant?.deliverect_client_secret,
        deliverect_client_id: settings?.data?.restaurant?.deliverect_client_id,
        enableJKSoft: settings?.data?.restaurant?.enableJKSoft,
        hideModifierTitleJKSoft:
          settings?.data?.restaurant?.hideModifierTitleJKSoft,
        JKSoftLoginId: settings?.data?.restaurant?.JKSoftLoginId,
        clientId: settings?.data?.restaurant?.JKSoftClientId,
        externalLinkId: settings?.data?.restaurant?.externalLinkId,
        privacyUrl: settings?.data?.restaurant?.privacyUrl,
        termsUrl: settings?.data?.restaurant?.termsUrl,
        enableStreamPOS: settings?.data?.restaurant?.stream?.enableStreamPOS,
        enableStreamDSP: settings?.data?.restaurant?.stream?.enableStreamDSP,
        uberDelivery: settings?.data?.restaurant?.providerDeliveryMethod?.uber,
        doorDashDelivery:
          settings?.data?.restaurant?.providerDeliveryMethod?.doorDash,
        grubHubDelivery:
          settings?.data?.restaurant?.providerDeliveryMethod?.grubHub,
        host: settings?.data?.restaurant?.mqtt?.host,
        port: settings?.data?.restaurant?.mqtt?.port,
        username: settings?.data?.restaurant?.mqtt?.username,
        password: settings?.data?.restaurant?.mqtt?.password,
        enableMenuocity: settings?.data?.restaurant?.enableMenuocity,
      };

      setValues({ ...doc });
    }
  }, [settings]);

  useEffect(() => {
    setFieldValue("blockedEmails", list?.data);
  }, [list]);

  const handleHoursDetails = async () => {
    try {
      setUpdateHoursLoading(true);

      await locationUpdate(hoursData?.location, {
        hoursOfOperation: hoursData?.timings,
      });
      setUpdateHoursLoading(false);

      toast.success("Hours updated successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (isLoadingRest) {
    return (
      <CardBody>
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      </CardBody>
    );
  }

  console.log(errors);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <>
      <AddTaxRateModal
        isVisible={visibleRate}
        toggle={() => {
          setVisibleRate(false);
        }}
      />

      <TimelineHeader
        path="/resturants"
        name="Settings"
        parentName="Dashboard"
        showBtn={true}
      >
        {(tabs === 1 || tabs === 4) && (
          <>
            <Button color="primary" size="md" onClick={handleSubmit}>
              {isSubmitting ? <Spinner size="sm" /> : "Save"}
            </Button>

            <Button
              color="secondary"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <FaFileDownload />
            </Button>
          </>
        )}
        {tabs === 2 && (
          <>
            <Button
              className="btn-icon btn-2"
              color="primary"
              type="button"
              size="md"
              onClick={() => {
                props.history.push({
                  pathname: "/resturants/add-location",
                  search: props.location.search,
                });
              }}
            >
              Add new
            </Button>
          </>
        )}
        {tabs === 3 && (
          <>
            <Button color="primary" size="md" onClick={handleSubmitOrdering}>
              {submitting ? <Spinner size="sm" color="white" /> : "Save"}
            </Button>
            <Button
              className="btn-neutral text-black"
              color="default"
              size="md"
              onClick={() => {
                props.history.goBack();
              }}
            >
              Cancel
            </Button>
          </>
        )}
        {tabs === 5 && (
          <Button
            size="md"
            color="primary "
            onClick={() => {
              setVisibleRate(true);
            }}
          >
            Add new
          </Button>
        )}

        {tabs === 6 && (
          <Button
            disabled={!hoursData?.location || hoursData?.location === "Select"}
            onClick={handleHoursDetails}
            size="md"
            color="primary "
          >
            {updateHoursLoading ? <Spinner size="sm" /> : "Save"}
          </Button>
        )}
        {tabs === 7 && (
          <Button
            size="md"
            color="primary "
            onClick={() => {
              props.history.push({
                pathname: "/resturants/payment/create-gateway",
                search: props.location.search,
              });
            }}
          >
            Add New
          </Button>
        )}
      </TimelineHeader>

      <TableWrapper customStyles="px-4 py-3">
        <Nav
          className="nav-fill flex-column flex-sm-row"
          id="tabs-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-user mr-2" />
              Account
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-map-marker mr-2" />
              Locations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 3}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 3,
              })}
              onClick={(e) => toggleNavs(e, 3)}
              href="#pablo"
              role="tab"
            >
              <i
                className={
                  "fa fa-cutlery mr-2" +
                  ` ${tabs === 3 ? "text-white" : "text-primary"}`
                }
                style={{ fontSize: 16 }}
                aria-hidden="true"
              ></i>
              Ordering
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 4}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 4,
              })}
              onClick={(e) => toggleNavs(e, 4)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-archive mr-2" />
              Integrations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 5}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 5,
              })}
              onClick={(e) => toggleNavs(e, 5)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-money mr-2" />
              Taxes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 6}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 6,
              })}
              onClick={(e) => toggleNavs(e, 6)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Hours
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 7}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 7,
              })}
              onClick={(e) => toggleNavs(e, 7)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-credit-card-alt mr-2" />
              Payment
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              aria-selected={tabs === 8}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 8,
              })}
              onClick={(e) => toggleNavs(e, 8)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-credit-card-alt mr-2" />
              Menuocity Dynamic Fee
            </NavLink>
          </NavItem> */}
        </Nav>

        <TabContent
          className="mt-4"
          style={{ minHeight: "50vh" }}
          activeTab={"tabs" + tabs}
        >
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <Account
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
            />
            <Privacy
              errors={errors}
              touched={touched}
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </TabPane>
          <TabPane tabId="tabs2">
            <Locations />
          </TabPane>
          <TabPane tabId="tabs3">
            <Ordering
              errors
              touched
              data={settings?.data}
              onChangeData={(data) => {
                setOrderingData(data);
              }}
            />
          </TabPane>
          <TabPane tabId="tabs4">
            <Integrations
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
            />
          </TabPane>
          <TabPane tabId="tabs5">
            <TaxRates
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
            />
          </TabPane>
          <TabPane tabId="tabs6">
            <Hours onSelect={(val) => setHoursData(val)} />
          </TabPane>
          <TabPane tabId="tabs7">
            <PaymentGateway history={props.history} />
          </TabPane>
          {/* <TabPane tabId="tabs8">
            <MQTTLogs history={props.history} />
          </TabPane> */}
        </TabContent>
        <Modal isOpen={isOpen}>
          <ModalHeader
            toggle={() => setIsOpen(!isOpen)}
            title={"Upload your file"}
          />
          <ModalBody>
            <span>
              You can download sample file from
              <a href="https://biteheist-uploads-dev.s3.us-east-2.amazonaws.com/restaurant/1674222077364-sample.numbers">
                {" "}
                here
              </a>
              . Format must be in same format as sample file.
            </span>
            <Input
              type="file"
              className="mt-4"
              onChange={(e) => {
                setImportFile(e.target.files[0]);
              }}
            />
          </ModalBody>

          <ModalFooter className="mt--2">
            <Button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="bg-red text-white"
            >
              Cancel
            </Button>
            <Button onClick={handleUploadFile} color="primary">
              {isImporting ? <Spinner size={"sm"} /> : "Upload"}
            </Button>
          </ModalFooter>
        </Modal>
      </TableWrapper>
    </>
  );
}
