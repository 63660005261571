/* eslint-disable no-unused-expressions */

import { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import swal from "sweetalert";
import CustomCheckbox from "../../../components/Inputs/CustomCheckbox";
import { ItemOptions } from "../../../components/ProductCard";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import { isStringValid } from "../../../utils/checkValidString";
import { getAdvancedPizzaPrice } from "../../../utils/getAdvancedPizzaPrice";
import getSortedArray from "../../../utils/getSortedArray";
import { truncateString } from "../../../utils/truncate";
import AdvancedPizzaModifiers from "./AdvancedPizzaModifiers";
import ModifierQty from "./ModifierQty";

const CustomOption = ({ innerProps, isDisabled, children }) => {
  const child = children.split("$");

  const str = children;

  const lastDollarIndex = str.lastIndexOf("$");

  const price = lastDollarIndex !== -1 ? str.substring(lastDollarIndex + 1) : 0;

  return (
    <>
      {!isDisabled ? (
        <div
          className="p-2 modifier_select_component"
          style={{ display: "flex", justifyContent: "space-between" }}
          {...innerProps}
        >
          <div>{child[0] === "" ? `$${child[1]}` : child[0]}</div>
          <div>
            {price !== 0 && price !== "0.00" && price !== undefined
              ? `$${Number(price)?.toFixed(2)}`
              : ""}{" "}
          </div>
        </div>
      ) : null}
    </>
  );
};

function requiredDependentModifiers(arr1, arr2) {
  let mergedArr = [];
  for (let item1 of arr1) {
    for (let item2 of arr2) {
      if (item1.parentModifier === item2.parent) {
        mergedArr.push({ ...item1, ...item2 });
      }
    }
  }
  return mergedArr;
}

export default function ViewDishModal({ isOpen, toggle, dish, onAddToCart }) {
  const [addInstructions, setAddInstructions] = useState(false);
  const { theme } = useTheme();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [price, setPrice] = useState();
  const [qty, setQty] = useState(1);
  const { restaurant } = useOrderingRestaurant();
  const [show, setShow] = useState(false);
  const [notes, setNotes] = useState("");
  const [disableButton] = useState(false);
  const [, setPizzaOptions] = useState({});
  const [modifierRequiredError, setRequiredModifierError] = useState([]);
  const [parentType, setParentType] = useState("");
  const [size, setSize] = useState("");
  const selectedRef = useRef();
  const [sumAdvancedPrice, setSumAdvancedPrice] = useState();
  const [isParentChanged, setisParentChanged] = useState(false);

  useEffect(() => {
    setQty(1);
  }, [isOpen]);

  useEffect(() => {
    setPrice(dish?.price);
  }, [dish]);

  useEffect(() => {
    const modifierValues = Object.values(dish?.modifiers || {});
    const wholePizzaModArray = modifierValues.filter(
      (mod) => mod.cloverPOSWhole
    );
    if (wholePizzaModArray.length > 0) {
      wholePizzaModArray.map((wholePizzaMod) => {
        const leftPizzaMod = modifierValues.find(
          (mod) =>
            mod.cloverPOSLeft && mod.mainModifierId === wholePizzaMod.modifierId
        );

        if (!leftPizzaMod) return;
        // enable pizza mod in this
        wholePizzaMod.advancedPizzaOptions = true;
        wholePizzaMod.subProducts.forEach((it) => {
          it.advancedPizzaOptions = true;
          // find half of this sub product by name
          const leftSubMod = leftPizzaMod?.subProducts.find((leftSide) => {
            return leftSide.product_name
              .toLowerCase()
              .includes(it.product_name.toLowerCase());
          });

          it.halfPrice = leftSubMod?.price || 0;
          it.extraPrice = it.price * 2;
        });
      });
    }
  }, [dish?.modifiers]);

  useEffect(() => {
    let basePrice = dish?.price;

    selectedOptions
      ?.filter((t) => !t.isFree)
      ?.map((selected) => {
        if (!selected?.advancedPizzaOptions && !selected.defaultSelected) {
          basePrice =
            parseFloat(basePrice) +
            parseFloat(selected?.price) * parseFloat(selected.qty || 1);
        } else if (!selected?.advancedPizzaOptions) {
          basePrice =
            parseFloat(basePrice) +
            parseFloat(selected?.price) * parseFloat(selected.qty - 1 || 1);
        }
      });

    basePrice = basePrice + Number(sumAdvancedPrice || 0);

    setPrice(basePrice);
  }, [selectedOptions, sumAdvancedPrice]);

  useEffect(() => {
    if (!addInstructions) {
      setNotes("");
    }
  }, [addInstructions]);

  useEffect(() => {
    if (
      getSortedArray(Object.values(dish?.modifiers || {}), "sortOrder")
        ?.length > 0 &&
      selectedOptions?.length > 0
    ) {
      const array = getSortedArray(
        Object.values(dish?.modifiers || {}),
        "sortOrder"
      )
        .filter((op) => op.enableParentModifier)
        .flatMap((modifier) => {
          return getSortedArray(modifier?.subProducts, "sortOrder")
            .filter((mod) => mod?.defaultSelected)
            .flatMap((sub) => {
              const labels = selectedOptions?.map((mp) => mp?.product_name);

              let arr = [];
              if (
                modifier.advancedPizzaOptions &&
                labels.includes(sub?.selectedParentValue?.label) &&
                !labels.includes(sub?.product_name)
              ) {
                arr.push({
                  ...sub,
                  type: "regular",
                  size: "all",
                  price: 0,
                  extraPrice: 0,
                  halfPrice: 0,
                  name: modifier?.name,
                  parent: modifier?.name,
                  sort: Number(modifier?.sortOrder || 0),
                });
              } else {
                if (
                  !modifier.advancedPizzaOptions &&
                  labels.includes(sub?.selectedParentValue?.label) &&
                  !labels.includes(sub?.product_name)
                ) {
                  arr.push({
                    ...sub,
                    price: 0,
                    name: modifier?.name,
                    parent: modifier?.name,
                    sort: Number(modifier?.sortOrder || 0),
                  });
                }
              }
              return arr;
            });
        });

      setSelectedOptions([...selectedOptions, ...array]);
    }
  }, [isParentChanged, dish, isOpen]);

  useEffect(() => {
    let arr = [];
    if (
      getSortedArray(Object.values(dish?.modifiers || {}), "sortOrder")
        ?.length > 0
    ) {
      getSortedArray(Object.values(dish?.modifiers || {}), "sortOrder")
        .filter((mod) => !mod.enableParentModifier)
        .map((modifier) => {
          const parentIndex = Object.values(dish?.modifiers || {}).findIndex(
            (t) => t.name === modifier.name
          );
          const parentModifId = Object.keys(dish?.modifiers || {})[parentIndex];
          const parentModifName = Object.values(dish?.modifiers || {})[
            parentIndex
          ]?.name;
          const overridePLU = Object.values(dish?.modifiers || {})[parentIndex]
            ?.overridePLU;

          getSortedArray(modifier?.subProducts, "sortOrder").map((sub) => {
            sub["name"] = modifier?.name;

            if (sub?.defaultSelected) {
              const label = `${sub?.subModifiers?.[0]?.name}  ${
                sub?.subModifiers?.[0]?.price !== 0
                  ? " - $" + sub?.subModifiers?.[0]?.price
                  : ""
              }`;

              if (sub?.subModifiers?.length > 0) {
                sub["selectedModifier"] = {
                  label: label,
                  value: sub?.subModifiers?.[0]?.price,
                };
              }

              if (modifier?.type === "radio") {
                setSelectedOptions([
                  ...selectedOptions,
                  {
                    ...sub,
                    parent: parentModifName,
                    name: parentModifName,
                    price: 0,
                    sort: Number(modifier?.sortOrder || 0),
                    plu: modifier?.plu,
                    modifierId: parentModifId,
                    overridePLU,
                  },
                ]);
              }

              if (sub?.advancedPizzaOptions) {
                sub["advancedPizzaOptions"] = modifier?.advancedPizzaOptions;
                arr.push({
                  ...sub,
                  type: "regular",
                  size: "all",
                  advancedPizzaOptions: modifier?.advancedPizzaOptions,
                  parent: parentModifName,
                  name: parentModifName,
                  price: 0,
                  extraPrice: 0,
                  halfPrice: 0,
                  sort: Number(modifier?.sortOrder || 0),
                  modifierId: parentModifId,
                  overridePLU,
                });
                setParentType("regular");
                setSize("all");
              } else
                arr.push({
                  ...sub,
                  price: 0,
                  qty: 1,
                  parent: parentModifName,
                  name: parentModifName,
                  sort: Number(modifier?.sortOrder || 0),
                  modifierId: parentModifId,
                  overridePLU,
                });
            }
          });
        });
      setSelectedOptions([...arr]);
    }
    if (arr?.length > 0) {
      setisParentChanged(!isParentChanged);
    }
  }, [dish?.modifiers, isOpen]);

  useEffect(() => {
    const res = getAdvancedPizzaPrice(selectedOptions);
    setSumAdvancedPrice(res);
  }, [selectedOptions, sumAdvancedPrice]);

  if (!dish) {
    return;
  }

  function checkModifiers() {
    const modifiers = dish?.modifiers;
    const modifiersMin = getSortedArray(
      Object.values(modifiers || {}),
      "sortOrder"
    )?.map?.((m, idx) => {
      return {
        type: m.type,
        min: m.min,
        parent: m.name,
        enableQtyUpdate: m.enableQtyUpdate,
        required: m.isRequired,
      };
    });

    const { parentId, success } = hasMinModifiers(
      modifiersMin.filter((m) => m.min)
    );

    if (!success) {
      selectedOptions.find((s) => s.parent === parentId);

      if (!success) {
        toast.error(`Please select minimum modifier for ${parentId}.`);
        return false;
      }
      return false;
    }
    const requiredModifiers = getSortedArray(
      Object.values(modifiers),
      "sortOrder"
    )?.filter(
      (modifier) => modifier?.isRequired && !modifier?.enableParentModifier
    );

    for (const requiredModifier of requiredModifiers) {
      const selectedModifier = selectedOptions.find(
        (option) =>
          requiredModifier.subProducts.findIndex(
            (sp) => sp.product_id === option.product_id
          ) !== -1
      );
      if (!selectedModifier) {
        setRequiredModifierError([
          ...modifierRequiredError,
          requiredModifier.name,
        ]);
        toast.error("All the required modifiers must be selected");
        return false;
      }
    }
    return true;
  }

  function checkDependentRequiredModifiers() {
    const modifiers = dish?.modifiers;

    const modifiersMin = getSortedArray(
      Object.values(modifiers || {}),
      "sortOrder"
    )?.map?.((m, idx) => {
      const modifierGroupId = Object.values(modifiers || {})[idx]?.name;
      return {
        min: m.min,
        parent: modifierGroupId,
        enableQtyUpdate: m.enableQtyUpdate,
        enableParentModifier: m?.enableParentModifier,
      };
    });

    const { parentId, success } = hasMinModifiers(modifiersMin);

    if (!success) {
      if (!success) {
        toast.error(`Please select minimum modifier for ${parentId}.`);
        return false;
      }
      return false;
    }

    const requiredModifiersDependent = getSortedArray(
      Object.values(modifiers),
      "sortOrder"
    )?.filter(
      (modifier) => modifier?.isRequired && modifier?.enableParentModifier
    );

    const reqMod = requiredDependentModifiers(
      requiredModifiersDependent,
      selectedOptions
    );

    if (
      selectedOptions?.length > 0 &&
      requiredModifiersDependent?.length > 0 &&
      reqMod?.length > 0
    ) {
      for (const requiredModifier of reqMod) {
        const selectedModifier = selectedOptions.find(
          (option) =>
            requiredModifier.subProducts.findIndex(
              (sp) => sp.product_id === option.product_id
            ) !== -1
        );

        if (!selectedModifier) {
          setRequiredModifierError([
            ...modifierRequiredError,
            requiredModifier.name,
          ]);
          toast.error("All the required modifiers must be selected");
          return false;
        }
      }
      return true;
    } else return true;
  }

  const handleAddOptions = (item, qty = 1, index = 0) => {
    const findIndex = selectedOptions.findIndex(
      (it) => it.product_id === item.product_id && item.name === it.name
    );

    if (findIndex === -1) {
      const optionsArray = [...selectedOptions, { ...item, qty }];
      const sortOptArray = getSortedArray(optionsArray, "sort");

      setSelectedOptions([...sortOptArray]);
    } else {
      const updatedOptions = [...selectedOptions];
      const sortOptArray = getSortedArray(updatedOptions, "sort");

      sortOptArray[findIndex] = item;

      setSelectedOptions([...updatedOptions]);
    }
  };

  const handleRemoveOption = (item) => {
    if (!item) {
      return;
    }

    const idx = selectedOptions.findIndex(
      (op) => op?.product_name === item?.product_name && item?.name === op?.name
    );

    const [removedModif] = selectedOptions?.splice(idx, 1);
    console.log("===>", removedModif);
    if (removedModif.isFree) {
      const free = selectedOptions.filter(
        (t) => t.parent === removedModif.parent && t.isFree
      );
      console.log("===>", free);
      if (free.length < removedModif.maxFreeToppings) {
        const notFreeIndex = selectedOptions.findIndex(
          (t) => t.parent === removedModif.parent && !t.isFree
        );
        console.log("===>", notFreeIndex);
        selectedOptions[notFreeIndex] = {
          ...selectedOptions[notFreeIndex],
          isFree: true,
          price: 0,
          extraPrice: 0,
          halfPrice: 0,
        };

        console.log("===>", selectedOptions);
      }
    }
    setSelectedOptions([...selectedOptions]);
  };

  const isSelected = (item) => {
    const idx = selectedOptions.findIndex(
      (option) =>
        option.product_id === item.product_id &&
        option.name === item.name &&
        !option?.exchangedWith
    );

    if (idx !== -1) {
      return true;
    }
    return false;
  };

  function countByParentId(parentId) {
    return selectedOptions.reduce(
      (count, item) => (item.parent === parentId ? count + 1 : count),
      1
    );
  }

  function countByParentIdAdvancePizza(parentName) {
    return selectedOptions.reduce(
      (count, item) => (item.parent === parentName ? count + 1 : count),
      1
    );
  }

  function hasMinModifiers(parentMinArray) {
    let allConditionsMet = true;
    let parentId = null;
    for (const {
      parent,
      min,
      enableQtyUpdate,
      type,
      required,
    } of parentMinArray) {
      const count = selectedOptions.filter(
        (item) => item.parent === parent
      )?.length;

      const doc = selectedOptions.filter((item) => item.parent === parent);
      const sumQty = doc.reduce((sum, obj) => sum + (obj.qty || 1), 0);

      if (type === "checkbox" && (required || (!required && count > 0))) {
        if (sumQty < min) {
          return { parentId: parent, success: false };
        }

        if (enableQtyUpdate) {
          if (sumQty === 0 || sumQty >= min) {
          } else if (!min || min === undefined || min < 0) {
          } else {
            parentId = parent;
            allConditionsMet = false;
          }
        } else {
          if (count === 0 || count >= min) {
          } else if (!min || min === undefined || min < 0) {
          } else {
            parentId = parent;
            allConditionsMet = false;
          }
        }
      } else {
        parentId = parent;
        allConditionsMet = true;
      }
    }
    return { parentId, success: allConditionsMet };
  }

  function filterByLabel(arr, label) {
    const filteredArr = [];
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].selectedParentValue?.label === label) {
        filteredArr.push(arr[i]);
      }
    }

    return filteredArr;
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => {
        setSelectedOptions([]);
        setNotes("");
        setAddInstructions(false);
        toggle();
      }}
      style={{
        padding: 0,
        transition: "none",
        animation: "none",
        transitionDelay: "none",
        transform: "none",
        transitionDuration: 0,
        zIndex: 99999,
      }}
    >
      <div
        className="px-2 py-1 rounded"
        onClick={() => {
          setSelectedOptions([]);
          setNotes("");
          setAddInstructions(false);
          toggle();
        }}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "white",
          cursor: "pointer",
          zIndex: 9999999,
        }}
      >
        <FaTimes size={16} color="black" />
      </div>
      {dish?.imageUrl !== "" && (
        <div style={{ height: 300, width: "100%" }}>
          <img
            src={dish?.imageUrl !== "" ? dish?.imageUrl : restaurant?.logo}
            alt="product"
            style={{
              width: "100%",
              height: "100%",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              objectFit: "cover",
              filter: dish?.imageUrl === "" && "grayscale(100)",
              opacity: dish?.imageUrl === "" && "5%",
            }}
          />
        </div>
      )}
      <div className="modal-body">
        <div className={`${dish?.imageUrl ? "" : "mt-3"}`}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                textTransform: "capitalize",
                fontWeight: "600",
                color: "#212121",
              }}
            >
              {dish?.name}
            </h1>
          </span>
          <span>{truncateString(dish?.description, 500)}</span>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ alignItems: "center" }}
        >
          <div className="d-flex align-items-center">
            <div className="plus-icon mt-2 inner-plus">
              <Button
                onClick={() => {
                  if (qty > 1) {
                    setQty(qty - 1);
                  } else {
                    setQty(1);
                  }
                }}
                size="sm"
                className="btn-icon btn-2"
                type="button"
                style={{
                  margin: "none",
                  boxShadow: "none",

                  transform: "none",
                }}
              >
                <span>
                  <i className="fa fa-minus" style={{ fontSize: "16px" }} />
                </span>
              </Button>
              <Button
                style={{
                  margin: "none",
                  boxShadow: "none",

                  transform: "none",
                }}
                size="sm"
              >
                {qty}
              </Button>
              <Button
                size="sm"
                style={{
                  margin: "none",
                  boxShadow: "none",

                  transform: "none",
                }}
                type="button"
                onClick={() => setQty(qty + 1)}
              >
                <span>
                  <i className="fa fa-plus" style={{ fontSize: "16px" }} />
                </span>
              </Button>
            </div>
            <div className=" ml-2">
              <ItemOptions modal={true} size="s" data={dish} />
            </div>
          </div>
          <div className="mt-2">
            {dish?.price > 0 && (
              <h1
                style={{
                  fontWeight: "600",
                  color: "#212121",
                }}
              >
                ${dish?.price?.toFixed(2)}
              </h1>
            )}
          </div>
        </div>

        <hr style={{ marginTop: 20, marginBottom: 20 }} />

        <div className="mt--2 mb-3">
          {dish?.modifiers &&
            getSortedArray(Object.values(dish?.modifiers), "sortOrder")
              ?.filter((t) => {
                return !t.isHidden || t.cloverPOSWhole;
              })
              ?.map((modifier, index) => {
                let {
                  name,
                  subProducts,
                  type,
                  isRequired,
                  advancedPizzaOptions,
                  max,
                  min,
                  parentModifier,
                  enableQtyUpdate,
                  enableParentModifier,
                  sortOrder,
                  modifierId,
                  overridePLU,
                  maxFreeToppings = 0,
                } = modifier;

                const selectedSubProduct = subProducts.find((subProd) =>
                  isSelected(subProd)
                );

                const modifierGroupId = Object.keys(dish?.modifiers)[index];

                const label = selectedOptions?.find(
                  (f) => f.parent === parentModifier
                );

                const selectedSubProductDependent = subProducts.find(
                  (subProd) =>
                    isSelected(subProd) &&
                    label?.product_name === subProd?.selectedParentValue?.label
                );

                return (
                  <>
                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: 20,
                        cursor: "pointer",
                        marginBottom: 12,
                        color: "#322F5C",
                        padding: 0,
                      }}
                      onClick={() => setShow(!show)}
                    >
                      {enableParentModifier &&
                      isStringValid(label?.product_name) &&
                      filterByLabel(
                        getSortedArray(subProducts, "sort"),
                        label?.product_name
                      )?.length > 0
                        ? name
                        : !enableParentModifier
                        ? name
                        : ""}

                      {type === "checkbox" &&
                      min > 0 &&
                      !enableParentModifier ? (
                        <small className="text-red">
                          {" "}
                          {(min > 0 || max > 0) && "("}
                          {min > 0 &&
                            "Min " + min + (max < 0 || (!max ? ")" : ""))}
                          {max <= 99 &&
                          max !== 0 &&
                          max > 0 &&
                          max &&
                          max !== undefined
                            ? " - "
                            : min > 0 || (max > 0 && ")")}
                        </small>
                      ) : (
                        enableParentModifier &&
                        type === "checkbox" &&
                        isStringValid(label?.product_name) &&
                        filterByLabel(
                          getSortedArray(subProducts, "sort"),
                          label?.product_name
                        )?.length > 0 && (
                          <small className="text-red">
                            {min > 0 && " ("}
                            {min > 0 && "Min " + min}
                            {max <= 99 &&
                            max !== 0 &&
                            max > 0 &&
                            max &&
                            max !== undefined &&
                            min > 0
                              ? " - "
                              : min > 0 && ")"}
                          </small>
                        )
                      )}

                      {type === "checkbox" &&
                      max <= 99 &&
                      max !== 0 &&
                      max > 0 &&
                      max &&
                      max !== undefined &&
                      !enableParentModifier ? (
                        <small className="text-red">
                          {min <= 0 && " ("}Max {max})
                        </small>
                      ) : enableParentModifier &&
                        type === "checkbox" &&
                        isStringValid(label?.product_name) &&
                        max <= 99 &&
                        max !== 0 &&
                        max > 0 &&
                        max &&
                        max !== undefined &&
                        filterByLabel(
                          getSortedArray(subProducts, "sort"),
                          label?.product_name
                        )?.length > 0 ? (
                        <small className="text-red">
                          {" "}
                          {min <= 0 && " ("}Max {max})
                        </small>
                      ) : (
                        ""
                      )}

                      {isRequired &&
                      enableParentModifier &&
                      isStringValid(label?.product_name) &&
                      filterByLabel(
                        getSortedArray(subProducts, "sort"),
                        label?.product_name
                      )?.length > 0 ? (
                        <sup className="text-red">*</sup>
                      ) : isRequired && !enableParentModifier ? (
                        <sup className="text-red">*</sup>
                      ) : (
                        ""
                      )}
                    </div>

                    {type === "checkbox" &&
                      !advancedPizzaOptions &&
                      enableParentModifier &&
                      !enableQtyUpdate &&
                      isStringValid(label?.product_name) && (
                        <>
                          {filterByLabel(
                            getSortedArray(subProducts, "sort"),
                            label?.product_name
                          )
                            ?.filter((t) => {
                              const exchangedWith = selectedOptions
                                .map((t) =>
                                  t.exchangedWith ? t?.product_id : null
                                )
                                .filter(Boolean);

                              return !exchangedWith.includes(t.product_id);
                            })
                            ?.map((subProd, index) => {
                              const { product_name, price, product_id } =
                                subProd;

                              const selectedModifiers = selectedOptions.filter(
                                (s) => s.name === name
                              );

                              const maxFree =
                                maxFreeToppings - selectedModifiers?.length;

                              const isFree =
                                selectedOptions.find(
                                  (d) => d.product_id === subProd.product_id
                                )?.isFree || false;

                              const shouldShowPrice =
                                (!isSelected(subProd) && maxFree <= 0) ||
                                (isSelected(subProd) && !isFree);

                              return (
                                <>
                                  <div key={index} className="">
                                    <div
                                      style={{
                                        zIndex: 0,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                        marginTop: 3,
                                      }}
                                    >
                                      <CustomCheckbox
                                        key={product_id}
                                        type={"checkbox"}
                                        id={product_id}
                                        name={product_id}
                                        value={JSON.stringify(subProd)}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            if (
                                              Number(max) > 0 &&
                                              countByParentId(name) >
                                                Number(max) &&
                                              max
                                            ) {
                                              toast.error(
                                                `You can select ${max} maximum.`
                                              );
                                              return;
                                            }
                                            subProd["parent"] = name;
                                            subProd["name"] = name;
                                            subProd["enableParentModifier"] =
                                              enableParentModifier;
                                            handleAddOptions({
                                              ...subProd,
                                              name: name,
                                              parent: name,
                                              sort: Number(sortOrder || 0),
                                              ...(!shouldShowPrice && {
                                                price: 0,
                                              }),
                                              isFree: !shouldShowPrice,
                                            });
                                          } else {
                                            handleRemoveOption({
                                              ...subProd,
                                              parent: name,
                                              name: name,
                                              ...(!shouldShowPrice && {
                                                price: 0,
                                              }),
                                              isFree: !shouldShowPrice,
                                            });
                                          }
                                        }}
                                        shape="curve"
                                        color="danger-o"
                                        animation="smooth"
                                        bigger
                                        checked={isSelected({
                                          ...subProd,
                                          parent: name,
                                          name: name,
                                        })}
                                        style={{
                                          marginBottom: 10,
                                          fontSize: 15,
                                          fontWeight: "400",
                                        }}
                                      />
                                      <label for={product_id}></label>
                                      <span
                                        style={{
                                          fontSize: 16,
                                          marginLeft: 5,
                                          flex: 1,
                                        }}
                                      >
                                        {product_name}
                                      </span>
                                      <div style={{ fontSize: 16 }}>
                                        {price !== 0 && shouldShowPrice
                                          ? `$${price?.toFixed(2)}`
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          {filterByLabel(
                            getSortedArray(subProducts, "sort"),
                            label?.product_name
                          )?.length > 0 && (
                            <hr style={{ marginBottom: 20, marginTop: 20 }} />
                          )}
                        </>
                      )}

                    {type === "checkbox" &&
                      enableParentModifier &&
                      advancedPizzaOptions &&
                      !enableQtyUpdate &&
                      isStringValid(label?.product_name) && (
                        <>
                          {filterByLabel(
                            getSortedArray(subProducts, "sort"),
                            label?.product_name
                          )
                            ?.filter((t) => {
                              const exchangedWith = selectedOptions
                                .map((t) =>
                                  t.exchangedWith ? t?.product_id : null
                                )
                                .filter(Boolean);

                              return !exchangedWith.includes(t.product_id);
                            })
                            ?.map((subProd, index) => {
                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd?.price;

                              subProd["enableParentModifier"] =
                                enableParentModifier;

                              subProd["advancedPizzaOptions"] =
                                advancedPizzaOptions;

                              subProd["parent"] = modifierGroupId;

                              subProd["name"] = name;

                              const selectedModifiers = selectedOptions.filter(
                                (s) => s.name === name
                              );

                              const isFree =
                                selectedOptions.find(
                                  (d) => d.product_id === subProd.product_id
                                )?.isFree || false;

                              return (
                                <AdvancedPizzaModifiers
                                  name={name}
                                  key={index}
                                  allData={getSortedArray(subProducts, "sort")}
                                  handleAddOptions={handleAddOptions}
                                  handleRemoveOption={handleRemoveOption}
                                  isNestedModifier={isNestedModifier}
                                  isRequired={isRequired}
                                  isSelected={isSelected}
                                  advancedPizzaOptions={advancedPizzaOptions}
                                  index={index}
                                  subProd={subProd}
                                  size={size}
                                  type={parentType}
                                  sortOrder={sortOrder}
                                  countByParentId={countByParentIdAdvancePizza}
                                  selectedOptions={selectedOptions}
                                  modifierGroupId={modifierGroupId}
                                  max={max}
                                  originalMaxFreeToppings={maxFreeToppings}
                                  maxFreeToppings={
                                    maxFreeToppings - selectedModifiers.length
                                  }
                                  isFree={isFree}
                                  parentChanged={() =>
                                    setisParentChanged(!isParentChanged)
                                  }
                                />
                              );
                            })}
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </>
                      )}

                    {type === "checkbox" &&
                      enableParentModifier &&
                      !advancedPizzaOptions &&
                      enableQtyUpdate &&
                      isStringValid(label?.product_name) && (
                        <>
                          {filterByLabel(
                            getSortedArray(subProducts, "sort"),
                            label?.product_name
                          )
                            ?.filter((t) => {
                              const exchangedWith = selectedOptions
                                .map((t) =>
                                  t.exchangedWith ? t?.product_id : null
                                )
                                .filter(Boolean);

                              return !exchangedWith.includes(t.product_id);
                            })
                            ?.map((subProd, index) => {
                              const { product_id } = subProd;
                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd?.price;

                              subProd["name"] = name;
                              console.log("====", subProd.allowExchange);
                              const selectedModifiers = selectedOptions.filter(
                                (s) => s.name === name
                              );

                              const maxFree =
                                maxFreeToppings - selectedModifiers.length;

                              const isFree =
                                selectedOptions.find(
                                  (d) => d.product_id === subProd.product_id
                                )?.isFree || false;
                              if (isFree || maxFree > 0) {
                                subProd.price = 0;
                              }

                              return (
                                <ModifierQty
                                  name={name}
                                  handleAddOptions={handleAddOptions}
                                  handleRemoveOption={handleRemoveOption}
                                  isNestedModifier={isNestedModifier}
                                  isRequired={isRequired}
                                  index={index}
                                  subProd={subProd}
                                  isSelected={isSelected}
                                  enableQtyUpdate={enableQtyUpdate}
                                  countByParentId={countByParentId}
                                  modifierGroupId={modifierGroupId}
                                  selectedOptions={selectedOptions}
                                  sortOrder={sortOrder}
                                  key={product_id}
                                  getModifierQty={(val) => {
                                    const arr = selectedOptions;
                                    const findSelectedItem = arr.findIndex(
                                      (item) => item.product_id === product_id
                                    );

                                    if (findSelectedItem !== -1) {
                                      arr[findSelectedItem].qty = val;
                                      if (
                                        arr[findSelectedItem].defaultSelected &&
                                        val > 1
                                      ) {
                                        arr[findSelectedItem].price =
                                          subProd?.price;
                                      }
                                      setSelectedOptions([...arr]);
                                    }
                                  }}
                                  max={max}
                                />
                              );
                            })}
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </>
                      )}

                    {type === "select" &&
                      enableParentModifier &&
                      isStringValid(label?.product_name) &&
                      filterByLabel(
                        getSortedArray(subProducts, "sort"),
                        label?.product_name
                      )?.length > 0 && (
                        <div style={{ zIndex: 9 }}>
                          <Select
                            components={{ Option: CustomOption }}
                            isSearchable={false}
                            className="basic-single"
                            classNamePrefix="select"
                            ref={selectedRef}
                            options={filterByLabel(
                              getSortedArray(subProducts, "sort"),
                              label?.product_name
                            )?.map((subProd, index) => {
                              const { product_name, price, defaultSelected } =
                                subProd;
                              console.log("====", subProd.allowExchange);

                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0 ||
                                defaultSelected;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd.price;

                              subProd["name"] = name;

                              return {
                                label: isNestedModifier
                                  ? `${product_name}`
                                  : `${product_name} ${
                                      price !== 0 ? "$" + price : ""
                                    }`,
                                value: subProd,
                                price: price,
                                parent: name,
                                enableParentModifier,
                              };
                            })}
                            onChange={(data) => {
                              if (selectedOptions?.length > 0) {
                                const array = selectedOptions?.filter(
                                  (sel) =>
                                    sel?.selectedParentValue?.label ===
                                      data?.value?.selectedParentValue?.label &&
                                    data.parent === sel.name
                                );
                                array.map((m) => handleRemoveOption(m));
                              }

                              const parentCheck = selectedOptions.findIndex(
                                (op) => op?.parent === name
                              );

                              const exist = selectedOptions.find(
                                (op) =>
                                  op?.product_id === data?.value?.product_id &&
                                  op?.name === data?.value?.name
                              );

                              if (!data?.value?.defaultSelected) {
                                if (!exist && parentCheck !== -1) {
                                  selectedOptions.splice(parentCheck, 1);
                                  setSelectedOptions([
                                    ...selectedOptions,
                                    {
                                      ...data.value,
                                      parent: data?.parent,
                                      enableParentModifier:
                                        data?.enableParentModifier,
                                      sort: Number(sortOrder || 0),
                                      modifierId,
                                      overridePLU,
                                    },
                                  ]);
                                } else if (!exist && parentCheck === -1) {
                                  setSelectedOptions([
                                    ...selectedOptions,
                                    {
                                      ...data.value,
                                      parent: data?.parent,
                                      enableParentModifier:
                                        data?.enableParentModifier,
                                      sort: Number(sortOrder || 0),
                                      modifierId,
                                      overridePLU,
                                    },
                                  ]);
                                }
                              } else {
                                if (!exist && parentCheck !== -1) {
                                  selectedOptions.splice(parentCheck, 1);
                                  setSelectedOptions([
                                    ...selectedOptions,
                                    {
                                      ...data.value,
                                      parent: data?.parent,
                                      enableParentModifier:
                                        data?.enableParentModifier,
                                      price: 0,
                                      sort: Number(sortOrder || 0),
                                      modifierId,
                                      overridePLU,
                                    },
                                  ]);
                                } else if (!exist && parentCheck === -1) {
                                  setSelectedOptions([
                                    ...selectedOptions,
                                    {
                                      ...data.value,
                                      parent: data?.parent,
                                      enableParentModifier:
                                        data?.enableParentModifier,
                                      price: 0,
                                      sort: Number(sortOrder || 0),
                                      modifierId,
                                      overridePLU,
                                    },
                                  ]);
                                }
                              }
                            }}
                            value={
                              selectedSubProductDependent
                                ? {
                                    label:
                                      `${
                                        selectedSubProductDependent?.product_name
                                      }${
                                        selectedSubProductDependent?.price !== 0
                                          ? ` - $${selectedSubProductDependent?.price}`
                                          : ""
                                      }` || "No",
                                  }
                                : ""
                            }
                          />
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </div>
                      )}

                    {type === "checkbox" &&
                      !advancedPizzaOptions &&
                      !enableQtyUpdate &&
                      !enableParentModifier && (
                        <>
                          {getSortedArray(subProducts, "sort")
                            ?.filter((t) => {
                              const exchangedWith = selectedOptions
                                .map((t) =>
                                  t.exchangedWith ? t?.product_id : null
                                )
                                .filter(Boolean);

                              return !exchangedWith.includes(t.product_id);
                            })
                            ?.map((subProd, index) => {
                              const { product_name, price, product_id } =
                                subProd;

                              subProd["name"] = name;
                              subProd["parent"] = name;

                              const selectedModifiers = selectedOptions.filter(
                                (s) => s.name === name
                              );

                              const maxFree =
                                maxFreeToppings - selectedModifiers?.length;

                              const isFree =
                                selectedOptions.find(
                                  (d) => d.product_id === subProd.product_id
                                )?.isFree || false;

                              const shouldShowPrice =
                                (!isSelected(subProd) && maxFree <= 0) ||
                                (isSelected(subProd) && !isFree);

                              return (
                                <>
                                  <div key={index} className="">
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          zIndex: 0,
                                          display: "flex",
                                          flexDirection: "row",
                                          marginTop: 3,
                                        }}
                                      >
                                        <CustomCheckbox
                                          onChange={(e) => {
                                            setisParentChanged(
                                              !isParentChanged
                                            );

                                            if (e.target.checked) {
                                              const exchangedWithItem =
                                                selectedOptions.find(
                                                  (t) =>
                                                    t.exchangedWith ===
                                                    subProd?.product_id
                                                );
                                              console.log(
                                                "EXCHANGED WITH",
                                                exchangedWithItem
                                              );

                                              handleRemoveOption(
                                                exchangedWithItem
                                              );
                                              if (
                                                subProd?.subModifiers?.length >
                                                0
                                              ) {
                                                const label = `${
                                                  subProd?.subModifiers[0]?.name
                                                }  ${
                                                  subProd?.subModifiers[0]
                                                    ?.price !== 0
                                                    ? " - $" +
                                                      subProd?.subModifiers[0]
                                                        ?.price
                                                    : ""
                                                }`;
                                                subProd["selectedModifier"] = {
                                                  label: label,
                                                  value:
                                                    subProd?.subModifiers[0]
                                                      ?.price,
                                                };
                                              }
                                              if (
                                                Number(max) > 0 &&
                                                countByParentId(name) >
                                                  Number(max) &&
                                                max
                                              ) {
                                                toast.error(
                                                  `You can select ${max} maximum.`
                                                );
                                                return;
                                              }
                                              subProd["parent"] = name;
                                              subProd["name"] = name;

                                              if (!subProd?.defaultSelected) {
                                                if (
                                                  subProd?.subModifiers
                                                    ?.length > 0
                                                ) {
                                                  handleAddOptions({
                                                    ...subProd,
                                                    price: Number(
                                                      subProd?.price
                                                    ),
                                                    name: name,
                                                    parent: name,
                                                    sort: Number(
                                                      subProd?.sort || 0
                                                    ),
                                                    modifierId,
                                                    overridePLU,
                                                    ...(!shouldShowPrice && {
                                                      price: 0,
                                                    }),
                                                    isFree: !shouldShowPrice,
                                                    maxFreeToppings,
                                                  });
                                                } else {
                                                  handleAddOptions({
                                                    ...subProd,
                                                    name: name,
                                                    parent: name,
                                                    sort: Number(
                                                      subProd?.sort || 0
                                                    ),
                                                    modifierId,
                                                    overridePLU,
                                                    ...(!shouldShowPrice && {
                                                      price: 0,
                                                    }),
                                                    isFree: !shouldShowPrice,
                                                    maxFreeToppings,
                                                  });
                                                }
                                              } else {
                                                if (
                                                  subProd?.subModifiers
                                                    ?.length > 0
                                                ) {
                                                  handleAddOptions({
                                                    ...subProd,
                                                    name: name,
                                                    parent: name,
                                                    price: 0,
                                                    sort: Number(
                                                      subProd?.sort || 0
                                                    ),
                                                    modifierId,
                                                    overridePLU,
                                                    ...(!shouldShowPrice && {
                                                      price: 0,
                                                    }),
                                                    isFree: !shouldShowPrice,
                                                    maxFreeToppings,
                                                  });
                                                } else
                                                  handleAddOptions({
                                                    ...subProd,
                                                    name: name,
                                                    parent: name,
                                                    price: 0,
                                                    sort: Number(
                                                      subProd?.sort || 0
                                                    ),
                                                    modifierId,
                                                    overridePLU,
                                                    ...(!shouldShowPrice && {
                                                      price: 0,
                                                    }),
                                                    isFree: !shouldShowPrice,
                                                    maxFreeToppings,
                                                  });
                                              }
                                            } else {
                                              handleRemoveOption(subProd);
                                            }
                                          }}
                                          checked={isSelected({
                                            ...subProd,
                                            parent: name,
                                            name: name,
                                          })}
                                          className="cb1"
                                          key={product_id}
                                          type={"checkbox"}
                                          id={product_id}
                                          name={product_id}
                                          value={JSON.stringify(subProd)}
                                          style={{
                                            marginBottom: 10,
                                            fontSize: 15,
                                            fontWeight: "400",
                                          }}
                                        />
                                        <label
                                          className="ml-2 my-0 pointer"
                                          style={{
                                            fontWeight: "500",
                                          }}
                                          htmlFor={product_id}
                                        >
                                          {product_name}
                                        </label>
                                      </div>

                                      <div style={{ fontSize: 16 }}>
                                        {price !== 0 &&
                                        price &&
                                        !subProd.defaultSelected &&
                                        price !== 0 &&
                                        price &&
                                        shouldShowPrice
                                          ? `$${Number(price)?.toFixed(2)}`
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                  {isSelected(subProd) &&
                                    subProd?.subModifiers?.length > 0 && (
                                      <div className="mb-2 mx-1 my-2">
                                        <Select
                                          components={{
                                            Option: CustomOption,
                                          }}
                                          value={{
                                            label: `${subProd?.selectedModifier?.label} `,
                                            value:
                                              subProd?.selectedModifier?.value,
                                          }}
                                          options={subProd?.subModifiers?.map(
                                            (subModif) => {
                                              return {
                                                label: `${subModif?.name} ${
                                                  subModif?.price !== 0
                                                    ? " - $" + subModif?.price
                                                    : ""
                                                }`,
                                                value: subModif?.price,
                                              };
                                            }
                                          )}
                                          onChange={(data) => {
                                            handleRemoveOption(subProd);
                                            subProd["selectedModifier"] = {
                                              ...data,
                                            };
                                            handleAddOptions({
                                              ...subProd,
                                              parent: subProd?.name,
                                              sort: Number(subProd?.sort || 0),
                                              price: Number(subProd?.price),
                                              maxFreeToppings,
                                            });
                                          }}
                                        ></Select>
                                      </div>
                                    )}
                                  {!isSelected(subProd) &&
                                    subProd?.defaultSelected &&
                                    subProd?.allowExchange && (
                                      <div className="my-2">
                                        <Select
                                          isSearchable={false}
                                          className="basic-single"
                                          classNamePrefix="select"
                                          placeholder={`Exchange ${subProd.product_name}`}
                                          ref={selectedRef}
                                          options={subProducts
                                            .filter(
                                              (t) =>
                                                t?.product_name !==
                                                  subProd?.product_name &&
                                                subProd?.selectedParentValue
                                                  ?.label ===
                                                  t?.selectedParentValue
                                                    ?.label &&
                                                !t?.defaultSelected
                                            )
                                            .filter((t) => {
                                              const allOptId =
                                                selectedOptions.map(
                                                  (t) => t.product_id
                                                );
                                              return !allOptId.includes(
                                                t.product_id
                                              );
                                            })
                                            .map((d) => {
                                              return {
                                                label: `${d?.product_name}${
                                                  d?.price > subProd?.price
                                                    ? ` - $${
                                                        d?.price -
                                                        subProd?.price
                                                      }`
                                                    : ``
                                                }`,
                                                value: d.product_id,
                                              };
                                            })}
                                          onChange={(data) => {
                                            // handleRemoveOption(subProd);
                                            const exchangedWithItem =
                                              selectedOptions.find(
                                                (t) =>
                                                  t.exchangedWith ===
                                                  subProd?.product_id
                                              );
                                            console.log(
                                              "EXCHANGED WITH",
                                              exchangedWithItem
                                            );

                                            handleRemoveOption(
                                              exchangedWithItem
                                            );

                                            const sel = subProducts.find(
                                              (d) => d.product_id === data.value
                                            );
                                            handleAddOptions({
                                              ...sel,
                                              name: name,
                                              parent: name,
                                              price:
                                                subProd?.price < sel.price
                                                  ? sel.price - subProd?.price
                                                  : 0,
                                              sort: Number(sortOrder || 0),
                                              exchangedWith: subProd.product_id,
                                              allowExchange:
                                                subProd?.allowExchange,
                                            });
                                            // setExhcnaged(sel);
                                          }}
                                        />
                                      </div>
                                    )}
                                </>
                              );
                            })}
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </>
                      )}

                    {type === "checkbox" &&
                      advancedPizzaOptions &&
                      !enableParentModifier &&
                      !enableQtyUpdate && (
                        <>
                          {getSortedArray(subProducts, "sort")
                            ?.filter((t) => {
                              const exchangedWith = selectedOptions
                                .map((t) =>
                                  t.exchangedWith ? t?.product_id : null
                                )
                                .filter(Boolean);

                              return !exchangedWith.includes(t.product_id);
                            })
                            ?.map((subProd, index) => {
                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd?.price;

                              subProd["enableParentModifier"] =
                                enableParentModifier;

                              subProd["advancedPizzaOptions"] =
                                advancedPizzaOptions;

                              subProd["parent"] = modifierGroupId;

                              subProd["name"] = name;
                              console.log("====", subProd.allowExchange);

                              const selectedModifiers = selectedOptions.filter(
                                (s) => s.name === name
                              );

                              const isFree =
                                selectedOptions.find(
                                  (d) => d.product_id === subProd.product_id
                                )?.isFree || false;

                              return (
                                <AdvancedPizzaModifiers
                                  key={index}
                                  name={name}
                                  allData={getSortedArray(subProducts, "sort")}
                                  handleAddOptions={handleAddOptions}
                                  handleRemoveOption={handleRemoveOption}
                                  isNestedModifier={isNestedModifier}
                                  isRequired={isRequired}
                                  isSelected={isSelected}
                                  advancedPizzaOptions={advancedPizzaOptions}
                                  index={index}
                                  subProd={subProd}
                                  size={size}
                                  sortOrder={sortOrder}
                                  selectedOptions={selectedOptions}
                                  countByParentId={countByParentIdAdvancePizza}
                                  type={parentType}
                                  max={max}
                                  originalMaxFreeToppings={maxFreeToppings}
                                  maxFreeToppings={
                                    maxFreeToppings - selectedModifiers.length
                                  }
                                  isFree={isFree}
                                />
                              );
                            })}
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </>
                      )}

                    {type === "checkbox" &&
                      !advancedPizzaOptions &&
                      enableQtyUpdate &&
                      !enableParentModifier && (
                        <>
                          {getSortedArray(subProducts, "sort")?.map(
                            (subProd, index) => {
                              const { product_id } = subProd;
                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd?.price;

                              subProd["name"] = name;

                              return (
                                <>
                                  <ModifierQty
                                    name={name}
                                    handleAddOptions={handleAddOptions}
                                    handleRemoveOption={handleRemoveOption}
                                    isNestedModifier={isNestedModifier}
                                    isRequired={isRequired}
                                    sortOrder={sortOrder}
                                    index={index}
                                    subProd={subProd}
                                    isSelected={isSelected}
                                    enableQtyUpdate={enableQtyUpdate}
                                    countByParentId={countByParentId}
                                    modifierGroupId={modifierGroupId}
                                    selectedOptions={selectedOptions}
                                    key={product_id}
                                    getModifierQty={(val) => {
                                      const arr = selectedOptions;
                                      const findSelectedItem = arr.findIndex(
                                        (item) => item.product_id === product_id
                                      );

                                      if (
                                        subProd?.defaultSelected &&
                                        arr[findSelectedItem]
                                      ) {
                                        if (val > 1) {
                                          arr[findSelectedItem].price =
                                            subProd?.price;
                                        } else {
                                          arr[findSelectedItem].price = 0;
                                        }
                                      }

                                      if (findSelectedItem > -1) {
                                        arr[findSelectedItem].qty = val;
                                      }
                                      setSelectedOptions([...arr]);
                                    }}
                                    max={max}
                                  />
                                </>
                              );
                            }
                          )}
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </>
                      )}

                    {type === "checkbox" &&
                      !advancedPizzaOptions &&
                      enableQtyUpdate &&
                      !enableParentModifier &&
                      isStringValid(label?.product_name) &&
                      filterByLabel(
                        getSortedArray(subProducts, "sort"),
                        label?.product_name
                      )?.length > 0 && (
                        <>
                          {filterByLabel(
                            getSortedArray(subProducts, "sort"),
                            label?.product_name
                          )?.map((subProd, index) => {
                            const { product_id } = subProd;
                            const isNestedModifier =
                              subProd?.subModifiers?.length > 0;
                            subProd["price"] = isNestedModifier
                              ? 0
                              : subProd?.price;

                            subProd["name"] = name;
                            const selectedModifiers = selectedOptions.filter(
                              (s) => s.name === name
                            );

                            const maxFree =
                              maxFreeToppings - selectedModifiers.length;

                            const isFree =
                              selectedOptions.find(
                                (d) => d.product_id === subProd.product_id
                              )?.isFree || false;

                            if (isFree || maxFree > 0) {
                              subProd.price = 0;
                            }

                            return (
                              <>
                                <ModifierQty
                                  name={name}
                                  handleAddOptions={handleAddOptions}
                                  handleRemoveOption={handleRemoveOption}
                                  isNestedModifier={isNestedModifier}
                                  isRequired={isRequired}
                                  index={index}
                                  subProd={subProd}
                                  isSelected={isSelected}
                                  sortOrder={sortOrder}
                                  enableQtyUpdate={enableQtyUpdate}
                                  countByParentId={countByParentId}
                                  modifierGroupId={modifierGroupId}
                                  selectedOptions={selectedOptions}
                                  key={product_id}
                                  getModifierQty={(val) => {
                                    const arr = selectedOptions;
                                    const findSelectedItem = arr.findIndex(
                                      (item) => item.product_id === product_id
                                    );

                                    if (findSelectedItem > -1) {
                                      arr[findSelectedItem].qty = val;

                                      setSelectedOptions([...arr]);
                                    }
                                  }}
                                  max={max}
                                />
                              </>
                            );
                          })}
                          <hr style={{ marginBottom: 20, marginTop: 20 }} />
                        </>
                      )}

                    {type === "select" && !enableParentModifier && (
                      <div style={{ zIndex: 9 }}>
                        <Select
                          components={{ Option: CustomOption }}
                          isSearchable={false}
                          className="basic-single"
                          classNamePrefix="select"
                          options={getSortedArray(subProducts, "sort")?.map(
                            (subProd) => {
                              let { product_name, price, defaultSelected } =
                                subProd;

                              price = Number(price)?.toFixed(2);

                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0 ||
                                defaultSelected;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd.price;

                              subProd["name"] = name;

                              return {
                                label: isNestedModifier
                                  ? `${product_name}`
                                  : `${product_name} ${
                                      price !== 0 ? "$" + price : ""
                                    }`,
                                value: subProd,
                                price: price,
                                parent: name,
                              };
                            }
                          )}
                          onChange={(data) => {
                            const isSame =
                              selectedOptions.findIndex(
                                (opt) =>
                                  opt.product_id === data.value.product_id
                              ) !== -1;

                            if (isSame) {
                              return;
                            }
                            const selectedVal = selectedOptions.filter(
                              (it) =>
                                it?.parent === data?.parent ||
                                (it?.selectedParentValue?.parentModifier ===
                                  data?.parent &&
                                  it?.defaultSelected)
                            );

                            selectedVal.map((s) => handleRemoveOption(s));

                            const parentCheck = selectedOptions.findIndex(
                              (op) => op?.name === name
                            );

                            setisParentChanged(!isParentChanged);

                            const exist = selectedOptions.find(
                              (op) =>
                                op?.product_id === data?.value?.product_id &&
                                op?.name === data?.value?.name
                            );

                            const toRemoveModifiers = selectedOptions?.filter(
                              (sel) =>
                                sel?.selectedParentValue?.label &&
                                sel?.selectedParentValue?.label !==
                                  data?.value?.product_name &&
                                sel?.selectedParentValue?.parentModifier ===
                                  name
                            );

                            toRemoveModifiers?.map((modi) =>
                              handleRemoveOption(modi)
                            );

                            if (!data?.value?.defaultSelected) {
                              if (!exist && parentCheck !== -1) {
                                selectedOptions.splice(parentCheck, 1);
                                setSelectedOptions([
                                  ...selectedOptions,
                                  {
                                    ...data.value,
                                    parent: data?.parent,
                                    sort: Number(sortOrder || 0),
                                    overridePLU,
                                  },
                                ]);
                              } else if (!exist && parentCheck === -1) {
                                setSelectedOptions([
                                  ...selectedOptions,
                                  {
                                    ...data.value,
                                    parent: data?.parent,
                                    sort: Number(sortOrder || 0),
                                    overridePLU,
                                  },
                                ]);
                              }
                            } else {
                              if (!exist && parentCheck !== -1) {
                                selectedOptions.splice(parentCheck, 1);
                                setSelectedOptions([
                                  ...selectedOptions,
                                  {
                                    ...data.value,
                                    parent: data?.parent,
                                    price: 0,
                                    sort: Number(sortOrder || 0),
                                    overridePLU,
                                  },
                                ]);
                              } else if (!exist && parentCheck === -1) {
                                setSelectedOptions([
                                  ...selectedOptions,
                                  {
                                    ...data.value,
                                    parent: data?.parent,
                                    price: 0,
                                    sort: Number(sortOrder || 0),
                                    overridePLU,
                                  },
                                ]);
                              }
                            }
                          }}
                          value={
                            selectedSubProduct
                              ? {
                                  label:
                                    `${selectedSubProduct?.product_name}${
                                      selectedSubProduct?.price !== 0
                                        ? ` - $${selectedSubProduct?.price}`
                                        : ""
                                    }` || "No",
                                }
                              : ""
                          }
                        />
                        <hr style={{ marginBottom: 20, marginTop: 20 }} />
                      </div>
                    )}

                    {type === "radio" && (
                      <>
                        {" "}
                        <FormGroup tag="fieldset">
                          {getSortedArray(subProducts, "sort")?.map(
                            (subProd, index) => {
                              const {
                                product_name,
                                product_id,
                                price: radioModifierPrice,
                              } = subProd;

                              const isNestedModifier =
                                subProd?.subModifiers?.length > 0;
                              subProd["price"] = isNestedModifier
                                ? 0
                                : subProd?.price;

                              subProd["name"] = name;

                              return (
                                <>
                                  <div className="d-flex align-items-center justify-content-between my-2">
                                    <FormGroup className="ml--3" check>
                                      <label
                                        className="radio-button-container"
                                        check
                                      >
                                        <input
                                          type="radio"
                                          name={name}
                                          value={product_id}
                                          id={product_id}
                                          checked={isSelected(subProd)}
                                          onChange={(e) => {
                                            const parentCheck =
                                              selectedOptions.findIndex(
                                                (op) => op?.name === name
                                              );
                                            const exist = selectedOptions.find(
                                              (op) =>
                                                op?.product_id === product_id
                                            );

                                            if (!subProd?.defaultSelected) {
                                              if (
                                                !exist &&
                                                parentCheck !== -1
                                              ) {
                                                selectedOptions.splice(
                                                  parentCheck,
                                                  1
                                                );
                                                setSelectedOptions([
                                                  ...selectedOptions,
                                                  {
                                                    ...subProd,
                                                    name: name,
                                                    sort: Number(
                                                      sortOrder || 0
                                                    ),
                                                  },
                                                ]);
                                              } else if (
                                                !exist &&
                                                parentCheck === -1
                                              ) {
                                                setSelectedOptions([
                                                  ...selectedOptions,
                                                  {
                                                    ...subProd,
                                                    name: name,
                                                    sort: Number(
                                                      sortOrder || 0
                                                    ),
                                                  },
                                                ]);
                                              }
                                            } else {
                                              if (
                                                !exist &&
                                                parentCheck !== -1
                                              ) {
                                                selectedOptions.splice(
                                                  parentCheck,
                                                  1
                                                );
                                                setSelectedOptions([
                                                  ...selectedOptions,
                                                  {
                                                    ...subProd,
                                                    name: name,
                                                    price: 0,
                                                    sort: Number(
                                                      sortOrder || 0
                                                    ),
                                                  },
                                                ]);
                                              } else if (
                                                !exist &&
                                                parentCheck === -1
                                              ) {
                                                setSelectedOptions([
                                                  ...selectedOptions,
                                                  {
                                                    ...subProd,
                                                    name: name,
                                                    price: 0,
                                                    sort: Number(
                                                      sortOrder || 0
                                                    ),
                                                  },
                                                ]);
                                              }
                                            }
                                          }}
                                        />
                                        <span class="checkmark"></span>
                                        {product_name}
                                      </label>
                                    </FormGroup>
                                    <div>
                                      {radioModifierPrice !== 0
                                        ? `$${Number(
                                            radioModifierPrice
                                          )?.toFixed(2)}`
                                        : ""}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </FormGroup>
                        <hr style={{ marginBottom: 20, marginTop: 20 }} />
                      </>
                    )}
                  </>
                );
              })}
        </div>

        {!restaurant?.ordering?.hideSpecialInstruction && (
          <span
            onClick={() => {
              if (addInstructions) {
                setNotes("");
              }
              setAddInstructions(!addInstructions);
            }}
            style={{ color: theme.primary, cursor: "pointer", marginTop: 10 }}
          >
            {addInstructions ? "- Remove" : "+ Add"} Instructions
          </span>
        )}
        {addInstructions && (
          <Input
            placeholder={restaurant?.ordering?.instructionText}
            style={{
              marginTop: 10,
              border: `2px solid ${theme.primary}`,
              color: "black",
            }}
            type="textarea"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          ></Input>
        )}
      </div>

      <Button
        size="lg"
        disabled={disableButton}
        onClick={() => {
          // return;
          if (price < 0) {
            swal({
              text: "Price should not be less than zero. Please remove some modifiers to continue ordering.",
              icon: "warning",
            });
            return;
          }
          if (dish.modifiers) {
            if (!checkModifiers() || !checkDependentRequiredModifiers()) {
              return;
            }
          }

          if (dish?.isAlchohol) {
            swal({
              title: "Confirmation",
              text:
                restaurant?.ordering?.ageVerificationWarningMsg ||
                "Are you above 18?",
              icon: "warning",
              buttons: true,
              dangerMode: false,
            }).then(async (willContinue) => {
              if (willContinue) {
                onAddToCart(
                  Object.keys(dish?.modifiers || {}).length > 0
                    ? getSortedArray(selectedOptions, "parentSortOrder")
                    : [],
                  notes,
                  qty || 1
                );
                setSelectedOptions([]);
                setNotes("");
                setAddInstructions(false);
                setPizzaOptions({});
                toggle();
              } else {
                toggle();
              }
            });
          } else {
            onAddToCart(
              Object.keys(dish?.modifiers || {}).length > 0
                ? getSortedArray(selectedOptions, "parentSortOrder")
                : [],
              notes,
              qty
            );
            setSelectedOptions([]);
            setPizzaOptions({});
            setNotes("");
            setAddInstructions(false);
            toggle();
          }
        }}
        style={{
          backgroundColor: theme.primary,
          color: "white",
          transform: "none",
          boxShadow: "none",
          margin: 5,
          padding: 20,
          fontFamily: "Inter",
          borderRadius: 5,
          fontWeight: "600",
        }}
        type="button"
      >
        {`Add to Cart - $${Number(price * qty)?.toFixed(2)}`}
      </Button>
    </Modal>
  );
}
