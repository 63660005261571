import * as Yup from "yup";

export const validationSchemaSettings = Yup.object().shape({
  account_name: Yup.string()
    .min(5, "Must be atleast 5 characters")
    .max(100, "Must be atmost 30 characters"),
  account_email: Yup.string().email("Must be a valid email"),
  owner_name: Yup.string()
    .min(5, "Must be atleast 5 characters")
    .max(30, "Must be atmost 30 characters"),
  master_order_email: Yup.string(),
  privacy: Yup.string().min(10, "Must be atleast 10 characters"),
  terms: Yup.string().min(10, "Must be atmost 10 characters"),
});
