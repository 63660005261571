/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import $ from "jquery"; // Import jQuery
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useTheme } from "../../store/theme";
import apiCaller from "../../api/apiCaller";
import { AuthContext } from "../../context";
import { toast } from "react-toastify";
import { BridgePayTokenPayUrl, TokenPayHost } from "../../config";

function PaymentForm({
  formik,
  onSuccess,
  isSaveCard,
  guestFormik,
  guestErrors,
  guestRef,
}) {
  const { cartItems } = useCartStore();
  const { activeTab, gateway } = useOrdering();
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    isSaveCard(saveCard);
  }, [saveCard]);

  useEffect(() => {
    // Dynamically inject TokenPay script into the document
    const script = document.createElement("script");

    script.src = BridgePayTokenPayUrl;

    script.async = true;

    script.id = "tokenpayjs-script";

    script.onload = () => {
      const tokenpay = TokenPay(gateway?.tokenPayPublicKey);
      tokenpay.initialize({
        dataElement: "#card",
        errorElement: "#errorMessage",
        amountElement: "#amount",
        useACH: false,
        useStyles: false,
        disableZip: false,
        disableCvv: false,
      });

      const form = document.getElementById("paymentForm");

      $(form).on("submit", async function (event) {
        event.preventDefault();

        console.log("CALLED");

        setLoading(true);

        if (activeTab === "guest") {
          guestFormik();
          if (Object.keys(guestErrors).length !== 0) {
            guestRef.current.scrollIntoView();
            setLoading(false);
            return;
          }
        }

        const invoiceNumber = await apiCaller("/bridgepay/invoice", {
          method: "GET",
        });

        tokenpay.createToken(
          async function (result) {
            const payload = {
              accountHolder: {
                name: authContext?.user?.customer?.name,
              },
              merchantAccountCode: gateway?.merchantAccountCode,
              transactionType: "sale",
              invoiceNumber: invoiceNumber.data,
              transIndustryType: "RE",
              holderType: "P",
              accountType: "R",
              amount: formik?.values?.billing?.total,
              gateway: gateway?._id,
            };

            const paymentMethodToken = await apiCaller(
              "/bridgepay/payment-method-token",
              {
                method: "POST",
                body: payload,
                headers: {
                  token: result.token,
                  "Content-Type": "application/json",
                },
              }
            );

            const data = {
              payment: {
                token: paymentMethodToken.token,
                expirationDate: paymentMethodToken.expirationDate,
              },
              accountHolder: {
                name: authContext?.user?.customer?.name,
                email: authContext?.user?.customer?.email,
                phone: authContext?.user?.customer?.phone,
              },
              merchantAccountCode: gateway?.merchantAccountCode,
              transactionType: "sale",
              invoiceNumber: invoiceNumber.data,
              transIndustryType: "RE",
              holderType: "P",
              accountType: "R",
              amount: formik?.values?.billing?.total,
              gateway: gateway?._id,
              saveCard,
            };

            const transaction = await apiCaller("/bridgepay/transaction", {
              method: "POST",
              body: data,
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (
              transaction?.transactionDoc?.responseCode === "00000" ||
              transaction?.transactionDoc?.providerResponseCode === "00"
            ) {
              onSuccess({
                ...transaction,
                invoiceNumber: invoiceNumber.data,
                ...(activeTab !== "guest" && { saveCard }),
              });
              return;
            }

            toast.error("Payment failed");
            setLoading(false);
            return;
          },
          function (error) {
            toast.error(error.message);
            setLoading(false);
          }
        );
      });

      setLoading(false);
    };
    document.body.appendChild(script);

    return () => {
      const script = document.getElementById("tokenpayjs-script");

      if (script) {
        script.remove();
        console.log("token-payjs: removed script", script);
      }
    };
  }, []);

  return (
    <header>
      <form ref={formRef} id="paymentForm" action={TokenPayHost} method="post">
        <div
          style={{ maxWidth: 500, margin: "auto" }}
          name="tokenPayFields"
          id="tokenPayFields"
        >
          <div id="card" className="tokenPayCardFormat"></div>
          <div id="errorMessage" style={{ color: "#c0392b" }}></div>
        </div>
        {authContext?.user && (
          <div className="custom-control custom-checkbox my-2">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={(e) => setSaveCard(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Save this card for future checkout
            </label>
          </div>
        )}

        <Button
          block
          disabled={
            loading ||
            formik?.values?.billing?.disableOrdering ||
            cartItems?.length <= 0 ||
            activeTab === "sign-in" ||
            activeTab === "register" ||
            (formik.values.payment_method === "saved-card" &&
              formik.values.selected_card === "") ||
            ((formik.values.payment_method === "new-card" ||
              formik.values.payment_method === "saved-card") &&
              !gateway) ||
            formik.isSubmitting
          }
          style={{
            backgroundColor: theme.primary,
            color: "white",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
          }}
          type="submit"
        >
          {loading ? <Spinner size={"sm"} /> : "Place Order"}
        </Button>
      </form>
    </header>
  );
}

const BridgepayTokenization = ({
  loading,
  formik,
  onSuccess,
  isSaveCard,
  guestErrors,
  guestFormik,
  guestRef,
}) => {
  return (
    <>
      <PaymentForm
        loading={loading}
        formik={formik}
        onSuccess={onSuccess}
        isSaveCard={isSaveCard}
        guestFormik={guestFormik}
        guestErrors={guestErrors}
        guestRef={guestRef}
      />
    </>
  );
};

export default BridgepayTokenization;
