import { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import CartModal from "../../screens/ordering/components/CartModal";
import MenuBannerDetails from "../../screens/ordering/menu/components/MenuBannerDetails";
import { useCartStore } from "../../store";
import useOrdering from "../../store/ordering";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../store/theme";
import InfoModal from "./InfoModal";
import MobileSidebar from "./MobileSIdebar";

const MobileNavMenu = ({
  history,
  searchString,
  tip,
  searchValues,
  hideSearch = false,
  hideCart = false,
  menu,
}) => {
  const { theme } = useTheme();
  const [isOpenCart, setIsOpenCart] = useState(false);
  const { cartItems } = useCartStore();
  const [showInfo, setShowInfo] = useState(false);
  const [search, setSearch] = useState(false);
  const [showLoyality, setShowLoyality] = useState(false);
  const [sidebarOpen] = useState(false);
  const { restaurant } = useOrderingRestaurant();
  const { method, cateringData } = useOrdering();

  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const eventEmit = () => {
      const event = new CustomEvent("mobileSeachEnabled", {
        detail: { mobileSearch: search },
      });
      document.dispatchEvent(event);
    };

    eventEmit();
  }, [search]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={"stickyNav"}
      style={{
        height:
          search && screenWidth < 600
            ? 140
            : !search && screenWidth < 600
            ? 60
            : screenWidth <= 1200
            ? 60
            : 0,
      }}
    >
      <div className="menu_mobile_nav">
        <div className="mx-3 back_menu_start">
          <div>
            <BiArrowBack size={23} color={theme.secondary} />
          </div>
          <div style={{ color: theme.secondary }} className="mx-2 text-sm bold">
            Start Over
          </div>
        </div>

        <MobileSidebar history={history} />
        {method !== "walkup" && (
          <span
            onClick={() => {
              let url = new URL(restaurant?.url);
              menu
                ? method === "dine-in"
                  ? history.push(`${url.pathname}/table`, { fromFlow: true })
                  : cateringData?.catering
                  ? history.push(`${url.pathname}/catering`)
                  : history.push(`${url.pathname}/timing`, { fromFlow: true })
                : history.push(`${url.pathname}/menu`, { fromFlow: true });
            }}
            className="ml-2"
          >
            <BiArrowBack color={theme.secondary} size={24} />
          </span>
        )}
        <div
          className="text-sm top_nav_icons"
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            zIndex: sidebarOpen ? 1.2 : 3,
            marginLeft: 100,
          }}
        >
          <div className="text_top_nav">
            <span style={{ color: theme?.primary }}>Need Help? </span>
            <span className="mx-2">4051231234 or info@coldstone.com</span>
          </div>

          {!hideSearch && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowInfo(false);
                setShowLoyality(false);
                setSearch(!search);
              }}
            >
              <BsSearch className="mx-3" size={22} color={theme?.secondary} />
            </span>
          )}

          {!hideCart && (
            <>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch(false);
                  setShowLoyality(false);
                  setOpenModal(true);
                }}
                className="mx-2"
              >
                <AiOutlineInfoCircle size={22} color={theme?.secondary} />
              </span>
              {showInfo && (
                <div
                  className="shadow-md px-4 py-3 rounded hide_desktop hide_tab"
                  style={{
                    width: "96%",
                    background: "#F5F5F5",
                    zIndex: 1000,
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <MenuBannerDetails popover={true} />
                </div>
              )}
            </>
          )}

          {!hideCart && (
            <span
              className="ml-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSearch(false);
                setShowInfo(false);
                setIsOpenCart(true);
              }}
            >
              <FiShoppingCart size={22} color={theme?.secondary} />
              <span
                style={{
                  padding: 3,
                  paddingLeft: 6,
                  paddingRight: 6,
                  backgroundColor: theme.primary,
                  color: "#fff",
                  borderRadius: 16,
                  fontSize: 12,
                  position: "relative",
                  bottom: 8,
                  right: 3,
                }}
              >
                {cartItems?.length}
              </span>
            </span>
          )}
        </div>

        <CartModal
          isOpen={isOpenCart}
          tip={tip}
          toggle={() => setIsOpenCart(!isOpenCart)}
          history={history}
        />
        <InfoModal toggleModal={toggleModal} openModal={openModal} />
      </div>
      {search && (
        <div
          className="px-2 py-2 mt-3 rounded "
          style={{
            width: "100%",
            zIndex: 9,
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            border: `1px solid ${theme.primary}`,
          }}
        >
          <div className="d-flex align-items-center justify-between">
            <div>
              <BsSearch className="mx-3" size={22} color="#666666" />
            </div>
            <div style={{ flex: 1 }}>
              <input
                className="py-2 px-1"
                style={{
                  width: "100%",
                  background: "transparent",
                  outline: "none",
                  border: "none",
                  zIndex: 9,
                }}
                onChange={(e) => searchString(e.target?.value)}
                type="text"
                placeholder="Search"
                value={searchValues}
              />
            </div>
            <div
              onClick={() => {
                if (searchValues?.length > 0) {
                  searchString("");
                } else {
                  setSearch(false);
                }
              }}
              className="mx-2"
            >
              <IoClose size={24} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNavMenu;
