import moment from "moment";
import { useEffect, useState } from "react";
import { CardBody, Spinner } from "reactstrap";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import check_slots_after_selected from "../../../utils/checkSlotsAfterSelected";
import EmptyPlaceholder from "../menu/components/EmptyPlaceholder";

const TimePicker = (props) => {
  const { theme } = useTheme();
  const { restaurant } = useOrderingRestaurant();
  let history = props.history;
  const [date, setDate] = useState(null);
  const {
    find,
    entities,
    loading: loadingDates,
  } = useEntity("eat/ordering/available-dates");
  const {
    find: findSlots,
    entities: slots,
    loading,
  } = useEntity("eat/ordering/slots");
  const { setLaterDate, setLaterTime, location, method, laterDate } =
    useOrdering();

  useEffect(() => {
    if (Object.keys(restaurant).length === 0) {
      const url = window.location.href?.replace(/\/later$/, "");
      let hostUrl = new URL(url);
      props.history.push(hostUrl.pathname);
      return;
    }
    if ((!location || !method) && Object.keys(restaurant).length > 0) {
      let url = new URL(restaurant?.url);
      props.history.push(url.pathname);
    }
  }, []);

  useEffect(() => {
    find({ locationId: location?._id, method });
  }, []);

  useEffect(() => {
    if (date) {
      findSlots({ date: date, method: method, locationId: location?._id });
    }
  }, [date]);

  return (
    <OrderingLayout
      title="Order for later"
      isCenter={true}
      classname="main_ordering_card_wide_picker"
      history={history}
      layout={true}
    >
      {!loading && !loadingDates ? (
        <CardBody
          className="card-location"
          style={{
            padding: 10,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <h3 className="mb-3" style={{ fontSize: "18px" }}>
            Choose date and time
          </h3>
          {restaurant?.ordering?.orderForLaterDays > 0 ? (
            <>
              <div
                className={` px-3 py-3 ${
                  restaurant?.ordering?.orderForLaterDays <= 8
                    ? "days_row_timing"
                    : "days_row_timing_new "
                }`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#f5f5f5",
                  boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
                  borderRadius: "6px",
                  overflow: "scroll",
                }}
              >
                {entities?.data?.map((d, i) => {
                  return (
                    <div
                      onClick={() => {
                        setLaterDate(new Date(d));
                        setDate(new Date(d));
                      }}
                      className="day-container"
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                        flexDirection: "column",
                        borderRadius: 8,
                        marginLeft: 10,
                        marginRight: 10,
                        height: 70,
                        minWidth: 70,
                        backgroundColor:
                          date && new Date(d).getDate() === date.getDate()
                            ? theme?.primary
                            : "#fff",
                        color:
                          date && new Date(d).getDate() === date.getDate()
                            ? "#fff"
                            : "#000",
                      }}
                    >
                      <span>{moment(d).format("ddd")}</span>
                      <span style={{ fontWeight: "bold" }}>
                        {moment(d).format("DD")}
                      </span>
                    </div>
                  );
                })}
              </div>
              <center>
                {laterDate !== "" && (
                  <div
                    className="mt-3 card-location mt-3"
                    style={{
                      overflow: "scroll",
                      maxHeight: "40vh",
                      width: "100%",
                    }}
                  >
                    {console.log(slots)}

                    {slots?.data?.map((d, i) => {
                      let splittedDate = d.split(":");

                      return (
                        <div
                          onClick={() => {
                            setLaterTime(d);

                            if (check_slots_after_selected(slots?.data, d)) {
                              setLaterDate(
                                moment(laterDate).add(1, "days").toDate()
                              );
                            }
                            history.push("menu", {
                              fromFlow: true,
                            });
                          }}
                        >
                          <div className="custom-control custom-radio mb-3">
                            <input
                              className="custom-control-input"
                              id={"customRadio_" + i}
                              name={"customRadio"}
                              type="radio"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"customRadio_" + i}
                            >
                              {moment(
                                new Date(
                                  0,
                                  0,
                                  0,
                                  splittedDate[0],
                                  splittedDate[1]
                                )
                              ).format("LT")}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </center>
            </>
          ) : (
            <div>
              <EmptyPlaceholder text="Order For later days are not defined" />
            </div>
          )}
        </CardBody>
      ) : (
        <>
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size="25" />
          </div>
        </>
      )}
    </OrderingLayout>
  );
};

export default TimePicker;
