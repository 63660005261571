import moment from "moment";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useEntity } from "../../../../hooks/useEntity";

const TableRowInfo = ({ data, history }) => {
  const { deleteEntity } = useEntity("more-info");
  const location = useLocation();
  return (
    <tr className="text-left">
      <th
        className="pointer"
        onClick={() =>
          history.push({
            pathname: "/resturants/more-info/add-more-info",
            state: data?._id,
            search: location.search,
          })
        }
      >
        {data?.title}
      </th>
      <td>{moment(data?.createdAt).format("MM/DD/YYYY")}</td>
      <td>{moment(data?.updatedAt).format("MM/DD/YYYY")}</td>
      <td>
        <span
          style={{ cursor: "pointer" }}
          onClick={async () => {
            try {
              swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  await deleteEntity(data?._id);
                  toast.error("Info deleted succesfully");
                }
              });
            } catch (error) {
              toast(error.message);
            }
          }}
        >
          <i className="fa fa-trash text-lg text-danger" />
        </span>
      </td>
    </tr>
  );
};

export default TableRowInfo;
