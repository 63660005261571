import React from "react";

const ErrorLable = ({ message }) => {
  return (
    <div
      className="mt-1 ml-1"
      style={{
        color: "red",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <span style={{ fontSize: 12 }}>{message}</span>
      </div>
    </div>
  );
};

export default ErrorLable;
