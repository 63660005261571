const { default: DB } = require("./DB");

const getTheme = (primaryColor) => {
  const theme = {
    primary: primaryColor,
    separatorColor: "rgba(0,0,0,0.08)",
    textColor: "black",
    backgroundColor: "white",
    headerBackgroundColor: "#f6f6f6",
    blockquoteColor: "rgba(0,0,0,0.80)",
    icon: "#121212",
  };
  return theme;
};

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  DB.storeData("primary", color);
  return color;
}

module.exports = { getTheme, getRandomColor };
