import * as Yup from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const zoneValidation = Yup.object().shape({
  deliveryFee: Yup.number("Delivery fee cannot be empty")
    .min(0, "Delivery fee cannot be less than 0")
    .max(999, "Delivery Fee cannot be more than 999")
    .required("Delivery fee cannot be empty")
    .typeError("Delivery Fee is invalid or empty"),

  minimumDeliveryAmount: Yup.number("Minimum Delivery Amount cannot be empty")
    .min(1, "Minimum Delivery Amount cannot be less than 1")
    .max(999, "Minimum Delivery Amount cannot be more than 999")
    .required("Minimum Delivery Amount cannot be empty")
    .typeError("Minimum Delivery Amount is invalid or empty"),

  deliveryReadyTime: Yup.number("Delivery ready time cannot be empty")
    .min(1, "Delivery ready time cannot be less than 1")
    .max(120, "Delivery ready time cannot be less than 120")
    .required("Delivery ready time cannot be empty")
    .typeError("Delivery Ready Time is invalid or empty"),

  deliveryRadius: Yup.number("Delivery radius cannot be empty")
    .min(1, "Delivery radius cannot be less than 1")
    .max(999, "Delivery radius cannot be more than 999")
    .required("Delivery Radius cannot be empty")
    .typeError("Delivery radius is invalid or empty"),

  ihdClientId: Yup.mixed().when("extendWithIHD", {
    is: true,
    then: Yup.string()
      .required("IHD Client ID cannot be empty")
      .typeError("IHD Client ID is invalid or empty"),
  }),
  ihdClientSecret: Yup.mixed().when("extendWithIHD", {
    is: true,
    then: Yup.string()
      .required("IHD Client Secret cannot be empty")
      .typeError("IHD Client Secret is invalid or empty"),
  }),
  ihdLocationId: Yup.mixed().when("extendWithIHD", {
    is: true,
    then: Yup.string()
      .required("IHD Location ID cannot be empty")
      .typeError("IHD Location ID is invalid or empty"),
  }),
  deliveryListingAddresses: Yup.mixed().when("deliveryOption", {
    is: "listing",
    then: Yup.mixed()
      .required("Delivery listing address cannot be empty")
      .typeError("Delivery listing address is invalid or empty")
      .test(
        "listing-unique-address",
        "Listing address should be unique",
        function (value) {
          const addresses = value.map((address) => address.formatted_address);
          const addressSet = new Set(addresses);
          return addresses.length === addressSet.size;
        }
      ),
  }),
});

export const validationSchemaAddLocation = Yup.object().shape({
  general_locationName: Yup.string()
    .trim()
    .min(5, "minimum 5 characters required")
    .required("Required"),

  general_timezone: Yup.string().trim().required("Required"),
  general_displayAddress: Yup.string()
    .trim()
    .max(200, "more than 200 character not allowed")
    .required("Required"),
  general_address: Yup.string()
    .trim()
    .max(200, "more than 200 character not allowed")
    .required("Required"),
  general_phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required")
    .max(10, "cannot be more than 10"),
  general_email: Yup.string()
    .email("Must be a valid email")
    .required("Required"),
  general_sortOrder: Yup.number().min(0, "Sort order cannot be less than 0"),
  pickupPrepTime: Yup.number().when("pickup", {
    is: true,
    then: Yup.number()
      .min(1, "Preparation time cannot be less than 1")
      .max(120, "Preparation time cannot be more than 120"),
  }),

  location_tax: Yup.number().when("locationBasedTax", {
    is: true,
    then: Yup.number()
      .min(0, " Location tax cannot be less than 0")
      .required("Field is required"),
  }),
  location_tax_title: Yup.string().when("locationBasedTax", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),

  service_charge_percentage: Yup.number().when("serviceCharge", {
    is: true,
    then: Yup.number()
      .min(1, " Service charge cannot be less than 1")
      .required("Field is required"),
  }),
  service_charge_title: Yup.string().when("serviceCharge", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),

  smsAlertPhone: Yup.string(),
  orderEmail: Yup.string(),

  txnReportEmail: Yup.string().when("txnReport", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  closeOfBusinessEmail: Yup.string().when("closeOfBusiness", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  txnReportFrequency: Yup.array().when("txnReport", {
    is: true,
    then: Yup.array().min(1, "Atleast one frequency should be selected"),
  }),
  cateringMethod: Yup.string().when("cateringShop", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),

  cateringMinHeadCount: Yup.number().when("cateringShop", {
    is: true,
    then: Yup.number().required("Field is required"),
  }),

  cateringOrderFee: Yup.string().when("cateringShop", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
});

export const deliveryZoneArrayValidation = Yup.array()
  .of(zoneValidation)
  .min(1, "Please add atleast one delivery zone")
  .test(
    "zone-radius-valdiation",
    "Zone should be defined in the incrementing order of radius",
    function (value) {
      console.log("zone: validation", value);
      const radiuses = value.map((zone) => zone.deliveryRadius);
      // check if radiuses are in incrementing order
      const isRadiusesInIncrementingOrder = radiuses.every((radius, index) => {
        if (index === 0) return true;
        return radius >= radiuses[index - 1];
      });
      console.log("zone: is incrementing", isRadiusesInIncrementingOrder);
      return isRadiusesInIncrementingOrder;
    }
  )
  .test(
    "validate-listing-unique",
    "Delivery listing only works with one zone",
    function (value) {
      const hasListing = value.some(
        (zone) => zone.deliveryOption === "listing"
      );
      if (hasListing) {
        return value.length === 1;
      }
      return true;
    }
  )
  .required();

export const validationSchemaCateringSettings = Yup.object().shape({
  cateringMinHeadCount: Yup.number()
    .min(1, "Please define minmum headcount.")
    .required("Required"),
  cateringHours: Yup.array().min(
    1,
    "Please define catering hours for catering"
  ),
  cateringPrepTimes: Yup.array().min(
    1,
    "Please define prep times for catering"
  ),
  cateringOrderFee: Yup.string().required(
    "Please select order fee settings for catering"
  ),
});

export const validationSchemaEditLocation = Yup.object().shape({
  general_locationName: Yup.string()
    .trim()
    .min(5, "minimum 5 characters required")
    .required("Required"),

  general_timezone: Yup.string().trim().required("Required"),
  general_displayAddress: Yup.string()
    .trim()
    .max(200, "more than 200 character not allowed")
    .required("Required"),
  general_address: Yup.string()
    .trim()
    .max(200, "more than 200 character not allowed")
    .required("Required"),
  general_phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required")
    .max(10, "cannot be more than 10"),
  general_email: Yup.string()
    .email("Must be a valid email")
    .required("Required"),
  general_sortOrder: Yup.number().min(0, "Sort order cannot be less than 0"),
  pickupPrepTime: Yup.number().when("pickup", {
    is: true,
    then: Yup.number()
      .min(1, "Preparation time cannot be less than 1")
      .max(120, "Preparation time cannot be more than 120"),
  }),
  location_tax: Yup.number().when("locationBasedTax", {
    is: true,
    then: Yup.number()
      .min(0, " Location tax cannot be less than 0")
      .required("Field is required"),
  }),
  location_tax_title: Yup.string().when("locationBasedTax", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),

  service_charge_percentage: Yup.number().when("serviceCharge", {
    is: true,
    then: Yup.number()
      .min(1, " Service charge cannot be less than 1")
      .required("Field is required"),
  }),
  service_charge_title: Yup.string().when("serviceCharge", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),

  smsAlertPhone: Yup.string(),
  orderEmail: Yup.string(),

  walkupOrderingPaymentMethods: Yup.mixed().when("enableWalkupOrdering", {
    is: true,
    then: Yup.array()
      .min(1, "Please select atleast one payment method")
      .required("Please select atleast one payment method"),
  }),
  orderAlertPhoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .optional()
    .max(10, "cannot be more than 10"),
  txnReportEmail: Yup.string().when("txnReport", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  closeOfBusinessEmail: Yup.string().when("closeOfBusiness", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  txnReportFrequency: Yup.array().when("txnReport", {
    is: true,
    then: Yup.array().min(1, "Atleast one frequency should be selected"),
  }),
  cateringMethod: Yup.string().when("cateringShop", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),

  cateringMinHeadCount: Yup.number().when("cateringShop", {
    is: true,
    then: Yup.number().required("Field is required"),
  }),

  cateringOrderFee: Yup.string().when("cateringShop", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
});
