/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import React, { useContext, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// react library for routing
import { Link, NavLink as NavLinkRRD, useLocation } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";
import { AuthContext } from "../../context";
import usePartnerId from "../../hooks/usePartnerId";
import { useEntity } from "../../hooks/useEntity";

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) {
  const [state, setState] = React.useState({});
  const [hoverSidenav, setHoverSidenav] = useState(false);
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const partner = usePartnerId();
  const { findOne, entity } = useEntity("partner");
  console.log("PARTNER ID:" + partner);

  React.useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (partner) {
      findOne(partner);
    }
  }, [partner]);

  React.useEffect(() => {
    if (isBrowser) {
      toggleSidenav();
    }
  }, []);

  const getToHref = (layout, path, search) => {
    let str = `${layout}${path}`;
    if (search) {
      str += search;
    }

    return str;
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ||
      location.pathname + "/" === routeName
      ? "text-link"
      : "";
  };

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
    setHoverSidenav(true);
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
    setHoverSidenav(false);
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes?.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        if (
          prop?.path?.includes("mobile-app") &&
          partner !== "638667fea3bd29bee53deab4"
        ) {
          return <></>;
        }
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <div className="text-center">
                  <i className={`${prop.icon}`} style={{}} />
                  <span className="nav-link-text ml-2">{prop.name}</span>
                </div>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse
              isOpen={sidenavOpen && !hoverSidenav ? false : state[prop.state]}
            >
              <Nav className="nav-sm flex-column ml-4">
                {createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }

      if (
        prop?.path?.includes("foodtronix") &&
        !entity?.data?.partner?.enableFoodTronix
      ) {
        return <></>;
      }

      return (
        <NavItem key={key}>
          <NavLink
            to={`${getToHref(prop.layout, prop.path, location.search)}`}
            activeClassName={activeRoute(prop.layout + prop.path)}
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <div className="text-center">
                <i className={`${prop.icon}`} style={{}} />
                <span className="nav-link-text ml-2">{prop.name}</span>
              </div>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <>
            {isMobile ? (
              <>
                <img
                  alt={logo.imgAlt}
                  src={logo.imgSrc}
                  className="mx-4 mt-3"
                  style={{ height: 70 }}
                />
              </>
            ) : (
              <NavbarBrand {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            )}
          </>
        ) : null}

        {/* <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: !sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div> */}
      </div>

      <div className={`${isMobile ? "mt-3" : ""}`}>
        {((authContext?.user?.role === "super-admin" &&
          !window.location.pathname.includes("admin")) ||
          (authContext?.user?.role === "partner" &&
            !window.location.pathname.includes("partner"))) && (
          <a
            href={
              authContext?.user?.role === "partner"
                ? "/partners"
                : authContext?.user?.role === "super-admin"
                ? "/admin"
                : "#"
            }
            className="text-sm"
            style={{ fontWeight: "bold" }}
          >
            Return To Admin
          </a>
        )}
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
