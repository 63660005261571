import { Col, Container, Input, Row } from "reactstrap";

export default function Integration({ errors, touched, values, handleChange }) {
  return (
    <div style={{ zIndex: 999, position: "relative" }}>
      <Container fluid>
        <Row className="mt-3">
          <Col md="6">
            <Row className="text-left">
              <Col
                md="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Enable Deliverect
              </Col>
              <Col md="6">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enable_deliverect}
                    value={values?.enable_deliverect}
                    onChange={handleChange("enable_deliverect")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md="6">
            <Row className="text-left">
              <Col
                md="4"
                style={{
                  display: "flex",
                  // justifyContent: isMobile ? "center" : "flex-end",
                  alignItems: "center",
                  // textAlign: "right",
                }}
              >
                Client ID
              </Col>
              <Col md="8" className="mt-2">
                <Input
                  type="text"
                  disabled={!values?.enable_deliverect}
                  onChange={handleChange}
                  value={values.deliverect_client_id}
                  name="deliverect_client_id"
                  className={`form-control ${
                    errors.deliverect_client_id && touched.deliverect_client_id
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </Col>
            </Row>
          </Col>
          <Col cl md="6">
            <Row>
              <Col
                md="4"
                style={{
                  display: "flex",
                  // justifyContent: isMobile ? "center" : "flex-end",
                  alignItems: "center",
                }}
              >
                Client Secret
              </Col>
              <Col md="8" className="mt-2">
                <Input
                  type="text"
                  name="deliverect_client_secret"
                  disabled={!values?.enable_deliverect}
                  onChange={handleChange}
                  value={values.deliverect_client_secret}
                  className={`form-control ${
                    errors.deliverect_client_secret &&
                    touched.deliverect_client_secret
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
