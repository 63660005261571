import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../components/Header/SimpleHeader";
import { AuthContext } from "../../context";
import { useEntity } from "../../hooks/useEntity";
import { useAdminStore } from "../../store/admin";
import logger from "../../utils/logger";

const CustomeAlerts = () => {
  const authContext = useContext(AuthContext);
  const { updateEntity } = useEntity("restaurant");
  const { restaurant } = useAdminStore();
  const { findOne, loading, entity } = useEntity("restaurant");

  useEffect(() => {
    findOne(restaurant);
  }, [restaurant]);

  const { handleChange, values, handleSubmit, isSubmitting, setValues } =
    useFormik({
      initialValues: {
        home_title: "",
        home_popup: false,
        home_message: "",
        category_title: "",
        category_popup: false,
        category_message: "",
        custom_note: "",
        checkout_title: "",
        checkout_popup: false,
        checkout_message: "",
      },
      onSubmit: async (values, { resetForm }) => {
        let data = {
          home_popup: {
            title: values?.home_title,
            home_popup: values?.home_popup,
            message: values?.home_message,
          },
          category_popup: {
            title: values?.category_title,
            category_popup: values?.category_popup,
            message: values?.category_message,
            note: values?.custom_note,
          },
          checkout_popup: {
            title: values?.checkout_title,
            checkout_popup: values?.checkout_popup,
            message: values?.checkout_message,
          },
        };
        try {
          if (authContext.user.restaurant || restaurant) {
            await updateEntity(restaurant || authContext.user.restaurant, {
              pop_up_alerts: { ...data },
            });
          }
          toast.success("Alerts has been added successfully");
          resetForm();
        } catch (error) {
          logger.push({ error, info: "CustomAlerts" });
          toast.error(error.message);
        }
      },
    });

  useEffect(() => {
    if (entity) {
      setValues({
        home_popup:
          entity?.data?.restaurant?.pop_up_alerts?.home_popup?.home_popup,
        category_popup:
          entity?.data?.restaurant?.pop_up_alerts?.category_popup
            ?.category_popup,
        checkout_popup:
          entity?.data?.restaurant?.pop_up_alerts?.checkout_popup
            ?.checkout_popup,
        home_title: entity?.data?.restaurant?.pop_up_alerts?.home_popup?.title,
        home_message:
          entity?.data?.restaurant?.pop_up_alerts?.home_popup?.message,
        category_title:
          entity?.data?.restaurant?.pop_up_alerts?.category_popup?.title,
        category_message:
          entity?.data?.restaurant?.pop_up_alerts?.category_popup?.message,
        custom_note:
          entity?.data?.restaurant?.pop_up_alerts?.category_popup?.note,
        checkout_title:
          entity?.data?.restaurant?.pop_up_alerts?.checkout_popup?.title,
        checkout_message:
          entity?.data?.restaurant?.pop_up_alerts?.checkout_popup?.message,
      });
    }
  }, [entity]);

  return (
    <>
      <TimelineHeader
        name="Custom Alerts"
        parentName="Marketing"
        showBtn={true}
        path="/resturants"
      >
        <>
          <Button color="primary" onClick={handleSubmit}>
            {isSubmitting ? (
              <Spinner color="white" size="sm" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </>
      </TimelineHeader>
      <Container fluid>
        <Card className="mt--6">
          <CardBody>
            {!loading ? (
              <div>
                <Form>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Home Page Popup
                        </label>
                      </Col>
                      <Col md="1">
                        <label className="custom-toggle">
                          <input
                            checked={values?.home_popup}
                            onChange={handleChange("home_popup")}
                            value={values?.home_popup}
                            type="checkbox"
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                        >
                          Page Title
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          value={values.home_title}
                          placeholder="Pop Title"
                          onChange={handleChange("home_title")}
                          type="text"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Pop Msg
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          onChange={handleChange("home_message")}
                          value={values?.home_message}
                          type="text"
                          placeholder="Pop Msg"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <hr />
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Category Page Popup
                        </label>
                      </Col>
                      <Col md="1">
                        <label className="custom-toggle">
                          <input
                            checked={values?.category_popup}
                            onChange={handleChange("category_popup")}
                            value={values?.category_popup}
                            type="checkbox"
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Page Title
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          onChange={handleChange("category_title")}
                          value={values?.category_title}
                          type="text"
                          placeholder="Page Title"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Pop Msg
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          onChange={handleChange("category_message")}
                          value={values?.category_message}
                          type="text"
                          placeholder="Pop Msg"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Custom Note
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          onChange={handleChange("custom_note")}
                          value={values?.custom_note}
                          type="text"
                          placeholder="Custom Note"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <hr />
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Checkout Page Popup
                        </label>
                      </Col>
                      <Col md="1">
                        <label className="custom-toggle">
                          <input
                            onChange={handleChange("checkout_popup")}
                            value={values?.checkout_popup}
                            type="checkbox"
                            checked={values?.checkout_popup}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Page Title
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          onChange={handleChange("checkout_title")}
                          value={values?.checkout_title}
                          type="text"
                          placeholder="Page Title"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="2">
                        <label
                          style={{
                            fontSize: 15,
                            fontWeight: "400",
                            textAlign: "right",
                            width: "100%",
                          }}
                          htmlFor="exampleFormControlSelect2"
                        >
                          Pop Msg
                        </label>
                      </Col>
                      <Col md="8">
                        <Input
                          onChange={handleChange("checkout_message")}
                          value={values?.checkout_message}
                          type="text"
                          placeholder="Pop Msg"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <hr />
                </Form>
              </div>
            ) : (
              <div
                style={{
                  padding: 20,
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <center>
                  <Spinner size="lg" color="primary" type="border" />
                </center>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default CustomeAlerts;
