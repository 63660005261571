import { isMobile } from "react-device-detect";
import ReactQuill from "react-quill";
import { Col, Input, Row } from "reactstrap";

export default function Privacy({ setFieldValue, values }) {
  return (
    <>
      <Row className="mt-4">
        <Col md="6">
          <Row>
            <Col
              md="4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Privacy
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <ReactQuill
                theme="snow"
                value={values?.privacy}
                onChange={(e) => setFieldValue("privacy", e)}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Terms
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <ReactQuill
                theme="snow"
                value={values?.terms}
                onChange={(e) => setFieldValue("terms", e)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="6">
          <Row>
            <Col
              md="4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Privacy URL
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Input
                type="text"
                name="privacyUrl"
                onChange={(e) => {
                  setFieldValue("privacyUrl", e.target.value);
                }}
                value={values.privacyUrl}
              />
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col
              md="4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Terms URL
            </Col>
            <Col md="8" className={isMobile && "my-2"}>
              <Input
                type="text"
                name="termsUrl"
                onChange={(e) => {
                  setFieldValue("termsUrl", e.target.value);
                }}
                value={values.termsUrl}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <small style={{ textAlign: "left", margin: 20 }}>
          * Text under privacy and terms will be ignored if URL is defined
        </small>
      </Row>
    </>
  );
}
