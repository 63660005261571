import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchemaAddPartner = Yup.object().shape({
  partnerUrl: Yup.string().required("Please enter website"),
  name: Yup.string().trim().max(50, "Too Long!").required("Required"),
  address: Yup.string().trim().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Too Short!")
    .max(50, "Too Long!"),
  phone: Yup.string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  fixedValue: Yup.number().when("orderFee", {
    is: "Fixed",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  firstTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  secondTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  thirdTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  fourthTierUpto: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  firstTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  secondTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  thirdTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
  fourthTierOnlineOrder: Yup.number().when("orderFee", {
    is: "Tiered",
    then: Yup.number()
      .min(0, "cannot be less than 0")
      .max(999, "cannot be more tha 999")
      .required("Required"),
  }),
});
