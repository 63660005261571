import Rollbar from "rollbar";

const _rollbarConfig = {
  accessToken: "e32969f8c39745aa92caecb695f1e414",
  captureUncaught: false,
  captureUnhandledRejections: false,
};

const rollbar = new Rollbar(_rollbarConfig);

export default rollbar;
