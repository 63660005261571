import React, { useState } from "react";
import { Col, Row } from "reactstrap";

export default function Tabs({ onChange, theme }) {
  const [tab, setTab] = useState(0);
  const styleActive = {
    padding: 9,
    backgroundColor: theme.primary,
    color: "#fff",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const styleInactive = {
    padding: 9,
    backgroundColor: "#f5f5f5",
    color: "#000",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Row
      style={{
        backgroundColor: "#f5f5f5",
        borderRadius: 10,
        marginLeft: "2px",
      }}
    >
      <Col
        className=""
        style={tab == 0 ? styleActive : styleInactive}
        onClick={() => {
          setTab(0);
          onChange(0);
        }}
      >
        Delivery
      </Col>
      <Col
        className=""
        style={tab == 1 ? styleActive : styleInactive}
        onClick={() => {
          setTab(1);
          onChange(1);
        }}
      >
        Takeout
      </Col>
    </Row>
  );
}
