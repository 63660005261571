import classnames from "classnames";
import { useState } from "react";
// reactstrap components
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { useRestaurantStore } from "../../../store/restaurant";
import TableWrapper from "../../../widgets/TableWrapper";
import CopyModifiers from "./tabs/CopyModifiers";
import OptionsTab from "./tabs/OptionsTab";

export default function Options(props) {
  const { restaurant: restaurantDoc } = useRestaurantStore();
  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <>
      <TimelineHeader
        path="/resturants"
        name="Modifiers"
        parentName="Dashboard"
        showBtn={true}
      >
        {!restaurantDoc?.restaurant?.enable_deliverect && tabs === 1 && (
          <Button
            className="btn-icon btn-2"
            color="primary"
            type="button"
            size="md"
            disabled={restaurantDoc?.restaurant?.enable_deliverect}
            onClick={() => {
              props.history.push({
                pathname:
                  tabs === 1
                    ? `/resturants/add-modifier`
                    : "/resturants/add-modifier-group",
                search: props.location.search,
              });
            }}
          >
            Add new
          </Button>
        )}
      </TimelineHeader>
      <TableWrapper customStyles="px-4 py-3">
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 1,
              })}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Modifiers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("mb-sm-3 mb-md-0 mt-2", {
                active: tabs === 2,
              })}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Copy Modifiers
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="mt-3" activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <OptionsTab {...props} />
          </TabPane>
          <TabPane tabId="tabs2">
            <CopyModifiers />
          </TabPane>
        </TabContent>
      </TableWrapper>
    </>
  );
}
