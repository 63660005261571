/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Card, Spinner, Table } from "reactstrap"
import swal from "sweetalert"
import { AuthContext } from "../../../context"
import { useEntity } from "../../../hooks/useEntity"
import { useAdminStore } from "../../../store/admin"
import AddTaxRateModal from "./components/AddTaxModalRate"

export default function TaxRates(props) {
  const authContext = useContext(AuthContext)
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id
  const { restaurant } = useAdminStore()
  const [editModal, setEditModal] = useState(false)
  const [activeItem, setActiveItem] = useState()
  const { find, entities, loading } = useEntity("tax-rate")
  const { deleteEntity } = useEntity("tax-rate")

  useEffect(() => {
    find({
      resturant: authRest || restaurant,
    })
  }, [])

  if (loading) {
    return (
      <div
        style={{
          padding: 20,
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <center>
          <Spinner size="lg" color="primary" type="border" />
        </center>
      </div>
    )
  }

  return (
    <>
      <AddTaxRateModal
        isVisible={editModal}
        toggle={() => {
          setEditModal(false)
          setActiveItem()
        }}
        id={activeItem?._id}
        data={activeItem}
      />
      <Card>
        <Table responsive className="align-items-center">
          <thead className="thead-light">
            <tr>
              <th>Tax Name</th>
              <th>Tax Rate</th>
              <th>Type</th>
              <th>Date Added</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {entities?.data?.length <= 0 && (
              <tr>
                <td>No tax rates defined yet</td>
              </tr>
            )}
            {entities?.data?.map((t, i) => {
              return (
                <tr key={`keyvalue-${i}`}>
                  <td>{t.name}</td>
                  <td>{t?.type === "percentage" ? t.rate : t?.fixedAmount}</td>
                  <td>{t.type}</td>

                  <td>{moment(t.createdAt).format("MM/DD/YYYY")}</td>
                  <td>
                    <i
                      className="fa fa-edit"
                      style={{ cursor: "pointer", fontSize: 18 }}
                      onClick={() => {
                        setActiveItem(t)
                        setEditModal(true)
                      }}
                    />
                    <i
                      className="fa fa-trash text-danger mx-2"
                      style={{ cursor: "pointer", fontSize: 18 }}
                      onClick={async () => {
                        try {
                          swal({
                            title: "Are you sure?",
                            text: "Once deleted, you will not be able to recover!",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              await deleteEntity(t?._id)
                              toast.error("Tax rate Deleted Succesfully")
                            }
                          })
                        } catch (error) {
                          toast(error.message)
                        }
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Card>
    </>
  )
}
