import { useFormik } from "formik";
import { MdEmail } from "react-icons/md";
import { toast } from "react-toastify";
import { Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import OrderingLayout from "../../../components/Layout/OrderingLayout";
import { useEntity } from "../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../store/theme";
import ErrorLable from "../../../utils/ErrorLable";
import logger from "../../../utils/logger";

const ForgotPassword = (props) => {
  let history = props.history;
  const { theme } = useTheme();

  const { create, loading } = useEntity("eat/auth/reset");

  const { restaurant } = useOrderingRestaurant();

  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    useFormik({
      initialValues: {
        email: "",
      },

      onSubmit: async (values) => {
        try {
          const customer = await create({
            email: values.email,
            restaurant: restaurant?.userId?._id,
          });
          if (customer) {
            history.push({
              pathname: "verify-otp",
              state: { customer },
            });
          }
        } catch (error) {
          logger.push({ error, info: "Customer_Forgot_Password" });
          toast.error(error.message);
        }
      },
      validationSchema: Yup.object({
        email: Yup.string().email().required("Email is required"),
      }),
    });

  return (
    <OrderingLayout
      isCenter={true}
      loading={loading}
      title="Forgot Password"
      history={history}
      classname="main_ordering_card_wide"
      layout={true}
    >
      <div>
        <div className="text-center forgot_pass_text">
          Enter the e-mail address associated with your account. If an account
          exists, we will send a reset password email.
        </div>
        <div
          className="rounded mt-4"
          style={{
            display: "flex",
            backgroundColor: "#ededed",
            alignItems: "center",
          }}
        >
          <input
            className="signin_input_ordering"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Email address"
          />

          <MdEmail className="icons_signin" size={30} />
        </div>
        {touched.email && errors.email && <ErrorLable message={errors.email} />}
        <div className=" mt-3">
          <Button
            disabled={loading}
            block
            style={{
              backgroundColor: theme?.primary,
              height: 45,
              color: "white",
            }}
            onClick={handleSubmit}
          >
            {loading ? <Spinner size="sm" /> : "CONTINUE"}
          </Button>
        </div>
      </div>
    </OrderingLayout>
  );
};

export default ForgotPassword;
