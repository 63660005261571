import * as Yup from "yup"

export const validationSchemaAddPartnerCoupon = Yup.object().shape({
  couponName: Yup.string().trim().required("Required"),
  couponCode: Yup.string()
    .required("Required")
    .matches(/^(\S+$)/g, "This field cannot contain only blankspaces"),
  couponType: Yup.string().required("Required"),
  couponDiscount: Yup.number().when("couponType", {
    is: "percentage" || "fixed",
    then: Yup.number().moreThan(0, "Must be more than 0").required("Required"),
  }),
  subTotal: Yup.number()
    .moreThan(0, "Must be more than 0")
    .required("Required"),
  startDate: Yup.string().required("Required"),
  endDate: Yup.string().required("Required"),
  method: Yup.string().required("Required"),
  status: Yup.boolean().required("Required"),
})
