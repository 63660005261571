import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { SelectionComponent } from "../../../../components/SelectionComponent";
import { generateTimeslots } from "../../../../utils/Timeslot";

const slots = generateTimeslots(15, "00:00", "23:59");

const toAMPM = (time) => {
  return moment(time, "HH:mm").format("hh:mm A");
};

const AddCustomPrepTimeModal = ({ open, toggle, setData, validate }) => {
  const [fromSlots, setFromSlots] = useState([]);
  const [toSlots, setToSlots] = useState([]);

  const {
    resetForm,
    setFieldValue,
    values,
    handleSubmit,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      from: "",
      to: "",
      day: "",
      methods: [],
      time: "",
      _errorPlaceholder: "",
    },
    onSubmit: (values, { resetForm, setErrors }) => {
      if (!validate(values)) {
        setErrors({
          ...errors,
          _errorPlaceholder: "This time slot already exists",
        });
        return;
      }
      setData(values);
      resetForm();
      toggle();
    },
    validationSchema: Yup.object({
      from: Yup.string()
        // .required("From is required")
        .test({
          test: (value, ctx) => {
            if (ctx.parent.to) {
              const from = moment(value, "HH:mm");
              const to = moment(ctx.parent.to, "HH:mm");
              if (from >= to) {
                return ctx.createError({
                  message: "From must be less than To",
                  path: "from",
                });
              }
            }
            return true;
          },
        })
        .required("From is required"),
      to: Yup.string().required("To is required"),
      day: Yup.string().required("Day is required"),
      methods: Yup.array().min(1).required("Methods is required"),
      time: Yup.number()
        .max(200, "Prep time can not be more 200 minutes")
        .required("Prep Time is required"),
    }),
  });

  useEffect(() => {
    const slotsGenerated = [...slots];
    const sltArr = slotsGenerated.filter((s) => s !== values.to);
    setFromSlots([...sltArr]);
  }, [values.to]);

  useEffect(() => {
    const slotsGenerated = [...slots];
    const sltArr = slotsGenerated.filter((s) => s !== values.from);
    setToSlots([...sltArr]);
  }, [values.from]);

  return (
    <div>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-white text-dark"
        isOpen={open}
        toggle={() => toggle()}
      >
        <div className="modal-header" style={{ display: "flex" }}>
          <h2>Add Time Based Prep Time</h2>
        </div>

        <ModalBody>
          <Row md={12}>
            <Col md={6}>
              <div className="mr-3">From</div>
              <div style={{ width: "100%" }}>
                <SelectionComponent
                  isSearchable={false}
                  multi={false}
                  onBlur={handleBlur}
                  value={{
                    label: values.from ? toAMPM(values.from) : "",
                    value: values.from || undefined,
                  }}
                  onSelect={(e) => {
                    setFieldValue("from", e.value);
                  }}
                  options={fromSlots?.map?.((s) => {
                    return {
                      label: toAMPM(s),
                      value: s,
                    };
                  })}
                />
                {errors?.from && touched?.from && (
                  <small className="text-red">{errors.from}</small>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="mr-3">To</div>
              <div style={{ width: "100%" }}>
                <SelectionComponent
                  multi={false}
                  isSearchable={false}
                  onBlur={handleBlur}
                  value={{
                    label: values.to ? toAMPM(values.to) : "",
                    value: values.to,
                  }}
                  onSelect={(e) => {
                    setFieldValue("to", e.value);
                  }}
                  options={toSlots
                    ?.filter((slt) => {
                      return slt !== values.from;
                    })
                    ?.map((s) => {
                      return {
                        label: toAMPM(s),
                        value: s,
                      };
                    })}
                />
                {errors?.to && touched?.to && (
                  <small className="text-red">{errors.to}</small>
                )}
              </div>
            </Col>
          </Row>
          <Row md={12} className="mt-3">
            <Col md={12} className="mb-3">
              <div className="mr-3">Day</div>
              <div style={{ width: "100%" }}>
                <SelectionComponent
                  isSearchable={false}
                  multi={false}
                  onBlur={handleBlur}
                  value={{
                    label: values.day,
                    value: values.day,
                  }}
                  onSelect={(e) => {
                    setFieldValue("day", e.value);
                  }}
                  options={[
                    { label: "Monday", value: "Monday" },
                    { label: "Tuesday", value: "Tuesday" },
                    { label: "Wednesday", value: "Wednesday" },
                    { label: "Thursday", value: "Thursday" },
                    { label: "Friday", value: "Friday" },
                    { label: "Saturday", value: "Saturday" },
                    { label: "Sunday", value: "Sunday" },
                  ]}
                />
                {errors?.day && touched?.day && (
                  <small className="text-red">{errors.day}</small>
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mr-3">Methods</div>
              <div style={{ width: "100%" }}>
                <SelectionComponent
                  isSearchable={false}
                  multi={true}
                  onBlur={handleBlur}
                  value={values.methods.map((m) => {
                    return {
                      label: m.charAt(0).toUpperCase() + m.slice(1),
                      value: m,
                    };
                  })}
                  onSelect={(options) => {
                    setFieldValue(
                      "methods",
                      options.map((o) => o.value)
                    );
                  }}
                  options={[
                    { label: "Delivery", value: "delivery" },
                    { label: "Pickup", value: "pickup" },
                  ]}
                />
                {errors?.methods && touched?.methods && (
                  <small className="text-red">{errors.methods}</small>
                )}
              </div>
            </Col>
          </Row>
          <Row md={12} className="mt-3">
            <Col md={12}>
              <div className="mr-3">Prep Time (minutes)</div>
              <div style={{ width: "100%" }}>
                <Input
                  type="text"
                  onChange={(e) => {
                    setFieldValue("time", e.target.value);
                  }}
                />
                {errors?.time && touched?.time && (
                  <small className="text-red">{errors.time}</small>
                )}
              </div>
            </Col>
          </Row>
          <Row md={12} className="mt-3">
            <Col md={12}>
              {errors?._errorPlaceholder && (
                <small className="text-red">{errors._errorPlaceholder}</small>
              )}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button
            onClick={() => {
              resetForm();
              toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCustomPrepTimeModal;
