/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import LoyalityStar from "../../../../assets/icons/loyality_image.svg";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import MyAccount from "../../home/components/MyAccount";
const LoyalityComponent = ({ mobile, view, border, balance }) => {
  const authContext = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const { restaurant } = useOrderingRestaurant();

  const { find: findLoyalty, entities: loyaltyPoints } = useEntity(
    "eat/ordering/loyalty-points"
  );

  useEffect(() => {
    if (authContext?.user) {
      findLoyalty({
        customer: authContext?.user?.customer?._id,
        restaurant: restaurant?.userId?._id,
      });
    }
  }, [authContext]);

  if (!authContext.user) {
    return <></>;
  }

  return (
    <div onClick={() => setModal(true)}>
      {mobile ? (
        <div style={{ borderBottom: "1px solid #ededed" }}>
          <div className=" d-flex align-items-center">
            <div>
              <img style={{ height: 40 }} src={LoyalityStar} alt="" />
            </div>
            <div
              className="mx-3"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>LOYALITY </span>
                <span className="mx-2 mb-1">
                  <AiOutlineInfoCircle />
                </span>
              </span>
              <span style={{ fontSize: 16, fontWeight: "600" }}>
                {Math.ceil(loyaltyPoints?.data?.points) || 0} PTS
              </span>
            </div>
            <div className="mx-2 bold">View</div>
          </div>
        </div>
      ) : (
        <div
          className="py-2"
          style={{ borderBottom: border && "1px solid #ededed" }}
        >
          <div className="px-3 py-2 loyality_menu_sidebar">
            <div>
              <img style={{ height: 40 }} src={LoyalityStar} alt="" />
            </div>
            <div
              className="mx-3"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>LOYALTY </span>
                {view && (
                  <span className="mx-2 mb-1">
                    <AiOutlineInfoCircle />
                  </span>
                )}
              </span>
              <span style={{ fontSize: 16, fontWeight: "600" }}>
                {Math.ceil(loyaltyPoints?.data?.points) || 0} PTS
              </span>
            </div>
            {view && <div className="mx-2 bold">View</div>}
          </div>
        </div>
      )}
      <MyAccount open={modal} toggle={() => setModal(!modal)} activeTabs={3} />
    </div>
  );
};

export default LoyalityComponent;
