import { ListGroup, ListGroupItem } from "reactstrap";

const CategoryList = ({ cats, catName, catSelected, onClick }) => {
  return (
    <>
      <ListGroup className="">
        <ListGroupItem
          className={`text-left group_list_cat ${
            catSelected?.name === cats?.categoryName
              ? "active-d hover_cat"
              : "hover_cat"
          }
                             `}
          style={{
            fontSize: 16,
            fontWeight: "600",
            cursor: "pointer",
            borderRadius: "0px",
            background: " #FFFFFF",
          }}
          onClick={() => {
            catName({
              name: cats?.categoryName,
              description: cats?.description,
            });
            onClick(cats?._id);
          }}
        >
          {cats?.categoryName}
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default CategoryList;
