import { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import TableLoader from "../../../components/TableLoader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import SuperTable from "../../../widgets/SuperTable";
import TableWrapper from "../../../widgets/TableWrapper";
import AddTagModal from "./components/AddTagModal";
import TableRowTags from "./components/TableRowTags";

const Tags = (props) => {
  const Headers = ["Name", "Date", "Action"];
  const { find, entities, loading } = useEntity("tag");
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openTagModal, setOpenTagModal] = useState(false);

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      page,
      limit: rowsPerPage,
      sorting,
    });
  }, [restaurant, page, sorting, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  return (
    <>
      <TimelineHeader
        parentPath="/resturants"
        name="Tags"
        parentName="Dashboard"
        showBtn={true}
        showSearch={false}
        placeholder="Search"
        path="/resturants"
      >
        <>
          <Button
            color="primary"
            type="button"
            size="md"
            onClick={() => {
              setOpenTagModal(true);
            }}
          >
            Add new
          </Button>
        </>
      </TimelineHeader>
      <TableWrapper customStyles="px-4 py-3">
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            row={TableRowTags}
            loading={loading}
            history={props.history}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no tags"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <TableLoader />
        )}
      </TableWrapper>
      <AddTagModal open={openTagModal} toggle={() => setOpenTagModal(false)} />
    </>
  );
};

export default Tags;
