const endpoints = {
  auth: {
    login: {
      path: "/user/login",
      method: "post",
    },
    verifyToken: {
      path: "/user/verifyToken",
      method: "post",
    },
  },
  admin: {
    createPartner: {
      path: "/user",
      method: "post",
    },
    profile: {
      path: "/user/get",
      method: "get",
    },
  },
  user: {
    create: {
      path: "/user",
      method: "post",
    },
    get: {
      path: "/user",
      method: "get",
    },
    profile: {
      path: "/user/get",
      method: "get",
    },
    delete: {
      path: "/user",
      method: "delete",
    },
    update: {
      path: "/user/update",
      method: "post",
    },
  },
  option: {
    create: {
      path: "/option",
      method: "post",
    },
    view: {
      path: "/option",
      method: "get",
    },
  },

  category: {
    create: {
      path: "/category",
      method: "post",
    },
    view: {
      path: "/category",
      method: "get",
    },
  },

  location: {
    create: {
      path: "/location",
      method: "post",
    },
    view: {
      path: "/location",
      method: "get",
    },
    walkupQR: {
      path: "/location/walkup-qr",
      method: "get",
    },
  },

  taxClass: {
    view: {
      path: "/tax/class",
      method: "get",
    },
  },
  taxRates: {
    view: {
      path: "/tax/rate",
      method: "get",
    },
    add: {
      path: "/tax/rate",
      method: "post",
    },
  },

  restaurant: {
    create: {
      path: "/restaurant",
      method: "post",
    },
    get: {
      path: "/restaurant",
      method: "get",
    },
    profile: {
      path: "/restaurant/get",
      method: "get",
    },
    delete: {
      path: "/restaurant",
      method: "delete",
    },
    update: {
      path: "/restaurant/update",
      method: "post",
    },
    location: {
      path: "/location",
      method: "get",
    },
  },
  gateway: {
    view: {
      path: "/gateway",
      method: "get",
    },
    create: {
      path: "/gateway",
      method: "post",
    },
  },
  order: {
    view: {
      path: "/order",
      method: "get",
    },
  },
  item: {
    create: {
      path: "/item",
      method: "post",
    },
    view: {
      path: "/item",
      method: "get",
    },
  },

  printNode: {
    login: {
      path: "/whoami",
      method: "get",
    },
  },

  ordering: {
    restaurant: {
      path: "/ordering/restaurant",
      method: "get",
    },
    order: {
      path: "/ordering/order",
      method: "get",
    },
    mail: {
      path: "/ordering/mail",
      method: "post",
    },
    location: {
      path: "/ordering/location",
      method: "get",
    },
    history: {
      path: "/ordering/history",
      method: "get",
    },
    items: {
      path: "/ordering/items",
      method: "get",
    },
    categories: {
      path: "/ordering/categories",
      method: "get",
    },
    checkout: {
      path: "/ordering/checkout",
      method: "post",
    },
    pay: {
      path: "/ordering/pay",
      method: "post",
    },
    auth: {
      path: "/ordering/auth",
      method: "post",
    },
    register: {
      path: "/ordering/register",
      method: "post",
    },
  },
  legacy: {
    printerStatus: {
      path: "/print/printer-status",
      method: "post",
    },
  },
};

export default endpoints;
