/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";

import {
  Button,
  Card,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import * as Yup from "yup";
import CustomHours from "./CustomHours";
import { BiTrash } from "react-icons/bi";
import MenuImport from "./MenuImport";

export default function Ordering({ onChangeData, data }) {
  const today = new Date();
  const dayAfterTomorrow = new Date(today);

  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  const formik = useFormik({
    initialValues: {
      enableLargeMap: data?.restaurant?.ordering?.enableLargeMap,
      showModifierName: data?.restaurant?.ordering?.showModifierName,
      viewOnly: data?.restaurant?.ordering?.viewOnly || false,
      enableSMSForExternalOrder: data?.restaurant?.enableSMSForExternalOrder,
      logoWhiteBackground:
        data?.restaurant?.ordering?.logoWhiteBackground || false,
      orderNow: data?.restaurant?.ordering?.orderNow || false,
      orderLater: data?.restaurant?.ordering?.orderLater || false,
      orderForLaterDays: data?.restaurant?.ordering?.orderForLaterDays || 0,
      orderFutureTimeSameDay:
        data?.restaurant?.ordering?.orderFutureTimeSameDay || true,
      orderFutureTimeDifferentDay:
        data?.restaurant?.ordering?.orderFutureTimeDifferentDay || true,
      turnOffSameAndNextDayOrdering:
        data?.restaurant?.ordering?.turnOffSameAndNextDayOrdering || true,
      nextActiveDate:
        data?.restaurant?.ordering?.turnOffSameAndNextDayOrdering === true
          ? dayAfterTomorrow
          : undefined,
      allowTip: data?.restaurant?.ordering?.allowTip || false,
      categoryLayout: data?.restaurant?.ordering?.categoryLayout || "select",
      menuLayout: data?.restaurant?.ordering?.menuLayout || "list",
      disableAmex: data?.restaurant?.ordering?.disableAmex || false,
      pickupPrepTime: data?.restaurant?.ordering?.pickupPrepTime || 10,

      pickupTitle: data?.restaurant?.ordering?.pickupTitle || "",
      deliveryTitle: data?.restaurant?.ordering?.deliveryTitle || "",
      orderForLaterBtnText:
        data?.restaurant?.ordering?.orderForLaterBtnText || "",
      notePlaceholderText:
        data?.restaurant?.ordering?.notePlaceholderText || "",
      peakHours: data?.restaurant?.ordering?.peakHours || 9,
      maxOrdersPerHour: data?.restaurant?.ordering?.maxOrdersPerHour || 10,
      shopCloseTitle: data?.restaurant?.ordering?.shopCloseTitle || "",
      shopCloseText: data?.restaurant?.ordering?.shopCloseText || "",
      ageVerificationWarningMsg:
        data?.restaurant?.ordering?.ageVerificationWarningMsg || "",

      hideSpecialInstruction:
        data?.restaurant?.ordering?.hideSpecialInstruction || false,
      instructionText: data?.restaurant?.ordering?.instructionText || "",
      hideExtraButtons: data?.restaurant?.ordering?.hideExtraButtons || false,

      hideOrderUsualAndPopular:
        data?.restaurant?.ordering?.hideOrderUsualAndPopular,
      skipStep: data?.restaurant?.ordering?.skipStep,
      dineInText: data?.restaurant?.ordering?.dineInText,
      dineInTitle: data?.restaurant?.ordering?.dineInTitle,
      skipStepMethod: data?.restaurant?.ordering?.skipStepMethod,
      skipStepTimeM: data?.restaurant?.ordering?.skipStepTimeM,
      backgroundColor: data?.restaurant?.ordering?.backgroundColor,
      secondaryColor: data?.restaurant?.ordering?.secondaryColor,
      bannerColor: data?.restaurant?.ordering?.bannerColor,
      headerImage: data?.restaurant?.ordering?.headerImage,
      applyServiceTax: data?.restaurant?.ordering?.applyServiceTax,
      allowLoyaltyTransaction:
        data?.restaurant?.ordering?.allowLoyaltyTransaction,
      socialLogin: data?.restaurant?.ordering?.socialLogin || false,
      enableMap: data?.restaurant?.ordering?.enableMap || true,
    },
    validationSchema: Yup.object({
      pickupPrepTime: Yup.number().min(0, "Cannot be less than 0"),
    }),
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    if (data) {
      formik?.setValues({
        logoWhiteBackground:
          data?.restaurant?.ordering?.logoWhiteBackground || false,
        viewOnly: data?.restaurant?.ordering?.viewOnly || false,
        showModifierName: data?.restaurant?.showModifierName || false,
        categoryLayout: data?.restaurant?.ordering?.categoryLayout || "select",
        menuLayout: data?.restaurant?.ordering?.menuLayout || "list",
        orderNow: data?.restaurant?.ordering?.orderNow || false,
        socialLogin: data?.restaurant?.ordering?.socialLogin || false,
        orderLater: data?.restaurant?.ordering?.orderLater || false,
        enableSMSForExternalOrder:
          data?.restaurant?.enableSMSForExternalOrder || false,
        orderForLaterDays: data?.restaurant?.ordering?.orderForLaterDays || 0,
        orderFutureTimeSameDay:
          data?.restaurant?.ordering?.orderFutureTimeSameDay,
        orderFutureTimeDifferentDay:
          data?.restaurant?.ordering?.orderFutureTimeDifferentDay || true,
        turnOffSameAndNextDayOrdering:
          data?.restaurant?.ordering?.turnOffSameAndNextDayOrdering,
        nextActiveDate:
          data?.restaurant?.ordering?.turnOffSameAndNextDayOrdering === true
            ? data?.restaurant?.ordering?.nextActiveDate
            : "",
        allowTip: data?.restaurant?.ordering?.allowTip || false,
        disableAmex: data?.restaurant?.ordering?.disableAmex || false,
        pickupPrepTime: data?.restaurant?.ordering?.pickupPrepTime || 10,

        pickupTitle: data?.restaurant?.ordering?.pickupTitle || "",
        deliveryTitle: data?.restaurant?.ordering?.deliveryTitle || "",
        orderForLaterBtnText:
          data?.restaurant?.ordering?.orderForLaterBtnText || "",
        notePlaceholderText:
          data?.restaurant?.ordering?.notePlaceholderText || "",
        peakHours: data?.restaurant?.ordering?.peakHours || 9,
        maxOrdersPerHour: data?.restaurant?.ordering?.maxOrdersPerHour || 10,
        shopCloseTitle: data?.restaurant?.ordering?.shopCloseTitle || "",
        shopCloseText: data?.restaurant?.ordering?.shopCloseText || "",
        ageVerificationWarningMsg:
          data?.restaurant?.ordering?.ageVerificationWarningMsg || "",

        enableOrderConfirmationMsg:
          data?.restaurant?.ordering?.enableOrderConfirmationMsg || false,
        hideSpecialInstruction:
          data?.restaurant?.ordering?.hideSpecialInstruction || false,
        instructionText: data?.restaurant?.ordering?.instructionText || "",
        hideExtraButtons: data?.restaurant?.ordering?.hideExtraButtons || false,

        hideOrderUsualAndPopular:
          data?.restaurant?.ordering?.hideOrderUsualAndPopular,
        dineInTitle: data?.restaurant?.ordering?.dineInTitle,
        dineInText: data?.restaurant?.ordering?.dineInText,
        skipStep: data?.restaurant?.ordering?.skipStep,
        skipStepMethod: data?.restaurant?.ordering?.skipStepMethod,
        skipStepTimeM: data?.restaurant?.ordering?.skipStepTimeM,
        backgroundColor: data?.restaurant?.ordering?.backgroundColor,
        secondaryColor: data?.restaurant?.ordering?.secondaryColor,
        bannerColor: data?.restaurant?.ordering?.bannerColor,
        headerImage: data?.restaurant?.ordering?.headerImage,
        enableLargeMap: data?.restaurant?.ordering?.enableLargeMap,
        applyServiceTax: data?.restaurant?.ordering?.applyServiceTax,
        allowLoyaltyTransaction:
          data?.restaurant?.ordering?.allowLoyaltyTransaction || false,
        enableMap: data?.restaurant?.ordering?.enableMap,
      });
    }
  }, [data]);

  useEffect(() => {
    onChangeData(formik.values);
  }, [formik.values]);

  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    <div style={{ zIndex: 999, position: "relative", minHeight: "60vh" }}>
      <Card
        className="flex-md-row flex-sm-col"
        style={{
          display: "flex",
          boxShadow: "none",
        }}
      >
        <Nav id="tabs-icons-text" pills vertical style={{ flex: 1 }}>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 1}
              className={classnames(
                "mb-sm-3 mb-md-0 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 1,
                }
              )}
              onClick={(e) => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-list mr-2" />
              General
            </NavLink>
          </NavItem>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 2}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 2,
                }
              )}
              onClick={(e) => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-gift mr-2" />
              Ordering
            </NavLink>
          </NavItem>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 3}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 3,
                }
              )}
              onClick={(e) => toggleNavs(e, 3)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-comments mr-2 " />
              Messaging
            </NavLink>
          </NavItem>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 4}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 4,
                }
              )}
              onClick={(e) => toggleNavs(e, 4)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-refresh mr-2" />
              Skip
            </NavLink>
          </NavItem>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 5}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 5,
                }
              )}
              onClick={(e) => toggleNavs(e, 5)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-spoon mr-2" />
              Dine In
            </NavLink>
          </NavItem>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 6}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 6,
                }
              )}
              onClick={(e) => toggleNavs(e, 6)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-clock-o mr-2" />
              Custom Hours
            </NavLink>
          </NavItem>
          <NavItem className="text-left">
            <NavLink
              aria-selected={tabs === 7}
              className={classnames(
                "mb-sm-3 mb-md-0 mt-2 restaurant_setting_ordering_tabs",
                {
                  active: tabs === 7,
                }
              )}
              onClick={(e) => toggleNavs(e, 7)}
              href="#pablo"
              role="tab"
            >
              <i className="fa fa-bars mr-2" />
              Import Menu
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent style={{ flex: 2 }} activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
            <Row>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Disable AMEX
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.disableAmex}
                        className="ml-3"
                        onChange={formik.handleChange("disableAmex")}
                        value={formik?.values?.disableAmex}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Hide Special Instructions
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.hideSpecialInstruction}
                        className="ml-3"
                        onChange={formik.handleChange("hideSpecialInstruction")}
                        value={formik?.values?.hideSpecialInstruction}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </Col>
              {!formik?.values.hideSpecialInstruction && (
                <Col md="12" className="my-2">
                  <Row>
                    <Col
                      md="5"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        textAlign: "right",
                      }}
                    >
                      Instruction Text
                    </Col>
                    <Col
                      md="7"
                      className={isMobile && "my-2"}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Input
                        maxLength={"122"}
                        checked={formik.values.instructionText}
                        onChange={formik.handleChange("instructionText")}
                        value={formik?.values?.instructionText}
                        type="text"
                        disabled={formik?.values?.hideSpecialInstruction}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Social Login
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.socialLogin}
                        className="ml-3"
                        onChange={formik.handleChange("socialLogin")}
                        value={formik?.values?.socialLogin}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      // textAlign: "right",
                    }}
                  >
                    Hide Order My Usual And Popular Picks
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <label className="custom-toggle">
                      <input
                        checked={formik.values.hideOrderUsualAndPopular}
                        className="ml-3"
                        onChange={formik.handleChange(
                          "hideOrderUsualAndPopular"
                        )}
                        value={formik?.values?.hideOrderUsualAndPopular}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </Col>

              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Pick Up Prep Time (in min)
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="number"
                      value={formik.values.pickupPrepTime}
                      onChange={formik.handleChange("pickupPrepTime")}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Primary color
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="color"
                      value={formik.values.backgroundColor}
                      onChange={formik.handleChange("backgroundColor")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Secondary color
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="color"
                      value={formik.values.secondaryColor}
                      onChange={formik.handleChange("secondaryColor")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="12" className="my-2">
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Banner color
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Input
                      type="color"
                      value={formik.values.bannerColor}
                      onChange={formik.handleChange("bannerColor")}
                    />
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row>
                  <Col
                    md="5"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    Header Image
                  </Col>
                  <Col
                    md="7"
                    className={isMobile && "my-2"}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Col>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImageUploader
                          withIcon={true}
                          buttonText="Choose images"
                          fileContainerStyle={{
                            backgroundColor: "#efefef",
                          }}
                          singleImage
                          maxFileSize={10002880}
                          imgExtension={[
                            ".jpg",
                            ".gif",
                            ".png",
                            ".gif",
                            "webp",
                            "jpeg",
                          ]}
                          withPreview
                          onChange={(f) => {
                            formik.setFieldValue("headerImage", f[0]);
                          }}
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col md="12" className="my-2">
              <Row>
                <Col md="5"></Col>
                <Col md="1"></Col>
                <Col
                  md="6"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {formik.values.headerImage !== "" &&
                    typeof formik.values.headerImage === "string" && (
                      <div className="d-flex flex-column">
                        <img
                          src={formik.values.headerImage}
                          alt={formik.values.headerImage}
                          height={150}
                          width={200}
                        />
                        <Button
                          onClick={() =>
                            formik.setFieldValue("headerImage", "")
                          }
                          className="bg-danger mt-2 text-white"
                        >
                          <BiTrash />
                        </Button>
                      </div>
                    )}
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane tabId={"tabs2"} style={{ textAlign: "left" }}>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Order Now
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.orderNow}
                      className="ml-3"
                      onChange={formik.handleChange("orderNow")}
                      value={formik?.values?.orderNow}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="mt-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-start" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  Order Later
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.orderLater}
                      className="ml-3"
                      onChange={formik.handleChange("orderLater")}
                      value={formik?.values?.orderLater}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Show Modifier Name On Cart
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.showModifierName}
                      className="ml-3"
                      onChange={formik.handleChange("showModifierName")}
                      value={formik?.values?.showModifierName}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="mt-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-start" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  Large Map
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.enableLargeMap}
                      className="ml-3"
                      onChange={formik.handleChange("enableLargeMap")}
                      value={formik?.values?.enableLargeMap}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="mt-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-start" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  Enable Map
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.enableMap}
                      className="ml-3"
                      onChange={formik.handleChange("enableMap")}
                      value={formik?.values?.enableMap}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-3">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Allow Tip
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.allowTip}
                      className="ml-3"
                      onChange={formik.handleChange("allowTip")}
                      value={formik?.values?.allowTip}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  White Logo background
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.logoWhiteBackground}
                      className="ml-3"
                      onChange={formik.handleChange("logoWhiteBackground")}
                      value={formik?.values?.logoWhiteBackground}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Apply service tax
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.applyServiceTax}
                      className="ml-3"
                      onChange={formik.handleChange("applyServiceTax")}
                      value={formik?.values?.applyServiceTax}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="mt-3">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  Define Order For Later Days
                </Col>
                <Col md="7" className={isMobile && "my-2"}>
                  <Input
                    type="select"
                    value={formik.values.orderForLaterDays}
                    onChange={formik.handleChange("orderForLaterDays")}
                  >
                    <option value="">Select</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-3">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Order Future Time Same Day
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.orderFutureTimeSameDay}
                      className="ml-3"
                      onChange={formik.handleChange("orderFutureTimeSameDay")}
                      value={formik?.values?.orderFutureTimeSameDay}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-3">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Menu Layout
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="select"
                    value={formik.values.menuLayout}
                    onChange={formik.handleChange("menuLayout")}
                  >
                    {[
                      { label: "List View", value: "list" },
                      { label: "Grid View", value: "grid" },
                    ]?.map((item) => {
                      return (
                        <option key={item?.value} value={item?.value}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-3">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Categories Layout
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="select"
                    value={formik.values.categoryLayout}
                    onChange={formik.handleChange("categoryLayout")}
                  >
                    {[
                      { label: "Horizontal Scroll", value: "horizontal" },
                      { label: "Select", value: "select" },
                    ]?.map((item) => {
                      return (
                        <option key={item?.value} value={item?.value}>
                          {item?.label}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Enable SMS For External Order
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.enableSMSForExternalOrder}
                      className="ml-3"
                      onChange={formik.handleChange(
                        "enableSMSForExternalOrder"
                      )}
                      value={formik?.values?.enableSMSForExternalOrder}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  View Only
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.viewOnly}
                      className="ml-3"
                      onChange={formik.handleChange("viewOnly")}
                      value={formik?.values?.viewOnly}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            {/* <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // textAlign: "right",
                  }}
                >
                  Order Future Time Different Day
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      disabled
                      checked={formik.values.orderFutureTimeDifferentDay}
                      className="ml-3"
                      onChange={formik.handleChange(
                        "orderFutureTimeDifferentDay"
                      )}
                      value={formik?.values?.orderFutureTimeDifferentDay}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col> */}
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  Turn Off Same Day And Next Day Ordering
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.turnOffSameAndNextDayOrdering}
                      className="ml-3"
                      onChange={formik.handleChange(
                        "turnOffSameAndNextDayOrdering"
                      )}
                      value={formik?.values?.turnOffSameAndNextDayOrdering}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
              <small>
                <span className="fa fa-info-circle mr-3"></span>If you Turn this
                on `Order Future Time Same Day` configuration will be overrided.
              </small>
            </Col>
          </TabPane>
          <TabPane tabId="tabs3" style={{ textAlign: "left" }}>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Choose Pickup Title
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.pickupTitle}
                    onChange={formik.handleChange("pickupTitle")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Choose Delivery Title
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.deliveryTitle}
                    onChange={formik.handleChange("deliveryTitle")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Order for Later Button Text
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.orderForLaterBtnText}
                    onChange={formik.handleChange("orderForLaterBtnText")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Note Place Holder Text
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.notePlaceholderText}
                    onChange={formik.handleChange("notePlaceholderText")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Shop Close Title
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.shopCloseTitle}
                    onChange={formik.handleChange("shopCloseTitle")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Shop Close Text
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.shopCloseText}
                    onChange={formik.handleChange("shopCloseText")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  Age verification warning message
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.ageVerificationWarningMsg}
                    onChange={formik.handleChange("ageVerificationWarningMsg")}
                  />
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane tabId="tabs4" style={{ textAlign: "left" }}>
            <Col md="12" className="mt-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-start" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  Skip step if only 1 location
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.skipStep}
                      className="ml-3"
                      onChange={formik.handleChange("skipStep")}
                      value={formik?.values?.skipStep}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="mt-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-start" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  Skip step if only 1 Method
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.skipStepMethod}
                      className="ml-3"
                      onChange={formik.handleChange("skipStepMethod")}
                      value={formik?.values?.skipStepMethod}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
            <Col md="12" className="mt-2">
              <Row>
                <Col
                  md="5"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-start" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  Skip step if only 1 Time Method
                </Col>
                <Col
                  md="7"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <label className="custom-toggle">
                    <input
                      checked={formik.values.skipStepTimeM}
                      className="ml-3"
                      onChange={formik.handleChange("skipStepTimeM")}
                      value={formik?.values?.skipStepTimeM}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane tabId="tabs5" style={{ textAlign: "right" }}>
            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Dine in Title
                </Col>
                <Col
                  md="9"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.dineInTitle}
                    onChange={formik.handleChange("dineInTitle")}
                  />
                </Col>
              </Row>
            </Col>

            <Col md="12" className="my-2">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "right",
                  }}
                >
                  Dine in Text
                </Col>
                <Col
                  md="9"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Input
                    type="text"
                    value={formik.values.dineInText}
                    onChange={formik.handleChange("dineInText")}
                  />
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane tabId={"tabs6"}>
            <CustomHours />
          </TabPane>
          <TabPane tabId={"tabs7"}>
            <MenuImport />
          </TabPane>
        </TabContent>
      </Card>
    </div>
  );
}
