import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { RiVisaLine } from "react-icons/ri";
import Select from "react-select";

import { toast } from "react-toastify";
import { Button, Modal, Progress, Spinner } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useCart } from "../../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../../hooks/useEntity";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../../../store/theme";
import logger from "../../../../utils/logger";
import { validationSchemaCustomerUpdateProfile } from "../../../../utils/schemas/CustomerUpdateProfile";
import HistoryCard from "../../history/components/HistoryCard";
import { AccountTabs } from "./CustomerProfile";
import LoyaltyModalComponent from "./LoyaltyModalComponent";

export default function MyAccount({ open, toggle, history, activeTabs = 0 }) {
  const { theme } = useTheme();
  const authContext = useContext(AuthContext);
  const { restaurant } = useOrderingRestaurant();
  const [activeTab, setActiveTab] = useState();
  const { find: findCards, entities: cards } = useEntity("eat/card");
  const { findOne, entity } = useEntity("eat/auth");
  const { updateEntity } = useEntity("eat/auth/profile");
  const { gateway } = useOrdering();
  const { deleteEntity } = useEntity("eat/card");
  const { find, entities } = useEntity("eat/ordering/history");
  const { find: findVisits, entities: visits } = useEntity(
    "eat/ordering/visits"
  );

  const { addToCart } = useCart();
  const [page] = useState(1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    find({
      customerId: authContext?.user?.customer?._id,
      restaurant: restaurant?.userId?._id,
      limit: rowsPerPage,
      page: page,
    });
  }, [authContext, restaurant, rowsPerPage, page]);

  useEffect(() => {
    if (authContext?.user) {
      findOne(authContext?.user?._id);
      findVisits({
        id: authContext?.user?.customer?._id,
        restaurant: restaurant?.userId?._id,
      });
    }
    if (authContext?.user?._id && gateway) {
      findCards({
        provider: gateway?.gateway,
      });
    }
  }, [gateway, authContext, restaurant]);

  //Export to some file
  const months = [
    { month: "January", value: 1, label: "January" },
    { month: "February", value: 2, label: "February" },
    { month: "March", value: 3, label: "March" },
    { month: "April", value: 4, label: "April" },
    { month: "May", value: 5, label: "May" },
    { month: "June", value: 6, label: "June" },
    { month: "July", value: 7, label: "July" },
    { month: "August", value: 8, label: "August" },
    { month: "September", value: 9, label: "September" },
    { month: "October", value: 10, label: "October" },
    { month: "November", value: 11, label: "November" },
    { month: "December", value: 12, label: "December" },
  ];

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      birthday: "",
      birthMonth: "",
      password: "",
      smsForCouponAndInfo: false,
      emailForCouponAndInfo: false,
      anniversary: "",
      anniversaryMonth: "",
    },
    validationSchema: validationSchemaCustomerUpdateProfile,
    onSubmit: async (values) => {
      const data = {
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        emailForCouponAndInfo: values?.emailForCouponAndInfo,
        smsForCouponAndInfo: values?.smsForCouponAndInfo,
        birthday: `${
          values?.birthMonth.toString().length === 1
            ? `0${values?.birthMonth.toString()}`
            : values?.birthMonth.toString()
        }/${
          values?.birthday.toString().length === 1
            ? `0${values?.birthday.toString()}`
            : values?.birthday.toString()
        }`,
        anniversary: `${
          values?.anniversary.toString().length === 1
            ? `0${values?.anniversaryMonth.toString()}`
            : values?.anniversaryMonth.toString()
        }/${
          values?.anniversary.toString().length === 1
            ? `0${values?.anniversary.toString()}`
            : values?.anniversary.toString()
        }`,
      };

      try {
        const user = await updateEntity(authContext?.user?.customer?._id, {
          userId: authContext.user._id,
          ...data,
        });
        toast.success("Customer updated successfully");
      } catch (error) {
        logger.push({ error, info: "CustomerProfile" });
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (activeTabs > 0) {
      setActiveTab(activeTabs);
    }
  }, [open, activeTabs]);

  useEffect(() => {
    if (entity) {
      setValues({
        name: entity?.data?.customer?.name,
        phone: entity?.data?.customer?.phone,
        email: entity?.data?.customer?.email,
        emailForCouponAndInfo: entity?.data?.customer?.emailForCouponAndInfo,
        smsForCouponAndInfo: entity?.data?.customer?.smsForCouponAndInfo,
        birthMonth: Number(entity?.data?.customer?.birthday?.split("/")[0]),
        birthday: Number(entity?.data?.customer?.birthday?.split("/")[1]),
        anniversary: Number(entity?.data?.customer?.anniversary?.split("/")[1]),
        anniversaryMonth: Number(
          entity?.data?.customer?.anniversary?.split("/")[0]
        ),
      });
    }
  }, [entity]);

  const styles = {
    fontSize: 13,
    padding: 10,
    transform: "none",
    boxShadow: "none",
    border: `1px solid ${theme.primary}`,
    textTransform: "uppercase",
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={open}
      toggle={() => {
        toggle();
      }}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          My Account
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="mx-3">
        {" "}
        <AccountTabs
          theme={theme}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          styles={styles}
        />
      </div>
      {activeTab === 0 && (
        <div className="mx-3">
          <div className="my-3">
            <input
              className="px-3 py-3 rounded"
              style={{
                width: "100%",
                backgroundColor: "#ededed",
                border: "none",
                fontSize: "18px",
              }}
              type="text"
              placeholder="name"
              value={values?.name}
              onChange={handleChange("name")}
            />
          </div>

          <div className="my-3">
            <input
              className="px-3 py-3 rounded"
              style={{
                width: "100%",
                backgroundColor: "#ededed",
                border: "none",
                fontSize: "18px",
              }}
              type="text"
              placeholder="Email"
              value={values?.email}
              onChange={handleChange("email")}
            />
          </div>
          <div className="my-3">
            <input
              className="px-3 py-3 rounded"
              style={{
                width: "100%",
                backgroundColor: "#ededed",
                border: "none",
                fontSize: "18px",
              }}
              type="number"
              placeholder="Number"
              value={values?.phone}
              onChange={handleChange("phone")}
            />
          </div>
          <div className="my-3">
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <span className="mb-2 bold">Birthday </span>{" "}
              <div
                style={{ display: "flex", flexDirection: "row", zIndex: 99 }}
              >
                <div style={{ flex: 1 }}>
                  <Select
                    name="month"
                    placeholder="Month"
                    isSearchable={false}
                    options={months}
                    onChange={(val) => setFieldValue("birthMonth", val?.value)}
                    value={{
                      label: values?.birthMonth,
                      value: values?.birthMonth,
                    }}
                  />
                </div>
                <span className="mx-2"></span>
                <div style={{ flex: 1 }}>
                  <Select
                    isSearchable={false}
                    name="day"
                    placeholder="Day"
                    options={[...Array(32).keys()]?.slice(1, 32).map((it) => {
                      return {
                        label: it,
                        value: it,
                      };
                    })}
                    onChange={(val) => setFieldValue("birthday", val?.value)}
                    value={{
                      label: values?.birthday,
                      value: values?.birthday,
                    }}
                  />
                </div>
              </div>
            </div>{" "}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                zIndex: 9999,
              }}
            >
              {" "}
              <span className="mb-2 bold">Anniversary </span>{" "}
              <div style={{ display: "flex", flexDirection: "row", zIndex: 9 }}>
                {" "}
                <div style={{ flex: 1 }}>
                  <Select
                    name="month"
                    isSearchable={false}
                    placeholder="Month"
                    options={months}
                    onChange={(val) =>
                      setFieldValue("anniversaryMonth", val?.value)
                    }
                    value={{
                      label: values?.anniversaryMonth,
                      value: values?.anniversaryMonth,
                    }}
                  />
                </div>
                <span className="mx-2"></span>
                <div style={{ flex: 1 }}>
                  <Select
                    name="day"
                    placeholder="Day"
                    isSearchable={false}
                    options={[...Array(32).keys()]?.slice(1, 32).map((it) => {
                      return {
                        label: it,
                        value: it,
                      };
                    })}
                    onChange={(val) => setFieldValue("anniversary", val?.value)}
                    value={{
                      label: values?.anniversary,
                      value: values?.anniversary,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="custom-control custom-checkbox my-3">
            <input
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              onChange={handleChange("smsForCouponAndInfo")}
              value={values?.smsForCouponAndInfo}
              checked={values?.smsForCouponAndInfo}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Sign up for SMS coupons and info{" "}
            </label>
          </div>
          <div className="custom-control custom-checkbox my-3">
            <input
              className="custom-control-input"
              id="customCheck2"
              type="checkbox"
              onChange={handleChange("emailForCouponAndInfo")}
              value={values?.emailForCouponAndInfo}
              checked={values?.emailForCouponAndInfo}
            />
            <label className="custom-control-label" htmlFor="customCheck2">
              Sign up for Email coupons and info
            </label>
          </div>

          <div>
            <Button
              className="py-3 mb-3"
              style={{
                backgroundColor: theme?.primary,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.1em",
              }}
              block
              onClick={handleSubmit}
            >
              {isSubmitting ? <Spinner size="sm" /> : "Update"}
            </Button>
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="mx-3">
          <table style={{ width: "100%" }}>
            <tr
              style={{
                color: theme.primary,
                textTransform: "uppercase",
              }}
            >
              <th>Number</th>
              <th>Type</th>
              <th className="text-center">Action</th>
            </tr>
            {cards?.data?.map((card, i) => {
              return (
                <>
                  <tr key={i}>
                    <td>{card?.truncatedCardNumber.split("-")[3]}</td>
                    <td>
                      {card?.cardLogo === "Visa" ? (
                        <RiVisaLine size={40} />
                      ) : (
                        card?.cardLogo
                      )}
                    </td>
                    <td className="text-center">
                      <FaTrash
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          try {
                            await deleteEntity(card?._id);
                            toast.success("Card deleted succesfully");
                          } catch (error) {
                            toast.error(error.message);
                          }
                        }}
                        color={theme?.primary}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
            {(cards?.data?.length === 0 || !cards?.data) && (
              <tr>
                <td colSpan={4} className="text-center p-4">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <lottie-player
                      src="https://assets4.lottiefiles.com/packages/lf20_dmw3t0vg.json"
                      background="transparent"
                      speed="1"
                      style={{ maxWidth: 300, maxHeight: 200 }}
                      autoplay
                    ></lottie-player>
                    <div>
                      {" "}
                      <h1 className="mt-3" style={{ textAlign: "center" }}>
                        {"Currently no data here"}
                      </h1>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </table>
        </div>
      )}
      {activeTab === 2 && (
        <div className="px-3 py-4">
          <>
            {" "}
            {entities?.data?.data?.map((o, index) => {
              return (
                <HistoryCard
                  disable={entities?.data?.itemsAvailable[index]?.map((op) => {
                    return op?.isAvailable;
                  })}
                  history={history}
                  length={entities?.data?.length}
                  index={index}
                  data={o}
                  onClick={() => {
                    entities?.data?.itemsAvailable[index]?.map((op) => {
                      addToCart({ ...op });
                    });
                  }}
                />
              );
            })}
          </>
          {entities?.data?.data?.length <= 0 && activeTab === 2 && (
            <div>No orders placed, Place an order now</div>
          )}
        </div>
      )}
      {activeTab === 3 && (
        <div className="px-3 pb-3">
          <h5>Keep earning points toward FREE faves</h5>
          <LoyaltyModalComponent />
          <div
            className="p-3 mt-3"
            style={{
              boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div>
                <h5>Unlock boosted status</h5>
              </div>
              <div>
                <h5>
                  {restaurant?.loyalty?.visitThreshold} visits in a calendar
                  month earns {restaurant?.loyalty?.rewardPoints} points.
                </h5>
                <Progress
                  style={{ height: 30, marginTop: 10, width: "100%" }}
                  multi
                >
                  <Progress
                    style={{ backgroundColor: "#5F9B0A", padding: 5 }}
                    bar
                    value={visits.data}
                    max={restaurant?.loyalty?.visitThreshold}
                  >
                    {visits.data} Visits
                  </Progress>
                </Progress>
              </div>
            </div>
            <div className="text-uppercase d-flex flex-column align-items-center">
              <div>
                <h5 className="m-0">VISITS</h5>
              </div>
              <div>
                <h5 className="text-red m-0 text-xl">{visits.data}</h5>
              </div>
              <div>
                <h5 className="m-0">
                  of {restaurant?.loyalty?.visitThreshold}
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
