import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Button, Input, Modal, Spinner } from "reactstrap";
import { useEntity } from "../../../../hooks/useEntity";
import { ErrorLable } from "../../settings/AddLocation";
import { useAdminStore } from "../../../../store/admin";

const AddPointsModal = ({ toggle, open, data }) => {
  const { create: addPoints } = useEntity("loyalty");

  const { restaurant: restaurantId } = useAdminStore();

  console.log(data);

  const { handleSubmit, handleChange, values, isSubmitting, errors, touched } =
    useFormik({
      initialValues: {
        points: "",
      },
      validationSchema: Yup.object({
        points: Yup.number()
          .moreThan(-1, "Must be any positive value")
          .required("Required field"),
      }),

      onSubmit: async (values, { resetForm }) => {
        try {
          await addPoints({
            points: values?.points,
            customer: data?._id,
            restaurant: restaurantId,
          });
          toast.success("Points added successfully");
          resetForm();
          toggle();
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  return (
    <div>
      {" "}
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={() => {
          toggle();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Loyalty Points
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            onChange={handleChange("points")}
            placeholder="Add Loyalty points"
            type="number"
            value={values?.points}
            className={`form-control ${
              errors.points && touched.points ? "is-invalid" : ""
            }`}
          />
          {errors.points && touched.points && (
            <ErrorLable message={errors.points} />
          )}
        </div>

        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary" type="button">
            {isSubmitting ? <Spinner /> : "Save changes"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddPointsModal;
