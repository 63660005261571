import moment from "moment";
import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import LineChart from "../../../components/Charts/LineChart";
import { useEntity } from "../../../hooks/useEntity";
import usePartnerId from "../../../hooks/usePartnerId";
import { toFixed } from "../../../utils/toFixed";
import SimpleHeader from "../../../components/Header/SimpleHeader";

export default function DashboardPartner(props) {
  const { findOne: findStat, entity: stats } = useEntity("stats/partner");
  const partnerId = usePartnerId();
  const { find: findOrders, entities: orders } = useEntity(
    "stats/latest-orders"
  );
  React.useEffect(() => {
    findStat(partnerId);
    findOrders({
      partner: partnerId,
    });
  }, []);

  return (
    <>
      <SimpleHeader name="Home" parentName="Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="8" xl="4">
            <Card className="border-0 card-color">
              <CardBody>
                <div className="custom-card">
                  <div className="order-main">
                    <div className="order-val">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 "
                      >
                        Total Orders
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        {stats?.data?.totalOrders}
                      </span>
                    </div>
                    <div className="order-icon">
                      <i className="fa fa-telegram bg-orange"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" xl="4">
            <Card className="border-0 card-color">
              <CardBody>
                <div className="custom-card">
                  <div className="order-main">
                    <div className="order-val">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 "
                      >
                        Total Sales
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        ${toFixed(stats?.data?.totalSales || 0, false)}
                      </span>
                    </div>
                    <div className="order-icon">
                      <i className="fa fa-bar-chart bg-green"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8" xl="4">
            <Card className=" border-0 card-color">
              <CardBody>
                <div className="custom-card">
                  <div className="order-main">
                    <div className="order-val">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 "
                      >
                        Total Revenue
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 ">
                        ${toFixed(stats?.data?.totalRevenue || 0, false)}
                      </span>
                    </div>
                    <div className="order-icon">
                      <i className="fa fa-money bg-purple"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="h3 mb-0"> Analytics</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <LineChart
                    data={[
                      {
                        label: "Orders",
                        labels: stats?.data?.customerVsOrders?.labels,
                        data: stats?.data?.customerVsOrders?.ordersByMonth,
                        color: "#5e72e4",
                      },
                      {
                        label: "Customers",
                        labels: stats?.data?.customerVsOrders?.labels,
                        data: stats?.data?.customerVsOrders?.customersByMonth,
                        color: "#fb6340",
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 text-left">Latest Orders</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      OrderID
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Customer
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Status
                    </th>
                    <th className="sort" data-sort="completion" scope="col">
                      Order Date
                    </th>
                    <th scope="col">Total</th>
                    <th scope="col">Method</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {orders.data?.map?.((order) => {
                    return (
                      <tr>
                        <th scope="row">{order.orderNum}</th>
                        <td>{order.customer.name}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={
                                order.status === "pending"
                                  ? "bg-info"
                                  : "bg-success"
                              }
                            />
                            <span className="status">{order.status}</span>
                          </Badge>
                        </td>
                        <td>{moment(order.createdAt).format("MM/DD/yyyy")}</td>
                        <td>${toFixed(order.payment.total)}</td>
                        <td>{order.method}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
