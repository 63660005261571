import { useContext, useEffect, useState } from "react";
import { FaEnvelope, FaList, FaPhone, FaUser } from "react-icons/fa";
import { Card } from "reactstrap";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useTheme } from "../../../../store/theme";

const CustomerDetails = ({ getOrderNotes }) => {
  const authContext = useContext(AuthContext);
  const [orderNotes] = useState();
  const { findOne, entity } = useEntity("eat/auth");

  const { theme } = useTheme();
  useEffect(() => {
    if (authContext?.user) {
      findOne(authContext?.user?._id);
    }
  }, []);

  return (
    <div>
      {authContext?.user && (
        <div className="mt-4">
          <Card
            className="px-3 py-3"
            style={{ boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)" }}
          >
            <h1
              style={{
                fontWeight: "600",
                color: theme.primary,
              }}
            >
              Personal Details
            </h1>

            <div className="input_group_checkout">
              <div
                className="px-3 input_checkout"
                style={{
                  backgroundColor: "#ededed",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  className=" py-3"
                  style={{
                    flex: 1,
                    border: "none",
                    fontSize: "18px",
                    background: "transparent",
                  }}
                  type="email"
                  disabled
                  value={entity?.data?.customer?.email}
                />
                <FaEnvelope size={25} />
              </div>
              <span className="mx-3"></span>
              <div
                className="px-3"
                style={{
                  backgroundColor: "#ededed",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  value={entity?.data?.customer?.phone}
                  className=" py-3"
                  style={{
                    border: "none",
                    flex: 1,
                    fontSize: "18px",
                    background: "transparent",
                  }}
                  type="text"
                  disabled
                />
                <FaPhone size={25} />
              </div>
            </div>

            <div className="input_group_checkout mt-3">
              <div
                className="px-3 input_checkout"
                style={{
                  backgroundColor: "#ededed",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  className=" py-3"
                  style={{
                    flex: 1,
                    border: "none",
                    fontSize: "18px",
                    background: "transparent",
                  }}
                  type="text"
                  disabled
                  value={entity?.data?.customer?.name}
                />
                <FaUser size={25} />
              </div>
              <span className="mx-3"></span>
              <div
                className="px-3 input_checkout"
                style={{
                  backgroundColor: "#ededed",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  className=" py-3"
                  style={{
                    flex: 1,
                    border: "none",
                    fontSize: "18px",
                    background: "transparent",
                  }}
                  type="text"
                  placeholder="Order Notes"
                  value={orderNotes}
                  onChange={(e) => getOrderNotes(e.target.value)}
                />
                <FaList size={25} />
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
