import React from "react";
import ContentLoader from "react-content-loader";

const Loader = (props) => (
  <ContentLoader
    viewBox="0 0 400 160"
    height={160}
    width={400}
    backgroundColor="transparent"
    {...props}
  >
    <circle cx="150" cy="86" r="8" />
    <circle cx="194" cy="86" r="8" />
    <circle cx="238" cy="86" r="8" />
  </ContentLoader>
);
Loader.metadata = {
  name: "Nic Bovee", // My name
  github: "ghettifish", // Github username
  description: "A simple favorite from the DoorDash local favorites.", // Little tagline
  filename: "DoorDashFavorite", // filename of your loader
};

export default Loader;
