import { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import { useEntity } from "../../../hooks/useEntity";
import SuperTable from "../../../widgets/SuperTable";
import TableWrapper from "../../../widgets/TableWrapper";
import TableRowMobileAppRestaurant from "./TableRowMobileAppRestaurant";
import { useAdminStore } from "../../../store/admin";
import { AuthContext } from "../../../context";

const Restaurants = (props) => {
  const { entities, loading: isLoadingRest, find } = useEntity("restaurant");
  const [page, setPage] = useState(1);
  const [sorting, setSorting] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { partner } = useAdminStore();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    let filter = {
      partner: partner || authContext?.user?._id,
      status: "active",
    };

    find({ ...filter });
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Restaurant", "Email", "Show in app"];

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  return (
    <div>
      <SimpleHeader
        path="/admin/partners"
        name="Mobile App Restaurants"
        parentName="Partners"
        showBtn={true}
      />
      <TableWrapper>
        {!isLoadingRest ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoadingRest}
            history={props.history}
            row={TableRowMobileAppRestaurant}
            columns={Headers}
            isLoading={isLoadingRest}
            emptyMessage="Currently no restaurants"
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </div>
  );
};

export default Restaurants;
