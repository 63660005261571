import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabPane,
} from "reactstrap";

import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { ErrorLable } from "../../AddLocation";
import CustomPrepTimes from "../../components/CustomPrepTimes";
import DeliveryZone from "./DeliveryZone";

const deliveryZoneKey = () => (Math.random() + 1).toString(36).substring(7);

export function PickupDelivery({ formik, location, restaurantDoc }) {
  const [activeDeliveryZone, setActiveDeliveryZone] = useState("");

  const handleCopy = async () => {
    const splits = restaurantDoc?.restaurant?.url?.split("/");
    const urlString = new URL(restaurantDoc.restaurant.url);
    const host = urlString.host;
    const restaurantEndpoint = splits?.[splits?.length - 1];
    const url = `${
      host.startsWith("http") ? host : `http://${host}`
    }/ordering/${restaurantEndpoint}/delivery?id=${location?.data?._id}&url=${
      restaurantDoc?.restaurant?.url
    }`;
    await navigator.clipboard.writeText(url);
    toast.success("Copied");
  };

  const onDeliveryZoneTabChange = (k) => {
    const zones = formik.values.deliveryZones || [];

    // check if custom prep times are enabled
    if (formik.values?.customPrepTimesEnabled && zones.length === 1) {
      return toast.error("Please disable custom prep times to add more zones");
    }

    if (k === "add") {
      if (zones[zones.length - 1]?.extendWithIHD) {
        return toast.error("Please disable extend with IHD to add more zones");
      }

      if (zones.some((zone) => zone.deliveryOption === "listing")) {
        return toast.error("Delivery listing only works with single zone.");
      }

      const newZone = {
        key: deliveryZoneKey(),
        deliveryFeeSetting: "always",
        deliveryRadius: 10,
        deliveryOption: "geofence",
      };
      zones.push(newZone);
      formik.setFieldValue("deliveryZones", [...zones]);
      setActiveDeliveryZone(newZone.key);
    } else {
      setActiveDeliveryZone(k);
    }
  };

  const handleZoneChange = (index, zone) => {
    const newZones = [...formik.values.deliveryZones];
    newZones[index] = zone;
    formik.setFieldValue("deliveryZones", [...newZones]);
  };

  const handleZoneDelete = (index) => {
    const newZones = [...formik.values.deliveryZones];
    newZones.splice(index, 1);
    const activeKey = newZones[Math.max(index - 1, 0)]?.key;
    formik.setFieldValue("deliveryZones", [...newZones]);
    setTimeout(() => setActiveDeliveryZone(activeKey), 0);
  };

  useEffect(() => {
    if (formik.values.deliveryZones?.length !== 0 && !activeDeliveryZone) {
      setActiveDeliveryZone(formik.values.deliveryZones?.[0]?.key);
    }
  }, [formik.values.deliveryZones]);

  return (
    <TabPane tabId="tabs5" style={{ textAlign: "left" }}>
      <Row>
        <Col md="12">
          <h2 style={{ fontWeight: "bolder" }}>Pickup</h2>
        </Col>
        <Col md="12">
          <FormGroup className="row" style={{ alignItems: "center" }}>
            <Label
              className="form-control-label text-left"
              htmlFor="example-text-input"
              md="4"
            >
              Enable Pickup
            </Label>
            <Col md="1">
              <label className="custom-toggle">
                <input
                  checked={formik.values?.pickup}
                  value={formik.values?.pickup}
                  onChange={formik.handleChange("pickup")}
                  type="checkbox"
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </FormGroup>
        </Col>

        <Col md="12">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                Pickup Prep Time <small>(in minutes)</small>
              </Label>
              <Col md="8">
                <Input
                  type="number"
                  value={formik.values.pickupPrepTime}
                  onChange={formik.handleChange("pickupPrepTime")}
                  disabled={formik.values?.pickup === false}
                  className={`form-control ${
                    formik.errors.pickupPrepTime &&
                    formik.touched.pickupPrepTime
                      ? "is-invalid"
                      : ""
                  }`}
                ></Input>
                {formik.errors.pickupPrepTime &&
                  formik.touched.pickupPrepTime && (
                    <ErrorLable message={formik.errors.pickupPrepTime} />
                  )}
                <small>Time based prep will take precedence if enabled</small>
              </Col>
            </FormGroup>
          </Form>
        </Col>

        <Col className="mt-5" md="12">
          <h2 style={{ fontWeight: "bolder" }}>IHD Configuration</h2>
        </Col>
        <Col md="12" className="mt-3">
          <Form>
            <FormGroup className="row">
              <Label
                className="form-control-label text-left"
                htmlFor="example-text-input"
                md="4"
              >
                Enable IHD
              </Label>
              <Col md="1">
                <label className="custom-toggle">
                  <input
                    checked={formik.values?.enableIhd}
                    value={formik.values?.enableIhd}
                    onChange={(e) => {
                      console.log("enableihd form", e.target.checked);
                      formik.setFieldValue("enableIhd", e.target.checked);
                      if (e.target.checked) {
                        formik.setFieldValue("enableDelivery", false);
                      }
                    }}
                    type="checkbox"
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </FormGroup>
          </Form>
        </Col>

        {formik.values.enableIhd && (
          <>
            {location?.data?.enableIhd && (
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Delivery URL
                    </Label>
                    <Col md="8">
                      <Button size="sm" onClick={handleCopy}>
                        Click to copy
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            )}
            <Col md="12">
              <FormGroup className="row" style={{ alignItems: "center" }}>
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  IHD Client ID
                </Label>
                <Col md="8">
                  <Input
                    onChange={formik.handleChange("ihdClientId")}
                    type="text"
                    value={formik.values?.ihdClientId}
                    placeholder="Enter Client ID received from IHD"
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row" style={{ alignItems: "center" }}>
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  IHD Client Secret
                </Label>
                <Col md="8">
                  <Input
                    onChange={formik.handleChange("ihdClientSecret")}
                    type="text"
                    value={formik.values?.ihdClientSecret}
                    placeholder="Enter Client Secret received from IHD"
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row" style={{ alignItems: "center" }}>
                <Label
                  className="form-control-label text-left"
                  htmlFor="example-text-input"
                  md="4"
                >
                  IHD Location ID
                </Label>
                <Col md="8">
                  <Input
                    onChange={formik.handleChange("ihdLocationId")}
                    type="text"
                    value={formik.values?.ihdLocationId}
                    placeholder="Enter Location ID received from IHD"
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col md="12">
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Delivery ready Time
                  </Label>
                  <Col md="8">
                    <Input
                      type="number"
                      value={formik.values.ihdReadyTime}
                      onChange={formik.handleChange("ihdReadyTime")}
                    ></Input>
                    {formik.errors.ihdReadyTime &&
                      formik.touched.ihdReadyTime && (
                        <ErrorLable message={formik.errors.ihdReadyTime} />
                      )}
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </>
        )}

        {
          <>
            <Col className="mt-5" md="12">
              <h2 style={{ fontWeight: "bolder" }}>Delivery</h2>
            </Col>
            <Col md="12" className="mt-3">
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Enable Delivery
                  </Label>
                  <Col md="1">
                    <label className="custom-toggle">
                      <input
                        checked={formik.values?.enableDelivery}
                        value={formik.values?.enableDelivery}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "enableDelivery",
                            e.target.checked
                          );
                          if (e.target.checked) {
                            formik.setFieldValue("enableIhd", false);
                          }
                        }}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
              </Form>
            </Col>

            {!formik.values.enableIhd && formik.values.enableDelivery && (
              <Col md="12">
                <Form>
                  <FormGroup className="row">
                    <Col md="12">
                      <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={activeDeliveryZone}
                        onSelect={(k) => onDeliveryZoneTabChange(k)}
                      >
                        {formik.values?.deliveryZones?.map?.((zone, index) => (
                          <Tab
                            key={zone.key}
                            eventKey={zone.key}
                            title={
                              <>
                                Zone {index + 1}
                                <button
                                  className="btn btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleZoneDelete(index);
                                  }}
                                >
                                  ✕
                                </button>
                              </>
                            }
                          >
                            <DeliveryZone
                              latLng={formik?.values?.latLng}
                              zone={zone}
                              onChange={(d) => handleZoneChange(index, d)}
                              handleCopy={handleCopy}
                              location={location?.data}
                              showExtendWithIHD={
                                formik.values?.deliveryZones?.length ===
                                index + 1
                              }
                              showDeliveryListing={
                                formik.values?.deliveryZones?.length <= 1
                              }
                            />
                          </Tab>
                        ))}
                        <Tab
                          eventKey="add"
                          title={
                            <button
                              className="btn btn-sm"
                              onClick={(e) => e.preventDefault()}
                            >
                              +
                            </button>
                          }
                        >
                          add ui
                        </Tab>
                      </Tabs>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            )}
          </>
        }
        <CustomPrepTimes formik={formik} />
      </Row>
    </TabPane>
  );
}
