import { useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "reactstrap";
import SimpleHeader from "../../components/Header/SimpleHeader";
import { useEntity } from "../../hooks/useEntity";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import { TableRow } from "./components/TableRow";
import EditMacModal from "./components/EditMacModal";
import { useAdminStore } from "../../store/admin";

function MicrosaleConfig(props) {
  const { type = "all" } = props;
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setshowModal] = useState(false);
  const [data, setData] = useState(null);
  const { partner } = useAdminStore();

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const {
    find: getMacs,
    entities: microsaleMacs,
    loading: isLoading,
  } = useEntity("microsale");

  let macs = microsaleMacs?.data;

  useEffect(() => {
    const query = {};
    if (type === "foodtronix") {
      query.provider = "foodtronix";
    }
    getMacs({ partner, ...query });
  }, [sorting, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = useMemo(() => {
    const h = [
      "MAC",
      "Partner",
      "Restaurant",
      "Location",
      "Provider",
      "Contact Info",
      "Action",
    ];

    if (props.view === "partner") {
      delete h[1];
    }

    return h;
  }, [props.view]);

  return (
    <>
      <SimpleHeader
        name={props.title || "POS Config"}
        parentName="Dashboard"
        showBtn={true}
      >
        <Button
          onClick={() => {
            setshowModal(true);
          }}
          className="btn-primary"
          color="primary"
          size="md"
        >
          Add new
        </Button>
      </SimpleHeader>
      <TableWrapper>
        {!isLoading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={macs}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={microsaleMacs?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoading}
            history={props.history}
            row={TableRow}
            columns={Headers}
            isLoading={isLoading}
            emptyMessage="Currently no macs"
            rowProps={{
              handleOpen(data) {
                setData(data);
                setshowModal(true);
              },
              view: props.view,
            }}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
      <EditMacModal
        toggle={() => setshowModal(!showModal)}
        open={showModal}
        provider={type === "foodtronix" ? "foodtronix" : null}
        partner={partner}
        data={data}
      />
    </>
  );
}

export default MicrosaleConfig;
