import classNames from "classnames";
import { useState } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import SimpleHeader from "../../../components/Header/SimpleHeader";
import TableWrapper from "../../../widgets/TableWrapper";
import CampaignsTab from "./components/reengage-tabs/Campaigns";
import ReEngageCoupons from "./components/reengage-tabs/Coupons";

export default function ReEngage(props) {
  const [currentTab, setCurrentTab] = useState("campaign");

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <SimpleHeader
        path="/resturants"
        name="Re-Engage"
        parentName="Dashboard"
        showBtn={true}
      >
        <Button
          onClick={() => {
            props.history.push({
              pathname: "/resturants/marketing/add-re-engage",
              search: props.location.search,
            });
          }}
          color="primary"
          size="md"
        >
          Add new
        </Button>
      </SimpleHeader>

      <TableWrapper>
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={currentTab === "campaign"}
              className={classNames("mb-sm-3 mb-md-0 mt-2", {
                active: currentTab === "campaign",
              })}
              onClick={(e) => handleTabChange(e, "campaign")}
              href="#"
              role="tab"
            >
              <i className="fa fa-book mr-2" />
              Campaigns
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={currentTab === "coupon"}
              className={classNames("mb-sm-3 mb-md-0 mt-2", {
                active: currentTab === "coupon",
              })}
              onClick={(e) => handleTabChange(e, "coupon")}
              href="#"
              role="tab"
            >
              <i className="fa fa-print mr-2" />
              Coupons
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          style={{
            marginTop: 20,
          }}
          activeTab={currentTab}
        >
          <TabPane tabId="campaign" role="tabpanel">
            <CampaignsTab history={props.history} />
          </TabPane>
          <TabPane tabId="coupon" role="tabpanel">
            <ReEngageCoupons history={props.history} />
          </TabPane>
        </TabContent>
      </TableWrapper>
    </>
  );
}
