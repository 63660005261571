import React from "react";
import { Modal } from "reactstrap";
import ModalHeader from "../../screens/ordering/components/modals/ModalHeader";

export default function DeliverectModal() {
  return (
    <Modal isOpen={false}>
      <ModalHeader title={"Deliverect Configuration"} />
    </Modal>
  );
}
