import ReactDOM from "react-dom";
import "react-notification-alert/dist/animate.css";

import App from "./App";
// core styles
import "./assets/css/argon-dashboard-pro-react.css";
import "./assets/vendor/nucleo/css/nucleo.css";
// plugins styles downloaded
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";

const queryClient = new QueryClient();

if (process.env.REACT_APP_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
