import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { isMobile } from "react-device-detect";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  PopoverBody,
  Row,
  Table,
  UncontrolledPopover,
} from "reactstrap";
import SimpleHeader from "../../components/Header/SimpleHeader";

export default function Transactions(props) {
  const [startDate] = React.useState(null);
  const [endDate] = React.useState(null);
  const [openDate, setOpenDate] = useState(false);

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  return (
    <>
      <SimpleHeader name="Transactions" parentName="Dashboard" showBtn={true}>
        <Button
          color="default"
          id="tooltip765206973"
          type="button"
          onClick={() => {
            setOpenDate(!openDate);
          }}
        >
          {!openDate ? (
            <i className="fa fa-calendar" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-close" aria-hidden="true"></i>
          )}
        </Button>
        <UncontrolledPopover placement="left" target="tooltip765206973">
          <PopoverBody>
            <Row className="input-daterange datepicker align-items-center">
              <Col xs={12} sm={6}>
                <label className=" form-control-label">Start date</label>
                <FormGroup>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "",
                    }}
                    value={startDate}
                    timeFormat={false}
                    // onChange={(e) => handleReactDatetimeChange("startDate", e)}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      classes += getClassNameReactDatetimeDays(currentDate);
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <label className=" form-control-label">End date</label>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "",
                    }}
                    value={endDate}
                    timeFormat={false}
                    // onChange={(e) => handleReactDatetimeChange("endDate", e)}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      classes += getClassNameReactDatetimeDays(currentDate);
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </PopoverBody>
        </UncontrolledPopover>

        <Button
          onClick={() => {}}
          className="btn-neutral"
          color="default"
          size="sm"
        >
          Run Report
        </Button>
        <Button
          onClick={() => {}}
          className="btn-neutral"
          color="default"
          size="sm"
        >
          CSV
        </Button>
      </SimpleHeader>
      <Container fluid>
        <Card
          className="py-4 px-3 mt--6"
          style={{ zIndex: 999, position: "relative" }}
        >
          <Row className="mt-2">
            <Col md="6" xl="3">
              <Card className="bg-gradient-primary border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Clients
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        83456
                      </span>
                      {/* <Progress
                    className="progress-xs mt-3 mb-0"
                    max="100"
                    value="30"
                    color="success"
                  /> */}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="bg-gradient-info border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Locations
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        4
                      </span>
                      {/* <Progress
                    className="progress-xs mt-3 mb-0"
                    max="100"
                    value="50"
                    color="success"
                  /> */}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="bg-gradient-danger border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Orders
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        100
                      </span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="3">
              <Card className="bg-gradient-default border-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0 text-white"
                      >
                        Users
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        5062
                      </span>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mx-4">
            <Col md="12">
              <Row>
                <Col
                  md="2"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  Sales
                </Col>
                <Col
                  md="10"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Table bordered className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Gross Qty</th>
                        <th scope="col">Gross Amt</th>
                        <th scope="col">Void Qty</th>
                        <th scope="col">Void Amt</th>
                        <th scope="col">Refund Qty</th>
                        <th scope="col">Refund Amt</th>
                        <th scope="col">New Qty</th>
                        <th scope="col">Net Amt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12</td>
                        <td>12</td>
                        <td>12</td>
                        <td>12</td>
                        <td>12</td>
                        <td>12</td>
                        <td>12</td>
                        <td>12</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mx-4 mt-4 mb-2">
            <Col md="12">
              <Row>
                <Col
                  md="2"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  Tax
                </Col>
                <Col
                  md="5"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Table bordered className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>$12766</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mx-4 my-2">
            <Col md="12">
              <Row>
                <Col
                  md="2"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  Tips
                </Col>
                <Col
                  md="5"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Table bordered className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>$12766</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mx-4 my-2">
            <Col md="12">
              <Row>
                <Col
                  md="2"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  Payment
                </Col>
                <Col
                  md="5"
                  className={isMobile && "my-2"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Table bordered className="align-items-center" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Card</th>
                        <th scope="col">Other</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>123</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}
