import { useContext, useState } from "react";
import Select from "react-select";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { RowContext } from "./categoryBulkRowContext";

export function CategoryBulkRow({ history, data, onChange, categories }) {
  const [selectedOpt, setSelectedOpt] = useState();
  const rowContext = useContext(RowContext);
  const itemCat = rowContext?.itemCat?.[data._id] || [];
  return (
    <tr>
      <td
        className="text-left"
        style={{
          width: "40%",
        }}
      >
        {data?.name}
      </td>
      <td
        style={{
          width: "60%",
        }}
        className="text-left"
      >
        <Row>
          <Col md={11}>
            <Select
              options={rowContext.categories}
              onChange={(opt) => setSelectedOpt(opt)}
              isSearchable
              isClearable
              value={selectedOpt}
              menuPortalTarget={document.body}
            />
          </Col>
          <Col md={1}>
            <Button
              onClick={() => {
                if (selectedOpt?.value) {
                  rowContext.onChange(data, selectedOpt);
                  setSelectedOpt({});
                }
              }}
            >
              +
            </Button>
          </Col>
        </Row>
        {itemCat.map((it) => {
          return (
            <ListGroup className="mt-2">
              <ListGroupItem>
                <Row>
                  <Col md={11}>{it.label}</Col>
                  <Col md={1}>
                    <Button
                      onClick={() => {
                        rowContext.onChange(data, it, true);
                      }}
                      color="warning"
                      outline
                      size="sm"
                    >
                      ✕
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          );
        })}
      </td>
    </tr>
  );
}
