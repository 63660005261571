import { Row } from "reactstrap";
import { useTheme } from "../../../../store/theme";

export default function ModalHeader({ toggle, title }) {
  const { theme } = useTheme();

  return (
    <div
      className="modal-header"
      style={{ borderBottom: "1px solid #ededed", alignItems: "center" }}
    >
      <h5 style={{ color: "#000", fontWeight: "bold" }} className="modal-title">
        {title}
      </h5>
      <button
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={toggle}
      >
        <span style={{ color: theme.primary }} aria-hidden={true}>
          ×
        </span>
      </button>
    </div>
  );
}
