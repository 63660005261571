import { FaArrowLeft } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { Card, Container } from "reactstrap";
import Header from "../../screens/ordering/auth/components/Header";
import Footer from "../../screens/ordering/components/Footer";
import HomeOrderingSVG from "../../screens/ordering/menu/components/HomeOrderingSVG";
import "../../screens/ordering/styles/Home/home.css";
import useOrdering from "../../store/ordering";
import { useOrderingRestaurant } from "../../store/ordering/useOrderingRestaurant";
import { useTheme } from "../../store/theme";
import RestaurantWrapper from "./RestaurantWrapper";
import { useEffect } from "react";

const OrderingLayout = (props) => {
  let {
    history,
    children,
    title,
    isCenter,
    renderRight,
    classname,
    showImage = true,
    layout,
    loading = false,
    noBoxShadow = false,
    hideHeader = false,
    showStartOrder,
    skipped = false,
  } = props;
  const { totalLocation } = useOrdering();
  const { restaurant } = useOrderingRestaurant();
  const { theme } = useTheme();

  return (
    <RestaurantWrapper loading={loading}>
      <div
        className="main_ordering_layout_wrapper"
        style={{ maxWidth: "1440px", margin: "auto" }}
      >
        <Header
          className={`${hideHeader && "hide_post_header"}`}
          account={true}
          history={props.history}
        />
        <>
          {layout ? (
            <Container
              style={{
                backgroundColor: "#fff",
              }}
              fluid
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card
                  style={{
                    boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.25)",
                  }}
                  className={classname ? classname : "main_ordering_card"}
                >
                  <div>
                    {isCenter ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          onClick={() => {
                            if (
                              totalLocation === 1 &&
                              restaurant?.ordering?.skipStep
                            ) {
                              let url = new URL(restaurant?.url);

                              props.history.push(url.pathname);
                            } else {
                              history.go(-1);
                            }
                          }}
                          style={{
                            display: "flex",
                            flex: 1,
                            cursor: "pointer",
                          }}
                        >
                          <IoArrowBack
                            size={24}
                            color={theme.primary}
                            style={{ fontWeight: "600" }}
                          />
                        </div>

                        <div>
                          <span
                            className="text-left "
                            style={{
                              fontWeight: "600",
                              color: theme.primary,
                              fontSize: 20,
                            }}
                          >
                            {title}
                          </span>
                        </div>

                        {renderRight ? (
                          renderRight
                        ) : (
                          <div style={{ flex: 1 }} />
                        )}
                      </div>
                    ) : showImage && !showStartOrder ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <HomeOrderingSVG fill={theme.primary} />
                        <h1
                          style={{ color: theme.primary }}
                          className="mt-2 mx-3"
                        >
                          {title}
                        </h1>
                      </div>
                    ) : (
                      <></>
                    )}
                    {children}
                  </div>
                </Card>
              </div>
            </Container>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Card
                style={{
                  borderRadius: "none",
                  boxShadow: !noBoxShadow
                    ? "0px 0px 6px 2px rgba(0, 0, 0, 0.25)"
                    : "0px",
                }}
                className={
                  classname
                    ? `${classname} ${noBoxShadow ? "padding_zero" : ""} `
                    : "main_ordering_card"
                }
              >
                {isCenter ? (
                  <div
                    className="pt-2 pb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      onClick={() => {
                        if (
                          totalLocation === 1 &&
                          restaurant?.ordering?.skipStep
                        ) {
                          let url = new URL(restaurant?.url);

                          props.history.push(url.pathname);
                        } else {
                          history.go(-1);
                        }
                      }}
                      style={{ flex: 1, cursor: "pointer" }}
                    >
                      <FaArrowLeft
                        size={24}
                        color="#212121"
                        style={{ fontWeight: "600" }}
                      />
                    </div>
                    <div>
                      <h1
                        className="text-left "
                        style={{ fontWeight: "600", color: "#212121" }}
                      >
                        {title}
                      </h1>
                    </div>
                    {renderRight ? renderRight : <div style={{ flex: 1 }} />}
                  </div>
                ) : showImage ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <HomeOrderingSVG fill={theme?.primary} />
                    <h1 style={{ color: "#000" }} className="mt-2 mx-3">
                      {title}
                    </h1>
                  </div>
                ) : (
                  <></>
                )}
                {children}
              </Card>
            </div>
          )}
        </>
      </div>
      <div className="mb-8">
        {" "}
        <Footer />
      </div>
    </RestaurantWrapper>
  );
};

export default OrderingLayout;
