import { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";

const FilterModal = ({ onChangeData, showMethod = true }) => {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [id, setId] = useState("");
  const [method, setMethod] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [orderType, setOrderType] = useState("BiteOrder");

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach(
      (k) =>
        (obj[k] && typeof obj[k] === "object" && removeEmpty(obj[k])) ||
        (!obj[k] && obj[k] !== undefined && delete obj[k])
    );
    toggle();
    onChangeData(obj);
    return obj;
  };

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <Dropdown toggle={toggle} isOpen={popoverOpen} direction="left">
      <DropdownToggle className="m-0" color="secondary">
        Filter
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem toggle={false}>
          <InputGroup style={{ marginBottom: 5 }} size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-archive" style={{ fontSize: 10 }}></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => setId(e.target.value)}
              value={id}
              placeholder="Order ID"
              type="text"
            />
          </InputGroup>
          <InputGroup style={{ marginBottom: 5 }} size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-user" style={{ fontSize: 10 }}></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Customer name"
              type="text"
            />
          </InputGroup>
          <InputGroup style={{ marginBottom: 5 }} size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-clock-o" style={{ fontSize: 10 }}></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => setCreatedAt(e.target.value)}
              value={createdAt}
              placeholder="Date Placed"
              type="date"
            />
          </InputGroup>
          {showMethod && (
            <InputGroup style={{ marginBottom: 5 }} size="sm">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-globe" style={{ fontSize: 10 }}></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                onChange={(e) => setMethod(e.target.value)}
                value={type}
                placeholder="Type of Order"
                type="select"
              >
                <option value={null}>Select</option>
                <option value="pickup">Pickup</option>
                <option value="delivery">Delivery</option>
                <option value="catering">Catering</option>
                <option value="dine-in">Dine in</option>
              </Input>
            </InputGroup>
          )}
          {showMethod && (
            <InputGroup style={{ marginBottom: 5 }} size="sm">
              <FormGroup className="form-control" check inline>
                <Input
                  id="check123"
                  type="checkbox"
                  checked={orderType === "ExternalOrder"}
                  onChange={(e) => {
                    setOrderType(
                      e.target.checked ? "ExternalOrder" : "BiteOrder"
                    );
                  }}
                />
                <Label for="check123" check>
                  Show External Orders
                </Label>
              </FormGroup>
            </InputGroup>
          )}
          <Button
            onClick={() => {
              let obj = {
                orderNum: id,
                createdAt,
                customerName: name,
                method,
                orderType,
              };
              removeEmpty(obj);
            }}
            block
            color="primary"
            size="sm"
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              onChangeData();
              setName("");
              setId("");
              setType("");
              setCreatedAt("");
              setOrderType("");
            }}
            block
            type="reset"
            size="sm"
          >
            Clear Filters
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterModal;
