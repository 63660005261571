import { Button, Modal } from "reactstrap";
import ModalHeader from "../../screens/ordering/components/modals/ModalHeader";
import { useTheme } from "../../store/theme";

export default function Notification({
  popupTitle = "Your attention is required",
  popupHeading = "A header",
  popupDescription = "Some description",
  open,
  toggle,
  origin,
  isVerified,
}) {
  const { theme } = useTheme();
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-white text-dark"
        isOpen={open}
        toggle={() => toggle()}
      >
        <>
          <ModalHeader toggle={toggle} title={popupTitle} />
          <div style={{ color: theme?.primary }} className="modal-body">
            {/* <i className="ni ni-bell-55 ni-3x" /> */}
            <p>{popupDescription}</p>
          </div>
          <div style={{ color: theme?.primary }} className="modal-footer">
            {origin === "dish" ? (
              <div>
                <Button
                  style={{ backgroundColor: "#eee", border: "none" }}
                  type="button"
                  onClick={() => {
                    isVerified(true);
                    toggle();
                  }}
                >
                  Yes
                </Button>
                <Button
                  style={{ backgroundColor: theme?.primary, border: "none" }}
                  color="default"
                  type="button"
                  onClick={() => {
                    isVerified(false);
                    toggle();
                  }}
                >
                  No
                </Button>
              </div>
            ) : (
              <Button
                style={{ backgroundColor: theme?.primary, border: "none" }}
                color="default"
                type="button"
                onClick={() => toggle()}
              >
                Ok, Got it
              </Button>
            )}
          </div>
        </>
      </Modal>
    </>
  );
}
