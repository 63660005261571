export function extractAddress(addr) {
  const obj = addr;
  // If the object is missing required fields, return null
  if (
    !obj.hasOwnProperty("formatted_address") ||
    !obj.hasOwnProperty("address1")
  ) {
    return null;
  }

  // If address1 is already populated, return the object as-is
  if (obj.address1.trim() !== "") {
    return obj;
  }

  // Split the formatted_address string using the comma delimiter
  let parts = obj.formatted_address.split(",");

  // If there are no parts, return the object as-is
  if (parts.length === 0) {
    return obj;
  }

  // Otherwise, assign the first part to address1
  obj.address1 = parts[0].trim();

  return obj;
}
