import { useEffect } from "react";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";

function RedirectComponent({ shouldRedirect, to }) {
  const { clearCart } = useCart();
  useEffect(() => {
    if (shouldRedirect) {
      clearCart();
      window.location.href = to;
    }
  }, [shouldRedirect]);

  return <></>;
}

export default function WalkupOrdering(props) {
  const orderingStore = useOrdering();
  const urlParams = new URLSearchParams(window.location.search);

  const {
    findOne,
    entity: location,
    loading,
  } = useEntity("eat/ordering/walkup/location");
  useEffect(() => {
    if (location) {
      orderingStore.setLocation(location?.data);
      orderingStore.setMethod("walkup");
      orderingStore.setTiming("now");
      orderingStore.setWalkupURL(window.location.href);
    } else {
      findOne(urlParams.get("id"));
    }
  }, [location]);

  if (!location?.data && !loading) {
    return (
      <div
        style={{
          marginTop: 50,
        }}
      >
        <h1>We're not accepting the order</h1>
        <h4>
          We're not accepting the orders at the moment. Please check back later.
        </h4>
      </div>
    );
  }

  return (
    <RestaurantWrapper>
      <RedirectComponent
        shouldRedirect={
          orderingStore.location && orderingStore.method && orderingStore.timing
        }
        to={`${urlParams.get("url")}/menu`}
      />
    </RestaurantWrapper>
  );
}
