import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { FaTimes } from "react-icons/fa";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import apiCaller from "../../api/apiCaller";
import ConfirmModal from "../../components/ConfirmModal";
import SimpleHeader from "../../components/Header/SimpleHeader";
import { useEntity } from "../../hooks/useEntity";
import usePartnerId from "../../hooks/usePartnerId";
import { exportAsCSV } from "../../utils/expoAsCSV";
import { toFixed } from "../../utils/toFixed";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import { TableRowOrderFeeReports } from "./components/TableRowOrderFeeReports";

function OrderFeeReports(props) {
  const [open, setOpen] = useState(false);
  const partnerId = usePartnerId();
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [restaurants, setRestaurants] = useState([]);
  const { entities, loading, find } = useEntity("reports/partner/order-fee");
  const { create } = useEntity("reports/partner/order-fee/export");
  const [isLoading, setIsLoading] = useState(false);

  const [dateRange, setDateRange] = useState({
    from: moment().startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
  });
  const [showDate, setShowDate] = useState(false);

  const handleSelectDates = (ranges) => {
    const range = {
      from: ranges.selection.startDate,
      to: ranges.selection.endDate,
    };

    range.to = moment(ranges.selection.endDate).endOf("day").toDate();
    setDateRange(range);
  };

  const selectionRange = {
    startDate: dateRange.from,
    endDate: dateRange.to,
    key: "selection",
  };

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = [
    "Restaurant",
    "Order Num",
    "Transaction ID",
    "Subtotal",
    "Total",
    "Order Fee",
    "Created At",
  ];

  const handleExport = async () => {
    const exportData = await create({
      partner: partnerId,
      restaurants,
      date: {
        ...dateRange,
      },
      page,
      limit: rowsPerPage,
    });

    const data = exportData?.data?.map((data, idx) => ({
      Restaurant: data?.restaurant?.restaurant?.name,
      "Order Num": data?.orderNum,
      "Transaction ID": data?.transactionId?.toString(),
      Subtotal: toFixed(data?.subTotal),
      Total: toFixed(data?.total),
      "Order Fee": toFixed(data?.orderFee),
      "Created At": moment(data?.createdAt).format("MM/DD/YYYY"),
    }));
    exportAsCSV({ arrayOfObject: data, fileName: "orderfee-report" });
  };

  const promiseOptions = (inputValue) => {
    return apiCaller(
      `/restaurant/search?_q=${encodeURIComponent(
        inputValue
      )}&partner=${partnerId}`
    )
      .then((res) => {
        return res?.data?.map((item) => ({
          value: item.userId,
          label: item.name,
        }));
      })
      .catch((err) => []);
  };

  useEffect(() => {
    find({
      partner: partnerId,
      restaurants,
      date: {
        ...dateRange,
      },
      page,
      limit: rowsPerPage,
    });
  }, [restaurants, dateRange, rowsPerPage, page]);

  const emailReport = useCallback(() => {
    setIsLoading(true);
    apiCaller(`/reports/partner/order-fee/email`, {
      query: {
        partner: partnerId,
        restaurants,
        date: {
          ...dateRange,
        },
      },
    })
      .then((res) => {
        toast("Email sent successfully");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [restaurants, dateRange, rowsPerPage, page]);

  return (
    <>
      <ConfirmModal open={open} toggle={() => setOpen(!open)} />
      <SimpleHeader
        name="Order Fee Report"
        parentName="Dashboard"
        showBtn={true}
      >
        <Button onClick={() => handleExport()} color="primary" size="md">
          CSV
        </Button>
        <Button onClick={() => emailReport()} color="primary" size="md">
          {isLoading ? <Spinner size={"sm"} /> : "Email report"}
        </Button>
      </SimpleHeader>

      <TableWrapper customStyles="px-4 py-3">
        <Row>
          <Col md="6">
            <FormGroup className="text-left">
              <label>Restaurants</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isMulti
                loadOptions={promiseOptions}
                onChange={(options = []) => {
                  if (options.length > 0) {
                    setRestaurants(options.map((opt) => opt.value));
                  } else {
                    setRestaurants([]);
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="text-left">
              <label className="text-left">Date</label>
              <div
                className="rounded"
                style={{
                  border: "1px solid hsl(0, 0%, 80%)",
                  height: "40px",
                  width: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setShowDate(!showDate)}
              >
                {moment(dateRange.from).format("MM/DD/YYYY")} -{" "}
                {moment(dateRange.to).format("MM/DD/YYYY")}
              </div>
            </FormGroup>
          </Col>

          {showDate && (
            <div
              className="px-3 py-3 shadow"
              style={{
                position: "absolute",
                zIndex: 999,
                backgroundColor: "#fff",
                cursor: "pointer",
                right: 150,
                top: 100,
              }}
            >
              <FaTimes
                className="mb-5"
                color="black"
                onClick={() => setShowDate(!showDate)}
              />
              <DateRangePicker
                ranges={[selectionRange]}
                rangeColors={["#0FCDEF"]}
                onChange={handleSelectDates}
                moveRangeOnFirstSelection={false}
                inputRanges={[]}
              />
            </div>
          )}
        </Row>
        {!loading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={entities?.data?.data}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={entities?.data?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={loading}
            history={props.history}
            row={TableRowOrderFeeReports}
            columns={Headers}
            isLoading={loading}
            emptyMessage="Currently no Data"
            prevPage={entities?.data?.prevPage}
            nextPage={entities?.data?.nextPage}
            currentPage={entities?.data?.currentPage}
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}

export default OrderFeeReports;
