const TableRowSmsReports = ({ data }) => {
  return (
    <tr className="text-left">
      <th>{data?.partnerName}</th>
      <th>{data?.restaurantName}</th>
      <th>{data?.orderCount?.length}</th>
      <th>{data?.count}</th>
    </tr>
  );
};

export default TableRowSmsReports;
