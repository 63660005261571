import styled from "styled-components"
import { useTheme } from "../../store/theme"

const StyledCheckbox = styled.input`
  appearance: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  border: none;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
  &:checked {
    background-color: ${(props) => props.themeColor};
  }
`

const CheckboxWrapper = styled.div`
  margin-top: 2px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 2px;
  padding: 1.5px;
`

export default function CustomCheckbox({ borderColor, bgColor, ...props }) {
  const { theme } = useTheme()

  return (
    <CheckboxWrapper borderColor={theme.primary}>
      <StyledCheckbox
        type="checkbox"
        borderColor={theme.primary}
        bgColor={theme.primary}
        themeColor={theme.primary}
        {...props}
      />
    </CheckboxWrapper>
  )
}
