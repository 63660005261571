const TableRowRestaurantCateringReports = ({ data }) => {
  return (
    <tr className="text-left">
      <td>{data?.totalOrders}</td>
      <td>{data?.totalItems}</td>
      <td>${data?.total.toFixed(2)}</td>
      <td>${data?.subTotal.toFixed(2)}</td>
      <td>${data?.coupon.toFixed(2)}</td>
      <td>${data?.tax.toFixed(2)}</td>
      <td>${data?.tip.toFixed(2)}</td>
      <td>${data?.deliveryFee.toFixed(2)}</td>
      <td>{Math.ceil(data?.creditUsed)}</td>
    </tr>
  );
};

export default TableRowRestaurantCateringReports;
