import { FaEnvelope, FaPen, FaPhone, FaUserCircle } from "react-icons/fa";
import { Card, CardBody } from "reactstrap";
import useOrdering from "../../../../store/ordering";
import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant";

const Guest = ({ handleChange, errors, touched, values }) => {
  const { restaurant } = useOrderingRestaurant();
  const { method } = useOrdering();

  if (method === "walkup") {
    return (
      <Card style={{ boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)" }}>
        <CardBody>
          <h1>Guest</h1>
          <div>
            <div className="input_group_checkout">
              <div
                className="px-3 input_checkout"
                style={{
                  backgroundColor: "#ededed",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: errors?.name && touched?.name && "1px solid red",
                }}
              >
                <input
                  className=" py-3"
                  style={{
                    border: "none",
                    flex: 1,
                    fontSize: "18px",
                    background: "transparent",
                  }}
                  type="text"
                  placeholder="Name"
                  onChange={handleChange("name")}
                  value={values?.name}
                />

                <FaUserCircle size={25} />
              </div>
            </div>
            {errors.name && touched.name ? (
              <small className="text-red mb-2">{errors.name}</small>
            ) : (
              <small></small>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      <Card style={{ boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)" }}>
        <CardBody>
          <h1>Guest</h1>
          <div>
            <div className="input_group_checkout">
              <div style={{ flex: 1 }}>
                <div
                  className="px-3 mt-2"
                  style={{
                    backgroundColor: "#ededed",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: errors?.name && touched?.name && "1px solid red",
                  }}
                >
                  <input
                    className=" py-3"
                    style={{
                      border: "none",
                      flex: 1,
                      fontSize: "18px",
                      background: "transparent",
                    }}
                    type="text"
                    placeholder="Name"
                    required={errors.name}
                    onChange={handleChange("name")}
                    value={values?.name}
                  />

                  <FaUserCircle size={25} />
                </div>
                {errors.name && touched.name ? (
                  <small className="text-red mb-2">{errors.name}</small>
                ) : (
                  <small></small>
                )}
              </div>

              <span className="mx-5"></span>
              <div style={{ flex: 1 }}>
                <div
                  className="px-3 mt-2"
                  style={{
                    backgroundColor: "#ededed",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: errors?.phone && touched?.phone && "1px solid red",
                  }}
                >
                  <input
                    className=" py-3"
                    style={{
                      border: "none",
                      flex: 1,
                      fontSize: "18px",
                      background: "transparent",
                    }}
                    type="number"
                    placeholder="Telephone"
                    onChange={handleChange("phone")}
                    value={values?.phone}
                  />
                  <FaPhone size={25} />
                </div>
                {errors.phone && touched.phone ? (
                  <small className="text-red mb-2">{errors.phone}</small>
                ) : (
                  <small></small>
                )}
              </div>
            </div>
            <div className="input_group_checkout mt-3">
              <div style={{ flex: 1 }}>
                <div
                  className="px-3 mt-2 "
                  style={{
                    backgroundColor: "#ededed",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: errors?.email && touched?.email && "1px solid red",
                  }}
                >
                  <input
                    className=" py-3"
                    style={{
                      border: "none",
                      flex: 1,
                      fontSize: "18px",
                      background: "transparent",
                    }}
                    type="email"
                    placeholder="Email"
                    onChange={handleChange("email")}
                    value={values?.email}
                  />
                  <FaEnvelope size={25} />
                </div>
                {errors.email && touched.email ? (
                  <small className="text-red mb-2">{errors.email}</small>
                ) : (
                  <small></small>
                )}
              </div>

              <span className="mx-5"></span>
              <div style={{ flex: 1 }}>
                {" "}
                <div
                  className="px-3 mt-2"
                  style={{
                    backgroundColor: "#ededed",
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: errors?.note && touched?.note && "1px solid red",
                  }}
                >
                  <input
                    className=" py-3"
                    style={{
                      border: "none",

                      flex: 1,
                      fontSize: "18px",
                      background: "transparent",
                    }}
                    type="text"
                    placeholder={
                      restaurant?.ordering?.notePlaceholderText || "Add Note"
                    }
                    onChange={handleChange("note")}
                    value={values?.note}
                  />
                  <FaPen size={22} />
                </div>
                {errors.note && touched.note ? (
                  <small>HELLO</small>
                ) : (
                  <small></small>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Guest;
