import React from "react";
import { toast } from "react-toastify";
import { useTheme } from "../../../../store/theme";

const IconButtonLarge = ({ setMethod, disabled, title, image, style = {} }) => {
  const { theme } = useTheme();
  return (
    <div
      className="method_ordering_card"
      onClick={() => {
        if (!disabled) {
          setMethod();
        } else {
          toast.error("Online ordering is currently disabled");
        }
      }}
      style={{ ...style }}
    >
      <div
        className="mx-3"
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            height: 70,
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ height: "70%", width: "70%" }}
            src={image}
            alt="pickup_logo"
          />
        </div>
      </div>
      <div
        style={{
          fontSize: 20,
          color: theme?.primary,
          flex: 1,
          fontWeight: "600",
          textTransform: "uppercase",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default IconButtonLarge;
