export default function getSortedArray(array, customKey = undefined) {
  if (customKey !== undefined) {
    return array
      .sort(
        (elem1, elem2) => Number(elem1[customKey]) - Number(elem2[customKey])
      )
      .sort(
        (elem1, elem2) =>
          (elem2.defaultSelected ? 1 : -1) - (elem1.defaultSelected ? 1 : -1)
      );
  }
  return array
    .sort((elem1, elem2) => Number(elem1.sort) - Number(elem2.sort))
    .sort(
      (elem1, elem2) =>
        (elem2.defaultSelected ? 1 : -1) - (elem1.defaultSelected ? 1 : -1)
    );
}
