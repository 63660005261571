import { Card } from "reactstrap";
import { useTheme } from "../../../../store/theme";
import CheckoutGraphic from "../../../../assets/icons/checkout_graphic.svg";
import MoreInfoForm from "./MoreInfoForm";

export default function MoreInfoCard({ data, handleFormInfoChange }) {
  const { theme } = useTheme();

  if (data?.length === 0) {
    return <></>;
  }
  return (
    <Card
      className="px-3 py-3"
      style={{
        boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ overflow: "none", height: "max-content" }}
      >
        <div className="right_border" style={{ flex: 1.5 }}>
          <div className="py-4">
            <h1
              style={{
                fontWeight: "600",
                color: theme.primary,
              }}
            >
              {data?.title}
            </h1>
            <hr className="my-4" style={{ padding: 0, margin: 0 }} />
            <MoreInfoForm
              onChange={(val) => handleFormInfoChange(val)}
              {...data}
            />
            <hr
              className="my-4 hide_mobile"
              style={{ padding: 0, margin: 0 }}
            />
          </div>
        </div>
        <div className="hide mx-5"></div>
        <div
          className="hide "
          style={{
            overflow: "none",
            width: "100%",
            flex: 1,
            backgroundImage: `url(${CheckoutGraphic})`,
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    </Card>
  );
}
