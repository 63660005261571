export function exportAsCSV({ arrayOfObject, fileName }) {
  const headers = Object.keys(arrayOfObject[0]);
  const csvData = arrayOfObject
    .map((row) => Object.values(row).join(","))
    .join("\n");
  const csvContent = `data:text/csv;charset=utf-8,${headers}\n${csvData}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
}
