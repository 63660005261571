import classnames from "classnames";
import { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

export default function GeneralForm({
  handleChange,
  values,
  address,
  addressValues,
}) {
  const [selected, setSelected] = useState([]);
  const [activeAddress, setActiveAddress] = useState(-1);

  const handleDelete = (i) => {
    setActiveAddress(0);
    let arr = selected.filter((it) => it.label !== selected[i].label);
    setSelected(arr);
  };
  const [tabs, setTabs] = useState(1);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {
    address(selected);
  }, [selected]);

  useEffect(() => {
    if (addressValues) {
      setSelected(addressValues);
    }
  }, [addressValues]);

  return (
    <>
      <Nav
        className="nav-fill flex-column flex-md-row mb-4 mx-5"
        id="tabs-icons-text"
        pills
        role="tablist"
      >
        <NavItem>
          <NavLink
            aria-selected={tabs === 1}
            className={classnames("mb-sm-3 mb-md-0", {
              active: tabs === 1,
            })}
            onClick={(e) => {
              toggleNavs(e, 1);
              setActiveAddress(-1);
            }}
            href="#pablo"
            role="tab"
          >
            <i className="fa fa-book mr-2" />
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            aria-selected={tabs === 2}
            className={classnames("mb-sm-3 mb-md-0", {
              active: tabs === 2,
            })}
            onClick={(e) => toggleNavs(e, 2)}
            href="#pablo"
            role="tab"
          >
            <i className="fa fa-print mr-2" />
            Address
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="mt-5 mx-5" activeTab={"tabs" + tabs}>
        <TabPane tabId="tabs1" style={{ textAlign: "left" }}>
          {activeAddress === -1 && (
            <Col md={12}>
              <Form>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Name
                  </Label>
                  <Col md="8">
                    <Input
                      id="example-text-input"
                      type="text"
                      value={values?.name}
                      placeholder="Name"
                      onChange={handleChange("name")}
                    ></Input>
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Email
                  </Label>
                  <Col md="8">
                    <Input
                      value={values?.email}
                      placeholder="Email"
                      onChange={handleChange("email")}
                      type="text"
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Phone
                  </Label>
                  <Col md="8">
                    <Input
                      type="number"
                      value={values?.phone}
                      placeholder="Phone"
                      onChange={handleChange("phone")}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Fax
                  </Label>
                  <Col md="8">
                    <Input
                      id="example-text-input"
                      type="text"
                      value={values?.fax}
                      placeholder="Fax"
                      onChange={handleChange("fax")}
                    ></Input>
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Newsletter
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        onChange={handleChange("newsLetter")}
                        value={values?.newsLetter}
                        checked={values?.newsLetter}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Approved
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        onChange={handleChange("approved")}
                        value={values?.approved}
                        checked={values?.approved}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="form-control-label text-left"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Safe
                  </Label>
                  <Col md="8">
                    <label className="custom-toggle">
                      <input
                        onChange={handleChange("safe")}
                        value={values?.safe}
                        checked={values?.safe}
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          )}
        </TabPane>
        <TabPane tabId="tabs2">
          <Row>
            <Col md={3}>
              <ListGroup>
                {selected.map((d, i) => {
                  return (
                    <ListGroupItem
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        borderRadius: "5px",
                      }}
                      className={`${
                        i === activeAddress && "active"
                      } border mb-3`}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveAddress(i);
                      }}
                      tag="a"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          padding: 5,
                        }}
                      >
                        <i
                          style={{
                            color: i === activeAddress ? "white" : "red",
                          }}
                          className="fa fa-minus-circle ml-2"
                          onClick={() => {
                            handleDelete(i);
                          }}
                        />
                        <span className="ml-2">{d.label}</span>
                      </div>
                    </ListGroupItem>
                  );
                })}
                <ListGroupItem
                  style={{
                    padding: 5,
                    cursor: "pointer",
                    borderRadius: "5px",
                  }}
                  className="border"
                  onClick={() => {
                    setSelected([
                      ...selected,
                      { label: "Address - " + parseInt(selected.length + 1) },
                    ]);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      padding: 5,
                    }}
                  >
                    <i className="fa fa-plus-circle ml-2" />
                    <span className="ml-2">Add new</span>
                  </div>
                </ListGroupItem>
              </ListGroup>
            </Col>
            {selected.length > 0 && activeAddress > -1 && (
              <Col md={9}>
                <Form>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      First Name
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="First Name"
                        type="text"
                        value={selected[activeAddress]?.firstName}
                        onChange={(e) =>
                          (selected[activeAddress].firstName = e.target.value)
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Lastname
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="Last name"
                        value={selected[activeAddress]?.lastName}
                        onChange={(e) =>
                          (selected[activeAddress].lastName = e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Company
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="Company"
                        value={selected[activeAddress]?.company}
                        onChange={(e) =>
                          (selected[activeAddress].company = e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Address 1
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="Address Line 1"
                        value={selected[activeAddress]?.addressLineOne}
                        onChange={(e) =>
                          (selected[activeAddress].addressLineOne =
                            e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Address 2
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="Address Line 2"
                        value={selected[activeAddress]?.addressLineTwo}
                        onChange={(e) =>
                          (selected[activeAddress].addressLineTwo =
                            e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      City
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="City"
                        value={selected[activeAddress]?.city}
                        onChange={(e) =>
                          (selected[activeAddress].city = e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Post Code
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="Post Code"
                        value={selected[activeAddress]?.postCode}
                        onChange={(e) =>
                          (selected[activeAddress].postCode = e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Country
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="Country"
                        value={selected[activeAddress]?.country}
                        onChange={(e) =>
                          (selected[activeAddress].coutry = e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Region/State
                    </Label>
                    <Col md="8">
                      <Input
                        placeholder="State"
                        value={selected[activeAddress]?.state}
                        onChange={(e) =>
                          (selected[activeAddress].state = e.target.value)
                        }
                        type="text"
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label text-left"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Default Address
                    </Label>
                    <Col
                      md="6"
                      style={{
                        textAlign: "left",
                        paddingLeft: 40,
                        paddingTop: 10,
                      }}
                    >
                      <label className="custom-toggle">
                        <input
                          value={selected[activeAddress]?.defaultAddress}
                          onChange={(e) =>
                            (selected[activeAddress].defaultAddress =
                              e.target.value)
                          }
                          checked={selected[activeAddress]?.defaultAddress}
                          type="checkbox"
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            )}
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
}
