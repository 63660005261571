import qs from "qs";
import { HOST } from "../config";
import { trigger } from "../utils/custom-event";
import DB from "../utils/DB";
import { DBKeys } from "../utils/Keys";
import { ResponseError } from "./error";

export default async function apiCaller(
  endpoint,
  options = { headers: {}, auth: true, method: "GET" }
) {
  let authorizationHeader = {};
  const pathname = window.location.pathname;
  if (pathname.split("/")[1] === "ordering") {
    let token = await DB.retrieveData(DBKeys.CUSTOMER_TOKEN);
    authorizationHeader["Authorization"] = `Bearer ${token}`;
  } else {
    let token = await DB.retrieveData(DBKeys.TOKEN);
    authorizationHeader["token"] = token;
  }

  const opt = {
    method: options.method,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader,
      ...options.headers,
    },
  };

  if (!options.auth) {
    delete opt.Authorization;
  }

  let url = `${HOST}${endpoint}`;

  if (options.query) {
    url += `?${qs.stringify(options.query)}`;
  }

  if (options.body) {
    opt.body = JSON.stringify(options.body);
  }

  if (options.params) {
    Object.keys(options.params).forEach((key) => {
      url = url.replace(`:${key}`, options.params[key]);
    });
  }

  try {
    console.log("api call", url, opt);
    const response = await fetch(url, opt);
    let jsonResponse;
    const contentType = response.headers.get("content-type");
    if (contentType.includes("application/json")) {
      jsonResponse = await response.json();
    } else if (contentType.includes("text/csv")) {
      jsonResponse = await response.blob();
    } else {
      jsonResponse = await response.text();
    }

    if (response.ok) {
      return jsonResponse;
    }

    console.log("SERVICE_CALLER", response);

    throw new ResponseError(jsonResponse);
  } catch (error) {
    if (
      error.message === "jwt malformed" ||
      error.message === "USER_INACTIVE"
    ) {
      trigger("logout", null, null, null, null);
    }
    throw error instanceof ResponseError
      ? error
      : new ResponseError({ code: 500 });
  }
}
