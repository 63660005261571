import { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { AuthContext } from "../../../../../context";
import { useEntity } from "../../../../../hooks/useEntity";
import { useAdminStore } from "../../../../../store/admin";
import SuperTable from "../../../../../widgets/SuperTable";
import TableRowCoupons from "../TableRowCoupons";

export default function ReEngageCoupons(props) {
  const { find, entities, loading } = useEntity("coupon");
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
      reEngageCoupon: true,
    });
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };
  const Headers = [
    "Coupon",
    "Code",
    "Type",
    "Discount",
    "Date Start",
    "Date End",
    "Status",
    "Action",
  ];
  return (
    <>
      {!loading ? (
        <SuperTable
          sort={handleSort}
          showCheckboxes={false}
          data={entities?.data}
          onPageChange={handlePageChange}
          OnRowPerPageChange={handleRowsPerPageChange}
          total={entities?.data?.length}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          loading={loading}
          history={props.history}
          row={TableRowCoupons}
          columns={Headers}
          isLoading={loading}
          emptyMessage="Currently no coupons"
          prevPage={entities?.data?.prevPage}
          nextPage={entities?.data?.nextPage}
          currentPage={entities?.data?.currentPage}
        />
      ) : (
        <div
          style={{
            padding: 20,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <center>
            <Spinner size="lg" color="primary" type="border" />
          </center>
        </div>
      )}
    </>
  );
}
