/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import LineChart from "../../components/Charts/LineChart";
import { useEntity } from "../../hooks/useEntity";
import { DBKeys } from "../../utils/Keys";
import { toFixed } from "../../utils/toFixed";
import StatCards from "./components/StatCards";
import { isMobileOnly } from "react-device-detect";

export default function Dashboard(props) {
  const { findOne: findStat, entity: stats } = useEntity("stats/admin");
  const { find: findOrders, entities: orders } = useEntity(
    "stats/latest-orders"
  );
  React.useEffect(() => {
    findStat("");
    findOrders();
  }, []);

  React.useEffect(() => {
    if (
      !localStorage.getItem(DBKeys.USER) ||
      !localStorage.getItem(DBKeys.TOKEN)
    ) {
      props.history.push("/auth");
    }
  }, []);

  const orderStats = stats?.data;

  return (
    <>
      <Container className="pt-3" fluid>
        <Row>
          <Col md="6" xl="3">
            <StatCards
              title="Monthly Revenue"
              value={`$${Math.ceil(orderStats?.totalSales || 0)}`}
              bgColor="primary"
            />
          </Col>
          <Col md="6" xl="3">
            <StatCards
              title="Fees Collected"
              value={`$${Math.ceil(orderStats?.totalOrderFee || 0)}`}
              bgColor="info"
            />
          </Col>
          <Col md="6" xl="3">
            <StatCards
              title="Monthly Orders"
              value={`${Math.ceil(orderStats?.totalOrders || 0)}`}
              bgColor="danger"
            />
          </Col>
          <Col md="6" xl="3">
            <StatCards
              title="Monthly New Users"
              value={`${Math.ceil(orderStats?.totalCustomer || 0)}`}
              bgColor="default"
            />
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="h3 mb-0">Analytics</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <LineChart
                    data={[
                      {
                        label: "Orders",
                        labels: orderStats?.customerVsOrders?.labels,
                        data: orderStats?.customerVsOrders?.ordersByMonth,
                        color: "#5e72e4",
                      },
                      {
                        label: "Customers",
                        labels: orderStats?.customerVsOrders?.labels,
                        data: orderStats?.customerVsOrders?.customersByMonth,
                        color: "#fb6340",
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0 text-left">Latest Orders</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" scope="col">
                      OrderID
                    </th>
                    <th className="sort" scope="col">
                      Customer
                    </th>
                    <th className="sort" scope="col">
                      Status
                    </th>
                    <th className="sort" scope="col">
                      Order Date
                    </th>
                    <th scope="col">Total</th>
                    <th scope="col">Method</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {orders.data?.map?.((order) => {
                    return (
                      <tr>
                        <th scope="row">{order.orderNum}</th>
                        <td>{order.customer.name}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={
                                order.status === "pending"
                                  ? "bg-info"
                                  : "bg-success"
                              }
                            />
                            <span className="status">{order.status}</span>
                          </Badge>
                        </td>
                        <td>{moment(order.createdAt).format("MM/DD/YYYY")}</td>
                        <td>${toFixed(order.payment.total)}</td>
                        <td>{order.method}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {isMobileOnly && <div style={{ height: 200 }}></div>}
    </>
  );
}
