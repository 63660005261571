function toTimeZone(date, timezone) {
  const availableTimezones = {
    "Pacific Standard Time": "America/Los_Angeles",
    "Atlantic Standard Time": "America/Anguilla",
    "US Eastern Standard Time": "America/Atikokan",
    "Central Standard Time": "America/Bahia_Banderas",
    "Mountain Standard Time (Mexico)": "America/Boise",
    India: "Asia/Kolkata",
  };

  // if (window.location.host.includes("localhost")) {
  //   timezone = "India";
  // }
  const str = new Date(date).toLocaleString("en-US", {
    timeZone:
      availableTimezones[timezone] ||
      availableTimezones["Pacific Standard Time"],
  });
  return new Date(str);
}

module.exports = toTimeZone;
