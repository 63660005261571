import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { Button, Col, Container, Input, Row } from "reactstrap";
import generateJKSoftLoginId from "../../../../utils/generateJKSoftLoginId";

export default function JKSoftIntegration({
  errors,
  touched,
  values,
  handleChange,
}) {
  return (
    <div style={{ zIndex: 999, position: "relative" }}>
      <Container fluid>
        <Row className="mt-3">
          <Col md="6">
            <Row className="text-left">
              <Col
                md="6"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Enable JKSoft
              </Col>
              <Col md="6">
                <label className="custom-toggle">
                  <input
                    type="checkbox"
                    checked={values?.enableJKSoft}
                    value={values?.enableJKSoft}
                    onChange={handleChange("enableJKSoft")}
                  />
                  <span className="custom-toggle-slider rounded-circle"></span>
                </label>
              </Col>
            </Row>
          </Col>
        </Row>

        {values.enableJKSoft && (
          <Row className="mt-3">
            <Col md="6">
              <Row className="text-left">
                <Col
                  md="4"
                  style={{
                    display: "flex",
                    // justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                    // textAlign: "right",
                  }}
                >
                  Login ID
                </Col>
                <Col md="6" className="mt-2">
                  <Input
                    type="text"
                    disabled={true}
                    onChange={handleChange}
                    value={values.JKSoftLoginId}
                    name="JKSoftLoginId"
                    className={`form-control ${
                      errors.JKSoftLoginId && touched.JKSoftLoginId
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </Col>
                <Col md="2" className="mt-2">
                  <Button
                    size="md"
                    color="secondary"
                    onClick={() => {
                      if (values.JKSoftLoginId) {
                        navigator.clipboard.writeText(values.JKSoftLoginId);
                        toast.success("Copied to clipboard");
                        return;
                      }
                      const loginId = generateJKSoftLoginId(10);
                      handleChange("JKSoftLoginId")(loginId);
                    }}
                  >
                    {values.JKSoftLoginId ? "Copy" : "Generate"}
                  </Button>
                </Col>
              </Row>
            </Col>
            {values.clientId && (
              <Col md="6">
                <Row className="text-left">
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: isMobile ? "center" : "flex-end",
                      alignItems: "center",

                      // textAlign: "right",
                    }}
                  >
                    Client ID
                  </Col>
                  <Col md="6" className="mt-2">
                    <Input
                      type="text"
                      disabled={true}
                      onChange={handleChange}
                      value={values.clientId}
                      name="clientId"
                    />
                  </Col>
                  <Col md="2" className="mt-2">
                    <Button
                      size="md"
                      color="secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(values.clientId);
                        toast.success("Copied to clipboard");
                      }}
                    >
                      Copy
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}

            <Col md="6" className="mt-4">
              {values.enableJKSoft && (
                <Row className="text-left">
                  <Col md="6" style={{}}>
                    Hide Modifier Title On Print
                  </Col>
                  <Col md="6">
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={values?.hideModifierTitleJKSoft}
                        value={values?.hideModifierTitleJKSoft}
                        onChange={handleChange("hideModifierTitleJKSoft")}
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
