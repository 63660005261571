import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import apiCaller from "../../../api/apiCaller";
import AuthHeader from "../../../components/Header/AuthHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import DB from "../../../utils/DB";
import { DBKeys } from "../../../utils/Keys";
import logger from "../../../utils/logger";
import { STREAM_POS_URL } from "../../../config";

export default function OAuthLandingStream(props) {
  const mainContentRef = React.useRef(null);
  const { create: login } = useEntity("auth/local");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const authContext = useContext(AuthContext);

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );

  const handleLogin = async () => {
    setLoading(true);

    try {
      const res = await login({
        email,
        password,
      });
      if (
        (await DB.storeData(DBKeys.USER, res?.data?.user)) &&
        (await DB.storeData(DBKeys.TOKEN, res?.data?.accessToken))
      ) {
        authContext.login(res?.data);
        swal({
          title: "Are you sure?",
          text: "Yo're authorizing Stream to access your resources",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (agree) => {
          if (agree) {
            const query = [...searchParams.entries()]
              .map((kv) => {
                return `${kv[0]}=${kv[1]}`;
              })
              .join("&");

            const code = query.split("=")?.[1];

            apiCaller("/stream-pos/v1/authorization-code", {
              headers: { token: res?.data?.accessToken },
              method: "POST",
              body: { client_id: code },
            })
              .then((res) =>
                window.location.replace(
                  `${STREAM_POS_URL}${res?.data?.authorizationCode}`
                )
              )
              .catch((err) => console.log(err));
          }
        });
      }
    } catch (error) {
      logger.push({ error, info: "OAuthLogin Stream Token Error" });
      console.log(error, "CODES");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {
        <div ref={mainContentRef}>
          <div
            className="mt-4 mx-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Button size="sm">Contact</Button>
            </div>
          </div>

          <div
            className="d-flex"
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <Container
              style={{
                maxWidth: "448px",
              }}
            >
              <Card
                style={{ boxShadow: "0px 1px 3px 0px rgba(50, 50, 93, 0.15)" }}
              >
                <CardBody>
                  <AuthHeader
                    title="Please login with your credentials"
                    lead=""
                  />
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder={"Email"}
                          type={"email"}
                          value={email}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          placeholder={"Password"}
                          type={"password"}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Button
                      block
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={handleLogin}
                    >
                      {loading ? <Spinner size="sm" color="white" /> : "Login"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Container>
          </div>
        </div>
      }
    </>
  );
}
