import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import swal from "sweetalert";
import { useEntity } from "../../../hooks/useEntity";
import DB from "../../../utils/DB";
import logger from "../../../utils/logger";
import { useLocation } from "react-router-dom";

export const TableRow = ({ history, data }) => {
  const location = useLocation();
  const { deleteEntity } = useEntity("partner");
  const { updateEntity, loading: updateLoading } = useEntity("partner");

  return (
    <tr className="text-left">
      <th scope="row">
        <Media
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            history.push({
              pathname: "/admin/partners/resturants",
              search: `?partnerId=${data?._id}`,
            });
          }}
        >
          {data?.partner?.name}
        </Media>
      </th>
      <td>{data?.partner?.phone}</td>
      <td> {data?.email}</td>

      <td>
        {updateLoading ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <label className="custom-toggle">
            <input
              checked={data?.status === "active"}
              type="checkbox"
              onClick={async () => {
                try {
                  await updateEntity(data?._id, {
                    status: data?.status === "active" ? "inactive" : "active",
                  });
                  toast.success("Partner updated successfully");
                } catch (error) {
                  logger.push({ error, info: "TableRowPartners" });
                  toast(error.message);
                }
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="Off"
              data-label-on="On"
            />
          </label>
        )}
      </td>
      <td>
        <UncontrolledDropdown group>
          <DropdownToggle style={{ backgroundColor: "#fff", padding: 0 }}>
            <i
              className="fa fa-ellipsis-v text-muted"
              style={{ fontSize: 18 }}
            />
          </DropdownToggle>
          <DropdownMenu style={{ zIndex: 100 }}>
            <DropdownItem
              onClick={() => {
                history.push({
                  pathname: "/admin/partners/resturants",
                  search: `?partnerId=${data?._id}`,
                });
              }}
            >
              View
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                let query = `partnerId=${data._id}`;
                if (location.search.split("=")[1]) {
                  query = query + `&partnerId=${location.search.split("=")[1]}`;
                }
                history.push({
                  pathname: "/partners",
                  search: query,
                });
              }}
            >
              Login As Partner
            </DropdownItem>

            <DropdownItem
              onClick={() =>
                history.push({
                  pathname: "/admin/edit-partner",
                  state: data?._id,
                })
              }
            >
              Edit
            </DropdownItem>
            <DropdownItem
              className="text-danger"
              onClick={async () => {
                try {
                  swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willDelete) => {
                    if (willDelete) {
                      await deleteEntity(data?._id);
                      toast.error("Partner deleted succesfully");
                    }
                  });
                } catch (error) {
                  logger.push({ error, info: "TableRowPartnerDelete" });
                  toast(error.message);
                }
              }}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};
