import { useEffect, useState } from "react";
// reactstrap components
import { Button, Spinner } from "reactstrap";
// core components
import SimpleHeader from "../../components/Header/SimpleHeader";
import { useEntity } from "../../hooks/useEntity";
import SuperTable from "../../widgets/SuperTable";
import TableWrapper from "../../widgets/TableWrapper";
import { TableRow } from "./components/TableRow";

function Partners(props) {
  const [sorting, setSorting] = useState(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSort = () => {
    if (sorting === -1) {
      setSorting(1);
    } else {
      setSorting(-1);
    }
  };

  const {
    find: getPartners,
    entities: partnersList,
    loading: isLoading,
  } = useEntity("partner");

  let partners = partnersList?.data;

  useEffect(() => {
    getPartners();
  }, [sorting, page, rowsPerPage]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const Headers = ["Partner", "Phone", "Email", "Status", "Action"];

  return (
    <>
      <SimpleHeader
        path="/admin"
        name="Partners"
        parentName="Dashboard"
        showBtn={true}
      >
        <Button
          onClick={() => {
            props.history.push("/admin/add-partner");
          }}
          className="btn-primary"
          color="primary"
          size="md"
        >
          Add new
        </Button>
      </SimpleHeader>
      <TableWrapper>
        {!isLoading ? (
          <SuperTable
            sort={handleSort}
            showCheckboxes={false}
            data={partners}
            onPageChange={handlePageChange}
            OnRowPerPageChange={handleRowsPerPageChange}
            total={partnersList?.length}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            loading={isLoading}
            history={props.history}
            row={TableRow}
            columns={Headers}
            isLoading={isLoading}
            emptyMessage="Currently no partners"
          />
        ) : (
          <div
            style={{
              padding: 20,
              height: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
    </>
  );
}

export default Partners;
