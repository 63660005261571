import { useOrderingRestaurant } from "../../../../store/ordering/useOrderingRestaurant"
import { useTheme } from "../../../../store/theme"
import { truncateString } from "../../../../utils/truncate"

const SuggestedCard = ({ data, onClick }) => {
  const { theme } = useTheme()
  const { restaurant } = useOrderingRestaurant()

  return (
    <div
      className="single_card"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <div>
        <img
          src={data?.imageUrl === "" ? restaurant?.logo : data?.imageUrl}
          alt="item_image"
          style={{
            height: 80,
            width: 140,
            borderRadius: 6,
            objectFit: "cover",
            filter: data?.imageUrl === "" && "grayscale(100)",
            opacity: data?.imageUrl === "" && "5%",
          }}
        />
      </div>{" "}
      <div className="mt-2">
        <h2
          style={{
            color: "#212121",
            fontWeight: "400",
            textTransform: "uppercase",
          }}
        >
          {truncateString(data?.name, 20)}
        </h2>
      </div>
      <div className="slider_component_width" style={{ minHeight: "70px" }}>
        <span>{truncateString(data?.description, 120)}</span>
      </div>
      <div className="d-flex" style={{ alignItems: "center" }}>
        <button
          className="mt-3 px-4 py-2 bold "
          style={{
            border: `1px solid ${theme.primary}`,
            borderRadius: "6px",
            outline: "none",
            backgroundColor: "#fff",
            color: theme?.primary,
            textTransform: "uppercase",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Select
        </button>
      </div>
    </div>
  )
}

export default SuggestedCard
