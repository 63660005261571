import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import Select from "react-select";
import { useAdminStore } from "../../../store/admin";
import logger from "../../../utils/logger";

const AddEditModifiersGroup = (props) => {
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { restaurant } = useAdminStore();
  const { create: createModifiersGroup } = useEntity("modifier-group");
  const { updateEntity: updateModifiersGroup } = useEntity("modifier-group");
  const { findOne, entity } = useEntity("modifier-group");
  const { find, entities: modifiers } = useEntity("modifier");
  useEffect(() => {
    find({
      restaurant: authRest || restaurant,
    });
  }, []);

  const {
    handleSubmit,
    handleChange,
    values,
    isSubmitting,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      restaurant: authRest || restaurant,
      name: "",
      sortOrder: null,
      price: null,
      subProducts: [],
      required: false,
      min: 0,
      max: 0,
    },
    onSubmit: async (values) => {
      try {
        if (!entity) {
          await createModifiersGroup({
            ...values,
            subProducts: values.subProducts?.map((prod) => prod.value),
            source: "local",
          });
          toast.success("Modifier group created successfully");
        } else {
          await updateModifiersGroup(entity?.data?._id, {
            ...values,
            subProducts: values.subProducts?.map((prod) => prod.value),
          });
          toast.success("Modifier group updated successfully");
        }
        props.history.goBack();
      } catch (error) {
        logger.push({ error, info: "AddEditModifiersGroup" });

        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (props.location?.state) {
      findOne(props?.location?.state);
    }
  }, [props.location?.state]);

  useEffect(() => {
    if (entity) {
      setValues({
        restaurant: authRest || restaurant,
        name: entity?.data?.name,
        min: entity?.data?.min,
        max: entity?.data?.max,
        subProducts: modifiers?.data?.map((modifier) => {
          if (entity?.data?.subProducts?.includes(modifier?._id)) {
            return { label: modifier.name, value: modifier?._id };
          }
        }),
      });
    }
  }, [entity]);

  return (
    <div>
      <TimelineHeader name="Add Options" parentName="Options" showBtn={true}>
        <>
          <Button
            size="md"
            className="btn-icon btn-2"
            color="primary"
            type="submit"
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <Spinner size="sm" color="primary" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </>

        <Button
          className="btn-neutral text-black mr-4"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Back
        </Button>
      </TimelineHeader>
      <div
        className="mt--6 card p-4 mx-5"
        style={{ zIndex: 999, position: "relative" }}
      >
        <Container fluid>
          <Form className="mt-2">
            <FormGroup>
              <Row>
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "700" }}
                    htmlFor="optionName"
                  >
                    Name
                  </label>
                </Col>
                <Col md="8">
                  <Input
                    id="name"
                    multiple=""
                    type="text"
                    onChange={handleChange("name")}
                    value={values?.name}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "700" }}
                    htmlFor="min"
                  >
                    Min
                  </label>
                </Col>
                <Col md="8">
                  <Input
                    id="min"
                    multiple=""
                    type="text"
                    onChange={handleChange("min")}
                    value={values?.min}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "700" }}
                    htmlFor="max"
                  >
                    Max
                  </label>
                </Col>
                <Col md="8">
                  <Input
                    id="max"
                    multiple=""
                    type="text"
                    onChange={handleChange("max")}
                    value={values?.max}
                  ></Input>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "700" }}
                    htmlFor="max"
                  >
                    Options
                  </label>
                </Col>
                <Col md="8">
                  <Select
                    name="subProducts"
                    isSearchable={false}
                    onChange={(val) => {
                      setFieldValue("subProducts", val);
                    }}
                    value={values.subProducts}
                    closeMenuOnSelect={false}
                    isMulti
                    options={modifiers?.data?.map((modifier) => {
                      return { label: modifier.name, value: modifier?._id };
                    })}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default AddEditModifiersGroup;
