import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";

export default function AddTaxRate(props) {
  return (
    <>
      <TimelineHeader name="Tax Rate" parentName="Settings" showBtn>
        <Button className="btn-icon btn-2" color="primary" type="button">
          <span className="btn-inner--icon">
            <i className="fa fa-save" />
          </span>
        </Button>
      </TimelineHeader>
      <Container className="mt--4" fluid>
        <Row>
          <div className="col">
            <Card>
              <br></br>
              <Form className="tal">
                <Row className="mlr-15 justify-content-md-center">
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label className="form-control-label">Tax Name</Label>
                      <Input type="text"></Input>
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label className="form-control-label">Tax Rate</Label>
                      <Input type="text"></Input>
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label className="form-control-label">Type</Label>
                      <Input type="select">
                        <option value="Percentage">Percentage</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
