/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import moment from "moment-timezone";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../../components/Header/SimpleHeader";
import { AuthContext } from "../../../context";
import { useEntity } from "../../../hooks/useEntity";
import { useAdminStore } from "../../../store/admin";
import { validationSchemaAddCoupon } from "../../../utils/schemas/AddCoupon";
import TableWrapper from "../../../widgets/TableWrapper";
import { ErrorLable } from "../menu/tabs/GeneralTabItem";
import { useRestaurantStore } from "../../../store/restaurant";

export default function AddCoupon(props) {
  const locationID = useLocation();
  const { create, updateEntity } = useEntity("coupon");
  const { restaurant } = useAdminStore();
  const authContext = useContext(AuthContext);
  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { find: findCategories, entities: categories } = useEntity("category");
  const { find: findLocations, entities: locations } = useEntity("location");
  const { find: findItems, entities: products } = useEntity("item");
  const { restaurant: restaurantDoc } = useRestaurantStore();

  const { findOne, entity, loading } = useEntity("coupon");

  useEffect(() => {
    findCategories({
      restaurant: restaurant || authRest,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findItems({
      restaurant: authRest || restaurant,
      status: true,
      filterDsp:
        restaurantDoc?.restaurant?.stream?.enableStreamDSP &&
        restaurantDoc?.restaurant?.stream?.enableStreamPOS
          ? "true"
          : "false",
    });
    findLocations({
      resturant: authRest || restaurant,
    });
    if (locationID.state) {
      findOne(locationID.state);
    }
  }, [authRest, restaurant, locationID.state]);

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    setValues,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      restaurant: "",
      couponName: "",
      couponCode: "",
      couponType: "percentage",
      couponDiscount: 0,
      subTotal: "",
      products: [{ label: "All", value: "all" }],
      categories: [{ label: "All", value: "all" }],
      locations: [{ label: "All", value: "all" }],
      startDate: "",
      endDate: "",
      usesPerCoupon: "",
      usesPerCustomer: "",
      status: true,
      method: "",
      customerLogin: true,
    },
    validationSchema: validationSchemaAddCoupon,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        partner: null,
        restaurant: authRest || restaurant,
        couponName: values?.couponName,
        couponCode: values?.couponCode,
        couponType: values?.couponType,
        couponDiscount:
          values?.couponType !== "free_delivery" ? values?.couponDiscount : 0,
        subTotal: values?.subTotal,
        items: values?.products?.map((pro) => {
          return pro?.value;
        }),
        categories: values?.categories?.map((cat) => {
          return cat?.value;
        }),
        location: values?.locations?.map((loc) => {
          return loc?.value;
        }),
        duration: {
          startDate: moment(values?.startDate).tz("UTC").toDate(),
          endDate: moment(values?.endDate).tz("UTC").toDate(),
        },
        usesPerCoupon: values?.usesPerCoupon,
        usesPerCustomer: values?.usesPerCustomer,
        status: values?.status,
        method:
          values?.couponType !== "free_delivery" ? values?.method : "delivery",
        customerLogin: values?.customerLogin,
        level: "restaurant",
      };

      if (data.items.length === 0) {
        data.items = ["all"];
      }
      if (data.categories.length === 0) {
        data.categories = ["all"];
      }
      if (data.location.length === 0) {
        data.location = ["all"];
      }

      try {
        if (!locationID.state) {
          await create({
            ...data,
          });
          toast.success("Coupon created successfully");
        } else {
          await updateEntity(locationID.state, {
            ...data,
          });
          toast.success("Coupon updated successfully");
        }

        resetForm();
        props.history.goBack();
      } catch (error) {}
    },
  });

  useEffect(() => {
    if (entity) {
      setValues({
        restaurant: entity?.data?.restaurant,
        couponName: entity?.data?.couponName,
        couponCode: entity?.data?.couponCode,
        couponType: entity?.data?.couponType,
        couponDiscount: entity?.data?.couponDiscount,
        subTotal: entity?.data?.subTotal,
        products: entity?.data?.items?.map((pr) => {
          return {
            label: pr?.name,
            value: pr?._id,
          };
        }),
        categories: entity?.data?.categories?.map((cat) => {
          return {
            label: cat?.categoryName,
            value: cat?._id,
          };
        }),
        locations: entity?.data?.location?.map((it) => {
          return {
            label: it?.displayAddress,
            value: it?._id,
          };
        }),
        startDate: entity?.data?.duration?.startDate,
        endDate: entity?.data?.duration?.endDate,
        usesPerCoupon: entity?.data?.usesPerCoupon,
        usesPerCustomer: entity?.data?.usesPerCustomer,
        status: entity?.data?.status,
        method: entity?.data?.method,
        customerLogin: entity?.data?.customerLogin,
      });
    }
  }, [entity]);

  return (
    <>
      <TimelineHeader
        name={!locationID.state ? "Add Coupon" : "Edit Coupon"}
        parentName="Coupons"
        showBtn={true}
        path="/resturants/coupons"
      >
        <Button
          onClick={() => {
            props.history.goBack();
          }}
          className="btn-neutral"
          color="default"
          size="md"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          color="primary"
          size="md"
        >
          {isSubmitting ? <Spinner size="sm" /> : "Save"}
        </Button>
      </TimelineHeader>
      <TableWrapper customStyles="px-4 py-3">
        {!loading ? (
          <Form>
            <FormGroup className="row">
              <Label className="form-control-label text-left" md="3">
                Coupon name
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponName")}
                  value={values?.couponName}
                  type="text"
                  className={`form-control ${
                    errors.couponName && touched.couponName ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.couponName && touched.couponName && (
                  <ErrorLable message={errors.couponName} />
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label text-left" md="3">
                Code
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponCode")}
                  value={values?.couponCode}
                  type="text"
                  className={`form-control ${
                    errors.couponCode && touched.couponCode ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.couponCode && touched.couponCode && (
                  <ErrorLable message={errors.couponCode} />
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label text-left" md="3">
                Type
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("couponType")}
                  value={values?.couponType}
                  type="select"
                  className={`form-control ${
                    errors.couponType && touched.couponType ? "is-invalid" : ""
                  }`}
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed Amount</option>
                  <option value="free_delivery">Free Delivery</option>
                </Input>
                {errors.couponType && touched.couponType && (
                  <ErrorLable message={errors.couponType} />
                )}
              </Col>
            </FormGroup>
            {values?.couponType !== "free_delivery" && (
              <FormGroup className="row">
                <Label className="form-control-label text-left" md="3">
                  Discount
                </Label>
                <Col md="9">
                  <Input
                    type="number"
                    onChange={handleChange("couponDiscount")}
                    value={values?.couponDiscount}
                    className={`form-control ${
                      errors.couponDiscount && touched.couponDiscount
                        ? "is-invalid"
                        : ""
                    }`}
                  ></Input>
                  {errors.couponDiscount && touched.couponDiscount && (
                    <ErrorLable message={errors.couponDiscount} />
                  )}
                </Col>
              </FormGroup>
            )}
            <FormGroup className="row text-left">
              <Label className="form-control-label text-left" md="3">
                Total Amount
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("subTotal")}
                  value={values?.subTotal}
                  type="text"
                  className={`form-control ${
                    errors.subTotal && touched.subTotal ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.subTotal && touched.subTotal ? (
                  <ErrorLable message={errors.subTotal} />
                ) : (
                  <small className="text-left">
                    *The total amount that must be reached before the coupon is
                    valid
                  </small>
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row text-left">
              <Label className="form-control-label text-left" md="3">
                Products
              </Label>
              <Col md="9">
                <Select
                  isSearchable={false}
                  name="products"
                  onChange={(val) => setFieldValue("products", val)}
                  isMulti
                  closeMenuOnSelect={false}
                  value={values?.products}
                  options={
                    products?.data?.data?.length > 0
                      ? [{ name: "All", _id: "all" }, ...products?.data?.data]
                          .filter((c) => {
                            if (
                              restaurantDoc?.restaurant?.stream
                                ?.enableStreamDSP &&
                              restaurantDoc?.restaurant?.stream?.enableStreamPOS
                            ) {
                              return c.source !== "stream";
                            } else if (
                              restaurantDoc?.restaurant?.enableStreamDSP
                            ) {
                              return c.source === "stream";
                            } else {
                              return c.source !== "stream";
                            }
                          })
                          .map((it) => {
                            return {
                              label: it?.name,
                              value: it?._id,
                            };
                          })
                      : []
                  }
                />
                {errors.products && touched.products ? (
                  <ErrorLable message={errors.products} />
                ) : (
                  <small className="text-left">
                    *Choose specific products the coupon will apply to. Select
                    no products to apply coupon to entire cart
                  </small>
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row text-left">
              <Label className="form-control-label text-left" md="3">
                Category
              </Label>
              <Col md="9">
                <Select
                  isSearchable={false}
                  name="categories"
                  onChange={(val) => setFieldValue("categories", val)}
                  isMulti
                  closeMenuOnSelect={false}
                  value={values?.categories}
                  options={
                    categories?.data?.data?.length > 0
                      ? [
                          { categoryName: "All", _id: "all" },
                          ...categories?.data?.data,
                        ]
                          .filter((c) => {
                            if (
                              restaurantDoc?.restaurant?.stream
                                ?.enableStreamDSP &&
                              restaurantDoc?.restaurant?.stream?.enableStreamPOS
                            ) {
                              return c.source !== "stream";
                            } else if (
                              restaurantDoc?.restaurant?.enableStreamDSP
                            ) {
                              return c.source === "stream";
                            } else {
                              return c.source !== "stream";
                            }
                          })
                          .map((it) => {
                            return {
                              label: it?.categoryName,
                              value: it?._id,
                            };
                          })
                      : []
                  }
                />
                {errors.categories && touched.categories && (
                  <ErrorLable message={errors.categories} />
                )}
              </Col>
            </FormGroup>

            <FormGroup className="row text-left">
              <Label className="form-control-label text-left" md="3">
                Location
              </Label>
              <Col md="9">
                <Select
                  isSearchable={false}
                  name="locations"
                  onChange={(val) => setFieldValue("locations", val)}
                  isMulti
                  closeMenuOnSelect={false}
                  value={values?.locations}
                  options={
                    locations?.data?.data?.length > 0
                      ? [
                          { displayAddress: "All", _id: "all" },
                          ...locations?.data?.data,
                        ].map((it) => {
                          return {
                            label: it?.displayAddress,
                            value: it?._id,
                          };
                        })
                      : []
                  }
                />
                {errors.locations && touched.locations && (
                  <ErrorLable message={errors.locations} />
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label text-left" md="3">
                Method
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("method")}
                  value={values?.method}
                  type="select"
                  className={`form-control ${
                    errors.method && touched.method ? "is-invalid" : ""
                  }`}
                >
                  <option value={null}>Select</option>{" "}
                  {values?.couponType !== "free_delivery" && (
                    <>
                      <option value="all">All</option>
                      <option value="pickup">Pickup</option>
                      <option value="dine-in">Dine in</option>
                      <option value="walkup">Walkup</option>
                      <option value="catering">Catering</option>
                    </>
                  )}
                  <option value="delivery">Delivery</option>
                </Input>
                {errors.method && touched.method && (
                  <ErrorLable message={errors.method} />
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label text-left" md="3">
                Date Start
              </Label>
              <Col md="9">
                <Input
                  min={new Date().toISOString().split("T")[0]}
                  max={
                    values?.endDate &&
                    new Date(values?.endDate).toISOString().split("T")[0]
                  }
                  type="date"
                  onChange={(e) => {
                    setFieldValue("startDate", e.target.value);
                  }}
                  value={moment(values?.startDate).format("YYYY-MM-DD")}
                  className={`form-control ${
                    errors.startDate && touched.startDate ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.startDate && touched.startDate && (
                  <ErrorLable message={errors.startDate} />
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label text-left" md="3">
                Date End
              </Label>
              <Col md="9">
                <Input
                  min={
                    values.startDate &&
                    new Date(values?.startDate).toISOString().split("T")[0]
                  }
                  onChange={(e) => {
                    setFieldValue("endDate", e.target.value);
                  }}
                  value={moment(values?.endDate).format("YYYY-MM-DD")}
                  type="date"
                  className={`form-control ${
                    errors.endDate && touched.endDate ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.endDate && touched.endDate && (
                  <ErrorLable message={errors.endDate} />
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row text-left">
              <Label className="form-control-label text-left" md="3">
                Uses Per Coupon
              </Label>
              <Col md="9">
                <Input
                  type="text"
                  onChange={handleChange("usesPerCoupon")}
                  value={values?.usesPerCoupon}
                  className={`form-control ${
                    errors.usesPerCoupon && touched.usesPerCoupon
                      ? "is-invalid"
                      : ""
                  }`}
                ></Input>

                {errors.usesPerCoupon && touched.usesPerCoupon ? (
                  <ErrorLable message={errors.usesPerCoupon} />
                ) : (
                  <small className="text-left">
                    *The maximum number of times the coupon can be used by any
                    customer, leave blank for unlimited
                  </small>
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row align-items-center">
              <Label className="form-control-label text-left" md="3">
                Customer Login
              </Label>
              <Col md="1">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("customerLogin")}
                      value={values?.customerLogin}
                      checked={values?.customerLogin ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </FormGroup>
            <FormGroup className="row text-left">
              <Label className="form-control-label text-left" md="3">
                Uses Per Customer
              </Label>
              <Col md="9">
                <Input
                  onChange={handleChange("usesPerCustomer")}
                  value={values?.usesPerCustomer}
                  disabled={!values?.customerLogin}
                  type="text"
                  className={`form-control ${
                    errors.usesPerCustomer && touched.usesPerCustomer
                      ? "is-invalid"
                      : ""
                  }`}
                ></Input>
                {errors.usesPerCustomer && touched.usesPerCustomer ? (
                  <ErrorLable message={errors.usesPerCustomer} />
                ) : (
                  <small className="text-left">
                    *The maximum number of times the coupon can be used by a
                    single customer, leave blank for unlimited
                  </small>
                )}
              </Col>
            </FormGroup>
            <FormGroup className="row align-items-center">
              <Label className="form-control-label text-left" md="3">
                Status
              </Label>
              <Col md="1">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("status")}
                      value={values?.status}
                      checked={values?.status ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </FormGroup>
          </Form>
        ) : (
          <div
            style={{
              padding: 20,
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <center>
              <Spinner size="lg" color="primary" type="border" />
            </center>
          </div>
        )}
      </TableWrapper>
      <div style={{ height: 100 }} />
    </>
  );
}
