import { useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginations = ({
  pageNumbers,
  setPage,
  page,
  total,
  records,
  nextPage,
  prevPage,
  currentPage,
}) => {
  useEffect(() => {
    if (records === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [records]);

  return (
    <nav
      className="mx-3 mt-5"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Pagination>
        {page > 1 && (
          <PaginationItem>
            <PaginationLink
              aria-label="Previous"
              onClick={(e) => setPage(prevPage)}
            >
              <i className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
        )}
        {total > 50 && (
          <>
            {prevPage !== "" && (
              <PaginationItem className={page === prevPage ? "active" : ""}>
                <PaginationLink onClick={() => setPage(prevPage)}>
                  {prevPage}
                </PaginationLink>
              </PaginationItem>
            )}
            <PaginationItem className={page === currentPage ? "active" : ""}>
              <PaginationLink onClick={() => setPage(currentPage)}>
                {currentPage}
              </PaginationLink>
            </PaginationItem>
            {nextPage && (
              <PaginationItem className={page === nextPage ? "active" : ""}>
                <PaginationLink onClick={() => setPage(nextPage)}>
                  {nextPage}
                </PaginationLink>
              </PaginationItem>
            )}
          </>
        )}
        {page !== pageNumbers.length && total > 50 && (
          <PaginationItem>
            <PaginationLink
              aria-label="Next"
              onClick={(e) => setPage(nextPage)}
            >
              <i className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </nav>
  );
};

export default Paginations;
