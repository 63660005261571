import { useFormik } from "formik";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";
import Select2 from "react-select2-wrapper";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import TimelineHeader from "../../components/Header/SimpleHeader";
import SecureInput from "../../components/Inputs/SecureInput";
import TieredInput from "../../components/Restaurant/tiered-inputt";
import { useEntity } from "../../hooks/useEntity";
import { useUploadImage } from "../../hooks/useUploadImage";
import ErrorLable from "../../utils/ErrorLable";
import logger from "../../utils/logger";
import { validationSchemaAddPartner } from "../../utils/schemas/AddPartner";
import timezones from "../../utils/timezones";
import Compressor from "compressorjs";

const MyorderingInputWithLabel = ({
  label,
  errors,
  touched,
  handleChange,
  value,
  name,
  type,
  addon,
}) => {
  return (
    <Row>
      <Col
        md="3"
        style={{
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-end",
          alignItems: "center",
        }}
      >
        {label}
      </Col>
      <Col md="8" className={isMobile && "my-2"}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{addon}</InputGroupText>
          </InputGroupAddon>
          <Input
            type={type}
            name={name}
            onChange={handleChange}
            value={value}
            autoComplete="off"
            className={`form-control ${errors && touched ? "is-invalid" : ""}`}
            rows={type === "textarea" && 3}
          />
        </InputGroup>
        {errors && touched && <ErrorLable message={errors} />}
      </Col>
    </Row>
  );
};

export default function AddPartner(props) {
  const { create: createPartner } = useEntity("partner");
  const { upload } = useUploadImage();
  const [headerLogo, setHeaderLogo] = useState("");
  const [timezone, setTimezone] = useState("Pacific Standard Time");
  const { errors, values, handleChange, handleSubmit, touched, isSubmitting } =
    useFormik({
      initialValues: {
        partnerUrl: "",
        name: "",
        password: "",
        phone: "",
        email: "",
        orderFee: 0,
        firstTierUpto: "",
        secondTierUpto: "",
        thirdTierUpto: "",
        fourthTierUpto: "",
        firstTierOnlineOrder: "",
        secondTierOnlineOrder: "",
        thirdTierOnlineOrder: "",
        fourthTierOnlineOrder: "",
        printNodeEmail: "",
        printNodeKey: "",
        sendGridEmail: "",
        sendGridKey: "",
        paymentUrl: "",
        apiUser: "",
        paymentPassword: "",
        jupiterBasic: "",
        vaultId: "",
        privacy: "",
        terms: "",
        headerLogo: "",
        timezone: "",
        address: "",
        fixedValue: "",
        enableFoodTronix: false,
        enableNewArch: false,
      },
      validationSchema: validationSchemaAddPartner,

      onSubmit: async (values, { resetForm }) => {
        let headerPath = "";

        headerPath = await upload({
          file: headerLogo,
          owner: "partner",
          resize: false,
        });

        const data = {
          url: values?.partnerUrl,
          name: values?.name,
          phone: values?.phone,
          headerLogo: headerPath,
          password: values?.password,
          email: values?.email,
          address: values.address,
          status: "active",
          role: "partner",
          emailApi: {
            email: values.sendGridEmail,
            key: values.sendGridKey,
          },
          privacy: values.privacy,
          terms: values.terms,
          paymentConfig: {
            apiUrl: values?.paymentUrl,
            apiUser: values?.apiUser,
            apiPassword: values?.paymentPassword,
            jupiterBasic: values?.jupiterBasic,
            vaultId: values?.vaultId,
          },
          timezone: timezone,
          orderFee: values?.orderFee,
          fixedValue: values?.fixedValue,
          tiers: {
            firstTier: {
              upto: values.firstTierUpto,
              onlineOrder: values.firstTierOnlineOrder,
            },
            secondTier: {
              upto: values.secondTierUpto,
              onlineOrder: values.secondTierOnlineOrder,
            },
            thirdTier: {
              upto: values.thirdTierUpto,
              onlineOrder: values.thirdTierOnlineOrder,
            },
            forthTier: {
              upto: values.fourthTierUpto,
              onlineOrder: values.fourthTierOnlineOrder,
            },
          },
          enableFoodTronix: values.enableFoodTronix,
        };

        try {
          await createPartner({
            ...values,
            ...data,
          });
          toast.success("Partner added successfully");
          props.history.goBack();
          setHeaderLogo("");
          resetForm();
        } catch (error) {
          logger.push({ error, info: "AddPartner" });
          toast.error(error?.message);
        }
      },
    });

  return (
    <>
      <TimelineHeader
        path="/admin/partners"
        name="Add Partner"
        parentName="Partners"
        showBtn={true}
      >
        <Button
          className="btn-primary text-white"
          color="primary"
          size="md"
          onClick={handleSubmit}
        >
          {isSubmitting ? <Spinner size="sm" /> : "Save"}
        </Button>
        <Button
          className="btn-neutral text-black"
          color="default"
          size="md"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Cancel
        </Button>
      </TimelineHeader>

      <div
        className={"mt--6 card p-4"}
        style={{
          zIndex: 999,
          position: "relative",
          minHeight: "80vh",
          marginLeft: 30,
          marginRight: 30,
        }}
      >
        <Container fluid>
          <Row>
            <Col md="6">
              <MyorderingInputWithLabel
                label={"Partner URL"}
                errors={errors.partnerUrl}
                touched={touched.partnerUrl}
                handleChange={handleChange}
                value={values.partnerUrl}
                type="text"
                addon={<i className="fa fa-link" aria-hidden="true"></i>}
                name={"partnerUrl"}
              />
            </Col>
            <Col md="6">
              <MyorderingInputWithLabel
                label={"Owner"}
                errors={errors.name}
                touched={touched.name}
                handleChange={handleChange}
                value={values.name}
                type="text"
                addon={
                  <i
                    className="fa fa-user"
                    style={{ fontSize: 18 }}
                    aria-hidden="true"
                  ></i>
                }
                name={"name"}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="6">
              <MyorderingInputWithLabel
                label={"Phone"}
                errors={errors.phone}
                touched={touched.phone}
                handleChange={handleChange}
                value={values.phone}
                type="number"
                addon={<i className="fa fa-phone" aria-hidden="true"></i>}
                name={"phone"}
              />
            </Col>
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  Passoword
                </Col>
                <Col md="8" className={isMobile && "my-2"}>
                  <InputGroup>
                    <SecureInput
                      icon={"fa fa-lock"}
                      value={values?.password}
                      onChange={handleChange("password")}
                      className={`form-control ${
                        errors.password && touched.password ? "is-invalid" : ""
                      }`}
                    />
                  </InputGroup>
                  {errors?.password && touched?.password && (
                    <ErrorLable message={errors.password} />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="6">
              <MyorderingInputWithLabel
                label={"Email"}
                errors={errors.email}
                touched={touched.email}
                handleChange={handleChange}
                value={values.email}
                type="text"
                addon={<i className="fa fa-envelope" aria-hidden="true"></i>}
                name={"email"}
              />
            </Col>
            <Col md="6">
              <MyorderingInputWithLabel
                label={"Address"}
                errors={errors.address}
                touched={touched.address}
                handleChange={handleChange}
                value={values.address}
                type="textarea"
                addon={<i className="fa fa-globe" aria-hidden="true"></i>}
                name={"address"}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  Timezone
                </Col>
                <Col md="8" className={isMobile && "my-2"}>
                  <Select2
                    className="form-control"
                    isSearchable={true}
                    options={{
                      placeholder: timezone,
                    }}
                    data={timezones}
                    onSelect={(e) => {
                      setTimezone(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6" className="mt-3">
              <Row style={{ textAlign: "left" }}>
                <FormGroup
                  className="d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Label
                    md={8}
                    className="text-left"
                    htmlFor="example-text-input"
                  >
                    Enable Foodtronix
                  </Label>
                  <InputGroup className="mx-4">
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={values?.enableFoodTronix}
                        value={values?.enableFoodTronix}
                        onChange={handleChange("enableFoodTronix")}
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                  </InputGroup>
                </FormGroup>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  Order Fee
                </Col>
                <Col md="8" className={isMobile && "my-2"}>
                  <Input
                    onChange={handleChange("orderFee")}
                    value={values?.orderFee}
                    type="select"
                  >
                    <option value="">Select</option>
                    <option value="Fixed">Fixed</option>
                    <option value="Tiered">Tiered</option>
                  </Input>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="1"></Col>
            <Col md="6" className="mt-3 mx-5">
              {values.orderFee === "Tiered" && (
                <TieredInput
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  values={values}
                />
              )}
            </Col>
          </Row>
          {values.orderFee === "Fixed" && (
            <Row className="mt-3">
              <Col md={6}>
                <MyorderingInputWithLabel
                  label={"Fixed Value"}
                  errors={errors.fixedValue}
                  touched={touched.fixedValue}
                  handleChange={handleChange}
                  value={values.fixedValue}
                  type="number"
                  addon={<i className="fa fa-server" aria-hidden="true"></i>}
                  name={"fixedValue"}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-2 align-items-start">
            <Col md="6">
              <Row>
                <Col
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: isMobile ? "center" : "flex-end",
                    alignItems: "center",
                  }}
                >
                  Header Logo
                </Col>
                <Col md="8" className={isMobile && "my-2"}>
                  <ImageUploader
                    withIcon={true}
                    buttonText="Choose images"
                    fileContainerStyle={{
                      elevation: 0,
                      shadow: 0,
                      border: "1px solid #adb5bd",
                    }}
                    maxFileSize={10002880}
                    singleImage
                    onChange={(f) => {
                      setHeaderLogo(f[0]);
                      new Compressor(f[0], {
                        quality: 0.6,
                        retainExif: false,
                        // height: 1000,
                        success: (compressedResult) => {
                          setHeaderLogo(compressedResult);
                        },
                      });
                    }}
                    imgExtension={[
                      ".jpg",
                      ".gif",
                      ".png",
                      ".gif",
                      "webp",
                      "jpeg",
                    ]}
                    withPreview
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6" className="mt-3">
              <Row style={{ textAlign: "left" }}>
                <FormGroup
                  className="d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Label
                    md={8}
                    className="text-left"
                    htmlFor="example-text-input"
                  >
                    Forward To New Arch.
                  </Label>
                  <InputGroup className="mx-4">
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={values?.enableNewArch}
                        value={values?.enableNewArch}
                        onChange={handleChange("enableNewArch")}
                      />
                      <span className="custom-toggle-slider rounded-circle"></span>
                    </label>
                  </InputGroup>
                </FormGroup>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
