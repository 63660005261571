import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaArrowLeft } from "react-icons/fa";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Container, Spinner } from "reactstrap";
import AuthHeader from "../../components/Header/AuthHeader";
import Timer from "../../components/Timer";
import { useEntity } from "../../hooks/useEntity";
import logger from "../../utils/logger";

const VerifyOTP = ({ history }) => {
  const location = useLocation();

  const [otp, setOtp] = useState();

  const [, setCookie] = useCookies(["secondsLeft"]);

  const { create, loading } = useEntity("auth/verify");

  const { create: resendEmail, loading: resendLoading } =
    useEntity("auth/reset");

  useEffect(() => {
    setCookie("secondsLeft", 30, { path: "/" });
  }, []);

  return (
    <div className="bg-white" style={{ height: "100vh" }}>
      <div
        className="mt-4 mx-4"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div>
          <Button size="sm">Contact</Button>
        </div>
      </div>
      <div
        className="d-flex"
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Container
          style={{
            maxWidth: "448px",
          }}
        >
          <AuthHeader title="Verify OTP" lead="" />
          <Card>
            <CardBody>
              <div
                onClick={() => history.push("/login")}
                className="text-left mb-3 d-flex"
                style={{
                  color: "gray",
                  cursor: "pointer",
                  alignItems: "baseline",
                }}
              >
                <div>
                  <FaArrowLeft className="mr-2" style={{ color: "gray" }} />
                </div>
                <div>Login</div>
              </div>
              <div className="text-center text-muted mb-4">
                <small>Please enter 4 digit code sent to your mail.</small>
              </div>
              <div className="d-flex justify-content-center">
                <OtpInput
                  onChange={(otp) => {
                    setOtp(otp);
                  }}
                  numInputs={4}
                  separator={<span style={{ marginRight: 10 }}></span>}
                  inputStyle={{
                    backgroundColor: "#ededed",
                    border: "1px solid #ededed",
                    fontSize: 25,
                    height: "80px",
                    width: "80px",
                    color: "black",
                    fontWeight: "600",
                  }}
                  focusStyle={{
                    color: "black",
                    backgroundColor: "#fff",
                  }}
                  value={otp}
                />
              </div>

              <Timer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    color: "gray",
                    marginTop: 20,
                    fontSize: 12,
                  }}
                >
                  If you don{"'"}t receive the code?{" "}
                  <span
                    onClick={async () => {
                      setCookie("secondsLeft", 30, { path: "/" });
                      try {
                        await resendEmail({
                          email: location.state,
                        });
                        toast.success("OTP sent successfully");
                      } catch (error) {
                        logger.push({
                          error,
                          info: "Restaurant_OTP_verify",
                        });
                        toast.error(error.message);
                      }
                    }}
                    className="ml-1"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: "gray",
                      cursor: "pointer",
                      fontSize: 12,
                    }}
                  >
                    Resend{resendLoading && <Spinner size="sm" />}
                  </span>
                </div>
              </Timer>
              <Button
                onClick={async () => {
                  try {
                    await create({
                      email: location.state,
                      otp,
                    });
                    toast.success("OTP verified successfully");
                    history.push({
                      pathname: "/update-password",
                      state: { email: location.state, otp },
                    });
                  } catch (error) {
                    toast.error(error.message);
                  }
                }}
                block
                className="my-4"
                color="info"
                type="button"
                disabled={otp?.length !== 4}
              >
                {loading ? <Spinner color="white" size="sm" /> : " Verify OTP"}
              </Button>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default VerifyOTP;
