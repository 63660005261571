/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import RestaurantWrapper from "../../../components/Layout/RestaurantWrapper";
import { AuthContext } from "../../../context";
import { useCart } from "../../../hooks/ordering/useHandleCart";
import { useRestaurant } from "../../../hooks/restaurant/useRestaurant";
import { useEntity } from "../../../hooks/useEntity";
import useOrdering from "../../../store/ordering";
import { useOrderingRestaurant } from "../../../store/ordering/useOrderingRestaurant";

function RedirectComponent({ shouldRedirect, to }) {
  const { clearCart } = useCart();
  useEffect(() => {
    if (shouldRedirect) {
      clearCart();
      window.location.href = to;
    }
  }, [shouldRedirect]);

  return <></>;
}

export default function DineinOrdering(props) {
  const orderingStore = useOrdering();
  const { restaurant, setRestaurant } = useOrderingRestaurant();
  const urlParams = new URLSearchParams(window.location.search);
  const pathname = window.location.pathname.substr(1);
  const authContext = useContext(AuthContext);

  const { find: findRestaurant, entity: restaurantDoc } = useRestaurant();

  useEffect(() => {
    if (authContext.user) {
      if (
        Object.keys(authContext.user).length !== 0 &&
        authContext.user.role !== "customer"
      )
        authContext.logout();
    }
  }, []);

  useEffect(() => {
    findRestaurant({
      partnerUrl: window.location.host,
      restaurantId: pathname.split("/")[1],
    });
  }, []);

  useEffect(() => {
    if (Object.keys(restaurantDoc).length > 0) {
      setRestaurant(restaurantDoc?.data);
    }
  }, [restaurantDoc]);

  const {
    findOne,
    entity: location,
    loading,
  } = useEntity("eat/ordering/dine-in/location");

  useEffect(() => {
    if (location) {
      orderingStore.setLocation(location?.data);
      orderingStore.setMethod("dine-in");
      orderingStore.setTiming("now");
    } else {
      findOne(urlParams.get("location"));
    }
  }, [location]);
  console.log("TESLA", location?.data?.ordering.enableDineIn);

  if (
    (!location?.data && !loading) ||
    (location?.data && !location?.data?.ordering.enableDineIn)
  ) {
    return (
      <div
        style={{
          marginTop: 50,
        }}
      >
        <h1>We're not accepting the order</h1>
        <h4>
          We're not accepting the orders at the moment. Please check back later.
        </h4>
      </div>
    );
  }
  console.log(restaurant);

  return (
    <RestaurantWrapper>
      <RedirectComponent
        shouldRedirect={
          location?.data &&
          orderingStore.location &&
          orderingStore.method &&
          orderingStore.timing &&
          restaurant.url
        }
        to={`${restaurant.url}/table`}
      />
    </RestaurantWrapper>
  );
}
