import Compressor from "compressorjs";
import { useContext, useEffect, useState } from "react";
import ImageUploader from "react-images-upload";
import { useQueryClient } from "react-query";
import Select from "react-select";
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import apiCaller from "../../../../api/apiCaller";
import { AuthContext } from "../../../../context";
import { useEntity } from "../../../../hooks/useEntity";
import { useAdminStore } from "../../../../store/admin";
const SectionHeader = ({ title }) => {
  return <h4 className="heading text-muted mb-5">{title}</h4>;
};

const Divider = () => {
  return <hr className="my-5" />;
};

export const ErrorLable = ({ message }) => {
  return (
    <small
      className="mt-1"
      style={{
        color: "red",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <span style={{ fontSize: 12 }}>{message}</span>
      </div>
    </small>
  );
};

export default function GeneralTabItem(props) {
  const {
    category,
    location,
    setImage,
    handleChange,
    values,
    setFieldValue,
    taxRate,
    items,
    errors,
    touched,
    tag,
    entity,
  } = props;

  const [newImage, setNewImage] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [seed, setSeed] = useState(false);
  const { restaurant } = useAdminStore();
  const searchParams = new URLSearchParams(window.location.search);

  const authRest =
    authContext?.user?.role === "restaurant" && authContext?.user?._id;
  const { findOne, entity: restaurantDoc } = useEntity("restaurant");

  useEffect(() => {
    findOne(authRest || restaurant);
  }, []);

  useEffect(() => {
    if (searchParams.get("id") && items) {
      if (items?.length > 0) {
        const arr = items?.map((it) => {
          if (it?._id === searchParams.get("id") && it.isCombo) {
            return;
          } else if (it?._id === searchParams.get("id") && !it.isCombo) {
            return;
          } else {
            return {
              label: it?.name,
              value: it?._id,
              price: it?.price,
            };
          }
        });
        const finalArr = arr?.filter((a) => a);
        setFilteredItems([...finalArr]);
      }
    } else {
      if (items) {
        const arr = items?.map((it) => {
          if (it?.isCombo) {
            return;
          }
          return {
            label: it?.name,
            value: it?._id,
            price: it?.price,
          };
        });

        const finalArr = arr?.filter((a) => a);
        setFilteredItems([...finalArr]);
      }
    }
  }, [items]);

  const handleDeleteImage = async (uri) => {
    await apiCaller("/upload/delete-image", {
      method: "DELETE",
      body: {
        imageUrl: uri, //Replace with image url
        id: entity?.data?._id, //Replace With Partner Restaurant and Item Id
        entityName: "item", //Replace it with relevant entity name "partner,restaurant or item"
      },
    });
    queryClient.invalidateQueries("find-one-item");
    setSeed(!seed);
    setFieldValue("image", "");
  };

  return (
    <div>
      <Form>
        <SectionHeader title="Product Information" />
        <div className="pl-lg-4">
          <FormGroup>
            <Row>
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="productName"
                >
                  Product Name
                </label>
              </Col>
              <Col md="8">
                <Input
                  placeholder="Product Name"
                  name="productName"
                  className={`form-control ${
                    errors.productName && touched.productName
                      ? "is-invalid"
                      : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  value={values?.productName}
                ></Input>
                {errors.productName && touched.productName ? (
                  <small className="text-red">{errors.productName}</small>
                ) : (
                  <small>Name of the product*</small>
                )}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="customName"
                >
                  Custom Name
                </label>
              </Col>
              <Col md="8">
                <Input
                  placeholder="Custom Name"
                  name="customName"
                  className={`form-control ${
                    errors.customName && touched.customName ? "is-invalid" : ""
                  }`}
                  type="text"
                  onChange={handleChange}
                  value={values?.customName}
                ></Input>
                {errors.customName && touched.customName ? (
                  <small className="text-red">{errors.customName}</small>
                ) : (
                  <small>Name of the product*</small>
                )}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label style={{ fontSize: 15, fontWeight: "600" }}>
                  Desciption
                </label>
              </Col>
              <Col md="8">
                <Input
                  rows="5"
                  type="textarea"
                  placeholder="Description"
                  onChange={handleChange("description")}
                  value={values?.description}
                  className={`form-control ${
                    errors.description && touched.description
                      ? "is-invalid"
                      : ""
                  }`}
                ></Input>
                {errors.description && touched.description ? (
                  <small className="text-red">{errors.description}</small>
                ) : (
                  <small className={`${"text-muted"}`}>
                    Brief description of the product*
                  </small>
                )}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="row">
            <Col md="4">
              <label
                style={{ fontSize: 15, fontWeight: "600" }}
                htmlFor="description"
              >
                Image
              </label>
            </Col>
            <Col md={!newImage && values?.image !== "" ? "5" : "8"}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  fileContainerStyle={{
                    backgroundColor: "#efefef",
                  }}
                  singleImage
                  maxFileSize={10002880}
                  imgExtension={[
                    ".jpg",
                    ".gif",
                    ".png",
                    ".gif",
                    "webp",
                    "jpeg",
                  ]}
                  withPreview
                  onChange={(f) => {
                    const image = f[0];
                    setNewImage(f[0]);
                    if (image) {
                      new Compressor(image, {
                        quality: 0.6,
                        retainExif: true,
                        // height: 500,
                        // width: 300,
                        success: (compressedResult) => {
                          setImage(compressedResult);
                          setNewImage(compressedResult);
                        },
                      });
                    }
                  }}
                  defaultImage={values?.image}
                />
              </div>
            </Col>
            <Col md="3">
              {!newImage && values?.image !== "" && (
                <div
                  className="row"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <img
                    alt="a"
                    style={{
                      height: 180,
                      width: 180,
                      borderRadius: 15,
                      marginTop: 5,
                    }}
                    src={values?.image}
                  />
                  <span
                    onClick={() => {
                      handleDeleteImage(values?.image);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 65,
                      zIndex: 9999,
                      color: "red",
                      cursor: "pointer",
                      fontSize: 20,
                    }}
                    className="fa fa-trash"
                  ></span>
                </div>
              )}
            </Col>
          </FormGroup>

          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="exampleFormControlSelect2"
                >
                  Price
                </label>
              </Col>
              <Col md="8">
                <Input
                  id="price"
                  multiple=""
                  type="number"
                  onChange={handleChange("price")}
                  value={values?.price}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  className={`form-control ${
                    errors.price && touched.price ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.price && touched.price && (
                  <ErrorLable message={errors.price} />
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="exampleFormControlSelect2"
                >
                  Plu
                </label>
              </Col>
              <Col md="8">
                <Input
                  id="plu"
                  onChange={handleChange("plu")}
                  value={values?.plu}
                  className={`form-control ${
                    errors.plu && touched.plu ? "is-invalid" : ""
                  }`}
                ></Input>
                {errors.plu && touched.plu && (
                  <ErrorLable message={errors.plu} />
                )}
              </Col>
            </Row>
          </FormGroup>
          {restaurantDoc?.data?.restaurant?.enableJKSoft && (
            <FormGroup>
              <Row>
                <Col md="4">
                  <label
                    style={{ fontSize: 15, fontWeight: "600" }}
                    htmlFor="productName"
                  >
                    Printer ID
                  </label>
                </Col>
                <Col md="8">
                  <Input
                    placeholder="Printer ID"
                    name="printerId"
                    className={`form-control ${
                      errors.printerId && touched.printerId ? "is-invalid" : ""
                    }`}
                    type="text"
                    onChange={handleChange}
                    value={values?.printerId}
                  ></Input>
                  {errors.printerId && touched.printerId ? (
                    <small className="text-red">{errors.printerId}</small>
                  ) : (
                    <small>Printer ID For JKSoft</small>
                  )}
                </Col>
              </Row>
            </FormGroup>
          )}
        </div>
        <Divider />

        <SectionHeader title="Combo Meal" />
        <div className="pl-lg-4">
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="isAlchohol"
                >
                  Is Combo
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("isCombo")}
                      value={values?.isCombo}
                      checked={values?.isCombo ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>

          {values?.isCombo && (
            <FormGroup className="row">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="exampleFormControlSelect2"
                >
                  Sub Items
                </label>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Select
                    name="subProducts"
                    isSearchable={false}
                    onChange={(val) => setFieldValue("subProducts", val)}
                    isMulti
                    closeMenuOnSelect={false}
                    value={values?.subProducts}
                    options={filteredItems}
                  />
                  {errors.subProducts && touched.subProducts && (
                    <small className="text-red">
                      Select the products for combo meals
                    </small>
                  )}
                </FormGroup>
              </Col>
            </FormGroup>
          )}
        </div>
        <Divider />

        <SectionHeader title="Ordering Settings" />
        <div className="pl-lg-4">
          {/* <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="loyaltyPointEnabled"
                >
                  Enable loyalty Point{" "}
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("loyaltyPointEnabled")}
                      value={values?.loyaltyPointEnabled}
                      checked={values?.loyaltyPointEnabled ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup> */}
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="preparationTime"
                >
                  Method Availability
                </label>
              </Col>
              <Col md="8">
                <Input
                  id="method"
                  type="select"
                  onChange={handleChange("method")}
                  value={values?.method}
                  className={`form-control ${
                    errors.method && touched.method ? "is-invalid" : ""
                  }`}
                >
                  <option value="">Select </option>

                  <option value={"pickup"}> Pickup</option>
                  <option value={"delivery"}> Delivery</option>
                  <option value={"walkup"}> Walk Up</option>
                  <option value={"dine-in"}>Dine In</option>
                  <option value={"all"}> All</option>
                </Input>
                {errors.method && touched.method && (
                  <ErrorLable message={errors?.method} />
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="row">
            <Col md="4">
              <label
                style={{ fontSize: 15, fontWeight: "600" }}
                htmlFor="exampleFormControlSelect2"
              >
                Categories
              </label>
            </Col>
            <Col md="8">
              <FormGroup>
                <Select
                  name="categories"
                  isSearchable={false}
                  onChange={(val) => {
                    setFieldValue("categories", val);
                    setFieldValue("assignedToLocation", [
                      { label: "All", value: "all" },
                    ]);
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  value={values?.categories}
                  options={category?.map((modifier) => {
                    return {
                      label: modifier?.categoryName,
                      value: modifier?._id,
                    };
                  })}
                />
                {errors.categories && touched.categories && (
                  <ErrorLable message={errors.categories} />
                )}
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label style={{ fontSize: 15, fontWeight: "600" }}>Tag</label>
              </Col>
              <Col md="8">
                <Select
                  onChange={(val) => setFieldValue("tag", val)}
                  isMulti
                  name="tag"
                  isSearchable={false}
                  options={tag?.map((loca) => {
                    return {
                      label: loca?.name,
                      value: loca?._id,
                    };
                  })}
                  value={values?.tag}
                />
                {errors.tag && touched.tag && (
                  <ErrorLable message={errors.tag} />
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label style={{ fontSize: 15, fontWeight: "600" }}>
                  Tax Rate
                </label>
              </Col>
              <Col md="8">
                <Input
                  type="select"
                  onChange={handleChange("taxRate")}
                  value={values?.taxRate || taxRate?.[0]?._id}
                  className={`form-control ${
                    errors.taxRate && touched.taxRate ? "is-invalid" : ""
                  }`}
                >
                  {taxRate?.map((item) => {
                    return <option value={item?._id}> {item?.name}</option>;
                  })}
                </Input>
                {errors.taxRate && touched.taxRate && (
                  <ErrorLable message={errors.taxRate} />
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="preparationTime"
                >
                  Preparation Time
                </label>
              </Col>
              <Col md="8">
                <InputGroup>
                  <Input
                    id="preparationTime"
                    type="number"
                    onChange={handleChange("preparationTime")}
                    value={values?.preparationTime}
                    className={`form-control ${
                      errors.preparationTime && touched.preparationTime
                        ? "is-invalid"
                        : ""
                    }`}
                  ></Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText>Minutes</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {errors.preparationTime && touched.preparationTime && (
                  <ErrorLable message={errors.preparationTime} />
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="row">
            <Col md="4">
              <label
                style={{ fontSize: 15, fontWeight: "600" }}
                htmlFor="assignedToLocation"
              >
                Assigned To Location
              </label>
            </Col>
            <Col md="8">
              <FormGroup>
                <Select
                  onChange={(val) => setFieldValue("assignedToLocation", val)}
                  isMulti
                  name="location"
                  isSearchable={false}
                  options={[
                    { displayAddress: "All", _id: "all" },
                    ...location,
                  ].map((loca) => {
                    return {
                      label: loca?.displayAddress,
                      value: loca?._id,
                    };
                  })}
                  value={values?.assignedToLocation}
                />
                {errors.assignedToLocation && touched.assignedToLocation && (
                  <ErrorLable message={errors.assignedToLocation} />
                )}
              </FormGroup>
            </Col>
          </FormGroup>
        </div>
        <Divider />

        <SectionHeader title="Item Options" />
        <div className="pl-lg-4">
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="override"
                >
                  Override Price & Plu In Menu
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("override")}
                      value={values?.override}
                      checked={values?.override ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="vegetarian"
                >
                  Vegetarian
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("vegetarian")}
                      value={values?.vegetarian}
                      checked={values?.vegetarian ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="glutenFree"
                >
                  Gluten Free
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("glutenFree")}
                      value={values?.glutenFree}
                      checked={values?.glutenFree ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="vegan"
                >
                  Vegan
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("vegan")}
                      value={values?.vegan}
                      checked={values?.vegan}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="spicyLevel"
                >
                  Spicy Level
                </label>
              </Col>
              <Col md="8">
                <>
                  <Input
                    onChange={handleChange("spicyLevel")}
                    value={values?.spicyLevel}
                    type="select"
                  >
                    <option value="">Select</option>
                    <option value="spicy">Spicy</option>
                    <option value="very_spicy">Very Spicy</option>
                    <option value="extra_spicy">Extra Spicy</option>
                  </Input>
                </>
              </Col>
            </Row>
          </FormGroup>
        </div>
        <Divider />
        <SectionHeader title="MISC" />
        <div className="pl-lg-4">
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="isAlchohol"
                >
                  Is Alcohol
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("isAlchohol")}
                      value={values?.isAlchohol}
                      checked={values?.isAlchohol ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="isSuggested"
                >
                  Suggested
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("isSuggested")}
                      value={values?.isSuggested}
                      checked={values?.isSuggested ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row className="my-2">
              <Col md="4">
                <label
                  style={{ fontSize: 15, fontWeight: "600" }}
                  htmlFor="isSuggested"
                >
                  Catering
                </label>
              </Col>
              <Col md="8">
                <>
                  <label className="custom-toggle">
                    <input
                      onChange={handleChange("catering")}
                      value={values?.catering}
                      checked={values?.catering ? true : false}
                      type="checkbox"
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                </>
              </Col>
            </Row>
          </FormGroup>
        </div>
        {values.catering && (
          <>
            <Divider />
            <SectionHeader title="Catering Options" />
            <div className="pl-lg-4">
              <FormGroup>
                <Row className="my-2">
                  <Col md="4">
                    <label
                      style={{ fontSize: 15, fontWeight: "600" }}
                      htmlFor="feed"
                    >
                      Feed
                    </label>
                  </Col>
                  <Col md="8">
                    <InputGroup>
                      <Input
                        id="feed"
                        type="number"
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                          handleChange("feed")(e);
                        }}
                        value={values?.feed}
                        min={0}
                        className={`form-control ${
                          errors.feed && touched.feed ? "is-invalid" : ""
                        }`}
                      ></Input>
                    </InputGroup>
                    {errors.feed && touched.feed && (
                      <ErrorLable message={errors.feed} />
                    )}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row className="my-2">
                  <Col md="4">
                    <label
                      style={{ fontSize: 15, fontWeight: "600" }}
                      htmlFor="feed"
                    >
                      Max quantity per order
                    </label>
                  </Col>
                  <Col md="8">
                    <InputGroup>
                      <Input
                        id="moqPerOrder"
                        type="number"
                        onChange={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                          handleChange("moqPerOrder")(e);
                        }}
                        value={values?.moqPerOrder}
                        min={0}
                        className={`form-control ${
                          errors.moqPerOrder && touched.moqPerOrder
                            ? "is-invalid"
                            : ""
                        }`}
                      ></Input>
                    </InputGroup>
                    {errors.moqPerOrder && touched.moqPerOrder && (
                      <ErrorLable message={errors.moqPerOrder} />
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}
